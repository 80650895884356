<template>
  <div class="card position-relative overflow-hidden">
    <div
      v-if="permohonan.status_id === 'D'"
      style="
        position: absolute;
        padding: 5px 50px;
        background: #5e87b0;
        color: white;
        top: 15px;
        right: -45px;
        transform: rotate(45deg);
        z-index: 1;
      "
    >
      DERAF
    </div>
    <div
      v-else-if="['K1', 'K2', 'KB'].indexOf(permohonan.status_id) !== -1"
      style="
        position: absolute;
        padding: 5px 50px;
        background: red;
        color: white;
        top: 15px;
        right: -45px;
        transform: rotate(45deg);
        z-index: 1;
      "
    >
      KUIRI
    </div>

    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li class="undone">
            <a href="#bahagian-a">
              A - Maklumat Pemaju<span
                v-if="canAccessSection('a')"
                class="ml-2 badge badge-danger"
                ><i class="fa fa-exclamation"></i
              ></span>
            </a>
          </li>
          <li class="undone">
            <a href="#bahagian-b">
              B - Maklumat Kewangan<span
                v-if="canAccessSection('b')"
                class="ml-2 badge badge-danger"
                ><i class="fa fa-exclamation"></i
              ></span>
            </a>
          </li>
          <li class="undone">
            <a href="#bahagian-c">
              C - Maklumat Tanah<span
                v-if="canAccessSection('c')"
                class="ml-2 badge badge-danger"
                ><i class="fa fa-exclamation"></i
              ></span>
            </a>
          </li>
          <li class="undone">
            <a href="#bahagian-d">
              D - Akuan Berkanun<span
                v-if="canAccessSection('d')"
                class="ml-2 badge badge-danger"
                ><i class="fa fa-exclamation"></i
              ></span>
            </a>
          </li>
          <li class="undone">
            <a href="#bahagian-e">
              E - Lain - Lain Dokumen<span
                v-if="canAccessSection('e')"
                class="ml-2 badge badge-danger"
                ><i class="fa fa-exclamation"></i
              ></span>
            </a>
          </li>
          <li class="undone">
            <a href="#bahagian-f">F - Perakuan Maklumat</a>
          </li>
        </ul>
      </nav>
      <section>
        <error-bar
          :messages="errorMessages"
          @close="errorMessages = {}"
        ></error-bar>

        <div class="tab-content">
          <div
            v-if="isCurrentTab('#bahagian-a')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-a') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN A: MAKLUMAT PEMAJU</h4>
                <bahagian-a
                  :pemaju="pemaju"
                  :maklumat="maklumat"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-taraf-pemaju-rs="kodTarafPemajuRs"
                  :kod-jenis-pemaju-rs="kodJenisPemajuRs"
                  @update="(v) => (this.maklumat = v)"
                  :readonly="!canAccessSection('a')"
                  :kuiri="hasKuiriSection('a')"
                ></bahagian-a>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-b')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-b') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN B: MAKLUMAT KEWANGAN</h4>
                <bahagian-b
                  :pemaju="pemaju"
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  :readonly="!canAccessSection('b')"
                  :kuiri="hasKuiriSection('b')"
                ></bahagian-b>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-c')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-c') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN C: MAKLUMAT TANAH</h4>
                <bahagian-c
                  :maklumat="maklumat"
                  :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
                  :kod-dun-rs="kodDunRs"
                  :kod-parlimen-rs="kodParlimenRs"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-syarat-nyata-rs="kodSyaratNyataRs"
                  :kod-status-tanah-rs="kodStatusTanahRs"
                  :kod-rezab-tanah-rs="kodRezabTanahRs"
                  :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
                  :kod-bebanan-tanah-rs="kodBebananTanahRs"
                  :kod-jenis-kaveat-rs="kodJenisKaveatRs"
                  :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"
                  :kod-jenis-tuan-tanah-rs="kodJenisTuanTanahRs"
                  :kod-jenis-urusan-rs="kodJenisUrusanRs"
                  :kod-pemilikan-pembeli-rs="kodPemilikanPembeliRs"

                  :mahkamah-tinggi="mahkamahTinggi"
                  :pengarah-rs="pengarahRs"
                  :kod-bank-hq-rs="kodBankHqRs"
                  :kod-poskod-rs="kodPoskodRs"
                  :kod-poskod-bandar-rs="kodPoskodBandarRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('c')"
                  :kuiri="hasKuiriSection('c')"
                ></bahagian-c>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-d')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-d') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN D: AKUAN BERKANUN</h4>
                <bahagian-d
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  :readonly="!canAccessSection('d')"
                  :kuiri="hasKuiriSection('d')"
                ></bahagian-d>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-e')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-e') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN E: LAIN - LAIN DOKUMEN</h4>
                <bahagian-e
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  :readonly="!canAccessSection('e')"
                  :kuiri="hasKuiriSection('e')"
                ></bahagian-e>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-f')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-f') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN F: PERAKUAN MAKLUMAT BENAR</h4>
                <bahagian-f
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  @hantar="submit"
                  :readonly="!isStatusKuiri"
                ></bahagian-f>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import BahagianB from "./BahagianB";
import BahagianC from "./BahagianC";
import BahagianD from "./BahagianD";
import BahagianE from "./BahagianE";
import BahagianF from "./BahagianF";
import ErrorBar from "../../common/ErrorBar";

export default {
  name: "Borang",
  components: {
    BahagianA,
    BahagianB,
    BahagianC,
    BahagianD,
    BahagianE,
    BahagianF,
    ErrorBar,
  },

  props: {
    pengarah: Object,
    pemaju: Object,

    default_maklumat: Object,
    default_permohonan: Object,

    // tanah
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,

    // tuan tanah
    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    // pemaju
    kodKategoriPemajuRs: Array,
    kodTarafPemajuRs: Array,
    kodJenisPemajuRs: Array,

    // pemajuan
    kodJenisHakmilikRs: Array,
    kodPbtRs: Array,
    kodJenisPemajuanRs: Array,
    kodKaedahPemajuanRs: Array,
    kodSkimProjekRs: Array,

    // iklan
    kodJenisIklanRs: Array,

    // kelulusan agensi
    kodKelulusanAgensiRs: Array,

    // misc
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,

    mahkamahTinggi: Array,
    pengarahRs: Array,

    // bank hq
    kodBankHqRs: Array,
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,
      errorMessages: {},
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    async submit() {
      swalconfirm().then((result) => {
        if (result.value) {
          axios.post(`/pemaju/setup/maklumBalas`).then((response) => {
            window.location.replace("/pemaju/setup/step2");
          });
        }
      });
    },

    canAccessSection(section) {
      if (this.isStatusKuiri) {
        return !!this.hasKuiriSection(section);
      }

      return this.isLulusBersyarat && section == "e";
    },

    hasKuiriSection(section) {
      return this.maklumat.kuiri_rs
        .filter((kuiri) => this.maklumat.kuiri_no == kuiri.kuiri_no)
        .find((kuiri) => kuiri.seksyen == section);
    },
  },

  computed: {
    isStatusKuiri() {
      return ["K1", "K2", "K3", "KB"].indexOf(this.permohonan.status_id) !== -1;
    },

    isLulusBersyarat() {
      return this.permohonan.status_id == "LB";
    },
  },
};
</script>

<style scoped></style>
