<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <section class="form-compact row">
        <div class="col-lg-12">
          <section>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Alamat Pemajuan Tanah</label>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri & Daerah <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 v-model="form.kod_negeri_id"
                         @change="form.kod_daerah_id = null; form.kod_bandar_id = null; form.kod_parlimen_id = null; form.kod_dun_id = null; form.kod_pbt_id = null;"
                         class="form-control"
                         :disabled="!canEditTanah"
                >
                  <option :value="null">Sila Pilih Negeri</option>
                  <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri-${negeri.id}`">{{ negeri.keterangan }}</option>
                </select2>
              </div>
              <div class="col-md-4">
                <select2 v-model="form.kod_daerah_id" class="form-control" :disabled="!canEditTanah">
                  <option :value="null">Sila Pilih Daerah</option>
                  <option v-for="daerah in filteredDaerahRs(form.kod_negeri_id)" :value="daerah.id" :key="`daerah-${daerah.id}`">{{
                      daerah.keterangan
                    }}
                  </option>
                </select2>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 v-model="form.kod_bandar_id" class="form-control" :disabled="!canEditTanah">
                  <option :value="null">Sila Pilih Mukim / Bandar / Pekan</option>
                  <option v-for="bandar in filteredBandarRs(form.kod_negeri_id)" :value="bandar.id" :key="`bandar-${bandar.id}`">{{
                      bandar.keterangan
                    }}
                  </option>
                </select2>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Parlimen & DUN <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 v-model="form.kod_parlimen_id" @change="form.kod_dun_id = null;" class="form-control" :disabled="!canEditTanah">
                  <option :value="null">Sila Pilih Parlimen</option>
                  <option v-for="parlimen in filteredParlimenRs(form.kod_negeri_id)" :value="parlimen.id" :key="`parlimen_${parlimen.id}`">
                    {{ parlimen.keterangan }}
                  </option>
                </select2>
              </div>
              <div class="col-md-4" v-if="showDun(form.kod_negeri_id)">
                <select2 v-model="form.kod_dun_id" class="form-control" :disabled="!canEditTanah">
                  <option :value="null">Sila Pilih DUN</option>
                  <option v-for="dun in filteredDunRs(form.kod_parlimen_id)" :value="dun.id" :key="`dun_${dun.id}`">{{ dun.keterangan }}</option>
                </select2>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Poskod <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" v-model="form.poskod" :disabled="!canEditTanah">
              </div>
            </div>
          </section>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Jenis Hakmilik <span class="label-required">*</span></label>
            <div class="col-md-4">
              <select class="form-control" v-model="form.kod_jenis_hakmilik_id" :disabled="!canEditTanah">
                <option :value="null">Sila Pilih Jenis Hakmilik</option>
                <option v-for="hakmilik in kodJenisHakmilikRs" :value="hakmilik.id">{{ hakmilik.keterangan }}</option>
              </select>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Nama PBT <span class="label-required">*</span></label>
            <div class="col-md-4">
              <select2 class="form-control" v-model="form.kod_pbt_id" :disabled="!canEditTanah">
                <option value="">Sila Pilih</option>
                <option v-for="pbt in filteredPbtRs(form.kod_negeri_id)" :value="pbt.id">{{ pbt.keterangan }}</option>
              </select2>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="card-footer" v-if="canEditTanah">
      <div class="text-right">
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianB",
  props: {
    maklumat: Object,

    kodJenisHakmilikRs: Array,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,
    kodPbtRs: Array,

    kuiri: null,
  },

  data() {
    return {
      loading: false,
      form: {
        kod_jenis_hakmilik_id: this.maklumat.kod_jenis_hakmilik_id,
        kod_negeri_id: this.maklumat.kod_negeri_id,
        kod_daerah_id: this.maklumat.kod_daerah_id,
        kod_bandar_id: this.maklumat.kod_bandar_id,
        kod_parlimen_id: this.maklumat.kod_parlimen_id,
        poskod: this.maklumat.poskod,
        kod_dun_id: this.maklumat.kod_dun_id,
        kod_pbt_id: this.maklumat.kod_pbt_id,
      },
    };
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-b`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandarRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodBandarRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredParlimenRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodParlimenRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },

    filteredDunRs(kod_parlimen_id) {
      if (!kod_parlimen_id) {
        return [];
      }

      return this.kodDunRs.filter(item => item.kod_parlimen_id == kod_parlimen_id);
    },

    filteredPbtRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodPbtRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },

    showDun(kod_negeri_id) {
      if (!kod_negeri_id) {
        return false;
      }

      const negeri = this.kodNegeriRs.find(item => item.id == kod_negeri_id);

      return negeri && negeri.ada_dun == 'Y';
    }
  },

  computed: {
    canEditTanah() {
      return this.maklumat.pindaan_pelbagai_geran;
    }
  }
}
</script>

<style scoped>

</style>
