<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Tempoh Permit (Tahun)</label>
        <div class="col-md-8">
          <input type="text" :value="maklumat.flag_pembaharuan_lesen ? 1 : 'TIDAK BERKENAAN'" name="tempoh-lesen" disabled="disabled" class="form-control bg-light ">
        </div>
      </div>

      <div class="form-group row" v-show="penalti" v-if="maklumat.pembaharuan_tempoh_lesen">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&nbsp;</label>
        <div class="col-12 col-sm-8 col-md-8">
          <div class="card border border-warning">
            <div class="card-header text-warning pb-1 border-warning">
              <h5 class="card-title"><i class="fas fa-exclamation-triangle"></i> Pemakluman</h5>
            </div>
            <div class="card-body">
              <p style="font-size:11px;"> AP perlu diperbaharui bermula tamat tempoh <strong>{{form.tarikh_luput_semasa}}</strong>. Tempoh pembaharuan terdahulu, <strong>{{maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti}}</strong> tahun. </p>

              <p style="font-size:11px;">Tempoh pembaharuan tahun semasa, <strong>{{form.tempoh_lesen}}</strong> tahun. </p>
              <p style="font-size:11px;" >Jumlah tempoh keseluruhan pembaharuan AP <strong>{{form.total_tempoh_lesen}}</strong> tahun</p>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Dokumen Surat Iringan <br><small>&#9;(Perincian Tujuan Permohonan)</small></label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_iringan_pemaju"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_iringan_pemaju`)}"
            :doc="maklumat.doc_iringan_pemaju"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_iringan_pemaju', file, callback)"
            @remove="removeDoc('doc_iringan_pemaju')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Dokumen AP Terkini</label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_ap_terkini"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_ap_terkini`)}"
            :doc="maklumat.doc_ap_terkini"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_ap_terkini', file, callback)"
            @remove="removeDoc('doc_ap_terkini')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBar from "../../common/ErrorBar";
export default {
  name: "BahagianR",
  components: {ErrorBar},
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
      errorMessages: {},

      passes: false,
      penalti: false,

      form: {
        perakaun: false,
        tempoh_lesen: this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen,
        tarikh_luput_semasa:'',
        total_tempoh_lesen:'',
      }
    }
  },

  created: function(){
    this.onOpen()
  },

  methods: {
    onOpen(){
      if(this.maklumat.flag_pembaharuan_lesen){
        if(this.maklumat.pembaharuan_tempoh_lesen){
          this.form.tarikh_luput_semasa = moment(String(this.maklumat.pembaharuan_tempoh_lesen.tarikh_luput_semasa)).format('DD/MM/YYYY');

          if(this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti > 0){
            this.form.total_tempoh_lesen= this.form.tempoh_lesen+this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti
            this.penalti = true;
          }
        }
      }
    },
    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      this.passes = false;
      // save it
      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      this.passes = false;

      axios.delete(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },

    save() {
      // this.$success();
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/bahagian-r`)
        .then((response) => {
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
  },
  computed: {
    isLanded() {
      return ['G', 'I'].indexOf(this.maklumat.kod_jenis_perjanjian_id) !== -1;
    }
  }
}
</script>

<style scoped>

</style>
