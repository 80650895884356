<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Bil.</th>
              <th>Jenis Kediaman</th>
              <th>Bil Tingkat</th>
              <th>Bil. Unit Yang Dipohon</th>
              <th>Harga Minima (RM)</th>
              <th>Harga Maksima (RM)</th>
              <th>Nilai Jualan (RM)</th>
              <th>Jenis Perjanjian</th>
            </tr>
            </thead>
            <tbody>
            <template v-if="default_pembangunan_rs.length">
              <tr v-for="(pembangunan, index) in default_pembangunan_rs">
                <td>{{ index + 1 }}</td>
                <td>{{ pembangunan.jenis_rumah.keterangan }}</td>
                <td>{{ pembangunan.bil_tingkat }}</td>
                <td>{{ pembangunan.bil_unit }}</td>
                <td>{{ pembangunan.harga_min | currency('') }}</td>
                <td>{{ pembangunan.harga_max | currency('') }}</td>
                <td>{{ pembangunan.nilai_jualan | currency('') }}</td>
                <td>{{ pembangunan.jenis_perjanjian.keterangan }}</td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianB",
  props: {
    default_pembangunan_rs: Array,
  }
}
</script>

<style scoped>

</style>
