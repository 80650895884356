<template>
  <div>
    <div class="alert alert-primary" v-if="!readonly">
      <div><b>ARAHAN:</b></div>
      <ol>
        <li>Sila pastikan maklumat pemaju di seksyen A dikemaskini terlebih dahulu.</li>
        <li>Sila pilih Pengarah yang akan menandatangani dokumen Akuan Berkanun.</li>
        <li>Sila muat turun serta lengkapkan dokumen Akuan Berkanun Pemaju dan Akuan Berkanun LAM&nbsp;&nbsp;<i class="fa fa-download"></i></li>
        <li>Sila imbas dan muat naik dokumen Akuan Berkanun Pemaju dan Akuan Berkanun LAM yang telah lengkap dengan akuan sumpah.</li>
      </ol>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="alert alert-warning" v-if="kuiri">
          <strong>Catatan Kuiri:</strong>
          <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
        </div>

        <div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Diperakukan dan ditandatangani oleh <span class="label-required">*</span></label>
            <div class="col-12 col-sm-6 col-md-6">
              <div class="d-flex justify-content-between">
                <!-- <div class="flex-grow-1"> -->
                  <select2
                    name="pengarah_peraku_akaun_berkanun_id"
                    class="form-control col-12" :class="{'is-invalid': errors.first('pengarah_peraku_akaun_berkanun_id')}"
                    v-model="form.pengarah_peraku_akaun_berkanun_id"
                    @change="save"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Pengarah</option>
                    <option v-for="pengarah in maklumat.pengarah_rs" :value="pengarah.id" :key="`pengarah_${pengarah.id}`">{{ pengarah.nama }}</option>
                  </select2>
                <!-- </div> -->
                <!-- <a v-if="maklumat.pengarah_peraku_akaun_berkanun_id && !readonly" target="_blank" class="btn btn-sm btn-secondary ml-2" :href="`/pemaju/setup/pengarah-download/${maklumat.pengarah_peraku_akaun_berkanun_id}?type=pindaan`"><i class="fa fa-download"></i></a> -->
                <a v-if="maklumat.pengarah_peraku_akaun_berkanun_id && !readonly" @click.prevent="showModal" class="btn btn-sm btn-secondary ml-2">
                  <i class="fa fa-download"></i>
                </a>
              </div>
            </div>
          </div>

          <!-- Modal Contoh Akuan Berkanun Pemaju dan Akuan Berkanun LAM -->
          <div v-if="showModalFlag" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Akuan Berkanun</h5>
                  <button type="button" class="close" @click="hideModal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p style="font-size: 12px;">Sila Pilih Jenis Akuan Berkanun</p>
                  <div class="button-group">
                    <!-- <button @click="goToView1" class="btn btn-secondary" style="font-size: 12px;">Akuan Berkanun Pemaju</button> -->
                    <!-- <button @click="goToView2" class="btn btn-secondary" style="font-size: 12px;">Akuan Berkanun LAM</button> -->
                    <a :href="pemajuUrl" target="_blank"  class="btn btn-secondary" style="font-size: 12px;">Akuan Berkanun Pemaju</a>
                    <a :href="lamUrl" target="_blank"  class="btn btn-secondary" style="font-size: 12px;">Akuan Berkanun LAM</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9Akuan Berkanun Pemaju</label>
            <div class="col-12 col-sm-6 col-md-6">
              <file-upload
                type="file" accept="application/pdf"
                name="akaun_berkanun"
                class="form-control w-100"
                :class="{'is-invalid': errors.first(`akaun_berkanun`)}"
                :doc="maklumat.akaun_berkanun"
                @uploadwithcallback="({file, callback}) => uploadDoc('akaun_berkanun', file, callback)"
                @remove="removeDoc('akaun_berkanun')"
                v-validate="'required'"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9Akuan Berkanun LAM</label>
            <div class="col-12 col-sm-6 col-md-6">
              <file-upload
                type="file" accept="application/pdf"
                name="akaun_berkanun_lam"
                class="form-control w-100"
                :class="{'is-invalid': errors.first(`akaun_berkanun_lam`)}"
                :doc="maklumat.akaun_berkanun_lam"
                @uploadwithcallback="({file, callback}) => uploadDoc('akaun_berkanun_lam', file, callback)"
                @remove="removeDoc('akaun_berkanun_lam')"
                v-validate="'required'"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer" v-if="!readonly">
        <div class="text-right">
          <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
          <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
          <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BahagianD",
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      showModalFlag: false,
      loading: false,

      form: {
        pengarah_peraku_akaun_berkanun_id: this.maklumat.pengarah_peraku_akaun_berkanun_id,
      }
    }
  },

  computed:{
    pemajuUrl(){
      return `/pemaju/setup/pengarah-download/${this.maklumat.pengarah_peraku_akaun_berkanun_id}?type=pindaan`
    },
    lamUrl(){
      return `/pemaju/setup/pengarah-download-lam/${this.maklumat.pengarah_peraku_akaun_berkanun_id}?type=pindaan`
    },
  },

  methods: {
    showModal() {
      this.showModalFlag = true;
      },
    hideModal() {
      this.showModalFlag = false;
      },
    // goToView1() {
    //   this.hideModal();
    //   window.location.href = `/pemaju/setup/pengarah-download/${this.maklumat.pengarah_peraku_akaun_berkanun_id}?type=pindaan`;
    //   },
    // goToView2() {
    //   this.hideModal();
    //   window.location.href = `/pemaju/setup/pengarah-download-lam/${this.maklumat.pengarah_peraku_akaun_berkanun_id}?type=pindaan`;
    //   },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/bahagian-d`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)

          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  }
}
</script>

<style scoped>
    .modal {
      display: block; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 1; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0,0,0); /* Fallback color */
      background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    }

    .modal-dialog {
      position: relative;
      margin: auto;
      padding: 10px;
      border: 0px solid #888;
      width: 80%; /* Could be more or less, depending on screen size */
    }

    .modal-content {
      background-color: #fefefe;
      margin: 15% auto; /* 15% from the top and centered */
      padding: 20px;
      border: 1px solid #888;
      width: 80%; /* Could be more or less, depending on screen size */
    }

    .button-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
      }

      .button-group .btn {
        flex: 1; /* Make buttons the same width */
        margin-bottom: 5px;
      }
</style>
