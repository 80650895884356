<template>
  <div class="card bg-light">
    <div class="card-body">
      <h4 class="card-title">SENARAI FIRMA DAN KONTRAKTOR</h4>

      <div class="card">
        <h4 class="card-header">FIRMA BUKAN TEKNIKAL</h4>
        <div class="table-responsive">
          <multi-list>
            <template v-slot:header>
              <tr>
                <th width="15%">Jenis Firma</th>
                <th width="15%">Nama Syarikat</th>
                <th width="21%">Alamat</th>
                <th width="12%">Telefon</th>
                <th width="12%">Faks</th>
                <th width="10%">Emel</th>
                <th v-if="!readonly"></th>
              </tr>

            </template>
            <template v-slot:default>
              <tr v-if="!default_perunding_bukan_teknikal_rs.length">
                <td colspan="7">
                  <div class="alert alert-warning alert-sm">Sila isi semua butiran dan tekan butang 'TAMBAH'</div>
                </td>
              </tr>
              <row-papar-butiran-firma
                v-else v-for="(row, index) in default_perunding_bukan_teknikal_rs" :key="`row-${index}`"
                :row="row"
                :readonly="readonly"
                :jenis-rs="jenisPerundingBukanTeknikalRs"
                :loading="processing"
                @update="({ form, callback }) => { kemaskiniPerundingBukanTeknikal(row, form,callback) }"
                @remove="removePerundingBukanTeknikal(index)"
              ></row-papar-butiran-firma>
              <row-tambah-butiran-firma
                v-if="!readonly"
                :jenis-rs="jenisPerundingBukanTeknikalRs"
                :loading="processing"
                @add="({ form, callback }) => { addPerundingBukanTeknikal(form,callback) }"
              ></row-tambah-butiran-firma>
            </template>
          </multi-list>
        </div>

        <h4 class="card-header">FIRMA PERUNDING</h4>
        <div class="table-responsive">
          <multi-list>
            <template v-slot:header>
              <tr>
                <th width="15%">Jenis Firma</th>
                <th width="15%">Nama Perunding</th>
                <th width="21%">Alamat</th>
                <th width="12%">Telefon</th>
                <th width="12%">Faks</th>
                <th width="10%">Emel</th>
                <th v-if="!readonly"></th>
              </tr>
            </template>
            <template>
              <tr v-if="!default_perunding_teknikal_rs.length">
                <td colspan="7">
                  <div class="alert alert-warning alert-sm">Sila isi semua butiran dan tekan butang 'TAMBAH'</div>
                </td>
              </tr>
              <row-papar-butiran-firma
                v-else v-for="(row, index) in default_perunding_teknikal_rs" :key="`row-teknikal-${index}`"
                :row="row"
                :readonly="readonly"
                :jenis-rs="jenisPerundingTeknikalRs"
                :loading="processing"
                @update="({ form, callback }) => { kemaskiniPerundingTeknikal(row, form,callback) }"
                @remove="removePerundingTeknikal(index)"
              ></row-papar-butiran-firma>
              <row-tambah-butiran-firma
                v-if="!readonly"
                :jenis-rs="jenisPerundingTeknikalRs"
                :loading="processing"
                @add="({ form, callback }) => { addPerundingTeknikal(form,callback) }"
              ></row-tambah-butiran-firma>
            </template>
          </multi-list>
        </div>

        <h4 class="card-header">KONTRAKTOR</h4>
        <div class="table-responsive">
          <multi-list>
            <template v-slot:header>
              <tr>
                <th width="15%">Jenis Firma</th>
                <th width="15%">Nama Kontraktor</th>
                <th width="21%">Alamat</th>
                <th width="12%">Telefon</th>
                <th width="12%">Faks</th>
                <th width="10%">Emel</th>
                <th v-if="!readonly"></th>
              </tr>
            </template>
            <template>
              <tr v-if="!default_perunding_kontraktor_rs.length">
                <td colspan="7">
                  <div class="alert alert-warning alert-sm">Sila isi semua butiran dan tekan butang 'TAMBAH'</div>
                </td>
              </tr>
              <row-papar-butiran-firma
                v-else v-for="(row, index) in default_perunding_kontraktor_rs" :key="`row-kontraktor-${index}`"
                :row="row"
                :readonly="readonly"
                :jenis-rs="jenisPerundingKontraktorRs"
                :loading="processing"
                @update="({ form, callback }) => { kemaskiniPerundingKontraktor(row, form,callback) }"
                @remove="removePerundingKontraktor(index)"
              ></row-papar-butiran-firma>
              <row-tambah-butiran-firma
                v-if="!readonly"
                :jenis-rs="jenisPerundingKontraktorRs"
                :loading="processing"
                @add="({ form, callback }) => { addPerundingKontraktor(form,callback) }"
              ></row-tambah-butiran-firma>
            </template>
          </multi-list>
        </div>
      </div>

    </div>
    <div class="card-footer">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
      </div>
    </div>
  </div>
</template>

<script>
  import RowTambahButiranFirma from "./components/RowTambahButiranFirma";
  import RowPaparButiranFirma from "./components/RowPaparButiranFirma";
  export default {
    name: "BahagianD",
    components: {RowPaparButiranFirma, RowTambahButiranFirma},
    props: {
      jenisPerundingTeknikalRs: Array,
      jenisPerundingBukanTeknikalRs: Array,
      jenisPerundingKontraktorRs: Array,
      maklumat: Object,

      default_perunding_teknikal_rs: {
        type: Array,
        default: () => ([]),
      },

      default_perunding_bukan_teknikal_rs: {
        type: Array,
        default: () => ([]),
      },

      default_perunding_kontraktor_rs: {
        type: Array,
        default: () => ([]),
      },

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        processing: false,
      }
    },

    methods: {
      save() {
        this.$success();
      },

      addPerundingTeknikal(form, callback) {
        if (this.processing) return;

        this.processing = true;

        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/TEKNIKAL`, form)
          .then(({data}) => {
            this.$emit('update:default_perunding_teknikal_rs', data);
            this.processing = false;

            callback();
          })
          .catch(() => {
            this.processing = false;
          })
          .then(() => {
          });
      },

      addPerundingBukanTeknikal(form, callback) {
        if (this.processing) return;

        this.processing = true;

        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/BUKANTEKNIKAL`, form)
          .then(({data}) => {
            this.$emit('update:default_perunding_bukan_teknikal_rs', data);
            this.processing = false;

            callback();
          })
          .catch(() => {
            this.processing = false;
          })
          .then(() => {
          });
      },

      addPerundingKontraktor(form, callback) {
        if (this.processing) return;

        this.processing = true;

        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/KONTRAKTOR`, form)
          .then(({data}) => {
            this.$emit('update:default_perunding_kontraktor_rs', data);
            this.processing = false;

            callback();
          })
          .catch(() => {
            this.processing = false;
          })
          .then(() => {
          });
      },

      kemaskiniPerundingTeknikal(perunding, form, callback) {
        if (this.processing) return;

        this.processing = true;

        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/${perunding.id}/TEKNIKAL`, form)
          .then(({data}) => {
            this.$emit('update:default_perunding_teknikal_rs', data);
            this.processing = false;

            callback();
          })
          .catch(() => {
            this.processing = false;
          })
          .then(() => {
          });
      },

      kemaskiniPerundingBukanTeknikal(perunding, form, callback) {
        if (this.processing) return;

        this.processing = true;

        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/${perunding.id}/BUKANTEKNIKAL`, form)
          .then(({data}) => {
            this.$emit('update:default_perunding_bukan_teknikal_rs', data);
            this.processing = false;

            callback();
          })
          .catch(() => {
            this.processing = false;
          })
          .then(() => {
          });
      },

      kemaskiniPerundingKontraktor(perunding, form, callback) {
        if (this.processing) return;

        this.processing = true;

        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/${perunding.id}/KONTRAKTOR`, form)
          .then(({data}) => {
            this.$emit('update:default_perunding_kontraktor_rs', data);
            this.processing = false;

            callback();
          })
          .catch(() => {
            this.processing = false;
          })
          .then(() => {
          });
      },

      removePerundingBukanTeknikal(index) {
        if (this.processing) return;

        swalconfirm()
          .then(result => {
            if (result.value) {
              this.processing = true;

              const perunding = this.default_perunding_bukan_teknikal_rs[index];

              axios.delete(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/${perunding.id}`)
                .then(() => {
                  const perunding_rs = JSON.parse(JSON.stringify(this.default_perunding_bukan_teknikal_rs))
                  perunding_rs.splice(index, 1);

                  this.$emit('update:default_perunding_bukan_teknikal_rs', perunding_rs);

                  this.processing = false;
                })
                .catch(() => {
                  this.processing = false;
                })
                .then(() => {
                });
            }
          });
      },

      removePerundingTeknikal(index) {
        if (this.processing) return;

        swalconfirm()
          .then(result => {
            if (result.value) {
              this.processing = true;

              const perunding = this.default_perunding_teknikal_rs[index];

              axios.delete(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/${perunding.id}`)
                .then(() => {
                  const perunding_rs = JSON.parse(JSON.stringify(this.default_perunding_teknikal_rs))
                  perunding_rs.splice(index, 1);

                  this.$emit('update:default_perunding_teknikal_rs', perunding_rs);

                  this.processing = false;
                })
                .catch(() => {
                  this.processing = false;
                })
                .then(() => {
                });
            }
          });
      },

      removePerundingKontraktor(index) {
        if (this.processing) return;

        swalconfirm()
          .then(result => {
            this.processing = true;

            const perunding = this.default_perunding_kontraktor_rs[index];

            axios.delete(`/pemaju/laporan-7f/${this.maklumat.id}/perunding/${perunding.id}`)
              .then(() => {
                const perunding_rs = JSON.parse(JSON.stringify(this.default_perunding_kontraktor_rs))
                perunding_rs.splice(index, 1);

                this.$emit('update:default_perunding_kontraktor_rs', perunding_rs);
                this.processing = false;
              })
              .catch(() => {
                this.processing = false;
              })
              .then(() => {
              });
          });
      },
    }
  }
</script>

<style scoped>

</style>
