<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact">
        <fieldset role="group" class="form-group">
          <div class="form-row">
            <label class="col-sm-2 col-form-label">PILIHAN PENGESAHAN 1</label>
            <div role="group" class="col-sm-10">
              <div role="radiogroup" tabindex="-1" class="">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" :checked="form.pengesahan_kuotabumi == 'Y'"
                         @change="form.pengesahan_kuotabumi = 'Y'" id="pilihan_pengesahan1" name="pilihan_pengesahan"
                         class="custom-control-input">
                  <label class="custom-control-label" for="pilihan_pengesahan1">Saya / Kami <strong><u>BERSUMPAH DAN
                    MENGAKU</u></strong> bahawa sepanjang pengetahuan dan kepercayaan saya / kami, projek ini
                    telah disahkan <strong><u>MEMPUNYAI KUOTA BUMIPUTERA</u></strong> yang diperuntukkan dan diluluskan
                    oleh pihak berkuasa</label>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div class="sub-margin">
              <div class="form-group form-row">
              <label class="offset-sm-2 col-sm-2 col-form-label">Peruntukan Kuota Bumi</label>
              <div class="col-sm-8">
                <div class="form-check form-check-inline">
                    <input id="pilihan_kuotabumi_peratus" type="radio" :disabled="form.pengesahan_kuotabumi != 'Y'" :checked="form.pilihan_kuotabumi == 0"
                        @change="form.pilihan_kuotabumi = 0" class="form-check-input">
                    <label for="pilihan_kuotabumi_peratus" class="form-check-label">Peratus</label>
                </div>
                  <div class="form-check form-check-inline">
                    <input id="pilihan_kuotabumi_bilangan" type="radio" :disabled="form.pengesahan_kuotabumi != 'Y'" :checked="form.pilihan_kuotabumi == 1"
                        @change="form.pilihan_kuotabumi = 1" class="form-check-input">
                    <label for="pilihan_kuotabumi_bilangan" class="form-check-label">Bilangan</label>
                </div>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="offset-sm-2 col-sm-2 col-form-label">Nilai</label>
              <div class="col-sm-8 form-inline">
                <input type="text" :disabled="form.pengesahan_kuotabumi != 'Y' || form.pilihan_kuotabumi != 0" v-model="form.peratus_kuotabumi"
                       class="form-control" style="width: 150px">&nbsp;&nbsp;%&nbsp;&nbsp;
                <input type="text" :disabled="form.pengesahan_kuotabumi != 'Y' || form.pilihan_kuotabumi != 1" v-model="form.bilangan_kuotabumi"
                class="form-control" style="width: 150px">&nbsp;&nbsp;Unit
              </div>
            </div>
            <div class="form-group form-row">
              <label class="offset-sm-2 col-sm-2 col-form-label">Peratusan Harga Diskaun Bumiputera (Minima)</label>
              <div class="col-sm-8 form-inline">
                <input type="text" :disabled="form.pengesahan_kuotabumi != 'Y'" v-model="form.peratus_diskaun_kuotabumi"
                       class="form-control" style="width: 150px">&nbsp;&nbsp;%
              </div>
            </div>
          </div>
        </fieldset>
        <br>
        <fieldset role="group" class="form-group">
          <div class="form-row">
            <label class="col-sm-2 col-form-label">PILIHAN PENGESAHAN 2</label>
            <div role="group" class="col-sm-10">
              <div role="radiogroup" tabindex="-1" class="">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" :checked="form.pengesahan_kuotabumi == 'N'"
                         @change="form.pengesahan_kuotabumi = 'N'" id="pilihan_pengesahan2" name="pilihan_pengesahan"
                         class="custom-control-input">
                  <label class="custom-control-label" for="pilihan_pengesahan2">Saya / Kami <strong><u>BERSUMPAH DAN
                    MENGAKU</u></strong> bahawa sepanjang pengetahuan dan kepercayaan saya / kami, projek ini
                    telah disahkan <strong><u>TANAH RIZAB MELAYU</u></strong> yang diperuntukkan dan diluluskan oleh
                    pihak berkuasa</label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <br>
        <fieldset role="group" class="form-group">
          <div class="form-row">
            <label class="col-sm-2 col-form-label">PILIHAN PENGESAHAN 3</label>
            <div role="group" class="col-sm-10">
              <div role="radiogroup" tabindex="-1" class="">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" :checked="form.pengesahan_kuotabumi == 'N2'"
                         @change="form.pengesahan_kuotabumi = 'N2'" id="pilihan_pengesahan3" name="pilihan_pengesahan"
                         class="custom-control-input">
                  <label class="custom-control-label" for="pilihan_pengesahan3">Saya / Kami <strong><u>BERSUMPAH DAN
                    MENGAKU</u></strong> bahawa sepanjang pengetahuan dan kepercayaan saya / kami, projek ini
                    telah disahkan <strong><u>TANAH RIZAB MELAKA (MCL)</u></strong> yang diperuntukkan dan diluluskan
                    oleh pihak berkuasa</label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <br>
        <fieldset role="group" class="form-group">
          <div class="form-row">
            <label class="col-sm-2 col-form-label">PILIHAN PENGESAHAN 4</label>
            <div role="group" class="col-sm-10">
              <div role="radiogroup" tabindex="-1" class="">
                <div class="custom-control custom-radio custom-control-inline">
                  <input type="radio" :checked="form.pengesahan_kuotabumi == 'N3'"
                         @change="form.pengesahan_kuotabumi = 'N3'" id="pilihan_pengesahan4" name="pilihan_pengesahan"
                         class="custom-control-input">
                  <label class="custom-control-label" for="pilihan_pengesahan4">Saya / Kami <strong><u>BERSUMPAH DAN
                    MENGAKU</u></strong> bahawa sepanjang pengetahuan dan kepercayaan saya / kami, projek ini
                    telah disahkan <strong><u>TIADA KUOTA BUMIPUTERA</u></strong> yang diperuntukkan dan diluluskan oleh
                    pihak berkuasa</label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </section>
    </div>
    <div class="card-footer">
      <div class="text-center">
        <button class="btn btn-primary" @click.prevent="submit" :disabled="submitting">SIMPAN</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BahagianA",

    props: {
      pemajuan: Object,
    },

    data() {
      return {
        submitting: false,
        form: JSON.parse(JSON.stringify(this.pemajuan)),
      };
    },

    methods: {
      submit() {
        if (this.form.pengesahan_kuotabumi != 'Y' && this.totalUnitKuotaBumi > 0) {
          swal.fire(
            'Harap maaf!',
            'Pemajuan memiliki no lot / unit berkuota bumi. Sila kemaskini unit kuota bumi sebelum menukar pengesahan kuota bumi.',
          )
          return;
        }
        this.submitting = true;

        axios.post(`/pemaju/kuota-bumi/${this.pemajuan.id}/bahagian-a`, this.form)
          .then((response) => {
            this.$emit('update:pemajuan', JSON.parse(JSON.stringify(response.data)))
            this.$emit('done')
          })
          .catch(error => {

          })
          .then(() => {
            this.submitting = false;
          });

      },

      getTotalKuotaBumi(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.kuota_bumi) {
            return total + 1;
          }

          return total;
        }, 0);
      }
    },
    computed: {
      totalUnitKuotaBumi() {
        return this.form.pembangunan_rs.reduce((total, item) => total + this.getTotalKuotaBumi(item), 0);
      },
       totalUnit() {
        return this.form.pembangunan_rs.reduce((total, item) => {
          return total + parseInt(item.maklumat7f.bil_dilulus || item.bil_unit, 10);
        }, 0);
      }
    },
    watch: {
      "form.peratus_kuotabumi": function (value) {
       if (this.form.pilihan_kuotabumi == 0){
          const percent = value || 0;
          const amount =  Math.ceil(this.totalUnit * percent / 100);
          this.form.bilangan_kuotabumi = amount;
       }
      },
      "form.bilangan_kuotabumi": function (value) {
       if (this.form.pilihan_kuotabumi == 1){
          const amount = value || 0;
          const percent =  (amount * 100 / this.totalUnit).toFixed(2);
          this.form.peratus_kuotabumi = percent;
       }
      }
    }
  }
</script>
