<template>
  <div v-if="Object.keys(messages).length" class="error-bar">
    <button @click.prevent="close" type="button" aria-label="Close" class="close"><span data-v-dfa9d76c="" aria-hidden="true">×</span></button>
    <div v-for="section in Object.keys(messages)" class="error-section">
      <h5 class="error-title">{{ printSection(section) }}</h5>
      <ul>
        <li v-for="err in messages[section]">{{ printErr(err) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "ErrorBar",
  props: {
    messages: Object,
  },
  methods: {
    close() {
      this.$emit('close');
    },

    printSection(section) {
      return section.split('-').join(' ').toUpperCase();
    },

    printErr(err) {
      return err.length ? err[0] : err;
    }
  }
}
</script>

<style lang="scss" scoped>
.error-bar {
  position: relative;
  border: 1px solid #bf0011;
  margin-bottom: 1rem;

  border-left: 0.5rem solid #bf0011;
  padding: 0.8rem 1rem;

  .error-title {
    color: #bf0011;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .error-section + .error-section {
    margin-top: 1.25rem;
  }
}
</style>
