<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <section class="form-compact row">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Bil.</th>
              <th>No. Geran</th>
              <th>No. Lot</th>
              <th>Keluasan</th>
              <th width="1%" v-if="canEditTanah">
                <button class="btn btn-success" style="width: 1.5rem; height: 1.5rem; padding: 0;" @click.prevent="add"><i class="fas fa-plus"></i></button>
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-if="tanah_rs.length">
              <tr v-for="(tanah, index) in tanah_rs" :key="`tanah-${index}`" :class="{ 'bg-danger': tanah.dipadam_pada }">
                <td>{{ index + 1 }}</td>
                <td>{{ tanah.no_geran }}</td>
                <td>{{ tanah.no_lot_pemajuan }}</td>
                <td>{{ tanah.luas_tanah }}</td>
                <td v-if="canEditTanah" nowrap>
                  <button v-if="tanah.dipadam_pada" class="btn btn-icon text-warning" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="enable(index)" title="Aktif Semula"><i class="fas fa-check"></i></button>
                  <template v-else>
                    <button class="btn btn-icon text-primary" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="update(index)" title="Kemaskini"><i
                      class="fas fa-edit"></i></button>

                    <button class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="del(index)" title="Hapus"><i
                      class="fas fa-trash"></i></button>
                  </template>

                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <tambah-butiran-tanah-modal
      :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
      :kod-status-tanah-rs="kodStatusTanahRs"
      :kod-syarat-nyata-rs="kodSyaratNyataRs"
      :kod-bebanan-tanah-rs="kodBebananTanahRs"
    ></tambah-butiran-tanah-modal>
    <tuan-tanah-modal
      :kod-negeri-rs="kodNegeriRs"
      :kod-daerah-rs="kodDaerahRs"
      :kod-bandar-rs="kodBandarRs"
    ></tuan-tanah-modal>
    <gadaian-tanah-modal
      :kod-negeri-rs="kodNegeriRs"
      :kod-daerah-rs="kodDaerahRs"
      :kod-bandar-rs="kodBandarRs"
    ></gadaian-tanah-modal>
    <kaveat-tanah-modal></kaveat-tanah-modal>
  </div>
</template>

<script>
import TambahButiranTanahModal from "../../common/Tanah/TambahButiranTanahModal";
import TuanTanahModal from "../../common/Tanah/TuanTanahModal";
import GadaianTanahModal from "../../common/Tanah/GadaianTanahModal";
import KaveatTanahModal from "../../common/Tanah/KaveatTanahModal";

export default {
  name: "BahagianB1",
  components: {KaveatTanahModal, GadaianTanahModal, TuanTanahModal, TambahButiranTanahModal},
  props: {
    maklumat: Object,
    tanah_rs: Array,
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    kuiri: null,
  },

  methods: {
    add() {
      if (!this.canEditTanah) return;

      vmodal.open('tambahbutirantanah', {}, (tanah) => {
        if (this.loading) {
          return;
        }

        this.loading = true;

        axios.post(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-b1`, tanah)
          .then((response) => {
            this.$emit('update', response.data);
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      })
    },

    update(index) {
      if (!this.canEditTanah) return;

      const editing_tanah = JSON.parse(JSON.stringify(this.tanah_rs[index]));

      vmodal.open('tambahbutirantanah', editing_tanah, (tanah) => {
        if (this.loading) {
          return;
        }

        this.loading = true;

        axios.put(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-b1/${editing_tanah.id}`, tanah)
          .then((response) => {
            this.$emit('update', response.data);
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      })
    },

    async enable(index) {
      if (!this.canEditTanah) return;

      const editing_tanah = JSON.parse(JSON.stringify(this.tanah_rs[index]));

      delete editing_tanah.dipadam_pada;

      axios.put(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-b1/${editing_tanah.id}`, editing_tanah)
        .then((response) => {
          this.$emit('update', response.data);
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        });
    },

    async del(index) {
      if (!this.canEditTanah) return;

      if (this.loading) {
        return;
      }

      const result = await swalconfirm()

      if (result.value) {
        this.loading = true;

        const tuan_tanah = this.tanah_rs[index];

        axios.delete(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-b1/${tuan_tanah.id}`)
          .then((response) => {
            this.$emit('update', response.data);
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      }
    },
  },

  computed: {
    canEditTanah() {
      return this.maklumat.pindaan_pelbagai_geran;
    }
  }
}
</script>

<style scoped>

</style>
