<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="alert alert-warning" v-if="kuiri">
          <strong>Catatan Kuiri:</strong>
          <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
        </div>

        <div class="col-lg-12">
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Nama Pemajuan <span class="label-required">*</span></label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.nama" @input="form.nama = $event.target.value.toUpperCase()" :disabled="!canEditName">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Nama Fasa (Jika Ada)</label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.nama_fasa" @input="form.nama_fasa = $event.target.value.toUpperCase()" :disabled="!canEditName">
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Jenis Pemajuan <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="jenis_pemajuan in kodJenisPemajuanRs" :key="`jenis_pemajuan_${jenis_pemajuan.id}`">
                <input type="radio" class="form-check-input" v-model="form.kod_jenis_pemajuan_id" name="kod_jenis_pemajuan_id"
                       :id="`jenis_pemajuan_${jenis_pemajuan.id}`" :value="jenis_pemajuan.id" disabled>
                <label class="form-check-label" :for="`jenis_pemajuan_${jenis_pemajuan.id}`">{{ jenis_pemajuan.keterangan }}</label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Kaedah Pemajuan <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="kaedah_pemajuan in kodKaedahPemajuanRs" :key="`kaedah_pemajuan_${kaedah_pemajuan.id}`">
                <input type="radio" class="form-check-input" v-model="form.kod_kaedah_pemajuan_id" name="kod_kaedah_pemajuan_id"
                       :id="`kaedah_pemajuan_${kaedah_pemajuan.id}`" :value="kaedah_pemajuan.id" disabled>
                <label class="form-check-label" :for="`kaedah_pemajuan_${kaedah_pemajuan.id}`">{{ kaedah_pemajuan.singkatan }}</label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Program Kemajuan <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline">
                <input
                  :id="`skim_projek_tiada`"
                  type="radio" name="kod_skim_projek"
                  class="form-check-input"
                  v-model="form.kod_skim_projek_id" :value="null"
                  disabled
                >
                <label class="form-check-label" :for="`skim_projek_skim_projek_tiada`">TIADA</label>
              </div>
              <div class="form-check form-check-inline" v-for="skim_projek in kodSkimProjekRs" :key="`skim_projek_${skim_projek.id}`">
                <input
                  :id="`skim_projek_${skim_projek.id}`"
                  type="radio" name="kod_skim_projek"
                  class="form-check-input"
                  v-model="form.kod_skim_projek_id" :value="skim_projek.id"
                  disabled
                >
                <label class="form-check-label" :for="`skim_projek_${skim_projek.id}`">{{ skim_projek.keterangan }}</label>
              </div>
            </div>
          </div>

          <br><br>
          <table>
            <tbody>
            <tr v-for="kaedah_pemajuan in kodKaedahPemajuanRs" :key="`table-kaedah_pemajuan_${kaedah_pemajuan.id}`">
              <td width="100px">{{ kaedah_pemajuan.singkatan }}</td>
              <td width="20px">-</td>
              <td>{{ kaedah_pemajuan.keterangan }}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </section>
    </div>
    <div class="card-footer" v-if="canSave">
      <div class="text-right">
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianC",
  props: {
    maklumat: Object,
    kodJenisPemajuanRs: Array,
    kodKaedahPemajuanRs: Array,
    kodSkimProjekRs: Array,

    kuiri: null,
  },

  data() {
    return {
      loading: false,

      form: {
        nama: this.maklumat.nama,
        nama_fasa: this.maklumat.nama_fasa,
        kod_jenis_pemajuan_id: this.maklumat.kod_jenis_pemajuan_id,
        kod_kaedah_pemajuan_id: this.maklumat.kod_kaedah_pemajuan_id,
        kod_skim_projek_id: this.maklumat.kod_skim_projek_id,
      },
    }
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-c`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
  },

  computed: {
    canEditName() {
      return !!this.maklumat.pindaan_pelbagai_nama_pemajuan;
    },

    canSave() {
      return this.canEditName;
    }
  }
}
</script>

<style scoped>

</style>
