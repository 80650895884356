<template>
  <div class="fileupload">
    <ul class="list-group">
        <li class="list-group-item" v-for="doc in docs" :key="`doc-${doc.id}`">
          <a :href="doc.url" target="_blank" :title="doc.nama_asal">
            <i class="fas fa-file-pdf mr-2" /> {{ doc.nama_asal }}
          </a>
          <button v-if="!readonly" class="text-dark ml-2" @click.prevent="remove(doc)" type="button" tabindex="-1"><i class="fas fa-window-close"></i></button>
        </li>
        <li v-if="!readonly" class="list-group-item">
          <div v-if="currentFile" class="d-flex file-container align-items-center">
            <div class="text-truncate" v-if="currentFile">
              uploading..
            </div>
          </div>
          <div v-if="!currentFile" class="file-container text-truncate cursor-pointer" @click.prevent="$refs.input.click()">
            <i class="upload-icon fas fa-upload mr-2" /> Muat Naik
          </div>

          <input
            v-bind="$attrs"
            type="file"
            :name="name"
            :accept="acceptType"
            class="input-file"
            ref="input"
            @change="filesChange($event.target.name, $event.target.files)"
          >
        </li>
    </ul>
  </div>

</template>

<script>
  export default {
    inheritAttrs: false,

    $_veeValidate: {
      value () {
        if (this.docs.length) {
          return this.docs;
        }

        return this.currentFile;
      },
    },

    props: {
      docs: Array,
      name: String,

      acceptType: {
        type: String,
        default: 'application/pdf',
      },

      readonly: {
        type: Boolean,
        default: false,
      },
    },



    data() {
      return {
        currentFile: null,
      };
    },
    methods: {
      filesChange(name, files) {
        this.$emit('upload', { files, done: this.clear });
      },

      clear() {
        this.currentFile = '';
        this.$refs.input.value = '';
      },

      remove(doc) {
        this.$emit('remove', doc);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fileupload {
    position: relative;
    width: 100px;
    padding: 0 !important;
    height: auto;

    &.has-file {
      background: #BA68C8;
      color: white !important;
    }

    &.is-invalid {
      color: red;

      .upload-icon {
        display: none;
      }
    }

    .list-group-item {
      padding: 0.56rem 0.75rem;
    }


  }
  .fa-window-close {
    color: #f44747;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 0;
    height: 0;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
  }
  button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: sans-serif;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
</style>
