<template>
  <div>
    <slot :setCurrent="setCurrent" :currentActive="currentActive"></slot>
  </div>
</template>

<script>

export default {
  name: "SimpleNav",

  data() {
    return {
      currentActive: 0,
    }
  },

  methods: {
    setCurrent(current) {
      this.currentActive = current;
    }
  }
}
</script>

<style scoped>

</style>
