<template>
  <div class="card-body">
    <div class="mb-4 text-justify">
      Menurut peruntukan-peruntukan Akta Pemajuan Perumahan (Kawalan dan Pelesenan) 1996 (Akta 118) & Peraturan-Peraturan dan Akta Akuan Berkanun 1960 (Akta
      113) tuan adalah dengan ini dikehendaki mengemukakan dalam borang ini suatu penyataan mengenai kemajuan projek terkini bermula dari tarikh lesen diperolehi bagi
      sesuatu kemajuan projek perumahan yang dimajukan di Semenanjung Malaysia, dan memberi butir-butir berkaitan yang diminta dalam borang ini. Jika tuan gagal
      membuat laporan kemajuan dalam masa yang ditetapkan atau membuat kenyataan palsu tentang mana-mana butir, atau gagal memberitahu pertukaran alamat, tuan
      boleh didakwa atau dikenakan penalti menurut Seksyen 19 Akta Pemajuan Perumahan (Kawalan dan Pelesenan) 1966 (Akta 118) dan Peraturan-Peraturan serta
      Seksyen 119 dan Seksyen 200 Kanun Keseksaan (Akta 574) dan Akta Akuan Berkanun 1960 (Akta 113).
    </div>
    <div class="form-check" v-if="!readonly">

      <label for="perakuan" class="form-check-label">
        <input type="checkbox" value="" id="perakuan" class="form-check-input" v-model="form.perakaun">
        <div class="col text-justify">
          Saya, <span style="text-transform: uppercase; font-weight: bold">{{user.name}}</span>, No. Kad Pengenalan,
          <span style="text-transform: uppercase; font-weight: bold">({{user.no_kp}})</span> bersumpah dan mengaku bahawa sepanjang pengetahuan
          dan kepercayaan saya / kami, butir–butir yang diberikan di dalam perakuan ini adalah benar dan lengkap dan
          faham bahawa apa–apa salah penyataan butir–butir yang dikemukakan adalah menjadi satu kesalahan di bawah
          Seksyen 7(f) Akta Pemajuan Perumahan (Kawalan dan Pelesenan) 1966 dan Akta Akuan Berkanun 1960.
        </div>
      </label>
    </div>
    <br>
    <br>
    <div>
      Sila klik butang 'HANTAR' sekiranya maklumat telah dikemaskini dengan betul dan lengkap.
      <br><br>
      Sila simpan No. Rujukan : (H01-XXXXXX-XXXXXXXX)
    </div>

    <div class="text-center mt-4">
      <button class="btn btn-primary" v-if="!readonly" @click="$event => $emit('submit', $event)" :disabled="!form.perakaun">HANTAR</button>
      <a target="_blank" :href="`/pemaju/laporan-7f/${maklumat.id}/pdf`" class="btn btn-secondary">PREVIEW</a>
    </div>
  </div>
</template>

<script>
    export default {
      name: "BahagianF",

      props: {
        maklumat: Object,
        user: Object,
        readonly: {
          type: Boolean,
          default: false,
        }
      },
      data() {
        return {
          form: {
            perakaun: false,
          }
        }
      },
    }
</script>

<style scoped>

</style>
