<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">No Unit / Lot {{ unit.no_unit }} - (Saksi)</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <section>
              <h4 class="card-title">MAKLUMAT UNIT</h4>
              <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Lot PT :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_lot_pt }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Unit :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_unit }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Harga Jualan Yang Diluluskan :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">
                            {{ unit.harga_jualan | currency("RM", ",", 2, ".", "front", false) }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Pembeli Pertama :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ isFirstBuyer ? "Ya" : "Tidak" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="d-flex justify-content-between my-3">
                <h4 class="card-title">MAKLUMAT CALON PEMBELI</h4>
                <div v-if="pembeliNoSaksi.length > 0">
                  <button type="button" class="btn btn-primary" id="btnTambahPembeli"
                          @click.prevent="bukaBorang">Tambah Saksi
                  </button>
                </div>
                <div v-else>
                  <button type="button" class="btn btn-primary" style="cursor: not-allowed;opacity: 0.65"
                          title="Tidak dibenarkan untuk menambah calon pembeli Saksi">
                    Tambah Saksi
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center" v-if="loading" style="min-height: 100px;">
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block; shape-rendering: auto;"
                     width="50px" height="50px" viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" r="6.57689" fill="none" stroke="#3887b4" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                  </circle>
                  <circle cx="50" cy="50" r="16.4808" fill="none" stroke="#5699d2" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline"></animate>
                  </circle>
                </svg>
              </div>
              <div v-else>
                <div class="table-responsive">
                  <table class="table table-striped ">
                    <thead class="bg-warning text-white">
                    <tr>
                      <th>Kategori Pembeli</th>
                      <th>Nama / Syarikat</th>
                      <th>No. KP / Passport / SSM</th>
                      <th>Negara</th>
                      <th nowrap width="1%">Status</th>
                      <th class="text-center">Penama Pertama</th>
                      <th class="text-center">Saksi</th>
                      <th class="text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="pembeli_rs.length">
                      <tr v-for="(row, index) in pembeli_rs" :key="`pembeli-${index}`">
                        <td>
                          {{
                            optional(kodJenisPemilikanRs.find(item => row.kod_jenis_pemilikan_id == item.id)).keterangan
                          }}
                        </td>
                        <td>{{ row.nama_pembeli }}</td>
                        <td>{{ row.no_kp }}</td>
                        <td style="text-transform: uppercase;">{{ optional(kodNegaraRs.find(item => row.kod_negara_id == item.id)).keterangan }}</td>
                        <td nowrap>{{ row.status_pembeli }}</td>
                        <td nowrap class="text-center">
                          <span v-if="row.no_kp === unit.no_kp">{{ "&#x2714;" }}</span>
                        </td>
                        <td nowrap class="text-center">
                          <span v-if="row.saksi">Ada</span>
                          <span v-else>Tiada</span>
                        </td>
                        <td nowrap class="text-center">
                          <button class="btn btn-icon btn-sm d-inline" data-toggle="tooltip"
                                    title="Kemaskini Maklumat Calon Pembeli Peguam" @click.prevent="editSaksi(row.id)"><i
                          class="fas fa-edit"></i></button>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="99">
                        <div class="alert alert-warning">Tiada maklumat calon pembeli.</div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <section>
                  <div v-show="openBorang">
                    <div class="card mb-3">
                      <div class="card-header d-flex justify-content-between">
                        <h5 class="card-title">BORANG PENDAFTARAN SAKSI</h5>
                        <button type="button" class="close" @click.prevent="tutupBorang" aria-label="Close"
                                title="Tutup Borang">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-8">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Calon Pembeli <span
                                class="label-required">*</span></label>
                              <div class="col-md-8" v-if="action == 'Daftar'">
                                <select v-model="form.calon_pembeli_id" class="form-control"
                                  :class="{'is-invalid': errors.first('calon_pembeli_id')}"
                                  v-validate="'required'"
                                  name="calon_pembeli_id" data-vv-as="Calon Pembeli"
                                  :disabled="disabled == 1">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="pembeli in pembeliNoSaksi" :value="pembeli.id">
                                  {{ pembeli.nama_pembeli }} ({{ pembeli.no_kp }})
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("calon_pembeli_id") }}</span>
                              </div>
                              <div class="col-md-8" v-else>
                                <input v-model="form.nama_calon_pembeli"
                                  type="text" class="form-control mt-3"
                                  :class="{'is-invalid': errors.first('nama_calon_pembeli')}"
                                  name="nama_calon_pembeli"
                                  readonly
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="card-title">MAKLUMAT SAKSI PEMBELI</h5>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-8">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Nama <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.pembeli_nama"
                                  @input="form.pembeli_nama = $event.target.value.toUpperCase()" type="text"
                                  class="form-control" maxlength="255" required=""
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('pembeli_nama')}"
                                  name="pembeli_nama" data-vv-as="Nama Saksi Pembeli" :disabled="disabled == 1"
                                >
                                <span class="invalid-feedback">{{ errors.first("pembeli_nama") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No. Kad Pengenalan / Passport <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.pembeli_no_kp"
                                  @input="form.pembeli_no_kp = $event.target.value.toUpperCase()"
                                  type="text" class="form-control"
                                  v-validate="'required'"
                                  v-mask="'######-##-####'"
                                  :class="{'is-invalid': errors.first('pembeli_no_kp')}"
                                  name="pembeli_no_kp" data-vv-as="No. Kad Pengenalan / Passport Saksi Pembeli"
                                  :disabled="disabled == 1"
                                >
                                <span class="invalid-feedback">{{ errors.first("pembeli_no_kp") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No. Telefon Bimbit <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input 
                                  v-model="form.pembeli_no_telefon"
                                  type="number" class="form-control"
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('pembeli_no_telefon')}"
                                  name="pembeli_no_telefon" data-vv-as="No. Telefon Bimbit Saksi Pembeli"
                                  onKeyPress="if(this.value.length == 15) return false;"
                                  :disabled="disabled == 1"
                                >
                                <span class="invalid-feedback">{{ errors.first("pembeli_no_telefon") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Emel <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input 
                                  v-model="form.pembeli_emel"
                                  type="email" class="form-control"
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('pembeli_emel')}"
                                  name="pembeli_emel" data-vv-as="Emel Saksi Pembeli"
                                  :disabled="disabled == 1"
                                >
                                <span class="invalid-feedback">{{ errors.first("pembeli_emel") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="card-title">MAKLUMAT SAKSI PEMAJU</h5>
                        <p class="text-danger">Maklumat Saksi Pemaju perlu diisi pada Penama Pertama sahaja.</p>
                        <p v-show="listSaksi.length == 0" class="mb-3">Sila Klik <a href="/pemaju/home/profil#maklumatSaksi" target="_blank">Pendaftaran Maklumat Saksi</a>, sekiranya Nama Saksi tiada dalam pilihan</p>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-8">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Nama <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <!-- <input :value="form.pemaju_nama"
                                  @input="form.pemaju_nama = $event.target.value.toUpperCase()" type="text"
                                  class="form-control" maxlength="255" required=""
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('pemaju_nama')}"
                                  name="pemaju_nama" data-vv-as="Nama Saksi Pemaju" :disabled="disabled == 1"
                                > -->
                                <select v-model="form.pemaju_id" class="form-control"
                                  :class="{'is-invalid': errors.first('pemaju_id')}"
                                  name="pemaju_id" data-vv-as="Saksi Pemaju" :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                  @change="saksiChange('pemaju')">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="saksi in listSaksi" :value="saksi.id">
                                    {{ saksi.nama }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("pemaju_id") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No. Kad Pengenalan / Passport <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.pemaju_no_kp"
                                  @input="form.pemaju_no_kp = $event.target.value.toUpperCase()"
                                  type="text" class="form-control"
                                  :class="{'is-invalid': errors.first('pemaju_no_kp')}"
                                  v-validate="'required'"
                                  v-mask="'######-##-####'"
                                  name="pemaju_no_kp" data-vv-as="No. Kad Pengenalan / Passport Saksi Pemaju"
                                  :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                >
                                <span class="invalid-feedback">{{ errors.first("pemaju_no_kp") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No. Telefon Bimbit <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input 
                                  v-model="form.pemaju_no_telefon"
                                  type="number" class="form-control"
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('pemaju_no_telefon')}"
                                  name="pemaju_no_telefon" data-vv-as="No. Telefon Bimbit Saksi Pemaju"
                                  onKeyPress="if(this.value.length == 15) return false;"
                                  :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                >
                                <span class="invalid-feedback">{{ errors.first("pemaju_no_telefon") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Emel <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input 
                                  v-model="form.pemaju_emel"
                                  type="email" class="form-control"
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('pemaju_emel')}"
                                  name="pemaju_emel" data-vv-as="Emel Saksi Pemaju"
                                  :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                >
                                <span class="invalid-feedback">{{ errors.first("pemaju_emel") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="card-title">MAKLUMAT SAKSI TUAN TANAH</h5>
                        <p class="text-danger">Maklumat Saksi Tuan Tanah perlu diisi pada Penama Pertama sahaja.</p>
                        <p v-show="listSaksi.length == 0" class="mb-3">Sila Klik <a href="/pemaju/home/profil#maklumatSaksi" target="_blank">Pendaftaran Maklumat Saksi</a>, sekiranya Nama Saksi tiada dalam pilihan</p>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-8">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Nama <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <!-- <input :value="form.tuan_tanah_nama"
                                  @input="form.tuan_tanah_nama = $event.target.value.toUpperCase()" type="text"
                                  class="form-control" maxlength="255" required=""
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('tuan_tanah_nama')}"
                                  name="tuan_tanah_nama" data-vv-as="Nama Saksi Tuan Tanah" :disabled="disabled == 1"
                                > -->
                                <select v-model="form.tuan_tanah_id" class="form-control"
                                  :class="{'is-invalid': errors.first('tuan_tanah_id')}"
                                  name="tuan_tanah_id" data-vv-as="Saksi Tuan Tanah" :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                  @change="saksiChange('tuan_tanah')">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="saksi in listSaksi" :value="saksi.id">
                                    {{ saksi.nama }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("tuan_tanah_id") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No. Kad Pengenalan / Passport <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.tuan_tanah_no_kp"
                                  @input="form.tuan_tanah_no_kp = $event.target.value.toUpperCase()"
                                  type="text" class="form-control"
                                  v-validate="'required'"
                                  v-mask="'######-##-####'"
                                  :class="{'is-invalid': errors.first('tuan_tanah_no_kp')}"
                                  name="tuan_tanah_no_kp" data-vv-as="No. Kad Pengenalan / Passport Saksi Tuan Tanah"
                                  :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                >
                                <span class="invalid-feedback">{{ errors.first("tuan_tanah_no_kp") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No. Telefon Bimbit <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input 
                                  v-model="form.tuan_tanah_no_telefon"
                                  type="number" class="form-control"
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('tuan_tanah_no_telefon')}"
                                  name="tuan_tanah_no_telefon" data-vv-as="No. Telefon Bimbit Saksi Tuan Tanah"
                                  onKeyPress="if(this.value.length == 15) return false;"
                                  :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                >
                                <span class="invalid-feedback">{{ errors.first("tuan_tanah_no_telefon") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Emel <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input 
                                  v-model="form.tuan_tanah_emel"
                                  type="email" class="form-control"
                                  v-validate="'required'"
                                  :class="{'is-invalid': errors.first('tuan_tanah_emel')}"
                                  name="tuan_tanah_emel" data-vv-as="Emel Saksi Tuan Tanah"
                                  :disabled="disabled == 1 || disabledForm || !isPenamaPertama(pembeli_no_kp_edit)"
                                >
                                <span class="invalid-feedback">{{ errors.first("tuan_tanah_emel") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div class="card-footer text-right">
                        <button class="btn btn-secondary" type="button" @click.prevent="tutupBorang">Tutup</button>
                        <button class="btn btn-success" type="button" @click.prevent="daftar" :disabled="saving"
                                v-if="action == 'Daftar'">Daftar
                        </button>
                        <button class="btn btn-success" type="button" @click.prevent="updateSaksi(pembeli_id)"
                                v-else-if="action == 'Kemaskini'">Kemaskini
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: [ "$validator" ],
  name: "PembeliSaksiModal",

  mounted() {
    vmodal.register(this.modalId, { open: this.onOpen, close: this.onClose });
  },

  props: {
    pemajuan: Object,
    kodKaumRs: Array,
    kodNegaraRs: Array,
    kodJenisPemilikanRs: {
      type: Array,
      default: () => ([
        { id: "I", keterangan: "INDIVIDU" },
        { id: "ST", keterangan: "SYARIKAT TEMPATAN" },
        { id: "SA", keterangan: "SYARIKAT ASING" },
      ]),
    },
    saksiRs: Array,
  },

  data() {
    return {
      showInisiatifKerajaanError: false,
      modalId: "pembeli_saksi",
      loading: false,
      saving: false,
      unit: {},
      pembeli_id: null,
      pembeli_rs: [],
      form: {},
      unit_pertama: null,
      errorTimeout: null,
      response: () => {
      },
      openBorang: true,
      openSPJB: false,
      action: "Daftar",
      disabled: 0,
      pembeliNoSaksi: [],
      listSaksi: this.saksiRs || [],
      pembeli_no_kp_edit: null
    };
  },

  methods: {
    onOpen(data, callback) {
      this.unit = JSON.parse(JSON.stringify(data.unit));
      this.unit_pertama = data.unit_pertama;
      this.resetForm();

      this.loading = true;
      this.response = callback;
      this.openBorang = false;

      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}`)
        .then((response) => {
          this.pembeli_rs = response.data.calon;
          this.pembeliNoSaksi = response.data.calon.filter((item) => item.saksi == null) || [];
          if (this.pembeli_rs.length > 0) {
            if (this.pembeli_rs[ 0 ][ "status_pembeli" ] == "PEMBELI") {
              this.openSPJB = true;
              this.action = null;
            } else {
              this.openSPJB = false;
              this.action = "Daftar";
            }
          } else {
            this.openSPJB = false;
            this.action = "Daftar";
          }

        })
        .catch((e) => {
          console.log(e);
        })
        .then(() => {
          this.loading = false;
        });


    },

    onClose() {
    },

    bukaBorang() {
      this.action = "Daftar";
      this.resetForm();
      return this.openBorang = true;
    },

    tutupBorang() {
      if (this.openSPJB) {
        this.action = null;
      } else {
        this.action = "Daftar";
      }

      return this.openBorang = false;
    },

    daftar() {
      this.$validator.validateAll()
        .then((passes) => {
          if(!passes) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
              "warning",
            );
            return;
          } 
          this.saving = true;
          axios.put(`/pemaju/kuota-bumi/pembeli/${this.form.calon_pembeli_id}/updateSaksi`, this.form)
            .then(({ data }) => {
              this.pembeli_rs = data.pembeli_rs;
              this.pembeliNoSaksi = data.pembeli_rs.filter((item) => item.saksi == null) || [];
              this.response(data.unit);
              this.openBorang = false;
              this.listSaksi = data.saksi_rs;
              swal.fire(
                "Berjaya!",
                "Maklumat calon pembeli berjaya didaftar.",
                "success",
              );
            })
            .catch(function (error) {
              console.log(error.response.data.errors);
              swal.fire(
                "Tidak Berjaya!",
                "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        });
    },

    editSaksi(pembeli_id) {
      this.openBorang = true;
      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/${pembeli_id}/show`)
        .then(({ data }) => {
          this.setFormSaksi(data)

          this.disabled = 0;
          this.action = "Kemaskini";
        })
        .catch((e) => {
          console.log(e);
        })
        .then(() => {
          this.loading = false;
        });
      this.openBorang = true;
    },

    updateSaksi(pembeli_id) {
      this.$validator.validateAll()
        .then(passes => {
          if(!passes) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
              "warning",
            );
            return;
          } 
          this.saving = true;
          axios.put(`/pemaju/kuota-bumi/pembeli/${pembeli_id}/updateSaksi`, this.form)
            .then(({ data }) => {
              this.pembeli_rs = data.pembeli_rs;
              this.pembeliNoSaksi = data.pembeli_rs.filter((item) => item.saksi == null) || [];
              this.response(data.unit);
              this.openBorang = false;
              this.listSaksi = data.saksi_rs;

              swal.fire(
                "Berjaya!",
                "Maklumat calon pembeli berjaya dikemaskini.",
                "success",
              );
            })
            .catch(function (error) {
              swal.fire(
                "Tidak Berjaya!",
                "Sila lengkapkan borang pendaftaran calon pembeli",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        });
    },

    close() {
      vmodal.close(this.modalId);
    },

    setFormSaksi(data) {
      const pembeli = data.pembeli
      const saksi = pembeli.saksi;
      const { pemaju_saksi, tuan_tanah_saksi } = saksi || {};
      this.pembeli_id = pembeli.id;
      this.pembeli_no_kp_edit = pembeli.no_kp;
      this.form = {
        nama_calon_pembeli: pembeli.nama_pembeli,
        calon_pembeli_id: pembeli.id,

        pembeli_nama: saksi?.pembeli_nama || null,
        pembeli_no_kp: saksi?.pembeli_no_kp || null,
        pembeli_no_telefon: saksi?.pembeli_no_telefon || null,
        pembeli_emel: saksi?.pembeli_emel || null,

        pemaju_id: saksi?.pemaju_id || null,
        pemaju_no_kp: pemaju_saksi?.no_kp || null,
        pemaju_no_telefon: pemaju_saksi?.no_telefon || null,
        pemaju_emel: pemaju_saksi?.emel || null,

        tuan_tanah_id: saksi?.tuan_tanah_id || null,
        tuan_tanah_no_kp: tuan_tanah_saksi?.no_kp || null,
        tuan_tanah_no_telefon: tuan_tanah_saksi?.no_telefon || null,
        tuan_tanah_emel: tuan_tanah_saksi?.emel || null,

        is_penama_pertama: pembeli.no_kp == this.unit.no_kp
      };

    },

    resetForm() {
      this.form = {
        nama_calon_pembeli: null,
        calon_pembeli_id: null,

        pembeli_nama: null,
        pembeli_no_kp: null,
        pembeli_no_telefon: null,
        pembeli_emel: null,

        pemaju_id: null,
        pemaju_nama: null,
        pemaju_no_kp: null,
        pemaju_no_telefon: null,
        pemaju_emel: null,

        tuan_tanah_id: null,
        tuan_tanah_nama: null,
        tuan_tanah_no_kp: null,
        tuan_tanah_no_telefon: null,
        tuan_tanah_emel: null,

        is_penama_pertama: false
      };
    },

    saksiChange(type) {
      if(type == 'pemaju') {
        if(this.form.pemaju_id) {
          const saksi = this.listSaksi.find(item => item.id == this.form.pemaju_id)
          this.form.pemaju_no_kp = saksi?.no_kp || null
          this.form.pemaju_no_telefon = saksi?.no_telefon || null
          this.form.pemaju_emel = saksi?.emel || null
        }
      } else {
        if(this.form.tuan_tanah_id) {
          const saksi = this.listSaksi.find(item => item.id == this.form.tuan_tanah_id)
          this.form.tuan_tanah_no_kp = saksi?.no_kp || null
          this.form.tuan_tanah_no_telefon = saksi?.no_telefon || null
          this.form.tuan_tanah_emel = saksi?.emel || null
        }
      }
    },

    isPenamaPertama(no_kp) {
      return this.unit && this.unit.no_kp == no_kp;
    }
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1;
    },
    isFirstBuyer() {
      return this.unit_pertama && this.unit.id == this.unit_pertama.id;
    },
    disabledForm() {
      return this.listSaksi.length  == 0;
    }
  },
};
</script>

<style scoped>

</style>
