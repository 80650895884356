<template>
  <div>
    <ul class="ribbon-steps nav">
      <li>
        <a
          :class="{ 'show active': isCurrentTab('#bahagian-a') }"
          href="#bahagian-a"
          >A. MAKLUMAT PEMAJU PERUMAHAN</a
        >
      </li>
      <li class>
        <a
          :class="{ 'show active': isCurrentTab('#bahagian-b') }"
          href="#bahagian-b"
          >B. MAKLUMAT AKAUN PEMAJUAN PERUMAHAN (HDA)</a
        >
      </li>
      <li v-if="!readonly">
        <a
          :class="{ 'show active': isCurrentTab('#bahagian-d') }"
          href="#bahagian-d"
          >C. PERAKUAN PEMAJU PERUMAHAN</a
        >
      </li>
    </ul>
    <div class="tab-content mt-4">
      <div
        class="tab-pane fade"
        :class="{ 'show active': isCurrentTab('#bahagian-a') }"
        id="bahagian-a"
        role="tabpanel"
      >
        <div class="card bg-light">
          <div class="card-body">
            <h4 class="card-title">MAKLUMAT PEMBAYARAN</h4>
            <div class="card">
              <div class="card-body">
                <table
                  class="table table-striped table-hover table-card text-center"
                >
                  <thead>
                    <tr class="bg-matte-blue text-white">
                      <th scope="col">#</th>
                      <th scope="col">Nama</th>
                      <th scope="col" nowrap>No KP/Passport</th>
                      <th scope="col">Jawatan</th>
                      <th scope="col">Tarikh Lantikan Jawatan</th>
                      <th scope="col">Tarikh Letak Jawatan</th>
                      <th scope="col">Tindakan</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- @forelse ($pemaju->pengarah_rs as $pengarah)
                    <tr>
                        <td>{{>{{$loop->index + 1}}</td>
                        <td>{{$pengarah->nama}}</td>
                        <td>{{$pengarah->no_kp}}</td>
                        <td>{{$pengarah->jawatan}}</td>
                        <td>{{optional($pengarah->tarikh_lantik)->format('d/m/Y')}}</td>
                        <td>{{optional($pengarah->tarikh_berhenti)->format('d/m/Y')}}</td>
                        <td>
                            <form action="{{route('bahagian.ptl_maklumatpemaju.hapuspengarah',[$pengarah->id])}}"
                                method="POST">
                                @csrf
                                <button class="btn btn-sm btn-primary" type="button" data-toggle="modal"
                                data-target="#pengarahmodal-{{$pengarah->id}}">
                                    <i class="fa fa-edit">
                                    </i>
                                </button>

                                <button class="btn btn-sm btn-danger btn-delete" type="button">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </form>
                        </td>
                    </tr>
                    @empty
                    <tr>
                        <td colspan="999"> <div class="alert-warning p-3">Tiada Rekod ... </div></td>
                    </tr>
                    @endforelse -->
                  </tbody>
                </table>
                <div class="text-center">
                  <button
                    type="button"
                    class="btn btn-primary m-2"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Tambah Butiran
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Butiran Pembayaran -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title text-primary" id="Kuiri">
              Butiran Pembayaran
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <section class="form-compact row">
              <div class="col-lg-10">
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Bayar Kepada (Firma Projek)</label
                  >
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="alasanlain" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label"
                    >Bayar Kepada (Lain-lain)</label
                  >
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="alasanlain" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Tujuan</label>
                  <div class="col-sm-8">
                    <select class="form-control" name="cars" id="cars">
                      <option value="volvo">Sila Pilih</option>
                      <option value="saab"
                        >Cukai dan caj-caj berkaitan tanah</option
                      >
                      <option value="mercedes">
                        Duti setem ke atas sesuatu gadaian, kaveat, debentur, jaminan atau memorandum simpanan hak milik
                        untuk mendapatkan apa-apa kemudahan kewangan bagi pembinaan rumah untuk pemajuan tersebut
                      </option>
                      <option value="audi"
                        >Fi guaman (tidak termasuk perkhidmatan guaman
                        SPJB)</option
                      >
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Catatan</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="alasanlain" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Amaun (RM)</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="alasanlain" />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-sm-4 col-form-label">Nama Pelulus</label>
                  <div class="col-sm-8">
                    <input type="text" class="form-control" name="alasanlain" />
                  </div>
                </div>
              </div>
            </section>
            <div class=" text-center">
              <button class="btn btn-success btn-submit">Tambah</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      :class="{ 'show active': isCurrentTab('#bahagian-b') }"
      id="bahagian-b"
      role="tabpanel"
    >
      <div class="card bg-light">
        <div class="card-body">
          <h4 class="card-title">
            MAKLUMAT AKAUN PEMAJUAN PERUMAHAN (HDA)
          </h4>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label">No Akaun</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label">Alamat Bank</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label">Tarikh Buka</label>
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >Status Akaun</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >Jenis Deposit</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >No Rujukan Surat Kelulusan</label
                      >
                      <div class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label">Nama Bank</label>
                      <div role="group" class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >Baki Terkini HDA (RM)</label
                      >
                      <div role="group" class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >Tarikh Tutup</label
                      >
                      <div role="group" class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >Amaun Deposit (RM)</label
                      >
                      <div role="group" class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label">Tarikh Beku</label>
                      <div role="group" class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                  <div role="group" class="form-group">
                    <div class="form-row">
                      <label class="col-sm-3 col-form-label"
                        >Tarikh Surat Kelulusan</label
                      >
                      <div role="group" class="col-sm-9">
                        <input
                          type="text"
                          class="form-control"
                          value=""
                          style="border:none;"
                          readonly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="tab-pane fade"
      :class="{ 'show active': isCurrentTab('#bahagian-d') }"
      id="bahagian-d"
      role="tabpanel"
    >
      <div class="card bg-light">
        <div class="card-body">
          <h4 class="card-title">PERAKUAN PEMAJU PERUMAHAN</h4>
          <div class="card-body form-compact">
            <p>
              1. Saya akui segala maklumat dan dokumen yang dikemukakan adalah
              benar.
            </p>
            <p>
              2. Saya memahami sekiranya maklumat dan dokumen yang dikemukakan
              tidak benar atau tidak lengkap, pihak Kementerian berhak menolak
              permohonan ini.
            </p>
            <br />
            <div class="form-group form-row">
              <label class="col-form-label col-md-2">Nama :</label>
              <div class="col-md-4">
                <span class="form-control-plaintext">{{ pemaju.nama }}</span>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-form-label col-md-2">Jawatan :</label>
              <div class="col-md-4">
                <!-- <span class="form-control-plaintext">{{ pengarah.jawatan }}</span> -->
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-form-label col-md-2">Tarikh :</label>
              <div class="col-md-4">
                <span class="form-control-plaintext">09/12/2019</span>
              </div>
            </div>
            <div class="text-center" v-if="!readonly">
              <!-- <button class="btn btn-primary" @click="$emit('submit', $event)">HANTAR</button> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false
    },

    pemaju: Object
  },
  data() {
    return {
      defaultTab: "#bahagian-a",
      tab: this.defaultTab
    };
  },
  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },
  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    }
  }
};
</script>

<style></style>
