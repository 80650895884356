var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.kuiri)?_c('div',{staticClass:"alert alert-warning"},[_c('strong',[_vm._v("Catatan Kuiri:")]),_vm._v(" "),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.kuiri.catatan))])]):_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("1.\tKelulusan Ubah Syarat Tanah kepada Perumahan atau Komersi / Surat kelulusan Serah Balik Berimilik (Kurnia) Semula")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("grn_doc_kelulusan_ubah_syarat_tanah")},attrs:{"type":"file","accept":"application/pdf","name":"laporan_ctos","doc":_vm.maklumat.grn_doc_kelulusan_ubah_syarat_tanah,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('grn_doc_kelulusan_ubah_syarat_tanah', file, callback);
},"remove":function($event){return _vm.removeDoc('grn_doc_kelulusan_ubah_syarat_tanah')}}}),_vm._v(" "),_c('div',{staticClass:"text-danger col-form-label-sm"},[_vm._v("Hanya dokumen berformat PDF sahaja dibenarkan")])],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("2.\tBorang 5(A) / 7G (KTN)")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("grn_doc_borang_5a_7g")},attrs:{"type":"file","accept":"application/pdf","name":"grn_doc_borang_5a_7g","doc":_vm.maklumat.grn_doc_borang_5a_7g,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('grn_doc_borang_5a_7g', file, callback);
},"remove":function($event){return _vm.removeDoc('grn_doc_borang_5a_7g')}}}),_vm._v(" "),_c('div',{staticClass:"text-danger col-form-label-sm"},[_vm._v("Hanya dokumen berformat PDF sahaja dibenarkan")])],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("3.\tResit bayaran secukupnya")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("grn_doc_resit_bayaran_secukupnya")},attrs:{"type":"file","accept":"application/pdf","name":"grn_doc_resit_bayaran_secukupnya","doc":_vm.maklumat.grn_doc_resit_bayaran_secukupnya,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('grn_doc_resit_bayaran_secukupnya', file, callback);
},"remove":function($event){return _vm.removeDoc('grn_doc_resit_bayaran_secukupnya')}}}),_vm._v(" "),_c('div',{staticClass:"text-danger col-form-label-sm"},[_vm._v("Hanya dokumen berformat PDF sahaja dibenarkan")])],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("4.\tSurat Rayuan")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("grn_doc_surat_rayuan")},attrs:{"type":"file","accept":"application/pdf","name":"grn_doc_surat_rayuan","doc":_vm.maklumat.grn_doc_surat_rayuan,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('grn_doc_surat_rayuan', file, callback);
},"remove":function($event){return _vm.removeDoc('grn_doc_surat_rayuan')}}}),_vm._v(" "),_c('div',{staticClass:"text-danger col-form-label-sm"},[_vm._v("Hanya dokumen berformat PDF sahaja dibenarkan")])],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("5.\tTarikh Surat Kelulusan Serah Balik")]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{'is-invalid': _vm.errors.first('grn_tarikh_kelulusan')},attrs:{"name":"grn_tarikh_kelulusan","disabled":_vm.readonly},model:{value:(_vm.form.grn_tarikh_kelulusan),callback:function ($$v) {_vm.$set(_vm.form, "grn_tarikh_kelulusan", $$v)},expression:"form.grn_tarikh_kelulusan"}})],1)])])]),_vm._v(" "),(!_vm.readonly)?_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.hErrorMessage)?_c('span',{staticClass:"text-danger mr-2"},[_vm._v(_vm._s(_vm.hErrorMessage))]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.succesfulMessage)?_c('span',{staticClass:"text-success mr-2"},[_vm._v(_vm._s(_vm.succesfulMessage))]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v("Simpan")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }