<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="col-lg-12">
          <section>
            <h5 class="card-title text-primary">MAKLUMAT PEGAWAI BERHUBUNG</h5>
            <div class="form-group form-row" v-if="pegawai_perhubungan_rs.length">
              <label class="col-form-label col-md-3">Pegawai Perhubungan</label>
              <div class="col-md-9">
                <select class="form-control">
                  <option selected :value="null">Sila Pilih</option>
                  <option v-for="(pegawai, index) in pegawai_perhubungan_rs" :value="index">{{ pegawai.nama }}</option>
                </select>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-form-label col-md-3" style="white-space: pre-wrap;">1.&#9;Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
              <div class="col-md-9">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai') }" name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai" @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required'"/>
              </div>
            </div>
            <div class="form-group form-row">
              <label class="col-form-label col-md-3" style="white-space: pre-wrap;">2.&#9;No Telefon Pejabat <span class="label-required">*</span></label>
              <div class="col-md-3">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_pejabat') }" name="pemaju_no_pejabat" :value="pemaju_no_pejabat" @input="$emit('update:pemaju_no_pejabat', $event.target.value.toUpperCase())" v-validate="'required'"/>
              </div>
              <label class="col-form-label col-md-3" style="white-space: pre-wrap;">3.&#9;No Telefon Bimbit <span class="label-required">*</span></label>
              <div class="col-md-3">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit') }" name="pemaju_no_bimbit" :value="pemaju_no_bimbit" @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required'"/>
              </div>
            </div>
          </section>
          <br>
          <br>
          <section>
            <h5 class="card-title text-primary">MAKLUMAT PEMAJU</h5>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Nama Pemaju</label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="pemaju.nama" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;No. Pendaftaran SSM</label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="pemaju.no_ssm" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;No. Pendaftaran SSM baru</label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="pemaju.no_ssm_baru" disabled>
              </div>
            </div>
            <section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Alamat Berdaftar</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_daftar1" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_daftar2" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_daftar3" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-4">
                  <input type="text" class="form-control" :value="pemaju.poskod_daftar" disabled>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.negeri_daftar ? pemaju.negeri_daftar.keterangan : ''" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Daerah <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.daerah_daftar ? pemaju.daerah_daftar.keterangan : ''" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.bandar_daftar ? pemaju.bandar_daftar.keterangan : ''" disabled>
                </div>
              </div>
            </section>

            <section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Alamat Perniagaan</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_perniagaan1" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_perniagaan2" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_perniagaan3" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-4">
                  <input type="text" class="form-control" :value="pemaju.poskod_perniagaan" disabled>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.negeri_perniagaan ? pemaju.negeri_perniagaan.keterangan : ''" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Daerah <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.daerah_perniagaan ? pemaju.daerah_perniagaan.keterangan : ''" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.bandar_perniagaan ? pemaju.bandar_perniagaan.keterangan : ''" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (1)</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.telefon_perniagaan1" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (2) / No Telefon Bimbit</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.telefon_perniagaan2" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Faksimili</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.fax_perniagaan" disabled>
                </div>
              </div>
            </section>

            <section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Alamat Surat Menyurat <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_suratmenyurat1" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_suratmenyurat2" disabled>
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input type="text" class="form-control" :value="pemaju.alamat_suratmenyurat3" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Poskod <span class="label-required">*</span></label>

                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.poskod_suratmenyurat" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri & Daerah <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.negeri_suratmenyurat ? pemaju.negeri_suratmenyurat.keterangan : ''" disabled>
                </div>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.daerah_suratmenyurat ? pemaju.daerah_suratmenyurat.keterangan : ''" disabled>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.bandar_suratmenyurat ? pemaju.bandar_suratmenyurat.keterangan : ''" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (1) <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.telefon_suratmenyurat1" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (2) / No Telefon Bimbit</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.telefon_suratmenyurat2" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Faksimili</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.fax_suratmenyurat" disabled>
                </div>
              </div>
            </section>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Emel <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="pemaju.emel" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Alamat Web</label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="pemaju.alamat_web" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">9.&#9;Taraf Pemaju <span class="label-required">*</span></label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="pemaju.taraf_pemaju.keterangan" disabled>
              </div>
            </div>
            <div class="form-group">
              <fieldset class="form-fieldset">
                <legend style="white-space: pre-wrap;">9.&#9;Amaun Modal</legend>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">a.&#9;Modal Dibenarkan</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :value="pemaju.amaun_modal_dibenar" disabled>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">b.&#9;Modal Terbitan</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :value="pemaju.amaun_modal_diterbit" disabled>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">c.&#9;Modal Berbayar Tunai</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :value="pemaju.amaun_modal_tunai" disabled>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">d.&#9;Modal Berbayar Bukan Tunai</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :value="pemaju.amaun_modal_bukantunai" disabled>
                  </div>
                </div>
              </fieldset>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">10.&#9;Jenis Pemaju <span class="label-required">*</span></label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="pemaju.jenis_pemaju.keterangan" disabled>
              </div>
            </div>

            <div class="ml-4">
              <div class="form-group">
                Jika Subsidiari atau Bersekutu, sila isikan butiran syarikat Induk:
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">i.&#9;Nama Syarikat Induk</label>
                <div class="col-md-8">
                  <input type="text" class="form-control" :value="pemaju.nama_pemaju_induk" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">i.&#9;No Pendaftaran SSM</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="pemaju.nossm_pemaju_induk" disabled>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">11.&#9;Kategori Pemaju <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="pemaju.kategori_pemaju.keterangan" disabled>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianA",
  inject: ['$validator'],
  props: {
    pegawai_perhubungan_rs: {type: Array, default: () => ([])},

    pemaju_nama_pegawai: String,
    pemaju_no_pejabat: String,
    pemaju_no_bimbit: String,

    pemaju: Object,
  },
}
</script>

<style scoped>

</style>
