<template>
  <tr>
    <td><div v-if="!(nolot_unit.profile_nolot && nolot_unit.profile_nolot.status_jualan)"><slot></slot></div></td>
    <td>{{ no }}</td>
    <template v-if="!updating">
      <template v-if="['G', 'I'].includes(kodJenisPerjanjianId)">
        <td>{{ nolot_unit.no_lot_pt }}</td>
        <td>{{ nolot_unit.no_unit }}</td>
        <td>{{ nolot_unit.luas_tanah }}</td>
        <td>{{ nolot_unit.luas_binaan }}</td>
        <td>{{ nolot_unit.jenis_susunatur }}</td>
        <td>{{ nolot_unit.kedudukan_lot ? nolot_unit.kedudukan_lot.keterangan : '-' }}</td>
        <td>{{ nolot_unit.bil_bilik_tidur }}</td>
        <td>{{ nolot_unit.bil_bilik_air }}</td>
        <td>{{ Number(nolot_unit.harga).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
        <td>{{ Number(nolot_unit.harga_jualan).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
      </template>
      <template v-else>
        <td>{{ nolot_unit.strata_menara }}</td>
        <td>{{ nolot_unit.strata_tingkat }}</td>
        <td>{{ nolot_unit.no_petak }}</td>
        <td>{{ nolot_unit.no_unit }}</td>
        <td>{{ nolot_unit.jenis_layout }}</td>
        <td>{{ nolot_unit.kedudukan_lot ? nolot_unit.kedudukan_lot.keterangan : '-' }}</td>
        <td>{{ nolot_unit.jadual_petak_utama }}</td>
        <td>{{ nolot_unit.luas_tanah }}</td>
        <td>{{ nolot_unit.petak_aksesori }}</td>
        <td>{{ nolot_unit.keluasan }}</td>
        <td>{{ nolot_unit.jadual_petak_aksesori }}</td>
        <td>{{ nolot_unit.bilangan_tlk }}</td>
        <td>{{ nolot_unit.unit_syer }}</td>
        <td>{{ Number(nolot_unit.harga_jualan).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
        <td>{{ Number(nolot_unit.harga).toLocaleString(undefined, {minimumFractionDigits: 2}) }}</td>
        <td>{{ nolot_unit.bil_bilik_tidur }}</td>
        <td>{{ nolot_unit.bil_bilik_air }}</td>
      </template>

      <td class="text-center" :class="{'text-success': nolot_unit.tuan_tanah, 'text-danger': !nolot_unit.tuan_tanah}">{{ nolot_unit.tuan_tanah ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center" v-if="maklumat.pengesahan_kuotabumi == `Y`" :class="{'text-success': nolot_unit.kuota_bumi, 'text-danger': !nolot_unit.kuota_bumi}">{{ nolot_unit.kuota_bumi ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center px-1" v-if="programKemajuan == 1">{{ nolot_unit.kod_skim_projek_id ? skimProjek.keterangan : '-' }}</td>
      <td v-if="nolot_unit.profile_nolot && nolot_unit.profile_nolot.status_jualan">
        <span :title="nolot_unit.profile_nolot.no_kp"><i class="fa fa-user"></i></span>
      </td>
      <td v-else-if="!readonly" nowrap>
        <button @click="startUpdating" class="btn btn-icon text-primary btn-sm mr-2" title="Kemaskini"><i class="fas fa-edit"></i></button>
        <button type="button" class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="$emit('destroyUnit')" title="Hapus"><i class="fas fa-trash"></i></button>
      </td>
    </template>
    <template v-else>
      <form-unit
        :maklumat="maklumat"
        :kodJenisPerjanjianId="kodJenisPerjanjianId"
        :program-kemajuan="maklumat.program_kemajuan"
        :kod-skim-projek-rs="kodSkimProjekRs"
        :kod-kedudukan-lot-rs="kodKedudukanLotRs"
        :form="form"
        :is-edit="updating"
        :nolot-unit="nolot_unit"
        @save="(form) => save({form})"
        @cancelSave="() => updating = false"
      ></form-unit>
    </template>
  </tr>
</template>

<script>
import FormUnit from './FormUnit.vue';

export default {
  name: 'RowNolotUnit',
  components: {
    FormUnit
  },
  props: {
    no: {
      type: Number,
      default: 0,
    },
    maklumat: Object,
    nolot_unit: Object,

    readonly: {
      type: Boolean,
      default: false,
    },


    kodJenisPerjanjianId: null,
    skimProjek: null,
    programKemajuan: {
      type: Number,
      default: 0,
    },
    kodSkimProjekRs: Array,
    kodKedudukanLotRs: Array,
  },

  data() {
    return {
      updating: false,

      form: this.parse(),
      loading: false,
    }
  },

  methods: {
    parse() {
      return {
        no_lot_pt: this.nolot_unit.no_lot_pt,
        no_unit: this.nolot_unit.no_unit,
        jenis_layout: this.nolot_unit.jenis_layout,
        luas_tanah: this.nolot_unit.luas_tanah,
        luas_binaan: this.nolot_unit.luas_binaan,
        bilangan_tlk: this.nolot_unit.bilangan_tlk,
        harga_jualan: this.nolot_unit.harga_jualan,
        tuan_tanah: this.nolot_unit.tuan_tanah,
        kuota_bumi: this.nolot_unit.kuota_bumi,
        kod_skim_projek_id: this.nolot_unit.kod_skim_projek_id,

        // new
        strata_menara: this.nolot_unit.strata_menara,
        strata_tingkat: this.nolot_unit.strata_tingkat,
        no_petak: this.nolot_unit.no_petak,
        jadual_petak_utama: this.nolot_unit.jadual_petak_utama,
        petak_aksesori: this.nolot_unit.petak_aksesori,
        keluasan: this.nolot_unit.keluasan,
        jadual_petak_aksesori: this.nolot_unit.jadual_petak_aksesori,
        unit_syer: this.nolot_unit.unit_syer,
        harga: this.nolot_unit.harga,
        bil_bilik_tidur: this.nolot_unit.bil_bilik_tidur,
        bil_bilik_air: this.nolot_unit.bil_bilik_air,
        jenis_susunatur: this.nolot_unit.jenis_susunatur,
        kedudukan_lot_id: this.nolot_unit.kedudukan_lot_id,
      };
    },

    startUpdating() {
      this.form = this.parse();

      this.updating = true;
    },

    tuanTanahChange() {
      this.form.kuota_bumi = 0;
    },

    async save({ form }) {
      if (this.loading) {
        return;
      }

      const self = this;

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.$emit('updateUnit', form, {
        setLoading: (loading) => {
          self.loading = loading;
        },
        close: () => {
          self.updating = false;
        },
      })
    },
  }
}
</script>

<style scoped>
  td {
    text-align: center;
  }
</style>
