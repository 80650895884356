import { render, staticRenderFns } from "./TuanTanahModal.vue?vue&type=template&id=216b84c4&scoped=true&"
import script from "./TuanTanahModal.vue?vue&type=script&lang=js&"
export * from "./TuanTanahModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216b84c4",
  null
  
)

export default component.exports