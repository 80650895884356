<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Bil.</th>
              <th>Nama Syarikat</th>
              <th>No. Daftar SSM</th>
              <th>Kod Pemaju</th>
              <th width="1%">
                <button class="btn btn-success" style="width: 1.5rem; height: 1.5rem; padding: 0;" @click.prevent="add"><i class="fas fa-plus"></i></button>
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-if="syarikat_rs.length">
              <tr v-for="(syarikat, index) in syarikat_rs">
                <td>{{ index + 1}}</td>
                <td>{{ syarikat.nama }}</td>
                <td>{{ syarikat.no_ssm }}</td>
                <td>{{ syarikat.kod_pemaju }}</td>
                <td nowrap>
                  <button class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroy(index)" title="Hapus"><i class="fas fa-trash"></i></button>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <tambah-maklumat-syarikat-modal
      :kod-jenis-pemaju-rs="kodJenisPemajuRs"
    ></tambah-maklumat-syarikat-modal>
  </div>
</template>

<script>
import TambahMaklumatSyarikatModal from "../../common/TambahMaklumatSyarikatModal";
export default {
  name: "BahagianB",
  inject: ['$validator'],

  components: {TambahMaklumatSyarikatModal},
  props: {
    kodJenisPemajuRs: Array,
    syarikat_rs: Array,
  },
  data() {
    return {
    }
  },
  methods: {
    add() {
      vmodal.open('tambahmaklumatsyarikat', {}, (syarikat) => {
        const syarikat_rs = JSON.parse(JSON.stringify(this.syarikat_rs));

        if (syarikat.kod_pemaju) {
          syarikat_rs.push(syarikat);
        }

        this.$emit('update:syarikat_rs', syarikat_rs);
      })
    },
    destroy(index) {
      const syarikat_rs = JSON.parse(JSON.stringify(this.syarikat_rs));

      syarikat_rs.splice(index, 1)
      this.$emit('update:syarikat_rs', syarikat_rs);
    }
  }
}
</script>

<style scoped>

</style>
