<template>
    <img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle espa" data-toggle="tooltip" data-placement="top" title="Medan ini akan dipaparkan dalam cetakan eSPA."/>
</template>
  
<script>
export default {
    name: "ImageEspa",
}
</script>

<style scoped>
.espa {
    display: inline-flex; 
    align-items: center; 
    line-height: 1; 
    width: 15px; 
    margin-left: auto;
}
</style>
  