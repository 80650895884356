<template>
  <div class="card position-relative">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>
      <div class="alert alert-warning pb-0">
        <div><strong>PANDUAN</strong></div>
        <ol>
          <li>
            Sila muat turun Surat Kelulusan Bersyarat
            <a
              :href="`/pemaju/ap-permit-baru/${maklumat.id}/download/kelulusan_bersyarat`"
              target="_blank"
            >
              (Klik disini)</a
            >
          </li>
          <li>
            Sila muat turun Lampiran A1
            <a
              :href="`/pemaju/ap-permit-baru/${maklumat.id}/download/lampiran_a1`"
              target="_blank"
              >(Klik disini)</a
            >
            / A2
            <a
              :href="`/pemaju/ap-permit-baru/${maklumat.id}/download/lampiran_a2`"
              target="_blank"
              >(Klik disini)</a
            >
          </li>
          <li>
            Sila muat naik semula dokumen A1/A2 yang telah mendapat pengesahan
            dari Bank
          </li>
          <li>
            Medan bertanda <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    style="display: inline-flex; align-items: center; line-height: 1;  height: 16px; margin-left: 5px; margin-right: 5px;"
                  ></img> akan dipaparkan dalam cetakan eSPA.
          </li>
        </ol>
      </div>
      <section class="form-compact row">
        <div class="col-lg-12">
          <h5 class="card-title">MAKLUMAT HDA</h5>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="align-items: center; display: flex"
              >1.<span
              style="display: inline-block; width: 1.4em"
              ></span
              >No Akaun HDA <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label
            >
            <div class="col-md-8">
              <input
                type="text"
                name="hda_no_akaun"
                class="form-control"
                :class="{ 'is-invalid': errors.first('hda_no_akaun') }"
                :value="form.hda_no_akaun"
                @input="form.hda_no_akaun = $event.target.value.toUpperCase()"
                :disabled="readonly"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="align-items: center; display: flex"
              >2.<span
              style="display: inline-block; width: 1.4em"
              ></span
              >Nama Bank <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label
            >
            <div class="col-md-8">
              <select2
                name="kod_bank_hq_id"
                class="form-control"
                :class="{
                  'is-invalid select2-hidden-accessible':
                    errors.first('kod_bank_hq_id'),
                }"
                v-model="form.kod_bank_hq_id"
                :disabled="readonly"
                @change="updateBankHqData(`kod_bank_hq_id`, { alamat1: 'hda_alamat_hq_bank1', alamat2: 'hda_alamat_hq_bank2', alamat3: 'hda_alamat_hq_bank3', poskod: 'hda_hq_poskod' })"
              >
                <option :value="null">
                  Sila Pilih Nama Bank Yang Berdaftar
                </option>
                <option
                  v-for="bank in kodBankHqRs"
                  :value="bank.id"
                  :key="`bank_${bank.id}`"
                >
                  {{ bank.keterangan }}
                </option>
              </select2>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Nama Bank <span class="label-required">*</span></label>
            <div class="col-md-8">
              <input
                type="text" name="hda_nama_bank"
                class="form-control" :class="{'is-invalid': errors.first('hda_nama_bank')}"
                :value="form.hda_nama_bank" @input="form.hda_nama_bank = $event.target.value.toUpperCase()"
                :disabled="readonly"
              >
            </div>
          </div> -->

          <section>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="align-items: center; display: flex"
                >3.<span
              style="display: inline-block; width: 1.4em"
              ></span
              >Alamat Ibu Pejabat <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  name="hda_alamat_hq_bank1"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_alamat_hq_bank1') }"
                  v-model="form.hda_alamat_hq_bank1"
                  @input="
                    form.hda_alamat_hq_bank1 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                disabled />
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input
                  type="text"
                  name="hda_alamat_hq_bank2"
                  class="form-control"
                  v-model="form.hda_alamat_hq_bank2"
                  @input="
                    form.hda_alamat_hq_bank2 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                  disabled />
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input
                  type="text"
                  name="hda_alamat_hq_bank3"
                  class="form-control"
                  v-model="form.hda_alamat_hq_bank3"
                  @input="
                    form.hda_alamat_hq_bank3 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                  disabled />
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Poskod <span class="label-required">*</span></label
              >

              <div class="col-md-4">
                <select2
                  name="hda_hq_poskod"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_hq_poskod') }"
                  v-model="form.hda_hq_poskod"
                  @change="updatePoskodHqBank"
                  :disabled="true"
                >
                  <option :value="null">
                    Sila Pilih Poskod
                  </option>
                  <option
                    v-for="poskod in kodPoskodRs"
                    :value="poskod.poskod"
                    :key="`poskod_${poskod.id}`"
                  >
                    {{ poskod.poskod }}
                  </option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Bandar <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2
                  name="hda_kod_bandar_hq_id"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_kod_bandar_hq_id') }"
                  v-model="form.hda_kod_bandar_hq_id"
                  :disabled="true"
                  disabled >
                  <option :value="null">
                    Sila Pilih Bandar
                  </option>
                  <option
                    v-for="bandar in kodPoskodBandarRs"
                    :value="bandar.id"
                    :key="`bandar_${bandar.id}`"
                  >
                    {{ bandar.keterangan }}
                  </option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Negeri <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <select2
                  name="hda_kod_negeri_hq_id"
                  class="form-control"
                  :class="{
                    'is-invalid select2-hidden-accessible':
                      errors.first('hda_kod_negeri_hq_id'),
                  }"
                  v-model="form.hda_kod_negeri_hq_id"
                  :disabled="true"
                >
                  <option :value="null">Sila Pilih Negeri</option>
                  <option
                    v-for="negeri in kodNegeriRs"
                    :value="negeri.id"
                    :key="`negeri_${negeri.id}`"
                  >
                    {{ negeri.keterangan }}
                  </option>
                </select2>
              </div>
            </div>
            <!-- <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Daerah<span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <div v-if="disableDaerah">
                  <input
                    class="form-control"
                    type=text
                    value="TIDAK BERKAITAN"
                    disabled>
                </div>
                <div v-else>
                  <select2
                    name="hda_kod_daerah_hq_id"
                    class="form-control"
                    :class="{
                    'is-invalid select2-hidden-accessible':
                      errors.first('hda_kod_daerah_hq_id'),
                  }"
                    v-model="form.hda_kod_daerah_hq_id"
                    @change="form.hda_kod_bandar_hq_id = null;"
                    :disabled="readonly || disableDaerah"
                  >
                    <option :value="null">Sila Pilih Daerah</option>
                    <option
                      v-for="daerah in filteredDaerahRs(form.hda_kod_negeri_hq_id)"
                      :value="daerah.id"
                      :key="`daerah_${daerah.id}`"
                    >
                      {{ daerah.keterangan }}
                    </option>
                  </select2>
                </div>
              </div>
            </div> -->
          </section>

          <section>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >3.&#9;Alamat Cawangan <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  name="hda_alamat_bank1"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_alamat_bank1') }"
                  :value="form.hda_alamat_bank1"
                  @input="
                    form.hda_alamat_bank1 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input
                  type="text"
                  name="hda_alamat_bank2"
                  class="form-control"
                  :value="form.hda_alamat_bank2"
                  @input="
                    form.hda_alamat_bank2 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input
                  type="text"
                  name="hda_alamat_bank3"
                  class="form-control"
                  :value="form.hda_alamat_bank3"
                  @input="
                    form.hda_alamat_bank3 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>

            <!-- <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Poskod <span class="label-required">*</span></label
              >

              <div class="col-md-4">
                <input
                  type="text"
                  name="hda_poskod"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_poskod') }"
                  :value="form.hda_poskod"
                  @input="form.hda_poskod = $event.target.value.toUpperCase()"
                  :disabled="readonly"
                  v-validate="'numeric|length:5,6'"
                />
              </div>
            </div> -->

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Poskod <span class="label-required">*</span></label
              >

              <div class="col-md-4">
                <select2
                  name="hda_poskod"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_poskod') }"
                  v-model="form.hda_poskod"
                  @input="updatePoskod"
                  :disabled="readonly"
                >
                <option :value="null">Sila Pilih Poskod</option>
                  <option
                    v-for="poskod in kodPoskodRs"
                    :value="poskod.poskod"
                    :key="`poskod_${poskod.id}`"
                  >
                    {{ poskod.poskod }}
                  </option>
                </select2>
              </div>
            </div>


            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Bandar <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <select2
                  name="hda_kod_bandar_id"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('hda_kod_bandar_id') }"
                  v-model="form.hda_kod_bandar_id"
                  :disabled="true"
                >
                  <option :value="null">
                    Sila Pilih Bandar
                  </option>
                  <option
                    v-for="bandar in kodPoskodBandarRs"
                    :value="bandar.id"
                    :key="`bandar_${bandar.id}`"
                  >
                    {{ bandar.keterangan }}
                  </option>
                </select2>
              </div>
            </div>


            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Negeri <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <select2
                  name="hda_kod_negeri_id"
                  class="form-control"
                  :class="{
                    'is-invalid select2-hidden-accessible':
                      errors.first('hda_kod_negeri_id'),
                  }"
                  v-model="form.hda_kod_negeri_id"
                  :disabled="true"
                >
                  <option :value="null">Sila Pilih Negeri</option>
                  <option
                    v-for="negeri in kodNegeriRs"
                    :value="negeri.id"
                    :key="`negeri_${negeri.id}`"
                  >
                    {{ negeri.keterangan }}
                  </option>
                </select2>
              </div>
            </div>

            <!-- <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >&#9;Daerah<span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <select2
                  name="hda_kod_daerah_id"
                  class="form-control"
                  :class="{
                    'is-invalid select2-hidden-accessible':
                      errors.first('hda_kod_daerah_id'),
                  }"
                  v-model="form.hda_kod_daerah_id"
                  @change="form.hda_kod_bandar_id = null"
                  :disabled="readonly"
                >
                  <option :value="null">Sila Pilih Daerah</option>
                  <option
                    v-for="daerah in filteredDaerahRs(form.hda_kod_negeri_id)"
                    :value="daerah.id"
                    :key="`daerah_${daerah.id}`"
                  >
                    {{ daerah.keterangan }}
                  </option>
                </select2>
              </div>
            </div> -->
          </section>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >4.&#9;No. Telefon <span class="label-required">*</span></label
            >
            <div class="col-md-8">
              <input
                type="text"
                name="hda_no_telefon"
                class="form-control"
                :class="{ 'is-invalid': errors.first('hda_no_telefon') }"
                :value="form.hda_no_telefon"
                @input="form.hda_no_telefon = $event.target.value.toUpperCase()"
                :disabled="readonly"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >5.&#9;Tarikh Akaun Dibuka <span class="label-required">*</span></label>
            <div class="col-md-4">
              <datepicker
                :class="{ 'is-invalid': errors.first('hda_tarikh_dibuka') }"
                name="hda_tarikh_dibuka"
                v-model="form.hda_tarikh_dibuka"
                :disabled="readonly"
              >
              </datepicker>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >6.&#9;Dokumen Pengesahan Pembukaan Akaun HDA <span class="label-required">*</span></label>
            <div class="col-md-8">
              <file-upload
                type="file"
                accept="application/pdf"
                name="doc_lampiran"
                class="form-control w-100"
                :doc="
                  maklumat.akaun_hda ? maklumat.akaun_hda.doc_lampiran : null
                "
                :class="{ 'is-invalid': errors.first(`doc_lampiran`) }"
                @uploadwithcallback="
                  ({ file, callback }) =>
                    uploadDoc('doc_lampiran', file, callback)
                "
                @remove="removeDoc('doc_lampiran')"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">
                Hanya dokumen berformat PDF sahaja dibenarkan
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <section class="form-compact row">
        <div class="col-lg-12">
          <h5 class="card-title">WANG DEPOSIT LESEN</h5>
          <div class="form-compact form-row">
            <div class="col-lg-4">
              <div class="form-group form-row">
                <label
                  class="col-form-label col-12"
                  style="white-space: pre-wrap"
                  >1.&#9;Jenis Wang Deposit HDA/Tunai <span class="label-required">*</span></label>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="form-group" tabindex="-1">
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    id="kod_jenis_wp_hda"
                    name="kod_jenis_wp_id"
                    class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="form.kod_jenis_wp_id = 'T'"
                    :checked="form.kod_jenis_wp_id === 'T'"
                    value="T"
                    :disabled="readonly"
                  />
                  <label class="form-check-label" for="kod_jenis_wp_hda"
                    >HDA</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    id="kod_jenis_wp_bg"
                    name="kod_jenis_wp_id"
                    class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="form.kod_jenis_wp_id = 'S'"
                    :checked="form.kod_jenis_wp_id === 'S'"
                    value="S"
                    :disabled="readonly"
                  />
                  <label class="form-check-label" for="kod_jenis_wp_bg"
                    >Jaminan Bank / Bank Guarantee</label
                  >
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    id="kod_jenis_wp_aa"
                    name="kod_jenis_wp_id"
                    class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="form.kod_jenis_wp_id = 'A'"
                    :checked="form.kod_jenis_wp_id === 'A'"
                    value="A"
                    :disabled="readonly"
                  />
                  <label class="form-check-label" for="kod_jenis_wp_aa"
                    >Akaun Amanah</label
                  >
                </div>
              </div>
            </div>
          </div>

          <section>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >2.&#9;Mendapat Pengecualian 3% <span class="label-required">*</span></label>
              <div class="col-md-4">
                <div>
                  <div class="form-check form-check-inline">
                    <input
                      id="pengecualian_3peratus_1"
                      type="radio"
                      name="pengecualian_3peratus"
                      v-model="form.pengecualian_3peratus"
                      class="form-check-input"
                      :value="1"
                      @click="is3Percent"
                      :disabled="readonly"
                    />
                    <label
                      for="pengecualian_3peratus_1"
                      class="form-check-label"
                      >Ya</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      id="pengecualian_3peratus_0"
                      type="radio"
                      name="pengecualian_3peratus"
                      v-model="form.pengecualian_3peratus"
                      class="form-check-input"
                      :value="0"
                      @click="is3Percent"
                      :disabled="readonly"
                    />
                    <label
                      for="pengecualian_3peratus_0"
                      class="form-check-label"
                      >Tidak</label
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >3.&#9;Jumlah Amaun Perlu Dibayar <span class="label-required">*</span></label>
              <div
                class="col-md-8"
                v-if="maklumat.kewangan && !form.pengecualian_3peratus"
              >
                <p class="form-control-plaintext">
                  {{ jumlah_deposit_perlu_dibayar | currency }}
                </p>
              </div>
              <div
                class="col-md-8"
                v-else-if="maklumat.kewangan && form.pengecualian_3peratus"
              >
                <p class="form-control-plaintext">PENGECUALIAN 3%</p>
              </div>
              <div class="col-md-8" v-else>
                <p class="form-control-plaintext">TIADA MAKLUMAT</p>
              </div>
            </div>

            <div class="form-group row" v-if="form.pengecualian_3peratus">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >4.&#9;Amaun <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <money
                  type="text"
                  name="wp_amaun"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('wp_amaun') }"
                  v-model="form.wp_amaun"
                  :disabled="readonly"
                  v-validate="'decimal:2'"
                ></money>
              </div>
            </div>
          </section>

          <div class="form-group row" v-if="form.pengecualian_3peratus">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >5.&#9;Dokumen Pengecualian 3%</label
            >
            <div class="col-md-8">
              <file-upload
                type="file"
                accept="application/pdf"
                name="doc_pengecualian_3peratus"
                class="form-control w-100"
                :doc="
                  maklumat.akaun_hda
                    ? maklumat.akaun_hda.doc_pengecualian_3peratus
                    : null
                "
                :class="{
                  'is-invalid': errors.first(`doc_pengecualian_3peratus`),
                }"
                @uploadwithcallback="
                  ({ file, callback }) =>
                    uploadDoc('doc_pengecualian_3peratus', file, callback)
                "
                @remove="removeDoc('doc_pengecualian_3peratus')"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">
                Hanya dokumen berformat PDF sahaja dibenarkan
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- T = HDA -->
      <!-- A = Akaun Amaanah -->
      <!-- S = BG -->
      <section class="form-compact" v-if="form.kod_jenis_wp_id === 'S'"> 
        <br />
        <div
          v-if="
            this.maklumat.kod_skim_projek_id &&
            this.maklumat.kewangan.kos_keseluruhan_harga_bebas == 0
          "
        >
          <h5 class="card-title">
            JAMINAN BANK WANG PENDAHULUAN
            <span
              v-if="
                maklumat.kewangan.pecahan_bg_80 &&
                !form.pengecualian_3peratus &&
                this.jumlah_deposit_perlu_dibayar >= 200000
              "
            ></span>
            <span
              v-else-if="
                maklumat.kewangan.pecahan_bg_80 && !form.pengecualian_3peratus
              "
              >(BG 80%)</span
            >
          </h5>
        </div>
        <div v-else>
          <h5 class="card-title">
            JAMINAN BANK WANG PENDAHULUAN
            <span
              v-if="
                maklumat.kewangan.pecahan_bg_80 && !form.pengecualian_3peratus
              "
              >(BG 80%)</span
            >
          </h5>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap">1.&#9;No. BG / No. Sijil <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input
              type="text"
              name="wp_no_sijil"
              class="form-control"
              :class="{ 'is-invalid': errors.first('wp_no_sijil') }"
              :value="form.wp_no_sijil"
              @input="form.wp_no_sijil = $event.target.value.toUpperCase()"
              :disabled="readonly"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >2.&#9;Nama Bank <span class="label-required">*</span></label
          >
          <div class="col-md-8">
            <select2
              name="wp_kod_bank_hq_id"
              class="form-control"
              :class="{
                'is-invalid select2-hidden-accessible':
                  errors.first('wp_kod_bank_hq_id'),
              }"
              v-model="form.wp_kod_bank_hq_id"
              :disabled="readonly"
              @change="updateBankHqData(`wp_kod_bank_hq_id`, { alamat1: 'wp_alamat_bank1', alamat2: 'wp_alamat_bank2', alamat3: 'wp_alamat_bank3', poskod: 'wp_poskod' })"
            >
              <option :value="null">
                Sila Pilih Nama Bank Yang Berdaftar
              </option>
              <option
                v-for="bank in kodBankHqRs"
                :value="bank.id"
                :key="`bank_${bank.id}`"
              >
                {{ bank.keterangan }}
              </option>
            </select2>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >3.&#9;Cawangan <span class="label-required">*</span></label
          >
          <div class="col-md-8">
            <input
              type="text"
              name="wp_nama_cawangan"
              class="form-control"
              :class="{ 'is-invalid': errors.first('wp_nama_cawangan') }"
              :value="form.wp_nama_cawangan"
              @input="form.wp_nama_cawangan = $event.target.value.toUpperCase()"
              :disabled="readonly"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >4.&#9;Amaun <span class="label-required">*</span></label
          >
          <div
            class="col-md-8"
            v-if="
              this.maklumat.kod_skim_projek_id &&
              this.maklumat.kewangan.kos_keseluruhan_harga_bebas == 0
            "
          >
            <div
              v-if="
                maklumat.kewangan.pecahan_bg_80 &&
                !form.pengecualian_3peratus &&
                this.jumlah_deposit_perlu_dibayar >= 200000
              "
            >
              <p class="form-control-plaintext" v-validate="'decimal:2'">
                {{ jumlah_deposit_perlu_dibayar | currency }}
              </p>
            </div>
            <div v-else>
              <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar*0.8 | currency}}</p>
            </div>
          </div>
          <div class="col-md-8" v-else>
              <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar*0.8 | currency}}</p>
          </div>
        </div>

        <section>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >5.&#9;Alamat Bank <span class="label-required">*</span></label
            >
            <div class="col-md-8">
              <input
                type="text"
                name="wp_alamat_bank1"
                class="form-control"
                :class="{ 'is-invalid': errors.first('wp_alamat_bank1') }"
                :value="form.wp_alamat_bank1"
                @input="
                  form.wp_alamat_bank1 = $event.target.value.toUpperCase()
                "
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-md-4 col-md-8">
              <input
                type="text"
                name="wp_alamat_bank2"
                class="form-control"
                :value="form.wp_alamat_bank2"
                @input="
                  form.wp_alamat_bank2 = $event.target.value.toUpperCase()
                "
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-md-4 col-md-8">
              <input
                type="text"
                name="wp_alamat_bank3"
                class="form-control"
                :value="form.wp_alamat_bank3"
                @input="
                  form.wp_alamat_bank3 = $event.target.value.toUpperCase()
                "
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Poskod <span class="label-required">*</span></label
            >

            <div class="col-md-4">
              <select2
                name="wp_poskod"
                class="form-control"
                :class="{ 'is-invalid': errors.first('wp_poskod') }"
                v-model="form.wp_poskod"
                @input="updatePoskodWp"
                :disabled="readonly"
              >
                <option :value="null">Sila Pilih Poskod</option>
                <option
                  v-for="poskod in kodPoskodRs"
                  :value="poskod.poskod"
                  :key="`poskod_${poskod.id}`"
                >
                  {{ poskod.poskod }}
                </option>
              </select2>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Bandar <span class="label-required">*</span></label>
            <div class="col-md-4">
              <select2
                name="wp_kod_bandar_id"
                class="form-control"
                :class="{ 'is-invalid': errors.first('wp_kod_bandar_id') }"
                v-model="form.wp_kod_bandar_id"
                :disabled="true"
              >
                <option :value="null">Sila Pilih Bandar</option>
                <option
                  v-for="bandar in kodPoskodBandarRs"
                  :value="bandar.id"
                  :key="`bandar_${bandar.id}`"
                >
                  {{ bandar.keterangan }}
                </option>
              </select2>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Negeri <span class="label-required">*</span></label
            >
            <div class="col-md-4">
              <select2
                name="wp_kod_negeri_id"
                class="form-control"
                :class="{
                  'is-invalid select2-hidden-accessible':
                    errors.first('wp_kod_negeri_id'),
                }"
                v-model="form.wp_kod_negeri_id"
                :disabled="true"
              >
                <option :value="null">Sila Pilih Negeri</option>
                <option
                  v-for="negeri in kodNegeriRs"
                  :value="negeri.id"
                  :key="`negeri_${negeri.id}`"
                >
                  {{ negeri.keterangan }}
                </option>
              </select2>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Daerah<span class="label-required">*</span></label
            >
            <div class="col-md-4">
              <select2
                name="wp_kod_daerah_id"
                class="form-control"
                :class="{
                  'is-invalid select2-hidden-accessible':
                    errors.first('wp_kod_daerah_id'),
                }"
                v-model="form.wp_kod_daerah_id"
                @change="form.wp_kod_bandar_id = null"
                :disabled="readonly"
              >
                <option :value="null">Sila Pilih Daerah</option>
                <option
                  v-for="daerah in filteredDaerahRs(form.wp_kod_negeri_id)"
                  :value="daerah.id"
                  :key="`daerah_${daerah.id}`"
                >
                  {{ daerah.keterangan }}
                </option>
              </select2>
            </div>
          </div> -->

        </section>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >6.&#9;Tarikh Dikeluarkan <span class="label-required">*</span></label>
          <div class="col-md-4">
            <datepicker
              :class="{ 'is-invalid': errors.first('wp_tarikh_dikeluarkan') }"
              name="wp_tarikh_dikeluarkan"
              v-model="form.wp_tarikh_dikeluarkan"
              :disabled="readonly"
            ></datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >7.&#9;Tarikh Mula <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <datepicker
              :class="{ 'is-invalid': errors.first('wp_tarikh_sahlaku') }"
              name="wp_tarikh_sahlaku"
              v-model="form.wp_tarikh_sahlaku"
              :disabled="readonly"
            ></datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >8.&#9;Tarikh Tamat <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <datepicker
              :class="{ 'is-invalid': errors.first('wp_tarikh_luput') }"
              name="wp_tarikh_luput"
              v-model="form.wp_tarikh_luput"
              :disabled="readonly"
            ></datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >9.&#9;Dokumen Salinan Jaminan Bank <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file"
              accept="application/pdf"
              name="doc_salinan_jaminan_bank_pemajuan"
              class="form-control w-100"
              :doc="
                maklumat.akaun_hda
                  ? maklumat.akaun_hda.doc_salinan_jaminan_bank_pemajuan
                  : null
              "
              :class="{
                'is-invalid': errors.first(`doc_salinan_jaminan_bank_pemajuan`),
              }"
              @uploadwithcallback="
                ({ file, callback }) =>
                  uploadDoc('doc_salinan_jaminan_bank_pemajuan', file, callback)
              "
              @remove="removeDoc('doc_salinan_jaminan_bank_pemajuan')"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">
              Hanya dokumen berformat PDF sahaja dibenarkan
            </div>
          </div>
        </div>

        <div
          v-if="
            this.maklumat.kod_skim_projek_id &&
            this.maklumat.kewangan.kos_keseluruhan_harga_bebas == 0
          "
        >
          <div
            v-if="
              maklumat.kewangan.pecahan_bg_20 &&
              !form.pengecualian_3peratus &&
              this.jumlah_deposit_perlu_dibayar == 200000
            "
          ></div>
          <div
            v-else-if="
              maklumat.kewangan.pecahan_bg_20 &&
              !form.pengecualian_3peratus &&
              this.jumlah_deposit_perlu_dibayar < 200000
            "
          >
            <br />
            <h5 class="card-title">JAMINAN BANK WANG PENDAHULUAN (BG 20%)</h5>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >1.&#9;No. BG / No. Sijil <span class="label-required">*</span></label>
              <div class="col-md-8">
                <input
                  type="text"
                  name="wp_no_sijil_bg_20"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('wp_no_sijil_bg_20') }"
                  :value="form.wp_no_sijil_bg_20"
                  @input="
                    form.wp_no_sijil_bg_20 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >2.&#9;Nama Bank <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <select2
                  name="wp_kod_bank_hq_id_bg_20"
                  class="form-control"
                  :class="{
                    'is-invalid select2-hidden-accessible':
                      errors.first('wp_kod_bank_hq_id_bg_20'),
                  }"
                  v-model="form.wp_kod_bank_hq_id_bg_20"
                  :disabled="readonly"
                  @change="updateBankHqData(`wp_kod_bank_hq_id_bg_20`, { alamat1: 'wp_alamat_bank1_bg_20', alamat2: 'wp_alamat_bank2_bg_20', alamat3: 'wp_alamat_bank3_bg_20', poskod: 'wp_poskod_bg_20' })"
                >
                  <option :value="null">
                    Sila Pilih Nama Bank Yang Berdaftar
                  </option>
                  <option
                    v-for="bank in kodBankHqRs"
                    :value="bank.id"
                    :key="`bank_${bank.id}`"
                  >
                    {{ bank.keterangan }}
                  </option>
                </select2>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >3.&#9;Cawangan <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  name="wp_nama_cawangan_bg_20"
                  class="form-control"
                  :class="{
                    'is-invalid': errors.first('wp_nama_cawangan_bg_20'),
                  }"
                  :value="form.wp_nama_cawangan_bg_20"
                  @input="
                    form.wp_nama_cawangan_bg_20 =
                      $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >4.&#9;Amaun <span class="label-required">*</span></label
              >
              <div
                class="col-md-8"
                v-if="
                  this.maklumat.kod_skim_projek_id &&
                  this.maklumat.kewangan.kos_keseluruhan_harga_bebas == 0
                "
              >
                <div
                  v-if="
                    maklumat.kewangan.pecahan_bg_80 &&
                    !form.pengecualian_3peratus &&
                    this.jumlah_deposit_perlu_dibayar < 200000
                  "
                >
                  <!-- <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar | currency}}</p> -->
                  <p class="form-control-plaintext" v-validate="'decimal:2'">
                    {{ form.wp_amaun_bg_20 | currency }}
                  </p>
                </div>
                <div v-else>
                  <p class="form-control-plaintext" v-validate="'decimal:2'">
                    {{ form.wp_amaun_bg_20 | currency }}
                  </p>
                </div>
              </div>
              <div class="col-md-8" v-else>
                <p class="form-control-plaintext" v-validate="'decimal:2'">
                  {{ form.wp_amaun_bg_20 | currency }}
                </p>
              </div>
            </div>
            <section>
              <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="white-space: pre-wrap"
                  >5.&#9;Alamat Bank <span class="label-required">*</span>
                </label>
                <div class="col-md-8">
                  <input
                    type="text"
                    name="wp_alamat_bank1_bg_20"
                    class="form-control"
                    :class="{
                      'is-invalid': errors.first('wp_alamat_bank1_bg_20'),
                    }"
                    :value="form.wp_alamat_bank1_bg_20"
                    @input="
                      form.wp_alamat_bank1_bg_20 =
                        $event.target.value.toUpperCase()
                    "
                    :disabled="readonly"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Cawangan <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <input
                    type="text" name="wp_nama_cawangan_bg_20"
                    class="form-control" :class="{'is-invalid': errors.first('wp_nama_cawangan_bg_20')}"
                    :value="form.wp_nama_cawangan_bg_20" @input="form.wp_nama_cawangan_bg_20 = $event.target.value.toUpperCase()"
                    :disabled="readonly"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Amaun <span class="label-required">*</span></label>
                <div class="col-md-8" v-if="this.maklumat.kod_skim_projek_id && this.maklumat.kewangan.kos_keseluruhan_harga_bebas == 0">
                  <div v-if="maklumat.kewangan.pecahan_bg_80 && !form.pengecualian_3peratus && this.jumlah_deposit_perlu_dibayar < 200000">
                    <!-- <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar | currency}}</p> -->
                    <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar*0.2 | currency}}</p>
                  </div>
                  <div v-else>
                    <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar*0.2 | currency}}</p>
                  </div>
                </div>
                <div class="col-md-8" v-else>
                    <p class="form-control-plaintext" v-validate="'decimal:2'">{{ jumlah_deposit_perlu_dibayar*0.2 | currency}}</p>
                </div>
              </div>
              <section>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Alamat Bank <span class="label-required">*</span></label>
                  <div class="col-md-8">
                    <input
                      type="text" name="wp_alamat_bank1_bg_20"
                      class="form-control" :class="{'is-invalid': errors.first('wp_alamat_bank1_bg_20')}"
                      :value="form.wp_alamat_bank1_bg_20" @input="form.wp_alamat_bank1_bg_20 = $event.target.value.toUpperCase()"
                      :disabled="readonly"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <div class="offset-md-4 col-md-8">
                    <input
                      type="text" name="wp_alamat_bank2_bg_20"
                      class="form-control"
                      :value="form.wp_alamat_bank2_bg_20" @input="form.wp_alamat_bank2_bg_20 = $event.target.value.toUpperCase()"
                      :disabled="readonly"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <div class="offset-md-4 col-md-8">
                    <input
                      type="text" name="wp_alamat_bank3_bg_20"
                      class="form-control"
                      :value="form.wp_alamat_bank3_bg_20" @input="form.wp_alamat_bank3_bg_20 = $event.target.value.toUpperCase()"
                      :disabled="readonly"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Poskod <span class="label-required">*</span></label>

                  <div class="col-md-4">
                    <select2
                      name="wp_poskod_bg_20"
                      class="form-control"
                      :class="{ 'is-invalid': errors.first('wp_poskod_bg_20') }"
                      v-model="form.wp_poskod_bg_20"
                      @input="updatePoskodWpBg20"
                      :disabled="readonly"
                    >
                      <option :value="null">Sila Pilih Poskod</option>
                      <option
                        v-for="poskod in kodPoskodRs"
                        :value="poskod.poskod"
                        :key="`poskod_${poskod.id}`"
                      >
                        {{ poskod.poskod }}
                      </option>
                    </select2>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Bandar <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2
                      name="wp_kod_bandar_id_bg_20"
                      class="form-control" :class="{'is-invalid': errors.first('wp_kod_bandar_id_bg_20')}"
                      v-model="form.wp_kod_bandar_id_bg_20"
                      :disabled="true"
                    >
                      <option :value="null">Sila Pilih Bandar</option>
                      <option v-for="bandar in kodPoskodBandarRs" :value="bandar.id" :key="`bandar_${bandar.id}`">{{ bandar.keterangan }}</option>
                    </select2>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2
                      name="wp_kod_negeri_id_bg_20"
                      class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wp_kod_negeri_id_bg_20')}"
                      v-model="form.wp_kod_negeri_id_bg_20"
                      :disabled="true"
                    >
                      <option :value="null">Sila Pilih Negeri</option>
                      <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri_${negeri.id}`">{{ negeri.keterangan }}</option>
                    </select2>
                  </div>
                </div>
                <!-- <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Daerah<span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2
                      name="wp_kod_daerah_id_bg_20"
                      class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wp_kod_daerah_id_bg_20')}"
                      v-model="form.wp_kod_daerah_id_bg_20"
                      @change="form.wp_kod_bandar_id_bg_20 = null;"
                      :disabled="readonly"
                    >
                      <option :value="null">Sila Pilih Daerah</option>
                      <option v-for="daerah in filteredDaerahRs(form.wp_kod_negeri_id_bg_20)" :value="daerah.id" :key="`daerah_${daerah.id}`">{{ daerah.keterangan }}</option>
                    </select2>
                  </div>
                </div> -->
              </section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Tarikh Dikeluarkan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <datepicker
                    :class="{'is-invalid': errors.first('wp_tarikh_dikeluarkan_bg_20')}"
                    name="wp_tarikh_dikeluarkan_bg_20"
                    v-model="form.wp_tarikh_dikeluarkan_bg_20"
                    :disabled="readonly"
                  ></datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Tarikh Mula <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <datepicker
                    :class="{'is-invalid': errors.first('wp_tarikh_sahlaku_bg_20')}"
                    name="wp_tarikh_sahlaku_bg_20"
                    v-model="form.wp_tarikh_sahlaku_bg_20"
                    :disabled="readonly"
                  ></datepicker>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Tarikh Tamat <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <datepicker
                    :class="{'is-invalid': errors.first('wp_tarikh_luput_bg_20')}"
                    name="wp_tarikh_luput_bg_20"
                    v-model="form.wp_tarikh_luput_bg_20"
                    :disabled="readonly"
                  ></datepicker>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">9.&#9;Dokumen Salinan Jaminan Bank <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_salinan_jaminan_bank_pemajuan_bg_20"
                    class="form-control w-100"
                    :doc="maklumat.akaun_hda ? maklumat.akaun_hda.doc_salinan_jaminan_bank_pemajuan_bg_20 : null"
                    :class="{'is-invalid': errors.first(`doc_salinan_jaminan_bank_pemajuan_bg_20`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_salinan_jaminan_bank_pemajuan_bg_20', file, callback)"
                    @remove="removeDoc('doc_salinan_jaminan_bank_pemajuan_bg_20')"
                    :readonly="readonly"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input
                    type="text"
                    name="wp_alamat_bank2_bg_20"
                    class="form-control"
                    :value="form.wp_alamat_bank2_bg_20"
                    @input="
                      form.wp_alamat_bank2_bg_20 =
                        $event.target.value.toUpperCase()
                    "
                    :disabled="readonly"
                  />
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input
                    type="text"
                    name="wp_alamat_bank3_bg_20"
                    class="form-control"
                    :value="form.wp_alamat_bank3_bg_20"
                    @input="
                      form.wp_alamat_bank3_bg_20 =
                        $event.target.value.toUpperCase()
                    "
                    :disabled="readonly"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="white-space: pre-wrap"
                  >&#9;Poskod <span class="label-required">*</span></label
                >

                <div class="col-md-4">
                  <input
                    type="text"
                    name="wp_poskod_bg_20"
                    class="form-control"
                    :class="{ 'is-invalid': errors.first('wp_poskod_bg_20') }"
                    :value="form.wp_poskod_bg_20"
                    @input="
                      form.wp_poskod_bg_20 = $event.target.value.toUpperCase()
                    "
                    :disabled="readonly"
                    v-validate="'numeric|length:5,6'"
                  />
                </div>
              </div>
              <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="white-space: pre-wrap"
                  >&#9;Negeri <span class="label-required">*</span></label
                >
                <div class="col-md-4">
                  <select2
                    name="wp_kod_negeri_id_bg_20"
                    class="form-control"
                    :class="{
                      'is-invalid select2-hidden-accessible': errors.first(
                        'wp_kod_negeri_id_bg_20'
                      ),
                    }"
                    v-model="form.wp_kod_negeri_id_bg_20"
                    @change="
                      form.wp_kod_daerah_id_bg_20 = null;
                      form.wp_kod_bandar_id_bg_20 = null;
                    "
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Negeri</option>
                    <option
                      v-for="negeri in kodNegeriRs"
                      :value="negeri.id"
                      :key="`negeri_${negeri.id}`"
                    >
                      {{ negeri.keterangan }}
                    </option>
                  </select2>
                </div>
              </div>

              <!-- <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="white-space: pre-wrap"
                  >&#9;Daerah<span class="label-required">*</span></label
                >
                <div class="col-md-4">
                  <select2
                    name="wp_kod_daerah_id_bg_20"
                    class="form-control"
                    :class="{
                      'is-invalid select2-hidden-accessible': errors.first(
                        'wp_kod_daerah_id_bg_20'
                      ),
                    }"
                    v-model="form.wp_kod_daerah_id_bg_20"
                    @change="form.wp_kod_bandar_id_bg_20 = null"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Daerah</option>
                    <option
                      v-for="daerah in filteredDaerahRs(
                        form.wp_kod_negeri_id_bg_20
                      )"
                      :value="daerah.id"
                      :key="`daerah_${daerah.id}`"
                    >
                      {{ daerah.keterangan }}
                    </option>
                  </select2>
                </div>
              </div> -->

              <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="white-space: pre-wrap"
                  >&#9;Mukim / Bandar / Pekan
                  <span class="label-required">*</span></label
                >
                <div class="col-md-4">
                  <select2
                    name="wp_kod_bandar_id_bg_20"
                    class="form-control"
                    :class="{
                      'is-invalid': errors.first('wp_kod_bandar_id_bg_20'),
                    }"
                    v-model="form.wp_kod_bandar_id_bg_20"
                    :disabled="readonly"
                  >
                    <option :value="null">
                      Sila Pilih Mukim / Bandar / Pekan
                    </option>
                    <option
                      v-for="bandar in filteredBandarRs(
                        form.wp_kod_daerah_id_bg_20
                      )"
                      :value="bandar.id"
                      :key="`bandar_${bandar.id}`"
                    >
                      {{ bandar.keterangan }}
                    </option>
                  </select2>
                </div>
              </div>
            </section>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >6.&#9;Tarikh Dikeluarkan
                <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <datepicker
                  :class="{
                    'is-invalid': errors.first('wp_tarikh_dikeluarkan_bg_20'),
                  }"
                  name="wp_tarikh_dikeluarkan_bg_20"
                  v-model="form.wp_tarikh_dikeluarkan_bg_20"
                  :disabled="readonly"
                ></datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >7.&#9;Tarikh Mula <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <datepicker
                  :class="{
                    'is-invalid': errors.first('wp_tarikh_sahlaku_bg_20'),
                  }"
                  name="wp_tarikh_sahlaku_bg_20"
                  v-model="form.wp_tarikh_sahlaku_bg_20"
                  :disabled="readonly"
                ></datepicker>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >8.&#9;Tarikh Tamat <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <datepicker
                  :class="{
                    'is-invalid': errors.first('wp_tarikh_luput_bg_20'),
                  }"
                  name="wp_tarikh_luput_bg_20"
                  v-model="form.wp_tarikh_luput_bg_20"
                  :disabled="readonly"
                ></datepicker>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >9.&#9;Dokumen Salinan Jaminan Bank
                <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <file-upload
                  type="file"
                  accept="application/pdf"
                  name="doc_salinan_jaminan_bank_pemajuan_bg_20"
                  class="form-control w-100"
                  :doc="
                    maklumat.akaun_hda
                      ? maklumat.akaun_hda
                          .doc_salinan_jaminan_bank_pemajuan_bg_20
                      : null
                  "
                  :class="{
                    'is-invalid': errors.first(
                      `doc_salinan_jaminan_bank_pemajuan_bg_20`
                    ),
                  }"
                  @uploadwithcallback="
                    ({ file, callback }) =>
                      uploadDoc(
                        'doc_salinan_jaminan_bank_pemajuan_bg_20',
                        file,
                        callback
                      )
                  "
                  @remove="removeDoc('doc_salinan_jaminan_bank_pemajuan_bg_20')"
                  :readonly="readonly"
                />
                <div class="text-danger col-form-label-sm">
                  Hanya dokumen berformat PDF sahaja dibenarkan
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            v-if="
              maklumat.kewangan.pecahan_bg_20 && !form.pengecualian_3peratus
            "
          >
            <br />
            <h5 class="card-title">JAMINAN BANK WANG PENDAHULUAN (BG 20%)</h5>
            <!-- <div class="form-group row">
              <div class="col-md-8 offset-md-4">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="copyInfoBG80" @click="copyInfoBG80">
                  <label class="form-check-label ml-2" for="copyInfoBG80">
                    Salin semula seperti maklumt diatas.
                  </label>
                </div>
              </div>
            </div> -->
            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >1.&#9;No. BG / No. Sijil <span class="label-required">*</span>
              </label>
              <div class="col-md-8">
                <input
                  type="text"
                  name="wp_no_sijil_bg_20"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('wp_no_sijil_bg_20') }"
                  :value="form.wp_no_sijil_bg_20"
                  @input="
                    form.wp_no_sijil_bg_20 = $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >2.&#9;Nama Bank <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <select2
                  name="wp_kod_bank_hq_id_bg_20"
                  class="form-control"
                  :class="{
                    'is-invalid select2-hidden-accessible':
                      errors.first('wp_kod_bank_hq_id_bg_20'),
                  }"
                  v-model="form.wp_kod_bank_hq_id_bg_20"
                  :disabled="readonly"
                  @change="updateBankHqData(`wp_kod_bank_hq_id_bg_20`, { alamat1: 'wp_alamat_bank1_bg_20', alamat2: 'wp_alamat_bank2_bg_20', alamat3: 'wp_alamat_bank3_bg_20', poskod: 'wp_poskod_bg_20' })"
                >
                  <option :value="null">
                    Sila Pilih Nama Bank Yang Berdaftar
                  </option>
                  <option
                    v-for="bank in kodBankHqRs"
                    :value="bank.id"
                    :key="`bank_${bank.id}`"
                  >
                    {{ bank.keterangan }}
                  </option>
                </select2>
              </div>
            </div>

            <div class="form-group row">
              <label
                class="col-form-label col-md-4"
                style="white-space: pre-wrap"
                >3.&#9;Cawangan <span class="label-required">*</span></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  name="wp_nama_cawangan_bg_20"
                  class="form-control"
                  :class="{
                    'is-invalid': errors.first('wp_nama_cawangan_bg_20'),
                  }"
                  :value="form.wp_nama_cawangan_bg_20"
                  @input="
                    form.wp_nama_cawangan_bg_20 =
                      $event.target.value.toUpperCase()
                  "
                  :disabled="readonly"
                />
              </div>
            </div>

            <section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Amaun <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <p class="form-control-plaintext" v-validate="'decimal:2'">{{jumlah_deposit_perlu_dibayar*0.2 | currency}}</p>
                </div>
              </div>
              <section>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Alamat Bank <span class="label-required">*</span></label>
                  <div class="col-md-8">
                    <input
                      type="text" name="wp_alamat_bank1_bg_20"
                      class="form-control" :class="{'is-invalid': errors.first('wp_alamat_bank1_bg_20')}"
                      :value="form.wp_alamat_bank1_bg_20" @input="form.wp_alamat_bank1_bg_20 = $event.target.value.toUpperCase()"
                      :disabled="readonly"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <div class="offset-md-4 col-md-8">
                    <input
                      type="text" name="wp_alamat_bank2_bg_20"
                      class="form-control"
                      :value="form.wp_alamat_bank2_bg_20" @input="form.wp_alamat_bank2_bg_20 = $event.target.value.toUpperCase()"
                      :disabled="readonly"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <div class="offset-md-4 col-md-8">
                    <input
                      type="text" name="wp_alamat_bank3_bg_20"
                      class="form-control"
                      :value="form.wp_alamat_bank3_bg_20" @input="form.wp_alamat_bank3_bg_20 = $event.target.value.toUpperCase()"
                      :disabled="readonly"
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap"
                    >&#9;Poskod <span class="label-required">*</span>
                  </label>
                  <div class="col-md-4">
                    <select2
                      name="wp_poskod_bg_20"
                      class="form-control"
                      :class="{ 'is-invalid': errors.first('wp_poskod_bg_20') }"
                      v-model="form.wp_poskod_bg_20"
                      @input="updatePoskodWpBg20"
                      :disabled="readonly"
                    >
                      <option :value="null">Sila Pilih Poskod</option>
                      <option
                        v-for="poskod in kodPoskodRs"
                        :value="poskod.poskod"
                        :key="`poskod_${poskod.id}`"
                      >
                        {{ poskod.poskod }}
                      </option>
                    </select2>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap"
                    >&#9;Bandar <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2
                      name="wp_kod_bandar_id_bg_20"
                      class="form-control"
                      :class="{ 'is-invalid': errors.first('wp_kod_bandar_id_bg_20') }"
                      v-model="form.wp_kod_bandar_id_bg_20"
                      :disabled="true"
                    >
                      <option :value="null">Sila Pilih Bandar</option>
                      <option
                        v-for="bandar in kodPoskodBandarRs"
                        :value="bandar.id"
                        :key="`bandar_${bandar.id}`"
                      >
                        {{ bandar.keterangan }}
                      </option>
                    </select2>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap"
                    >&#9;Negeri <span class="label-required">*</span></label
                  >
                  <div class="col-md-4">
                    <select2
                      name="wp_kod_negeri_id_bg_20"
                      class="form-control"
                      :class="{
                        'is-invalid select2-hidden-accessible':
                          errors.first('wp_kod_negeri_id_bg_20'),
                      }"
                      v-model="form.wp_kod_negeri_id_bg_20"
                      :disabled="true"
                    >
                      <option :value="null">Sila Pilih Negeri</option>
                      <option
                        v-for="negeri in kodNegeriRs"
                        :value="negeri.id"
                        :key="`negeri_${negeri.id}`"
                      >
                        {{ negeri.keterangan }}
                      </option>
                    </select2>
                  </div>
                </div>
              </section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Tarikh Dikeluarkan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <datepicker
                    :class="{'is-invalid': errors.first('wp_tarikh_dikeluarkan_bg_20')}"
                    name="wp_tarikh_dikeluarkan_bg_20"
                    v-model="form.wp_tarikh_dikeluarkan_bg_20"
                    :disabled="readonly"
                  ></datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Tarikh Mula <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <datepicker
                    :class="{'is-invalid': errors.first('wp_tarikh_sahlaku_bg_20')}"
                    name="wp_tarikh_sahlaku_bg_20"
                    v-model="form.wp_tarikh_sahlaku_bg_20"
                    :disabled="readonly"
                  ></datepicker>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Tarikh Tamat <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <datepicker
                    :class="{'is-invalid': errors.first('wp_tarikh_luput_bg_20')}"
                    name="wp_tarikh_luput_bg_20"
                    v-model="form.wp_tarikh_luput_bg_20"
                    :disabled="readonly"
                  ></datepicker>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">9.&#9;Dokumen Salinan Jaminan Bank <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_salinan_jaminan_bank_pemajuan_bg_20"
                    class="form-control w-100"
                    :doc="maklumat.akaun_hda ? maklumat.akaun_hda.doc_salinan_jaminan_bank_pemajuan_bg_20 : null"
                    :class="{'is-invalid': errors.first(`doc_salinan_jaminan_bank_pemajuan_bg_20`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_salinan_jaminan_bank_pemajuan_bg_20', file, callback)"
                    @remove="removeDoc('doc_salinan_jaminan_bank_pemajuan_bg_20')"
                    :readonly="readonly"
                  />
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <br />
      <section class="form-compact">
        <h5 class="card-title">JAMINAN BANK TANAH</h5>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >1.&#9;No. BG / No. Sijil
          </label>
          <div class="col-md-8">
            <input
              type="text"
              name="tanah_no_sijil"
              class="form-control"
              :class="{ 'is-invalid': errors.first('tanah_no_sijil') }"
              :value="form.tanah_no_sijil"
              @input="form.tanah_no_sijil = $event.target.value.toUpperCase()"
              :disabled="readonly"
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >2.&#9;Amaun
          </label>
          <div class="col-md-8">
            <money
              type="text"
              name="tanah_amaun"
              class="form-control"
              :class="{ 'is-invalid': errors.first('tanah_amaun') }"
              v-model="form.tanah_amaun"
              :disabled="readonly"
              v-validate="'decimal:2'"
            ></money>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >3.&#9;Nama Bank
          </label>
          <div class="col-md-8">
            <select2
              name="tanah_kod_bank_hq_id"
              class="form-control"
              :class="{
                'is-invalid select2-hidden-accessible':
                  errors.first('tanah_kod_bank_hq_id'),
              }"
              v-model="form.tanah_kod_bank_hq_id"
              :disabled="readonly"
              @change="updateBankHqData(`tanah_kod_bank_hq_id`, { alamat1: 'tanah_alamat_bank1', alamat2: 'tanah_alamat_bank2', alamat3: 'tanah_alamat_bank3', poskod: 'tanah_poskod' })"
            >
              <option :value="null">
                Sila Pilih Nama Bank Yang Berdaftar
              </option>
              <option
                v-for="bank in kodBankHqRs"
                :value="bank.id"
                :key="`bank_${bank.id}`"
              >
                {{ bank.keterangan }}
              </option>
            </select2>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >4.&#9;Cawangan
          </label>
          <div class="col-md-8">
            <input
              type="text"
              name="tanah_nama_cawangan"
              class="form-control"
              :class="{ 'is-invalid': errors.first('tanah_nama_cawangan') }"
              :value="form.tanah_nama_cawangan"
              @input="
                form.tanah_nama_cawangan = $event.target.value.toUpperCase()
              "
              :disabled="readonly"
            />
          </div>
        </div>

        <section>
          <div class="form-group row">
            <label class="col-form-label col-md-4"
              >5.&#9;Alamat Bank
            </label>
            <div class="col-md-8">
              <input
                type="text"
                name="tanah_alamat_bank1"
                class="form-control"
                :class="{ 'is-invalid': errors.first('tanah_alamat_bank1') }"
                :value="form.tanah_alamat_bank1"
                @input="
                  form.tanah_alamat_bank1 = $event.target.value.toUpperCase()
                "
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-md-4 col-md-8">
              <input
                type="text"
                name="tanah_alamat_bank2"
                class="form-control"
                :value="form.tanah_alamat_bank2"
                @input="
                  form.tanah_alamat_bank2 = $event.target.value.toUpperCase()
                "
                :disabled="readonly"
              />
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-md-4 col-md-8">
              <input
                type="text"
                name="tanah_alamat_bank3"
                class="form-control"
                :value="form.tanah_alamat_bank3"
                @input="
                  form.tanah_alamat_bank3 = $event.target.value.toUpperCase()
                "
                :disabled="readonly"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Poskod
            </label>

            <div class="col-md-4">
              <select2
                name="tanah_poskod"
                class="form-control"
                :class="{ 'is-invalid': errors.first('tanah_poskod') }"
                 v-model="form.tanah_poskod"
                @input="updateTanahPoskod"
                :disabled="readonly"
              >
              <option :value="null">Sila Pilih Poskod</option>
                  <option
                    v-for="poskod in kodPoskodRs"
                    :value="poskod.poskod"
                    :key="`poskod_${poskod.id}`"
                  >
                    {{ poskod.poskod }}
                  </option>
                </select2>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Bandar
            </label>
            <div class="col-md-4">
              <select2
                name="tanah_kod_bandar_id"
                class="form-control"
                :class="{ 'is-invalid': errors.first('tanah_kod_bandar_id') }"
                v-model="form.tanah_kod_bandar_id"
                :disabled="true"
              >
                <option :value="null">Sila Pilih Bandar</option>
                <option
                  v-for="bandar in kodPoskodBandarRs"
                  :value="bandar.id"
                  :key="`bandar_${bandar.id}`"
                >
                  {{ bandar.keterangan }}
                </option>
              </select2>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Negeri
            </label>
            <div class="col-md-4">
              <select2
                name="tanah_kod_negeri_id"
                class="form-control"
                :class="{
                  'is-invalid select2-hidden-accessible': errors.first(
                    'tanah_kod_negeri_id'
                  ),
                }"
                v-model="form.tanah_kod_negeri_id"
                :disabled="true"
              >
                <option :value="null">Sila Pilih Negeri</option>
                <option
                  v-for="negeri in kodNegeriRs"
                  :value="negeri.id"
                  :key="`negeri_${negeri.id}`"
                >
                  {{ negeri.keterangan }}
                </option>
              </select2>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >&#9;Daerah</label
            >
            <div class="col-md-4">
              <select2
                name="tanah_kod_daerah_id"
                class="form-control"
                :class="{
                  'is-invalid select2-hidden-accessible': errors.first(
                    'tanah_kod_daerah_id'
                  ),
                }"
                v-model="form.tanah_kod_daerah_id"
                @change="form.tanah_kod_bandar_id = null"
                :disabled="readonly"
              >
                <option :value="null">Sila Pilih Daerah</option>
                <option
                  v-for="daerah in filteredDaerahRs(form.tanah_kod_negeri_id)"
                  :value="daerah.id"
                  :key="`daerah_${daerah.id}`"
                >
                  {{ daerah.keterangan }}
                </option>
              </select2>
            </div>
          </div> -->

        </section>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >6.&#9;Tarikh Dikeluarkan
          </label>
          <div class="col-md-4">
            <datepicker
              :class="{
                'is-invalid': errors.first('tanah_tarikh_dikeluarkan'),
              }"
              name="tanah_tarikh_dikeluarkan"
              v-model="form.tanah_tarikh_dikeluarkan"
              :disabled="readonly"
            ></datepicker>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >7.&#9;Tarikh Mula
          </label>
          <div class="col-md-4">
            <datepicker
              :class="{ 'is-invalid': errors.first('tanah_tarikh_sahlaku') }"
              name="tanah_tarikh_sahlaku"
              v-model="form.tanah_tarikh_sahlaku"
              :disabled="readonly"
            ></datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >8.&#9;Tarikh Luput
          </label>
          <div class="col-md-4">
            <datepicker
              :class="{ 'is-invalid': errors.first('tanah_tarikh_luput') }"
              name="tanah_tarikh_luput"
              v-model="form.tanah_tarikh_luput"
              :disabled="readonly"
            ></datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >9.&#9;Dokumen Salinan Jaminan Bank
          </label>
          <div class="col-md-8">
            <file-upload
              type="file"
              accept="application/pdf"
              name="doc_salinan_jaminan_bank_tanah"
              class="form-control w-100"
              :doc="
                maklumat.akaun_hda
                  ? maklumat.akaun_hda.doc_salinan_jaminan_bank_tanah
                  : null
              "
              :class="{
                'is-invalid': errors.first(`doc_salinan_jaminan_bank_tanah`),
              }"
              @uploadwithcallback="
                ({ file, callback }) =>
                  uploadDoc('doc_salinan_jaminan_bank_tanah', file, callback)
              "
              @remove="removeDoc('doc_salinan_jaminan_bank_tanah')"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">
              Hanya dokumen berformat PDF sahaja dibenarkan
            </div>
          </div>
        </div>
      </section>
    </div>

    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"
          ><span class="text-danger mr-2" v-if="hErrorMessage">{{
            hErrorMessage
          }}</span></transition
        >
        <transition name="fade"
          ><span class="text-success mr-2" v-if="succesfulMessage">{{
            succesfulMessage
          }}</span></transition
        >
        <button
          class="btn btn-primary"
          @click.prevent="save"
          :disabled="loading"
        >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianQ",
  props: {
    maklumat: Object,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodBankHqRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,

    readonly: {
      type: Boolean,
      default: false,
    },

    overlay: {
      type: Boolean,
      default: false,
    },

    showAmaunPerluDibayar: {
      type: Boolean,
      default: true,
    },

    kuiri: null,
  },

  created: function(){
    this.onOpen()
  },

  data() {
    return {
      loading: false,
      selectedPoskod: null,
      selectedTanahPoskod: null,
      form: this.parse(this.maklumat),

    };

  },

  created: function () {
    this.onOpen();
  },

  methods: {
    parse(maklumat) {
      return {
        hda_no_akaun:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_no_akaun
            : null,
        hda_nama_bank:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_nama_bank
            : null,
        hda_alamat_bank1:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_alamat_bank1
            : null,
        hda_alamat_bank2:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_alamat_bank2
            : null,
        hda_alamat_bank3:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_alamat_bank3
            : null,
        hda_poskod:
          maklumat && maklumat.akaun_hda ? maklumat.akaun_hda.hda_poskod : null,
        hda_kod_negeri_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_kod_negeri_id
            : null,
        hda_kod_daerah_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_kod_daerah_id
            : null,
        hda_kod_bandar_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_kod_bandar_id
            : null,
        hda_no_telefon:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_no_telefon
            : null,
        hda_tarikh_dibuka:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.hda_tarikh_dibuka
            : null,

        kod_jenis_wp_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.kod_jenis_wp_id
            : null,
        wp_amaun:
          (maklumat && maklumat.akaun_hda ? maklumat.akaun_hda.wp_amaun : 0) ||
          null,

        wp_no_sijil:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_no_sijil
            : null,
        wp_nama_bank:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_nama_bank
            : null,
        wp_nama_cawangan:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_nama_cawangan
            : null,
        wp_alamat_bank1:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_alamat_bank1
            : null,
        wp_alamat_bank2:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_alamat_bank2
            : null,
        wp_alamat_bank3:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_alamat_bank3
            : null,
        wp_poskod:
          maklumat && maklumat.akaun_hda ? maklumat.akaun_hda.wp_poskod : null,
        wp_kod_negeri_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_kod_negeri_id
            : null,
        wp_kod_daerah_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_kod_daerah_id
            : null,
        wp_kod_bandar_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_kod_bandar_id
            : null,

        wp_tarikh_dikeluarkan:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_tarikh_dikeluarkan
            : null,
        wp_tarikh_sahlaku:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_tarikh_sahlaku
            : null,
        wp_tarikh_luput:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_tarikh_luput
            : null,

        wp_no_sijil_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_no_sijil_bg_20
            : null,
        wp_nama_bank_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_nama_bank_bg_20
            : null,
        wp_nama_cawangan_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_nama_cawangan_bg_20
            : null,
        wp_amaun_bg_20:
          (maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_amaun_bg_20
            : maklumat.kewangan.pecahan_bg_20) ||
          maklumat.kewangan.pecahan_bg_20,
        wp_alamat_bank1_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_alamat_bank1_bg_20
            : null,
        wp_alamat_bank2_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_alamat_bank2_bg_20
            : null,
        wp_alamat_bank3_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_alamat_bank3_bg_20
            : null,
        wp_poskod_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_poskod_bg_20
            : null,
        wp_kod_negeri_id_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_kod_negeri_id_bg_20
            : null,
        wp_kod_daerah_id_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_kod_daerah_id_bg_20
            : null,
        wp_kod_bandar_id_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_kod_bandar_id_bg_20
            : null,

        wp_tarikh_dikeluarkan_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_tarikh_dikeluarkan_bg_20
            : null,
        wp_tarikh_sahlaku_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_tarikh_sahlaku_bg_20
            : null,
        wp_tarikh_luput_bg_20:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.wp_tarikh_luput_bg_20
            : null,

        tanah_no_sijil:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_no_sijil
            : null,
        tanah_amaun:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_amaun
            : null,
        tanah_nama_bank:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_nama_bank
            : null,
        tanah_nama_cawangan:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_nama_cawangan
            : null,
        tanah_alamat_bank1:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_alamat_bank1
            : null,
        tanah_alamat_bank2:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_alamat_bank2
            : null,
        tanah_alamat_bank3:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_alamat_bank3
            : null,
        tanah_poskod:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_poskod
            : null,
        tanah_kod_negeri_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_kod_negeri_id
            : null,
        tanah_kod_daerah_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_kod_daerah_id
            : null,
        tanah_kod_bandar_id:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_kod_bandar_id
            : null,

        tanah_tarikh_dikeluarkan:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_tarikh_dikeluarkan
            : null,
        tanah_tarikh_sahlaku:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_tarikh_sahlaku
            : null,
        tanah_tarikh_luput:
          maklumat && maklumat.akaun_hda
            ? maklumat.akaun_hda.tanah_tarikh_luput
            : null,

        pengecualian_3peratus: maklumat ? maklumat.pengecualian_3peratus : null,

        // property autofill
        kod_bank_hq_id: maklumat?.akaun_hda?.kod_bank_hq_id || null,
        hda_alamat_hq_bank1: maklumat?.akaun_hda?.hda_alamat_hq_bank1 || null,
        hda_alamat_hq_bank2: maklumat?.akaun_hda?.hda_alamat_hq_bank2 || null,
        hda_alamat_hq_bank3: maklumat?.akaun_hda?.hda_alamat_hq_bank3 || null,
        hda_hq_poskod: maklumat?.akaun_hda?.hda_hq_poskod || null,
        hda_kod_negeri_hq_id: maklumat?.akaun_hda?.hda_kod_negeri_hq_id || null,
        hda_kod_daerah_hq_id: maklumat?.akaun_hda?.hda_kod_daerah_hq_id || null,
        hda_kod_bandar_hq_id: maklumat?.akaun_hda?.hda_kod_bandar_hq_id || null,

        // tanah property autofill
        tanah_kod_bank_hq_id: maklumat?.akaun_hda?.tanah_kod_bank_hq_id || null,
        tanah_poskod: maklumat?.akaun_hda?.tanah_poskod || null,

        // wp
        wp_kod_bank_hq_id: maklumat?.akaun_hda?.wp_kod_bank_hq_id || null,
        wp_poskod: maklumat?.akaun_hda?.wp_poskod || null,

        // wp_bg_20
        wp_kod_bank_hq_id_bg_20: maklumat?.akaun_hda?.wp_kod_bank_hq_id_bg_20 || null,
        wp_poskod_bg_20: maklumat?.akaun_hda?.wp_poskod_bg_20 || null,

      };
    },
    // method auto fill
    updateBankHqData(formSelect, { alamat1, alamat2, alamat3, poskod }) {
      const selectedBankId = parseInt(this.form[formSelect]);
      const selectedBank = this.kodBankHqRs.find((item) => item.id == selectedBankId);
      
      if (!selectedBank) {
        this.form[alamat1] = null;
        this.form[alamat2] = null;
        this.form[alamat3] = null;
        this.form[poskod] = null;

        return;
      }

      this.form[alamat1] = selectedBank.alamat1;
      this.form[alamat2] = selectedBank.alamat2;
      this.form[alamat3] = selectedBank.alamat3;
      this.form[poskod] = selectedBank.poskod;

      switch (formSelect) {
        case 'kod_bank_hq_id':
          this.$nextTick(() => {
            this.updatePoskodHqBank();
          })
        break;

        case 'tanah_kod_bank_hq_id':
          this.$nextTick(() => {
            this.updateTanahPoskod();
          })
        break;

        case 'wp_kod_bank_hq_id':
          this.$nextTick(() => {
            this.updatePoskodWp();
          })
        break;

        case 'wp_kod_bank_hq_id_bg_20':
          this.$nextTick(() => {
            this.updatePoskodWpBg20();
          })
        break;
      
        default:
          break;
      }
    },

    onOpen() {
      if (this.form.kod_bank_hq_id) {
        this.updateBankHqData(`kod_bank_hq_id`, { alamat1: 'hda_alamat_hq_bank1', alamat2: 'hda_alamat_hq_bank2', alamat3: 'hda_alamat_hq_bank3', poskod: 'hda_hq_poskod' });
      }
      if (
        this.maklumat.kewangan.pecahan_bg_80 &&
        !this.maklumat.pengecualian_3peratus
      ) {
        this.form.wp_amaun = this.maklumat.kewangan.pecahan_bg_80;
      } else if (this.maklumat.pengecualian_3peratus) {
        this.form.wp_amaun = maklumat.akaun_hda.wp_amaun;
      } else {
        this.form.wp_amaun = this.maklumat.kewangan.jumlah_deposit_keseluruhan;
      }
      if (
        this.maklumat.kewangan.pecahan_bg_20 &&
        !this.maklumat.pengecualian_3peratus
      ) {
        this.form.wp_amaun_bg_20 = this.maklumat.kewangan.pecahan_bg_20;
      }
    },

    copyInfoBG80(e) {
      if (e.target.checked) {
        this.form.wp_nama_bank_bg_20 = this.form.wp_nama_bank;
        this.form.wp_nama_cawangan_bg_20 = this.form.wp_nama_cawangan;
        this.form.wp_alamat_bank1_bg_20 = this.form.wp_alamat_bank1;
        this.form.wp_alamat_bank2_bg_20 = this.form.wp_alamat_bank2;
        this.form.wp_alamat_bank3_bg_20 = this.form.wp_alamat_bank3;
        this.form.wp_poskod_bg_20 = this.form.wp_poskod;
        this.form.wp_kod_negeri_id_bg_20 = this.form.wp_kod_negeri_id;
        this.form.wp_kod_daerah_id_bg_20 = this.form.wp_kod_daerah_id;
        this.form.wp_kod_bandar_id_bg_20 = this.form.wp_kod_bandar_id;
        this.form.wp_tarikh_sahlaku_bg_20 = this.form.wp_tarikh_sahlaku;
        this.form.wp_tarikh_luput_bg_20 = this.form.wp_tarikh_luput;
      }
    },

    is3Percent(e) {
      if (this.form.kod_jenis_wp_id == "S") {
        if (e.target.value == 1) {
          this.form.wp_amaun =
            this.maklumat.kewangan.jumlah_deposit_keseluruhan;
        } else {
          this.form.wp_amaun = this.maklumat.kewangan.pecahan_bg_80;
        }
      }
      if (this.form.kod_jenis_wp_id == "T") {
        this.form.wp_amaun = this.maklumat.kewangan.jumlah_deposit_keseluruhan;
      }
    },

    async save() {
      if (this.loading) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.loading = true;

      axios
        .post(
          `/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-q`,
          this.form
        )
        .then((response) => {
          this.form = this.parse(response.data);
          this.updateBankHqData(`kod_bank_hq_id`, { alamat1: 'hda_alamat_hq_bank1', alamat2: 'hda_alamat_hq_bank2', alamat3: 'hda_alamat_hq_bank3', poskod: 'hda_hq_poskod' });
          this.$emit("update", JSON.parse(JSON.stringify(response.data)));
          this.$success();
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append("doc", file, file.name);

      // save it
      axios
        .post(
          `/pemaju/ap-permit-baru/${this.maklumat.id}/hda/dokumen/${key}`,
          formData
        )
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit("update", maklumat);
          this.$success(
            `Berjaya muat naik dokumen. (${this.$options.filters.filesize(
              file.size
            )})`
          );
        })
        .catch((error) => {
          this.$hError(error);
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      // console.log(this.maklumat.akaun_hda[key]);
      if (!(this.maklumat.akaun_hda && this.maklumat.akaun_hda[key])) {
        return;
      }

      // save it
      axios
        .delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/hda/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat.akaun_hda[key] = null;

          this.$emit("update", maklumat);
        })
        .catch(() => {});
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(
        (item) => item.kod_negeri_id == kod_negeri_id
      );
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(
        (item) => item.kod_daerah_id == kod_daerah_id
      );
    },

    updatePoskod() {
      const selectedPoskod = this.kodPoskodRs.find((item) => item.poskod == this.form.hda_poskod);

      if(!selectedPoskod) {
        this.form.hda_kod_negeri_id = null;
        this.form.hda_kod_bandar_id = null;
      } else {
        this.form.hda_kod_negeri_id = selectedPoskod.kod_negeri_id;
        this.form.hda_kod_bandar_id = selectedPoskod.kod_bandar_id;
      }
    },

    updatePoskodWp() {
      const selectedPoskod = this.kodPoskodRs.find((item) => item.poskod == this.form.wp_poskod);

      if(!selectedPoskod) {
        this.form.wp_kod_negeri_id = null;
        this.form.wp_kod_bandar_id = null;
      } else {
        this.form.wp_kod_negeri_id = selectedPoskod.kod_negeri_id;
        this.form.wp_kod_bandar_id = selectedPoskod.kod_bandar_id;
      }
    },

    updatePoskodWpBg20() {
      const selectedPoskod = this.kodPoskodRs.find((item) => item.poskod == this.form.wp_poskod_bg_20);

      if(!selectedPoskod) {
        this.form.wp_kod_negeri_id_bg_20 = null;
        this.form.wp_kod_bandar_id_bg_20 = null;
      } else {
        this.form.wp_kod_negeri_id_bg_20 = selectedPoskod.kod_negeri_id;
        this.form.wp_kod_bandar_id_bg_20 = selectedPoskod.kod_bandar_id;
      }
    },

    updateTanahPoskod() {
      const selectedPoskod = this.kodPoskodRs.find((item) => item.poskod == this.form.tanah_poskod);

      if(!selectedPoskod) {
        this.form.tanah_kod_negeri_id = null;
        this.form.tanah_kod_bandar_id = null;
      } else {
        this.form.tanah_kod_negeri_id = selectedPoskod.kod_negeri_id;
        this.form.tanah_kod_bandar_id = selectedPoskod.kod_bandar_id;
      }
    },

    updatePoskodHqBank() {
      const selectedPoskod = this.kodPoskodRs.find((item) => item.poskod == this.form.hda_hq_poskod);
      
      if(!selectedPoskod) {
        this.form.hda_kod_bandar_hq_id = null;
        this.form.hda_kod_negeri_hq_id = null;
      } else {
        this.form.hda_kod_bandar_hq_id = selectedPoskod.kod_bandar_id;
        this.form.hda_kod_negeri_hq_id = selectedPoskod.kod_negeri_id;
      }
    }
  },
  computed: {
    jumlah_deposit_perlu_dibayar() {
      if (
        this.maklumat.kewangan.kos_keseluruhan_harga_bebas > 0 &&
        this.maklumat.kewangan.kos_keseluruhan_harga_mampumilik > 0
      ) {
        return this.maklumat.kewangan.jumlah_deposit_keseluruhan;
      } else if (
        this.maklumat.kod_skim_projek_id &&
        this.maklumat.kewangan.kos_keseluruhan_harga_bebas == 0
      ) {
        if (this.maklumat.kewangan.jumlah_deposit_keseluruhan >= 200001) {
          return parseFloat(200000 || 0);
        } else if (this.maklumat.kewangan.jumlah_deposit_keseluruhan < 200000) {
          return parseFloat(
            this.maklumat.kewangan.jumlah_deposit_keseluruhan || 0
          );
        } else if (
          (this.maklumat.kewangan.jumlah_deposit_keseluruhan = 200000)
        ) {
          return parseFloat(
            this.maklumat.kewangan.estimated_gdc_harga_mampumilik || 0
          );
        }
      } else {
        return (
          (parseFloat(this.maklumat.kewangan.jumlah_deposit_harga_bebas || 0) *
            100 +
            parseFloat(
              this.maklumat.kewangan.jumlah_deposit_harga_mampumilik || 0
            ) *
              100) /
          100
        );
      }
    },
    disableDaerah() {
      const result = ['14', '16'].includes(this.form.hda_kod_negeri_hq_id);
      //console.info("disableDaerah(): " + result);
      return result;
    },
    disableBandar() {
      return ['14', '16', '10'].includes(this.form.hda_kod_negeri_hq_id);
    },
    // selectedBandar() {
    //   return this.kodBandarRs.find(bandar => bandar.id === this.form.hda_kod_bandar_id);
    // },
    // selectedNegeri() {
    //   return this.kodNegeriRs.find(negeri => negeri.id === this.form.hda_kod_negeri_id);
    // },
  },
};
</script>

<style scoped>
</style>
