<template>
  <section class="list-group">
    <div
      class="list-group-item form-row d-flex"
      v-for="(config, index) in default_docs"
      :key="`doc-${config.id}`"
    >
      <label class="col-form-label col-md-8">
        <span
          v-if="
            !readonly && !config.flag_lulus && config.mandatori_ind && showKuiri
          "
          class="text-danger"
          ><i class="fas fa-exclamation-circle"></i
        ></span>
        {{ index + 1 }}. <span v-html="config.nama_dokumen"></span>
        <span class="label-required" v-if="config.mandatori_ind">*</span>
        <span
          v-if="
            config.nama_dokumen ===
            'Salinan Laporan Juruaudit kepada Ahli-Ahli Lembaga Syarikat'
          "
          class="italic"
          ><br />
          Independent Auditor's Report To The Member Of The Company
        </span>
      </label>
      <div class="col-md-4">
        <div class="form-inline">
          <file-upload
            v-if="!config.multiple"
            type="file"
            :accept="acceptType"
            :name="`doc[${config.id}]`"
            class="form-control flex-grow-1 mr-2"
            :class="{ 'is-invalid': errors.first(`doc[${config.id}]`) }"
            :doc="getDoc(config)"
            @upload="(file) => uploadDoc(file, config)"
            @remove="(doc) => removeDoc(doc, config)"
            v-validate="config.mandatori_ind ? 'required' : ''"
            :readonly="(showKuiri && !!config.flag_lulus) || readonly"
          />
          <file-upload-multi
            v-else
            type="file"
            :accept="acceptType"
            :name="`doc[${config.id}]`"
            class="form-control flex-grow-1 mr-2"
            :class="{ 'is-invalid': errors.first(`doc[${config.id}]`) }"
            :docs="getDoc(config)"
            @upload="
              ({ files, clear }) => uploadDocMultiple(files, config, clear)
            "
            @remove="(doc) => removeDoc(doc, config)"
            v-validate="config.mandatori_ind ? 'required' : ''"
            :readonly="(showKuiri && !!config.flag_lulus) || readonly"
          />
          <a
            v-if="checkDocConfig(index)"
            class="d-flex align-items-center mt-1 text-decoration-none"
            target="_blank"
            :href="
              docConfigs[index].contoh_dokumen_url
                ? docConfigs[index].contoh_dokumen_url
                : 'javascript:void(0);'
            "
            data-toggle="tooltip"
            data-placement="left"
            title="Contoh Dokumen"
          >
            <span class="badge badge-primary mr-1">Contoh</span>
          </a>
        </div>
      </div>

      <div
        class="col-md-12 mt-2"
        v-if="showKuiri && !readonly && !config.flag_lulus && config.kuiri"
      >
        <label style="font-weight: 500">Catatan Kuiri</label>
        <textarea class="form-control" disabled>{{
          config.kuiri.catatan_kuiri
        }}</textarea>
        <br />
        <div v-if="config.kuiri.catatan_kuiri">
          <strong>Catatan Maklumbalas</strong>
          <textarea
            @click.stop
            class="form-control"
            :name="`kuiri_permohonan_rs[${config.kuiri.id}][catatan_maklumbalas_kuiri]`"
            v-model="config.kuiri.catatan_maklumbalas_kuiri"
            :disabled="config.kuiri.tarikh_maklumbalas_kuiri"
          ></textarea>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PemajuPermohonanDokumen",
  inject: ["$validator"],

  props: {
    default_docs: Array,

    readonly: {
      type: Boolean,
      default: false,
    },

    showKuiri: {
      type: Boolean,
      default: true,
    },

    showReply: {
      type: Boolean,
      default: false,
    },

    acceptType: {
      type: String,
      default: "application/pdf",
    },

    docConfigs: Array,
  },

  methods: {
    getDoc(config) {
      if (config.multiple) {
        return config.muatnaik_rs.map((item) => item.dokumen);
      }

      return config.muatnaik_rs.length ? config.muatnaik_rs[0].dokumen : null;
    },

    uploadDoc(file, config) {
      const formData = new FormData();

      // append the files to FormData
      formData.append("doc", file, file.name);

      let docs = JSON.parse(JSON.stringify(this.default_docs));
      const index = this.default_docs.indexOf(config);

      // save it
      axios
        .post(`/pemaju/permohonan/dokumen/${config.id}`, formData)
        .then((response) => {
          docs[index].muatnaik_rs = response.data;
          this.$emit("update:default_docs", docs);
        })
        .catch(() => {});
    },

    uploadDocMultiple(files, config, callback) {
      // append the files to FormData
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const formData = new FormData();
        formData.append("doc", file, file.name);

        let docs = JSON.parse(JSON.stringify(this.default_docs));
        const index = this.default_docs.indexOf(config);

        // save it
        axios
          .post(`/pemaju/permohonan/dokumen/${config.id}`, formData)
          .then((response) => {
            docs[index].muatnaik_rs = response.data;
            this.$emit("update:default_docs", docs);
            callback();
          })
          .catch(() => {});
      }
    },

    removeDoc(doc, config) {
      if (!doc) return;

      let docs = JSON.parse(JSON.stringify(this.default_docs));
      const index = this.default_docs.indexOf(config);

      axios
        .delete(`/pemaju/permohonan/dokumen/${config.id}/${doc.id}`)
        .then((response) => {
          docs[index].muatnaik_rs = response.data;
          this.$emit("update:default_docs", docs);
        })
        .catch(() => {});
    },

    checkDocConfig(index) {
      if (this.docConfigs != null) {
        return this.docConfigs[index].contoh_dokumen_ind;
      }
    },
  },
};
</script>

<style scoped>
.list-group .list-group-item:hover {
  background: rgba(232, 243, 143, 0.3);
}

.italic {
  font-style: italic;
}
</style>
