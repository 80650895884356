<template>
  <div class="card">
    <div class="card-body">
      <error-bar :messages="errorMessages" @close="errorMessages = {}"></error-bar>

      <div class="form-check" v-if="!readonly">
        <label for="perakuan" class="form-check-label">
          <input type="checkbox" value="" id="perakuan" class="form-check-input" v-model="form.perakaun">

          Saya mengaku semua butiran yang saya kemukakan adalah benar dan jika didapati maklumat yang dikemukakan adalah palsu, pihak Jabatan Perumahan Negara berhak menarik
          balik Lesen Pemajuan Perumahan yang telah diberikan
        </label>
      </div>


    </div>
    <div class="card-footer">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button v-if="!readonly && form.perakaun && !passes" class="btn btn-warning" @click="semak">Semak</button>
        <button v-if="!readonly && form.perakaun && passes" class="btn btn-success" @click="$emit('hantar')">Hantar</button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBar from "../../common/ErrorBar";
export default {
  name: "BahagianE",
  components: {ErrorBar},
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
      errorMessages: {},

      passes: false,

      form: {
        perakaun: false,
      }
    }
  },

  methods: {
    semak() {
      this.loading = true;
      this.errorMessages = {};

      if (this.isModalBerbayarInvalid) {
        swal.fire({
          title: 'SILA PASTIKAN MODAL BERBAYAR LEBIH DARI RM 250K',
          icon: 'warning',
          confirmButtonColor: '#0069d9',
          confirmButtonText: 'OK',
        })

        return;
      }

      axios.post(`/pemaju/setup/semak`)
        .then(() => {
          this.passes = true;
        })
        .catch((error) => {
          this.errorMessages = error.response.data;
        })
        .then(() => {
          this.loading = false;
        });
    },
  },

  computed: {
    isModalBerbayarInvalid() {
      return (parseFloat(this.maklumat.amaun_modal_tunai.toString()) + parseFloat(this.maklumat.amaun_modal_bukantunai.toString())) < 250000;
    },
  },
}
</script>

<style scoped>

</style>
