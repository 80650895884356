<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">No Unit / Lot {{ unit.no_unit }} - (Peguam)</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <section>
              <h4 class="card-title">MAKLUMAT UNIT</h4>
              <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Lot PT :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_lot_pt }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Unit :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_unit }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Harga Jualan Yang Diluluskan :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">
                            {{ unit.harga_jualan | currency("RM", ",", 2, ".", "front", false) }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Pembeli Pertama :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ isFirstBuyer ? "Ya" : "Tidak" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="d-flex justify-content-between my-3">
                <h4 class="card-title">MAKLUMAT CALON PEMBELI</h4>
                <div v-if="pembeliNoPeguam.length > 0">
                  <button type="button" class="btn btn-primary" id="btnTambahPembeli"
                          @click.prevent="bukaBorang">Tambah Peguam
                  </button>
                </div>
                <div v-else>
                  <button type="button" class="btn btn-primary" style="cursor: not-allowed;opacity: 0.65"
                          title="Tidak dibenarkan untuk menambah calon pembeli peguam">
                    Tambah Peguam
                  </button>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-center" v-if="loading" style="min-height: 100px;">
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block; shape-rendering: auto;"
                     width="50px" height="50px" viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" r="6.57689" fill="none" stroke="#3887b4" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                  </circle>
                  <circle cx="50" cy="50" r="16.4808" fill="none" stroke="#5699d2" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline"></animate>
                  </circle>
                </svg>
              </div>
              <div v-else>
                <div class="table-responsive">
                  <table class="table table-striped ">
                    <thead class="bg-success text-white">
                    <tr>
                      <th>Kategori Pembeli</th>
                      <th>Nama / Syarikat</th>
                      <th>No. KP / Passport / SSM</th>
                      <th>Bangsa</th>
                      <th>Negara</th>
                      <th nowrap width="1%">Status</th>
                      <th class="text-center">Peguam</th>
                      <th class="text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="pembeli_rs.length">
                      <tr v-for="(row, index) in pembeli_rs" :key="`pembeli-${index}`">
                        <td>
                          {{
                            optional(kodJenisPemilikanRs.find(item => row.kod_jenis_pemilikan_id == item.id)).keterangan
                          }}
                        </td>
                        <td>{{ row.nama_pembeli }}</td>
                        <td>{{ row.no_kp }}</td>
                        <td>{{ optional(kodKaumRs.find(item => row.kod_bangsa_id == item.id)).keterangan }}</td>
                        <td style="text-transform: uppercase;">{{ optional(kodNegaraRs.find(item => row.kod_negara_id == item.id)).keterangan }}</td>
                        <td nowrap>{{ row.status_pembeli }}</td>
                        <td nowrap class="text-center">
                          <span v-if="row.nama_firma_guaman_pembeli != null && row.nama_firma_guaman_pemaju != null && row.peguam_id_pemaju != null && row.nama_firma_guaman_stakeholder != null">Ada</span>
                          <span v-else>Tiada</span>
                        </td>
                        <td nowrap class="text-center">
                          <button class="btn btn-icon btn-sm d-inline" data-toggle="tooltip"
                                    title="Kemaskini Maklumat Calon Pembeli Peguam" @click.prevent="editPeguam(row.id)"><i
                          class="fas fa-edit"></i></button>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="99">
                        <div class="alert alert-warning">Tiada maklumat calon pembeli.</div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <section>
                  <div v-show="openBorang">
                    <div class="card mb-3">
                      <div class="card-header d-flex justify-content-between">
                        <h5 class="card-title">BORANG PENDAFTARAN PEGUAM</h5>
                        <button type="button" class="close" @click.prevent="tutupBorang" aria-label="Close"
                                title="Tutup Borang">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-6">
                            <div class="form-group row" style="align-items: baseline;">
                              <label class="col-md-4 col-form-label">Calon Pembeli <span
                                class="label-required">*</span></label>
                              <div class="col-md-8" v-if="action == 'Daftar'">
                                <select v-model="form.calon_pembeli_id" class="form-control"
                                  :class="{'is-invalid': errors.first('calon_pembeli_id')}"
                                  v-validate="'required'"
                                  name="calon_pembeli_id" data-vv-as="Calon Pembeli"
                                  :disabled="disabled == 1">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="pembeli in pembeliNoPeguam" :value="pembeli.id">
                                  {{ pembeli.nama_pembeli }} ({{ pembeli.no_kp }})
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("calon_pembeli_id") }}</span>
                              </div>
                              <div class="col-md-8" v-else>
                                <input v-model="form.nama_calon_pembeli"
                                  type="text" class="form-control mt-3"
                                  :class="{'is-invalid': errors.first('nama_calon_pembeli')}"
                                  name="nama_calon_pembeli"
                                  readonly
                                >
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="card-title">MAKLUMAT PEGUAM PEMBELI</h5>
                        <div>Medan bertanda <img src="/img/espa_icon.png"
                        alt="ESPA Icon"
                        style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
                        ></img> akan dipaparkan dalam cetakan eSPA.
                        </div>
                        <hr>
                        <h6 class="mb-3">Sila Klik <a href="/pemaju/home/profil#maklumatPeguam" target="_blank">Pendaftaran Maklumat Peguam</a>, sekiranya Nama Firma tiada dalam pilihan</h6>
                        <div class="row mb-3">
                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Nama Firma <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <select v-model="form.peguam_id_pembeli" class="form-control"
                                  :class="{'is-invalid': errors.first('peguam_id_pembeli')}"
                                  name="peguam_id_pembeli" data-vv-as="Peguam Pembeli" :disabled="disabled == 1"
                                  @change="peguamPembeliChange">
                                  <option :value="null">SILA PILIH</option>
                                  <option :value="0">TIADA</option>
                                  <option v-for="peguam in peguamPembeliFiltered" :value="peguam.id">{{
                                      peguam.nama_firma_guaman
                                    }}
                                  </option>
                                </select>

                                <input :value="form.nama_firma_guaman_pembeli"
                                       @input="form.nama_firma_guaman_pembeli = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mt-3" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('nama_firma_guaman_pembeli')}"
                                       name="nama_firma_guaman_pembeli" data-vv-as="Nama Firma"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pembeli !== null"
                                       v-if="form.peguam_id_pembeli == 0"
                                      >
                                <span class="invalid-feedback">{{ errors.first("nama_firma_guaman_pembeli") }}</span>
                                <span class="text-grey col-form-label-sm">Nota: Peguam yang sama tidak boleh mewakili dua (2) pihak yang berbeza dalam satu perjanjian.</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Nama Peguam</label>
                              <div class="col-md-8">
                                <input :value="form.nama_peguam_pembeli"
                                       @input="form.nama_peguam_pembeli = $event.target.value.toUpperCase()" type="text"
                                       class="form-control" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('nama_peguam_pembeli')}"
                                       name="nama_peguam_pembeli" data-vv-as="Nama Peguam" :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pembeli !== null">
                                <span class="invalid-feedback">{{ errors.first("nama_peguam_pembeli") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: baseline; display: flex">Alamat <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <input :value="form.alamat1_peguam_pembeli"
                                       @input="form.alamat1_peguam_pembeli = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('alamat1_peguam_pembeli')}"
                                       name="alamat1_peguam_pembeli" data-vv-as="Alamat Peguam Pembeli"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pembeli !== null">
                                <span class="invalid-feedback">{{ errors.first("alamat1_peguam_pembeli") }}</span>

                                <input :value="form.alamat2_peguam_pembeli"
                                       @input="form.alamat2_peguam_pembeli = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pembeli !== null">
                                <input :value="form.alamat3_peguam_pembeli"
                                       @input="form.alamat3_peguam_pembeli = $event.target.value.toUpperCase()"
                                       type="text" class="form-control" maxlength="255"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pembeli !== null">
                              </div>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No Telefon</label>
                              <div class="col-md-8">
                                <input :value="form.no_tel_peguam_pembeli"
                                       @input="form.no_tel_peguam_pembeli = $event.target.value.toUpperCase()"
                                       type="number" class="form-control" maxlength="15" minlength="10"
                                       onKeyPress="if(this.value.length==15) return false;"
                                       :class="{'is-invalid': errors.first('no_tel_peguam_pembeli')}"
                                       name="no_tel_peguam_pembeli" data-vv-as="No Telefon Peguam"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pembeli !== null">
                                <span class="invalid-feedback">{{ errors.first("no_tel_peguam_pembeli") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Poskod <!-- <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            > --></label>
                              <div class="col-md-8">
                                <select v-model="form.poskod_peguam_pembeli" class="form-control"
                                        :class="{'is-invalid': errors.first('poskod_peguam_pembeli')}"
                                        name="poskod_peguam_pembeli" data-vv-as="Poskod"
                                        :disabled="form.peguam_id_pembeli !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="poskod in poskodRs" :value="poskod.poskod">{{
                                      poskod.poskod
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("poskod_peguam_pembeli") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Bandar <!-- <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            > --></label>
                              <div class="col-md-8">
                                <select v-model="form.kod_bandar_id_peguam_pembeli" class="form-control" required=""
                                        :class="{'is-invalid': errors.first('kod_bandar_id_peguam_pembeli')}"
                                        name="kod_bandar_id_peguam_pembeli" data-vv-as="Bandar"
                                        :disabled="form.peguam_id_pembeli !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option
                                    v-for="bandar in filteredBandar(form.kod_negeri_id_peguam_pembeli)"
                                    :value="bandar.id" :key="`bandar-${bandar.id}`">{{ bandar.keterangan }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kod_bandar_id_peguam_pembeli") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Negeri <!-- <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            > --></label>
                              <div class="col-md-8">
                                <select v-model="form.kod_negeri_id_peguam_pembeli" class="form-control"
                                        :class="{'is-invalid': errors.first('kod_negeri_id_peguam_pembeli')}"
                                        name="kod_negeri_id_peguam_pembeli" data-vv-as="Negeri"
                                        :disabled="form.peguam_id_pembeli !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="negeri in kodNegeri" :value="negeri.id">{{
                                      negeri.keterangan
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kod_negeri_id_peguam_pembeli") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="card-title">MAKLUMAT PEGUAM PEMAJU</h5>
                        <div>Medan bertanda <img src="/img/espa_icon.png"
                        alt="ESPA Icon"
                        style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
                        ></img> akan dipaparkan dalam cetakan eSPA.
                        </div>
                        <hr>
                        <h6 class="mb-3">Sila Klik <a href="/pemaju/home/profil#maklumatPeguam" target="_blank">Pendaftaran Maklumat Peguam</a>, sekiranya Nama Firma tiada dalam pilihan</h6>
                        <div class="row mb-3">
                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Nama Firma <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <select v-model="form.peguam_id_pemaju" class="form-control"
                                        :class="{'is-invalid': errors.first('peguam_id_pemaju')}"
                                        name="peguam_id_pemaju" data-vv-as="Peguam Pemaju" :disabled="disabled == 1"
                                        @change="peguamPemajuChange">
                                  <option :value="null">SILA PILIH</option>
                                  <option :value="0">TIADA</option>
                                  <option v-for="peguam in peguamPemajuFiltered" :value="peguam.id">{{
                                      peguam.nama_firma_guaman
                                    }}
                                  </option>
                                </select>

                                <input :value="form.nama_firma_guaman_pemaju"
                                       @input="form.nama_firma_guaman_pemaju = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mt-3" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('nama_firma_guaman_pemaju')}"
                                       name="nama_firma_guaman_pemaju" data-vv-as="Nama Peguam"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pemaju !== null"
                                       v-if="form.peguam_id_pemaju == 0"
                                >
                                <span class="invalid-feedback">{{ errors.first("nama_firma_guaman_pemaju") }}</span>
                                <span class="text-grey col-form-label-sm">Nota: Peguam yang sama tidak boleh mewakili dua (2) pihak yang berbeza dalam satu perjanjian.</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Nama Peguam </label>
                              <div class="col-md-8">
                                <input :value="form.nama_peguam_pemaju"
                                       @input="form.nama_peguam_pemaju = $event.target.value.toUpperCase()" type="text"
                                       class="form-control" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('nama_peguam_pemaju')}"
                                       name="nama_peguam_pemaju" data-vv-as="Nama Peguam"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pemaju !== null">
                                <span class="invalid-feedback">{{ errors.first("nama_peguam_pemaju") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: baseline; display: flex">Alamat <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <input :value="form.alamat1_peguam_pemaju"
                                       @input="form.alamat1_peguam_pemaju = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('alamat1_peguam_pemaju')}"
                                       name="alamat1_peguam_pemaju" data-vv-as="Alamat"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pemaju !== null">
                                <span class="invalid-feedback">{{ errors.first("alamat1_peguam_pemaju") }}</span>
                                <input :value="form.alamat2_peguam_pemaju"
                                       @input="form.alamat2_peguam_pemaju = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pemaju !== null">
                                <input :value="form.alamat3_peguam_pemaju"
                                       @input="form.alamat3_peguam_pemaju = $event.target.value.toUpperCase()"
                                       type="text" class="form-control" maxlength="255"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pemaju !== null">
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No Telefon </label>
                              <div class="col-md-8">
                                <input :value="form.no_tel_peguam_pemaju"
                                       @input="form.no_tel_peguam_pemaju = $event.target.value.toUpperCase()"
                                       type="number" class="form-control" maxlength="15" minlength="10"
                                       onKeyPress="if(this.value.length==15) return false;"
                                       :class="{'is-invalid': errors.first('no_tel_peguam_pemaju')}"
                                       name="no_tel_peguam_pemaju" data-vv-as="No Telefon Peguam"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_pemaju !== null">
                                <span class="invalid-feedback">{{ errors.first("no_tel_peguam_pemaju") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Poskod </label>
                              <div class="col-md-8">
                                <select v-model="form.poskod_peguam_pemaju" class="form-control"
                                        :class="{'is-invalid': errors.first('poskod_peguam_pemaju')}"
                                        name="poskod_peguam_pemaju" data-vv-as="Poskod"
                                        :disabled="form.peguam_id_pemaju !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="poskod in poskodRs" :value="poskod.poskod">{{
                                      poskod.poskod
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("poskod_peguam_pemaju") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Bandar </label>
                              <div class="col-md-8">
                                <select v-model="form.kod_bandar_id_peguam_pemaju" class="form-control" required=""
                                        :class="{'is-invalid': errors.first('kod_bandar_id_peguam_pemaju')}"
                                        name="kod_bandar_id_peguam_pemaju" data-vv-as="Bandar"
                                        :disabled="form.peguam_id_pemaju !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option
                                    v-for="bandar in filteredBandar(form.kod_negeri_id_peguam_pemaju)"
                                    :value="bandar.id" :key="`bandar-${bandar.id}`">{{ bandar.keterangan }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kod_bandar_id_peguam_pemaju") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Negeri </label>
                              <div class="col-md-8">
                                <select v-model="form.kod_negeri_id_peguam_pemaju" class="form-control"
                                        :class="{'is-invalid': errors.first('kod_negeri_id_peguam_pemaju')}"
                                        name="kod_negeri_id_peguam_pemaju" data-vv-as="Negeri"
                                        :disabled="form.peguam_id_pemaju !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="negeri in kodNegeri" :value="negeri.id">{{
                                      negeri.keterangan
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kod_negeri_id_peguam_pemaju") }}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h5 class="card-title">MAKLUMAT PEGUAM <i>STAKEHOLDER</i> PEMAJU</h5>
                        <div>Medan bertanda <img src="/img/espa_icon.png"
                        alt="ESPA Icon"
                        style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
                        ></img> akan dipaparkan dalam cetakan eSPA.
                        </div>
                        <hr>
                        <h6 class="mb-3">Sila Klik <a href="/pemaju/home/profil#maklumatPeguam" target="_blank">Pendaftaran Maklumat Peguam</a>, sekiranya Nama Firma tiada dalam pilihan</h6>
                        <div class="row mb-3">
                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Nama Firma <span
                                class="label-required">*</span><img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <select v-model="form.peguam_id_stakeholder" class="form-control"
                                  :class="{'is-invalid': errors.first('nama_firma_guaman_stakeholder')}"
                                  name="peguam_id_stakeholder" data-vv-as="Peguam Stakeholder"
                                  :disabled="disabled == 1"
                                  @change="peguamStakeholderChange">
                                  <option :value="null">SILA PILIH</option>
                                  <option :value="0">TIADA</option>
                                  <option v-for="peguam in peguamRs" :value="peguam.id">{{
                                      peguam.nama_firma_guaman
                                    }}
                                  </option>
                                </select>

                                <input :value="form.nama_firma_guaman_stakeholder"
                                       @input="form.nama_firma_guaman_stakeholder = $event.target.value.toUpperCase()"
                                       v-validate="'required'" type="text" class="form-control mt-3" maxlength="255"
                                       required=""
                                       :class="{'is-invalid': errors.first('nama_firma_guaman_stakeholder')}"
                                       name="nama_firma_guaman_stakeholder" data-vv-as="Nama Peguam"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_stakeholder !== null"
                                       v-if="form.peguam_id_stakeholder == 0"
                                >
                                <span class="invalid-feedback">{{
                                    errors.first("nama_firma_guaman_stakeholder")
                                  }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Nama Peguam <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.nama_peguam_stakeholder"
                                       @input="form.nama_peguam_stakeholder = $event.target.value.toUpperCase()"
                                       v-validate="{required: stakeholderIsRequired}" type="text" class="form-control" maxlength="255"
                                       :class="{'is-invalid': errors.first('nama_peguam_stakeholder')}"
                                       name="nama_peguam_stakeholder" data-vv-as="Nama Peguam"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_stakeholder !== null">
                                <span class="invalid-feedback">{{ errors.first("nama_peguam_stakeholder") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: baseline; display: flex">Alamat <span
                                class="label-required">*</span><img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <input :value="form.alamat1_peguam_stakeholder"
                                       @input="form.alamat1_peguam_stakeholder = $event.target.value.toUpperCase()"
                                       v-validate="'required'" type="text" class="form-control mb-3" maxlength="255"
                                       required="" :class="{'is-invalid': errors.first('alamat1_peguam_stakeholder')}"
                                       name="alamat1_peguam_stakeholder" data-vv-as="Alamat"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_stakeholder !== null">
                                <span class="invalid-feedback">{{ errors.first("alamat1_peguam_stakeholder") }}</span>
                                <input :value="form.alamat2_peguam_stakeholder"
                                       @input="form.alamat2_peguam_stakeholder = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_stakeholder !== null">
                                <input :value="form.alamat3_peguam_stakeholder"
                                       @input="form.alamat3_peguam_stakeholder = $event.target.value.toUpperCase()"
                                       type="text" class="form-control" maxlength="255"
                                       :disabled="disabled == 1"
                                       :readonly="form.peguam_id_stakeholder !== null">
                              </div>
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No Telefon <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.no_tel_peguam_stakeholder"
                                  @input="form.no_tel_peguam_stakeholder = $event.target.value.toUpperCase()"
                                  v-validate="{required: stakeholderIsRequired}" type="number" class="form-control" maxlength="15"
                                  onKeyPress="if(this.value.length==15) return false;"
                                  :class="{'is-invalid': errors.first('no_tel_peguam_stakeholder')}"
                                  name="no_tel_peguam_stakeholder" data-vv-as="No Telefon Peguam"
                                  :disabled="disabled == 1"
                                  :readonly="form.peguam_id_stakeholder !== null">
                                <span class="invalid-feedback">{{ errors.first("no_tel_peguam_stakeholder") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Poskod <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <select v-model="form.poskod_peguam_stakeholder" class="form-control"
                                        v-validate="{required: stakeholderIsRequired}"
                                        :class="{'is-invalid': errors.first('poskod_peguam_stakeholder')}"
                                        name="poskod_peguam_stakeholder" data-vv-as="Poskod"
                                        :disabled="form.peguam_id_stakeholder !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="poskod in poskodRs" :value="poskod.poskod">{{
                                      poskod.poskod
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("poskod_peguam_stakeholder") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Bandar <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <select v-model="form.kod_bandar_id_peguam_stakeholder" class="form-control"
                                  v-validate="{required: stakeholderIsRequired}"
                                  :class="{'is-invalid': errors.first('kod_bandar_id_peguam_stakeholder')}"
                                  name="kod_bandar_id_peguam_stakeholder" data-vv-as="Bandar"
                                  :disabled="form.peguam_id_stakeholder !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option
                                    v-for="bandar in filteredBandar(form.kod_negeri_id_peguam_stakeholder)"
                                    :value="bandar.id" :key="`bandar-${bandar.id}`">{{ bandar.keterangan }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{
                                    errors.first("kod_bandar_id_peguam_stakeholder")
                                  }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Negeri <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <select v-model="form.kod_negeri_id_peguam_stakeholder" class="form-control"
                                        v-validate="{required: stakeholderIsRequired}"
                                        :class="{'is-invalid': errors.first('kod_negeri_id_peguam_stakeholder')}"
                                        name="kod_negeri_id_peguam_stakeholder" data-vv-as="Negeri"
                                        :disabled="form.peguam_id_stakeholder !== null">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="negeri in kodNegeri" :value="negeri.id">{{
                                      negeri.keterangan
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{
                                    errors.first("kod_negeri_id_peguam_stakeholder")
                                  }}</span>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="col-6">
                            <div class="form-group row">
                              <div class="col-md-12">
                                <div class="alert alert-primary" role="alert">
                                  * Tambah maklumat peguam di <a href="/pemaju/home/profil#maklumatPeguam"
                                                                 class="alert-link">Profil > Peguam</a>.
                                </div>
                              </div>
                            </div>
                          </div> -->
                        </div>

                      </div>
                      <div class="card-footer text-right">
                        <button class="btn btn-secondary" type="button" @click.prevent="tutupBorang">Tutup</button>
                        <button class="btn btn-success" type="button" @click.prevent="daftar" :disabled="saving"
                                v-if="action == 'Daftar'">Daftar
                        </button>
                        <button class="btn btn-success" type="button" @click.prevent="updatePeguam(pembeli_id)"
                                v-else-if="action == 'Kemaskini'">Kemaskini
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: [ "$validator" ],
  name: "PeguamModal",

  mounted() {
    vmodal.register(this.modalId, { open: this.onOpen, close: this.onClose });
  },

  props: {
    pemajuan: Object,
    kewangan: Object,
    kodNegeri: Array,
    kodDaerah: Array,
    kodBandar: Array,
    kodKaumRs: Array,
    kodNegaraRs: Array,
    kodBiayaPembeliRs: Array,
    peguamRs: Array,
    jenisPerjanjian: Array,
    kodJenisPemilikanRs: {
      type: Array,
      default: () => ([
        { id: "I", keterangan: "INDIVIDU" },
        { id: "ST", keterangan: "SYARIKAT TEMPATAN" },
        { id: "SA", keterangan: "SYARIKAT ASING" },
      ]),
    },
    firstBuyerProp: Object,
    poskodRs: Array,
  },

  data() {
    return {
      showInisiatifKerajaanError: false,
      modalId: "peguam",
      loading: false,
      saving: false,
      unit: {},
      pembeli_id: null,
      pembeli_rs: [],
      pembeli_rs_batal: [],
      form: {},
      unit_pertama: null,
      errorTimeout: null,
      response: () => {
      },
      openBorang: true,
      openJualan: null,
      openSPJB: false,
      action: "Daftar",
      disabled: 0,
      pembeliNoPeguam: [],
      firstBuyer: JSON.parse(JSON.stringify(this.firstBuyerProp)),
    };
  },

  methods: {
    onOpen(data, callback) {
      this.firstBuyer = Object.keys(this.firstBuyer).length != 0 ? this.firstBuyer : null;
      this.unit = JSON.parse(JSON.stringify(data.unit));
      this.unit_pertama = data.unit_pertama;
      this.form = {
        nama_calon_pembeli: null,
        calon_pembeli_id: null,
        peguam_id_pembeli: null,
        nama_peguam_pembeli: null,
        no_tel_peguam_pembeli: null,
        alamat1_peguam_pembeli: null,
        alamat2_peguam_pembeli: null,
        alamat3_peguam_pembeli: null,
        poskod: null,
        kod_negeri_id_peguam_pembeli: null,
        kod_daerah_id_peguam_pembeli: null,
        kod_bandar_id_peguam_pembeli: null,
        poskod_peguam_pembeli: null,
        peguam_id_pemaju: null,
        nama_peguam_pemaju: null,
        no_tel_peguam_pemaju: null,
        alamat1_peguam_pemaju: null,
        alamat2_peguam_pemaju: null,
        alamat3_peguam_pemaju: null,
        kod_negeri_id_peguam_pemaju: null,
        kod_daerah_id_peguam_pemaju: null,
        kod_bandar_id_peguam_pemaju: null,
        poskod_peguam_pemaju: null,
        peguam_id_stakeholder: null,
      };

      this.loading = true;
      this.response = callback;
      this.openBorang = false;

      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}`)
        .then((response) => {
          this.pembeli_rs = response.data.calon;
          this.pembeli_rs_batal = response.data.calon_batal;
          this.pembeliNoPeguam = response.data.pembeli_no_peguam;
          if (this.pembeli_rs.length > 0) {
            this.openJualan = true;
          } else {
            this.openJualan = false;
          }
          if (this.pembeli_rs.length > 0) {
            if (this.pembeli_rs[ 0 ][ "status_pembeli" ] == "PEMBELI") {
              this.openSPJB = true;
              this.action = null;
            } else {
              this.openSPJB = false;
              this.action = "Daftar";
            }
          } else {
            this.openSPJB = false;
            this.action = "Daftar";
          }

        })
        .catch((e) => {
          reject(e);
        })
        .then(() => {
          this.loading = false;
        });


    },

    onClose() {
    },

    peguamPembeliChange() {
      if(this.form.peguam_id_pembeli == null) {
        this.resetFormPembeli();
        return;
      }

      if(this.form.peguam_id_pembeli == 0) { // tiada
        this.resetFormPembeli();
        this.form.nama_firma_guaman_pembeli = 'NONE'
        this.form.alamat1_peguam_pembeli = 'NONE'
        return;
      }

      const peguam = this.peguamRs.filter(item => (item.id == this.form[ "peguam_id_pembeli" ]));

      this.form.nama_firma_guaman_pembeli = peguam[0].nama_firma_guaman;
      this.form.nama_peguam_pembeli = peguam[0].nama_peguam;
      this.form.no_tel_peguam_pembeli = peguam[0].no_telefon;
      this.form.alamat1_peguam_pembeli = peguam[0].alamat1;
      this.form.alamat2_peguam_pembeli = peguam[0].alamat2;
      this.form.alamat3_peguam_pembeli = peguam[0].alamat3;
      this.form.poskod_peguam_pembeli = peguam[0].poskod;
      this.form.kod_negeri_id_peguam_pembeli = peguam[0].kod_negeri_id;
      this.form.kod_daerah_id_peguam_pembeli = peguam[0].kod_daerah_id;
      this.form.kod_bandar_id_peguam_pembeli = peguam[0].kod_bandar_id;
    },

    peguamPemajuChange() {
      if(this.form.peguam_id_pemaju == null) {
        this.resetFormPemaju();
        return;
      }

      if(this.form.peguam_id_pemaju == 0) { // tiada
        this.resetFormPemaju();
        this.form.nama_firma_guaman_pemaju = 'NONE'
        this.form.alamat1_peguam_pemaju = 'NONE'
        return;
      }

      const peguam = this.peguamRs.filter(item => (item.id == this.form[ "peguam_id_pemaju" ]));

      this.form.nama_firma_guaman_pemaju = peguam[0].nama_firma_guaman;
      this.form.nama_peguam_pemaju = peguam[0].nama_peguam;
      this.form.no_tel_peguam_pemaju = peguam[0].no_telefon;
      this.form.alamat1_peguam_pemaju = peguam[0].alamat1;
      this.form.alamat2_peguam_pemaju = peguam[0].alamat2;
      this.form.alamat3_peguam_pemaju = peguam[0].alamat3;
      this.form.poskod_peguam_pemaju = peguam[0].poskod;
      this.form.kod_negeri_id_peguam_pemaju = peguam[0].kod_negeri_id;
      this.form.kod_daerah_id_peguam_pemaju = peguam[0].kod_daerah_id;
      this.form.kod_bandar_id_peguam_pemaju = peguam[0].kod_bandar_id;
    },

    peguamStakeholderChange() {
      if(this.form.peguam_id_stakeholder == null) {
        this.resetFormStakeholder();
        return;
      }

      if(this.form.peguam_id_stakeholder == 0) { // tiada
        this.resetFormStakeholder();
        this.form.nama_firma_guaman_stakeholder = 'NONE'
        this.form.alamat1_peguam_stakeholder = 'NONE'
        return;
      }

      const peguam = this.peguamRs.filter(item => (item.id == this.form[ "peguam_id_stakeholder" ]));

      this.form.nama_firma_guaman_stakeholder = peguam[0].nama_firma_guaman;
      this.form.nama_peguam_stakeholder = peguam[0].nama_peguam;
      this.form.no_tel_peguam_stakeholder = peguam[0].no_telefon;
      this.form.alamat1_peguam_stakeholder = peguam[0].alamat1;
      this.form.alamat2_peguam_stakeholder = peguam[0].alamat2;
      this.form.alamat3_peguam_stakeholder = peguam[0].alamat3;
      this.form.poskod_peguam_stakeholder = peguam[0].poskod;
      this.form.kod_negeri_id_peguam_stakeholder = peguam[0].kod_negeri_id;
      this.form.kod_daerah_id_peguam_stakeholder = peguam[0].kod_daerah_id;
      this.form.kod_bandar_id_peguam_stakeholder = peguam[0].kod_bandar_id;
    },

    resetFormPembeli() {
      this.form.nama_firma_guaman_pembeli = null;
      this.form.nama_peguam_pembeli = null;
      this.form.no_tel_peguam_pembeli = null;
      this.form.alamat1_peguam_pembeli = null;
      this.form.alamat2_peguam_pembeli = null;
      this.form.alamat3_peguam_pembeli = null;
      this.form.poskod_peguam_pembeli = null;
      this.form.kod_negeri_id_peguam_pembeli = null;
      this.form.kod_daerah_id_peguam_pembeli = null;
      this.form.kod_bandar_id_peguam_pembeli = null;
    },

    resetFormPemaju() {
      this.form.nama_firma_guaman_pemaju = null;
      this.form.nama_peguam_pemaju = null;
      this.form.no_tel_peguam_pemaju = null;
      this.form.alamat1_peguam_pemaju = null;
      this.form.alamat2_peguam_pemaju = null;
      this.form.alamat3_peguam_pemaju = null;
      this.form.poskod_peguam_pemaju = null;
      this.form.kod_negeri_id_peguam_pemaju = null;
      this.form.kod_daerah_id_peguam_pemaju = null;
      this.form.kod_bandar_id_peguam_pemaju = null;
    },

    resetFormStakeholder() {
      this.form.nama_firma_guaman_stakeholder = null;
      this.form.nama_peguam_stakeholder = null;
      this.form.no_tel_peguam_stakeholder = null;
      this.form.alamat1_peguam_stakeholder = null;
      this.form.alamat2_peguam_stakeholder = null;
      this.form.alamat3_peguam_stakeholder = null;
      this.form.poskod_peguam_stakeholder = null;
      this.form.kod_negeri_id_peguam_stakeholder = null;
      this.form.kod_daerah_id_peguam_stakeholder = null;
      this.form.kod_bandar_id_peguam_stakeholder = null;
    },

    bukaBorang() {
      this.action = "Daftar";
      // here
      if (this.firstBuyer != null) {
        // const penamaPertama = this.pembeli_rs.find(item => (item.no_kp == this.unit.no_kp));
        // if(!penamaPertama) {
        //   this.resetFormPeguam();
        // }

        this.setFormPeguamStakeholder(this.firstBuyer);
      } else {
        this.resetFormPeguam();
      }
      return this.openBorang = true;
    },

    tutupBorang() {
      if (this.openSPJB) {
        this.action = null;
      } else {
        this.action = "Daftar";
      }

      return this.openBorang = false;
    },

    filteredDaerah(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerah.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandar(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodBandar.filter(item => (item.kod_negeri_id == kod_negeri_id));
    },
    daftar() {
      this.$validator.validateAll()
        .then((passes) => {
          if(!passes) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
              "warning",
            );
            return;
          }
          this.saving = true;
          axios.put(`/pemaju/kuota-bumi/pembeli/${this.form.calon_pembeli_id}/updatePeguam`, this.form)
            .then(({ data }) => {
              this.pembeli_rs = data.pembeli_rs;
              this.pembeli_rs_batal = data.pembeli_rs_batal;
              this.pembeliNoPeguam = data.pembeli_no_peguam;
              this.response(data.unit);
              this.openBorang = false;
              if (this.pembeli_rs.length > 0) {
                this.openJualan = true;
              } else {
                this.openJualan = false;
              }
              swal.fire(
                "Berjaya!",
                "Maklumat calon pembeli berjaya didaftar.",
                "success",
              );
            })
            .catch(function (error) {
              console.log(error.response.data.errors);
              swal.fire(
                "Tidak Berjaya!",
                "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        });
    },

    editPeguam(pembeli_id) {
      this.openBorang = true;
      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/${pembeli_id}/show`)
        .then(({ data }) => {
          this.setFormPembeli(data)
          this.form.nama_calon_pembeli = data.pembeli.nama_pembeli
          this.form.calon_pembeli_id = data.pembeli.id

          this.disabled = 0;
          this.action = "Kemaskini";
        })
        .catch((e) => {
          reject(e);
        })
        .then(() => {
          this.loading = false;
        });
      this.openBorang = true;
    },

    updatePeguam(pembeli_id) {
      this.$validator.validateAll()
        .then(passes => {
          if(!passes) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
              "warning",
            );
            return;
          }
          this.saving = true;
          axios.put(`/pemaju/kuota-bumi/pembeli/${pembeli_id}/updatePeguam`, this.form)
            .then(({ data }) => {
              this.pembeli_rs = data.pembeli_rs;
              this.pembeli_rs_batal = data.pembeli_rs_batal;
              this.pembeliNoPeguam = data.pembeli_no_peguam;
              this.response(data.unit);
              this.openBorang = false;
              if (this.pembeli_rs.length > 0) {
                this.openJualan = true;
              } else {
                this.openJualan = false;
              }

              swal.fire(
                "Berjaya!",
                "Maklumat calon pembeli berjaya dikemaskini.",
                "success",
              );
            })
            .catch(function (error) {
              swal.fire(
                "Tidak Berjaya!",
                "Sila lengkapkan borang pendaftaran calon pembeli",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        });
    },

    close() {
      vmodal.close(this.modalId);
    },

    setFormPembeli(data) {
      this.pembeli_id = data.pembeli.id;
      if(data.pembeli.nama_firma_guaman_stakeholder == null) { // new
        if (this.firstBuyer != null) {
        // const penamaPertama = this.pembeli_rs.find(item => (item.no_kp == this.unit.no_kp));
        // if(!penamaPertama) {
        //   this.resetFormPeguam();
        // }

        this.setFormPeguamStakeholder(this.firstBuyer);
        } else {
          this.resetFormPeguam();
        }
        return;
      }

      // edit
      this.form = {
        nama_calon_pembeli: data.pembeli.nama_pembeli,
        calon_pembeli_id: data.pembeli.id,
        peguam_id_pembeli: data.pembeli.peguam_id_pembeli ?? 0,
        nama_firma_guaman_pembeli: data.pembeli.nama_firma_guaman_pembeli,
        nama_peguam_pembeli: data.pembeli.nama_peguam_pembeli,
        no_tel_peguam_pembeli: data.pembeli.no_tel_peguam_pembeli,
        alamat1_peguam_pembeli: data.pembeli.alamat1_peguam_pembeli,
        alamat2_peguam_pembeli: data.pembeli.alamat2_peguam_pembeli,
        alamat3_peguam_pembeli: data.pembeli.alamat3_peguam_pembeli,
        kod_negeri_id_peguam_pembeli: data.pembeli.kod_negeri_id_peguam_pembeli,
        kod_daerah_id_peguam_pembeli: data.pembeli.kod_daerah_id_peguam_pembeli,
        kod_bandar_id_peguam_pembeli: data.pembeli.kod_bandar_id_peguam_pembeli,
        poskod_peguam_pembeli: data.pembeli.poskod_peguam_pembeli,
        peguam_id_pemaju: data.pembeli.peguam_id_pemaju ?? 0,
        nama_firma_guaman_pemaju: data.pembeli.nama_firma_guaman_pemaju,
        nama_peguam_pemaju: data.pembeli.nama_peguam_pemaju,
        no_tel_peguam_pemaju: data.pembeli.no_tel_peguam_pemaju,
        alamat1_peguam_pemaju: data.pembeli.alamat1_peguam_pemaju,
        alamat2_peguam_pemaju: data.pembeli.alamat2_peguam_pemaju,
        alamat3_peguam_pemaju: data.pembeli.alamat3_peguam_pemaju,
        kod_negeri_id_peguam_pemaju: data.pembeli.kod_negeri_id_peguam_pemaju,
        kod_daerah_id_peguam_pemaju: data.pembeli.kod_daerah_id_peguam_pemaju,
        kod_bandar_id_peguam_pemaju: data.pembeli.kod_bandar_id_peguam_pemaju,
        poskod_peguam_pemaju: data.pembeli.poskod_peguam_pemaju,
        peguam_id_stakeholder: data.pembeli.peguam_id_stakeholder ?? 0,
        nama_firma_guaman_stakeholder: data.pembeli.nama_firma_guaman_stakeholder,
        nama_peguam_stakeholder: data.pembeli.nama_peguam_stakeholder,
        no_tel_peguam_stakeholder: data.pembeli.no_tel_peguam_stakeholder,
        alamat1_peguam_stakeholder: data.pembeli.alamat1_peguam_stakeholder,
        alamat2_peguam_stakeholder: data.pembeli.alamat2_peguam_stakeholder,
        alamat3_peguam_stakeholder: data.pembeli.alamat3_peguam_stakeholder,
        kod_negeri_id_peguam_stakeholder: data.pembeli.kod_negeri_id_peguam_stakeholder,
        kod_daerah_id_peguam_stakeholder: data.pembeli.kod_daerah_id_peguam_stakeholder,
        kod_bandar_id_peguam_stakeholder: data.pembeli.kod_bandar_id_peguam_stakeholder,
        poskod_peguam_stakeholder: data.pembeli.poskod_peguam_stakeholder,
      };

    },

    resetFormPeguam() {
      this.form = {
        nama_calon_pembeli: null,
        calon_pembeli_id: null,
        peguam_id_pembeli: null,
        nama_firma_guaman_pembeli: null,
        nama_peguam_pembeli: null,
        no_tel_peguam_pembeli: null,
        alamat1_peguam_pembeli: null,
        alamat2_peguam_pembeli: null,
        alamat3_peguam_pembeli: null,
        kod_negeri_id_peguam_pembeli: null,
        kod_daerah_id_peguam_pembeli: null,
        kod_bandar_id_peguam_pembeli: null,
        poskod_peguam_pembeli: null,
        nama_firma_guaman_pemaju: null,
        peguam_id_pemaju: null,
        nama_peguam_pemaju: null,
        no_tel_peguam_pemaju: null,
        alamat1_peguam_pemaju: null,
        alamat2_peguam_pemaju: null,
        alamat3_peguam_pemaju: null,
        kod_negeri_id_peguam_pemaju: null,
        kod_daerah_id_peguam_pemaju: null,
        kod_bandar_id_peguam_pemaju: null,
        poskod_peguam_pemaju: null,
        peguam_id_stakeholder: null,
        nama_firma_guaman_stakeholder: null,
        nama_peguam_stakeholder: null,
        no_tel_peguam_stakeholder: null,
        alamat1_peguam_stakeholder: null,
        alamat2_peguam_stakeholder: null,
        alamat3_peguam_stakeholder: null,
        kod_negeri_id_peguam_stakeholder: null,
        kod_daerah_id_peguam_stakeholder: null,
        kod_bandar_id_peguam_stakeholder: null,
        poskod_peguam_stakeholder: null,
      };
    },

    setFormPeguamStakeholder(firstBuyer) {
      this.form = {
        nama_calon_pembeli: null,
        calon_pembeli_id: null,
        peguam_id_pembeli: null,
        nama_firma_guaman_pembeli: null,
        nama_peguam_pembeli: null,
        no_tel_peguam_pembeli: null,
        alamat1_peguam_pembeli: null,
        alamat2_peguam_pembeli: null,
        alamat3_peguam_pembeli: null,
        kod_negeri_id_peguam_pembeli: null,
        kod_daerah_id_peguam_pembeli: null,
        kod_bandar_id_peguam_pembeli: null,
        poskod_peguam_pembeli: null,
        nama_firma_guaman_pemaju: null,
        peguam_id_pemaju: null,
        nama_peguam_pemaju: null,
        no_tel_peguam_pemaju: null,
        alamat1_peguam_pemaju: null,
        alamat2_peguam_pemaju: null,
        alamat3_peguam_pemaju: null,
        kod_negeri_id_peguam_pemaju: null,
        kod_daerah_id_peguam_pemaju: null,
        kod_bandar_id_peguam_pemaju: null,
        poskod_peguam_pemaju: null,
        peguam_id_stakeholder: firstBuyer.peguam_id_stakeholder,
        nama_firma_guaman_stakeholder: firstBuyer.nama_firma_guaman_stakeholder,
        nama_peguam_stakeholder: firstBuyer.nama_peguam_stakeholder,
        no_tel_peguam_stakeholder: firstBuyer.no_tel_peguam_stakeholder,
        alamat1_peguam_stakeholder: firstBuyer.alamat1_peguam_stakeholder,
        alamat2_peguam_stakeholder: firstBuyer.alamat2_peguam_stakeholder,
        alamat3_peguam_stakeholder: firstBuyer.alamat3_peguam_stakeholder,
        kod_negeri_id_peguam_stakeholder: firstBuyer.kod_negeri_id_peguam_stakeholder,
        kod_daerah_id_peguam_stakeholder: firstBuyer.kod_daerah_id_peguam_stakeholder,
        kod_bandar_id_peguam_stakeholder: firstBuyer.kod_bandar_id_peguam_stakeholder,
        poskod_peguam_stakeholder: firstBuyer.poskod_peguam_stakeholder,
      };
    }

  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1;
    },
    isFirstBuyer() {
      return this.unit_pertama && this.unit.id == this.unit_pertama.id;
    },
    peguamPembeliFiltered() {
      return this.peguamRs.filter((item) => {
        return item.id != this.form.peguam_id_pemaju;
      });
    },
    peguamPemajuFiltered() {
      return this.peguamRs.filter((item) => {
        return item.id != this.form.peguam_id_pembeli;
      });
    },
    stakeholderIsRequired() {
      return this.form.peguam_id_stakeholder === null
    },
  },
};
</script>

<style scoped>

</style>
