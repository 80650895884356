var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.kuiri)?_c('div',{staticClass:"alert alert-warning"},[_c('strong',[_vm._v("Catatan Kuiri:")]),_vm._v(" "),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.kuiri.catatan))])]):_vm._e(),_vm._v(" "),_c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("Tarikh Kewangan Berakhir")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-8 col-md-8"},[_c('datepicker',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:{'is-invalid': _vm.errors.first('tarikh_kewangan_berakhir')},attrs:{"name":"tarikh_kewangan_berakhir","disabled":_vm.readonly},on:{"changed":_vm.save},model:{value:(_vm.form.tarikh_kewangan_berakhir),callback:function ($$v) {_vm.$set(_vm.form, "tarikh_kewangan_berakhir", $$v)},expression:"form.tarikh_kewangan_berakhir"}})],1)])]),_vm._v(" "),_c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("Laporan Kewangan Tahunan")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("laporan_kewangan_tahunan")},attrs:{"type":"file","accept":"application/pdf","name":"laporan_kewangan_tahunan","doc":_vm.maklumat.laporan_kewangan_tahunan,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('laporan_kewangan_tahunan', file, callback);
},"remove":function($event){return _vm.removeDoc('laporan_kewangan_tahunan')}}}),_vm._v(" "),_c('div',{staticClass:"text-danger col-form-label-sm"},[_vm._v("Hanya dokumen berformat PDF sahaja dibenarkan")])],1)])]),_vm._v(" "),(_vm.pemaju.kod_kategori_pemaju_id === 1)?_c('div',[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("Laporan Kredit Syarikat")]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("laporan_ctos")},attrs:{"type":"file","accept":"application/pdf","name":"laporan_ctos","doc":_vm.maklumat.laporan_ctos,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('laporan_ctos', file, callback);
},"remove":function($event){return _vm.removeDoc('laporan_ctos')}}}),_vm._v(" "),_c('div',{staticClass:"text-danger col-form-label-sm"},[_vm._v("Hanya dokumen berformat PDF sahaja dibenarkan")])],1)])]):_vm._e()]),_vm._v(" "),(!_vm.readonly)?_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.hErrorMessage)?_c('span',{staticClass:"text-danger mr-2"},[_vm._v(_vm._s(_vm.hErrorMessage))]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.succesfulMessage)?_c('span',{staticClass:"text-success mr-2"},[_vm._v(_vm._s(_vm.succesfulMessage))]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v("Simpan")])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }