<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="col-lg-12">

          <div class="form-group row">
            <label class="col-form-label col-md-3">Nama Pemaju</label>
            <div class="col-md-9">
              <input type="text" class="form-control" disabled :value="pemaju.nama">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3">Nama Pemajuan</label>
            <div class="col-md-9">
              <input type="text" class="form-control" disabled :value="pemajuan.nama">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3">Kod Pemajuan</label>
            <div class="col-md-2">
              <input type="text" class="form-control" disabled :value="pemajuan.id">
            </div>
            <label class="col-form-label col-md-3">No. SSM</label>
            <div class="col-md-2">
              <input type="text" class="form-control" disabled :value="pemaju.no_ssm">
            </div>
            <label class="col-form-label col-md-3">No. SSM Baru</label>
            <div class="col-md-2">
              <input type="text" class="form-control" disabled :value="pemaju.no_ssm_baru">
            </div>
          </div>
          <pemaju-pegawai-untuk-dihubungi
            :pemaju_nama_pegawai.sync="form.pemaju_nama_pegawai"
            :pemaju_no_bimbit.sync="form.pemaju_no_bimbit"
            :pemaju_no_pejabat.sync="form.pemaju_no_pejabat"
            :pemaju_email.sync="form.pemaju_email"
          ></pemaju-pegawai-untuk-dihubungi>
          <br>
          <div class="form-group row">
            <label class="col-form-label col-md-3">Nama Pengarah</label>
            <div class="col-md-9">
              <input type="text" class="form-control" disabled :value="pengarah.nama">
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-3">No Kad Pengenalan / Passport</label>
            <div class="col-md-9">
              <input type="text" class="form-control" disabled :value="pengarah.no_kp">
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="card-footer">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition><button type="button" class="btn btn-sm btn-primary" :disabled="processing" @click.prevent="save">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import PemajuPegawaiUntukDihubungi from "../../common/PemajuPegawaiUntukDihubungi";

export default {
  inject: ['$validator'],
  name: "BahagianA",
  components: {PemajuPegawaiUntukDihubungi},
  props: {
    pemaju: {
      type: Object,
    },

    pemajuan: {
      type: Object,
    },

    pengarah: {
      type: Object,
    },

    maklumat: Object,
  },

  data() {
    return {
      processing: false,
      form: JSON.parse(JSON.stringify(this.maklumat)),
    };
  },

  methods: {
    save() {
      if (this.processing) return;

      Promise.all([
        this.$validator.validate('pemaju_nama_pegawai'),
        this.$validator.validate('pemaju_no_bimbit'),
        this.$validator.validate('pemaju_no_pejabat'),
        this.$validator.validate('pemaju_email'),
      ]).then((results) => {
        return results.every(t => t);
      })
      .then(passes => {
        if (!passes) {
          return;
        }

        this.processing = true;
        axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/pegawai-hubungi`, this.form)
          .then((response) => {
            const form = JSON.parse(JSON.stringify(this.form));

            this.$emit('update:maklumat', form);
            this.processing = false;
            this.$success();
          })
          .catch(() => {
            this.processing = false;
          })
      })
    }
  }
}
</script>
