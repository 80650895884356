<template>
  <div class="card">
    <div class="card-body pb-0">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <h5 class="card-title">Sila kemaskini maklumat dan tekan butang "Simpan"</h5>
    </div>
    <div class="table-responsive">
      <table class="table table-card">
        <thead>
        <tr>
          <th>Bil.</th>
          <th>Nama Syarikat</th>
          <th>No. Daftar SSM</th>
          <th>Kod Pemaju</th>
          <th width="1%" v-if="!readonly">
            <button class="btn btn-success" style="width: 1.5rem; height: 1.5rem; padding: 0;" @click.prevent="add"><i class="fas fa-plus"></i></button>
          </th>
        </tr>
        </thead>
        <tbody>
        <template v-if="syarikat_rs.length">
          <tr v-for="(syarikat, index) in syarikat_rs">
            <td>{{ index + 1}}</td>
            <td>{{ syarikat.nama }}</td>
            <td>{{ syarikat.no_ssm }}</td>
            <td>{{ syarikat.kod_pemaju }}</td>

            <td nowrap v-if="!readonly">
              <button class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroy(index)" title="Hapus"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
        </template>
        <tr v-else>
          <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition><button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
    <tambah-maklumat-syarikat-modal
      :kod-jenis-pemaju-rs="kodJenisPemajuRs"
    ></tambah-maklumat-syarikat-modal>
  </div>
</template>

<script>
import TambahMaklumatSyarikatModal from "../../common/TambahMaklumatSyarikatModal";
export default {
  name: "BahagianF",
  components: {TambahMaklumatSyarikatModal},
  props: {
    maklumat: Object,
    default_syarikat_rs: Array,

    kodJenisPemajuRs: Array,

    readonly: {
      type: Boolean,
      default: false,
    },

    kuiri: null,
  },
  data() {
    return {
      loading: false,
      syarikat_rs: JSON.parse(JSON.stringify(this.default_syarikat_rs)),
    }
  },
  methods: {
    add() {
      vmodal.open('tambahmaklumatsyarikat', {}, (syarikat) => {
        if (syarikat.kod_pemaju) {
          this.syarikat_rs.push(syarikat)
        }
      })
    },
    destroy(index) {
      this.syarikat_rs.splice(index, 1)
    },
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/bahagian-f`, {
        syarikat_rs: this.syarikat_rs,
      })
        .then((response) => {
          this.syarikat_rs = response.data.subsyarikat_rs;
          this.$emit('update', JSON.parse(JSON.stringify(response.data)));
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
  }
}
</script>

<style scoped>

</style>
