<template>
  <ul class="list-group" style="max-height: 600px; overflow-y: auto;">
    <li class="list-group-item d-flex" v-for="(kuiri, index) in kuiriRs" @click="kuiriChange(!kuiri.flag_maklumbalas_kuiri, index)">
      <div style="min-width: 50px">
        <input v-if="!kuiri.tarikh_maklumbalas_kuiri" class="mt-2 mr-4" :name="`kuiri_permohonan_rs[${kuiri.id}][flag_maklumbalas_kuiri]`" type="checkbox" @input.stop.prevent="ev => kuiriChange(ev.target.checked, index)" :checked="kuiri.flag_maklumbalas_kuiri">
      </div>
      <div class="flex-grow-1">
        <blockquote v-if="kuiri.catatan_kuiri" style="color: #5a5a5a">&#8220; {{ kuiri.catatan_kuiri }} &#8221;</blockquote>
        <br>
        <strong>Catatan Maklumbalas</strong>
        <textarea @click.stop class="form-control" :name="`kuiri_permohonan_rs[${kuiri.id}][catatan_maklumbalas_kuiri]`" v-model="kuiri.catatan_maklumbalas_kuiri" :disabled="kuiri.tarikh_maklumbalas_kuiri"></textarea>
      </div>
      <div class="text-right pr-2" style="min-width: 250px">
        <strong>Tarikh Maklumbalas</strong><br />
        <span>{{ kuiri.tarikh_maklumbalas_kuiri ? kuiri.tarikh_maklumbalas_kuiri : 'Baru' }}</span>
      </div>

    </li>
  </ul>
</template>

<script>
export default {
  name: "Kuiri",

  props: {
    kuiriRs: {
      type: Array,
    }
  },

  methods: {
    kuiriChange(value, index) {
      if (index !== -1) {
        let kuiriRs = JSON.parse(JSON.stringify(this.kuiriRs));

        const kuiri = kuiriRs[index];
        if (kuiri.tarikh_maklumbalas_kuiri) return;

        kuiri.flag_maklumbalas_kuiri = value;
        kuiriRs.splice(index, 1, kuiri)

        this.$emit('update:kuiri-rs', kuiriRs);
      }
    }
  }

}
</script>

<style scoped>

</style>
