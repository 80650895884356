<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="col-lg-12">
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Pengesahan Pembatalan Unit</label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="memiliki_jualan ? 'TELAH DIJUAL' : 'BELUM DIJUAL'" disabled>
            </div>
          </div>
          <div class="form-group">
            <label for="justifikasi_pembatalan" style="white-space: pre-wrap;">2.&#9;Nyatakan Justifikasi anda untuk pembatalan lesen</label>
            <textarea
              name="justifikasi_pembatalan"
              id="justifikasi_pembatalan" cols="30" rows="10"
              class="form-control" :class="{'is-invalid': errors.first('justifikasi_pembatalan')}"
              :value="justifikasi_pembatalan" @input="$emit('update:justifikasi_pembatalan', $event.target.value.toUpperCase())"
              v-validate="'required'"
            ></textarea>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianC",
  inject: ['$validator'],
  props: {
    memiliki_jualan: Boolean,

    justifikasi_pembatalan: String,
  }
}
</script>

<style scoped>

</style>
