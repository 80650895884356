<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="modal-jadual-1"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modal-jadual-1">No Unit / Lot {{ unit.no_unit }} - (Jadual {{ type }})</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <section>
              <h4 class="card-title">MAKLUMAT UNIT</h4>
              <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Lot PT :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_lot_pt }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Unit :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_unit }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Harga Jualan Yang Diluluskan :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">
                            {{ unit.harga_jualan | currency("RM", ",", 2, ".", "front", false) }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Pembeli Pertama :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ isFirstBuyer ? "Ya" : "Tidak" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="d-flex align-items-center justify-content-center" v-if="loading" style="min-height: 100px;">
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block; shape-rendering: auto;"
                     width="50px" height="50px" viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" r="6.57689" fill="none" stroke="#3887b4" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                  </circle>
                  <circle cx="50" cy="50" r="16.4808" fill="none" stroke="#5699d2" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline"></animate>
                  </circle>
                </svg>
              </div>
              <div v-else>
                <section>
                  <div>
                    <div class="card mb-3">
                      <div class="card-header d-flex justify-content-between">
                        <h5 class="card-title">BORANG KEMASKINI JADUAL {{ type }}</h5>
                      </div>
                      <div class="card-body">
                        <div class="row mb-3">
                          <div class="col-12">
                            <div class="form-group row" v-for="documents in listDoc" :key="documents.id">
                              <label class="col-md-4 col-form-label">{{ documents.keterangan }} <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <div v-for="(doc, index) in documents.nolot_unit_jadual" :key="index" class="row my-2">
                                  <div class="col-8">
                                    <file-upload
                                      class="w-100"
                                      v-if="doc.document && doc.document.url"
                                      :doc="doc.document"
                                      @remove="doc.document = null"
                                      :readonly="disabled == 1"
                                    ></file-upload>
                                    <input-file-new
                                      v-else
                                      type="file" accept="application/pdf"
                                      :name="`document_${documents.id}_${index}`"
                                      class="form-control"
                                      :class="{'is-invalid': errors.first(`document_${documents.id}_${index}`)}"
                                      :doc="doc.document"
                                      v-model="doc.document"
                                      v-validate="'required'"
                                      :data-vv-as="`Dokumen ${documents.keterangan}`"
                                      :readonly="disabled == 1"
                                    />
                                    <span class="invalid-feedback">{{ errors.first(`document_${documents.id}_${index}`) }}</span>
                                  </div>
                                  <div class="col-4">
                                    <button v-if="index == 0" class="btn btn-outline-success border-success" @click="addData(documents.id, index)" type="button">
                                      <i class="fa fa-plus"></i>
                                    </button>
                                    <button v-if="documents.nolot_unit_jadual.length > 1" class="btn btn-outline-danger border-danger" @click="removeData(documents.id, index)" type="button">
                                      <i class="fa fa-minus"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer text-right">
                        <button class="btn btn-success" type="button" @click.prevent="save" :disabled="loading">
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  inject: [ "$validator" ],
  name: "JadualModal",

  mounted() {
    vmodal.register(this.modalId, { open: this.onOpen, close: this.onClose });
  },

  props: {
    pemajuan: Object,
    typeDocJadual: Array,
  },

  data() {
    return {
      modalId: "jadual",
      loading: false,
      unit: {},
      pembeli_id: null,
      unit_pertama: null,
      pembeli_rs: [],
      form: {},
      errorTimeout: null,
      response: () => {
      },
      disabled: 0,
      listDoc: [],
      type: null
    };
  },

  methods: {
    async onOpen(data, callback) {
      this.unit = JSON.parse(JSON.stringify(data.unit));
      this.unit_pertama = data.unit_pertama;
      this.type = data.type

      this.loading = true;
      this.response = callback;

      const response = await axios.get(`/pemaju/kuota-bumi/jadual/${this.unit.id}?type=${this.type}`)
      this.listDoc = response.data.list_doc
      this.loading = false;
    },

    onClose() {
    },

    async save() {
      const isValid = await this.$validator.validateAll()
      if(!isValid) {
        swal.fire(
          "Tidak Berjaya!",
          `Sila Lengkapkan Borang Jadual ${this.type}`,
          "warning",
        );
        return;
      }
      this.loading = true;
      const formData = new FormData();

      this.listDoc.map((item) => {
        item.nolot_unit_jadual.map((jadual, index) => {
          if (jadual.id) formData.append(`docs[${item.id}][${index}][id]`, jadual.id);
          if(jadual.document instanceof File) {
            // append the files to FormData
            formData.append(`docs[${item.id}][${index}][file]`, jadual.document);
          }
        })
      })

      try {
        const response = await axios.post(`/pemaju/kuota-bumi/jadual/${this.unit.id}?type=${this.type}`, formData)
        this.loading = false;
        this.listDoc = response.data.list_doc
        swal.fire(
          "Berjaya!",
          "Maklumat jadual berjaya dikemaskini.",
          "success",
        );
      } catch (error) {
        console.log(error);
        swal.fire(
          "Tidak Berjaya!",
          "Sila Lengkapkan Borang Pendaftaran Calon Pembeli Peguam",
          "warning",
        );
      }
    },

    close() {
      vmodal.close(this.modalId);
    },

    addData(documentId, index) {
      return this.listDoc.find((item) => item.id == documentId)
      .nolot_unit_jadual
      .push({
        document: null
      })
    },
    
    removeData(documentId, index) {
      // add confirm swal
      swal.fire({
        title: "Adakah anda pasti?",
        text: "Dokumen ini akan dihapus dari senarai jadual.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0069d9",
        cancelButtonColor: "#d90014",
        confirmButtonText: "Teruskan",
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.isConfirmed) {
          return this.listDoc.find((item) => item.id == documentId)
          .nolot_unit_jadual
          .splice(index, 1);
        }
        return;
      })
    }

  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1;
    },
    isFirstBuyer() {
      return this.unit_pertama && this.unit.id == this.unit_pertama.id;
    },
  },
};
</script>

<style scoped>

</style>
