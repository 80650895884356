<template>
  <table class="table table-card">
    <thead class="thead-light">
    <slot name="header"></slot>
    </thead>
    <tbody>
    <slot v-bind:rows="rows" v-bind:form="form" v-bind:add="add" v-bind:remove="remove"></slot>
    </tbody>
  </table>

</template>

<script>
  export default {
    props: {
      default: Object,
      data: {
        type: Array,
        default: () => ([]),
      },
    },

    data() {
      return {
        form: this.createDefault(),
        rows: JSON.parse(JSON.stringify(this.data)),
      };
    },

    methods: {
      createDefault() {
        if (typeof this.default === 'undefined') {
          return {};
        }

        return JSON.parse(JSON.stringify(this.default));
      },

      add() {
        if (Object.keys(this.form).every((key) => this.form[key])) {
          this.rows.push(this.form);
          this.form = this.createDefault();
        }
      },

      remove(row) {
        const index = this.rows.indexOf(row);

        if (index !== -1) {
          this.rows.splice(index, 1);
        }
      },
    },
  }
</script>
