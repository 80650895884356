<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">MAKLUMAT TANAH</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1&#9;Label Tanah <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="form.label" @input="form.label = $event.target.value.toUpperCase()"
                       :class="{'is-invalid': errors.first('label')}"
                       name="label"
                       v-validate="'required'"
                >
                <div class="form-text text-grey">Contoh: Tanah Projek Kayangan</div>
              </div>
            </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Status Hakmilik <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select
                    class="form-control"
                    v-model="form.kod_jenis_hakmilik_id"
                    @change="form.kod_jenis_rumah_id = null"
                    :class="{'is-invalid': errors.first('kod_jenis_hakmilik_id')}"
                    name="kod_jenis_hakmilik_id"
                    v-validate="'required'"
                  >
                    <option :value="null">Sila Pilih</option>
                    <option v-for="item in kodJenisHakmilikRs" :value="item.id" :key="item.id">{{ item.keterangan }}</option>
                    <!-- <option v-for="item in filteredJenisHakmilikRs()" :value="item.id" :key="item.id">{{ item.keterangan }}</option> -->
                  </select>
                </div>
              </div>
              <section>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Lokasi Tanah Pemajuan</label>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri<span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2 v-model="form.kod_negeri_id"
                             @change="form.kod_daerah_id = null; form.kod_bandar_id = null; form.kod_parlimen_id = null; form.kod_dun_id = null;"
                             class="form-control"
                             v-validate="'required'"
                             name="kod_negeri_id"
                             :class="{'is-invalid': errors.first('kod_negeri_id')}"
                    >
                      <option :value="null">Sila Pilih Negeri</option>
                      <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri-${negeri.id}`">{{ negeri.keterangan }}</option>
                    </select2>
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Daerah<span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2 v-model="form.kod_daerah_id"
                             @change="form.kod_bandar_id = null;"
                             class="form-control"
                             name="kod_daerah_id"
                             v-validate="'required'"
                             :class="{'is-invalid': errors.first('kod_daerah_id')}"
                    >
                      <option :value="null">Sila Pilih Daerah</option>
                      <option v-for="daerah in filteredDaerahRs(form.kod_negeri_id)" :value="daerah.id" :key="`daerah-${daerah.id}`">{{
                          daerah.keterangan
                        }}
                      </option>
                    </select2>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2 v-model="form.kod_bandar_id" class="form-control"
                             v-validate="'required'"
                             name="kod_bandar_id"
                             :class="{'is-invalid': errors.first('kod_bandar_id')}"
                    >
                      <option :value="null">Sila Pilih Mukim / Bandar / Pekan</option>
                      <option v-for="bandar in filteredBandarRs(form.kod_daerah_id)" :value="bandar.id" :key="`bandar-${bandar.id}`">{{
                          bandar.keterangan
                        }}
                      </option>
                    </select2>
                  </div>
                </div> -->

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Parlimen <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <select2 v-model="form.kod_parlimen_id" @change="form.kod_dun_id = null;" class="form-control"
                             v-validate="'required'"
                             name="kod_parlimen_id"
                             :class="{'is-invalid': errors.first('kod_parlimen_id')}"
                    >
                      <option :value="null">Sila Pilih Parlimen</option>
                      <option v-for="parlimen in filteredParlimenRs(form.kod_negeri_id)" :value="parlimen.id" :key="`parlimen_${parlimen.id}`">
                        {{ parlimen.keterangan }}
                      </option>
                    </select2>
                  </div>
                </div>

                <div class="form-group row" v-if="showDun(form.kod_negeri_id)">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;DUN <span class="label-required">*</span></label>
                  <div class="col-md-4" >
                    <select2 v-model="form.kod_dun_id" class="form-control"
                             v-validate="'required'"
                             name="kod_dun_id"
                             :class="{'is-invalid': errors.first('kod_dun_id')}"
                    >
                      <option :value="null">Sila Pilih DUN</option>
                      <option v-for="dun in filteredDunRs(form.kod_parlimen_id)" :value="dun.id" :key="`dun_${dun.id}`">{{ dun.keterangan }}</option>
                    </select2>
                  </div>
                </div>

                <!-- <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Poskod <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" v-model="form.poskod"
                           v-validate="'required'"
                           name="poskod"
                           :class="{'is-invalid': errors.first('poskod')}"
                    >
                  </div>
                </div> -->
              </section>
          </div>
          <div class="modal-footer">
            <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "TanahModal",
  mounted() {
    vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
  },

  props: {
    kodJenisHakmilikRs: Array,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      modalId: 'tanahmodal',
      loading: false,
      saving: false,
      form: {},

      response: () => {
      },
    }
  },

  methods: {
    onOpen({
             label = null,
             kod_jenis_hakmilik_id = null,
             kod_negeri_id = null,
             kod_daerah_id = null,
             kod_bandar_id = null,
             kod_parlimen_id = null,
             poskod = null,
             kod_dun_id = null,
           }, callback) {
      this.form = {
        label,
        kod_jenis_hakmilik_id,
        kod_negeri_id,
        kod_daerah_id,
        kod_bandar_id,
        kod_parlimen_id,
        poskod,
        kod_dun_id,
      };

      this.response = callback;
    },

    onClose() {
    },

    close() {
      vmodal.close(this.modalId);
    },

    async save() {
      if (this.loading) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      // copy
      const form = JSON.parse(JSON.stringify(this.form));
      const self = this;

      this.response(form, {
        setLoading(loading) {
          self.loading = loading;
        },
        errorCallback(err) {
          self.$hError(err)
        },
        close: self.close
      });
    },

    filteredJenisHakmilikRs() {
      return this.kodJenisHakmilikRs.filter(item => item.id != 3);
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(item => item.kod_daerah_id == kod_daerah_id);
    },
    filteredParlimenRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodParlimenRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },

    filteredDunRs(kod_parlimen_id) {
      if (!kod_parlimen_id) {
        return [];
      }

      return this.kodDunRs.filter(item => item.kod_parlimen_id == kod_parlimen_id);
    },

    showDun(kod_negeri_id) {
      if (!kod_negeri_id) {
        return false;
      }

      const negeri = this.kodNegeriRs.find(item => item.id == kod_negeri_id);

      return negeri && negeri.ada_dun == 'Y';
    }
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1
    },

    showKaveat() {
      return [2,3,4,5].indexOf(this.form.kod_bebanan_tanah_id) !== -1;
    },

    showGadaian() {
      return [1,4,5].indexOf(this.form.kod_bebanan_tanah_id) !== -1;
    }
  }
}
</script>

<style scoped>

</style>
