<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Terdapat Sijil SIFUS? <span class="label-required">*</span></label>
        <div class="col-md-8 col-form-label">
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" v-model="form.sifus_ada" name="sifus_ada"
                   @change="save"
                   :id="`sifus_ada_1`" :value="1" :disabled="readonly">
            <label class="form-check-label" :for="`sifus_ada_1`">Ada</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" v-model="form.sifus_ada" name="sifus_ada"
                   @change="save"
                   :id="`sifus_ada_0`" :value="0" :disabled="readonly">
            <label class="form-check-label" :for="`sifus_ada_0`">Tiada</label>
          </div>
        </div>
      </div>

      <section v-if="maklumat.sifus_ada === 1">
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Tajuk <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.sifus_tajuk" @input="form.sifus_tajuk = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Tarikh Kelulusan <span class="label-required">*</span></label>
          <div class="col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('sifus_tarikh_kelulusan')}"
              name="sifus_tarikh_kelulusan"
              v-model="form.sifus_tarikh_kelulusan"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;No Siri <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.sifus_nosiri" @input="form.sifus_nosiri = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;No Rujukan Fail <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.sifus_no_rujukan" @input="form.sifus_no_rujukan = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Salinan Sijil Penentuan Formula Unit Syer (SIFUS) <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="sifus_doc_rujukan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`sifus_doc_rujukan`)}"
              :doc="maklumat.sifus_doc_rujukan"
              @uploadwithcallback="({file, callback}) => uploadDoc('sifus_doc_rujukan', file, callback)"
              @remove="removeDoc('sifus_doc_rujukan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>
      </section>

      <section v-if="maklumat.sifus_ada === 0">
        
          <div class="col-md-8 col-form-label">
            <label class="col-form-label" style="white-space: pre-wrap; color: red;">Permohonan ini tidak boleh diteruskan sehingga pemaju mendapat kelulusan SIFUS.</label>
          </div>
          
          <!-- <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Surat Rayuan pengecualian SIFUS</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="sifus_doc_rayuan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`sifus_doc_rayuan`)}"
              :doc="maklumat.sifus_doc_rayuan"
              @uploadwithcallback="({file, callback}) => uploadDoc('sifus_doc_rayuan', file, callback)"
              @remove="removeDoc('sifus_doc_rayuan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div> -->
       

      </section>

    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianO",
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,

      form: this.parse(this.maklumat),
    }
  },

  methods: {
    parse(maklumat) {
      return {
        sifus_ada: maklumat.sifus_ada,
        sifus_tajuk: maklumat.sifus_tajuk,
        sifus_nosiri: maklumat.sifus_nosiri,
        sifus_no_rujukan: maklumat.sifus_no_rujukan,
        sifus_tarikh_kelulusan: maklumat.sifus_tarikh_kelulusan,
      };
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-o`, this.form)
        .then((response) => {
          this.form = this.parse(JSON.parse(JSON.stringify(response.data)));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  },

  computed: {

  }
}
</script>

<style scoped>

</style>
