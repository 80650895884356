<template>
  <div class="card">
    <section class="card-body form-compact">
      <h4 class="card-title">DOKUMEN SOKONGAN</h4>

      <!-- edit data -->
      <pemaju-permohonan-dokumen
        v-if="readonly || default_docs.length > 0"
        :default_docs="default_docs"
        @update:default_docs="(value) => $emit('update:default_docs', value)"
        :readonly="readonly"
        :showKuiri="showKuiri"
        :showReply="showKuiri && showReply"
        :doc-configs="docConfigs"
      ></pemaju-permohonan-dokumen>
      <!-- new upload -->
      <pemaju-permohonan-dokumen-default
        v-else
        :kod_permohonan_pemaju_detail="kod_permohonan_pemaju_detail"
        :doc-configs="docConfigs"
      ></pemaju-permohonan-dokumen-default>
    </section>
  </div>
</template>

<script>
  import PemajuPermohonanDokumen from "./PemajuPermohonanDokumen";
  import PemajuPermohonanDokumenDefault from "./PemajuPermohonanDokumenDefault";

  export default {
    components: {PemajuPermohonanDokumenDefault, PemajuPermohonanDokumen},
    inject: ['$validator'],

    props: {
      readonly: {
        type: Boolean,
        default: false,
      },

      docConfigs: Array,

      kod_permohonan_pemaju_detail: null,

      default_docs: Array,

      showKuiri: {
        type: Boolean,
        default: true,
      },

      showReply: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>
