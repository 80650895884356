<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps">
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            href="#bahagian-a"
          ><span v-if="hasFormKuiri" class="text-danger"><i class="fas fa-exclamation-circle"></i></span> BUTIRAN MAKLUMAT JURUAUDIT</a>
        </li>
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            href="#bahagian-b"
          ><span v-if="hasDocKuiri" class="text-danger"><i class="fas fa-exclamation-circle"></i></span> SALINAN DOKUMEN SOKONGAN</a>
        </li>
      </ul>
      <div class="tab-content mt-4">
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-a')}"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN MAKLUMAT JURUAUDIT</h4>
              <bahagian-a
                :pemaju="pemaju"

                :tarikh_akhir_kewangan="maklumat.tarikh_akhir_kewangan"

                :pemaju_nama_pegawai.sync="maklumat.pemaju_nama_pegawai"
                :pemaju_no_bimbit.sync="maklumat.pemaju_no_bimbit"
                :nama_juruaudit.sync="maklumat.nama_juruaudit"
                :alamat_juruaudit.sync="maklumat.alamat_juruaudit"
                :no_tel_juruaudit.sync="maklumat.no_tel_juruaudit"
                :no_faks_juruaudit.sync="maklumat.no_faks_juruaudit"
                :emel_juruaudit.sync="maklumat.emel_juruaudit"
                :no_kelulusan.sync="maklumat.no_kelulusan"
                :tarikh_mula.sync="maklumat.tarikh_mula"
                :tarikh_akhir.sync="maklumat.tarikh_akhir"

                :readonly="readonly"
              ></bahagian-a>
              <div class="form-group form-row" v-if="hasFormKuiri">
                <label class="col-form-label col-md-4">Catatan Kuiri</label>
                <textarea class="form-control" rows="10" disabled>{{hasFormKuiri.catatan_kuiri}}</textarea>
              </div>
            </div>
            <div class="card-footer" v-if="!readonly">
              <div class="text-right">
                <button class="btn btn-primary" @click.prevent="save" :disabled="saving">SIMPAN</button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-b')}"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">SALINAN DOKUMEN SOKONGAN</h4>
              <maklumat-dokumen-sokongan
                :default_docs.sync="maklumat.dokumen_rs"

                :readonly="readonly"
              ></maklumat-dokumen-sokongan>
            </div>
            <div class="card-footer" v-if="!readonly">
              <div class="text-right">
                <button class="btn btn-primary" @click="submit">HANTAR</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BahagianA from "./BahagianA";
  import Kuiri from "./Kuiri";
  import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";

  export default {
    components: {MaklumatDokumenSokongan, Kuiri, BahagianA},

    props: {
      pemaju: Object,

      default_maklumat: Object,
      default_permohonan: Object,

      docConfigs: Array,

      errorBag: {
        type: Object
      },

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    created() {
      window.addEventListener("hashchange", this.changeTab, false);
      this.$makeErrorBag(this.errorBag);
      this.changeTab();
    },

    destroyed() {
      window.removeEventListener("hashchange", this.changeTab);
    },

    data() {
      return {
        defaultTab: "#bahagian-a",
        tab: this.defaultTab,

        saving: false,

        permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
        maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),

      };
    },

    methods: {
      isCurrentTab(tab) {
        return this.tab === tab;
      },

      changeTab() {
        this.tab = window.location.hash || this.defaultTab;
      },

      validate(skipDocValidation) {
        if (this.$validator.errors.first('pemaju_nama_pegawai') ||
          this.$validator.errors.first('pemaju_no_bimbit') ||
          this.$validator.errors.first('nama_juruaudit') ||
          this.$validator.errors.first('alamat_juruaudit') ||
          this.$validator.errors.first('no_tel_juruaudit') ||
          this.$validator.errors.first('no_faks_juruaudit') ||
          this.$validator.errors.first('emel_juruaudit') ||
          this.$validator.errors.first('no_kelulusan') ||
          this.$validator.errors.first('tarikh_mula')
        ) {
          window.location.hash = '#bahagian-a';
        } else if (!skipDocValidation) {
          window.location.hash = '#bahagian-b';
          return true;
        }

        return false;
      },

      async save(ev) {
        const passes = await this.$validator.validateAll();

        if (!passes && this.validate(true)) {
          return;
        }

        this.saving = true;
        axios.put(`/pemaju/permohonan/${this.permohonan.id}`, this.maklumat)
          .then((response) => {
            this.maklumat = response.data;
          })
          .catch(() => {

          })
          .then(() => {
            this.saving = false;
          })

      },

      async submit(ev) {
        ev.preventDefault();

        const passes = await this.$validator.validateAll();

        if (!passes && this.validate()) {
          return;
        }

        swalconfirm()
          .then(result => {
            if (result.value) {
              ev.target.form.submit();
            }
          });
      }
    },

    computed: {
      hasDocKuiri() {
        return this.maklumat.dokumen_rs.some((dokumen) => {
          return !dokumen.flag_lulus;
        });
      },
      hasFormKuiri() {
        // console.log();
        return this.permohonan.get_kuiri_maklumat;
      }
    }
  }
</script>
