<template>
  <div class="card">
    <div class="card-body form-compact">
      <div>
        <label for="">
          <strong>PERAKUAN PEMAJU PERUMAHAN</strong>
        </label>
      </div>
      <div>
        <label class="form-check-label ml-2 text-justify">
          Menurut peruntukan-peruntukan Akta Pemajuan Perumahan (Kawalan dan
          Pelesenan) 1966 (Akta 118) &amp; Peraturan-Peraturan dan Akta Akuan
          Berkanun 1960 (Akta 113) tuan adalah dengan ini dikehendaki
          mengemukakan dalam borang ini suatu penyataan mengenai laporan
          kemajuan kewangan terkini bermula daripada tarikh permit iklan dan
          jualan diperolehi bagi suatu pemajuan perumahan yang dimajukan di
          Semenanjung Malaysia, dan memberi butir-butir berkaitan yang diminta
          di dalam borang ini. Jika tuan gagal mengemukakan laporan ini dalam
          masa yang ditetapkan atau membuat kenyataan palsu tentang mana-mana
          butiran, atau gagal memberitahu pertukaran alamat, tuan boleh didakwa
          atau dikenakan penalti menurut Seksyen 19 Akta Pemajuan Perumahan
          (Kawalan dan Pelesenan) 1966 (Akta 118) dan Peraturan-Peraturan serta
          Seksyen 199 dan 200 Kanun Keseksaan (Akta 574) dan Akta Akuan Berkanun
          1960 (Akta 113).
        </label>
      </div>
      <div
        class="align-items-start mt-2 text-justify"
        :class="{ 'form-check': !readonly }"
      >
        <input
          id="pengesahan"
          type="checkbox"
          name="pengesahan"
          class="form-check-input"
          @input="$emit('update:pengesahan', $event.target.checked)"
          :checked="pengesahan"
          :class="{ 'is-invalid': errors.first('pengesahan') }"
          v-validate="'required'"
        />
        <label for="pengesahan" class="form-check-label ml-2">
          Saya, <b>{{ pengarah.nama }}</b
          >, No. Kad Pengenalan, <b>{{ pengarah.no_kp }}</b> bersumpah dan
          mengaku sepanjang pengetahuan dan kepercayaan saya/ kami, butir-butir
          yang diberikan di dalam perakuan ini adalah benar dan lengkap dan
          faham bahawa apa-apa salah penyataan butir-butir yang dikemukakan
          adalah menjadi satu kesalahan di bawah Peraturan 3A, 4, 5, 6, 7 dan 8
          Peraturan-Peraturan Pemajuan Perumahan (Akaun Pemajuan Perumahan)
          1991, Akta Pemajuan Perumahan (Kawalan dan Pelesenan) 1966 dan Akta
          Akuan Berkanun 1960.
        </label>
      </div>
      <br />
      <div>
        <label for=""
          >Sila klik butang “HANTAR” sekiranya maklumat telah dikemaskini dengan
          betul dan lengkap.</label
        >
      </div>
      <!-- <div class="form-group form-row">
        <label class="col-form-label col-md-2">Nama :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ pengarah.nama }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Jawatan :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ pengarah.jawatan }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Tarikh :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ tarikh_hantar }}</span>
        </div>
      </div> -->
      <div class="text-right" v-if="!readonly">
        <!-- <button class="btn btn-primary" @click="$emit('submit', $event)">SIMPAN</button> -->
        <button class="btn btn-success px-5" @click="$emit('submit', $event)">
          HANTAR
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],

  props: {
    tarikh_hantar: String,

    readonly: {
      type: Boolean,
      default: false,
    },

    pengesahan: Boolean,

    pengarah: Object,
  },
};
</script>
