export default {
    provide() {
        if (this.$validationError && this.$makeErrorBag) {
            return {
                $validationError: this.$validationError,
                $makeErrorBag: this.$makeErrorBag,
            };
        }
    },

    data() {
        return {
            submitting: false
        };
    },

    beforeCreate() {
        this.$validationError = errorResponse => {
            // check if errors exist
            if (!errorResponse.hasOwnProperty("errors")) {
                return;
            }

            this.$makeErrorBag(errorResponse.errors);
        };

        this.$makeErrorBag = errors => {
            // only allow this function to be run if the validator exists
            if (!this.hasOwnProperty("$validator")) {
                return;
            }

            // clear errors
            this.$validator.errors.clear();

            let errorFields = Object.keys(errors);

            // insert laravel errors
            for (let i = 0; i < errorFields.length; i++) {
                let field = errorFields[i];
                let errorString = errors[field].join(", ");

                field = field.replace(/\.(.+?)(?=\.|$)/g, (m, s) => `[${s}]`)
                this.$validator.errors.add({ field: field, msg: errorString });
            }
        };
    }
};
