<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">TARIKH & NO RUJUKAN DOKUMEN</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4">Carian Pemaju <span class="label-required">*</span></label>
              <div class="col-md-8">
                <v-select label="nama" :filterable="false" :options="options" @search="onSearch" :value="form" @input="pilihPemaju">
                  <template slot="no-options">
                    carian nama pemaju..
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <strong>{{ option.kod_pemaju }}</strong>&nbsp;- {{ option.nama }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <strong>{{ option.kod_pemaju }}</strong>&nbsp;- {{ option.nama }}
                    </div>
                  </template>
                </v-select>
              </div>
            </div>

            <section v-if="form">
              <h6 class="card-title">Maklumat Syarikat</h6>
              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_rujukan">No. Daftar SSM</label>
                <div class="col-md-8">
                  <input id="no_rujukan" type="text" class="form-control" :value="form.no_ssm" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" for="nama_syarikat">Nama Syarikat</label>
                <div class="col-md-8">
                  <input id="nama_syarikat" type="text" class="form-control" :value="form.nama" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" for="kod_jenis_pemaju_id">Jenis Syarikat <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <select
                    id="kod_jenis_pemaju_id"
                    name="kod_jenis_pemaju_id"
                    class="form-control" :class="{'is-invalid': errors.first('kod_jenis_pemaju_id')}"
                    v-model="form.kod_jenis_pemaju_id"
                    v-validate="'required'"
                  >
                    <option :value="null">Sila Pilih</option>
                    <option v-for="item in kodJenisPemajuRs" :value="item.id" :key="`kod-${item.id}`">{{ item.keterangan }}</option>
                  </select>
                </div>
              </div>
              <br>
              <div class="form-group">
                <label>Senarai Ahli Lembaga Pengarah / Perkongsian / Pemilikan Syer:</label>
              </div>
              <div class="table-responsive">
                <table class="table">
                  <thead>
                  <tr>
                    <th>Bil</th>
                    <th>Nama Ahli Lembaga Pengarah/ Perkongsian/ Pemilikan Syer</th>
                    <th>No. KP Baru / Passport</th>
                  </tr>
                  </thead>

                  <tbody>
                  <template v-if="form.pengarah_rs.length">
                    <tr v-for="(pengarah, index) in form.pengarah_rs" :key="`pengarah-${pengarah.id}`">
                      <td>{{ index + 1 }}</td>
                      <td>{{ pengarah.nama }}</td>
                      <td>{{ pengarah.no_kp }}</td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td class="text-center" colspan="99">Tiada maklumat pengarah</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </section>

          </div>
          <div class="modal-footer">
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  name: "TambahMaklumatSyarikatModal",

  components: {
    vSelect,
  },

  mounted() {
    vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
  },

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },

    kodJenisPemajuRs: Array,
  },

  data() {
    return {
      modalId: 'tambahmaklumatsyarikat',
      loading: false,
      saving: false,
      kod_pemaju: null,
      options: [],
      form: null,
      response: () => {
      },
    }
  },

  methods: {
    onOpen({ }, callback) {
      this.form = null;
      this.options = [];
      this.response = callback;
    },

    onClose() {
    },

    close() {
      vmodal.close(this.modalId);
    },

    onSearch(search, loading) {
      if (search && search.length >= 3) {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      axios.get(
        `/pemaju/apdl-lesen-baru/carian-pemaju?q=${escape(search)}`
      ).then(response => {
        vm.options = response.data;
        loading(false);
      });
    }, 350),

    pilihPemaju(pemaju) {
      this.form = {
        kod_pemaju: pemaju.kod_pemaju,
        nama: pemaju.nama,
        no_ssm: pemaju.no_ssm,
        kod_jenis_pemaju_id: pemaju.kod_jenis_pemaju_id,
        pengarah_rs: pemaju.pengarah_rs,
      }
    },

    async save() {
      if (!this.form) {
        swal.fire(
          'Maaf!',
          'Sila pilih pemaju.',
          'error'
        );
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.response({...this.form});
      this.close();
    },
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1
    },

    kategoriPemaju() {
      if (this.form && this.form.kod_kategori_pemaju_id) {
        const result = this.kodKategoriPemajuRs.find(item => item.id == this.form.kod_kategori_pemaju_id);

        return result ? result.keterangan : '-';
      }

      return '-';
    },
  }
}
</script>

<style scoped>

</style>
