<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="col-lg-10">
          <div class="form-group form-row">
            <label class="col-form-label col-md-3">1. No Akaun HDA</label>
            <div class="col-md-9">
              <input type="text" class="form-control" :value="akaunHda.no_akaun" disabled>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-form-label col-md-3">2. Nama Bank</label>
            <div class="col-md-9">
              <input type="text" class="form-control" :value="akaunHda.nama_bank" disabled>
            </div>
          </div>
          <div class="form-group form-row">
            <label class="col-form-label col-md-3">3. Cawangan Bank</label>
            <div class="col-md-9">
              <input type="text" class="form-control" :value="akaunHda.alamat1_bank" disabled>
            </div>
          </div>

          <div class="form-group form-row">
            <label class="col-form-label col-md-3">4. Tarikh HDA Dibuka</label>
            <div class="col-md-3">
              <input class="form-control" disabled name="tarikh_dibuka" :value="tarikh_dibuka">
            </div>
          </div>

          <div class="form-group form-row">
            <label class="col-form-label col-md-3">5. Jumlah Wang Pendahuluan <span
                class="label-required">*</span></label>
            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">RM</span>
                </div>
                <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('wang_pendahuluan') }"
                  name="wang_pendahuluan" :value="wang_pendahuluan"
                  @input="$emit('update:wang_pendahuluan', $event.target.value.toUpperCase())"
                  v-validate="'required|decimal:2'" />
              </div>
            </div>
          </div>

          <div class="form-compact form-row">
            <div class="col-lg-3">
              <div class="form-group form-row">
                <label class="col-form-label col">6. Jenis Wang Pendahuluan <span class="label-required">*</span></label>
              </div>
            </div>
            <div class="col-lg-9">
              <div class="form-group" tabindex="-1">
                <div class="form-check form-check-inline">
                  <input type="radio" id="kod_jenis_wp_hda" name="kod_jenis_wp_id" class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="$emit('update:kod_jenis_wp_id', 'T')" :checked="kod_jenis_wp_id === 'T'"
                    v-validate="'required'" value="T" />
                  <label class="form-check-label" for="kod_jenis_wp_hda">Tunai</label>
                </div>
                <div class="form-check form-check-inline">
                  <input type="radio" id="kod_jenis_wp_bg" name="kod_jenis_wp_id" class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="$emit('update:kod_jenis_wp_id', 'S')" :checked="kod_jenis_wp_id === 'S'" value="S" />
                  <label class="form-check-label" for="kod_jenis_wp_bg">Jaminan Bank / Bank Guarantee</label>
                </div>
                <div class="form-check form-check-inline">
                  <input type="radio" id="kod_jenis_wp_aa" name="kod_jenis_wp_id" class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="$emit('update:kod_jenis_wp_id', 'A')" :checked="kod_jenis_wp_id === 'A'" value="A" />
                  <label class="form-check-label" for="kod_jenis_wp_aa">Akaun Amanah</label>
                </div>
              </div>
            </div>
          </div>


          <div class="form-group form-row">
            <label class="col-form-label col-md-3">7. Baki Berdasarkan Penyata Terkini <span
                class="label-required">*</span></label>
            <div class="col-md-3">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text">RM</span>
                </div>
                <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('baki_terkini') }"
                  name="baki_terkini" :value="baki_terkini"
                  @input="$emit('update:baki_terkini', $event.target.value.toUpperCase())"
                  v-validate="'required|decimal:2'" />
              </div>
            </div>
          </div>

          <!-- <div class="form-group form-row">
            <label class="col-form-label col-md-3">8. Tarikh Penutupan Akaun <span class="label-required">*</span></label>
            <div class="col-md-3">
              <div class="input-group">
                <datepicker type="text" name="tarikh_penutupan_akaun"
                  :class="{ 'is-invalid': errors.first('tarikh_penutupan_akaun') }" v-model="tarikh_penutupan_akaun"
                  v-validate="'required'" :value="tarikh_penutupan_akaun"></datepicker>
              </div>
            </div>
          </div> -->

        </div>

      </section>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],

  props: {
    akaunHda: {
      type: Object,
    },

    tarikh_dibuka: String,
    kod_jenis_wp_id: String,
    wang_pendahuluan: String | Number,
    baki_terkini: String | Number,
  },
}
</script>
