require("./bootstrap");

window.moment = require('moment');

const VueTheMask = require('vue-the-mask');
import { format } from 'date-fns';
import PortalVue from 'portal-vue'
import money from 'v-money';

Vue.use(VueTheMask)

Vue.use(VeeValidate);
VeeValidate.Validator.localize('my');

Vue.use(PortalVue)
Vue.use(money, {
    precision: 2,
    suffix: '',
    prefix: '',
    decimal: '.',
    thousands: ',',
})

window.optional = function(property) {
    return property || {};
};

Vue.mixin({
    methods: {
        optional: window.optional
    }
});

Vue.mixin({
    data() {
        return {
            succesfulMessage: '',
            $successTimeout: null,
            hErrorMessage: '',
            $hErrorTimeout: null,
            today: format(new Date, 'dd/MM/yyyy')
        };
    },

    methods: {
        $success(message) {
            this.succesfulMessage = message || 'Berjaya disimpan!';
        },

        $hError(error) {
            if (error.response.status === 422) {
                const firstKey = Object.keys(error.response.data.errors)[0];

                this.hErrorMessage = `${error.response.data.errors[firstKey][0]}`;
            }
        },
    },

    watch: {
        'succesfulMessage': function(value) {
            if (this.$successTimeout) {
                clearTimeout(this.$successTimeout);
            }

            this.$successTimeout = window.setTimeout(() => {
                this.succesfulMessage = '';
            }, 3000);
        },

        'hErrorMessage': function(value) {
            if (this.$hErrorTimeout) {
                clearTimeout(this.$hErrorTimeout);
            }

            this.$hErrorTimeout = window.setTimeout(() => {
                this.hErrorMessage = '';
            }, 3000);
        }
    }
})

Vue.mixin(require("./mixins/ValidatorErrorResponse").default);
Vue.use(require("vue-currency-filter").default, {
    symbol: "RM",
    thousandsSeparator: ",",
    fractionCount: 2,
    fractionSeparator: ".",
    symbolPosition: "front",
    symbolSpacing: true
});

window.swalconfirm = function() {
    return new Promise((resolve, reject) => {
        swal.fire({
            title: 'Adakah anda pasti?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#0069d9',
            cancelButtonColor: '#d90014',
            confirmButtonText: 'Teruskan',
            cancelButtonText: 'Batal'
        }).then((result) => {
            resolve(result);
        });
    });
};


window.vmodal = new Vue({
    data: {
        modals: {},
    },

    methods: {
        register(modalKey, { open, close }) {
            this.modals[modalKey] = {
                open,
                close,
            };
        },

        open(modalKey, data, callback) {
            if (!this.modals.hasOwnProperty(modalKey)) {
                throw Error(`Modal ${modalKey} not registered.`);
            }

            const handler = this.modals[modalKey];

            this.$emit('open', {
                key: modalKey,
                handler,
                data,
                callback,
            });
        },

        close(modalKey) {
            const handler = this.modals[modalKey];

            this.$emit('close', modalKey);
            return handler.close();
        },
    },
});

new Vue({
    el: "#app",

    data: {
        openedModals: [],
        window: window,
    },

    mounted() {
        window.onkeydown = ev => {
            let escapePressed = false;

            if (ev.key !== undefined) {
                escapePressed = ev.key === 'Escape';
            } else if (ev.keyCode !== undefined) {
                escapePressed = ev.keyCode === 27;
            }

            if (this.openedModals.length && escapePressed) {
                vmodal.close(this.openedModals[this.openedModals.length - 1]);
            }
        };

        vmodal.$on('open', ({
            key,
            handler,
            data,
            callback,
        }) => {
            if (!this.openedModals.length) {
                $("body").addClass("modal-open");
            }

            if (this.openedModals.indexOf(key) !== -1) {
                return;
            }

            this.openedModals.push(key);
            handler.open(data, callback);
        });

        vmodal.$on('close', (modalKey) => {
            if (!modalKey) {
                this.openedModals.pop();
            } else {
                let index = this.openedModals.indexOf(modalKey);

                if (index !== -1) {
                    this.openedModals.splice(index, 1);
                }
            }

            if (!this.openedModals.length) {
                $("body").removeClass("modal-open");
            }
        });
    },
});

(function($) {
    $(".scrollbar-macosx").scrollbar();

    $('[data-toggle="sidebar"]').on("click", function(e) {
        const targetSelector = $(this).data("target");

        $(targetSelector).toggleClass("show");
    });

    $(".has-submenu > .nav-link").on("click", function(e) {
        e.stopImmediatePropagation();

        const thisEl = $(this).parent(".has-submenu");
        $(".has-submenu").each(function(e) {
            if ($(this).is(thisEl)) {
                $(this).toggleClass("open");
            } else {
                $(this).removeClass("open");
            }
        });
    });

    $(function() {
        $('[data-toggle="tooltip"]').tooltip();
    });
})(jQuery);
