<template>
  <section class="list-group">
    <div class="list-group-item form-row d-flex" v-for="(config, index) in filteredDocConfigs" :key="`doc-${config.id}`">
      <label class="col-form-label col-md-8">
          {{ index + 1 }}.
          <span v-html="config.kod_dokumen.nama_dokumen"></span>
          <span class="label-required" v-if="config.mandatori_ind">*</span>
          <br />
          <p v-if="config.kod_dokumen.id == 39" class="text-info ml-3 mt-2 mb-0 pb-0">Sila memuat naik Sijil Lesen Pemaju Perumahan (DL) & Permit Iklan dan Jualan (AP) bersekali.</p>
          <p v-else-if="config.kod_dokumen.id == 55" class="ml-3 mt-2 mb-0 pb-0" style="font-style: italic;">Independent Auditor's Report To The Member Of The Company</p>
          <!-- Add more v-else-if blocks for other specific document IDs -->
      </label>
      <div class="col-md-4">
        <div class="form-inline">
          <input-file
            v-if="!config.multiple" type="file" accept="application/pdf"
            :name="`doc[${config.kod_dokumen_id}]`"
            class="form-control flex-grow-1 mr-2"
            :class="{'is-invalid': errors.first(`doc[${config.kod_dokumen_id}]`)}"
            v-validate="config.mandatori_ind ? 'required' : ''"
          />
          <input-multi-file
            v-else type="file" accept="application/pdf"
            :name="`doc[${config.kod_dokumen_id}][]`"
            class="form-control flex-grow-1 mr-2"
            :class="{'is-invalid': errors.first(`doc[${config.kod_dokumen_id}]`)}"
          />
          <a v-if="config.contoh_dokumen_ind" class="d-flex align-items-center mt-1 text-decoration-none" target="_blank" :href="config.contoh_dokumen_url ? config.contoh_dokumen_url : 'javascript:void(0);'" data-toggle="tooltip" data-placement="left" title="Contoh Dokumen">
            <span class="badge badge-primary mr-1">Contoh</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    name: "PemajuPermohonanDokumenDefault",
    inject: ['$validator'],

    props: {
      docConfigs: Array,

      kod_permohonan_pemaju_detail: null,
    },

    computed: {
      filteredDocConfigs() {
        return this.docConfigs.filter((config) => {
          if (!this.kod_permohonan_pemaju_detail) {
            return true;
          }

          if (!config.kod_permohonan_pemaju_detail) {
            return true;
          }

          return this.kod_permohonan_pemaju_detail === config.kod_permohonan_pemaju_detail;
        });
      }
    }
  }
</script>

<style scoped>
  .list-group .list-group-item:hover {
    background: rgba(232, 243, 143, 0.3);
  }
</style>
