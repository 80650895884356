<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div
        class="alert alert-warning pb-0"
        v-if="pemaju.kod_kategori_pemaju_id === 1 && !readonly"
      >
        <div><strong>NOTA</strong></div>

        <div>
          1. Sila klik butang "SSM" bagi memuat turun data terkini.
        </div>
        <div>
          2. Medan bertanda  <img src="/img/espa_icon.png"
              alt="ESPA Icon"
              style="display: inline-flex; align-items: center; line-height: 1;  height: 15px;"
            ></img>  akan dipaparkan dalam cetakan eSPA.
        </div>

        <div
          v-if="pemaju.kod_kategori_pemaju_id === 1 && !readonly"
          class="text-right mb-3"
        >
          <span v-if="syncSuccessful" class="text-success"
            >Berjaya dikemaskini</span
          >
          <button
            class="btn btn-primary"
            @click="syncFromSSM"
            v-else-if="!syncing"
          >
            SSM
          </button>
          <button class="btn btn-primary" v-else @click.stop>
            <i class="fa fa-spinner fa-spin"></i>
          </button>
        </div>
      </div>

      <section>
        <div class="form-group row">
          <label
            class="col-form-label col-md-4"
            style="align-items: center; display: flex"
            >1.<span
              class="tab-space"
              style="display: inline-block; width: 1.4em"
            ></span
            >Nama Pemaju
            <img src="/img/espa_icon.png"
              alt="ESPA Icon"
              class="fas fa-info-circle ml-auto align-middle"
              style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
              data-toggle="tooltip"
              data-placement="top"
              title="Medan ini akan dipaparkan dalam cetakan eSPA."
            ></img
          ></label>
          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.nama"
              disabled
            />
          </div>
        </div>
        <div>
          <div
            class="form-group row"
            v-if="pemaju.kod_kategori_pemaju_id === 6"
          >
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >2.&#9;No. Pendaftaran Koperasi</label
            >
          </div>
          <div v-else>
            <div class="form-group row">
              <label
              class="col-form-label col-md-4"
              style="align-items: center; display: flex"
                >2.<span
              class="tab-space"
              style="display: inline-block; width: 1.4em"
              ></span
              >No. Pendaftaran SSM
              <img src="/img/espa_icon.png"
              alt="ESPA Icon"
              class="fas fa-info-circle ml-auto align-middle"
              style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
              data-toggle="tooltip"
              data-placement="top"
              title="Medan ini akan dipaparkan dalam cetakan eSPA."
            ></img
          ></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  :value="form.no_ssm"
                  disabled
                />
              </div>
            </div>

            <div class="form-group row">
              <label
              class="col-form-label col-md-4"
              style="align-items: center; display: flex"
                >3.<span
              class="tab-space"
              style="display: inline-block; width: 1.4em"
              ></span
              >No. Pendaftaran SSM Baru
              <img src="/img/espa_icon.png"
              alt="ESPA Icon"
              class="fas fa-info-circle ml-auto align-middle"
              style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
              data-toggle="tooltip"
              data-placement="top"
              title="Medan ini akan dipaparkan dalam cetakan eSPA."
            ></img
          ></label
              >
              <div class="col-md-8">
                <input
                  type="text"
                  class="form-control"
                  :value="form.no_ssm_baru"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div class="form-group row">
          <label
          class="col-form-label col-md-4"
          style="align-items: center; display: flex"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 4 : 3 }}.<span
              class="tab-space"
              style="display: inline-block; width: 1.4em"
              ></span
              >Alamat Berdaftar
              <img src="/img/espa_icon.png"
              alt="ESPA Icon"
              class="fas fa-info-circle ml-auto align-middle"
              style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
              data-toggle="tooltip"
              data-placement="top"
              title="Medan ini akan dipaparkan dalam cetakan eSPA."
            ></img
          ></label
          >
          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.alamat_daftar1"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="form.alamat_daftar1 = $event.target.value.toUpperCase()"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="offset-md-4 col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.alamat_daftar2"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="form.alamat_daftar2 = $event.target.value.toUpperCase()"
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="offset-md-4 col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.alamat_daftar3"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="form.alamat_daftar3 = $event.target.value.toUpperCase()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4"
          style="align-items: center; display: flex"
            ><span
              class="tab-space"
              style="display: inline-block; width: 2.3em"
              ></span
              >Poskod & negeri
              </label>

          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              :value="form.poskod_daftar"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="form.poskod_daftar = $event.target.value.toUpperCase()"
            />
          </div>
          <div class="col-md-4">
            <input
              v-if="(pemaju.kod_kategori_pemaju_id === 1 || readonly) && form.kod_negeri_daftar_id"
              type="text"
              class="form-control"
              :value="getNegeri(form.kod_negeri_daftar_id)"
              disabled
            />
            <select
              v-else
              name="kod_negeri_daftar_id"
              class="form-control"
              v-model="form.kod_negeri_daftar_id"
              @change="form.kod_daerah_daftar_id = null"
            >
              <option :value="null">Sila Pilih Negeri</option>
              <option v-for="item in kodNegeriRs" :value="item.id">
                {{ item.keterangan }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row" style="display: none;">
          <label class="col-form-label col-md-4" style="align-items: center; display: flex"
            ><span
              class="tab-space"
              style="display: inline-block; width: 2.3em"
              ></span
              >Daerah <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <select2
              class="form-control"
              v-model="form.kod_daerah_daftar_id"
              @change="form.kod_bandar_daftar_id = null"
              :disabled="readonly"
            >
              <option value="">Sila Pilih Daerah</option>
              <option
                v-for="daerah in filteredDaerahRs(form.kod_negeri_daftar_id)"
                :value="daerah.id"
                :key="`daerah_daftar_${daerah.id}`"
              >
                {{ daerah.keterangan }}
              </option>
            </select2>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="align-items: center; display: flex"
            ><span
              class="tab-space"
              style="display: inline-block; width: 2.3em"
              ></span
              >Bandar<span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <select2
              class="form-control"
              v-model="form.kod_bandar_daftar_id"
              :disabled="true"
            >
              <option value="">Sila Pilih Bandar</option>
              <option
                v-for="bandar in kodBandarRs"
                :value="bandar.id"
                :key="`bandar_daftar_${bandar.id}`"
              >
                {{ bandar.keterangan }}
              </option>
            </select2>
          </div>
        </div>
      </section>

      <section>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 5 : 4 }}.&#9;Alamat Perniagaan</label
          >
          <div class="col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.alamat_perniagaan1"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="
                form.alamat_perniagaan1 = $event.target.value.toUpperCase()
              "
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="offset-md-4 col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.alamat_perniagaan2"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="
                form.alamat_perniagaan2 = $event.target.value.toUpperCase()
              "
            />
          </div>
        </div>
        <div class="form-group row">
          <div class="offset-md-4 col-md-8">
            <input
              type="text"
              class="form-control"
              :value="form.alamat_perniagaan3"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="
                form.alamat_perniagaan3 = $event.target.value.toUpperCase()
              "
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >&#9;Poskod & Negeri</label
          >
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              :value="form.poskod_perniagaan"
              :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              @input="
                form.poskod_perniagaan = $event.target.value.toUpperCase()
              "
            />
          </div>
          <div class="col-md-4">
            <input
              v-if="(pemaju.kod_kategori_pemaju_id === 1 || readonly) && form.kod_negeri_perniagaan_id"
              type="text"
              class="form-control"
              :value="getNegeri(form.kod_negeri_perniagaan_id)"
              disabled
            />
            <select
              v-else
              name="kod_negeri_perniagaan_id"
              class="form-control"
              v-model="form.kod_negeri_perniagaan_id"
              @change="form.kod_daerah_perniagaan_id = null"
            >
              <option :value="null">Sila Pilih Negeri</option>
              <option v-for="item in kodNegeriRs" :value="item.id">
                {{ item.keterangan }}
              </option>
            </select>
          </div>
        </div>
        <div class="form-group row" style="display: none;">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >&#9;Daerah <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <select2
              class="form-control"
              v-model="form.kod_daerah_perniagaan_id"
              @change="form.kod_bandar_perniagaan_id = null"
              :disabled="readonly"
            >
              <option value="">Sila Pilih Daerah</option>
              <option
                v-for="daerah in filteredDaerahRs(
                  form.kod_negeri_perniagaan_id
                )"
                :value="daerah.id"
                :key="`daerah_perniagaan_${daerah.id}`"
              >
                {{ daerah.keterangan }}
              </option>
            </select2>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >&#9;Bandar <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <select2
              class="form-control"
              v-model="form.kod_bandar_perniagaan_id"
              :disabled="true"
            >
              <option value="">Sila Pilih Bandar</option>
              <option
                v-for="bandar in kodBandarRs"
                :value="bandar.id"
                :key="`bandar_perniagaan_${bandar.id}`"
              >
                {{ bandar.keterangan }}
              </option>
            </select2>
          </div>
        </div>
      </section>

      <hr />

      <section>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 6 : 5 }}.&#9;Tarikh Tubuh <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <input
              v-if="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              type="text"
              class="form-control"
              v-model="form.tarikh_tubuh"
              disabled
            />
            <datepicker v-else v-model="form.tarikh_tubuh"></datepicker>
          </div>
        </div>
      </section>

      <section>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 7 : 6 }}.&#9;No Telefon Pejabat (1) <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <input
              type="text"
              name="telefon"
              class="form-control"
              :class="{ 'is-invalid': errors.first('telefon') }"
              v-model="form.telefon"
              v-validate="'required|numeric|length:9,11'"
              maxlength="12"
              :disabled="readonly"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 8 : 7 }}.&#9;No Telefon Pejabat (2)
            / No Telefon Bimbit</label
          >
          <div class="col-md-4">
            <input
              type="text"
              name="telefon2"
              class="form-control"
              :class="{ 'is-invalid': errors.first('telefon2') }"
              v-model="form.telefon2"
              v-validate="'numeric|length:9,12'"
              maxlength="12"
              :disabled="readonly"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 9 : 8 }}.&#9;Emel <span class="label-required">*</span></label
          >
          <div class="col-md-4">
            <input
              type="text"
              name="emel"
              class="form-control"
              :class="{ 'is-invalid': errors.first('emel') }"
              v-model="form.emel"
              v-validate="'required|email'"
              :disabled="readonly"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 10 : 9 }}.&#9;Alamat Web</label
          >
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              v-model="form.alamat_web"
              :disabled="readonly"
            />
          </div>
        </div>
      </section>
      <hr />

      <section>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 11 : 10 }}.&#9;Maklumat Ahli Lembaga Pengarah <span class="label-required">*</span></label
          >
        </div>
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="table-responsive" style="width: 97%; margin: 0px auto">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Nama</th>
                    <th scope="col" nowrap="nowrap">No. KP/Passport</th>
                    <th scope="col">Jawatan</th>
                    <th scope="col">Tarikh Lantikan</th>
                    <th scope="col">Tarikh Berhenti</th>
                    <th scope="col" nowrap="nowrap">Senarai Hitam</th>
                    <th scope="col">
                      Tindakan <br />
                      <button
                        v-if="pemaju.kod_kategori_pemaju_id !== 1 && !readonly"
                        type="button"
                        class="btn btn-success btn-sm"
                        @click="addPengarah"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </th>
                  </tr>
                  <template v-if="maklumat.pengarah_rs.length">
                    <tr v-for="(pengarah, index) in maklumat.pengarah_rs">
                      <td>{{ index + 1 }}</td>
                      <td
                        class="text-left"
                        :class="{ 'text-strike': pengarah.action === 'H' }"
                      >
                        {{ pengarah.nama }}
                      </td>
                      <td :class="{ 'text-strike': pengarah.action === 'H' }">
                        {{ pengarah.no_kp }}
                      </td>
                      <td :class="{ 'text-strike': pengarah.action === 'H' }">
                        {{ pengarah.jawatan }}
                      </td>
                      <td :class="{ 'text-strike': pengarah.action === 'H' }">
                        {{ pengarah.tarikh_lantik }}
                      </td>
                      <td :class="{ 'text-strike': pengarah.action === 'H' }">
                        {{ pengarah.tarikh_berhenti }}
                      </td>
                      <td :class="{ 'text-strike': pengarah.action === 'H' }">
                        {{
                          pengarah.status_senaraihitam === "Y" ? "YA" : "TIDAK"
                        }}
                      </td>
                      <td nowrap>
                        <button
                          v-if="
                            pemaju.kod_kategori_pemaju_id !== 1 &&
                            !readonly &&
                            pengarah.action !== 'H'
                          "
                          type="button"
                          class="btn btn-link"
                          @click.prevent="hapusPengarah(pengarah)"
                          title="Hapus"
                        >
                          <i class="fa fa-trash text-danger"></i>
                        </button>
                        <button
                          v-if="
                            pemaju.kod_kategori_pemaju_id !== 1 &&
                            !readonly &&
                            pengarah.action === 'H'
                          "
                          type="button"
                          class="btn btn-link"
                          @click.prevent="hapusPengarah(pengarah)"
                          title="Memulihkan"
                        >
                          <i class="fa fa-check-circle text-success"></i>
                        </button>
                        <button
                          type="button"
                          v-if="pengarah.action !== 'H'"
                          class="btn btn-link"
                          @click.prevent="showPengarah(pengarah)"
                        >
                          <i class="fa fa-eye"></i>
                        </button>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="99">
                      <div class="alert alert-warning">Tiada data</div>
                    </td>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>

        <div class="form-group row" v-if="pemaju.kod_kategori_pemaju_id !== 1">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 6 ? 12 : 11 }}.&#9;Carta
            Organisasi ALP (dengan pengesahan SKM)</label
          >
          <div class="col-md-8">
            <file-upload
              type="file"
              accept="application/pdf"
              name="doc_carta_organisasi"
              class="form-control w-100"
              :class="{ 'is-invalid': errors.first(`doc_carta_organisasi`) }"
              :doc="maklumat.doc_carta_organisasi"
              @uploadwithcallback="
                ({ file, callback }) =>
                  uploadDoc('doc_carta_organisasi', file, callback)
              "
              @remove="removeDoc('doc_carta_organisasi')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">
              Hanya dokumen berformat PDF sahaja dibenarkan
            </div>
          </div>
        </div>
      </section>

      <hr />
      <section>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 1 ? 13 : 12 }}.&#9;Taraf Pemaju <span class="label-required">*</span></label
          >
          <div class="col-md-8 col-form-label">
            <div
              class="form-check form-check-inline"
              v-for="taraf in kodTarafPemajuRs"
              :key="`taraf_pemaju_${taraf.id}`"
            >
              <input
                :id="`taraf-pemaju-${taraf.id}`"
                type="radio"
                name="kod_taraf_pemaju_id"
                class="form-check-input"
                :class="{ 'is-invalid': errors.first('kod_taraf_pemaju_id') }"
                v-model="form.kod_taraf_pemaju_id"
                :value="taraf.id"
                v-validate="'required'"
                :disabled="readonly"
              />
              <label
                class="form-check-label"
                :for="`taraf-pemaju-${taraf.id}`"
                >{{ taraf.keterangan }}</label
              >
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 1 ? 14 : 13 }}.&#9;Amaun Modal <span class="label-required">*</span></label
          >
        </div>

        <div class="ml-4">
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >a.&#9;Modal Dibenarkan</label
            >
            <div class="col-md-4">
              <money
                type="text"
                class="form-control"
                v-model="form.amaun_modal_dibenar"
                :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              ></money>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >b.&#9;Modal Terbitan</label
            >
            <div class="col-md-4">
              <money
                type="text"
                class="form-control"
                v-model="form.amaun_modal_diterbit"
                :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              ></money>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >c.&#9;Modal Berbayar Tunai</label
            >
            <div class="col-md-4">
              <money
                type="text"
                class="form-control"
                v-model="form.amaun_modal_tunai"
                :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              ></money>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >d.&#9;Modal Berbayar Bukan Tunai</label
            >
            <div class="col-md-4">
              <money
                type="text"
                class="form-control"
                v-model="form.amaun_modal_bukantunai"
                :disabled="pemaju.kod_kategori_pemaju_id === 1 || readonly"
              ></money>
            </div>
          </div>
          <div class="form-group row" v-if="isModalBerbayarInvalid">
            <div class="col">
              <p class="form-control-plaintext text-danger">
                Sila pastikan modal berbayar lebih dari RM250K
              </p>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 1 ? 15 : 14 }}.&#9;Jenis Pemaju <span class="label-required">*</span></label
          >
          <div class="col-md-8 col-form-label">
            <div
              class="form-check form-check-inline"
              v-for="jenis in kodJenisPemajuRs"
              :key="`jenis_pemaju_${jenis.id}`"
            >
              <input
                :id="`jenis-pemaju-${jenis.id}`"
                type="radio"
                name="kod_jenis_pemaju_id"
                class="form-check-input"
                :class="{ 'is-invalid': errors.first('kod_jenis_pemaju_id') }"
                v-model="form.kod_jenis_pemaju_id"
                :value="jenis.id"
                v-validate="'required'"
                :disabled="readonly"
              />
              <label
                class="form-check-label"
                :for="`jenis-pemaju-${jenis.id}`"
                >{{ jenis.keterangan }}</label
              >
            </div>
          </div>
        </div>

        <div class="ml-4">
          <div class="form-group">
            Jika Subsidiari atau Bersekutu, sila isikan butiran syarikat Induk:
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >i.&#9;Nama Syarikat Induk</label
            >
            <div class="col-md-8">
              <input
                type="text"
                class="form-control"
                v-model="form.nama_pemaju_induk"
                :disabled="[2, 3].indexOf(form.kod_jenis_pemaju_id) === -1"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap"
              >ii.&#9;No Pendaftaran SSM</label
            >
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                v-model="form.nossm_pemaju_induk"
                :disabled="[2, 3].indexOf(form.kod_jenis_pemaju_id) === -1"
              />
            </div>
          </div>
        </div>
      </section>

      <hr />

      <section>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 1 ? 16 : 15 }}.&#9;Status Syarikat</label
          >
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              :value="
                form.status_syarikat === 'WINDING UP'
                  ? 'DIGULUNG'
                  : 'TIDAK DIGULUNG'
              "
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 1 ? 17 : 16 }}.&#9;Status Senaraihitam</label
          >
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              :value="
                form.status_senaraihitam === 'Y'
                  ? 'DISENARAI HITAM'
                  : 'TIDAK DISENARAI HITAM'
              "
              disabled
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap"
            >{{ pemaju.kod_kategori_pemaju_id !== 1 ? 18 : 17 }}.&#9;Kategori Pemaju</label
          >
          <div class="col-md-4">
            <input
              type="text"
              class="form-control"
              :value="
                pemaju.kategori_pemaju
                  ? pemaju.kategori_pemaju.keterangan
                  : 'TIADA MAKLUMAT'
              "
              disabled
            />
          </div>
        </div>
      </section>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"
          ><span class="text-success mr-2" v-if="succesfulMessage">{{
            succesfulMessage
          }}</span></transition
        >
        <button
          class="btn btn-primary"
          @click.prevent="save"
          :disabled="loading || syncing"
        >
          Simpan
        </button>
      </div>
    </div>

    <pengarah-modal
      :readonly="pemaju.kod_kategori_pemaju_id === 1 || readonly"
      :kod-negeri-rs="kodNegeriRs"
    ></pengarah-modal>
  </div>
</template>

<script>
export default {
  name: "BahagianA",
  props: {
    pemaju: Object,

    maklumat: Object,

    kodTarafPemajuRs: Array,
    kodJenisPemajuRs: Array,
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      syncing: false,
      syncSuccessful: false,

      form: {
        nama: this.maklumat.nama,
        no_ssm: this.maklumat.no_ssm,
        no_ssm_baru: this.maklumat.no_ssm_baru || "-",
        tarikh_tubuh: this.maklumat.tarikh_tubuh,

        alamat_daftar1: this.maklumat.alamat_daftar1,
        alamat_daftar2: this.maklumat.alamat_daftar2,
        alamat_daftar3: this.maklumat.alamat_daftar3,
        poskod_daftar: this.maklumat.poskod_daftar,
        kod_negeri_daftar_id: this.maklumat.kod_negeri_daftar_id,
        kod_daerah_daftar_id: this.maklumat.kod_daerah_daftar_id,
        kod_bandar_daftar_id: this.maklumat.kod_bandar_daftar_id,

        alamat_perniagaan1: this.maklumat.alamat_perniagaan1,
        alamat_perniagaan2: this.maklumat.alamat_perniagaan2,
        alamat_perniagaan3: this.maklumat.alamat_perniagaan3,
        poskod_perniagaan: this.maklumat.poskod_perniagaan,
        kod_negeri_perniagaan_id: this.maklumat.kod_negeri_perniagaan_id,
        kod_daerah_perniagaan_id: this.maklumat.kod_daerah_perniagaan_id,
        kod_bandar_perniagaan_id: this.maklumat.kod_bandar_perniagaan_id,

        telefon: this.maklumat.telefon,
        telefon2: this.maklumat.telefon2,
        fax: this.maklumat.fax,
        emel: this.maklumat.emel,
        alamat_web: this.maklumat.alamat_web,

        kod_taraf_pemaju_id: this.maklumat.kod_taraf_pemaju_id,
        amaun_modal_dibenar: this.maklumat.amaun_modal_dibenar || 0.0,
        amaun_modal_diterbit: this.maklumat.amaun_modal_diterbit || 0.0,
        amaun_modal_tunai: this.maklumat.amaun_modal_tunai || 0.0,
        amaun_modal_bukantunai: this.maklumat.amaun_modal_bukantunai || 0.0,
        kod_jenis_pemaju_id: this.maklumat.kod_jenis_pemaju_id,
        nama_pemaju_induk: this.maklumat.nama_pemaju_induk,
        nossm_pemaju_induk: this.maklumat.nossm_pemaju_induk,
        kod_kategori_pemaju_id: this.maklumat.kod_kategori_pemaju_id,
      },
    };
  },

  methods: {
    showPengarah(pengarah) {
      vmodal.open(
        "pengarah",
        {
          pengarah,
        },
        (form, setLoading, close) => {
          setLoading(true);

          axios
            .post(
              `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/pengarah/${pengarah.id}`,
              form
            )
            .then((response) => {
              this.form = JSON.parse(JSON.stringify(response.data));
              this.$emit("update", response.data);
              this.$success();
              close();
            })
            .catch((error) => {})
            .then(() => {
              setLoading(false);
            });
        }
      );
    },

    addPengarah() {
      vmodal.open(
        "pengarah",
        {
          pengarah: {
            negeri_bod: null,
          },
        },
        (form, setLoading, close) => {
          setLoading(true);

          axios
            .post(
              `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/pengarah`,
              form
            )
            .then((response) => {
              this.form = JSON.parse(JSON.stringify(response.data));
              this.$emit("update", response.data);
              this.$success();
              close();
            })
            .catch((error) => {})
            .then(() => {
              setLoading(false);
            });
        }
      );
    },

    hapusPengarah(pengarah) {
      swalconfirm().then((result) => {
        if (result.value) {
          this.loading = true;

          axios
            .delete(
              `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/pengarah/${pengarah.id}`
            )
            .then((response) => {
              this.form = JSON.parse(JSON.stringify(response.data));
              this.$emit("update", response.data);
              this.$success();
            })
            .catch((error) => {})
            .then(() => {
              this.loading = false;
            });
        }
      });
    },

    getNegeri(kod_negeri_id) {
      const negeri = this.kodNegeriRs.find((item) => item.id == kod_negeri_id);

      return negeri ? negeri.keterangan : null;
    },
    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return this.kodDaerahRs;
      }

      return this.kodDaerahRs.filter(
        (item) => item.kod_negeri_id == kod_negeri_id
      );
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return this.kodBandarRs;
      }

      return this.kodBandarRs.filter(
        (item) => item.kod_daerah_id === kod_daerah_id
      );
    },

    async save() {
      if (this.loading || this.syncing) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.loading = true;

      axios
        .post(
          `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/bahagian-a`,
          this.form
        )
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit("update", response.data);
          this.$success();
        })
        .catch((error) => {})
        .then(() => {
          this.loading = false;
        });
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append("doc", file, file.name);

      // save it
      axios
        .post(
          `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`,
          formData
        )
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit("update", maklumat);
          this.$success(
            `Berjaya muat naik dokumen. (${this.$options.filters.filesize(
              file.size
            )})`
          );
        })
        .catch((error) => {
          this.$hError(error);
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios
        .delete(
          `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`
        )
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit("update", maklumat);
        })
        .catch(() => {});
    },

    syncFromSSM() {
      if (this.syncing) {
        return;
      }
      this.syncing = true;
      axios
        .post(
          `/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/bahagian-a/update-from-ssm`
        )
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit("update", response.data);
          this.syncSuccessful = true;
        })
        .catch((error) => {})
        .then(() => {
          this.syncing = false;
        });
    },
  },

  computed: {
    isModalBerbayarInvalid() {
      if (
        this.readonly ||
        !this.form.amaun_modal_tunai ||
        !this.form.amaun_modal_bukantunai
      )
        return false;

      return (
        parseFloat(this.form.amaun_modal_tunai.toString()) +
          parseFloat(this.form.amaun_modal_bukantunai.toString()) <
        250000
      );
    },
  },

  watch: {
    syncSuccessful: function (value) {
      if (this.syncSuccessful) {
        window.setTimeout(() => {
          this.syncSuccessful = false;
        }, 3000);
      }
    },
  },
  mounted() {
    // Initialize the Bootstrap tooltip
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });
  },
};
</script>

<style scoped></style>
