<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Memiliki Jadual Petak? <span class="label-required">*</span></label>
        <div class="col-md-8 col-form-label">
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" v-model="form.jadual_petak" name="jadual_petak_1"
                   @change="save"
                   :id="`jadual_petak_1`" :value="1" :disabled="readonly">
            <label class="form-check-label" :for="`jadual_petak_1`">Ada</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" class="form-check-input" v-model="form.jadual_petak" name="jadual_petak_0"
                   @change="save"
                   :id="`jadual_petak_0`" :value="0" :disabled="readonly">
            <label class="form-check-label" :for="`jadual_petak_0`">Tiada</label>
          </div>
        </div>
      </div>

      <!-- <div class="form-group row">
        <label for="" class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Memiliki Jadual Petak? <span class="label-required">*</span></label>
        <div class="col-md-8">
          <div class="form-check form-check-inline">
            <input id="jadual_petak_1" type="radio" name="jadual_petak_1" value="1" v-model="form.jadual_petak" class="form-check-input"
            @change="save">
            <label for="jadual_petak_1" class="form-check-label">Ada</label>
          </div>
          <div class="form-check form-check-inline">
            <input id="jadual_petak_0" type="radio" name="jadual_petak_0" value="0" v-model="form.jadual_petak" class="form-check-input"
            @change="save">
            <label for="jadual_petak_0" class="form-check-label">Tiada</label>
          </div>
        </div>
      </div> -->

    <section v-if="form.jadual_petak == 1">
      <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;No. Skim <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.no_skim" @input="form.no_skim = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
      </div>
      <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;No. Jadual Petak <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.no_jadual_petak" @input="form.no_jadual_petak = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
      </div>
      <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Tarikh Kelulusan<span class="label-required">*</span></label>
          <div class="col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('jp_tarikh_kelulusan')}"
              name="jp_tarikh_kelulusan"
              v-model="form.jp_tarikh_kelulusan"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
      </div>
      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Salinan Jadual Petak <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="jp_doc_rujukan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`jp_doc_rujukan`)}"
              :doc="maklumat.jp_doc_rujukan"
              @uploadwithcallback="({file, callback}) => uploadDoc('jp_doc_rujukan', file, callback)"
              @remove="removeDoc('jp_doc_rujukan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
      </div>
    </section>

    <section  v-if="form.jadual_petak == 0">
      <div class="col-md-8 col-form-label">
        <label class="col-form-label" style="white-space: pre-wrap; color: red;">Permohonan ini tidak boleh diteruskan sehingga pemaju mendapat Salinan Jadual Petak.</label>
      </div>
      <!-- <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Surat Pengecualian Jadual Petak</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="jp_doc_pengecualian"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`jp_doc_pengecualian`)}"
              :doc="maklumat.jp_doc_pengecualian"
              @uploadwithcallback="({file, callback}) => uploadDoc('jp_doc_pengecualian', file, callback)"
              @remove="removeDoc('jp_doc_pengecualian')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
      </div> -->
    </section>
          <!-- <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Jadual Petak</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="jp_doc_rujukan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`jp_doc_rujukan`)}"
              :doc="maklumat.jp_doc_rujukan"
              @uploadwithcallback="({file, callback}) => uploadDoc('jp_doc_rujukan', file, callback)"
              @remove="removeDoc('jp_doc_rujukan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div> -->
      </div>

      


    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianP",
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
      form: this.parse(this.maklumat),
    }
  },

  methods: {
    
    parse(maklumat) {
      return {
        jadual_petak: maklumat.jadual_petak,
        no_skim: maklumat.no_skim,
        no_jadual_petak: maklumat.no_jadual_petak,
        jp_tarikh_kelulusan: maklumat.jp_tarikh_kelulusan,

      };
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-p`, this.form)
        .then((response) => {
          this.form = this.parse(JSON.parse(JSON.stringify(response.data)));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  },

  computed: {

  }
}

</script>

<style scoped>

</style>
