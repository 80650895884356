<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>
      <div>
        <div class="form-group row"><label class="col-form-label col-md-4" style="white-space: pre-wrap;">Tarikh Kewangan Berakhir</label>
          <div class="col-sm-12 col-md-8">
            <datepicker
              :class="{'is-invalid': errors.first('tarikh_kewangan_berakhir')}"
              name="tarikh_kewangan_berakhir"
              v-model="form.tarikh_kewangan_berakhir"
              @changed="save"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>
      </div>
      <div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">Laporan Kewangan Tahunan</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="laporan_kewangan_tahunan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`laporan_kewangan_tahunan`)}"
              :doc="maklumat.laporan_kewangan_tahunan"
              @uploadwithcallback="({file, callback}) => uploadDoc('laporan_kewangan_tahunan', file, callback)"
              @remove="removeDoc('laporan_kewangan_tahunan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>
      </div>
      <div v-if="pemaju.kod_kategori_pemaju_id === 1">
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">Laporan Kredit Syarikat</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="laporan_ctos"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`laporan_ctos`)}"
              :doc="maklumat.laporan_ctos"
              @uploadwithcallback="({file, callback}) => uploadDoc('laporan_ctos', file, callback)"
              @remove="removeDoc('laporan_ctos')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianB",
  props: {
    maklumat: Object,

    pemaju: {
      type: Object,
      default: () => ({}),
    },

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,

      form: {
        tarikh_kewangan_berakhir: this.maklumat.tarikh_kewangan_berakhir,
      },
    }
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/setup/bahagian-b`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/setup/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/setup/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  }
}
</script>

<style scoped>

</style>
