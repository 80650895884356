<template>
  <tr>
    <td class="pr-1">
      <select
        v-model="form.sumber_biaya_id"
        name="sumber_biaya"
        class="form-control"
        :class="{ 'is-invalid': errors.first('sumber_biaya') }"
        v-validate="'required'"
        data-vv-as="Sumber Biaya"
      >
        <option :value="null">Sila Pilih</option>
        <option
          v-for="sumber_biaya in sumberBiayaRs"
          :value="sumber_biaya.id"
        >{{ sumber_biaya.keterangan }}</option>
      </select>
    </td>
    <td class="px-1">
      <money type="text" class="form-control" name="amaun" :class="{'is-invalid': errors.first('amaun')}" v-model="form.amaun" v-validate="'required|decimal:2'"></money>
    </td>
    <td class="px-1">
      <select
        v-model="form.bentuk_biaya_id"
        name="bentuk_pembiayaan"
        class="form-control"
        :class="{ 'is-invalid': errors.first('bentuk_pembiayaan') }"
        v-validate="'required'"
        data-vv-as="Bentuk Pembiayaan"
      >
        <option :value="null">Sila Pilih</option>
        <option
          v-for="bentuk_biaya in bentukBiayaRs"
          :value="bentuk_biaya.id"
        >{{ bentuk_biaya.keterangan }}</option>
      </select>
    </td>
    <td class="px-1">
      <input
        type="text"
        :value="form.nama_pembiaya"
        @input="ev => form.nama_pembiaya = ev.target.value.toUpperCase()"
        name="nama"
        class="form-control"
        :class="{ 'is-invalid': errors.first('nama') }"
        v-validate="'required'"
        data-vv-as="Nama"
      />
    </td>
    <td class="px-1">
      <textarea
        :value="form.alamat_pembiaya"
        @input="ev => form.alamat_pembiaya = ev.target.value.toUpperCase()"
        name="alamat"
        class="form-control"
        :class="{ 'is-invalid': errors.first('alamat') }"
        v-validate="'required'"
        data-vv-as="Nama"
      ></textarea>
    </td>
    <td class="pl-1">
      <button tabindex="-1" class="btn btn-success" @click.prevent="add" type="button">TAMBAH</button>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    sumberBiayaRs: {
      type: Array,
      default: []
    },
    bentukBiayaRs: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      form: this.createDefault()
    };
  },

  methods: {
    createDefault() {
      return JSON.parse(JSON.stringify({
        sumber_biaya_id: null,
        amaun: 0,
        bentuk_biaya_id: null,
        nama_pembiaya: null,
        alamat_pembiaya: null
      }));
    },
    add() {
      this.$validator.validateAll().then(passes => {
        if (passes) {
          this.$emit('added', this.form);
          this.form = this.createDefault();
          this.$validator.reset();
        }
      });
    }
  }
};
</script>
