<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">CADANGAN PEMBANGUNAN</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Jenis Hakmilik</label>
              <div class="col-md-4">
                <select
                  class="form-control" :class="{'is-invalid': errors.first('kod_jenis_hakmilik_id')}"
                  name="kod_jenis_hakmilik_id"
                  v-model="form.kod_jenis_hakmilik_id"
                  @change="form.kod_jenis_rumah_id = null"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih</option>
                  <option v-for="item in kodJenisHakmilikRs" :value="item.id">{{ item.keterangan }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Jenis Kediaman</label>
              <div class="col-md-4">
                <select
                  class="form-control" :class="{'is-invalid': errors.first('kod_jenis_rumah_id')}"
                  name="kod_jenis_rumah_id"
                  v-model="form.kod_jenis_rumah_id"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih</option>
                  <option v-for="item in filteredJenisRumahRs(form.kod_jenis_hakmilik_id)" :value="item.id">{{ item.keterangan }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Bilangan Unit Yang Dipohon</label>
              <div class="col-md-4">
                <input type="text" class="form-control" disabled value="0">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Bilangan Tingkat</label>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control" :class="{'is-invalid': errors.first('bil_tingkat')}"
                  name="bil_tingkat"
                  v-model="form.bil_tingkat"
                  v-validate="'required|decimal:1|min_value:1'"
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Harga Minima (RM)</label>
              <div class="col-md-4">
                <input type="text" class="form-control" disabled value="0.00">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Harga Maksima (RM)</label>
              <div class="col-md-4">
                <input type="text" class="form-control" disabled value="0.00">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Harga Jualan (RM)</label>
              <div class="col-md-4">
                <input type="text" class="form-control" disabled value="0.00">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Jenis Perjanjian</label>
              <div class="col-md-4">
                <select
                  class="form-control" :class="{'is-invalid': errors.first('kod_jenis_perjanjian_id')}"
                  name="kod_jenis_perjanjian_id"
                  v-model="form.kod_jenis_perjanjian_id"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih</option>
                  <option v-for="item in kodJenisPerjanjianRs" :value="item.id">{{ item.keterangan }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "TambahButiranBangunanModal",
    mounted() {
      vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
    },

    props: {
      kodJenisKediamanRs: Array,
      kodJenisPerjanjianRs: Array,
      kodJenisHakmilikRs: Array,

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        modalId: 'tambahbutiranbangunan',
        loading: false,
        saving: false,
        form: {},

        response: () => {
        },
      }
    },

    methods: {
      onOpen({ bil_tingkat = 0, kod_jenis_rumah_id = null, kod_jenis_hakmilik_id = null, kod_jenis_perjanjian_id = null }, callback) {
        this.form = {
          bil_tingkat,
          kod_jenis_rumah_id,
          kod_jenis_hakmilik_id,
          kod_jenis_perjanjian_id,
        };

        this.response = callback;
      },

      onClose() {
      },

      close() {
        vmodal.close(this.modalId);
      },

      async save() {
        const passes = await this.$validator.validateAll();

        if (!passes) {
          return;
        }

        this.response(JSON.parse(JSON.stringify(this.form)));
        this.close();
      },

      filteredJenisRumahRs(kod_jenis_hakmilik) {
        if (!kod_jenis_hakmilik) {
          return [];
        }

        const jenis_hakmilik = this.kodJenisHakmilikRs.find(item => item.id == kod_jenis_hakmilik);
        return jenis_hakmilik.jenis_rumah_rs;
      }
    },

    computed: {
      isOpen() {
        return this.$root.openedModals.indexOf(this.modalId) !== -1
      },
    }
  }
</script>

<style scoped>

</style>
