<template>
  <div class="form-group row">
    <div class="col-3">
      <datepicker :class="{'is-invalid': errors.first('tarikh')}" name="tarikh" v-model="form.tarikh" v-validate="'required'" data-vv-as="Tarikh"></datepicker>
      <span class="invalid-feedback" v-if="errors.first('tarikh')">{{ errors.first('tarikh') }}</span>
    </div>
    <div class="col-2">
      <input :class="{'is-invalid': errors.first('bil_unit')}" class="form-control" name="bil_unit" @keypress.enter.prevent v-model="form.bil_unit" v-validate="'required'" data-vv-as="Bil Unit">
      <span class="invalid-feedback" v-if="errors.first('bil_unit')">{{ errors.first('bil_unit') }}</span>
    </div>
    <div class="col-6">
      <input-file-new :class="{'is-invalid': errors.first('dokumen')}" class="form-control w-100" name="dokumen" v-model="form.dokumen" v-validate="'required'" data-vv-as="Dokumen"></input-file-new>
      <span class="invalid-feedback" v-if="errors.first('dokumen')">{{ errors.first('dokumen') }}</span>
    </div>
    <div class="col-1">
      <button class="btn btn-sm btn-success" type="button" @click.prevent="add"><i class="fas fa-plus"></i></button>
    </div>
  </div>
</template>

<script>
import { isAfter, parse } from 'date-fns';
export default {
  name: "TambahButiranSiapBerperingkat",
  props: {
    jenisSiap: {
      type: String,
    },

    maxTarikh: {
      type: String,
      default: null,
    },

    currentSiap: {
      type: Number,
    },

    bilDilulus: {
      type: Number,
    },

    bilDimaju: {
      type: Number,
    },

    format: {
      type: String,
      default: () => 'dd/MM/yyyy'
    }
  },
  data() {
    return {
      form: {
        tarikh: null,
        bil_unit: null,
        dokumen: null,
      }
    };
  },

  methods: {
    validate() {
      let res = true;
      if (this.maxDate && isAfter(parse(this.form.tarikh, this.format, Date.now()), this.maxDate)) {
        this.$validator.errors.add({ field: 'tarikh', msg: `Tarikh tidak boleh melibihi tarikh ${this.jenisSiap}`});

        if (res) {
          res = false;
        }
      }

      if ((this.currentSiap + parseInt(this.form.bil_unit)) > this.bilDimaju) {
        this.$validator.errors.add({ field: 'bil_unit', msg: 'Jumlah bil unit melebihi bil dimaju.'});

        if (res) {
          res = false;
        }
      }
      else if ((this.currentSiap + parseInt(this.form.bil_unit)) > this.bilDilulus) {
        this.$validator.errors.add({ field: 'bil_unit', msg: 'Jumlah bil unit melebihi bil dilulus.'});

        if (res) {
          res = false;
        }
      }

      return res;
    },

    async add() {
      const passes = await this.$validator.validateAll();

      if (passes && this.validate()) {
        this.$emit('add', {
          form: this.form,
          callback: this.clear,
        })
      }
    },

    clear() {
      this.form = {
        tarikh: null,
        bil_unit: null,
        dokumen: null,
      };

      this.$validator.reset();
    }
  },
  computed: {
    maxDate() {
      if (this.maxTarikh) {
        return parse(this.maxTarikh, this.format, Date.now());
      }

      return null;
    }
  }
}
</script>

<style scoped>

</style>
