<template>
  <tr>
    <template>
      <td>{{ item.no_lot }}</td>
      <td>{{ item.no_unit }}</td>
      <td class="text-center">{{ item.tuan_tanah ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center">{{ item.kuota_bumi ? '&#x2714;' : '&#x2718;' }}</td>
      <td>{{ item.luas_binaan }}</td>
      <td>{{ item.luas_tanah }}</td>
      <td>{{ item.harga_jualan | currency('RM', ',', 2, '.', 'front', false) }}</td>
      <td><span v-if="item.kuota_bumi">{{ item.harga_diskaun_kuotabumi | currency('RM', ',', 2, '.', 'front', false) }}</span><span v-else>-</span></td>
      <td>{{ item.tuan_tanah ? 'TUAN TANAH' : (item.status_jualan ? 'TELAH DIJUAL' : 'BELUM DIJUAL') }}</td>

    </template>
  </tr>
</template>

<script>
  export default {
    name: "RowNolotUnit",
    props: {
      item: Object,
      peratusDiskaun: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        updating: false,
        saving: false,
        form: {},
      };
    },
    methods: {
      save() {
        this.saving = true;
        axios.post(`/pemaju/kuota-bumi/unit/${this.item.id}`, this.form)
        .then((response) => {
          this.$emit('update:item', response.data);
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
          this.updating = false;
        })
      },

      startUpdating() {
        this.form = JSON.parse(JSON.stringify(this.item));
        this.updating = true
      },

      updatePembeli() {
        vmodal.open('pembeli', this.item, (unit) => {
          this.$emit('update:item', unit);
        })
      },

      tuanTanahChange() {
        this.form.kuota_bumi = 0;
        this.form.harga_diskaun_kuotabumi = 0;
      },

      kuotaBumiChange() {
        if (this.form.kuota_bumi) {
          this.form.harga_diskaun_kuotabumi = this.getAfterDiscountPrice;
        }
      }
    },

    computed: {
      getAfterDiscountPrice() {
        return this.item.harga_jualan - (this.item.harga_jualan * (this.peratusDiskaun / 100));
      },
    },

  }
</script>
