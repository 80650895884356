<template>
  <section>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Latitud <span class="label-required">*</span></label>
          <input
            class="form-control"
            :class="{'is-invalid': errors.first('lat')}"
            v-model.number.lazy="form.lat"
            @change="syncMap"
            step="0.00001"
            aria-required="true"
            placeholder="Latitud"
          />
          <span class="invalid-feedback" v-if="errors.first('lat')">{{ errors.first('lat') }}</span>
          <input
            type="hidden"
            name="lat"
            v-model="form.lat"
            v-validate="'required|latitud'"
            data-vv-as="Latitud"
          />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Longitud <span class="label-required">*</span></label>
          <input
            class="form-control"
            :class="{'is-invalid': errors.first('lng')}"
            v-model.number.lazy="form.lng"
            @change="syncMap"
            step="0.00001"
            aria-required="true"
            placeholder="Longitud"
          />
          <span class="invalid-feedback" v-if="errors.first('lng')">{{ errors.first('lng') }}</span>
          <input
            type="hidden"
            name="lng"
            v-model="form.lng"
            v-validate="'required|longitud'"
            data-vv-as="Longitud"
          />
        </div>
      </div>
    </div>
    <div class="row mb-2" v-if="valid_coordinates">
      <div class="col">
        <GmapMap
          :center="center"
          :zoom="12"
          map-type-id="terrain"
          style="height: 300px"
          @center_changed="repositionCenter"
          @idle="syncMap"
        >
          <GmapMarker :position="markerCoords"></GmapMarker>
        </GmapMap>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  $inject: ['$validator'],

  props: {
    lat: {
      type: Number,
      default: 3.9001626796630258,
    },
    lng: {
      type: Number,
      default: 101.44522155215714,
    },
  },

  data() {
    return {
      form: {
          lat: this.lat,
          lng: this.lng,
      },
      center: {}
    };
  },
  created() {
    this.syncMap();
  },

  methods: {
    repositionCenter(coords) {
      this.form.lat = coords.lat();
      this.form.lng = coords.lng();

      this.$emit('update', {
        lat: this.form.lat,
        lng: this.form.lng,
      });
    },

    syncMap() {
      this.center = {
        lat: parseFloat(this.form.lat),
        lng: parseFloat(this.form.lng)
      };
    }
  },

  computed: {
    valid_coordinates() {
      return !!(
        this.form.lat &&
        this.form.lat > -90 &&
        this.form.lat < 90 &&
        this.form.lng &&
        this.form.lng > -180 &&
        this.form.lng < 180
      );
    },

    markerCoords() {
      return {
        lat: this.form.lat,
        lng: this.form.lng
      };
    },
  },

  watch: {
    'form': {
      deep: true,
      handler: function () {
        this.$emit('update', {
          lat: this.form.lat,
          lng: this.form.lng,
        });
      },
    },
  }
};
</script>
