<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">BUTIRAN KAVEAT</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">Jenis Kaveat <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select
                  class="form-control" :class="{ 'is-invalid': errors.first('kod_jenis_kaveat_id') }"
                  name="kod_jenis_kaveat_id" v-model="form.kod_jenis_kaveat_id"
                  v-validate="'required'"
                  :disabled="readonly || form.action === 'H'"
                >
                  <option :value="null">Sila Pilih</option>
                  <option v-for="kod_jenis_kaveat in kodJenisKaveatRs" :value="kod_jenis_kaveat.id">{{ kod_jenis_kaveat.keterangan }}</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" for="kaveat_nama">Nama Pengkaveat <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input id="kaveat_nama" type="text" class="form-control" :value="form.nama_pengkaveat" @input="form.nama_pengkaveat = $event.target.value.toUpperCase()"
                 :disabled="readonly || form.action === 'H'"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" for="kaveat_no">No Rujukan Kaveat <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input id="kaveat_no" type="text" class="form-control" :value="form.no_rujukan_kaveat" @input="form.no_rujukan_kaveat = $event.target.value.toUpperCase()"
                 :disabled="readonly || form.action === 'H'"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4">Tarikh Kaveat <span class="label-required">*</span></label>
              <div class="col-md-4">
                <datepicker v-model="form.tarikh_kaveat"
                  :disabled="readonly || form.action === 'H'"
                ></datepicker>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!readonly && form.action !== 'H'" type="button" class="btn btn-primary" @click.prevent="save">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
      <
    </div>
  </div>
</template>

<script>
  export default {
    name: "KaveatTanahModal",

    mounted() {
      vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
    },

    props: {
      kodJenisKaveatRs: Array,

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        modalId: 'kaveattanah',
        loading: false,
        saving: false,
        form: {},
        response: () => {
        },
      }
    },

    methods: {
      onOpen({ id = null, kod_jenis_kaveat_id = null, nama_pengkaveat = null, no_rujukan_kaveat = null, tarikh_kaveat = null, action = null}, callback) {
        this.form = {
          id,
          kod_jenis_kaveat_id,
          nama_pengkaveat,
          no_rujukan_kaveat,
          tarikh_kaveat,
          action,
        }
        this.response = callback;
      },

      onClose() {
      },

      close() {
        vmodal.close(this.modalId);
      },

      save() {
        this.response(JSON.parse(JSON.stringify(this.form)));
        this.close();
      },
    },

    computed: {
      isOpen() {
        return this.$root.openedModals.indexOf(this.modalId) !== -1
      },
    }
  }
</script>

<style scoped>

</style>
