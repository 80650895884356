<template>
  <div class="card">
    <div class="card-body form-compact">
      <p>1. Saya akui segala maklumat dan dokumen yang dikemukakan adalah benar.</p>
      <p>2. Saya memahami sekiranya maklumat dan dokumen yang dikemukakan tidak benar atau tidak lengkap, pihak Kementerian berhak menolak permohonan ini.</p>
      <br />
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Nama :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ pengarah.nama }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Jawatan :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ pengarah.jawatan }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Tarikh :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">09/12/2019</span>
        </div>
      </div>
      <div class="text-center" v-if="!readonly">
        <button class="btn btn-primary" @click="$emit('submit', $event)">HANTAR</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },

    pengarah: Object,
  }
}
</script>
