<template>
  <div>
    <div class="alert alert-warning" v-if="kuiri">
      <strong>Catatan Kuiri:</strong>
      <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Pilihan Tanah <span class="label-required">*</span></label>

          <div class="col-8">
            <select class="form-control" v-model="form.tanah_id" @change="chooseTanah" :disabled="readonly">
              <option :value="null">Sila Pilih Tanah</option>
              <option v-for="tanah in tanah_rs" :key="`option-tanah-${tanah.id}`" :value="tanah.id">{{ tanah.label }}</option>
            </select>
          </div>
        </div>
      </div>

    </div>

    <section class="mb-2">
      <template v-if="maklumat.tanah">
        <tanah-nav
          :kod-negeri-rs="kodNegeriRs"
          :kod-daerah-rs="kodDaerahRs"
          :kod-bandar-rs="kodBandarRs"
          :kod-bank-hq-rs="kodBankHqRs"
          :mahkamah-tinggi="mahkamahTinggi"
          :pengarah-rs="pengarahRs"
          :kod-poskod-rs="kodPoskodRs"
          :kod-poskod-bandar-rs="kodPoskodBandarRs"

          :kod-syarat-nyata-rs="kodSyaratNyataRs"
          :kod-status-tanah-rs="kodStatusTanahRs"
          :kod-rezab-tanah-rs="kodRezabTanahRs"
          :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
          :kod-bebanan-tanah-rs="kodBebananTanahRs"
          :kod-jenis-kaveat-rs="kodJenisKaveatRs"

          :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"

          :kod-jenis-tuan-tanah-rs="kodJenisTuanTanahRs"
          :kod-jenis-urusan-rs="kodJenisUrusanRs"
          :kod-pemilikan-pembeli-rs="kodPemilikanPembeliRs"

          :readonly="true"
        >
          <template #default="{ currentActive, setCurrent }">
            <tanah
              :no="1"
              :show="currentActive === 0"
              :tanah="maklumat.tanah"
              :maklumat="maklumat"
              :key="`tanah-${maklumat.tanah.id}`"

              @click="setCurrent(currentActive === 0 ? null : 0)"

              :readonly="true"

              :kod-syarat-nyata-rs="kodSyaratNyataRs"
              :kod-status-tanah-rs="kodStatusTanahRs"
              :kod-rezab-tanah-rs="kodRezabTanahRs"
              :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
              :kod-bebanan-tanah-rs="kodBebananTanahRs"
              :kod-jenis-kaveat-rs="kodJenisKaveatRs"
              :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"

              :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"

              :kod-poskod-rs="kodPoskodRs"
              :kod-poskod-bandar-rs="kodPoskodBandarRs"

              :kod-negeri-rs="kodNegeriRsFiltered"
              :kod-daerah-rs="kodDaerahRs"
              :kod-bandar-rs="kodBandarRs"
              :kod-bank-hq-rs="kodBankHqRs"
              :kod-parlimen-rs="kodParlimenRs"
              :kod-dun-rs="kodDunRs"
            >
              <template #default>
                <template v-if="maklumat.tanah.butiran_rs.length">
                  <butiran-tanah
                    v-for="(butiran, index) in maklumat.tanah.butiran_rs"
                    :no="index + 1"
                    :butiran="butiran"
                    :tanah="maklumat.tanah"
                    :key="`butiran-${butiran.id}`"
                    :has-checkbox="true"
                    :selected="!!form.butiran_rs.find(b => b.id === butiran.id)"

                    :checkbox-readonly="readonly || loading"
                    @select="selectButiran(butiran)"
                    @editButiran="editButiran(maklumat.tanah, butiran)"
                    @editTuanTanah="(tuan) => editTuanTanah(maklumat.tanah, butiran, tuan)"

                    :readonly="true"
                  ></butiran-tanah>
                </template>
                <div v-else class="alert alert-warning mb-0">Tiada maklumat butiran tanah.</div>
              </template>

            </tanah>
          </template>
        </tanah-nav>

        <!-- <div class="card mt-2">
          <div class="card-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Nama PBT <span class="label-required">*</span></label>
              <div class="col-12 col-sm-6 col-md-6">
                <select2 class="form-control" v-model="form.kod_pbt_id" :disabled="readonly">
                  <option value="">Sila Pilih</option>
                  <option v-for="pbt in filteredPbtRs(maklumat.tanah.kod_negeri_id)" :value="pbt.id" :key="pbt.id">{{ pbt.keterangan }}</option>
                </select2>
              </div>
            </div>
          </div>
        </div> -->


      </template>
      <div v-else-if="readonly" class="alert alert-warning">Tiada maklumat tanah.</div>
      <div v-else class="alert alert-warning">Sila pilih tanah.</div>
    </section>


    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>

</template>

<script>
import {jsonToFormData} from "../../../lib/utils";


export default {
  name: "BahagianC",
  components: {},

  props: {
    tanah_rs: Array,

    maklumat: Object,

    kodJenisHakmilikRs: Array,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodPbtRs: Array,
    kodBankHqRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,
    mahkamahTinggi: Array,
    pengarahRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,

    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,

    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
      form: this.parse(this.maklumat)
    }
  },

  methods: {
    parse(maklumat) {
      return {
        tanah_id: maklumat.tanah_id,
        // kod_pbt_id: maklumat.kod_pbt_id,
        butiran_rs: maklumat.butiran_rs,
      }
    },

    chooseTanah() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-b/tanah`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-b`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    selectButiran(butiran) {
      if (this.loading) {
        return;
      }

      const request = () => {
        this.loading = true;

        axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-b/butiran/${butiran.id}`)
          .then((response) => {
            this.form = JSON.parse(JSON.stringify(response.data));
            this.$emit('update', response.data);
            this.$success();
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      }

      if (this.maklumat.kod_jenis_perjanjian_id) {
        swal.fire({
          title: 'ANDA TELAH MENUKAR PILIHAN BUTIRAN TANAH ',
          text: 'Sila pilih semula Jenis Perjanjian pada Maklumat Pemajuan (Seksyen C)',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0069d9',
          cancelButtonColor: '#d90014',
          confirmButtonText: 'OK',
          cancelButtonText: 'Batal'
        }).then(confirm => {
          if (confirm.value) {
            return request();
          }
        })
        return;
      }

      request();
    },

    filteredPbtRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodPbtRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
  },

  computed: {
    kodNegeriRsFiltered() {
      /*
        12 - Sabah
        13 - Sarawak
        98 - Luar Negara
      */
      const excludeId = ['12', '13', '98'];
      return this.kodNegeriRs.filter(item => !excludeId.includes(item.id));
    }
  }
}
</script>

<style scoped>

</style>
