<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Surat Permohonan Pembatalan <span class="label-required">*</span></label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_surat_permohonan"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_surat_permohonan`)}"
            :doc="maklumat.doc_surat_permohonan"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_surat_permohonan', file, callback)"
            @remove="removeDoc('doc_surat_permohonan')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Dokumen DL Terkini <span class="label-required">*</span></label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_dl_terkini"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_dl_terkini`)}"
            :doc="maklumat.doc_dl_terkini"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_dl_terkini', file, callback)"
            @remove="removeDoc('doc_dl_terkini')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianE",
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/dl-penamatan-lesen/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/dl-penamatan-lesen/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },

    save() {
      this.$success();
    },
  },

  computed: {
    isModalBerbayarInvalid() {
      return (parseFloat(this.maklumat.amaun_modal_tunai.toString()) + parseFloat(this.maklumat.amaun_modal_bukantunai.toString())) < 250000;
    },
  },
}
</script>

<style scoped>

</style>
