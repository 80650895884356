<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">TARIKH & NO RUJUKAN DOKUMEN</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4">Tarikh</label>
              <div class="col-md-8">
                <datepicker v-model="form.tarikh_kelulusan"></datepicker>
              </div>
            </div>

            <div class="form-group row" v-if="docKey === 6">
              <label class="col-form-label col-md-4">Tarikh Luput</label>
              <div class="col-md-8">
                <datepicker v-model="form.tarikh_luput_kelulusan"></datepicker>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" for="no_rujukan">No. Rujukan</label>
              <div class="col-md-8">
                <input id="no_rujukan" type="text" class="form-control" :value="form.rujukan_kelulusan" @input="form.rujukan_kelulusan = $event.target.value.toUpperCase()">
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" for="dokumen_sokongan">Dokumen Sokongan</label>
              <div class="col-md-8">
                <file-upload
                  id="dokumen_sokongan" class="form-control w-100"
                  :doc="form.dokumen_rujukan"
                  @upload="upload"
                  @remove="remove"
                ></file-upload>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save" :disabled="saving">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "KemaskiniTarikhDokumenLesenModal",

    mounted() {
      vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
    },

    props: {
      readonly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        modalId: 'kemaskinitarikhdokumenlesen',
        loading: false,
        saving: false,
        docKey: null,
        maklumatId: null,
        form: {},
        response: () => {
        },
      }
    },

    methods: {

      upload(doc) {
        const formData = new FormData();

        // append the files to FormData
        formData.append('doc', doc, doc.name);

        // save it
        axios.post(`/pemaju/apdl-lesen-baru/${this.maklumatId}/bahagian-c2/${this.docKey}/dokumen`, formData)
          .then((response) => {
            this.form.dokumen_rujukan = response.data.dokumen_rujukan;
            this.response({ ...this.form })
          })
          .catch(() => {

          });
      },

      remove(doc) {
        if (!doc) return;

        axios.delete(`/pemaju/permohonan/lesen-baru/bahagian-c2/dokumen/${this.docKey}`)
          .then((response) => {
            this.form.dokumen_rujukan = null;
            this.response({ ...this.form })
          })
          .catch(() => {

          });
      },

      onOpen({ maklumatId, key, tarikh_kelulusan = null, rujukan_kelulusan = null, tarikh_luput_kelulusan = null, dokumen_rujukan = null }, callback) {
        this.maklumatId = maklumatId;
        this.docKey = key;

        this.form = {
          tarikh_kelulusan,
          tarikh_luput_kelulusan,
          rujukan_kelulusan,
          dokumen_rujukan,
        }
        this.response = callback;

        if (key !== 6) {
          this.form.tarikh_luput = null;
        }
      },

      onClose() {
      },

      close() {
        vmodal.close(this.modalId);
      },

      save() {
        this.saving = true;

        const {
          dokumen_rujukan,
          ...form
        } = this.form;

        axios.post(`/pemaju/apdl-lesen-baru/${this.maklumatId}/bahagian-c2/${this.docKey}`, form)
          .then((response) => {
            this.response({...this.form});
            this.close();
          })
          .catch((error) => {
            console.log(error);
          })
          .then(() => {
            this.saving = false;
          })
      },
    },

    computed: {
      isOpen() {
        return this.$root.openedModals.indexOf(this.modalId) !== -1
      },
    }
  }
</script>

<style scoped>

</style>
