<template>
  <main>
    <div class="card mb-2">
      <div class="card-body">
        <div class="alert alert-warning" v-if="kuiri">
          <strong>Catatan Kuiri:</strong>
          <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
        </div>
        <section class="form-compact row">
          <div class="col-lg-12">
            <h5 class="card-title">A. KOMPONEN HARGA BEBAS</h5>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">1.&#9;Kos Pembinaan <span class="label-required">*</span></label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <money type="text" class="form-control" name="kos_pembinaan_harga_bebas" :class="{'is-invalid': errors.first('kos_pembinaan_harga_bebas')}" v-model="form.kos_pembinaan_harga_bebas" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">2.&#9;Kos Konsultan <span class="label-required">*</span></label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <money type="text" class="form-control" name="kos_konsultan_harga_bebas" :class="{'is-invalid': errors.first('kos_konsultan_harga_bebas')}" v-model="form.kos_konsultan_harga_bebas" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">3.&#9;Kos Kewangan</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <money type="text" class="form-control" name="kos_kewangan_harga_bebas" :class="{'is-invalid': errors.first('kos_kewangan_harga_bebas')}" v-model="form.kos_kewangan_harga_bebas" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">4.&#9;Kos Tetap (Kos Overhead)<br><small>&#9;(Bayaran Gaji, Sewaan dan lain-lain)</small></label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <money type="text" class="form-control" name="kos_tetap_harga_bebas" :class="{'is-invalid': errors.first('kos_tetap_harga_bebas')}" v-model="form.kos_tetap_harga_bebas" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">5.&#9;Kos Pemasaran</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <money type="text" class="form-control" name="kos_pemasaran_harga_bebas" :class="{'is-invalid': errors.first('kos_pemasaran_harga_bebas')}" v-model="form.kos_pemasaran_harga_bebas" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">6.&#9;Kos Tanah</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <money type="text" class="form-control" name="kos_tanah_harga_bebas" :class="{'is-invalid': errors.first('kos_tanah_harga_bebas')}" v-model="form.kos_tanah_harga_bebas" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">7.&#9;Jumlah Kos</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_keseluruhan_harga_bebas.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row" v-if="form.kos_kewangan_harga_bebas <= 0">
              <label class="col-form-label col-md-6" style="white-space: pre-wrap;">8.&#9;Surat Pengesahan Pemaju mengenai Kos Kewangan <span class="label-required">*</span></label>
              <div class="col-md-6">
                <file-upload
                  type="file" accept="application/pdf"
                  name="doc_pengesahan_kos_kewangan_harga_bebas"
                  class="form-control w-100"
                  :doc="maklumat.kewangan ? maklumat.kewangan.doc_pengesahan_kos_kewangan_harga_bebas : null"
                  :class="{'is-invalid': errors.first(`doc_pengesahan_kos_kewangan_harga_bebas`)}"
                  @uploadwithcallback="({file, callback}) => uploadDoc('doc_pengesahan_kos_kewangan_harga_bebas', file, callback)"
                  @remove="removeDoc('doc_pengesahan_kos_kewangan_harga_bebas')"
                  :readonly="readonly"
                />
                <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-6" style="white-space: pre-wrap;">{{ form.kos_kewangan_harga_bebas <= 0 ? 9 : 8 }}.&#9;Nilai Jumlah Kos 3%</label>
              <div class="col-md-6">
                <p class="form-control-plaintext">{{ estimated_gdc_harga_bebas | currency }}</p>
              </div>
            </div>
            <div class="form-group row" v-if="kos_keseluruhan_harga_bebas > 0 && kos_keseluruhan_harga_mampumilik > 0">
              <label class="col-form-label col-md-6" style="white-space: pre-wrap;">{{ form.kos_kewangan_harga_bebas <= 0 ? 10 : 9 }}.&#9;GDV</label>
              <div class="col-md-6">
                <p class="form-control-plaintext">{{ getNilaiJualanBebas | currency }}</p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div class="card mb-2" v-if="maklumat.kod_skim_projek_id">
      <div class="card-body">
        <section class="form-compact row">
            <div class="col-lg-12">
              <h5 class="card-title">B. KOMPONEN HARGA MAMPU MILIK</h5>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">1.&#9;Kos Pembinaan <span class="label-required">*</span></label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <money type="text" class="form-control" name="kos_pembinaan_harga_mampumilik" :class="{'is-invalid': errors.first('kos_pembinaan_harga_mampumilik')}" v-model="form.kos_pembinaan_harga_mampumilik" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">2.&#9;Kos Konsultan <span class="label-required">*</span></label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <money type="text" class="form-control" name="kos_konsultan_harga_mampumilik" :class="{'is-invalid': errors.first('kos_konsultan_harga_mampumilik')}" v-model="form.kos_konsultan_harga_mampumilik" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">3.&#9;Kos Kewangan</label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <money type="text" class="form-control" name="kos_kewangan_harga_mampumilik" :class="{'is-invalid': errors.first('kos_kewangan_harga_mampumilik')}" v-model="form.kos_kewangan_harga_mampumilik" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">4.&#9;Kos Tetap (Kos Overhead)<br><small>&#9;(Bayaran Gaji, Sewaan dan lain-lain)</small></label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <money type="text" class="form-control" name="kos_tetap_harga_mampumilik" :class="{'is-invalid': errors.first('kos_tetap_harga_mampumilik')}" v-model="form.kos_tetap_harga_mampumilik" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">5.&#9;Kos Pemasaran</label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <money type="text" class="form-control" name="kos_pemasaran_harga_mampumilik" :class="{'is-invalid': errors.first('kos_pemasaran_harga_mampumilik')}" v-model="form.kos_pemasaran_harga_mampumilik" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">6.&#9;Kos Tanah</label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <money type="text" class="form-control" name="kos_tanah_harga_mampumilik" :class="{'is-invalid': errors.first('kos_tanah_harga_mampumilik')}" v-model="form.kos_tanah_harga_mampumilik" v-validate="'required|decimal:2'" :disabled="readonly"></money>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-6" style="white-space: pre-wrap;">7.&#9;Jumlah Kos</label>
                    <div class="col-md-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <input type="text" class="form-control" disabled :value="kos_keseluruhan_harga_mampumilik.toFixed(2) | currency('')">
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="form.kos_kewangan_harga_mampumilik <= 0">
                <label class="col-form-label col-md-6" style="white-space: pre-wrap;">8.&#9;Surat Pengesahan Pemaju mengenai Kos Kewangan <span class="label-required">*</span></label>
                <div class="col-md-6">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_pengesahan_kos_kewangan_harga_mampumilik"
                    class="form-control w-100"
                    :doc="maklumat.kewangan ? maklumat.kewangan.doc_pengesahan_kos_kewangan_harga_mampumilik : null"
                    :class="{'is-invalid': errors.first(`doc_pengesahan_kos_kewangan_harga_mampumilik`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_pengesahan_kos_kewangan_harga_mampumilik', file, callback)"
                    @remove="removeDoc('doc_pengesahan_kos_kewangan_harga_mampumilik')"
                    :readonly="readonly"
                  />
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-6" style="white-space: pre-wrap;">{{ form.kos_kewangan_harga_bebas <= 0 ? 9 : 8 }}.&#9;Nilai Jumlah Kos 3%</label>
                <div class="col-md-6">
                  <p class="form-control-plaintext">{{ estimated_gdc_harga_mampumilik | currency }}</p>
                </div>
              </div>
              <div class="form-group row" v-if="kos_keseluruhan_harga_bebas > 0 && kos_keseluruhan_harga_mampumilik > 0">
                <label class="col-form-label col-md-6" style="white-space: pre-wrap;">{{ form.kos_kewangan_harga_bebas <= 0 ? 10 : 9 }}.&#9;GDV</label>
                <div class="col-md-6">
                  <p class="form-control-plaintext">{{ getNilaiJualanMampumilik | currency }}</p>
                </div>
              </div>
            </div>

          </section>
      </div>
    </div>
    <div class="card mb-2">
      <div class="card-body">
        <section class="form-compact row">
          <div class="col-lg-12">
            <h5 class="card-title">C. JUMLAH KESELURUHAN</h5>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">1.&#9;Kos Pembinaan</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_pembinaan_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">2.&#9;Kos Konsultan</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_konsultan_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">3.&#9;Kos Kewangan</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_kewangan_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">4.&#9;Kos Tetap (Kos Overhead)<br><small>&#9;(Bayaran Gaji, Sewaan dan lain-lain)</small></label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_tetap_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">5.&#9;Kos Pemasaran</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_pemasaran_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">6.&#9;Kos Tanah</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_tanah_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-6" style="white-space: pre-wrap;">7.&#9;Jumlah Kos</label>
                  <div class="col-md-6">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">RM</span>
                      </div>
                      <input type="text" class="form-control" disabled :value="kos_keseluruhan_keseluruhan.toFixed(2) | currency('')">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-responsive mt-4">
              <table class="table">
                <tr>
                  <td>
                    <strong style="white-space: pre-wrap;">8.&#9;GDV</strong>
                  </td>
                  <td>
                    {{ getNilaiJualanKeseluruhan | currency }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong style="white-space: pre-wrap;">9.&#9;Nilai GDC 3%</strong>
                  </td>
                  <td>
                    {{ jumlah_deposit_keseluruhan | currency }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong style="white-space: pre-wrap;">10.&#9;Nilai Jumlah Kos 3%</strong> <small>(Item 1 hingga 5)</small>
                  </td>
                  <td>
                    {{ estimated_gdc_keseluruhan | currency }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong style="white-space: pre-wrap;">11.&#9;Perbezaan Diantara Nilai GDC dan Nilai Jumlah Kos</strong>
                  </td>
                  <td>
                    {{ (deviation_gdv_vs_gdc * 100).toFixed(2) }}%
                  </td>
                </tr>
                <!-- <tr class="bg-secondary">
                  <td>
                    <strong style="white-space: pre-wrap;">JUMLAH DEPOSIT (HDA) PERLU DIBAYAR</strong>
                  </td>
                  <td>
                    {{ jumlah_deposit_perlu_dibayar | currency }}
                  </td>
                </tr>
                <tr v-if="showPecahanBG">
                  <td>
                    <strong style="white-space: pre-wrap;">12.&#9;Deposit HDA BG 20%</strong>
                  </td>
                  <td>
                    {{ deposit_20_percent | currency }}
                  </td>
                </tr>

                <tr v-if="showPecahanBG">
                  <td>
                    <strong style="white-space: pre-wrap;">13.&#9;Deposit HDA BG 80%</strong>
                  </td>
                  <td>
                    {{ deposit_80_percent | currency }}
                  </td>
                </tr> -->
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>

    <div class="card mb-2">
      <div class="card-body">
        <section class="form-compact row">
          <div class="col-lg-12">
            <h5 class="card-title">D. JUMLAH DEPOSIT (HDA) PERLU DIBAYAR</h5>
            <div class="table-responsive mt-4">
              <table class="table">
                <tr class="bg-secondary">
                  <td>
                    <strong style="white-space: pre-wrap;">JUMLAH DEPOSIT (HDA) PERLU DIBAYAR</strong>
                  </td>
                  <td>
                    <strong>{{ jumlah_deposit_perlu_dibayar | currency }}</strong>
                  </td>
                </tr>

                <tr v-if="showPecahanBG">
                  <td>
                    <label style="white-space: pre-wrap;">1.&#9;Deposit HDA BG 20%</label>
                  </td>
                  <td>
                    {{ deposit_20_percent | currency }}
                  </td>
                </tr>

                <tr v-if="showPecahanBG">
                  <td>
                    <label style="white-space: pre-wrap;">2.&#9;Deposit HDA BG 80%</label>
                  </td>
                  <td>
                    {{ deposit_80_percent | currency }}
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-if="form.created_at >= '2023-05-01T00:00:00Z' && form.created_at <= '2024-06-30T23:59:59Z' && getHargaJualan.length !== 0">
      <div class="card mb-2">
        <div class="card-body">
          <section class="form-compact row">
            <div class="col-lg-12">
              <h5 class="card-title">E. DOKUMEN SOKONGAN</h5>

              <div class="form-group row">
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;">1.&#9;Dokumen Perakuan Arkitek Kos Pembinaan kasar (GDC),  Nilai Pembangunan Kasar (GDV); dan kos konsultan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_perakuan_arkitek"
                    class="form-control w-100"
                    :doc="maklumat.kewangan ? maklumat.kewangan.doc_perakuan_arkitek : null"
                    :class="{'is-invalid': errors.first(`doc_perakuan_arkitek`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_perakuan_arkitek', file, callback)"
                    @remove="removeDoc('doc_perakuan_arkitek')"
                    :readonly="readonly"
                  />
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;">2.&#9;Surat Kelulusan / Penetapan Harga  jualan Rumah Kos Rendah daripada Pihak Berkuasa Negeri (PBN)</label>
                <div class="col-md-4">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_kelulusan_rumah_kos_rendah"
                    class="form-control w-100"
                    :doc="maklumat.kewangan ? maklumat.kewangan.doc_kelulusan_rumah_kos_rendah : null"
                    :class="{'is-invalid': errors.first(`doc_kelulusan_rumah_kos_rendah`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_kelulusan_rumah_kos_rendah', file, callback)"
                    @remove="removeDoc('doc_kelulusan_rumah_kos_rendah')"
                    :readonly="readonly"
                  />
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <!-- <div class="card-footer" v-if="!readonly">
          <div class="text-right">
            <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
            <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
            <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
          </div>
        </div> -->
      </div>

      <div class="card">
        <div class="card-body" v-if="permohonan.status_id != 'D'">
          <section class="form-compact row">
            <div class="col-lg-12">
              <h5 class="card-title">F. PELAKSANAAN INISIATIF KERAJAAN</h5>

              <div class="form-group row">
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;">Adakah anda ingin menyertai inisiatif <strong>SIMEN RAHMAH</strong> ?</label>
                <div class="col-md-4">
                  <div class="form-check form-check-inline">
                    <input id="pilihan_simen_ya" type="radio"
                            name="pilihan_simen_ya" value="1" v-model="form.status_simen" class="form-check-input">
                    <label for="pilihan_simen_ya" class="form-check-label">Ya</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="pilihan_simen_tidak" type="radio"
                            name="pilihan_simen_tidak" value="0" v-model="form.status_simen" class="form-check-input"
                            @change="form.simen_tan = null; form.simen_kampit = null; form.simen_type = null"
                            @click="showInput('no_input')">
                    <label for="pilihan_simen_tidak" class="form-check-label">Tidak</label>
                  </div>
                </div>
                <label class="col-form-label col-md-8" v-if="form.status_simen == 1" style="white-space: pre-wrap;">1.&#9;Jumlah Simen Diperlukan<span class="label-required">*</span></label>
                <div class="col-md-4" v-if="form.status_simen == 1">
                  <div class="form-check form-check-inline">
                    <input id="pilihan_simen_tan" type="radio"
                            name="pilihan_simen" value="1" v-model="form.simen_type" class="form-check-input" 
                            @change="form.simen_kampit = null;"
                            @click="showInput('simen_tan')">
                    <label for="pilihan_simen" class="form-check-label">TAN</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input id="pilihan_simen_kampit" type="radio"
                            name="pilihan_simen" value="2" v-model="form.simen_type" class="form-check-input"
                            @change="form.simen_tan = null;"
                            @click="showInput('simen_kampit')">
                    <label for="pilihan_simen" class="form-check-label">KAMPIT</label>
                  </div>
                  <!-- <div class="form-check form-check-inline">
                    <input id="pilihan_kuotabumi_bilangan" type="radio" :disabled="form.pengesahan_kuotabumi != 'Y'" :checked="form.pilihan_kuotabumi == 1"
                            name="pilihan_kuotabumi"
                            @change="form.pilihan_kuotabumi = 1; form.peratus_kuotabumi = null; form.bilangan_kuotabumi = null;" class="form-check-input">
                    <label for="pilihan_kuotabumi_bilangan" class="form-check-label">Bilangan</label>
                  </div> -->
                </div>
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;"></label>
                <div class="col-md-4" v-if="showSimenTan || form.simen_type == 1">
                  <div class="form-check form-check-inline">
                    <div class="input-group">
                      <input type="text" class="form-control" name="simen_tan" :class="{'is-invalid': errors.first('simen_tan')}" v-model="form.simen_tan">

                        <!-- <input type="text" class="form-control" name="simen_tan" :class="{'is-invalid': errors.first('simen_tan')}" v-model="form.simen_tan"> -->
                        <div class="input-group-prepend">
                          <span class="input-group-text">TAN</span>
                        </div>
                      </div>
                  </div>
                </div>
                <div class="col-md-4" v-if="showSimenKampit || form.simen_type == 2">
                  <div class="form-check form-check-inline">
                    <div class="input-group">
                        <input type="text" class="form-control" name="simen_kampit" :class="{'is-invalid': errors.first('simen_kampit')}" v-model="form.simen_kampit">
                        <div class="input-group-prepend">
                          <span class="input-group-text">KAMPIT</span>
                        </div>
                      </div>
                  </div>
                </div>
              </div>

              <div class="form-group row" v-if="form.status_simen == 1">
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;">2.&#9;Dokumen Sokongan QS berkenaan jumlah simen yang diperlukan
                  <br><small>&#9;* Bil Unit Terlibat (Harga RM300,000 dan ke bawah)</small>
                  <!-- <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div> -->
                </label>
                <div class="col-md-4">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_sokongan_qs"
                    class="form-control w-100"
                    :doc="maklumat.kewangan ? maklumat.kewangan.doc_sokongan_qs : null"
                    :class="{'is-invalid': errors.first(`doc_sokongan_qs`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_sokongan_qs', file, callback)"
                    @remove="removeDoc('doc_sokongan_qs')"
                    :readonly="readonly"
                  />
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="card-footer" v-if="!readonly">
          <div class="text-right">
            <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
            <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
            <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="card mb-2">
        <div class="card-body">
          <section class="form-compact row">
            <div class="col-lg-12">
              <h5 class="card-title">E. DOKUMEN SOKONGAN</h5>

              <div class="form-group row">
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;">1.&#9;Dokumen Perakuan Arkitek Kos Pembinaan kasar (GDC),  Nilai Pembangunan Kasar (GDV); dan kos konsultan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_perakuan_arkitek"
                    class="form-control w-100"
                    :doc="maklumat.kewangan ? maklumat.kewangan.doc_perakuan_arkitek : null"
                    :class="{'is-invalid': errors.first(`doc_perakuan_arkitek`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_perakuan_arkitek', file, callback)"
                    @remove="removeDoc('doc_perakuan_arkitek')"
                    :readonly="readonly"
                  />
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-8" style="white-space: pre-wrap;">2.&#9;Surat Kelulusan / Penetapan Harga  jualan Rumah Kos Rendah daripada Pihak Berkuasa Negeri (PBN)</label>
                <div class="col-md-4">
                  <file-upload
                    type="file" accept="application/pdf"
                    name="doc_kelulusan_rumah_kos_rendah"
                    class="form-control w-100"
                    :doc="maklumat.kewangan ? maklumat.kewangan.doc_kelulusan_rumah_kos_rendah : null"
                    :class="{'is-invalid': errors.first(`doc_kelulusan_rumah_kos_rendah`)}"
                    @uploadwithcallback="({file, callback}) => uploadDoc('doc_kelulusan_rumah_kos_rendah', file, callback)"
                    @remove="removeDoc('doc_kelulusan_rumah_kos_rendah')"
                    :readonly="readonly"
                  />
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="card-footer" v-if="!readonly">
          <div class="text-right">
            <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
            <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
            <button class="btn btn-primary" @click.prevent="saveWithoutF" :disabled="loading">Simpan</button>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "BahagianN",
  components: {},
  props: {
    maklumat: Object,
    pemajuan: Object,
    kewangan: Object,
    permit: Object,
    pembangunan_rs: Array,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    },
    permohonan: Object,
  },
  data() {
    return {
      showSimenTan: false,
      showSimenKampit: false,
      loading: false,
      form: {
        kos_pembinaan_harga_bebas: (this.kewangan ? this.kewangan.kos_pembinaan_harga_bebas : 0) || 0,
        kos_konsultan_harga_bebas: (this.kewangan ? this.kewangan.kos_konsultan_harga_bebas : 0) || 0,
        kos_kewangan_harga_bebas: (this.kewangan ? this.kewangan.kos_kewangan_harga_bebas : 0) || 0,
        kos_tetap_harga_bebas: (this.kewangan ? this.kewangan.kos_tetap_harga_bebas : 0) || 0,
        kos_pemasaran_harga_bebas: (this.kewangan ? this.kewangan.kos_pemasaran_harga_bebas : 0) || 0,
        kos_tanah_harga_bebas: (this.kewangan ? this.kewangan.kos_tanah_harga_bebas : 0) || 0,

        kos_pembinaan_harga_mampumilik: (this.kewangan ? this.kewangan.kos_pembinaan_harga_mampumilik : 0) || 0,
        kos_konsultan_harga_mampumilik: (this.kewangan ? this.kewangan.kos_konsultan_harga_mampumilik : 0) || 0,
        kos_kewangan_harga_mampumilik: (this.kewangan ? this.kewangan.kos_kewangan_harga_mampumilik : 0) || 0,
        kos_tetap_harga_mampumilik: (this.kewangan ? this.kewangan.kos_tetap_harga_mampumilik : 0) || 0,
        kos_pemasaran_harga_mampumilik: (this.kewangan ? this.kewangan.kos_pemasaran_harga_mampumilik : 0) || 0,
        kos_tanah_harga_mampumilik: (this.kewangan ? this.kewangan.kos_tanah_harga_mampumilik : 0) || 0,
        simen_type: (this.kewangan ? this.kewangan.simen_type : 0) || 0,
        simen_tan: (this.kewangan ? this.kewangan.simen_tan : 0) || 0,
        simen_kampit: (this.kewangan ? this.kewangan.simen_kampit : 0) || 0,
        status_simen: (this.kewangan ? this.kewangan.status_simen : 0) || 0,
        created_at: (this.permit ? this.permit.tarikh_mula : 0) || 0,
      },
      showPecahanBG: true,
    }
  },
  methods: {
    showInput(option) {
      if (option === 'simen_tan') {
        this.showSimenTan = true;
        this.showSimenKampit = false;
      } else if (option === 'simen_kampit') {
        this.showSimenTan = false;
        this.showSimenKampit = true;
      } else if (option === 'no_input') {
        this.showSimenTan = false;
        this.showSimenKampit = false;
      }
    },

    async saveWithoutF() {
      if (this.loading) {
        return;
      }

      // todo kalau x setuju, gdc mesti dekat-dekat dengan 70% value gdv

      this.loading = true;

      this.form.status_simen = null;
      this.form.simen_tan = null;
      this.form.simen_kampit = null;
      this.form.simen_type = null;

      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/bahagian-n`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data.kewangan));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    async save() {
      if (this.loading) {
        return;
      }

      // todo kalau x setuju, gdc mesti dekat-dekat dengan 70% value gdv

      this.loading = true;

      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/bahagian-n`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data.kewangan));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/kewangan/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      console.log(this.maklumat.kewangan[key]);
      if (!(this.maklumat.kewangan && this.maklumat.kewangan[key])) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/kewangan/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat.kewangan[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },
  },
  computed: {
    getHargaJualan() {
      const filteredData = this.pembangunan_rs[0].nolot_unit_rs.filter(item => {
        const hargaJualan = parseFloat(item.harga_jualan);
        return !isNaN(hargaJualan) && hargaJualan <= 300000;
      });

      if (filteredData.length === 0) {
        return [];
      }

      return filteredData.map(item => ({
        id: item.id,
        no_lot_pt: item.no_lot_pt,
        no_unit: item.no_unit,
        harga_jualan: parseFloat(item.harga_jualan)
      }));
    },
    // HARGA BEBAS
    getNilaiJualanBebas() {
      if (!this.maklumat.kod_skim_projek_id) {
        return this.pembangunan_rs.reduce((total, pembangunan) => {
          return total + (_.sum(pembangunan.nolot_unit_rs.map(i => parseFloat(i.harga_jualan) * 100)));
        }, 0) / 100;
      }

      return this.pembangunan_rs.reduce((total, pembangunan) => {
        return total + (_.sum(pembangunan.nolot_unit_rs.filter(i => !i.kod_skim_projek_id).map(i => parseFloat(i.harga_jualan) * 100)));
      }, 0) / 100;
    },

    kos_keseluruhan_harga_bebas() {
      return ((parseFloat(this.form.kos_pembinaan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_konsultan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_kewangan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_tetap_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_pemasaran_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_tanah_harga_bebas || 0) * 100)) / 100;
    },

    estimated_gdc_harga_bebas() {
      return (
        ((this.kos_keseluruhan_harga_bebas * 100) - (parseFloat(this.form.kos_tanah_harga_bebas || 0) * 100)) * 0.03
      ) / 100;
    },

    jumlah_deposit_harga_bebas() {
      return (((this.getNilaiJualanBebas * 100) - (parseFloat(this.form.kos_tanah_harga_bebas || 0) * 100)) * ((100 - this.kewangan.profit_margin_pct) / 100) * 0.03) / 100;
    },

    // MAMPU MILIK
    getNilaiJualanMampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0.00;
      }

      return this.pembangunan_rs.reduce((total, pembangunan) => {
        return total + (_.sum(pembangunan.nolot_unit_rs.filter(i => !!i.kod_skim_projek_id).map(i => parseFloat(i.harga_jualan) * 100)));
      }, 0) / 100;
    },

    kos_keseluruhan_harga_mampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return ((parseFloat(this.form.kos_pembinaan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_konsultan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_kewangan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_tetap_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_pemasaran_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_tanah_harga_mampumilik || 0) * 100)) / 100;
    },

    estimated_gdc_harga_mampumilik() {
      return Math.min(
        (
          ((this.kos_keseluruhan_harga_mampumilik * 100) - (parseFloat(this.form.kos_tanah_harga_mampumilik || 0) * 100)) * 0.03
        ) / 100
        , 200000);
    },

    jumlah_deposit_harga_mampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return (((this.getNilaiJualanMampumilik * 100) - (parseFloat(this.form.kos_tanah_harga_mampumilik || 0) * 100)) * 0.8 * 0.03) / 100;
    },

    // KESELURUHAN
    kos_pembinaan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_pembinaan_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_pembinaan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_pembinaan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_konsultan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_konsultan_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_konsultan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_konsultan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_kewangan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_kewangan_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_kewangan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_kewangan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_tetap_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_tetap_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_tetap_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_tetap_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_pemasaran_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_pemasaran_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_pemasaran_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_pemasaran_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_tanah_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_tanah_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_tanah_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_tanah_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_keseluruhan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.kos_keseluruhan_harga_bebas || 0));
      }

      return ((parseFloat(this.kos_keseluruhan_harga_bebas || 0) * 100) +
        (parseFloat(this.kos_keseluruhan_harga_mampumilik || 0) * 100)) / 100;
    },

    estimated_gdc_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.estimated_gdc_harga_bebas || 0));
      }

      return ((parseFloat(this.estimated_gdc_harga_bebas || 0) * 100) +
        (parseFloat(this.estimated_gdc_harga_mampumilik || 0) * 100)) / 100;
    },

    jumlah_deposit_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.jumlah_deposit_harga_bebas || 0));
      }
      else if (this.maklumat.kod_skim_projek_id && this.kos_keseluruhan_harga_bebas == 0) {
        if (this.estimated_gdc_harga_mampumilik >= 200001){
          // sekiranya pemajuan ada skim dan fully RMM dan cap 200k, pemajuan tiada pecahan BG.
          return (parseFloat( 200000 || 0));
        }
        else if (this.estimated_gdc_harga_mampumilik <= 200000){
          return (parseFloat(this.jumlah_deposit_harga_mampumilik || 0));
        }
      }
      else {
        if(this.jumlah_deposit_harga_mampumilik >= 200001){
          return ((parseFloat(this.jumlah_deposit_harga_bebas || 0) * 100) +
          (parseFloat(200000 || 0) * 100)) / 100;
        }
        else if(this.jumlah_deposit_harga_mampumilik <= 200000){
          return ((parseFloat(this.jumlah_deposit_harga_bebas || 0) * 100) +
          (parseFloat(this.jumlah_deposit_harga_mampumilik || 0) * 100)) / 100;
        }
      }
    },

    jumlah_deposit_perlu_dibayar(){
      if (this.kos_keseluruhan_harga_bebas > 0 && this.kos_keseluruhan_harga_mampumilik > 0) {
        this.showPecahanBG = true;
        return this.jumlah_deposit_keseluruhan; 
      }
      else if (this.maklumat.kod_skim_projek_id && this.kos_keseluruhan_harga_bebas == 0){
        if(this.jumlah_deposit_keseluruhan >= 200001) {
          this.showPecahanBG = false;
          return (parseFloat( 200000 || 0));
        }
        else if(this.jumlah_deposit_keseluruhan < 200000) {
          this.showPecahanBG = true;
          return (parseFloat( this.jumlah_deposit_keseluruhan || 0));
        }
        else if(this.estimated_gdc_harga_mampumilik = 200000) {
          this.showPecahanBG = false;
          return (parseFloat( this.estimated_gdc_harga_mampumilik || 0));
        }
      }
      else{
        this.showPecahanBG = true;
        return ((parseFloat(this.jumlah_deposit_harga_bebas || 0) * 100) +
        (parseFloat(this.jumlah_deposit_harga_mampumilik || 0) * 100)) / 100;
      }
    },

    deposit_20_percent() {
      return (parseFloat(this.jumlah_deposit_perlu_dibayar * 0.2));
    },

    deposit_80_percent() {
      return (parseFloat(this.jumlah_deposit_perlu_dibayar * 0.8));
    },

    deviation_gdv_vs_gdc() {
      if (!this.jumlah_deposit_keseluruhan) {
        return 0;
      }

      return (this.jumlah_deposit_keseluruhan - this.estimated_gdc_keseluruhan) / this.jumlah_deposit_keseluruhan;
    },

    getNilaiJualanKeseluruhan() {
      return ((parseFloat(this.getNilaiJualanBebas || 0) * 100) +
        (parseFloat(this.getNilaiJualanMampumilik || 0) * 100)) / 100;
    }

  }
}
</script>

<style scoped>

</style>
