<template>
  <div class="accordion" id="senarai_pembangunan" >
    <div class="card" v-for="(item, index) in form.pembangunan_rs">
      <div class="card-header d-flex justify-content-between">
        <a class="text-dark form-control-plaintext"
           :href="`#tab${index}`"
           data-toggle="collapse">{{ index + 1 }}
          . {{ item.jenis_rumah.keterangan }} -
          {{ item.harga_min | currency('RM', ',', 2, '.', 'front', false) }} ~
          {{ item.harga_max | currency('RM', ',', 2, '.', 'front', false) }}
          - {{ item.maklumat7f.bil_dilulus }} Unit , {{ getTotalKuotaBumi(item) }}/{{ item.maklumat7f.bil_kuotabumi || 0 }} Unit Kuota Bumiputera
        </a>
        <!-- <a class="text-dark form-control-plaintext"
           :href="`#tab${index}`"
           data-toggle="collapse">
           {{ getTotalKuotaBumi(item) }}
           {{ item.total_kuotabumi_count }}
        </a> -->
      </div>
      <div :id="`tab${index}`" class="collapse" :class="{ 'show': index === 0 }" data-parent="#senarai_pembangunan" style="overflow: auto;">
        <!-- <div class="card-body"> -->
          <!-- <div class="table-responsive"> -->
            <table class="bg-white table table-hover table-card">
              <thead class="thead-light">
              <tr>
                <th>Bil.</th>
                <th>No Lot/No PT</th>
                <th>No Unit</th>
                <th>Tuan Tanah</th>
                <th>Kuota Bumi</th>
                <th>Status Jualan</th>
                <th>Harga Jualan(RM)</th>
                <th>Rebat Pemaju(RM)</th>
                <th>Diskaun Lot Bumiputera(RM)</th>
                <th>Inisiatif Kerajaan(RM)</th>
                <th>Harga SPJB(RM)</th>
                <!-- <th>Harga Diskaun</th> -->
                <th>Tarikh SPJB</th>
                <!-- <th>Kedudukan Lot</th> -->
                <!-- <th>Bil. Bilik Tidur</th> -->
                <!-- <th>Bil. Bilik Air</th> -->
                <th>Kemaskini</th>
              </tr>

              </thead>
              <template v-if="item.nolot_unit_rs.length">
                <tbody>
                  <row-nolot-unit
                    v-for="(nolot_unit, unitIndex) in item.nolot_unit_rs"
                    :item="nolot_unit"
                    :unit-pertama="item.nolot_unit_pembeli_pertama"
                    @update:item="i => updateItem(index, unitIndex, i)"
                    :no="unitIndex + 1"
                    :key="`nolot_unit-${nolot_unit.id}`"
                    :pemajuan="pemajuan"
                  ></row-nolot-unit>
                <tr>
                  <th></th>
                  <th>Jumlah</th>
                  <th></th>
                  <th class="text-center">{{ getTotalTuanTanah(item) }}</th>
                  <th class="text-center">{{ getTotalKuotaBumi(item) }}</th>
                  <th class="text-center">{{ getTotalStatusJualan(item) }}</th>
                  <th>{{ getSumHargaJualan(item) | currency('', ',', 2, '.', 'front', false) }}</th>
                  <th></th>
                  <td>
                    <!-- <strong>Bukan Kuota Bumi:</strong> <br>
                    {{ getSumHargaDiskaunNonBumi(item) | currency('', ',', 2, '.', 'front', false) }}<br><br> -->
                    <strong>Jumlah Kuota Bumi:</strong><br>
                    {{ getSumHargaDiskaunBumi(item) | currency('', ',', 2, '.', 'front', false) }}<br><br>
                    <!-- <strong>Jumlah:</strong><br>
                    {{ getSumHargaDiskaun(item) | currency('', ',', 2, '.', 'front', false) }} -->
                  </td>
                  <th></th>
                  <td></td>
                  <!-- <th></th>
                  <th></th>
                  <th></th> -->
                  <th width="1%"></th>
                </tr>
                </tbody>
              </template>
              <tbody v-else>
                <tr>
                  <td colspan="99">
                    <div class="alert alert-warning">Tiada Maklumat Lot Unit</div>
                  </td>
                </tr>
              </tbody>
            </table>
          <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import RowNolotUnit from "./components/RowNolotUnit";

  export default {
    name: "BahagianC",

    components: {
      RowNolotUnit,
    },

    props: {
      pemajuan: Object,
    },

    data() {
      return {
        submitting: false,
        form: JSON.parse(JSON.stringify(this.pemajuan)),
      };
    },

    methods: {
      updateItem(pembangunanIndex, unitIndex, newItem) {
        let pembangunan = this.form.pembangunan_rs[pembangunanIndex];

        if (unitIndex === -1) return;

        pembangunan.nolot_unit_rs.splice(unitIndex, 1, newItem);
        this.form.pembangunan_rs.splice(pembangunanIndex, 1, pembangunan);

        this.$emit('update:pemajuan', JSON.parse(JSON.stringify(this.form)))
      },

      getTotalTuanTanah(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.tuan_tanah) {
            return total + 1;
          }
          return total;
        }, 0);
      },

      getTotalStatusJualan(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.status_jualan) {
            return total + 1;
          }
          return total;
        }, 0);
      },

      getTotalKuotaBumi(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.kuota_bumi) {
            return total + 1;
          }
          return total;
        }, 0);
      },

      getSumHargaJualan(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          return (total * 100 + parseFloat(item.harga_jualan) * 100) / 100;
        }, 0);
      },

      getSumHargaSpjb(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          return (total * 100 + parseFloat(item.harga_spjb || 0.00) * 100) / 100;
        }, 0);
      },


      getSumHargaDiskaunNonBumi(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.kuota_bumi || !item.status_jualan) {
            return total;
          }

          return (total + parseFloat(item.harga_diskaun_kuotabumi || 0.00));
          // return (total * 100 + ( parseFloat(item.harga_jualan || 0.00) * 100 - parseFloat(item.harga_spjb || 0.00) * 100)) / 100;
        }, 0);
      },

      getSumHargaDiskaunBumi(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (!item.kuota_bumi || !item.status_jualan) {
            return total;
          }

          return (total + parseFloat(item.harga_diskaun_kuotabumi || 0.00));
          // return (total * 100 + ( parseFloat(item.harga_jualan || 0.00) * 100 - parseFloat(item.harga_spjb || 0.00) * 100)) / 100;
        }, 0);
      },

      getSumHargaDiskaun(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (!item.status_jualan) {
            return total;
          }

          return (total * 100 + ( parseFloat(item.harga_jualan || 0.00) * 100 - parseFloat(item.harga_spjb || 0.00) * 100)) / 100;
        }, 0);
      },
    },

  }
</script>
