<template>
    <input
      type="text"
      :name="name"
      class="input-file"
      
    >

</template>

<script>
  export default {
    inheritAttrs: false,
    props: {
      doc: Object,
      name: String,
      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        currentFile: null,
      };
    },
    methods: {
      filesChange(name, files) {
        this.currentFile = files[0];
        this.$emit('upload', { photo: this.currentFile, done: this.clear });
      },
      remove() {
        this.$emit('remove', this.doc);
        this.currentFile = '';
        this.$refs.input.value = '';
      },

      clear() {
        this.currentFile = '';
        this.$refs.input.value = '';
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fileupload {
    display: flex;
    position: relative;
    width: 100px;
    height: 100px;
    padding: 0 !important;

    &.has-file {
      background: #BA68C8;
      color: white !important;
    }

    &.is-invalid {
      color: red;

      .upload-icon {
        display: none;
      }
    }

    &.form-control {
      .file-container {
        padding: 0.56rem 0.75rem;
      }
    }
    .icon-photo {
      cursor: pointer;
      width: 70%;
    }

    .uploaded {
      width: 100%;
    }

  }
  .fa-window-close {
    color: #f44747;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 0;
    height: 0;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
  }
  button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: sans-serif;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
</style>
