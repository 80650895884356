<template>
  <div class="fileupload">
    <div v-if="readonly" class="d-flex file-container align-items-center">
      <div v-if="doc">
        <a :href="doc.url" target="_blank" :title="doc.nama_asal">
          <img class="uploaded" :src="doc.url" :alt="doc.nama_asal" />
        </a>
      </div>
      <div v-else class="text-truncate">
        Tidak disertakan
      </div>
    </div>
    <div class="d-flex align-items-center" v-else>
      <div class="d-flex file-container align-items-center position-relative">
        <div v-if="doc">
          <a :href="doc.url" target="_blank" :title="doc.nama_asal">
            <img class="uploaded" :src="doc.url" :alt="doc.nama_asal">
          </a>
        </div>
        <div class="text-truncate" v-else-if="currentFile">
          uploading..
        </div>

        <button class="text-dark ml-2" v-if="doc" @click.prevent="remove" type="button" tabindex="-1"><i class="fas fa-window-close"></i></button>
      </div>
      <div v-if="!currentFile && !doc" class="file-container text-truncate cursor-pointer" @click.prevent="$refs.input.click()">
        <img class="icon-photo" src="/img/photo_upload.png" alt="Muat Naik">
      </div>
    </div>
    <input
      v-bind="$attrs"
      type="file"
      :name="name"
      :accept="acceptType"
      class="input-file"
      ref="input"
      @change="filesChange($event.target.name, $event.target.files)"
    >
  </div>
</template>

<script>
  export default {
    inheritAttrs: false,

    $_veeValidate: {
      value () {
        if (this.doc) {
          return this.doc;
        }

        return this.currentFile;
      },
    },

    props: {
      doc: Object,
      name: String,

      acceptType: {
        type: String,
        default: 'image/*',
      },

      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        currentFile: null,
      };
    },
    methods: {
      filesChange(name, files) {
        this.currentFile = files[0];
        this.$emit('upload', { photo: this.currentFile, done: this.clear });
      },
      remove() {
        this.$emit('remove', this.doc);
        this.currentFile = '';
        this.$refs.input.value = '';
      },

      clear() {
        this.currentFile = '';
        this.$refs.input.value = '';
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fileupload {
    display: flex;
    position: relative;
    width: 100px;
    height: 100px;
    padding: 0 !important;

    &.has-file {
      background: #BA68C8;
      color: white !important;
    }

    &.is-invalid {
      color: red;

      .upload-icon {
        display: none;
      }
    }

    &.form-control {
      .file-container {
        padding: 0.56rem 0.75rem;
      }
    }
    .icon-photo {
      cursor: pointer;
      width: 70%;
    }

    .uploaded {
      width: 100%;
    }

  }
  .fa-window-close {
    color: #f44747;
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 0;
    height: 0;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
  }
  button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: sans-serif;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
</style>
