<template>
  <div class="card">
    <div class="card-body">
      <section>
        <h5 class="card-title text-primary">MAKLUMAT PEGAWAI BERHUBUNG</h5>
        <div class="form-group form-row" v-if="pegawai_perhubungan_rs.length">
          <label class="col-form-label col-md-3">Pegawai Perhubungan</label>
          <div class="col-md-9">
            <select class="form-control">
              <option selected :value="null">Sila Pilih</option>
              <option v-for="(pegawai, index) in pegawai_perhubungan_rs" :value="index">{{ pegawai.nama }}</option>
            </select>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3" style="white-space: pre-wrap;">1.&#9;Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
          <div class="col-md-9">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai') }" name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai" @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required'"/>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3" style="white-space: pre-wrap;">2.&#9;No Telefon Pejabat <span class="label-required">*</span></label>
          <div class="col-md-3">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_pejabat') }" name="pemaju_no_pejabat" :value="pemaju_no_pejabat" @input="$emit('update:pemaju_no_pejabat', $event.target.value.toUpperCase())" v-validate="'required'"/>
          </div>
          <label class="col-form-label col-md-3" style="white-space: pre-wrap;">3.&#9;No Telefon Bimbit <span class="label-required">*</span></label>
          <div class="col-md-3">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit') }" name="pemaju_no_bimbit" :value="pemaju_no_bimbit" @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required'"/>
          </div>
        </div>
      </section>
      <br>
      <br>
      <section>
        <h5 class="card-title text-primary">MAKLUMAT PEMBAHARUAN</h5>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;No. Lesen</label>
          <div class="col-md-8">
            <input type="text" :value="lesen ? lesen.no_lesenpermit : ''" class="form-control" disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;No. Permit</label>
          <div class="col-md-8">
            <input type="text" :value="permit ? permit.no_lesenpermit : ''" class="form-control" disabled>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Jenis Pembaharuan</label>
          <div class="col-md-8">
            <div class="form-check">
              <input id="pembaharuan_lesen" class="form-check-input" type="checkbox" name="pembaharuan_lesen" :checked="pembaharuan_lesen" @change="$emit('update:pembaharuan_lesen', !pembaharuan_lesen)" value="1">
              <label for="pembaharuan_lesen" class="form-check-label">Lesen</label>
            </div>
            <div class="form-check">
              <input id="pembaharuan_permit" class="form-check-input" type="checkbox" name="pembaharuan_permit" :checked="pembaharuan_permit" @change="$emit('update:pembaharuan_permit', !pembaharuan_permit)" value="1">
              <label for="pembaharuan_permit" class="form-check-label">Permit</label>

            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Jenis Iklan</label>
        </div>

        <section>
          <h5 class="card-title">Dicadangkan:</h5>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th width="100px">Bil.</th>
                <th>Jenis Iklan</th>
                <th width="1%" class="text-center"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(jenis_iklan, index) in kodJenisIklanRs" :key="`jenis_iklan_${jenis_iklan.id}`">
                <td>{{ index + 1 }}.</td>
                <td>{{ jenis_iklan.keterangan }}</td>
                <td>
                  <div class="form-check" style="padding: 0;padding:0; text-align:center;"><input type="checkbox" :checked="checkedJenisIklan(jenis_iklan.id)" @input="toggleJenisIklan(jenis_iklan.id)"></div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>

          <br>
          <br>

          <h5 class="card-title">Lain-lain, jika ada:</h5>
          <div class="table-responsive">
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th width="100px">Bil.</th>
                <th>Jenis Iklan</th>
                <th width="1%" class="text-center">
                  <button class="btn btn-success" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="add"><i class="fas fa-plus"></i></button>
                </th>
              </tr>
              </thead>
              <tbody>
              <template v-if="iklan_lain_rs.length">
                <tr v-for="(item, index) in iklan_lain_rs">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.keterangan_iklan_lain }}</td>
                  <td nowrap>
                    <button class="btn btn-icon text-primary" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="update(index)" title="Kemaskini Jenis Iklan"><i class="fas fa-building"></i></button>
                    <button class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroy(index)" title="Hapus Jenis Iklan"><i class="fas fa-trash"></i></button>
                  </td>
                </tr>
              </template>
              <template v-else>
                <td class="text-center text-gray" colspan="5">Tiada maklumat iklan lain-lain.</td>
              </template>
              </tbody>
            </table>
          </div>
          <input v-for="(item, index) in iklan_rs" type="hidden" name="iklan_rs[]" :value="item.id">
          <input v-for="(item, index) in iklan_lain_rs" type="hidden" name="iklan_lain_rs[]" :value="item.keterangan_iklan_lain">
        </section>

        <br>
        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Senarai Harga</label>
        </div>

        <div class="accordion" id="senarai_pembangunan">
          <div class="card" v-for="(item, index) in pembangunan_rs">
            <div class="card-header d-flex justify-content-between">
              <a class="text-dark form-control-plaintext"
                 :href="`#tab${index}`"
                 data-toggle="collapse"
                 style="display: block; font-size: 1rem; line-height: 1.5; text-decoration: none;"
              >
                <table>
                  <tbody>
                  <tr>
                    <th colspan="2">PEMBANGUNAN {{ index + 1 }}</th>
                  </tr>
                  <tr>
                    <th class="pr-4">Jenis Hakmilik</th>
                    <td>: {{ item.jenis_hakmilik ? item.jenis_hakmilik.keterangan : '' }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Jenis Kediaman</th>
                    <td>: {{ item.jenis_rumah ? item.jenis_rumah.keterangan : '' }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Bil Unit Dipohon</th>
                    <td>: {{ item.bil_unit }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Bil Tingkat</th>
                    <td>: {{ item.bil_tingkat }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Harga Minima</th>
                    <td>: {{ item.harga_min | currency('RM', ',', 2, '.', 'front', false) }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Harga Maksima</th>
                    <td>: {{ item.harga_max | currency('RM', ',', 2, '.', 'front', false) }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Nilai Jualan</th>
                    <td>: {{ item.nilai_jualan | currency('RM', ',', 2, '.', 'front', false) }}</td>
                  </tr>
                  <tr>
                    <th class="pr-4">Jenis Perjanjian</th>
                    <td>: {{ item.jenis_perjanjian ? item.jenis_perjanjian.keterangan : '' }}</td>
                  </tr>
                  </tbody>
                </table>
              </a>
            </div>
            <div :id="`tab${index}`" class="collapse" :class="{ 'show': index === 0 }" data-parent="#senarai_pembangunan">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="bg-white table table-hover table-card">
                    <thead class="thead-light">
                    <tr>
                      <th>No. Hakmilik</th>
                      <th>No Unit / Lot</th>
                      <th class="text-center">Tuan Tanah</th>
                      <th class="text-center">Kuota Bumi</th>
                      <th>Keluasan Tanah</th>
                      <th>Keluasan Bangunan</th>
                      <th>Harga Sebelum Diskaun</th>
                      <th>Harga Diskaun Bumi</th>
                      <th>Status Jualan</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="item.nolot_unit_rs.length">
                      <row-nolot-unit
                        v-for="(nolot_unit, unitIndex) in item.nolot_unit_rs"
                        :item="nolot_unit"
                        :key="`nolot_unit-${nolot_unit.id}`"
                      ></row-nolot-unit>
                    </template>
                    <tr v-else>
                      <td colspan="99">
                        <div class="alert alert-warning">Tiada Maklumat Lot Unit</div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <tambah-jenis-iklan-modal></tambah-jenis-iklan-modal>

    </div>
  </div>
</template>

<script>
import RowNolotUnit from "./components/RowNolotUnit";
import TambahJenisIklanModal from "../../common/TambahJenisIklanModal";

export default {
  name: "BahagianA",
  inject: ['$validator'],

  components: {
    TambahJenisIklanModal,
    RowNolotUnit,
  },

  props: {
    // read only
    kodJenisIklanRs: Array,
    lesen: Object,
    permit: Object,
    pembangunan_rs: Array,
    pegawai_perhubungan_rs: {type: Array, default: () => ([])},

    pembaharuan_lesen: Boolean,
    pembaharuan_permit: Boolean,
    iklan_rs: Array,
    iklan_lain_rs: Array,
    pemaju_nama_pegawai: String,
    pemaju_no_pejabat: String,
    pemaju_no_bimbit: String,
  },

  methods: {
    checkedJenisIklan(kod_jenis_iklan_id) {
      const jenis_iklan = this.iklan_rs.find(item => item.id == kod_jenis_iklan_id);

      return !!jenis_iklan;
    },

    toggleJenisIklan(kod_jenis_iklan_id) {
      const iklan_rs = JSON.parse(JSON.stringify(this.iklan_rs));

      const item = iklan_rs.find(item => item.id == kod_jenis_iklan_id);

      if (!!item) {
        const index = iklan_rs.indexOf(item);
        iklan_rs.splice(index, 1);
      } else {
        iklan_rs.push({
          id: kod_jenis_iklan_id,
        })
      }

      this.$emit('update:iklan_rs', iklan_rs);
    },

    update(index) {
      const iklan_lain_rs = JSON.parse(JSON.stringify(this.iklan_lain_rs));
      const jenis_iklan_lain = iklan_lain_rs[index];

      vmodal.open('tambahjenisiklan', {
        ...jenis_iklan_lain,
      }, ({keterangan_iklan_lain}) => {

        iklan_lain_rs.splice(index, 1, {...jenis_iklan_lain, keterangan_iklan_lain});

        this.$emit('update:iklan_lain_rs', iklan_lain_rs);
      })
    },

    destroy(index) {
      const iklan_lain_rs = JSON.parse(JSON.stringify(this.iklan_lain_rs));
      iklan_lain_rs.splice(index, 1);

      this.$emit('update:iklan_lain_rs', iklan_lain_rs);
    },

    add() {
      const iklan_lain_rs = JSON.parse(JSON.stringify(this.iklan_lain_rs));

      vmodal.open('tambahjenisiklan', {}, ({keterangan_iklan_lain}) => {
        iklan_lain_rs.push({keterangan_iklan_lain})
        this.$emit('update:iklan_lain_rs', iklan_lain_rs);
      })
    },
  },
}
</script>
