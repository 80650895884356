<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">No Unit / Lot {{ unit.no_unit }}</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <section>
              <h4 class="card-title">MAKLUMAT UNIT</h4>
              <div class="card border-0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Lot PT :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_lot_pt }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">No Unit :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ unit.no_unit }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Harga Jualan Yang Diluluskan :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">
                            {{ unit.harga_jualan | currency("RM", ",", 2, ".", "front", false) }}</p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <label class="col-sm-6 col-form-label">Pembeli Pertama :</label>
                        <div class="col-sm-6">
                          <p class="form-control-plaintext">{{ isFirstBuyer ? "Ya" : "Tidak" }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section>
              <div class="d-flex justify-content-between my-3">
                <h4 class="card-title">MAKLUMAT CALON PEMBELI</h4>
                <div>
                  <button type="button" class="btn btn-primary" id="btnTambahPembeli"
                          @click="bukaBorang">Tambah Calon Pembeli
                  </button>
                </div>

                <!-- <div v-if="pembeli_rs.length < 20">
                  <button type="button" class="btn btn-primary" id="btnTambahPembeli" v-if="!unit.no_kp"
                          @click.prevent="bukaBorang">Tambah Calon Pembeli
                  </button>
                </div>
                <div v-if="pembeli_rs.length > 19 || unit.no_kp ">
                  <button type="button" class="btn btn-primary" style="cursor: not-allowed;opacity: 0.65"
                          title="Tidak dibenarkan untuk menambah calon pembeli kerana kuota sudah mencukupi atau maklumat jualan telah dikemaskini.">
                    Tambah Calon Pembeli
                  </button>
                </div> -->
                <!-- <div v-if="unit.no_kp">
                  <button type="button" class="btn btn-primary" style="cursor: not-allowed;opacity: 0.65" title="Tidak dibenarkan untuk menambah calon pembeli kerana maklumat jualan telah dikemaskini.">Tambah Calon Pembeli</button>
                </div> -->
              </div>
              <div class="d-flex align-items-center justify-content-center" v-if="loading" style="min-height: 100px;">
                <svg xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block; shape-rendering: auto;"
                     width="50px" height="50px" viewBox="0 0 100 100"
                     preserveAspectRatio="xMidYMid">
                  <circle cx="50" cy="50" r="6.57689" fill="none" stroke="#3887b4" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline" begin="-0.49019607843137253s"></animate>
                  </circle>
                  <circle cx="50" cy="50" r="16.4808" fill="none" stroke="#5699d2" stroke-width="11">
                    <animate attributeName="r" repeatCount="indefinite" dur="0.9803921568627451s" values="0;20"
                             keyTimes="0;1" keySplines="0 0.2 0.8 1"
                             calcMode="spline"></animate>
                    <animate attributeName="opacity" repeatCount="indefinite" dur="0.9803921568627451s" values="1;0"
                             keyTimes="0;1" keySplines="0.2 0 0.8 1"
                             calcMode="spline"></animate>
                  </circle>
                </svg>
              </div>
              <div v-else>
                <div class="table-responsive">
                  <table class="table table-striped ">
                    <thead class="bg-info text-white">
                    <tr>
                      <th>Kategori Pembeli</th>
                      <th>Nama / Syarikat</th>
                      <th>No. KP / Passport / SSM</th>
                      <th>Bangsa</th>
                      <th>Negara</th>
                      <th nowrap width="1%">Status</th>
                      <th class="text-center">Penama Pertama</th>
                      <th class="text-center"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="pembeli_rs.length">
                      <tr v-for="(row, index) in pembeli_rs" :key="`pembeli-${index}`">
                        <td>
                          {{
                            optional(kodJenisPemilikanRs.find(item => row.kod_jenis_pemilikan_id == item.id)).keterangan
                          }}
                        </td>
                        <td>{{ row.nama_pembeli }}</td>
                        <td>{{ row.no_kp }}</td>
                        <td>{{ optional(kodKaumRs.find(item => row.kod_bangsa_id == item.id)).keterangan }}</td>
                        <td style="text-transform: uppercase;">{{ optional(kodNegaraRs.find(item => row.kod_negara_id == item.id)).keterangan }}</td>
                        <td nowrap>{{ row.status_pembeli }}</td>
                        <td nowrap class="text-center">
                          <span v-if="row.no_kp === unit.no_kp">{{ "&#x2714;" }}</span>
                        </td>
                        <td nowrap class="text-center">
                          <span v-if="row.updated_by === 'API' " class="badge badge-info px-3 mx-2"
                                :title="'Dikemaskini oleh API pada '+ row.updated_at">API </span>
                          <div class="d-inline" v-if="action != null">
                            <button class="btn btn-icon btn-sm d-inline" data-toggle="tooltip"
                                    title="Kemaskini Maklumat Calon Pembeli" @click.prevent="viewPembeli(row.id)"><i
                              class="fas fa-edit"></i></button>

                            <button v-if="row.status_pembeli === 'CALON'"
                                    @click.prevent="kemaskiniStatusPembeli(index, 'BATAL')"
                                    class="btn btn-icon btn-sm text-danger" data-toggle="tooltip" title="Batal"><i
                              class="fas fa-trash"></i></button>
                            <button v-else-if="row.status_pembeli === 'PEMBELI' && !isFirstBuyer"
                                    @click.prevent="kemaskiniStatusPembeli(index, 'BATAL SPJB')"
                                    class="btn btn-icon btn-sm text-danger" data-toggle="tooltip" title="Batal SPJB"><i
                              class="fas fa-trash"></i></button>
                          </div>
                          <div class="d-inline" v-else="action == null">
                            <span v-if="row.updated_by === 'API' ">
                            </span>
                            <span v-else>
                              <button class="btn btn-icon btn-sm d-inline" data-toggle="tooltip"
                                    title="Kemaskini Maklumat Calon Pembeli" @click.prevent="editPembeli(row.id)"><i
                              class="fas fa-edit"></i></button>
                            </span>
                            <button class="btn btn-icon btn-sm d-inline" data-toggle="tooltip"
                                    title="Papar Maklumat Calon Pembeli" @click.prevent="viewPembeli(row.id)"><i
                              class="fas fa-eye"></i></button>

                            <button class="btn btn-icon btn-sm text-danger disabled" style="cursor: not-allowed;"
                                    disabled=""><i class="fas fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td colspan="99">
                        <div class="alert alert-warning">Tiada maklumat calon pembeli.</div>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-center my-3">
                  <a v-if="isFirstBuyer" class="btn btn-primary" :href="`/pemaju/pembatalan-unit-pertama/${unit.id}`">Permohonan
                    Pembatalan Unit Pertama</a>
                  <div v-else="isFirstBuyer" class="mx-3">
                    <p v-if="pembeli_rs.length && unit.updated_by === 'API'">
                      <button type="button" class="btn btn-outline-danger disabled" disabled><i class="fas fa-trash"></i> Batal Pembeli</button>

                      <span class="border-left ml-2 pl-2">Fungsi ini tidak diaktifkan disebabkan pengemaskinian maklumat melalui integrasi.</span>
                    </p>
                    <p v-else>
                      <button type="button" v-show="openSPJB" class="btn btn-outline-danger" @click.prevent="batalPembeli()"><i class="fas fa-trash"></i> Batal Pembeli</button>
                    </p>
                  </div>
                </div>
                <section>
                  <div v-show="openBorang">
                    <div class="card mb-3">
                      <div class="card-header d-flex justify-content-between">
                        <h5 class="card-title">BORANG PENDAFTARAN CALON PEMBELI</h5>
                        <button type="button" class="close" @click.prevent="tutupBorang" aria-label="Close"
                                title="Tutup Borang">
                          <span aria-hidden="true">×</span>
                        </button>
                      </div>
                      <div class="card-body">
                        <h5 class="card-title">MAKLUMAT CALON PEMBELI</h5>
                        <div>Medan bertanda <img src="/img/espa_icon.png"
                        alt="ESPA Icon"
                        style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
                        ></img> akan dipaparkan dalam cetakan eSPA.
                        </div>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-6">
                            <!-- <div class="form-group row" v-show="isPenamaPertama">
                              <label class="col-md-4 col-form-label">Kumpul Kepada</label>
                              <div class="col-md-8">
                                <select v-model="form.parent_id" class="form-control" :class="{'is-invalid': errors.first('parent_id')}" v-validate="'required'" name="parent_id" data-vv-as="kumpul kepada">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="pembeli in pembeli_rs.slice(0, 1)" :value="pembeli.id" :selected="true">{{ pembeli.nama_pembeli }}</option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first('parent_id') }}</span>
                              </div>
                            </div> -->
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Kategori Pembeli <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <select class="form-control" v-model="form.kod_jenis_pemilikan_id"
                                        @change="jenisPemilikanChange"
                                        :class="{'is-invalid': errors.first('kod_jenis_pemilikan_id')}"
                                        v-validate="'required'" name="kod_jenis_pemilikan_id"
                                        data-vv-as="Jenis Pemilikan" :disabled="disabled == 1">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="jenis_pemilikan in kodJenisPemilikanRsFilter" :value="jenis_pemilikan.id">
                                    {{ jenis_pemilikan.keterangan }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kod_jenis_pemilikan_id") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">{{ !form.kod_jenis_pemilikan_id ? `Nama Individu / Syarikat` : (form.kod_jenis_pemilikan_id == 'I' ? `Nama Individu` : `Nama Syarikat`) }} <span
                                class="label-required">*</span><img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <input :value="form.nama_pembeli"
                                       @input="form.nama_pembeli = $event.target.value.toUpperCase()" type="text"
                                       class="form-control" maxlength="255" required=""
                                       :class="{'is-invalid': errors.first('nama_pembeli')}" v-validate="'required'"
                                       name="nama_pembeli" data-vv-as="Nama Individu / Syarikat"
                                       :disabled="disabled == 1">
                                <span class="invalid-feedback">{{ errors.first("nama_pembeli") }}</span>
                              </div>
                            </div>
                            <div class="form-group row" v-if="form.kod_jenis_pemilikan_id && form.kod_jenis_pemilikan_id != 'I'">
                              <label class="col-md-4 col-form-label">No. Pendaftaran Syarikat (SSM) <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.no_kp"
                                  @input="form.no_kp = $event.target.value.toUpperCase()" type="text"
                                  class="form-control" maxlength="255" required=""
                                  :class="{'is-invalid': errors.first('no_kp')}" v-validate="'required'"
                                  name="no_kp" data-vv-as="No. Pendaftaran Syarikat (SSM)"
                                  :disabled="disabled == 1">
                                <span class="invalid-feedback">{{ errors.first("no_kp") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">No Telefon <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input :value="form.no_tel" @input="form.no_tel = $event.target.value.toUpperCase()"
                                  type="number" class="form-control"
                                  :class="{'is-invalid': errors.first('no_tel')}" v-validate="'required'"
                                  maxlength="15" minlength="10"
                                  onKeyPress="if(this.value.length==15) return false;" name="no_tel"
                                  data-vv-as="No Telefon" :disabled="disabled == 1">
                                <span class="invalid-feedback">{{ errors.first("no_tel") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-form-label col-md-4">Kerakyatan <span class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input v-if="form.kod_jenis_pemilikan_id && form.kod_jenis_pemilikan_id != 'I'" v-model="form.kerakyatan"
                                  type="text" class="form-control"
                                  :class="{'is-invalid': errors.first('kerakyatan')}" v-validate="'required'"
                                  name="kerakyatan"
                                  data-vv-as="Kerakyatan" :disabled="true">
                                <select v-else class="form-control" v-model="form.kerakyatan"
                                  @change="kerakyatanChange"
                                  :class="{'is-invalid': errors.first('kerakyatan')}"
                                  v-validate="'required'" name="kerakyatan"
                                  data-vv-as="Kerakyatan" :disabled="disabled == 1 || ['ST', 'SA'].includes(form.kod_jenis_pemilikan_id)">
                                    <option :value="null">Sila Pilih</option>
                                    <option v-for="kerakyatan in listKerakyatan" :value="kerakyatan.id">
                                      {{ kerakyatan.keterangan }}
                                    </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kerakyatan") }}</span>
                              </div>
                            </div>
                            <!-- kerakyatan = warganegara -->
                            <template v-if="form.kod_jenis_pemilikan_id == `I` && form.kerakyatan == `warganegara`">
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label" style="align-items: center; display: flex">No. Kad Pengenalan<span class="label-required">*</span><img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                                <div class="col-md-8">
                                  <input :value="form.no_kp" @input="form.no_kp = $event.target.value.toUpperCase()"
                                    type="text" class="form-control" maxlength="22"
                                    :class="{'is-invalid': errors.first('no_kp')}" v-validate="'required'"
                                    name="no_kp"
                                    data-vv-as="No. Kad Pengenalan"
                                    :disabled="disabled == 1">
                                  <span class="invalid-feedback">{{ errors.first("no_kp") }}</span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label" style="display: flex; justify-content: space-between; align-items: center;">
                                    <div style="display: flex; flex-direction: column;">
                                      <span>No. Askar/Polis</span>
                                      <small class="form-text">(jika berkaitan)</small>
                                    </div>
                                    <img src="/img/espa_icon.png"
                                        alt="ESPA Icon"
                                        class="fas fa-info-circle ml-auto align-middle"
                                        style="width: 15px;"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Medan ini akan dipaparkan dalam cetakan eSPA.">
                                  </label>
                                <div class="col-md-8">
                                  <input :value="form.no_askar"
                                    @input="form.no_askar = $event.target.value.toUpperCase()"
                                    type="text" class="form-control" maxlength="22"
                                    :class="{'is-invalid': errors.first('no_askar')}"
                                    name="no_askar"
                                    :disabled="disabled == 1">
                                  <span class="invalid-feedback">{{ errors.first("no_askar") }}</span>
                                  <!-- <small class="form-text">(jika berkaitan)</small> -->
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label">Warganegara <span class="label-required">*</span></label>
                                <div class="col-md-8">
                                  <input
                                    v-model="form.warganegara_text"
                                    type="text" class="form-control"
                                    :class="{'is-invalid': errors.first('warganegara_text')}"
                                    name="warganegara_text" readonly
                                    :disabled="disabled == 1">
                                  <span class="invalid-feedback">{{ errors.first("warganegara_text") }}</span>
                                </div>
                              </div>
                            </template>
                            <!-- kerakyatan = bukan_warganegara -->
                            <template v-else-if="form.kod_jenis_pemilikan_id == `I` && form.kerakyatan == `bukan_warganegara`">
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label" style="align-items: center; display: flex">No. Passport <span class="label-required">*</span><img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                                <div class="col-md-8">
                                  <input :value="form.no_kp"
                                    @input="form.no_kp = $event.target.value.toUpperCase()"
                                    type="text" class="form-control" maxlength="22"
                                    :class="{'is-invalid': errors.first('no_kp')}" v-validate="'required'"
                                    name="no_kp"
                                    data-vv-as="No. Passport"
                                    :disabled="disabled == 1">
                                  <span class="invalid-feedback">{{ errors.first("no_kp") }}</span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label">Warganegara <span class="label-required">*</span></label>
                                <div class="col-md-8">
                                  <select v-model="form.warganegara_id" class="form-control text-uppercase"
                                    :class="{'is-invalid': errors.first('warganegara_id')}" v-validate="'required'"
                                    name="warganegara_id" data-vv-as="Warganegara" :disabled="disabled == 1">
                                    <option :value="null">SILA PILIH</option>
                                    <option v-for="negara in kodNegaraFiltered" :value="negara.id">{{ negara.keterangan }}</option>
                                  </select>
                                  <span class="invalid-feedback">{{ errors.first("warganegara_id") }}</span>
                                </div>
                              </div>
                            </template>
                            <!-- kerakyatan = pemastautin_tetap -->
                            <template v-else-if="form.kod_jenis_pemilikan_id == `I` && form.kerakyatan == `pemastautin_tetap`">
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label" style="align-items: center; display: flex">No. Kad Pengenalan<span class="label-required">*</span><img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                                <div class="col-md-8">
                                  <input :value="form.no_kp" @input="form.no_kp = $event.target.value.toUpperCase()"
                                    type="text" class="form-control" maxlength="22"
                                    :class="{'is-invalid': errors.first('no_kp')}" v-validate="'required'"
                                    name="no_kp"
                                    data-vv-as="No. Kad Pengenalan"
                                    :disabled="disabled == 1">
                                  <span class="invalid-feedback">{{ errors.first("no_kp") }}</span>
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label">Warganegara <span class="label-required">*</span></label>
                                <div class="col-md-8">
                                  <select v-model="form.warganegara_id" class="form-control text-uppercase"
                                    :class="{'is-invalid': errors.first('warganegara_id')}" v-validate="'required'"
                                    name="warganegara_id" data-vv-as="Warganegara" :disabled="disabled == 1">
                                    <option :value="null">SILA PILIH</option>
                                    <option v-for="negara in kodNegaraFiltered" :value="negara.id">{{ negara.keterangan }}</option>
                                  </select>
                                  <span class="invalid-feedback">{{ errors.first("warganegara_id") }}</span>
                                </div>
                              </div>
                            </template>

                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Bangsa <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <input v-if="form.kod_jenis_pemilikan_id && form.kod_jenis_pemilikan_id != 'I'" v-model="form.kod_bangsa_id"
                                  type="text" class="form-control"
                                  :class="{'is-invalid': errors.first('kod_bangsa_id')}" v-validate="'required'"
                                  name="kod_bangsa_id"
                                  data-vv-as="Bangsa" :disabled="true">
                                <select v-else v-model="form.kod_bangsa_id" class="form-control"
                                        :class="{'is-invalid': errors.first('kod_bangsa_id')}" v-validate="'required'"
                                        name="kod_bangsa_id" data-vv-as="Bangsa" :disabled="disabled == 1">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="kaum in listBangsa" :value="kaum.id">{{ kaum.keterangan }}</option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("kod_bangsa_id") }}</span>
                              </div>
                            </div>
                          </div>

                          <div class="col-6">
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label">Negara <span
                                class="label-required">*</span></label>
                              <div class="col-md-8">
                                <select v-model="form.kod_negara_id" class="form-control text-uppercase"
                                        :class="{'is-invalid': errors.first('negara')}" v-validate="'required'"
                                        name="negara" data-vv-as="Negara" :disabled="disabled == 1">
                                  <option :value="null">SILA PILIH</option>
                                  <option v-for="negara in listNegara" :value="negara.id">{{
                                      negara.keterangan
                                    }}
                                  </option>
                                </select>
                                <span class="invalid-feedback">{{ errors.first("negara") }}</span>
                              </div>
                            </div>
                            <div class="form-group row">
                              <label class="col-md-4 col-form-label" style="align-items: center; display: flex">Alamat <span
                                class="label-required">*</span>
                                <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                              <div class="col-md-8">
                                <input :value="form.alamat1" @input="form.alamat1 = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255"
                                       :class="{'is-invalid': errors.first('alamat1')}" v-validate="'required'"
                                       name="alamat1" data-vv-as="Alamat" :disabled="disabled == 1">
                                <span class="invalid-feedback">{{ errors.first("alamat1") }}</span>
                                <input :value="form.alamat2" @input="form.alamat2 = $event.target.value.toUpperCase()"
                                       type="text" class="form-control mb-3" maxlength="255" :disabled="disabled == 1">
                                <input :value="form.alamat3" @input="form.alamat3 = $event.target.value.toUpperCase()"
                                       type="text" class="form-control" maxlength="255" :disabled="disabled == 1">
                              </div>
                            </div>
                            <template v-if="isNegaraMalaysia">
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label">Poskod <span
                                  class="label-required">*</span></label>
                                <div class="col-md-8">
                                  <select2 v-model="form.poskod" class="form-control"
                                    :class="{'is-invalid': errors.first('poskod')}" name="poskod"
                                    v-validate="'required'"
                                    @input="poskodChange"
                                    data-vv-as="Poskod" :disabled="disabled == 1">
                                    <option :value="null">SILA PILIH</option>
                                    <option v-for="poskod in poskodRs"
                                            :value="poskod.poskod" :key="`poskod-${poskod.id}`">{{ poskod.poskod }}
                                    </option>
                                  </select2>
                                  <span class="invalid-feedback">{{ errors.first("poskod") }}</span>
                                </div>
                              </div>
  
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label">Bandar <span
                                  class="label-required">*</span></label>
                                <div class="col-md-8">
                                  <select v-model="form.kod_bandar_id" class="form-control"
                                          :class="{'is-invalid': errors.first('kod_bandar_id')}" name="kod_bandar_id"
                                          data-vv-as="Bandar" :disabled="true">
                                    <option :value="null">SILA PILIH</option>
                                    <option v-for="bandar in filteredBandar(form.kod_negeri_id)"
                                            :value="bandar.id" :key="`bandar-${bandar.id}`">{{ bandar.keterangan }}
                                    </option>
                                  </select>
                                  <span class="invalid-feedback">{{ errors.first("kod_bandar_id") }}</span>
                                </div>
                              </div>
  
                              <div class="form-group row">
                                <label class="col-md-4 col-form-label">Negeri <span
                                  class="label-required">*</span></label>
                                <div class="col-md-8">
                                  <select v-model="form.kod_negeri_id" class="form-control"
                                          :class="{'is-invalid': errors.first('kod_negeri_id')}" v-validate="'required'"
                                          name="kod_negeri_id" data-vv-as="Negeri"
                                          :disabled="true">
                                    <option :value="null">SILA PILIH</option>
                                    <option v-for="negeri in kodNegeri" :value="negeri.id">{{
                                        negeri.keterangan
                                      }}
                                    </option>
                                  </select>
                                  <span class="invalid-feedback">{{ errors.first("kod_negeri_id") }}</span>
                                </div>
                              </div>

                            </template>
                            
                          </div>
                        </div>

                        <!-- <h5 class="card-title">MAKLUMAT WAKIL KUASA PEMBELI</h5>
                        <hr>
                        <div class="row mb-3">
                          <div class="col-12">
                            <div class="form-group row align-items-center">
                              <label class="col-form-label col-md-3">Mempunyai Wakil Kuasa? <span class="label-required">*</span></label>
                              <div class="col-md-9">
                                <div class="form-check form-check-inline">
                                  <input type="radio" class="form-check-input" v-model="formWakilkuasa.wakilkuasa_ada" name="wakilkuasa_ada" :id="`wakilkuasa_ada_1`" :value="1" :disabled="disabled == 1">
                                  <label class="form-check-label" :for="`wakilkuasa_ada_1`">Ada</label>
                                </div>
                                <div class="form-check form-check-inline">
                                  <input type="radio" class="form-check-input" v-model="formWakilkuasa.wakilkuasa_ada" name="wakilkuasa_ada" :id="`wakilkuasa_ada_0`" :value="0" :disabled="disabled == 1">
                                  <label class="form-check-label" :for="`wakilkuasa_ada_0`">Tiada</label>
                                </div>
                              </div>
                            </div>
                            <div class="form-group row align-items-center" v-show="formWakilkuasa.wakilkuasa_ada == 1">
                              <label class="col-md-3 col-form-label">Jenis Wakil Kuasa <span class="label-required">*</span></label>
                              <div class="col-md-9">
                                <select
                                  id="kod_jenis_wakil_kuasa_id"
                                  v-validate="'required'"
                                  class="form-control" :class="{'is-invalid': errors.first('kod_jenis_wakil_kuasa_id')}"
                                  name="kod_jenis_wakil_kuasa_id"
                                  v-model="formWakilkuasa.kod_jenis_wakil_kuasa_id"
                                  :disabled="disabled == 1"
                                >
                                  <option :value="null">Sila Pilih Jenis Wakil Kuasa</option>
                                  <option v-for="jenis_wakil_kuasa in listJenisWakilKuasa" :value="jenis_wakil_kuasa.id" :key="`jenis_wakilkuasa_${jenis_wakil_kuasa.id}`">{{ jenis_wakil_kuasa.keterangan }}</option>
                                </select>
                              </div>
                            </div>
                            <template v-if="formWakilkuasa.wakilkuasa_ada == 1">
                              section if wakilkuasa_jenis === 'SYARIKAT (BUKAN PEMAJU)'
                              <template v-if="formWakilkuasa.kod_jenis_wakil_kuasa_id === 'BP'">
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">No. SSM <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_no_kp"
                                      type="text" name="wakilkuasa_no_kp"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                                      v-model="formWakilkuasa.wakilkuasa_no_kp"
                                      v-validate="'required'"
                                    >
                                  </div>
                                </div>
                              </template>
                              <section v-show="formWakilkuasa.kod_jenis_wakil_kuasa_id">
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">{{ ['S', 'BP'].includes(formWakilkuasa.kod_jenis_wakil_kuasa_id) ? `Nama Syarikat` : (formWakilkuasa.kod_jenis_wakil_kuasa_id == 'F' ? `Nama Firma` : `Nama Wakil Kuasa`) }} <span
                                    class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input :value="formWakilkuasa.wakilkuasa_nama"
                                      @input="formWakilkuasa.wakilkuasa_nama = $event.target.value.toUpperCase()"
                                      v-validate="'required'" type="text" class="form-control" maxlength="255"
                                      required="" :class="{'is-invalid': errors.first('wakilkuasa_nama')}"
                                      name="wakilkuasa_nama" :data-vv-as="formWakilkuasa.kod_jenis_wakil_kuasa_id == 'S' ? `Nama Syarikat` : (formWakilkuasa.kod_jenis_wakil_kuasa_id == 'F' ? `Nama Firma` : `Nama Wakil Kuasa`)"
                                      :disabled="disabled == 1"
                                      :readonly="isReadonlyWakilKuasaNama"
                                    >
                                    <span class="invalid-feedback">{{ errors.first("wakilkuasa_nama") }}</span>
                                  </div>
                                </div>
                              </section>
                              section if wakilkuasa_jenis === 'SYARIKAT (PEMAJU)'
                              <template v-if="formWakilkuasa.kod_jenis_wakil_kuasa_id === 'S'">
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">No. SSM <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_no_kp"
                                      type="text" name="wakilkuasa_no_kp"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                                      v-model="formWakilkuasa.wakilkuasa_no_kp"
                                      v-validate="'required'"
                                      readonly
                                    >
                                  </div>
                                </div>
                              </template>
  
                              section if wakilkuasa_jenis === 'FIRMA'
                              <template v-else-if="formWakilkuasa.kod_jenis_wakil_kuasa_id === 'F'">
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">No. Pendaftaran Firma <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_no_pendaftaran_firma"
                                      type="text" name="wakilkuasa_no_pendaftaran_firma"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran_firma')}"
                                      v-model="formWakilkuasa.wakilkuasa_no_pendaftaran_firma"
                                      v-validate="'required'"
                                    >
                                  </div>
                                </div>
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">Nama Penandatangan <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_nama_penandatangan"
                                      type="text" name="wakilkuasa_nama_penandatangan"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_nama_penandatangan')}"
                                      v-model="formWakilkuasa.wakilkuasa_nama_penandatangan"
                                      v-validate="'required'"
                                    >
                                  </div>
                                </div>
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">No. Kad Pengenalan <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_no_kp"
                                      type="text" name="wakilkuasa_no_kp"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                                      v-model="formWakilkuasa.wakilkuasa_no_kp"
                                      v-mask="'######-##-####'"
                                      v-validate="'required'"
                                    >
                                  </div>
                                </div>
                              </template>
  
                              section if wakilkuasa_jenis === 'INDIVIDU'
                              <template v-else-if="formWakilkuasa.kod_jenis_wakil_kuasa_id === 'I'">
                                <div class="form-group row align-items-center">
                                  <label class="col-md-3 col-form-label">No. Kad Pengenalan <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_no_kp"
                                      type="text" name="wakilkuasa_no_kp"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                                      v-model="formWakilkuasa.wakilkuasa_no_kp"
                                      v-mask="'######-##-####'"
                                      v-validate="'required'"
                                    >
                                  </div>
                                </div>
                              </template>
  
                              <section v-show="formWakilkuasa.kod_jenis_wakil_kuasa_id">
                                <div class="form-group row">
                                  <label class="col-form-label col-md-3" for="wakilkuasa_no_pendaftaran">No. Pendaftaran Wakil Kuasa <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <input
                                      id="wakilkuasa_no_pendaftaran"
                                      type="text" name="wakilkuasa_no_pendaftaran"
                                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran')}"
                                      v-model="formWakilkuasa.wakilkuasa_no_pendaftaran"
                                      v-validate="'required'"
                                      :disabled="disabled == 1"
                                    >
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label class="col-form-label col-md-3">Tarikh Pendaftaran Wakil Kuasa <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <datepicker name="wakilkuasa_tarikh_pendaftaran"
                                      :class="{ 'is-invalid': errors.first('wakilkuasa_tarikh_pendaftaran') }"
                                      v-model="formWakilkuasa.wakilkuasa_tarikh_pendaftaran"
                                      :disabled="disabled == 1">
                                    </datepicker>
                                  </div>
                                </div>
                                <div class="form-group row">
                                  <label class="col-form-label col-md-3">Nama Mahkamah <span class="label-required">*</span></label>
                                  <div class="col-md-9">
                                    <select2
                                      name="wakilkuasa_mahkamah_id"
                                      class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wakilkuasa_mahkamah_id')}"
                                      v-model="formWakilkuasa.wakilkuasa_mahkamah_id"
                                      v-validate="'required'"
                                      :disabled="disabled == 1"
                                    >
                                      <option :value="null">Sila Pilih Mahkamah Tinggi</option>
                                      <option v-for="mahkamah in mahkamahTinggi" :value="mahkamah.id" :key="`mahkamah_${mahkamah.id}`">{{ mahkamah.nama_mahkamah }}</option>
                                    </select2>
                                  </div>
                                </div>
                              </section>
                            </template>

                          </div>
                        </div> -->

                        <template v-if="form.kod_jenis_pemilikan_id == 'ST' || form.kod_jenis_pemilikan_id == 'SA'">
                          <h5 class="card-title">MAKLUMAT PENANDATANGAN SYARIKAT</h5>
                          <hr>
                          <div class="row border rounded pt-3 my-2" v-for="(item, index) in formPenandatanganSyarikat" :key="index">
                            <div class="col-12 position-relative">
                              <p class="text-bold" style="font-size: 1.2rem; font-weight: bold;">Penandatangan Syarikat {{ index + 1 }}</p>
                              <div class="form-group row">
                                <label class="col-form-label col-md-3" for="nama_pengarah">Nama Pengarah / Wakil <span class="label-required">*</span></label>
                                <div class="col-md-9">
                                  <input
                                    :id="`nama_pengarah_${index}`"
                                    type="text" :name="`nama_pengarah_${index}`"
                                    class="form-control" :class="{'is-invalid': errors.first(`nama_pengarah_${index}`)}"
                                    v-model="item.nama_pengarah"
                                    @input="item.nama_pengarah = item.nama_pengarah.toUpperCase()"
                                    v-validate="'required'"
                                    :disabled="disabled == 1"
                                  >
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-form-label col-md-3" for="no_kp">No. Kad Pengenalan <span class="label-required">*</span></label>
                                <div class="col-md-9">
                                  <input
                                    :id="`no_kp_${index}`"
                                    type="text" :name="`no_kp_${index}`"
                                    class="form-control" :class="{'is-invalid': errors.first(`no_kp_${index}`)}"
                                    v-model="item.no_kp"
                                    v-mask="'######-##-####'"
                                    v-validate="'required'"
                                    :disabled="disabled == 1"
                                  >
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-form-label col-md-3" for="no_tel">No. Telefon Bimbit <span class="label-required">*</span></label>
                                <div class="col-md-9">
                                  <input
                                    :id="`no_tel_${index}`"
                                    type="text" :name="`no_tel_${index}`"
                                    class="form-control" :class="{'is-invalid': errors.first(`no_tel_${index}`)}"
                                    v-model="item.no_tel"
                                    v-validate="'required'"
                                    :disabled="disabled == 1"
                                  >
                                </div>
                              </div>
                              <div class="form-group row">
                                <label class="col-form-label col-md-3" for="emel">Emel <span class="label-required">*</span></label>
                                <div class="col-md-9">
                                  <input
                                    :id="`emel_${index}`"
                                    type="email" :name="`emel_${index}`"
                                    class="form-control" :class="{'is-invalid': errors.first(`emel_${index}`)}"
                                    v-model="item.emel"
                                    v-validate="'required'"
                                    :disabled="disabled == 1"
                                  >
                                </div>
                              </div>
                              <div class="position-absolute" style="top: 3px; right: 5px" v-show="hasRemovePenandatanganSyarikat">
                                <button class="btn btn-link btn-icon pb-0 pr-2 pt-2" @click.prevent="removePenandatanganSyarikat(index)" title="Hapus Komponen"><i class="fa fa-trash text-danger"></i></button>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 d-flex justify-content-end p-0">
                              <button type="button" class="btn btn-primary" @click.prevent="addPenandatanganSyarikat" title="Tambah Unit">Tambah <i class="fas fa-plus"></i></button>
                            </div>
                          </div>
                        </template>

                      </div>
                      <div class="card-footer text-right">
                        <button class="btn btn-secondary" type="button" @click.prevent="tutupBorang">Tutup</button>
                        <button class="btn btn-success" type="button" @click.prevent="daftar" :disabled="saving"
                                v-if="action == 'Daftar'">Daftar
                        </button>
                        <button class="btn btn-success" type="button" @click.prevent="updatePembeli(pembeli_id)"
                                v-else-if="action == 'Kemaskini'">Kemaskini
                        </button>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </section>
            <section>
              <h4 class="mt-2">MAKLUMAT JUALAN</h4>
                  <div class="card mb-3" v-show="openJualan">
                    <div class="card-body">
                      <div>Medan bertanda <img src="/img/espa_icon.png"
                      alt="ESPA Icon"
                      style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
                      ></img> akan dipaparkan dalam cetakan eSPA.
                      </div>
                      <br>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Penama Pertama</label>
                            <div class="col-6 col-md-8">
                              <select v-model="formJualan.no_kp" class="form-control">
                                <option :value="null">SILA PILIH</option>
                                <option v-for="pembeli in pembeli_rs" :value="pembeli.no_kp" :selected="true">
                                  {{ pembeli.nama_pembeli }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Jenis Pembiayaan</label>
                            <div class="col-6 col-md-8">
                              <select v-model="formJualan.kod_biaya_pembeli_id" class="form-control"
                                      name="jenis_pembiayaan_id">
                                <option :value="null">SILA PILIH</option>
                                <option v-for="biaya_pembeli in kodBiayaPembeliRs" :value="biaya_pembeli.id">
                                  {{ biaya_pembeli.keterangan }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Tarikh SPJB</label>
                            <div class="col-6 col-md-8">
                              <datepicker type="text" v-model="formJualan.tarikh_spjb"
                                          :disabled="isFirstBuyer"></datepicker>
                              <small>Kemaskini jika tarikh SPJB berubah</small>
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Diskaun Pemaju</label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money type="text" class="form-control" name="rebat_pemaju"
                                      :class="{'is-invalid': errors.first('rebat_pemaju')}" v-model="formJualan.rebat_pemaju"
                                      v-validate="'required|decimal:2'"
                                      @value="formatPrice(formJualan.rebat_pemaju)"></money>
                              </div>
                              <!-- <small>Harga rumah tidak boleh melebihi harga jualan</small> -->
                            </div>
                          </div>
                          <div v-if="unit.kuota_bumi === 0 && !unit.no_kp" class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Diskaun Lot Bumiputera <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money type="text" class="form-control" name="harga_diskaun_kuotabumi"
                                      :class="{'is-invalid': errors.first('harga_diskaun_kuotabumi')}" v-model="formJualan.harga_diskaun_kuotabumi"
                                      v-validate="'required|decimal:2'" :readonly="unit.kuota_bumi === 0 && !unit.no_kp"
                                      @value="formatPrice(formJualan.harga_diskaun_kuotabumi)"></money>
                              </div>
                              <small>Sekiranya ingin mengisi harga diskaun kuotabumi, sila kemaskini maklumat Unit</small>
                            </div>
                          </div>
                          <div v-if="unit.kuota_bumi === 1 && !unit.no_kp" class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Diskaun Lot Bumiputera <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money type="text" class="form-control" name="harga_diskaun_kuotabumi"
                                      :class="{'is-invalid': errors.first('harga_diskaun_kuotabumi')}" v-model="formJualan.harga_diskaun_kuotabumi"
                                      v-validate="'required|decimal:2'"
                                      @value="formatPrice(formJualan.harga_diskaun_kuotabumi)"></money>
                              </div>
                              <small><i>Nyatakan jumlah diskaun, jika ada.</i></small>
                            </div>
                          </div>
                          <div v-if="unit.kuota_bumi === 0 && unit.no_kp" class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Diskaun Lot Bumiputera</label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money type="text" class="form-control" name="harga_diskaun_kuotabumi"
                                      :class="{'is-invalid': errors.first('harga_diskaun_kuotabumi')}" v-model="formJualan.harga_diskaun_kuotabumi"
                                      v-validate="'required|decimal:2'" :readonly="unit.kuota_bumi === 0 && unit.no_kp"
                                      @value="formatPrice(formJualan.harga_diskaun_kuotabumi)"></money>
                              </div>
                              <!-- <small>Sekiranya ingin mengisi harga diskaun kuotabumi, sila kemaskini maklumat Unit</small> -->
                            </div>
                          </div>
                          <div v-if="unit.kuota_bumi === 1 && unit.no_kp" class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Diskaun Lot Bumiputera <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money type="text" class="form-control" name="harga_diskaun_kuotabumi"
                                      :class="{'is-invalid': errors.first('harga_diskaun_kuotabumi')}" v-model="formJualan.harga_diskaun_kuotabumi"
                                      v-validate="'required|decimal:2'"
                                      @value="formatPrice(formJualan.harga_diskaun_kuotabumi)"></money>
                              </div>
                              <small><i>Nyatakan jumlah diskaun, jika ada.</i></small>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Harga Jualan Selepas Diskaun</label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <input type="text"
                                    class="form-control"
                                    name="harga_spjb_sebelum_inisiatif" readonly v-bind:value="hargaSPJBsebelumInisiatif.toFixed(2)">
                              </div>
                              <!-- <small>Harga rumah tidak boleh melebihi harga jualan</small> -->
                            </div>
                          </div>
                          <div class="form-group row" v-if="this.pemajuan.kewangan && this.pemajuan.kewangan.status_simen == 1">
                            <label class="col-6 col-md-4 col-form-label">Inisiatif Kerajaan</label>
                            <div class="col-3 col-md-5">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money v-if="unit.harga_jualan <= 300000" type="text" class="form-control" name="inisiatif_kerajaan"
                                :class="{'is-invalid': errors.first('inisiatif_kerajaan') || showInisiatifKerajaanError,
                                      'is-warning': showInisiatifKerajaanError && !errors.first('inisiatif_kerajaan')}" v-model="formJualan.inisiatif_kerajaan"
                                      v-validate="'required|decimal:2'"
                                      @value="formatPrice(formJualan.inisiatif_kerajaan)"
                                      @input="calculateDiskaunInisiatif"></money>
				<money v-else type="text" class="form-control" name="inisiatif_kerajaan" @value="formatPrice(formJualan.inisiatif_kerajaan)" @input="calculateDiskaunInisiatif" disabled></money>
                              </div>
                              <small v-if="showInisiatifKerajaanError && formJualan.inisiatif_kerajaan !== null && formJualan.inisiatif_kerajaan !== 0.00 && unit.harga_jualan <= 300000" class="text-danger">
                                Amaun inisiatif kerajaan mestilah minimum RM1000.
                              </small>
                              <small>Amaun inisiatif kerajaan mestilah minimum RM1000 atau sehingga RM5000 atau apa-apa nilai lebih tinggi</small>
                            </div>
                            <div class="col-3 col-md-3">
                              <div class="input-group">
                                <money type="text" class="form-control" name="diskaun_inisiatif"
                                      :class="{'is-invalid': errors.first('diskaun_inisiatif')}" readonly v-model="formJualan.diskaun_inisiatif"
                                      v-validate="'required|decimal:2'"></money>
                                <div class="input-group-prepend">
                                  <span class="input-group-text">%</span>
                                </div>
                              </div>
                              <small><button class="btn btn-icon btn-sm d-inline"
                                    :title="'( Inisiatif_kerajaan / Harga Jualan Yang Diluluskan ) * 100'"><i class="fas fa-info"></i></button></small>
                            </div>
                          </div>
                          <div class="form-group row" v-else>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Harga Belian Bersih (Harga SPJB) <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <input type="text"
                                    class="form-control"
                                    name="harga_spjb" readonly v-bind:value="hargaSPJB.toFixed(2)">
                                <!-- <money type="text" class="form-control" name="harga_spjb"
                                      :class="{'is-invalid': errors.first('harga_spjb')}" v-model="formJualan.harga_spjb"
                                      v-validate="'required|decimal:2'"
                                      @value="formatPrice(formJualan.harga_spjb)"></money> -->
                              </div>
                              <small>Harga rumah tidak boleh melebihi harga jualan</small>
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Harga Meter Persegi <span
                                class="label-required">*</span> <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <div class="input-group">
                                <div class="input-group-prepend">
                                  <span class="input-group-text">RM</span>
                                </div>
                                <money type="text" class="form-control" name="harga_mps"
                                      :class="{'is-invalid': errors.first('harga_mps')}" v-model="formJualan.harga_mps"
                                      v-validate="'required|decimal:2'" @value="formatPrice(formJualan.harga_mps)"></money>
                              </div>
                            </div>
                          </div>
                          <!-- <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label">Jenis Pembiayaan</label>
                            <div class="col-6 col-md-8">
                              <select v-model="formJualan.kod_biaya_pembeli_id" class="form-control"
                                      name="jenis_pembiayaan_id">
                                <option :value="null">SILA PILIH</option>
                                <option v-for="biaya_pembeli in kodBiayaPembeliRs" :value="biaya_pembeli.id">
                                  {{ biaya_pembeli.keterangan }}
                                </option>
                              </select>
                            </div>
                          </div> -->

                        </div>
                      </div>
                      <hr>
                      <label for=""><h4>MAKLUMAT PETAK STRATA</h4></label>
                      <div>Medan bertanda <img src="/img/espa_icon.png"
                        alt="ESPA Icon"
                        style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
                        ></img> akan dipaparkan dalam cetakan eSPA.
                        </div>
                        <br>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Petak / Petak Tanah <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              >
                              </img
                            >
                          </label>
                            <div class="col-6 col-md-8">
                              <input type="text"
                                     :readonly="jenisPerjanjian[0] == 'G'"
                                     v-model="formJualan.strata_petak_tanah"
                                     name="strata_petak_tanah"
                                     class="form-control"
                                     maxlength="255"
                                     required=""
                                     :class="{'is-invalid': jenisPerjanjian[0] == 'H' ? errors.first('strata_petak_tanah') : '' }"
                                     v-validate="'required'"
                                     data-vv-as="Petak / Petak Tanah Strata"
                                     :disabled="disabled == 1"
                              >
                              <span class="invalid-feedback">{{ jenisPerjanjian[0] == 'H' ? errors.first("strata_petak_tanah") : "" }}</span>
                              <!--<small>Isi maklumat jika mempunyai Jadual Strata</small>-->
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Menara <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <input type="text"
                                     :readonly="jenisPerjanjian[0] == 'G'"
                                     v-model="formJualan.strata_menara"
                                     name="strata_menara"
                                     class="form-control"
                                     maxlength="255"
                                     required=""
                                     :class="{'is-invalid': jenisPerjanjian[0] == 'H' ? errors.first('strata_menara') : ''}"
                                     v-validate="'required'"
                                     data-vv-as="Menara Strata"
                                     :disabled="disabled == 1"
                              >
                              <span class="invalid-feedback">{{ jenisPerjanjian[0] == 'H' ? errors.first("strata_menara") : "" }}</span>
                              <!--<small>Isi maklumat jika mempunyai Jadual Strata</small>-->
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Tingkat <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <input type="text"
                                     :readonly="jenisPerjanjian[0] == 'G'"
                                     v-model="formJualan.strata_tingkat"
                                     name="strata_tingkat"
                                     class="form-control"
                                     maxlength="255"
                                     required=""
                                     :class="{'is-invalid': jenisPerjanjian[0] == 'H' ? errors.first('strata_tingkat') : '' }"
                                     v-validate="'required'"
                                     data-vv-as="Tingkat Strata"
                                     :disabled="disabled == 1"
                              >
                              <span class="invalid-feedback">{{ jenisPerjanjian[0] == 'H' ? errors.first("strata_tingkat") : "" }}</span>
                              <!--<small>Isi maklumat jika mempunyai Jadual Strata</small>-->
                            </div>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Jadual Petak Rumah <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <input type="text"
                                     :readonly="jenisPerjanjian[0] == 'G'"
                                     v-model="formJualan.strata_jp"
                                     name="strata_jp"
                                     class="form-control"
                                     maxlength="255"
                                     required=""
                                     :class="{'is-invalid': jenisPerjanjian[0] == 'H' ? errors.first('strata_jp') : '' }"
                                     v-validate="'required'"
                                     data-vv-as="Jadual Petak Rumah Strata"
                                     :disabled="disabled == 1"
                              >
                              <span class="invalid-feedback">{{ jenisPerjanjian[0] == 'H' ? errors.first("strata_jp") : "" }}</span>
                              <!--<small>Isi maklumat jika mempunyai Jadual Strata</small>-->
                            </div>
                          </div>
                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Petak Aksesori <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <input type="text"
                                     :readonly="jenisPerjanjian[0] == 'G'"
                                     v-model="formJualan.strata_petak_aksesori"
                                     name="strata_petak_aksesori"
                                     class="form-control"
                                     maxlength="255"
                                     required=""
                                     :class="{'is-invalid': jenisPerjanjian[0] == 'H' ? errors.first('strata_petak_aksesori') : '' }"
                                     v-validate="'required'"
                                     data-vv-as="Petak Aksesori Strata"
                                     :disabled="disabled == 1"
                              >
                              <span class="invalid-feedback">{{ jenisPerjanjian[0] == 'H' ? errors.first("strata_petak_aksesori") : "" }}</span>
                              <!--<small>Isi maklumat jika mempunyai Jadual Strata</small>-->
                            </div>
                          </div>

                          <div class="form-group row">
                            <label class="col-6 col-md-4 col-form-label" style="align-items: center; display: flex">Jadual Petak Aksesori <img src="/img/espa_icon.png"
                                alt="ESPA Icon"
                                class="fas fa-info-circle ml-auto align-middle"
                                style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Medan ini akan dipaparkan dalam cetakan eSPA."
                              ></img
                            ></label>
                            <div class="col-6 col-md-8">
                              <input type="text"
                                     :readonly="jenisPerjanjian[0] == 'G'"
                                     v-model="formJualan.strata_jadual_aksesori"
                                     name="strata_jadual_aksesori"
                                     class="form-control"
                                     maxlength="255"
                                     required=""
                                     :class="{'is-invalid': jenisPerjanjian[0] == 'H' ? errors.first('strata_jadual_aksesori') : '' }"
                                     v-validate="'required'"
                                     data-vv-as="Jadual Petak Aksesori Strata"
                                     :disabled="disabled == 1"
                              >
                              <span class="invalid-feedback">{{ jenisPerjanjian[0] == 'H' ? errors.first("strata_jadual_aksesori") : '' }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer text-right">
                      <button class="btn btn-primary" type="button" @click.prevent="setPembeliUtama(jenisPerjanjian[0])" :disabled="saving">
                        Simpan
                      </button>
                    </div>
                  </div>
                  <p class="alert alert-warning mb-5" v-show="!openJualan">Tambah maklumat calon pembeli untuk mengemaskini
                    maklumat jualan.</p>
              <!-- </div> -->
            </section>
            <section v-show="openPajakan">
              <h4 class="mt-2">MAKLUMAT PAJAKAN PERSENDIRIAN</h4>
              <p>Sekiranya pembeli mempunyai surat ikatan amanah, sila isi semua maklumat dibawah.</p>
              <div class="card mb-3" v-show="openJualan">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div class="form-group row">
                        <label class="col-6 col-md-4 col-form-label">Tarikh Ikatan Amanah</label>
                        <div class="col-6 col-md-8">
                          <datepicker type="text" v-model="formPajakan.tarikh_ikatan_amanah"></datepicker>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div class="form-group row">
                        <label class="col-6 col-md-4 col-form-label">Surat Ikatan Amanah</label>
                        <div class="col-6 col-md-8">
                          <file-upload
                            type="file" accept="application/pdf"
                            name="doc_ikatan_amanah"
                            class="form-control w-100"
                            :doc="formPajakan.doc_ikatan_amanah"
                            :class="{'is-invalid': errors.first(`doc_ikatan_amanah`)}"
                            @uploadwithcallback="({file, callback}) => uploadDoc('doc_surat_ikatan_amanah', file, callback)"
                            @remove="removeDoc('doc_surat_ikatan_amanah')"
                          />
                          <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6">
                      <div class="form-group row">
                        <label class="col-6 col-md-4 col-form-label">Tempoh Pajakan</label>
                        <div class="col-6 col-md-8">
                          <div class="input-group">
                            <input type="number" class="form-control" name="tempoh_pajakan"
                                   v-model="formPajakan.tempoh_pajakan" placeholder="99">
                            <div class="input-group-append">
                              <span class="input-group-text">Tahun</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer text-right">
                  <button class="btn btn-primary" type="button" @click.prevent="setPajakan" :disabled="saving">Simpan
                  </button>
                </div>
              </div>
              <p class="alert alert-warning mb-5" v-show="!openJualan">Tambah maklumat calon pembeli untuk mengemaskini
                maklumat pajakan.</p>
            </section>
            <section>
              <h4 class="card-title mt-2">PERJANJIAN JUAL BELI</h4>
              <div class="card mb-3" v-show="openSPJB">
                <div class="card-body">
                  <div class="d-flex justify-content-center my-3">
                    <button type="button" @click.prevent="janaProcess" class="btn btn-danger">Jana Perjanjian Jual Beli</button>
                    <!-- <button type="button" class="btn btn-danger" style="cursor: not-allowed;opacity: 0.65" v-show="!buttonJanaSPJB"  >Jana Perjanjian Jual Beli</button> -->
                  </div>
                </div>
              </div>
              <p class="alert alert-warning mb-5" v-show="!openSPJB">Tambah maklumat calon pembeli dan kemaskini
                maklumat jualan untuk menjana Perjanjian Jual Beli.</p>
            </section>
            <section>
              <h4 class="card-title mt-2">SENARAI BAKAL PEMBELI TERDAHULU</h4>
              <table class="table table-sm">
                <thead class="bg-secondary ">
                <tr>
                  <th>Jenis Pemilikan</th>
                  <th>Nama / Syarikat</th>
                  <th>No. KP / Passport / SSM</th>
                  <th>Bangsa</th>
                  <th>Negara</th>
                  <th nowrap width="1%">Status</th>
                  <th nowrap width="15%">Sebab Batal</th>
                  <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                <template v-if="pembeli_rs_batal.length">
                  <tr v-for="(row, index) in pembeli_rs_batal" :key="`pembeli-${index}`">
                    <td>{{
                        optional(kodJenisPemilikanRs.find(item => row.kod_jenis_pemilikan_id == item.id)).keterangan
                      }}
                    </td>
                    <td>{{ row.nama_pembeli }}</td>
                    <td>{{ row.no_kp }}</td>
                    <td>{{ optional(kodKaumRs.find(item => row.kod_bangsa_id == item.id)).keterangan }}</td>
                    <td>{{ optional(kodNegaraRs.find(item => row.kod_negara_id == item.id)).keterangan }}</td>
                    <td nowrap="">{{ row.status_pembeli }}</td>
                    <td nowrap="" v-html="row.sebab_pembatalan"></td>
                    <td>
                      <span v-if="row.updated_by === 'API' " class="badge badge-info px-3 mx-2"
                            :title="'Dikemaskini oleh API pada '+ row.updated_at">API</span>
                    </td>
                  </tr>
                </template>
                <tr v-else>
                  <td colspan="99">
                    <div class="alert  alert-warning">Tiada maklumat bakal pembeli terdahulu.</div>
                  </td>
                </tr>
                </tbody>
              </table>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: [ "$validator" ],
  name: "PembeliModal",

  mounted() {
    vmodal.register(this.modalId, { open: this.onOpen, close: this.onClose });
  },

  props: {
    pemajuan: Object,
    kewangan: Object,
    kodNegeri: Array,
    kodDaerah: Array,
    kodBandar: Array,
    kodKaumRs: Array,
    kodNegaraRs: Array,
    kodBiayaPembeliRs: Array,
    peguamRs: Array,
    jenisPerjanjian: Array,
    kodJenisPemilikanRs: {
      type: Array,
      default: () => ([
        { id: "I", keterangan: "INDIVIDU" },
        { id: "ST", keterangan: "SYARIKAT TEMPATAN" },
        { id: "SA", keterangan: "SYARIKAT ASING" },
      ]),
    },
    kodJenisWakilKuasa: Array,
    mahkamahTinggi: Array,
    poskodRs: Array,
  },

  data() {
    return {
      showInisiatifKerajaanError: false,
      modalId: "pembeli",
      loading: false,
      saving: false,
      unit: {},
      pembeli_id: null,
      pembeli_rs: [],
      pembeli_rs_batal: [],
      form: {},
      formJualan: {},
      unit_pertama: null,
      errorTimeout: null,
      response: () => {
      },
      openPajakan: false,
      openBorang: true,
      openJualan: null,
      openStrata: false,
      openSPJB: false,
      buttonJanaSPJB: false,
      action: "Daftar",
      openBandar: true,
      disabled: 0,
      urlTambahPeguam: "{{url('pemaju/home/profil#maklumatPeguam')}}",
      defaultWarganegara: "Malaysia",
      listBangsa: [],
      kerakyatanRs: [
        { id: "warganegara", keterangan: "Warganegara" },
        { id: "bukan_warganegara", keterangan: "Bukan Warganegara" },
        { id: "pemastautin_tetap", keterangan: "Pemastautin Tetap" },
      ],
      listKerakyatan: [],
      listNegara: this.kodNegaraRs.sort((a, b) => a.keterangan - b.keterangan),
      KOD_NEGARA_MALAYSIA: 458,
      listJenisWakilKuasa: this.kodJenisWakilKuasa.sort((a, b) => a.order - b.order),
      formWakilkuasa: {},
      formPenandatanganSyarikat: []
    };
  },

  methods: {
    onOpen(data, callback) {
      this.unit = JSON.parse(JSON.stringify(data.unit));
      this.unit_pertama = data.unit_pertama;
      this.form = {
        parent_id: null,
        nama_pembeli: null,
        no_kp: null,
        no_tel: null,
        alamat1: null,
        alamat2: null,
        alamat3: null,
        kod_negeri_id: null,
        kod_daerah_id: null,
        kod_bandar_id: null,
        kod_bangsa_id: null,
        kod_negara_id: null,
        peguam_id_pembeli: null,
        nama_peguam_pembeli: null,
        no_tel_peguam_pembeli: null,
        alamat1_peguam_pembeli: null,
        alamat2_peguam_pembeli: null,
        alamat3_peguam_pembeli: null,
        poskod: null,
        kod_negeri_id_peguam_pembeli: null,
        kod_daerah_id_peguam_pembeli: null,
        kod_bandar_id_peguam_pembeli: null,
        poskod_peguam_pembeli: null,
        peguam_id_pemaju: null,
        nama_peguam_pemaju: null,
        no_tel_peguam_pemaju: null,
        alamat1_peguam_pemaju: null,
        alamat2_peguam_pemaju: null,
        alamat3_peguam_pemaju: null,
        kod_negeri_id_peguam_pemaju: null,
        kod_daerah_id_peguam_pemaju: null,
        kod_bandar_id_peguam_pemaju: null,
        poskod_peguam_pemaju: null,
        kod_jenis_pemilikan_id: null,
        peguam_id_stakeholder: null,
        warganegara: null,
        no_askar: null,
        warganegara_text: this.defaultWarganegara,
        warganegara_id: null,
      };

      this.formPajakan = {
        tarikh_ikatan_amanah: this.unit.tarikh_ikatan_amanah,
        tempoh_pajakan: this.unit.tempoh_pajakan,
        doc_ikatan_amanah: this.unit.doc_surat_ikatan_amanah,
      };
      this.formJualan = {
        no_kp: this.unit.no_kp,
        rebat_pemaju: this.unit.rebat_pemaju,
        inisiatif_kerajaan: this.unit.inisiatif_kerajaan,
        harga_diskaun_kuotabumi: this.unit.harga_diskaun_kuotabumi,
        harga_spjb: this.unit.harga_spjb ? this.unit.harga_spjb : 0,
        harga_mps: this.unit.harga_mps ? this.unit.harga_mps : 0,
        tarikh_spjb: this.unit.tarikh_spjb,
        kod_biaya_pembeli_id: this.unit.kod_biaya_pembeli_id,
        strata_menara: this.unit.strata_menara,
        strata_tingkat: this.unit.strata_tingkat,
        strata_petak_tanah: this.unit.strata_petak_tanah,
        strata_jp: this.unit.strata_jp,
        strata_petak_aksesori: this.unit.strata_petak_aksesori,
        strata_jadual_aksesori: this.unit.strata_jadual_aksesori,
      };

      this.formWakilkuasa = {
        wakilkuasa_ada: null,
        kod_jenis_wakil_kuasa_id: null,
        wakilkuasa_nama: null,
        wakilkuasa_no_kp: null,
        wakilkuasa_no_pendaftaran_firma: null,
        wakilkuasa_nama_penandatangan: null,
        wakilkuasa_no_pendaftaran: null,
        wakilkuasa_tarikh_pendaftaran: null,
        wakilkuasa_mahkamah_id: null,
      }

      this.formPenandatanganSyarikat = [
        {
          nama_pengarah: null,
          no_kp: null,
          no_tel: null,
          emel: null
        },
        {
          nama_pengarah: null,
          no_kp: null,
          no_tel: null,
          emel: null
        }
      ]

      if (this.pemajuan.kod_jenis_jualan_milikan_id == 2) {
        this.openPajakan = true;
      }

      this.loading = true;
      this.response = callback;
      this.openBorang = false;

      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}`)
        .then((response) => {
          this.pembeli_rs = response.data.calon;
          this.pembeli_rs_batal = response.data.calon_batal;
          if (this.pembeli_rs.length > 0) {
            this.openJualan = true;
            this.openStrata = true;
          } else {
            this.openJualan = false;
          }
          if (this.pembeli_rs.length > 0) {
            if (this.pembeli_rs[ 0 ][ "status_pembeli" ] == "PEMBELI") {
              this.openSPJB = true;
              this.action = null;
            } else {
              this.openSPJB = false;
              this.action = "Daftar";
            }
          } else {
            this.openSPJB = false;
            this.action = "Daftar";
          }

        })
        .catch((e) => {
          reject(e);
        })
        .then(() => {
          this.loading = false;

        });


    },

    onClose() {
    },

    jenisPemilikanChange() {
      if(this.form.kod_jenis_pemilikan_id == null) {
        this.listKerakyatan = []
        this.listBangsa = []
      } else {
        this.listKerakyatan = this.kerakyatanRs
        this.listBangsa = this.kodKaumRs.sort((a, b) => a.order - b.order)
      }
      switch (this.form.kod_jenis_pemilikan_id) {
        case "ST": // Syarikat Tempatan
          this.form.kerakyatan = "TIDAK BERKAITAN";
          this.form.kod_bangsa_id = "TIDAK BERKAITAN";
          this.form.kod_negara_id = 458; // malaysia
          break;
        case "SA": // Syarikat Asing
          this.form.kerakyatan = "TIDAK BERKAITAN";
          this.form.kod_bangsa_id = "TIDAK BERKAITAN";
          this.form.kod_negara_id = null;
          break;
        default: // Individu
          this.form.kod_bangsa_id = null;
          this.form.kod_negara_id = null;
          this.form.kerakyatan = null;
          break;
      }
    },

    kerakyatanChange() {
      const kerakyatan = this.form.kerakyatan
      if(this.form.kod_jenis_pemilikan_id == null) {
        this.listBangsa = []
        return
      }
      if(kerakyatan == null || this.form.kod_jenis_pemilikan_id != 'I') {
        this.listBangsa = this.kodKaumRs.sort((a, b) => a.order - b.order)
        return
      }

      // filter by kerakyatan
      // this.listNegara = this.kodNegaraRs.filter((negara) => {
        // if(this.form.kod_jenis_pemilikan_id == 'I' && kerakyatan == `bukan_warganegara`) {
        //   return negara.id != this.KOD_NEGARA_MALAYSIA
        // }
        // return negara.id
      // })

      this.listBangsa = this.kodKaumRs.filter((kaum) => {
        if(this.form.kod_jenis_pemilikan_id == 'I' && kerakyatan == `warganegara`) {
          this.form.kod_bangsa_id = null // set default bangsa to null
          this.form.warganegara_id = this.KOD_NEGARA_MALAYSIA.toString()
          return kaum.id != 'W'
        } else {
          this.form.kod_bangsa_id = 'W'
          this.form.warganegara_id = null
          return kaum.id == 'W'
        }
      });
    },

    onNegeriChange(event) {
      if (event.target.value == 98 || event.target.value == 99) {
        this.form.kod_bangsa_id = "W";
        this.openBandar = false;
      } else {
        // if (this.form.kod_bangsa_id == "W") {
        //   this.form.kod_bangsa_id = null;
        // }
        this.form.kod_negara_id = 458; // malaysia
        this.openBandar = true;
      }
    },


    kaumChange() {
      switch (this.form.kod_bangsa_id) {
        case "W":
          this.form.kod_negara_id = null;
          break;
        default:
          this.form.kod_negara_id = 458; // malaysia
          break;
      }
    },

    peguamPembeliChange() {
      if (this.form[ "peguam_id_pembeli" ] !== null) {
        var peguam = this.peguamRs.filter(item => (item.id == this.form[ "peguam_id_pembeli" ]));

        this.form.nama_firma_guaman_pembeli = peguam[ 0 ].nama_firma_guaman;
        this.form.nama_peguam_pembeli = peguam[ 0 ].nama_peguam;
        this.form.no_tel_peguam_pembeli = peguam[ 0 ].no_telefon;
        this.form.alamat1_peguam_pembeli = peguam[ 0 ].alamat1;
        this.form.alamat2_peguam_pembeli = peguam[ 0 ].alamat2;
        this.form.alamat3_peguam_pembeli = peguam[ 0 ].alamat3;
        this.form.poskod_peguam_pembeli = peguam[ 0 ].poskod;
        this.form.kod_negeri_id_peguam_pembeli = peguam[ 0 ].kod_negeri_id;
        this.form.kod_daerah_id_peguam_pembeli = peguam[ 0 ].kod_daerah_id;
        this.form.kod_bandar_id_peguam_pembeli = peguam[ 0 ].kod_bandar_id;
      } else {
        this.form.nama_firma_guaman_pembeli = null;
        this.form.nama_peguam_pembeli = null;
        this.form.no_tel_peguam_pembeli = null;
        this.form.alamat1_peguam_pembeli = null;
        this.form.alamat2_peguam_pembeli = null;
        this.form.alamat3_peguam_pembeli = null;
        this.form.poskod_peguam_pembeli = null;
        this.form.kod_negeri_id_peguam_pembeli = null;
        this.form.kod_daerah_id_peguam_pembeli = null;
        this.form.kod_bandar_id_peguam_pembeli = null;
      }
    },

    peguamPemajuChange() {
      if (this.form[ "peguam_id_pemaju" ] !== null) {
        var peguam = this.peguamRs.filter(item => (item.id == this.form[ "peguam_id_pemaju" ]));

        this.form.nama_firma_guaman_pemaju = peguam[ 0 ].nama_firma_guaman;
        this.form.nama_peguam_pemaju = peguam[ 0 ].nama_peguam;
        this.form.no_tel_peguam_pemaju = peguam[ 0 ].no_telefon;
        this.form.alamat1_peguam_pemaju = peguam[ 0 ].alamat1;
        this.form.alamat2_peguam_pemaju = peguam[ 0 ].alamat2;
        this.form.alamat3_peguam_pemaju = peguam[ 0 ].alamat3;
        this.form.poskod_peguam_pemaju = peguam[ 0 ].poskod;
        this.form.kod_negeri_id_peguam_pemaju = peguam[ 0 ].kod_negeri_id;
        this.form.kod_daerah_id_peguam_pemaju = peguam[ 0 ].kod_daerah_id;
        this.form.kod_bandar_id_peguam_pemaju = peguam[ 0 ].kod_bandar_id;
      } else {
        this.form.nama_firma_guaman_pemaju = null;
        this.form.nama_peguam_pemaju = null;
        this.form.no_tel_peguam_pemaju = null;
        this.form.alamat1_peguam_pemaju = null;
        this.form.alamat2_peguam_pemaju = null;
        this.form.alamat3_peguam_pemaju = null;
        this.form.poskod_peguam_pemaju = null;
        this.form.kod_negeri_id_peguam_pemaju = null;
        this.form.kod_daerah_id_peguam_pemaju = null;
        this.form.kod_bandar_id_peguam_pemaju = null;
      }
    },

    peguamStakeholderChange() {
      if (this.form[ "peguam_id_stakeholder" ] !== null) {
        var peguam = this.peguamRs.filter(item => (item.id == this.form[ "peguam_id_stakeholder" ]));

        this.form.nama_firma_guaman_stakeholder = peguam[ 0 ].nama_firma_guaman;
        this.form.nama_peguam_stakeholder = peguam[ 0 ].nama_peguam;
        this.form.no_tel_peguam_stakeholder = peguam[ 0 ].no_telefon;
        this.form.alamat1_peguam_stakeholder = peguam[ 0 ].alamat1;
        this.form.alamat2_peguam_stakeholder = peguam[ 0 ].alamat2;
        this.form.alamat3_peguam_stakeholder = peguam[ 0 ].alamat3;
        this.form.poskod_peguam_stakeholder = peguam[ 0 ].poskod;
        this.form.kod_negeri_id_peguam_stakeholder = peguam[ 0 ].kod_negeri_id;
        this.form.kod_daerah_id_peguam_stakeholder = peguam[ 0 ].kod_daerah_id;
        this.form.kod_bandar_id_peguam_stakeholder = peguam[ 0 ].kod_bandar_id;
      } else {
        this.form.nama_firma_guaman_stakeholder = null;
        this.form.nama_peguam_stakeholder = null;
        this.form.no_tel_peguam_stakeholder = null;
        this.form.alamat1_peguam_stakeholder = null;
        this.form.alamat2_peguam_stakeholder = null;
        this.form.alamat3_peguam_stakeholder = null;
        this.form.poskod_peguam_stakeholder = null;
        this.form.kod_negeri_id_peguam_stakeholder = null;
        this.form.kod_daerah_id_peguam_stakeholder = null;
        this.form.kod_bandar_id_peguam_stakeholder = null;
      }
    },

    bukaBorang() {
      this.action = "Daftar";
      this.form = {
        parent_id: null,
        nama_pembeli: null,
        no_kp: null,
        no_tel: null,
        alamat1: null,
        alamat2: null,
        alamat3: null,
        kod_negeri_id: null,
        kod_daerah_id: null,
        kod_bandar_id: null,
        kod_bangsa_id: null,
        kod_negara_id: this.KOD_NEGARA_MALAYSIA,
        poskod: null,
        peguam_id_pembeli: null,
        nama_firma_guaman_pembeli: null,
        nama_peguam_pembeli: null,
        no_tel_peguam_pembeli: null,
        alamat1_peguam_pembeli: null,
        alamat2_peguam_pembeli: null,
        alamat3_peguam_pembeli: null,
        kod_negeri_id_peguam_pembeli: null,
        kod_daerah_id_peguam_pembeli: null,
        kod_bandar_id_peguam_pembeli: null,
        poskod_peguam_pembeli: null,
        nama_firma_guaman_pemaju: null,
        peguam_id_pemaju: null,
        nama_peguam_pemaju: null,
        no_tel_peguam_pemaju: null,
        alamat1_peguam_pemaju: null,
        alamat2_peguam_pemaju: null,
        alamat3_peguam_pemaju: null,
        kod_negeri_id_peguam_pemaju: null,
        kod_daerah_id_peguam_pemaju: null,
        kod_bandar_id_peguam_pemaju: null,
        poskod_peguam_pemaju: null,
        peguam_id_stakeholder: null,
        nama_firma_guaman_stakeholder: null,
        nama_peguam_stakeholder: null,
        no_tel_peguam_stakeholder: null,
        alamat1_peguam_stakeholder: null,
        alamat2_peguam_stakeholder: null,
        alamat3_peguam_stakeholder: null,
        kod_negeri_id_peguam_stakeholder: null,
        kod_daerah_id_peguam_stakeholder: null,
        kod_bandar_id_peguam_stakeholder: null,
        poskod_peguam_stakeholder: null,
        kod_jenis_pemilikan_id: null,
        warganegara: null,
        kerakyatan: null,
        no_askar: null,
        warganegara_text: this.defaultWarganegara,
        warganegara_id: null
      };

      this.resetFormWakilkuasa();
      return this.openBorang = true;
    },

    tutupBorang() {
      if (this.openSPJB) {
        this.action = null;
      } else {
        this.action = "Daftar";
      }

      return this.openBorang = false;
    },

    filteredDaerah(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerah.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandar(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodBandar.filter(item => (item.kod_negeri_id == kod_negeri_id));
    },
    daftar() {
      // Promise.all([
      //   this.$validator.validate('parent_id'),
      //   this.$validator.validate('kod_jenis_pemilikan_id'),
      //   this.$validator.validate('nama_pembeli'),
      //   this.$validator.validate('no_kp'),
      //   this.$validator.validate('alamat1'),
      //   this.$validator.validate('kod_negeri_id'),
      //   this.$validator.validate('kod_daerah_id'),
      //   this.$validator.validate('kod_bandar_id'),
      //   this.$validator.validate('kod_bangsa_id'),
      //   this.$validator.validate('kod_negara_id'),
      //   this.$validator.validate('poskod'),
      //   this.$validator.validate('negeri'),
      //   this.$validator.validate('no_tel'),
      //   this.$validator.validate('nama_pembeli'),
      //   this.$validator.validate('nama_pembeli'),
      // ]).then((results) => {
      //   return results.every(t => t);
      // })
      this.$validator.validateAll()
        .then((passes) => {
          // if (!passes) {
          //   alert("yes");
          //   return;
          // }
          const form = {
            ...this.form,
            // ...this.formWakilkuasa,
            'penandatangan_syarikat': this.formPenandatanganSyarikat
          }

          this.saving = true;
          axios.post(`/pemaju/kuota-bumi/pembeli/${this.unit.id}`, form)
            .then(({ data }) => {
              this.pembeli_rs = data.pembeli_rs;
              this.pembeli_rs_batal = data.pembeli_rs_batal;
              this.response(data.unit);
              this.openBorang = false;
              if (this.pembeli_rs.length > 0) {
                this.openJualan = true;
              } else {
                this.openJualan = false;
              }
              swal.fire(
                "Berjaya!",
                "Maklumat calon pembeli berjaya didaftar.",
                "success",
              );
            })
            .catch(function (error) {
              console.log(error.response.data.errors);
              swal.fire(
                "Tidak Berjaya!",
                "Sila Lengkapkan Borang Pendaftaran Calon Pembeli",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        });
    },

    kemaskiniStatusPembeli(index, status_pembeli) {

      const pembeli = this.pembeli_rs[ index ];
      swal.fire({
        title: "Adakah anda pasti?",
        text: "Maklumat ini akan dibatalkan dari senarai maklumat pembeli.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0069d9",
        cancelButtonColor: "#d90014",
        confirmButtonText: "Teruskan",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.value) {
          axios.put(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/${pembeli.id}/status`, { status_pembeli })
            .then(({ data }) => {
              if (data.pembeli) {
                this.pembeli_rs = data.pembeli;
                if (status_pembeli == "BATAL SPJB" || status_pembeli == "BATAL") {
                  if (this.pembeli_rs.length == 0) {
                    this.openJualan = false;
                    this.openSPJB = false;
                  }
                }
              }

              if (data.pembeli_batal) {
                this.pembeli_rs_batal = data.pembeli_batal;
              }

              this.unit = data.unit;

              this.response(JSON.parse(JSON.stringify(this.unit)));
              swal.fire(
                "Berjaya!",
                "Maklumat pembeli berjaya dibatalkan.",
                "success",
              );
            })
            .catch(() => {
              swal.fire(
                "Tidak Berjaya!",
                "Maklumat pembeli tidak berjaya dibatalkan.",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        }
      });

    },

    batalPembeli() {

      swal.fire({
        title: "Anda pasti membatalkan pembeli?",
        text: "Sistem akan membatalkan semua pembeli yang telah didaftarkan bagi unit ini. Penambahan calon, kemaskini maklumat jualan dan janaan SPJB perlu dilakukan sekali lagi.",
        icon: "warning",
        input: "textarea",
        inputLabel: "Sebab Batal",
        inputPlaceholder: "Nyatakan sebab pembatalan",
        inputValidator: (value) => {
          if (!value) {
            return "Sila nyatakan alasan pembatalan!";
          }
        },
        showCancelButton: true,
        confirmButtonColor: "#0069d9",
        cancelButtonColor: "#d90014",
        confirmButtonText: "Teruskan",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.value) {
          var sebab_pembatalan = result.value;
          axios.put(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/batalSPJB`, { sebab_pembatalan })
            .then(({ data }) => {
              this.unit = data.unit;
              this.response(JSON.parse(JSON.stringify(this.unit)));
              this.pembeli_rs = data.pembeli;
              this.pembeli_rs_batal = data.pembeli_batal;
              if (this.pembeli_rs.length > 0) {
                this.openJualan = true;
                this.openStrata = true;
              } else {
                this.openJualan = false;
              }
              if (this.pembeli_rs.length > 0) {
                if (this.pembeli_rs[ 0 ][ "status_pembeli" ] == "PEMBELI") {
                  this.openSPJB = true;
                  this.action = null;
                } else {
                  this.openSPJB = false;
                }
              } else {
                this.openSPJB = false;
              }
              swal.fire(
                "Berjaya!",
                "Pembeli berjaya dibatalkan.",
                "success",
              );
            })
            .catch(() => {
              swal.fire(
                "Tidak Berjaya!",
                "Pembeli tidak berjaya dibatalkan.",
                "warning",
              );
            });
        }
      });

    },

    // kemaskiniJualan(){
    //   if(this.openBorang = true){
    //     this.openBorang = false;
    //   }
    //   this.openJualan = true;
    // },
    setPajakan() {
      axios.put(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/pajakan`, this.formPajakan)
        .then(() => {
          swal.fire(
            "Berjaya!",
            "Maklumat pajakan berjaya dikemaskini.",
            "success",
          );
        })
        .catch((error) => {
          if (error.response.status === 422) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila lengkapkan maklumat jualan.",
              "error",
            );
          }
        });
    },

    notifPembeliUtamaWarning(){
      swal.fire(
        "Harap Maaf",
        "Sila lengkapkan Maklumat Petak Strata",
        "warning",
      );
    },

    setPembeliUtama(type) {
      this.$validator.validateAll()
        .then((passes) => {
          // if (!passes) {
          //   alert(type);
          //   return;
          // }


          if (type === "H") {
            if (!this.formJualan.strata_petak_tanah) {
              this.notifPembeliUtamaWarning();
              return;
            }
            if (!this.formJualan.strata_menara) {
              this.notifPembeliUtamaWarning();
              return;
            }
            if (!this.formJualan.strata_tingkat) {
              this.notifPembeliUtamaWarning();
              return;
            }
            if (!this.formJualan.strata_jp) {
              this.notifPembeliUtamaWarning();
              return;
            }
            if (!this.formJualan.strata_petak_aksesori) {
              this.notifPembeliUtamaWarning();
              return;
            }
            if (!this.formJualan.strata_jadual_aksesori) {
              this.notifPembeliUtamaWarning();
              return;
            }
          }

          if (this.hargaSPJB > this.unit.harga_jualan) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila masukkan amaun Harga Rumah sama atau lebih rendah dari amaun Harga Jualan seperti yang telah didaftarkan.",
              "error",
            );
            return false;
          }

          if (this.unit.harga_jualan <= 300000) {
            if (this.pemajuan.kewangan && this.pemajuan.kewangan.status_simen === 1) {
              if (this.formJualan.inisiatif_kerajaan < 1000) {
                swal.fire(
                  "Tidak Berjaya!",
                  "Minimum amaun bagi Inisiatif Kerajaan adalah RM1000",
                  "error",
                );
                return false;
              }
            }
          }

          if (this.formJualan.harga_mps == 0) {
            swal.fire(
              "Tidak Berjaya!",
              "Sila masukkan amaun Harga Meter Persegi",
              "error",
            );
            return false;
          }

          swal.fire({
            title: "Adakah anda pasti?",
            text: "Dengan meneruskan, anda tidak boleh menambah nama calon / penama lain.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#0069d9",
            cancelButtonColor: "#d90014",
            confirmButtonText: "Teruskan",
            cancelButtonText: "Batal",
          }).then((result) => {
            if (result.value) {
              this.saving = true;

              axios.put(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/pembeli-utama`, this.formJualan)
                .then(({data}) => {
                  this.unit = data.unit;
                  this.response(JSON.parse(JSON.stringify(this.unit)));
                  this.pembeli_rs = data.pembeli;
                  swal.fire(
                    "Berjaya!",
                    "Maklumat jualan berjaya dikemaskini.",
                    "success",
                  );
                  if (data.count_pembeli < 5) {
                    if (this.unit_pertama == "") {
                      location.reload(true);
                    }
                  }
                })
                .catch((error) => {
                  if (error.response.status === 422) {
                    swal.fire(
                      "Tidak Berjaya!",
                      "Sila lengkapkan maklumat jualan.",
                      "error",
                    );
                  }
                })
                .then(() => {
                  this.saving = false;
                  this.openSPJB = true;
                });
            }
          });
        });
    },

    // setPembeliUtama() {
    //   if (this.hargaSPJB > this.unit.harga_jualan) {
    //     swal.fire(
    //       "Tidak Berjaya!",
    //       "Sila masukkan amaun Harga Rumah sama atau lebih rendah dari amaun Harga Jualan seperti yang telah didaftarkan.",
    //       "error",
    //     );
    //     return false;
    //   }
    //   if (this.unit.harga_jualan <= 300000) {
    //     if (this.pemajuan.kewangan && this.pemajuan.kewangan.status_simen === 1) {
    //       if (this.formJualan.inisiatif_kerajaan < 1000) {
    //         swal.fire(
    //           "Tidak Berjaya!",
    //           "Minimum amaun bagi Inisiatif Kerajaan adalah RM1000",
    //           "error",
    //         );
    //         return false;
    //       }
    //     }
    //   }
    //
    //   if(this.formJualan.harga_mps == 0){
    //     swal.fire(
    //       "Tidak Berjaya!",
    //       "Sila masukkan amaun Harga Meter Persegi",
    //       "error",
    //     );
    //     return false;
    //   }
    //
    //   swal.fire({
    //     title: "Adakah anda pasti?",
    //     text: "Dengan meneruskan, anda tidak boleh menambah nama calon / penama lain.",
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#0069d9",
    //     cancelButtonColor: "#d90014",
    //     confirmButtonText: "Teruskan",
    //     cancelButtonText: "Batal",
    //   }).then((result) => {
    //     if (result.value) {
    //       this.saving = true;
    //
    //       axios.put(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/pembeli-utama`, this.formJualan)
    //         .then(({ data }) => {
    //           this.unit = data.unit;
    //           this.response(JSON.parse(JSON.stringify(this.unit)));
    //           this.pembeli_rs = data.pembeli;
    //           swal.fire(
    //             "Berjaya!",
    //             "Maklumat jualan berjaya dikemaskini.",
    //             "success",
    //           );
    //           if (data.count_pembeli < 5) {
    //             if (this.unit_pertama == "") {
    //               location.reload(true);
    //             }
    //           }
    //         })
    //         .catch((error) => {
    //           if (error.response.status === 422) {
    //             swal.fire(
    //               "Tidak Berjaya!",
    //               "Sila lengkapkan maklumat jualan.",
    //               "error",
    //             );
    //           }
    //         })
    //         .then(() => {
    //           this.saving = false;
    //           this.openSPJB = true;
    //         });
    //     }
    //   });
    // },

    editPembeli(pembeli_id) {
      this.openBorang = true;
      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/${pembeli_id}/show`)
        .then(async ({ data }) => {
          await this.setFormPembeli(data)
          await this.setFormWakilkuasa(data)
          await this.setFormPenandatanganSyarikat(data)

          if (this.openSPJB) {
          //   this.disabled = 1;
          //   this.action = null;
          // } else {
            this.disabled = 0;
            this.action = "Kemaskini";
          }
          if (data.pembeli.kod_negeri_id == 98) {
            this.openBandar = false;
          } else {
            this.openBandar = true;
          }
          this.form.kod_bangsa_id = data.pembeli.kod_bangsa_id;
          this.formWakilkuasa.wakilkuasa_no_kp = data.pembeli.wakil_kuasa_pembeli?.no_kp || null;
          this.formWakilkuasa.wakilkuasa_nama = data.pembeli.wakil_kuasa_pembeli?.nama || null;
          this.form.warganegara_id = data.pembeli.warganegara_id;
          this.form.kod_negara_id = data.pembeli.kod_negara_id;
          if(data.pembeli.kod_jenis_pemilikan_id == 'I') {
            this.setKerakyatan(data.pembeli);
          } else {
            // set bangsa and kerakyatan for syarikat asing and tempatan
            this.form.kerakyatan = 'TIDAK BERKAITAN';
            this.form.kod_bangsa_id = 'TIDAK BERKAITAN';
          }

          // set daerah and bandar
          if(['14', '15', '16'].includes(data.pembeli.kod_negeri_id)) {
            this.form.kod_daerah_id = null;
            this.form.kod_bandar_id = null;
          }

        })
        .catch((e) => {
          reject(e);
        })
        .then(() => {
          this.loading = false;
        });
      this.openBorang = true;
    },

    viewPembeli(pembeli_id) {
      this.openBorang = false;
      axios.get(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/${pembeli_id}/show`)
        .then(async ({ data }) => {
          await this.setFormPembeli(data)
          await this.setFormWakilkuasa(data)
          await this.setFormPenandatanganSyarikat(data)

          if (this.openSPJB) {
            this.disabled = 1;
            this.action = null;
          } else {
            this.disabled = 0;
            this.action = "Kemaskini";
          }
          if (data.pembeli.kod_negeri_id == 98) {
            this.openBandar = false;
          } else {
            this.openBandar = true;
          }
          this.form.kod_bangsa_id = data.pembeli.kod_bangsa_id;
          this.formWakilkuasa.wakilkuasa_no_kp = data.pembeli.wakil_kuasa_pembeli?.no_kp || null;
          this.formWakilkuasa.wakilkuasa_nama = data.pembeli.wakil_kuasa_pembeli?.nama || null;
          this.form.warganegara_id = data.pembeli.warganegara_id;
          this.form.kod_negara_id = data.pembeli.kod_negara_id;
          if(data.pembeli.kod_jenis_pemilikan_id == 'I') {
            this.setKerakyatan(data.pembeli);
          } else {
            // set bangsa and kerakyatan for syarikat asing and tempatan
            this.form.kerakyatan = 'TIDAK BERKAITAN';
            this.form.kod_bangsa_id = 'TIDAK BERKAITAN';
          }

          // set daerah and bandar
          if(['14', '15', '16'].includes(data.pembeli.kod_negeri_id)) {
            this.form.kod_daerah_id = null;
            this.form.kod_bandar_id = null;
          }

        })
        .catch((e) => {
          reject(e);
        })
        .then(() => {
          this.loading = false;
        });
      this.openBorang = true;
    },

    updatePembeli(pembeli_id) {
      this.$validator.validateAll()
        .then(passes => {
          // if (!passes) {
          //   return;
          // }
          const form = {
            ...this.form,
            // ...this.formWakilkuasa,
            'penandatangan_syarikat': this.formPenandatanganSyarikat
          }
          this.saving = true;
          axios.post(`/pemaju/kuota-bumi/pembeli/${this.unit.id}/${pembeli_id}/update`, form)
            .then(({ data }) => {
              this.pembeli_rs = data.pembeli_rs;

              swal.fire(
                "Berjaya!",
                "Maklumat calon pembeli berjaya dikemaskini.",
                "success",
              );
            })
            .catch(function (error) {
              swal.fire(
                "Tidak Berjaya!",
                "Sila lengkapkan borang pendaftaran calon pembeli",
                "warning",
              );
            })
            .then(() => {
              this.saving = false;
            });
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    uploadDoc(key, file, clear) {
      if (!this.formPajakan) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append("doc", file, file.name);

      // save it
      axios.post(`/pemaju/kuota-bumi/unit/${this.unit.id}/dokumen/${key}`, formData)
        .then((response) => {
          this.unit = JSON.parse(JSON.stringify(response.data.unit));
          this.formPajakan.doc_ikatan_amanah = this.unit.doc_surat_ikatan_amanah;
        })
        .catch((error) => {
          this.$hError(error);
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.formPajakan) {
        return;
      }

      axios.delete(`/pemaju/kuota-bumi/unit/${this.unit.id}/dokumen/${key}`)
        .then((response) => {
          this.unit = JSON.parse(JSON.stringify(response.data.unit));
          this.formPajakan.doc_ikatan_amanah = this.unit.doc_surat_ikatan_amanah;
        })
        .catch(() => {

        });
    },

    close() {
      vmodal.close(this.modalId);
    },

    janaProcess() {
      if(this.isButtonJanaDisabled) {
        swal.fire(
          'Harap Maaf!',
          'Sila lengkapkan maklumat unit terlebih dahulu.',
        )
        return
      }

      if(this.isKuotaBumiTiadaSkim){
        swal.fire(
            'Ini Unit Kuota Bumiputera!',
            'Sila masukkan amaun Diskaun Lot Bumiputera',
          )
        return
      }

      return window.open(`/pemaju/kuota-bumi/pjb/${this.unit.id}`, '_blank');
    },

    setFormPembeli(data) {
      this.pembeli_id = data.pembeli.id;
      this.form = {
        nama_pembeli: data.pembeli.nama_pembeli,
        no_kp: data.pembeli.no_kp,
        no_tel: data.pembeli.no_tel,
        alamat1: data.pembeli.alamat1,
        alamat2: data.pembeli.alamat2,
        alamat3: data.pembeli.alamat3,
        kod_negeri_id: data.pembeli.kod_negeri_id,
        kod_daerah_id: data.pembeli.kod_daerah_id,
        kod_bandar_id: data.pembeli.kod_bandar_id,
        kod_bangsa_id: data.pembeli.kod_bangsa_id,
        kod_negara_id: data.pembeli.kod_negara_id,
        poskod: data.pembeli.poskod,
        peguam_id_pembeli: data.pembeli.peguam_id_pembeli,
        nama_firma_guaman_pembeli: data.pembeli.nama_firma_guaman_pembeli,
        nama_peguam_pembeli: data.pembeli.nama_peguam_pembeli,
        no_tel_peguam_pembeli: data.pembeli.no_tel_peguam_pembeli,
        alamat1_peguam_pembeli: data.pembeli.alamat1_peguam_pembeli,
        alamat2_peguam_pembeli: data.pembeli.alamat2_peguam_pembeli,
        alamat3_peguam_pembeli: data.pembeli.alamat3_peguam_pembeli,
        kod_negeri_id_peguam_pembeli: data.pembeli.kod_negeri_id_peguam_pembeli,
        kod_daerah_id_peguam_pembeli: data.pembeli.kod_daerah_id_peguam_pembeli,
        kod_bandar_id_peguam_pembeli: data.pembeli.kod_bandar_id_peguam_pembeli,
        poskod_peguam_pembeli: data.pembeli.poskod_peguam_pembeli,
        peguam_id_pemaju: data.pembeli.peguam_id_pemaju,
        nama_firma_guaman_pemaju: data.pembeli.nama_firma_guaman_pemaju,
        nama_peguam_pemaju: data.pembeli.nama_peguam_pemaju,
        no_tel_peguam_pemaju: data.pembeli.no_tel_peguam_pemaju,
        alamat1_peguam_pemaju: data.pembeli.alamat1_peguam_pemaju,
        alamat2_peguam_pemaju: data.pembeli.alamat2_peguam_pemaju,
        alamat3_peguam_pemaju: data.pembeli.alamat3_peguam_pemaju,
        kod_negeri_id_peguam_pemaju: data.pembeli.kod_negeri_id_peguam_pemaju,
        kod_daerah_id_peguam_pemaju: data.pembeli.kod_daerah_id_peguam_pemaju,
        kod_bandar_id_peguam_pemaju: data.pembeli.kod_bandar_id_peguam_pemaju,
        poskod_peguam_pemaju: data.pembeli.poskod_peguam_pemaju,
        peguam_id_stakeholder: data.pembeli.peguam_id_stakeholder,
        nama_firma_guaman_stakeholder: data.pembeli.nama_firma_guaman_stakeholder,
        nama_peguam_stakeholder: data.pembeli.nama_peguam_stakeholder,
        no_tel_peguam_stakeholder: data.pembeli.no_tel_peguam_stakeholder,
        alamat1_peguam_stakeholder: data.pembeli.alamat1_peguam_stakeholder,
        alamat2_peguam_stakeholder: data.pembeli.alamat2_peguam_stakeholder,
        alamat3_peguam_stakeholder: data.pembeli.alamat3_peguam_stakeholder,
        kod_negeri_id_peguam_stakeholder: data.pembeli.kod_negeri_id_peguam_stakeholder,
        kod_daerah_id_peguam_stakeholder: data.pembeli.kod_daerah_id_peguam_stakeholder,
        kod_bandar_id_peguam_stakeholder: data.pembeli.kod_bandar_id_peguam_stakeholder,
        poskod_peguam_stakeholder: data.pembeli.poskod_peguam_stakeholder,
        kod_jenis_pemilikan_id: data.pembeli.kod_jenis_pemilikan_id,
        no_askar: data.pembeli.no_askar,
        warganegara_text: this.defaultWarganegara,
      };

      // set kerakyatan
      if(data.pembeli.warganegara == null) {
        // 458 = malaysia
        if(data.pembeli.kod_negara_id == this.KOD_NEGARA_MALAYSIA) {
          this.form.kerakyatan = 'warganegara'
          this.form.warganegara_id = this.KOD_NEGARA_MALAYSIA
        }
      } else {
        this.form.kerakyatan = data.pembeli.warganegara == 1 ? 'warganegara' : data.pembeli.warganegara == 0 ? 'bukan_warganegara' : 'pemastautin_tetap';
        if(data.pembeli.warganegara == 0 || data.pembeli.warganegara == 2) {
          this.form.warganegara_id = data.pembeli.warganegara_id ?? data.pembeli.kod_negara_id
        } else {
          this.form.warganegara_id = null
        }
      }

    },

    setFormWakilkuasa(data) {
      // set wakil kuasa pembeli
      const wakilKuasa = data.pembeli.wakil_kuasa_pembeli;
      this.formWakilkuasa = {
        wakilkuasa_ada: wakilKuasa ? 1 : 0,
        kod_jenis_wakil_kuasa_id: wakilKuasa?.kod_jenis_wakil_kuasa_pembeli || null,
        wakilkuasa_nama: wakilKuasa?.nama || null,
        wakilkuasa_no_kp: wakilKuasa?.no_kp || null,
        wakilkuasa_no_pendaftaran_firma: wakilKuasa?.no_pendaftaran_firma || null,
        wakilkuasa_nama_penandatangan: wakilKuasa?.nama_penandatangan || null,
        wakilkuasa_no_pendaftaran: wakilKuasa?.no_pendaftaran || null,
        wakilkuasa_tarikh_pendaftaran: wakilKuasa?.tarikh_pendaftaran || null,
        wakilkuasa_mahkamah_id: wakilKuasa?.mahkamah_id || null,
      }
    },

    resetFormWakilkuasa() {
      this.formWakilkuasa = {
        wakilkuasa_ada: null,
        kod_jenis_wakil_kuasa_id: null,
        wakilkuasa_nama: null,
        wakilkuasa_no_kp: null,
        wakilkuasa_no_pendaftaran_firma: null,
        wakilkuasa_nama_penandatangan: null,
        wakilkuasa_no_pendaftaran: null,
        wakilkuasa_tarikh_pendaftaran: null,
        wakilkuasa_mahkamah_id: null,
      }
    },

    addPenandatanganSyarikat() {
      this.formPenandatanganSyarikat.push({
          nama_pengarah: null,
          no_kp: null,
          no_tel: null,
          emel: null
      });
    },

    removePenandatanganSyarikat(index) {
      // add confirm swal
      swal.fire({
        title: "Adakah anda pasti?",
        text: "Maklumat ini akan dihapus dari senarai maklumat penandatangan syarikat.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#0069d9",
        cancelButtonColor: "#d90014",
        confirmButtonText: "Teruskan",
        cancelButtonText: "Batal",
      })
      .then((result) => {
        if (result.isConfirmed) {
          this.formPenandatanganSyarikat.splice(index, 1);
        }
        return;
      })
    },

    setFormPenandatanganSyarikat(data) {
      // reset form penandatangan syarikat
      this.formPenandatanganSyarikat = [];
      // penandatangan syarikat null (for existing data)
      if(data.pembeli.penandatangan_syarikat.length  == 0) {
        for(let i = 0; i < 2; i++) {
          this.addPenandatanganSyarikat();
        }
      } else {
        this.formPenandatanganSyarikat = data.pembeli.penandatangan_syarikat;
      }
    },

    poskodChange() {
      const poskod = this.form.poskod;
      const poskodSelected = this.poskodRs.find((poskodData) => poskodData.poskod == poskod);
      if(!poskod) {
        this.form.kod_bandar_id = null;
        this.form.kod_negeri_id = null;
        return;
      }
      
      if(!poskodSelected) {
        this.form.kod_bandar_id = null;
        this.form.kod_negeri_id = null;
        return;
      }

      this.form.kod_bandar_id = poskodSelected.kod_bandar_id;
      this.form.kod_negeri_id = poskodSelected.kod_negeri_id;
    },

    setKerakyatan(pembeli) {
      if(pembeli.warganegara == null) {
        // get from kod_bangsa_id [W = WARGANEGARA ASING]
        if(pembeli.kod_bangsa_id == "W") {
          this.form.kerakyatan = 'bukan_warganegara';
        } else {
          this.form.kerakyatan = 'warganegara';
        }
      } else {
        this.form.kerakyatan = pembeli.warganegara == 1 ? 'warganegara' : pembeli.warganegara == 0 ? 'bukan_warganegara' : 'pemastautin_tetap';
      }
    }

  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1;
    },
    pembeliBerstatusPembeliRs() {
      if (this.pembeli_rs.filter((pembeli) => pembeli.status_pembeli === "PEMBELI").length > 0) {
        this.openJualan = true;
        this.openStrata = true;
      } else {
        this.openJualan = false;
      }

      return this.pembeli_rs.filter((pembeli) => pembeli.status_pembeli === "PEMBELI");
    },
    isFirstBuyer() {
      return this.unit_pertama && this.unit.id == this.unit_pertama.id;
    },
    hargaSPJBsebelumInisiatif() {
      this.harga_spjb_sebelum_inisiatif = this.unit.harga_jualan - this.formJualan.rebat_pemaju - this.formJualan.harga_diskaun_kuotabumi;

      return this.harga_spjb_sebelum_inisiatif;
    },

    hargaSPJB() {
      this.harga_spjb = this.unit.harga_jualan - this.formJualan.rebat_pemaju - this.formJualan.harga_diskaun_kuotabumi - this.formJualan.inisiatif_kerajaan;

      return this.harga_spjb;
    },
    isSimenApBaru() {
      if (moment(this.pemajuan.created_at).isBetween('2023-05-01', '2024-06-30')){
        return true;
      }
    },

    calculateDiskaunInisiatif() {
      const inisiatif_kerajaan = parseFloat(this.formJualan.inisiatif_kerajaan);
      const hargaJualan = parseFloat(this.unit.harga_jualan);

      if (!isNaN(inisiatif_kerajaan) && !isNaN(hargaJualan)) {
        const diskaunInisiatif = ((inisiatif_kerajaan / hargaJualan) * 100 ).toFixed(2);
        this.formJualan.diskaun_inisiatif = diskaunInisiatif;

        if (parseFloat(inisiatif_kerajaan) < 1000) {
          this.showInisiatifKerajaanError = true;
        }
        else if(parseFloat(inisiatif_kerajaan) == 0) {
          this.showInisiatifKerajaanError = false;
        }else {
          this.showInisiatifKerajaanError = false;
        }
      }
    },

    isButtonJanaDisabled() {
      return this.unit.status_jualan === 1 && (this.unit.jenis_layout == null || this.unit.kod_kedudukan_lot_id == null);
    },

    isKuotaBumiTiadaSkim(){
      if(this.unit.kuota_bumi == 1 && this.pemajuan.skim_projek == 0){
        return this.unit.harga_diskaun_kuotabumi < 1.0;
      }
    },

    kodNegaraFiltered() {
      return this.kodNegaraRs.filter((item) => {
        return item.id != this.KOD_NEGARA_MALAYSIA
      })
    },

    kodKaumRsFilter() {
      return this.kodKaumRs
    },

    isNegaraMalaysia() {
      return this.form.kod_negara_id == this.KOD_NEGARA_MALAYSIA
    },

    isReadonlyWakilKuasaNama() {
      if(["S"].includes(this.formWakilkuasa.kod_jenis_wakil_kuasa_id)) {
        return true
      }
      return false
    },
    hasRemovePenandatanganSyarikat() {
      return this.formPenandatanganSyarikat.length > 2
    },
    disabledDaerah() {
      const negeriExcludedDaerah = ['14', '15', '16'];
      /*
        14 = WP Kuala Lumpur
        15 = WP Labuan
        16 = WP Putrajaya    
      */
      return negeriExcludedDaerah.includes(this.form.kod_negeri_id)
    },
    disabledBandar() {
      const negeriExcludedDaerah = ['14', '15', '16'];
      /*
        14 = WP Kuala Lumpur
        15 = WP Labuan
        16 = WP Putrajaya    
      */
      return negeriExcludedDaerah.includes(this.form.kod_negeri_id)
    },
    kodJenisPemilikanRsFilter() {
      if(this.pembeli_rs.length == 0) {
        return this.kodJenisPemilikanRs
      }
      const firstBuyerInUnit = this.pembeli_rs[0];
      return this.kodJenisPemilikanRs.filter((item) => {
        return firstBuyerInUnit.kod_jenis_pemilikan_id == 'I' ? item.id == 'I' : item.id != 'I';
      })
    }
  },

  watch: {
    'form.kerakyatan': function() {
      this.kerakyatanChange();
    },
    'form.kod_jenis_pemilikan_id': async function() {
      await this.jenisPemilikanChange();
      this.kerakyatanChange();
    },
    // 'formWakilkuasa.kod_jenis_wakil_kuasa_id': function() {
    //   if(["S"].includes(this.formWakilkuasa.kod_jenis_wakil_kuasa_id)) {
    //     this.formWakilkuasa.wakilkuasa_nama = this.pemajuan.pemaju.nama;
    //     this.formWakilkuasa.wakilkuasa_no_kp = `${this.pemajuan.pemaju.no_ssm_baru} (${this.pemajuan.pemaju.no_ssm})`;
    //   } else {
    //     this.formWakilkuasa.wakilkuasa_nama = null;
    //     this.formWakilkuasa.wakilkuasa_no_kp = null
    //   }
    // }
  }
};
</script>

<style scoped>

</style>
