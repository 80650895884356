<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps">
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            href="#bahagian-a"
          >A. BUTIRAN MAKLUMAT JURUAUDIT</a>
        </li>
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            href="#bahagian-b"
          >B. SALINAN DOKUMEN SOKONGAN</a>
        </li>
        <li v-if="!readonly">
          <a
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            href="#bahagian-c"
          >C. PERAKUAN PEMAJU PERUMAHAN</a>
        </li>
      </ul>
      <div class="tab-content mt-4">
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-a')}"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN MAKLUMAT JURUAUDIT</h4>
              <bahagian-a
                :pemaju="pemaju"
                :tarikh_akhir_kewangan="default_tarikh_akhir_kewangan"

                :pemaju_nama_pegawai.sync="pemaju_nama_pegawai"
                :pemaju_no_bimbit.sync="pemaju_no_bimbit"
                :nama_juruaudit.sync="nama_juruaudit"
                :alamat_juruaudit.sync="alamat_juruaudit"
                :no_tel_juruaudit.sync="no_tel_juruaudit"
                :no_faks_juruaudit.sync="no_faks_juruaudit"
                :emel_juruaudit.sync="emel_juruaudit"
                :no_kelulusan.sync="no_kelulusan"
                :tarikh_mula.sync="tarikh_mula"
                :tarikh_akhir.sync="tarikh_akhir"

                :readonly="readonly"
              ></bahagian-a>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-b')}"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">SALINAN DOKUMEN SOKONGAN</h4>
              <maklumat-dokumen-sokongan
                :doc-configs="docConfigs"
                :default_docs="default_docs"
                :readonly="readonly"
              ></maklumat-dokumen-sokongan>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-c')}"
          id="bahagian-c"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">PERAKUAN PEMAJU PERUMAHAN</h4>
              <bahagian-c
                @submit="submit"
                :readonly="readonly"
              ></bahagian-c>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BahagianA from "./BahagianA";
  import BahagianC from "./BahagianC";
  import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";

  export default {
    components: {MaklumatDokumenSokongan, BahagianA, BahagianC},

    props: {
      pemaju: Object,
      default_pemaju_nama_pegawai: String,
      default_pemaju_no_bimbit: String,
      default_nama_juruaudit: String,
      default_alamat_juruaudit: String,
      default_no_tel_juruaudit: String,
      default_no_faks_juruaudit: String,
      default_emel_juruaudit: String,
      default_no_kelulusan: String,
      default_tarikh_mula: String,
      default_tarikh_akhir: String,
      default_tarikh_akhir_kewangan: String,

      default_docs: Array,

      docConfigs: Array,

      errorBag: {
        type: Object
      },

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    created() {
      window.addEventListener("hashchange", this.changeTab, false);
      this.$makeErrorBag(this.errorBag);
      this.changeTab();
    },

    destroyed() {
      window.removeEventListener("hashchange", this.changeTab);
    },

    data() {
      return {
        defaultTab: "#bahagian-a",
        tab: this.defaultTab,

        pemaju_nama_pegawai: this.default_pemaju_nama_pegawai,
        pemaju_no_bimbit: this.default_pemaju_no_bimbit,
        nama_juruaudit: this.default_nama_juruaudit,
        alamat_juruaudit: this.default_alamat_juruaudit,
        no_tel_juruaudit: this.default_no_tel_juruaudit,
        no_faks_juruaudit: this.default_no_faks_juruaudit,
        emel_juruaudit: this.default_emel_juruaudit,
        no_kelulusan: this.default_no_kelulusan,
        tarikh_mula: this.default_tarikh_mula,
        tarikh_akhir: this.default_tarikh_akhir,
      };
    },

    methods: {
      isCurrentTab(tab) {
        return this.tab === tab;
      },

      changeTab() {
        this.tab = window.location.hash || this.defaultTab;
      },

      validate(skipDocValidation) {
        if (this.$validator.errors.first('pemaju_nama_pegawai') ||
          this.$validator.errors.first('pemaju_no_bimbit') ||
          this.$validator.errors.first('nama_juruaudit') ||
          this.$validator.errors.first('alamat_juruaudit') ||
          this.$validator.errors.first('no_tel_juruaudit') ||
          this.$validator.errors.first('no_faks_juruaudit') ||
          this.$validator.errors.first('emel_juruaudit') ||
          this.$validator.errors.first('no_kelulusan') ||
          this.$validator.errors.first('tarikh_mula')
        ) {
          window.location.hash = '#bahagian-a';
          return false;
        } else if (!skipDocValidation && this.$validator.errors.any()) {
          window.location.hash = '#bahagian-b';
          return false;
        }

        return true;
      },

      async submit(ev) {
        ev.preventDefault();

        const passes = await this.$validator.validateAll();

        if (this.validate() && passes) {
          swalconfirm()
            .then(result => {
              if (result.value) {
                ev.target.form.submit();
              }
            });
        }
      },
    },
  }
</script>
