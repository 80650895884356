<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps">
        <li>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-a') }"
            href="#bahagian-a"
            ><span v-if="hasFormKuiri" class="text-danger"
              ><i class="fas fa-exclamation-circle"></i
            ></span>
            A. MAKLUMAT PEMAJU PERUMAHAN</a
          >
        </li>
        <li>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-b') }"
            href="#bahagian-b"
            ><span v-if="hasDocKuiri" class="text-danger mr-1"
              ><i class="fas fa-exclamation-circle"></i
            ></span>
            B. SALINAN DOKUMEN SOKONGAN</a
          >
        </li>
      </ul>
      <div class="tab-content mt-4">
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-a') }"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN MAKLUMAT PEMAJU</h4>

              <bahagian-a
                :pemaju="pemaju"
                :nama_juruaudit.sync="juruaudit.nama"
                :no_rujukan_suratwarta.sync="maklumat.no_rujukan_suratwarta"
                :pemaju_nama_pegawai.sync="maklumat.pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="maklumat.pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="maklumat.pemaju_no_bimbit"
                :pemaju_email.sync="maklumat.pemaju_email"
                :tarikh_akhir_kewangan="default_tarikh_akhir_kewangan"
                :readonly="readonly"
              ></bahagian-a>
              <div class="form-group" v-if="hasFormKuiri">
                <label class="col-form-label col-md-4">Catatan Kuiri</label>
                <textarea class="form-control" rows="2" disabled>{{
                  hasFormKuiri.catatan_kuiri
                }}</textarea>
              </div>
              <div class="form-group" v-if="hasFormKuiri">
                <label class="col-form-label col-md-4"
                  >Maklumbalas Kuiri <span style="color: red">*</span></label
                >
                <textarea
                  class="form-control"
                  rows="3"
                  name="catatan_maklumbalas_kuiri"
                  required
                  v-model="maklumbalasKuiri"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-b') }"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">SALINAN DOKUMEN SOKONGAN</h4>
              <maklumat-dokumen-sokongan
                :doc-configs="docConfigs"
                :default_docs.sync="maklumat.dokumen_rs"
                :readonly="readonly"
              ></maklumat-dokumen-sokongan>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button class="btn btn-primary btn-sm" @click="submit">
                  HANTAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import Kuiri from "./Kuiri";
import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";
import { data } from "jquery";

export default {
  components: { MaklumatDokumenSokongan, Kuiri, BahagianA },
  props: {
    pemaju: {
      type: Object
    },

    pengarah: {
      type: Object
    },

    juruaudit: Object,
    default_tarikh_akhir_kewangan: String,
    
    default_maklumat: Object,
    default_permohonan: Object,

    errorBag: {
      type: Object
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    pengarah: {
      type: Object,
    },

    juruaudit: Object,
    default_tarikh_akhir_kewangan: String,

    docConfigs: Array,

    default_maklumat: Object,
    default_permohonan: Object,

    errorBag: {
      type: Object,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      saving: false,

      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      pengesahan: false,

      maklumbalasKuiri: "", //khai
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

      validate() {
    if (this.$validator.errors.first('nama_juruaudit') ||
      this.$validator.errors.first('no_rujukan_suratwarta') ||
      this.$validator.errors.first('pemaju_nama_pegawai') ||
      this.$validator.errors.first('pemaju_no_pejabat') ||
      this.$validator.errors.first('pemaju_no_bimbit') ||
      this.$validator.errors.first('pemaju_email')
    ) {
      window.location.hash = '#bahagian-a';
    } else if (this.$validator.errors.first('pengesahan')) {
      window.location.hash = '#bahagian-c';
    } else if (this.$validator.errors.any()) {
      window.location.hash = '#bahagian-b';
    }
  },

  submit(ev) {
    ev.preventDefault();
    var flag_doc = true;
    this.maklumat.dokumen_rs.forEach((item) => {
      var doc = $(`[name="doc[${item.id}]"]`);
      if(item.mandatori_ind == 1){
        if(doc.length == 1){
          flag_doc = false;
        }
      }
    });

    if(flag_doc == false){
      swal.fire({
        icon: 'error',
        title: 'Harap Maaf',
        text: 'Sila muatnaik dokumen yang dikuiri oleh pegawai.',
      });
      return;
    }
    // Check if the textarea exists and if it's empty
    if (this.hasFormKuiri && !this.enableSubmit) {
    // if (!this.enableSubmit) {
        swal.fire({
            icon: 'error',
            title: 'Harap Maaf',
            text: 'Sila lengkapkan ruangan Maklumbalas Kuiri di Bahagian A',
        });
        return;
    }
     // Check if the textarea is empty and alert the user if it is (bahagian B)
     const failedInputMaklumBalas = this.maklumat.dokumen_rs.filter((dokumen) => {
      if (!dokumen.kuiri || dokumen.flag_lulus) {
        return false;
      }

      return dokumen.kuiri?.catatan_maklumbalas_kuiri == null || dokumen.kuiri?.catatan_maklumbalas_kuiri.trim() == "";
    });

    if(failedInputMaklumBalas.length > 0) {
      swal.fire({
        icon: 'error',
        title: 'Harap Maaf',
        text: 'Sila lengkapkan ruangan Catatan Maklumbalas di Bahagian B',
      });
      return;
    }

    // If the textarea doesn't exist or if it's not empty, proceed with submission
    swalconfirm().then(result => {
        if (result.value) {
            ev.target.form.submit();
        }
    });
},

  async save(ev) {
    const passes = await this.$validator.validateAll();

    if (!passes) {
      this.validate();

      return;
    }

    // Check if the textarea is empty and alert the user if it is (bahagian B)
    const failedInputMaklumBalas = this.maklumat.dokumen_rs.filter((dokumen) => {
      if (!dokumen.kuiri || dokumen.flag_lulus) {
        return false;
      }

      return dokumen.kuiri?.catatan_maklumbalas_kuiri == null || dokumen.kuiri?.catatan_maklumbalas_kuiri.trim() == "";
    });

    if(failedInputMaklumBalas.length > 0) {
      swal.fire({
        icon: 'error',
        title: 'Harap Maaf',
        text: 'Sila isi ruangan maklumbalas kuiri',
      });
      return;
    }

    // Proceed with submission if the textarea is not empty
    swalconfirm().then(result => {
      if (result.value) {
        ev.target.form.submit();
      }
    });
  },

    async save(ev) {
      const passes = await this.$validator.validateAll();

      if (!passes) {
        this.validate();

        return;
      }

      this.saving = true;
      axios
        .put(`/pemaju/permohonan/${this.permohonan.id}`, {
          pengesahan: true,
          ...this.maklumat,
        })
        .then((response) => {
          this.maklumat = response.data;
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
        });
    },
  },
  computed: {
    hasDocKuiri() {
      return this.maklumat.dokumen_rs.some((dokumen) => {
        return !dokumen.flag_lulus;
      });
    },
    hasFormKuiri() {
      // console.log();
      return this.maklumat.permohonan.get_kuiri_maklumat;
    },

    enableSubmit() {
      //khai
      // Return true if the textarea has some text, otherwise false
      return this.maklumbalasKuiri.trim() !== "";
    },
  },
};
</script>
