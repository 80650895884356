<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps nav">
        <li>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-a') }"
            href="#bahagian-a"
            ><span v-if="hasFormKuiri" class="text-danger"
              ><i class="fas fa-exclamation-circle"></i
            ></span>
            A. BUTIRAN PEMAJU</a
          >
        </li>
        <li class>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-b') }"
            href="#bahagian-b"
            ><span v-if="hasBulanKuiri" class="text-danger"
              ><i class="fas fa-exclamation-circle"></i></span
            >B. MAKLUMAT KEWANGAN</a
          >
        </li>
        <li class v-if="!readonly">
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-c') }"
            href="#bahagian-c"
            >C. PERAKUAN PEMAJU PERUMAHAN</a
          >
        </li>
      </ul>

      <div class="tab-content mt-4 mx-0">
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-a') }"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN PEMAJU</h4>

              <bahagian-a
                :pemaju="pemaju"
                :pemajuan="pemajuan"
                :pengarah="pengarah"
                :pemaju_nama_pegawai.sync="maklumat.pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="maklumat.pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="maklumat.pemaju_no_bimbit"
                :pemaju_email.sync="maklumat.pemaju_email"
              ></bahagian-a>
              <div class="form-group" v-if="hasFormKuiri">
                <label class="col-form-label col-md-4">Catatan Kuiri</label>
                <textarea class="form-control" rows="10" disabled>{{
                  hasFormKuiri.catatan_kuiri
                }}</textarea>
                <label class="col-form-label col-md-4">Catatan Maklumbalas Kuiri</label>
                <textarea class="form-control" rows="10" :name="`kuiri_permohonan_rs[${hasFormKuiri.id}][catatan_maklumbalas_kuiri]`"
                      v-model="kuiri.catatan_maklumbalas_kuiri"
                      :disabled="kuiri.tarikh_maklumbalas_kuiri"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-b') }"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">MAKLUMAT KEWANGAN</h4>

              <bahagian-b
                :sesi="sesi"
                :akaun-hda="maklumat.akaun_hda"
                :jaminan-bank-rs="jaminanBankRs"
                :sumber-biaya-rs="sumberBiayaRs"
                :bentuk-biaya-rs="bentukBiayaRs"
                :perihal-peraturan7-rs="perihalPeraturan7Rs"
                :default_maklumat="maklumat"
                :default_bulan_rs.sync="bulan_rs"
                :default_sumber_kewangan_rs.sync="maklumat.sumber_kewangan_rs"
                :nilai_hakmilik_ditebus.sync="maklumat.nilai_hakmilik_ditebus"
                :nilai_jumlah_jualan.sync="maklumat.nilai_jumlah_jualan"
                :nilai_jumlah_progbill.sync="maklumat.nilai_jumlah_progbill"
                :has-kuiri="hasBulanKuiri"
                :readonly="readonly"
              ></bahagian-b>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-c') }"
          id="bahagian-c"
          role="tabpanel"
        >
          <bahagian-c
            :pengesahan.sync="pengesahan"
            :pengarah="pengarah"
            @submit="submit"
            :readonly="readonly"
            :tarikh_hantar="default_tarikh_hantar"
          ></bahagian-c>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA.vue";
import BahagianB from "./BahagianB.vue";
import BahagianC from "./BahagianC.vue";

import Kuiri from "./Kuiri.vue";

export default {
  components: {
    BahagianA,
    BahagianB,
    BahagianC,
    Kuiri,
  },

  props: {
    akaunHda: {
      type: Object,
    },

    pemaju: {
      type: Object,
    },

    pemajuan: {
      type: Object,
    },

    jaminanBankRs: {
      type: Array,
    },

    pengarah: {
      type: Object,
    },

    perihalPeraturan7Rs: {
      type: Array,
      default: [],
    },

    sumberBiayaRs: {
      type: Array,
      default: [],
    },

    bentukBiayaRs: {
      type: Array,
      default: [],
    },

    sesi: {
      type: Object,
      default: () => ({
        id: "MAC",
        baki_bawa_bawah: 10000,
        bulan: ["JANUARI", "FEBRUARI", "MAC"],
      }),
    },

    default_bulan_rs: {
      type: Array,
      default: () => [],
    },

    default_permohonan: {
      type: Object,
      default: () => ({}),
    },

    default_maklumat: {
      type: Object,
      default: () => ({}),
    },

    default_tarikh_hantar: String,

    errorBag: {
      type: Object,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
    console.log(this.default_bulan_rs);
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      kuiri: {},
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      saving: false,

      bulan_rs: [
        this.createDefaultPerihal(this.default_bulan_rs, 0),
        this.createDefaultPerihal(this.default_bulan_rs, 1),
        this.createDefaultPerihal(this.default_bulan_rs, 2),
      ],

      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),

      pengesahan: null,
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    createDefaultPerihal(bulan_rs, bulan) {
      const data = {
        jumlah_masuk: bulan_rs.hasOwnProperty(bulan)
          ? bulan_rs[bulan].jumlah_masuk
          : "0.00",
        jumlah_keluar: bulan_rs.hasOwnProperty(bulan)
          ? bulan_rs[bulan].jumlah_keluar
          : "0.00",
        penyata_kewangan_bulanan: bulan_rs.hasOwnProperty(bulan)
          ? bulan_rs[bulan].penyata_kewangan_bulanan
          : null,
        lampiran_a: bulan_rs.hasOwnProperty(bulan)
          ? bulan_rs[bulan].lampiran_a
          : null,

        kuiri: bulan_rs.hasOwnProperty(bulan) ? bulan_rs[bulan].kuiri : null,
      };

      if (bulan === 0) {
        data.baki_bawa_bawah = bulan_rs.hasOwnProperty(bulan)
          ? bulan_rs[bulan].baki_bawa_bawah || this.sesi.baki_bawa_bawah
          : this.sesi.baki_bawa_bawah;
      }

      this.perihalPeraturan7Rs.forEach((perihal) => {
        data[perihal.id] = bulan_rs.hasOwnProperty(bulan)
          ? bulan_rs[bulan][perihal.id]
          : "0.00";
      });
      return JSON.parse(JSON.stringify(data));
    },

    validate() {
      if (
        this.$validator.errors.first("pemaju_nama_pegawai") ||
        this.$validator.errors.first("pemaju_no_pejabat") ||
        this.$validator.errors.first("pemaju_no_bimbit") ||
        this.$validator.errors.first("pemaju_email")
      ) {
        window.location.hash = "#bahagian-a";

        return false;
      }

      if (!this.maklumat.sumber_kewangan_rs.length) {
        this.$validator.errors.add({
          field: "sumber_kewangan",
          msg: "Sila isi maklumat sumber kewangan.",
        });

        if (this.tab === "#bahagian-b") {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
          return false;
        }

        window.location.hash = "#bahagian-b";
        return false;
      }

      if (this.$validator.errors.first("pengesahan")) {
        window.location.hash = "#bahagian-c";

        return false;
      }

      if (this.$validator.errors.any()) {
        window.location.hash = "#bahagian-b";

        return false;
      }

      return true;
    },

    save() {
      this.$validator.errors.clear();

      this.$validator.validateAll().then((passes) => {
        if (this.validate() && passes) {
          this.saving = true;
          axios
            .put(`/pemaju/permohonan/${this.permohonan.id}`, {
              pemaju_nama_pegawai: this.maklumat.pemaju_nama_pegawai,
              pemaju_no_pejabat: this.maklumat.pemaju_no_pejabat,
              pemaju_no_bimbit: this.maklumat.pemaju_no_bimbit,
              pemaju_email: this.maklumat.pemaju_email,
              sumber_kewangan: this.maklumat.sumber_kewangan_rs,
              bulan: this.bulan_rs,
              nilai_hakmilik_ditebus: this.maklumat.nilai_hakmilik_ditebus,
              nilai_jumlah_jualan: this.maklumat.nilai_jumlah_jualan,
              nilai_jumlah_progbill: this.maklumat.nilai_jumlah_progbill,
            })
            .then((response) => {
              this.maklumat = response.data.maklumat;
              this.bulan_rs = [
                this.createDefaultPerihal(response.data.bulan, 0),
                this.createDefaultPerihal(response.data.bulan, 1),
                this.createDefaultPerihal(response.data.bulan, 2),
              ];
            })
            .catch(() => {})
            .then(() => {
              this.saving = false;
            });
        }
      });
    },

    submit(ev) {
      ev.preventDefault();

      this.$validator.errors.clear();

      this.$validator.validateAll().then((passes) => {
        if (this.validate() && passes) {
          swalconfirm().then((result) => {
            if (result.value) {
              ev.target.form.submit();
            }
          });
        }
      });
    },
  },

  computed: {
    hasBulanKuiri() {
      return this.bulan_rs.some((bulanan) => {
        return !!bulanan.kuiri;
      });
    },
    hasFormKuiri() {
      // console.log();
      return this.maklumat.permohonan.get_kuiri_maklumat;
    },
  },
};
</script>
