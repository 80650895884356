<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <section class="form-compact row">
        <div class="table-responsive">
          <table class="table">
            <thead>
            <tr>
              <th>Bil.</th>
              <th>Jenis Kediaman</th>
              <th>Bil Tingkat</th>
              <th>Bil. Unit Yang Dipohon</th>
              <th>Harga Minima (RM)</th>
              <th>Harga Maksima (RM)</th>
              <th>Nilai Jualan (RM)</th>
              <th width="1%" class="text-center">
                <button type="button" class="btn btn-success" style="width: 1.5rem; height: 1.5rem; padding: 0;" @click.prevent="add" tabindex="-1"><i class="fas fa-plus"></i></button>
              </th>
            </tr>
            </thead>
            <tbody>
            <template v-if="pembangunan_rs.length">
              <tr v-for="(pembangunan, index) in pembangunan_rs">
                <td>{{ index + 1 }}</td>
                <td>{{ jenisKediaman(pembangunan.kod_jenis_rumah_id) }}</td>
                <td>{{ pembangunan.bil_tingkat }}</td>
                <td>{{ pembangunan.nolot_unit_rs.length }}</td>
                <template v-if="pembangunan.nolot_unit_rs.length">
                  <td>{{ getHargaMinima(pembangunan.nolot_unit_rs) | currency('') }}</td>
                  <td>{{ getHargaMaksima(pembangunan.nolot_unit_rs) | currency('') }}</td>
                  <td>{{ getNilaiJualan(pembangunan.nolot_unit_rs) | currency('') }}</td>
                </template>
                <template v-else>
                  <td class="text-center bg-danger-light" colspan="3">Sila kemaskini butiran unit.</td>
                </template>

                <td nowrap>
                  <button type="button" class="btn btn-icon text-primary" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="manageUnit(pembangunan, index)"
                          title="Kemaskini Butiran Unit" tabindex="-1"><i class="fas fa-building"></i></button>
                  <button type="button" class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroyPembangunan(index)"
                          title="Hapus" tabindex="-1"><i class="fas fa-trash"></i></button>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <tambah-butiran-bangunan-modal
      :kod-jenis-kediaman-rs="kodJenisKediamanRs"
      :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
      :kod-jenis-perjanjian-rs="kodJenisPerjanjianRs"
    ></tambah-butiran-bangunan-modal>

    <kemaskini-butiran-unit-modal
      :maklumat="maklumat"
      :kod-jenis-kediaman-rs="kodJenisKediamanRs"
      :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
      :kod-jenis-perjanjian-rs="kodJenisPerjanjianRs"
    ></kemaskini-butiran-unit-modal>
  </div>
</template>

<script>
import TambahButiranBangunanModal from "../../common/TambahButiranBangunanModal";
import KemaskiniButiranUnitModal from "../../common/KemaskiniButiranUnitModal";

export default {
  name: "BahagianC1",
  components: {KemaskiniButiranUnitModal, TambahButiranBangunanModal},
  props: {
    maklumat: Object,

    default_pembangunan_rs: Array,

    kodJenisKediamanRs: Array,
    kodJenisPerjanjianRs: Array,
    kodJenisHakmilikRs: Array,

    kuiri: null,
  },
  data() {
    return {
      pembangunan_rs: JSON.parse(JSON.stringify(this.default_pembangunan_rs)),
    }
  },
  methods: {
    add() {
      vmodal.open('tambahbutiranbangunan', {}, (pembangunan) => {
        if (this.loading) {
          return;
        }

        this.loading = true;

        axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/bahagian-c1`, pembangunan)
          .then((response) => {
            this.pembangunan_rs = JSON.parse(JSON.stringify(response.data.pembangunan_rs));

            this.$emit('update', response.data);
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      })
    },

    manageUnit(pembangunan, index) {
      vmodal.open('kemaskinibutiranunit', {
        pembangunan,
      }, (pembangunan) => {
        this.pembangunan_rs.splice(index, 1, pembangunan);

        const maklumat = JSON.parse(JSON.stringify(this.maklumat));
        maklumat.pembangunan_rs = JSON.parse(JSON.stringify(this.pembangunan_rs));

        this.$emit('update', maklumat);
      })
    },

    async destroyPembangunan(index) {
      if (this.loading) {
        return;
      }

      const result = await swalconfirm()

      if (result.value) {
        this.loading = true;

        const pembangunan = this.pembangunan_rs[index];

        axios.delete(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/bahagian-c1/${pembangunan.id}`)
          .then((response) => {
            this.pembangunan_rs = JSON.parse(JSON.stringify(response.data.pembangunan_rs));

            this.$emit('update', response.data);
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      }
    },

    jenisKediaman(id) {
      const kediaman = this.kodJenisKediamanRs.find(item => item.id == id);

      return kediaman ? kediaman.keterangan : '-';
    },

    getKeluasanBangunan(nolot_unit_rs) {
      return (_.sum(nolot_unit_rs.map(i => parseFloat(i.luas_binaan) * 100)) / 100).toFixed(2);
    },

    getKeluasanTanah(nolot_unit_rs) {
      return (_.sum(nolot_unit_rs.map(i => parseFloat(i.luas_tanah) * 100)) / 100).toFixed(2);
    },

    getHargaMinima(nolot_unit_rs) {
      return parseFloat(_.min(nolot_unit_rs.map(i => i.harga_jualan))).toFixed(2);
    },

    getHargaMaksima(nolot_unit_rs) {
      return parseFloat(_.max(nolot_unit_rs.map(i => i.harga_jualan))).toFixed(2);
    },

    getNilaiJualan(nolot_unit_rs) {
      return (_.sum(nolot_unit_rs.map(i => parseFloat(i.harga_jualan) * 100)) / 100).toFixed(2);
    },
  }
}
</script>

<style scoped>

</style>
