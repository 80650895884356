<template>
  <div class="card">
      <div class="card-body">
          <section class="form-compact row">
              <div class="col-lg-12">
                  <div class="form-group form-row">
                      <label class="col-form-label col-md-2">Nama Pemaju</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control" disabled :value="pemaju.nama">
                      </div>
                  </div>
                  <div class="form-group form-row">
                      <label class="col-form-label col-md-2">Nama Projek</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control" disabled :value="pemajuan.nama">
                      </div>
                  </div>
                  
                  <div class="form-group form-row">
                  <label class="col-form-label col-md-2">Kod Pemaju</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" disabled :value="pemaju.kod_pemaju">
                  </div>
                  <label class="col-form-label col-md-2">Fasa No.</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" disabled :value="pemajuan.kod_fasa">
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-2">No. SSM</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" disabled :value="pemaju.no_ssm">
                  </div>
                  <label class="col-form-label col-md-2">No. SSM Baru</label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" disabled :value="pemaju.no_ssm_baru">
                  </div>
                </div>
  
                <div class="form-group form-row">
                  <label class="col-form-label col-md-2">Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
                  <div class="col-md-10">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai') }" name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai" @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required|max:100'" data-vv-as="Nama Pegawai" />
                    <span class="invalid-feedback" v-if="errors.first('pemaju_nama_pegawai')">{{ errors.first('pemaju_nama_pegawai') }}</span>
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-2">No Telefon Pejabat <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_pejabat') }" name="pemaju_no_pejabat" :value="pemaju_no_pejabat" @input="$emit('update:pemaju_no_pejabat', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:9,11'" data-vv-as="No Telefon Pejabat" />
                    <span class="invalid-feedback" v-if="errors.first('pemaju_no_pejabat')">{{ errors.first('pemaju_no_pejabat') }}</span>
                  </div>
                  <label class="col-form-label col-md-2">No Telefon Bimbit <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit') }" name="pemaju_no_bimbit" :value="pemaju_no_bimbit" @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:10,11'" data-vv-as="No Telefon Bimbit" />
                    <span class="invalid-feedback" v-if="errors.first('pemaju_no_bimbit')">{{ errors.first('pemaju_no_bimbit') }}</span>
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-2">Emel <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_email') }" name="pemaju_email" :value="pemaju_email" @input="$emit('update:pemaju_email', $event.target.value)" v-validate="'required|email'" data-vv-as="Emel"/>
                    <span class="invalid-feedback" v-if="errors.first('pemaju_email')">{{ errors.first('pemaju_email') }}</span>
                  </div>
                </div>
  
                <br>
                  <div class="form-group form-row">
                      <label class="col-form-label col-md-2">Nama Pengarah / Pengarah Urusan</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control" disabled :value="pengarah.nama">
                      </div>
                  </div>
                  <div class="form-group form-row">
                      <label class="col-form-label col-md-2">No Kad Pengenalan</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control" disabled :value="pengarah.no_kp">
                      </div>
                  </div>
              </div>
          </section>
      </div>
  </div>
  </template>
  
  <script>
  export default {
    name: "BahagianA",
  
    inject: ['$validator'],
  
    props: {
      pemaju: {
        type: Object,
      },
  
      pemajuan: {
        type: Object,
      },
  
      pengarah: {
        type: Object,
      },
  
      pemaju_nama_pegawai: String,
      pemaju_no_pejabat: String,
      pemaju_no_bimbit: String,
      pemaju_email: String,
    },
  }
  </script>
  