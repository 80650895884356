<template>
  <tr>
    <td>{{ index+ 1}}</td>
    <td>{{ jenis_rumah.keterangan }}</td>
    <td>{{ pembangunan.bil_tingkat }}</td>
    <td>{{ pembangunan.bil_dilulus }}</td>
    <td>{{ pembangunan.harga_min | currency('RM', ',', 2, '.', 'front', false) }}</td>
    <td>{{ pembangunan.harga_max | currency('RM', ',', 2, '.', 'front', false) }}</td>
    <td>{{ pembangunan.tarikh_pjb }}</td>
    <td><button type="button" @click="updatePembangunan()" class="btn btn-inverse-primary">KEMASKINI</button></td>
    <td class="text-center">
      <span v-if="!pembangunan.status" class="text-danger">&#x2718;</span>
      <span v-else class="text-success">&#x2714;</span>
    </td>
  </tr>
</template>

<script>
  export default {
    name: "RowPembangunan",
    props: {
      maklumat: Object,
      jenis_rumah: Object,
      pembangunan: Object,
      kemajuan: Object,
      jualan: Object,
      index: Number,
    },
    data() {
      return {
        updating: false,
        saving: false,
      };
    },
    methods: {
      updatePembangunan() {
        vmodal.open('laporan7fkemaskini', {
          maklumat: this.maklumat,
          jenis_rumah: this.jenis_rumah,
          pembangunan: this.pembangunan,
          jualan: this.jualan,
          kemajuan: this.kemajuan,
        }, (data) => {
          this.$emit('update', data);
        })
      },
    },
  }
</script>
