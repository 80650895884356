<template>
  <section>
    <section class="card">
      <table class="table table-card mb-0">
        <thead>
        <tr>
          <th v-if="hasCheckbox"></th>
          <th>#</th>
          <th>No. Hakmilik</th>
          <th>No. Lot / PTD / PT</th>
          <th>Keluasan Lot</th>
          <th>Kategori Kegunaan Tanah</th>
          <th>Syarat Nyata</th>
          <th>Status Tanah</th>
          <th class="text-center">Tindakan</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-if="hasCheckbox"><input type="checkbox" @input="$emit('select')" :checked="hasCheckbox && selected" :disabled="checkboxReadonly"></td>
          <td width="5%">{{ no }}</td>
          <td style="word-break: break-word;">
            <div v-if="butiran.action === 'H'"><s>{{ butiran.no_geran }}</s> <span class="text-danger">(HAPUS)</span></div>
            <div v-else-if="butiran.action === 'B'">{{ butiran.no_geran }} <span class="text-warning">(BARU)</span></div>
            <div v-else-if="butiran.action === 'K'">{{ butiran.no_geran }} <span class="text-info">(KEKAL)</span></div>
            <div v-else>{{ butiran.no_geran }}</div>
          </td>
          <td style="word-break: break-word;">
            <div v-if="butiran.no_lot_pemajuan !== butiran.orig_no_lot_pemajuan"><s>{{ butiran.orig_no_lot_pemajuan }}</s></div>
            <div>{{ butiran.no_lot_pemajuan }}</div>
          </td>
          <td>
            <div v-if="butiran.luas_tanah !== butiran.orig_luas_tanah"><s>{{ butiran.orig_luas_tanah }} {{ butiran.orig_luas_tanah_param }}</s></div>
            <div>{{ butiran.luas_tanah }} {{ butiran.luas_tanah_param }}</div>
          </td>
          <td>
            <div v-if="butiran.kategori_tanah_id !== butiran.orig_kategori_tanah_id"><s>{{ butiran.orig_kategori_tanah ? butiran.orig_kategori_tanah.keterangan : 'TIADA MAKLUMAT' }}</s></div>
            <div>{{ butiran.kategori_tanah ? butiran.kategori_tanah.keterangan : 'TIADA MAKLUMAT' }}</div>
          </td>
          <td>{{ butiran.syarat_nyata ? butiran.syarat_nyata.keterangan : 'TIADA MAKLUMAT' }}</td>
          <td>{{ butiran.status_tanah ? butiran.status_tanah.keterangan : 'TIADA MAKLUMAT' }}</td>
          <td class="text-center" nowrap>
            <button class="btn btn-sm btn-link btn-icon" @click.prevent="editButiran"><i class="fa fa-edit"></i></button>
            <div v-if="butiran.pemajuan_rs.length" class="text-sm text-info">Memiliki Pemajuan</div>
            <button class="btn pb-0 pr-2" @click.stop="$emit('destroyButiran')" v-else-if="!readonly && butiran.action === 'H'"><i class="fa fa-check-circle text-success"></i></button>
            <button class="btn btn-sm btn-link btn-icon" @click.prevent="$emit('destroyButiran')" v-else-if="!readonly"><i class="fa fa-trash text-danger"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </section>
    <div class="ml-4 p-4" style="border-left: 4px solid #dee2e6">
      <div class="d-flex align-items-center mb-2">
        <h5 class="mb-0">TUAN TANAH</h5>
        <div class="ml-3">
          <button v-if="!readonly && butiran.action !== 'H'" class="btn btn-sm btn-success" type="button" @click.prevent="$emit('addTuanTanah')"><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <section class="card">
        <table class="table table-card mb-0">
          <thead>
          <tr>
            <th>#</th>
            <th>Jenis Ketuanpunyaan</th>
            <th>Nama Pemilik</th>
            <th>No. Kad Pengenalan / SSM</th>
            <th>Tindakan</th>
          </tr>
          </thead>
          <tbody>
            <template v-if="butiran.tuan_tanah_rs.length > 0">
              <tr v-for="(tuan_tanah,index) in butiran.tuan_tanah_rs" :key="`tuan-tanah-${tuan_tanah.id}`">
                <td>{{ index + 1 }}</td>
                <td>
                  <div class="d-flex">
                    <div v-if="butiran.action !== 'H' && tuan_tanah.action === 'H'"><s>{{ tuan_tanah.jenis_tuan_tanah.keterangan }}</s></div>
                    <div v-else>{{ tuan_tanah.jenis_tuan_tanah.keterangan }}&nbsp;</div>

                    <div v-if="tuan_tanah.action === 'H'"> <span class="text-danger">(HAPUS)</span></div>
                    <div v-else-if="tuan_tanah.action === 'B'"><span class="text-warning">(BARU)</span></div>
                    <div v-else-if="tuan_tanah.action === 'K'"><span class="text-info">(KEKAL)</span></div>
                  </div>

                </td>
                <td>{{ tuan_tanah.nama }}</td>
                <td>{{ tuan_tanah.no_kp }}</td>
                <td class="text-center" nowrap>
                  <button class="btn btn-sm btn-link btn-icon" @click.prevent="editTuanTanah(tuan_tanah)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-sm btn-link btn-icon" @click.prevent="$emit('destroyTuanTanah', tuan_tanah)" v-if="!readonly && butiran.action !== 'H' && tuan_tanah.action !== 'H' && (!tuan_tanah.pemajuan_rs || !tuan_tanah.pemajuan_rs.length)"><i class="fa fa-trash text-danger"></i></button>
                  <button class="btn btn-sm btn-link btn-icon" @click.prevent="$emit('destroyTuanTanah', tuan_tanah)" v-else-if="!readonly && butiran.action !== 'H' && tuan_tanah.action === 'H' && (!tuan_tanah.pemajuan_rs || !tuan_tanah.pemajuan_rs.length)"><i class="fa fa-check-circle text-success"></i></button>
                </td>
              </tr>
            </template>
            <tr v-else-if="!readonly">
              <td colspan="99">
                <div class="alert alert-warning mb-0">Sila tambah tuan tanah.</div>
              </td>
            </tr>
            <tr v-else>
              <td colspan="99">
                <div class="alert alert-warning mb-0">Tiada maklumat tuan tanah.</div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: "PindaanButiranTanah",

  props: {
    tanah: Object,
    butiran: Object,
    maklumat: Object,

    readonly: {
      type: Boolean,
      default: false,
    },

    hasCheckbox: {
      type: Boolean,
      default: false,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    checkboxReadonly: {
      type: Boolean,
      default: false,
    },

    no: {
      type: Number,
      default: 1,
    },
  },

  methods: {
    editButiran() {
      if (!this.readonly) {
        return this.$emit('editButiran')
      }

      vmodal.open(`pindaanbutirantanah-${this.tanah.id}`, {
        ...this.butiran,
      }, (butiran, {setLoading, close}) => {
        close();
      })
    },

    editTuanTanah(default_tuan_tanah) {
      if (!this.readonly) {
        return this.$emit('editTuanTanah', default_tuan_tanah)
      }
      vmodal.open('tuantanah', {
        ...default_tuan_tanah,
        tuan_tanah_rs: this.butiran.tuan_tanah_rs,
        maklumat: this.maklumat,
      }, (tuan_tanah, {setLoading, close}) => {
        close();
      })
    },
  },

}
</script>

<style scoped>

</style>
