<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps nav">
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            href="#bahagian-a"
          >A. BUTIRAN PEMAJU</a>
        </li>
        <li class>
          <a
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            href="#bahagian-b"
          >B. BUTIRAN PEMBANGUNAN</a>
        </li>
        <li class>
          <a
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            href="#bahagian-c"
          >C. BUTIRAN KEMAJUAN</a>
        </li>
        <li class>
          <a
            :class="{'show active': isCurrentTab('#bahagian-d')}"
            href="#bahagian-d"
          >D. SENARAI FIRMA DAN KONTRAKTOR</a>
        </li>
        <li class>
          <a
            :class="{'show active': isCurrentTab('#bahagian-e')}"
            href="#bahagian-e"
          >E. SENARAI NAMA PEMBELI</a>
        </li>
        <li class v-if="hasKuiri">
          <a
            :class="{'show active': isCurrentTab('#bahagian-f')}"
            href="#bahagian-f"
          ><span v-if="hasKuiri" class="text-danger mr-1"><i class="fas fa-exclamation-circle"></i></span>F. KUIRI PERMOHONAN</a>
        </li>
        <li class v-else-if="!readonly">
          <a
            :class="{'show active': isCurrentTab('#bahagian-f')}"
            href="#bahagian-f"
          >F. PERAKUAN PEMOHON</a>
        </li>

      </ul>

      <div class="tab-content mx-0 mt-4">
        <div class="text-dark rounded mb-4" style="border: 1px solid darkred; border-left: 4px solid darkred;" v-if="err">
          <div class="m-3 ml-4 mr-4">
            <h6 class="mb-3" v-if="err.includes('a')"><span style="color: darkred">SEKSYEN A</span><br class="mb-2">Sila isi butiran Pegawai Pemaju Untuk Dihubungi</h6>
            <h6 class="mb-3" v-if="err.includes('b')"><span style="color: darkred">SEKSYEN B</span><br class="mb-2">Sila kemaskini Butiran Pembangunan</h6>
            <h6 class="mb-3" v-if="err.includes('c')"><span style="color: darkred">SEKSYEN C</span><br class="mb-2">Sila isi butiran Latitud dan Longitud</h6>
            <h6 class="mb-3" v-if="err.includes('g')"><span style="color: darkred">SEKSYEN C</span><br class="mb-2">Sila muat naik Gambar Kemajuan 1, 2, 3 dan 4</h6>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-a')}"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN PEMAJU</h4>
              <bahagian-a
                :pemaju="pemaju"
                :pemajuan="form_pemajuan"
                :pengarah="pengarah"
                :maklumat.sync="maklumat"
              ></bahagian-a>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-b')}"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN PEMBANGUNAN</h4>

              <bahagian-b
                :maklumat.sync="maklumat"
                :pemajuan="form_pemajuan"
                :pembangunan-rs="form_pemajuan.pembangunan_rs"
                :readonly="readonly"
              ></bahagian-b>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-c')}"
          id="bahagian-c"
          role="tabpanel"
        >
          <bahagian-c
            :default_docs.sync="maklumat.dokumen_rs"
            :maklumat.sync="maklumat"
            :pemajuan="form_pemajuan"
            :readonly="readonly"
          ></bahagian-c>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-d')}"
          id="bahagian-d"
          role="tabpanel"
        >
          <bahagian-d
            :readonly="readonly"
            :jenis-perunding-teknikal-rs="jenisPerundingTeknikalRs"
            :jenis-perunding-bukan-teknikal-rs="jenisPerundingBukanTeknikalRs"
            :jenis-perunding-kontraktor-rs="jenisPerundingKontraktorRs"
            :maklumat="maklumat"

            :default_perunding_teknikal_rs.sync="form_pemajuan.perunding_teknikal_rs"
            :default_perunding_bukan_teknikal_rs.sync="form_pemajuan.perunding_bukan_teknikal_rs"
            :default_perunding_kontraktor_rs.sync="form_pemajuan.perunding_kontraktor_rs"
          ></bahagian-d>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-e')}"
          id="bahagian-e"
          role="tabpanel"
        >
          <bahagian-e
            :maklumat="maklumat"
            :pembangunan-rs="pemajuan.pembangunan_rs"

            :readonly="readonly"
          ></bahagian-e>
        </div>

        <div
          v-if="hasKuiri"
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-f')}"
          id="bahagian-f"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">KUIRI PERMOHONAN</h4>
              <kuiri :kuiri-rs="permohonan.kuiri_permohonan_rs"></kuiri>
            </div>
            <div class="card-footer" v-if="!readonly">
              <div class="text-right">
                <button class="btn btn-primary" @click="submit">HANTAR</button>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="!readonly"
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-f')}"
          id="bahagian-f"
          role="tabpanel"
        >
          <bahagian-f
            @submit="submit"
            :maklumat="maklumat"
            :readonly="readonly"
            :user="user"
          ></bahagian-f>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBar from "../../common/ErrorBar";
import BahagianA from "./BahagianA.vue";
import BahagianB from "./BahagianB.vue";
import BahagianC from "./BahagianC.vue";
import BahagianD from "./BahagianD.vue";
import BahagianE from "./BahagianE.vue";
import BahagianF from "./BahagianF.vue";
import Kuiri from "./Kuiri";

export default {
  name: "Borang",

  components: {
    BahagianA,
    BahagianB,
    BahagianC,
    BahagianD,
    BahagianE,
    BahagianF,
    Kuiri,
    ErrorBar
  },

  props: {
    pemaju: {
      type: Object
    },

    user: {
      type: Object
    },

    pemajuan: {
      type: Object
    },

    pengarah: {
      type: Object
    },

    jenisPerundingTeknikalRs: {
      type: Array,
      default: () => ([]),
    },

    jenisPerundingBukanTeknikalRs: {
      type: Array,
      default: () => ([]),
    },

    jenisPerundingKontraktorRs: {
      type: Array,
      default: () => ([]),
    },

    sesi: {
      type: Object,
      default: () => ({
        id: "MAC",
        bulan: ["JANUARI", "FEBRUARI", "MAC"]
      })
    },

    default_permohonan: {
      type: Object,
      default: () => ({})
    },

    default_maklumat: {
      type: Object,
      default: () => ({})
    },

    errorBag: {
      type: Object
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,
      errorMessages: {},
      err: "",

      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      form_pemajuan: JSON.parse(JSON.stringify(this.pemajuan)),
    };
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.err = urlParams.get("err");
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    validate() {
      console.log(this.$validator.errors);
      if (
        this.$validator.errors.first('pemaju_nama_pegawai') ||
        this.$validator.errors.first('pemaju_no_pejabat') ||
        this.$validator.errors.first('pemaju_no_bimbit') ||
        this.$validator.errors.first('pemaju_email')
      ) {
        window.location.hash = '#bahagian-a';
        return false;
      }

      if (!this.maklumat.pembangunan_rs.every((i) => i.status)) {
        window.location.hash = '#bahagian-b';

        return false;
      }

      if(this.pemajuan.latitud == null || this.pemajuan.longitud == null) {
        window.location.hash = '#bahagian-c';
        return false;
      }

      if (
        this.$validator.errors.first('gambar_sisi_kiri') ||
        this.$validator.errors.first('gambar_sisi_depan') ||
        this.$validator.errors.first('gambar_sisi_kanan') ||
        this.$validator.errors.first('gambar_sisi_atas')
      ) {
        window.location.hash = '#bahagian-c';
        return false;
      }

      return true;
    },

    submit(ev) {
      ev.preventDefault();

      this.$validator.validateAll()
      .then((passes) => {
        if (!this.validate() || !passes) {
          return;
        }

        swalconfirm()
          .then(result => {
            if (result.value) {
              ev.target.form.submit();
            }
          });
      });

    }
  },

  computed: {
    hasKuiri() {
      return this.permohonan.kuiri_permohonan_rs && this.permohonan.kuiri_permohonan_rs.some((dokumen) => {
        return !dokumen.flag_lulus;
      });
    },
  }
};
</script>
