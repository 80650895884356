<template>
  <div class="card">
    <h4 class="card-header">SUMBER KEWANGAN</h4>
    <div class="table-responsive">
      <table class="table table-card">
        <thead class="thead-light">
          <tr>
            <th width="15%">Sumber Kewangan</th>
            <th width="15%">Amaun (RM)</th>
            <th width="15%">Bentuk Pembiayaan</th>
            <th width="18%">Nama Pembiaya</th>
            <th width="32%">Alamat Pembiaya</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="!!default_sumber_kewangan_rs.length">
            <tr
              v-for="(row, index) in default_sumber_kewangan_rs"
              :key="`row-${index}`"
            >
              <td>
                {{
                  optional(
                    sumberBiayaRs.find((item) => row.sumber_biaya_id == item.id)
                  ).keterangan
                }}
              </td>
              <td>{{ row.amaun | currency("") }}</td>
              <td>
                {{
                  optional(
                    bentukBiayaRs.find((item) => row.bentuk_biaya_id == item.id)
                  ).keterangan
                }}
              </td>
              <td>{{ row.nama_pembiaya }}</td>
              <td>{{ row.alamat_pembiaya }}</td>
              <td>
                <button
                  v-if="!readonly"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="removeSumberKewangan(index)"
                  tabindex="-1"
                >
                  HAPUS
                </button>
              </td>
            </tr>
          </template>
          <tr v-else-if="errors.first('sumber_kewangan')">
            <td colspan="6">
              <div class="alert alert-danger m-0">
                Sila isi maklumat sumber kewangan.
              </div>
            </td>
          </tr>
          <tr v-else>
            <td colspan="6">
              <div class="alert alert-warning m-0">
                Sila isi semua butiran dan tekan butang 'TAMBAH'
              </div>
            </td>
          </tr>

          <input-row
            v-if="!readonly"
            :bentuk-biaya-rs="bentukBiayaRs"
            :sumber-biaya-rs="sumberBiayaRs"
            @added="addSumberKewangan"
          ></input-row>
        </tbody>
      </table>
      <div
        v-if="default_sumber_kewangan_rs.length"
        v-for="(row, index) in default_sumber_kewangan_rs"
      >
        <input
          type="hidden"
          :name="`sumber_kewangan[${index}][sumber_biaya_id]`"
          :value="row.sumber_biaya_id"
        />
        <input
          type="hidden"
          :name="`sumber_kewangan[${index}][amaun]`"
          :value="row.amaun"
        />
        <input
          type="hidden"
          :name="`sumber_kewangan[${index}][bentuk_biaya_id]`"
          :value="row.bentuk_biaya_id"
        />
        <input
          type="hidden"
          :name="`sumber_kewangan[${index}][nama_pembiaya]`"
          :value="row.nama_pembiaya"
        />
        <input
          type="hidden"
          :name="`sumber_kewangan[${index}][alamat_pembiaya]`"
          :value="row.alamat_pembiaya"
        />
      </div>
    </div>

    <div class="card-body">
      <div class="form-group form-row">
        <label class="col-form-label col-lg-6"
          >Nilai Hakmilik (Strata / Individu) Yang Ditebus</label
        >
        <div class="col-lg-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">RM</span>
            </div>
            <money
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.first('nilai_hakmilik_ditebus') }"
              name="nilai_hakmilik_ditebus"
              :disabled="readonly"
              :value="nilai_hakmilik_ditebus"
              @input="(value) => $emit('update:nilai_hakmilik_ditebus', value)"
              v-validate="`decimal:2`"
              data-vv-as="Nilai Hakmilik Yang Ditebus"
            ></money>
            <div class="invalid-feedback">
              {{ errors.first("nilai_hakmilik_ditebus") }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-lg-6"
          >Nilai Jumlah Jualan Yang Terkini
          <span class="label-required">*</span></label
        >
        <div class="col-lg-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">RM</span>
            </div>
            <money
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.first('nilai_jumlah_jualan') }"
              name="nilai_jumlah_jualan"
              :disabled="readonly"
              :value="nilai_jumlah_jualan"
              @input="(value) => $emit('update:nilai_jumlah_jualan', value)"
              v-validate="`required|decimal:2`"
              data-vv-as="Nilai Jumlah Jualan"
            ></money>
            <div class="invalid-feedback">
              {{ errors.first("nilai_jumlah_jualan") }}
            </div>
          </div>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-lg-6"
          >Jumlah <i>Progress Billing</i> Yang Telah Dituntut
          <i>(Secara Kumulatif)</i> <span class="label-required">*</span></label
        >
        <div class="col-lg-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">RM</span>
            </div>
            <money
              type="text"
              class="form-control"
              :class="{ 'is-invalid': errors.first('nilai_jumlah_progbill') }"
              name="nilai_jumlah_progbill"
              :disabled="readonly"
              :value="nilai_jumlah_progbill"
              @input="(value) => $emit('update:nilai_jumlah_progbill', value)"
              v-validate="`required|decimal:2`"
              data-vv-as="Jumlah Progress Billing"
            ></money>
            <div class="invalid-feedback">
              {{ errors.first("nilai_jumlah_progbill") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body">
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">
          NO. AKAUN PEMAJUAN PERUMAHAN
          <!-- <span class="label-required">*</span> -->
        </label>
        <div class="col-md-8">
          <input
            type="text"
            disabled
            class="form-control"
            :value="akaunHda ? akaunHda.no_akaun : ''"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">
          NAMA BANK
          <!-- <span class="label-required">*</span> -->
        </label>
        <div class="col-md-8">
          <input
            type="text"
            disabled
            class="form-control"
            :value="akaunHda ? akaunHda.nama_bank : ''"
          />
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">
          CAWANGAN
          <!-- <span class="label-required">*</span> -->
        </label>
        <div class="col-md-8">
          <textarea
            class="form-control"
            disabled
            cols="30"
            rows="10"
            :value="akaunHda ? akaunHda.alamat1_bank : ''"
          ></textarea>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">
          AMAUN DEPOSIT
          <!-- <span class="label-required">*</span> -->
        </label>
        <div class="col-md-8">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">RM</span>
            </div>
            <input
              type="text"
              class="form-control"
              disabled
              :value="akaunHda ? akaunHda.amaun_asal : '' | currency('')"
            />
          </div>
        </div>
      </div>
    </div>

    <h4 class="card-header">MAKLUMAT JAMINAN BANK / BANK GUARANTEE</h4>
    <div class="table-responsive">
      <table class="table table-card">
        <thead class="thead-light">
          <tr>
            <th>No BG</th>
            <th>Amaun (RM)</th>
            <th>Tarikh Luput</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="jaminanBankRs.length > 0">
            <tr
              v-for="jaminan_bank in jaminanBankRs"
              :key="`jaminan-bank-${jaminan_bank.id}`"
            >
              <td>{{ jaminan_bank.no_rujukan }}</td>
              <td>
                {{
                  jaminan_bank.amaun_nilai
                    | currency("", ",", 2, ".", "front", false)
                }}
              </td>
              <td>{{ jaminan_bank.tarikh_luput }}</td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="99">
              <div class="alert alert-warning">
                Tiada maklumat jaminan bank.
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br />

    <div class="card-header">
      <h4 id="bakiakaunpemajuan">BAKI AKAUN PEMAJUAN PERUMAHAN</h4>
      <p class="mb-0 text-info">
        Sila pastikan jumlah masuk dan jumlah keluar bersamaan dengan penyata
        bank
      </p>
    </div>
    <div class="table-responsive">
      <div class="alert alert-danger" v-if="errors.first('bulan')">
        {{ errors.first("bulan") }}
      </div>
      <table class="table table-card">
        <thead class="thead-light">
          <tr>
            <th class="px-2">Sesi</th>
            <th class="px-2">Bulan</th>
            <th class="px-2" width="10%">Baki Bawa Bawah</th>
            <th class="px-2" width="10%">Jumlah Masuk</th>
            <th class="px-2" width="10%">Jumlah Keluar</th>
            <th class="px-2" width="10%">Baki Bawa ke Hadapan</th>
            <th class="px-2" width="10%">
              Muat Naik <br /><span class="font-weight-normal"
                >Penyata Bank</span
              >
            </th>
            <th class="px-2" width="25%">
              Muat Naik<br />
              <span class="font-weight-normal"
                >Penyata Lampiran A <br />& Dokumen pengeluaran HDA</span
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :rowspan="hasKuiri ? 3 + kuiriCount : 3">
              {{ sesi.keterangan }}<br />
              {{ sesi.tahun_lapor }}
            </td>
            <td>{{ sesi.bulan[0] }}</td>
            <td class="px-1">
              <money
                type="text"
                class="form-control"
                onclick="this.select()"
                :class="{
                  'is-invalid': errors.first(`bulan[0][baki_bawa_bawah]`),
                }"
                :name="`bulan[0][baki_bawa_bawah]`"
                v-model="bulan_rs[0].baki_bawa_bawah"
                v-validate="'required|decimal:2'"
              ></money>
            </td>
            <td class="px-1">
              <money
                type="text"
                class="form-control"
                onclick="this.select()"
                :class="{
                  'is-invalid': errors.first('bulan[0][jumlah_masuk]'),
                }"
                name="bulan[0][jumlah_masuk]"
                v-model="bulan_rs[0].jumlah_masuk"
                v-validate="'required|decimal:2'"
              ></money>
            </td>
            <td class="px-1">
              <input
                type="text"
                class="form-control"
                onclick="this.select()"
                :class="{
                  'is-invalid': errors.first('bulan[0][jumlah_keluar]'),
                }"
                name="bulan[0][jumlah_keluar]"
                :value="monthTotalKeluar(0) | currency('')"
                disabled
              />
            </td>
            <td class="px-1">
              <input
                type="text"
                class="form-control"
                disabled
                :value="bakiBawaHadapan(0) | currency('')"
              />
            </td>
            <template v-if="default_maklumat">
              <template
                v-if="readonly && !bulan_rs[0].penyata_kewangan_bulanan"
              >
                <td>
                  <span>Tidak disertakan</span>
                </td>
                <td>
                  <span>Tidak disertakan</span>
                </td>
              </template>
              <template v-else class="pl-1">
                <template
                  v-if="
                    (sesi.tahun_lapor == '2022' && sesi.id == '4') ||
                    parseInt(sesi.tahun_lapor) > 2022
                  "
                >
                  <td class="px-2">
                    <file-upload
                      type="file"
                      accept="application/pdf"
                      :name="`bulan[0][penyata_kewangan_bulanan]`"
                      class="form-control flex-grow-1 mr-2 mw-250px"
                      :class="{
                        'is-invalid': errors.first(
                          `bulan[0][penyata_kewangan_bulanan]`
                        ),
                      }"
                      :doc="bulan_rs[0].penyata_kewangan_bulanan"
                      @upload="(file) => uploadDoc(0, file, 'penyata')"
                      @remove="removeDoc(0, 'penyata')"
                      v-validate="monthTotalKeluar(0) > 0 ? 'required' : ''"
                      :readonly="readonly || (hasKuiri && !bulan_rs[0].kuiri)"
                    />
                  </td>
                  <td class="px-2">
                    <file-upload
                      type="file"
                      accept="application/pdf"
                      :name="`bulan[0][lampiran_a]`"
                      class="form-control flex-grow-1 mr-2 mw-250px"
                      :class="{
                        'is-invalid': errors.first(`bulan[0][lampiran_a]`),
                      }"
                      :doc="bulan_rs[0].lampiran_a"
                      @upload="(file) => uploadDoc(0, file, 'lampiran')"
                      @remove="removeDoc(0, 'lampiran')"
                      v-validate="monthTotalKeluar(0) > 0 ? 'required' : ''"
                      :readonly="false"
                      size="15MB"
                    />
                  </td>
                </template>
                <template v-else>
                  <td class="px-2">
                    <file-upload
                      type="file"
                      accept="application/pdf"
                      :name="`bulan[0][penyata_kewangan_bulanan]`"
                      class="form-control flex-grow-1 mr-2 mw-250px"
                      :class="{
                        'is-invalid': errors.first(
                          `bulan[0][penyata_kewangan_bulanan]`
                        ),
                      }"
                      :doc="bulan_rs[0].penyata_kewangan_bulanan"
                      @upload="(file) => uploadDoc(0, file, 'penyata')"
                      @remove="removeDoc(0, 'penyata')"
                      :readonly="readonly || (hasKuiri && !bulan_rs[0].kuiri)"
                    />
                  </td>
                  <td class="px-2">
                    <file-upload
                      type="file"
                      accept="application/pdf"
                      :name="`bulan[0][lampiran_a]`"
                      class="form-control flex-grow-1 mr-2 mw-250px"
                      :class="{
                        'is-invalid': errors.first(`bulan[0][lampiran_a]`),
                      }"
                      :doc="bulan_rs[0].lampiran_a"
                      @upload="(file) => uploadDoc(0, file, 'lampiran')"
                      @remove="removeDoc(0, 'lampiran')"
                      :readonly="false"
                      size="15MB"
                    />
                  </td>
                </template>
              </template>
            </template>
            <template v-else>
              <template
                v-if="readonly && !bulan_rs[0].penyata_kewangan_bulanan"
              >
                <td><span>Tidak disertakan</span></td>
                <td><span>Tidak disertakan</span></td>
              </template>
              <template v-else>
                <td class="px-2">
                  <input-file
                    class="form-control"
                    :class="{
                      'is-invalid': errors.first(
                        `bulan[0][penyata_kewangan_bulanan]`
                      ),
                    }"
                    :name="`bulan[0][penyata_kewangan_bulanan]`"
                    :doc="bulan_rs[0].penyata_kewangan_bulanan"
                    v-validate="'required'"
                    :readonly="readonly"
                  />
                </td>
                <td class="px-2">
                  <input-file
                    class="form-control"
                    :class="{
                      'is-invalid': errors.first(`bulan[0][lampiran_a]`),
                    }"
                    :name="`bulan[0][lampiran_a]`"
                    :doc="bulan_rs[0].lampiran_a"
                    :readonly="readonly"
                  />
                </td>
              </template>
            </template>
          </tr>
          <tr v-if="hasKuiri && bulan_rs[0].kuiri">
            <td colspan="7" class="bg-danger-light">
              <div class="d-flex">
                <div class="w-100 mr-2">
                  <label style="font-weight: 500"
                    >Catatan Kuiri Penyata Bank</label
                  >
                  <textarea class="form-control" disabled>{{
                    bulan_rs[0].kuiri.catatan_kuiri
                  }}</textarea>
                  <br />
                  <div>
                    <strong>Catatan Maklumbalas Penyata Bank</strong>
                    <textarea
                      @click.stop
                      class="form-control"
                      :name="`kuiri_permohonan_rs[${bulan_rs[0].kuiri.id}][catatan_maklumbalas_kuiri]`"
                      v-model="bulan_rs[0].kuiri.catatan_maklumbalas_kuiri"
                      :disabled="bulan_rs[0].kuiri.tarikh_maklumbalas_kuiri"
                    ></textarea>
                  </div>
                </div>
                <div class="w-100 ml-2">
                  <label style="font-weight: 500"
                    >Catatan Kuiri Lampiran A</label
                  >
                  <textarea class="form-control" disabled>{{
                    bulan_rs[0].kuiri.catatan_kuiri_lampiran_a
                  }}</textarea>
                  <br />
                  <div>
                    <strong>Catatan Maklumbalas Lampiran A</strong>
                    <textarea
                      @click.stop
                      class="form-control"
                      :name="`kuiri_permohonan_rs[${bulan_rs[0].kuiri.id}][catatan_maklumbalas_kuiri_lampiran_a]`"
                      v-model="
                        bulan_rs[0].kuiri.catatan_maklumbalas_kuiri_lampiran_a
                      "
                      :disabled="bulan_rs[0].kuiri.tarikh_maklumbalas_kuiri_lampiran_a"
                    ></textarea>
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <template v-for="(sesi_bulan, index) in sesi.bulan" v-if="index > 0">
            <tr>
              <td>{{ sesi_bulan }}</td>
              <td class="px-1">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="bakiBawaBawah(index) | currency('')"
                />
              </td>
              <td class="px-1">
                <money
                  type="text"
                  class="form-control"
                  onclick="this.select()"
                  :class="{
                    'is-invalid': errors.first(`bulan[${index}][jumlah_masuk]`),
                  }"
                  :name="`bulan[${index}][jumlah_masuk]`"
                  v-model="bulan_rs[index].jumlah_masuk"
                  v-validate="'required|decimal:2'"
                ></money>
              </td>
              <td class="px-1">
                <input
                  type="text"
                  class="form-control"
                  onclick="this.select()"
                  :class="{
                    'is-invalid': errors.first(
                      `bulan[${index}][jumlah_keluar]`
                    ),
                  }"
                  :name="`bulan[${index}][jumlah_keluar]`"
                  :value="monthTotalKeluar(index) | currency('')"
                  disabled
                />
              </td>
              <td class="px-1">
                <input
                  type="text"
                  class="form-control"
                  disabled
                  :value="bakiBawaHadapan(index) | currency('')"
                />
              </td>
              <template v-if="default_maklumat">
                <template
                  v-if="readonly && !bulan_rs[index].penyata_kewangan_bulanan"
                >
                  <td>
                    <span>Tidak disertakan</span>
                  </td>
                  <td>
                    <span>Tidak disertakan</span>
                  </td>
                </template>
                <template v-else>
                  <template
                    v-if="
                      (sesi.tahun_lapor == '2022' && sesi.id == '4') ||
                      parseInt(sesi.tahun_lapor) > 2022
                    "
                  >
                    <td class="px-2">
                      <file-upload
                        type="file"
                        accept="application/pdf"
                        :name="`bulan[${index}][penyata_kewangan_bulanan]`"
                        class="form-control flex-grow-1 mr-2 mw-250px"
                        :class="{
                          'is-invalid': errors.first(
                            `bulan[${index}][penyata_kewangan_bulanan]`
                          ),
                        }"
                        :doc="bulan_rs[index].penyata_kewangan_bulanan"
                        @upload="(file) => uploadDoc(index, file, 'penyata')"
                        @remove="removeDoc(index, 'penyata')"
                        v-validate="
                          monthTotalKeluar(index) > 0 ? 'required' : ''
                        "
                        :readonly="
                          readonly || (hasKuiri && !bulan_rs[index].kuiri)
                        "
                      />
                    </td>
                    <td class="px-2">
                      <file-upload
                        type="file"
                        accept="application/pdf"
                        :name="`bulan[${index}][lampiran_a]`"
                        class="form-control flex-grow-1 mr-2 mw-250px"
                        :class="{
                          'is-invalid': errors.first(
                            `bulan[${index}][lampiran_a]`
                          ),
                        }"
                        :doc="bulan_rs[index].lampiran_a"
                        @upload="(file) => uploadDoc(index, file, 'lampiran')"
                        @remove="removeDoc(index, 'lampiran')"
                        v-validate="
                          monthTotalKeluar(index) > 0 ? 'required' : ''
                        "
                        :readonly="false"
                        size="15MB"
                      />
                    </td>
                  </template>
                  <template v-else>
                    <td class="px-2">
                      <file-upload
                        type="file"
                        accept="application/pdf"
                        :name="`bulan[${index}][penyata_kewangan_bulanan]`"
                        class="form-control flex-grow-1 mr-2 mw-250px"
                        :class="{
                          'is-invalid': errors.first(
                            `bulan[${index}][penyata_kewangan_bulanan]`
                          ),
                        }"
                        :doc="bulan_rs[index].penyata_kewangan_bulanan"
                        @upload="(file) => uploadDoc(index, file, 'penyata')"
                        @remove="removeDoc(index, 'penyata')"
                        :readonly="
                          readonly || (hasKuiri && !bulan_rs[index].kuiri)
                        "
                      />
                    </td>
                    <td class="px-2">
                      <file-upload
                        type="file"
                        accept="application/pdf"
                        :name="`bulan[${index}][lampiran_a]`"
                        class="form-control flex-grow-1 mr-2 mw-250px"
                        :class="{
                          'is-invalid': errors.first(
                            `bulan[${index}][lampiran_a]`
                          ),
                        }"
                        :doc="bulan_rs[index].lampiran_a"
                        @upload="(file) => uploadDoc(index, file, 'lampiran')"
                        @remove="removeDoc(index, 'lampiran')"
                        :readonly="false"
                        size="15MB"
                      />
                    </td>
                  </template>
                </template>
              </template>
              <template v-else>
                <template
                  v-if="readonly && !bulan_rs[index].penyata_kewangan_bulanan"
                >
                  <td><span>Tidak disertakan</span></td>
                  <td><span>Tidak disertakan</span></td>
                </template>
                <template v-else>
                  <td class="px-2">
                    <input-file
                      class="form-control"
                      :class="{
                        'is-invalid': errors.first(
                          `bulan[${index}][penyata_kewangan_bulanan]`
                        ),
                      }"
                      :name="`bulan[${index}][penyata_kewangan_bulanan]`"
                      :doc="bulan_rs[index].penyata_kewangan_bulanan"
                      v-validate="'required'"
                      :readonly="readonly"
                    />
                  </td>
                  <td class="px-2">
                    <input-file
                      class="form-control"
                      :class="{
                        'is-invalid': errors.first(
                          `bulan[${index}][lampiran_a]`
                        ),
                      }"
                      :name="`bulan[${index}][lampiran_a]`"
                      :doc="bulan_rs[index].lampiran_a"
                      v-validate="monthTotalKeluar(0) > 0 ? 'required' : ''"
                      :readonly="readonly"
                    />
                  </td>
                </template>
              </template>
            </tr>
            <tr v-if="hasKuiri && bulan_rs[index].kuiri">
              <td colspan="99" class="bg-danger-light">
                <div class="d-flex">
                  <div class="w-100 mr-2">
                    <label style="font-weight: 500"
                      >Catatan Kuiri Penyata Bank</label
                    >
                    <textarea class="form-control" disabled>{{
                      bulan_rs[index].kuiri.catatan_kuiri
                    }}</textarea>
                    <br />
                    <div>
                      <strong>Catatan Maklumbalas</strong>
                      <textarea
                        @click.stop
                        class="form-control"
                        :name="`kuiri_permohonan_rs[${bulan_rs[index].kuiri.id}][catatan_maklumbalas_kuiri]`"
                        v-model="
                          bulan_rs[index].kuiri.catatan_maklumbalas_kuiri
                        "
                        :disabled="
                          bulan_rs[index].kuiri.tarikh_maklumbalas_kuiri
                        "
                      ></textarea>
                    </div>
                  </div>
                  <div  class="w-100 mr-2">
                    <label style="font-weight: 500"
                      >Catatan Kuiri Lampiran A</label
                    >
                    <textarea class="form-control" disabled>{{
                      bulan_rs[index].kuiri.catatan_kuiri_lampiran_a
                    }}</textarea>
                    <br />
                    <div>
                      <strong>Catatan Maklumbalas Lampiran A</strong>
                      <textarea
                        @click.stop
                        class="form-control"
                        :name="`kuiri_permohonan_rs[${bulan_rs[index].kuiri.id}][catatan_maklumbalas_kuiri_lampiran_a]`"
                        v-model="
                          bulan_rs[index].kuiri.catatan_maklumbalas_kuiri_lampiran_a
                        "
                        :disabled="
                          bulan_rs[index].kuiri.tarikh_maklumbalas_kuiri_lampiran_a
                        "
                      ></textarea>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <br />

    <div class="card-header">
      <h4>BUTIRAN PENGELUARAN HDA</h4>
      <p class="mb-0 text-info">
        Pastikan semua perihal pengeluaran yang diisi di bahagian ini bersamaan
        dengan jumlah pengeluaran dalam penyata bank
      </p>
    </div>
    <div class="table-responsive">
      <table class="table table-card">
        <thead class="thead-light">
          <tr>
            <th width="2%" class="text-center">Peraturan 7</th>
            <th width="50%">Perihal Pengeluaran</th>
            <th
              class="text-center"
              v-for="(bulan, index) in sesi.bulan"
              width="16%"
            >
              <div>{{ bulan }}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="perihal in perihalPeraturan7Rs" :key="perihal.id">
            <td class="text-center">{{ perihal.p7 }}</td>
            <td>{{ perihal.keterangan }}</td>
            <td>
              <money
                type="text"
                :name="`bulan[0][${perihal.id}]`"
                v-model="bulan_rs[0][perihal.id]"
                class="form-control"
                onclick="this.select()"
                :class="{
                  'is-invalid': errors.first(`bulan[0][${perihal.id}]`),
                }"
                v-validate="'required|decimal:2'"
              ></money>
            </td>
            <td>
              <money
                type="text"
                :name="`bulan[1][${perihal.id}]`"
                v-model="bulan_rs[1][perihal.id]"
                class="form-control"
                onclick="this.select()"
                :class="{
                  'is-invalid': errors.first(`bulan[1][${perihal.id}]`),
                }"
                v-validate="'required|decimal:2'"
              ></money>
            </td>
            <td>
              <money
                type="text"
                :name="`bulan[2][${perihal.id}]`"
                v-model="bulan_rs[2][perihal.id]"
                class="form-control"
                onclick="this.select()"
                :class="{
                  'is-invalid': errors.first(`bulan[2][${perihal.id}]`),
                }"
                v-validate="'required|decimal:2'"
              ></money>
              <!-- <money type="text" :name="`bulan[2][${perihal.id}]`" v-model="bulan_rs[2][perihal.id]" class="form-control" onclick="this.select()" :class="{'is-invalid': errors.first(`bulan[2][${perihal.id}]`)}" v-validate="'required|decimal:2'" :disabled="perihal.id === 'o'"></money> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import InputRow from "./InputRow.vue";

export default {
  name: "BahagianB",

  inject: ["$validator"],

  components: {
    InputRow,
  },

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },

    hasKuiri: {
      type: Boolean,
      default: false,
    },

    akaunHda: {
      type: Object,
    },

    bentukBiayaRs: {
      type: Array,
    },

    sumberBiayaRs: {
      type: Array,
    },

    jaminanBankRs: {
      type: Array,
    },

    perihalPeraturan7Rs: {
      type: Array,
      default: [],
    },

    sesi: {
      type: Object,
    },

    default_maklumat: Object,

    nilai_hakmilik_ditebus: {
      default: 0,
    },

    nilai_jumlah_jualan: {
      default: 0,
    },

    nilai_jumlah_progbill: {
      default: 0,
    },

    default_sumber_kewangan_rs: {
      type: Array,
      default: () => [],
    },

    default_bulan_rs: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      bulan_rs: JSON.parse(JSON.stringify(this.default_bulan_rs)),
    };
  },

  methods: {
    addSumberKewangan(row) {
      const sumber_kewangan_rs = JSON.parse(
        JSON.stringify(this.default_sumber_kewangan_rs)
      );
      sumber_kewangan_rs.push(row);

      this.$emit("update:default_sumber_kewangan_rs", sumber_kewangan_rs);
    },

    monthTotalKeluar(index) {
      return this.perihalPeraturan7Rs.reduce((total, perihal) => {
        // return (parseInt(total * 100, 10) + parseInt(this.bulan_rs[index][perihal.id] * 100, 10)) / 100;
        return total + this.bulan_rs[index][perihal.id];
      }, 0);
    },

    removeSumberKewangan(index) {
      const sumber_kewangan_rs = JSON.parse(
        JSON.stringify(this.default_sumber_kewangan_rs)
      );
      sumber_kewangan_rs.splice(index, 1);

      this.$emit("update:default_sumber_kewangan_rs", sumber_kewangan_rs);
    },

    uploadDoc(index, file, type, max = 5) {
      if (!this.default_maklumat) {
        return;
      }

      const bulanId = this.sesi.bulan_id[index];

      const formData = new FormData();

      // append the files to FormData
      formData.append("doc", file, file.name);

      // save it
      axios
        .post(
          `/pemaju/laporan-kemajuan-kewangan/dokumen/${this.default_maklumat.id}/${bulanId}/${type}`,
          formData
        )
        .then((response) => {
          if (type === "penyata") {
            this.bulan_rs[index].penyata_kewangan_bulanan = response.data;
          } else {
            this.bulan_rs[index].lampiran_a = response.data;
          }
        })
        .catch(() => {});
    },

    removeDoc(index, type) {
      if (!this.default_maklumat) {
        return;
      }

      const bulanId = this.sesi.bulan_id[index];

      // save it
      axios
        .delete(
          `/pemaju/laporan-kemajuan-kewangan/dokumen/${this.default_maklumat.id}/${bulanId}/${type}`
        )
        .then((response) => {
          if (type === "penyata") {
            this.bulan_rs[index].penyata_kewangan_bulanan = null;
          } else {
            this.bulan_rs[index].lampiran_a = null;
          }
        })
        .catch(() => {});
    },

    bakiBawaBawah(index) {
      if (index === 0) {
        if (this.bulan_rs.hasOwnProperty(index)) {
          const bulan = this.bulan_rs[index];
          if (isNaN(bulan.baki_bawa_bawah)) {
            return 0.0;
          }
          return parseFloat(bulan.baki_bawa_bawah);
        }
      }

      return this.bakiBawaHadapan(index - 1);
    },

    bakiBawaHadapan(index) {
      if (this.bulan_rs.hasOwnProperty(index)) {
        const bulan = this.bulan_rs[index];
        if (isNaN(bulan.jumlah_masuk) || isNaN(this.monthTotalKeluar(index))) {
          return 0.0;
        }

        return (
          this.bakiBawaBawah(index) +
          (bulan.jumlah_masuk - this.monthTotalKeluar(index))
        );
      }
    },
  },

  watch: {
    bulan_rs: {
      deep: true,
      handler: function () {
        const bulan_rs = JSON.parse(JSON.stringify(this.bulan_rs));
        this.$emit("update:default_bulan_rs", bulan_rs);
      },
    },
  },

  computed: {
    kuiriCount() {
      if (this.hasKuiri) {
        return this.default_bulan_rs.reduce((total, bulan) => {
          if (!!bulan.kuiri) {
            return total + 1;
          }

          return total;
        }, 0);
      }

      return 0;
    },
  },
};
</script>
