<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="!canSubmit">
        Jumlah Peruntukan Bilangan Unit Kuota Bumiputera mesti melibihi <strong>{{ totalShouldKuotaBumi }}</strong>
      </div>
      <div class="table-responsive">
        <table class="bg-white table table-hover table-card">
          <thead class="thead-light">
          <tr>
            <th>Bil</th>
            <th>Jenis Rumah</th>
            <th>Harga (Min)</th>
            <th>Harga (Max)</th>
            <th>Bil Unit Diluluskan</th>
            <th width="15%">Peruntukan Bilangan Unit Kuota Bumiputera</th>
            <th width="15%">Bilangan Rekod Unit Kuota Bumiputera</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in form" :key="`bangunan-${item.id}`">
            <td>{{ index + 1 }}</td>
            <td>{{ item.jenis_rumah.keterangan }}</td>
            <td>{{ item.harga_min | currency('RM', ',', 2, '.', 'front', false) }}</td>
            <td>{{ item.harga_max | currency('RM', ',', 2, '.', 'front', false) }}</td>
            <td>{{ item.maklumat7f.bil_dilulus || item.bil_unit }}</td>
            <td><input type="text" class="form-control" v-model="item.maklumat7f.bil_kuotabumi"></td>
            <td><span class="text-danger">{{ getTotalKuotaBumi(item) }}</span></td>
          </tr>
          </tbody>
          <tfoot>
          <tr style="font-weight: bold">
            <td colspan="4">JUMLAH KESELURUHAN</td>
            <td>{{ totalUnit }}</td>
            <td>{{ totalInputKuotaBumi }}</td>
            <td>{{ form.reduce((total, item) => total + getTotalKuotaBumi(item), 0)}}</td>
          </tr>
          </tfoot>
        </table>
      </div>
      <br>
      <div>Jumlah Keseluruhan <strong>"PERUNTUKAN BILANGAN UNIT KUOTA BUMIPUTERA"</strong> yang perlu dimasukkan
        adalah <strong>{{ totalShouldKuotaBumi }}</strong> unit atau lebih.
      </div>

    </div>
    <div class="card-footer">
      <div class="text-center">
        <button class="btn btn-primary" @click.prevent="submit" :disabled="!canSubmit || submitting">SIMPAN</button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BahagianB",

    props: {
      pemajuan: Object,
    },

    data() {
      return {
        submitting: false,
        form: JSON.parse(JSON.stringify(this.pemajuan.pembangunan_rs)),
      };
    },

    methods: {
      submit() {
        if (!this.canSubmit) {
          return;
        }

        this.submitting = true;

        axios.post(`/pemaju/kuota-bumi/${this.pemajuan.id}/bahagian-b`, this.form)
          .then((response) => {
            this.$emit('update:pemajuan', JSON.parse(JSON.stringify(response.data)))
            this.$emit('done')
          })
          .catch(error => {

          })
          .then(() => {
            this.submitting = false;
          });

      },

      getTotalKuotaBumi(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.kuota_bumi) {
            return total + 1;
          }

          return total;
        }, 0);
      }
    },

    computed: {
      canSubmit() {
        return this.totalInputKuotaBumi >= this.totalShouldKuotaBumi;
      },

      totalShouldKuotaBumi() {
        if (this.pemajuan.pengesahan_kuotabumi !== 'Y') {
          return 0;
        }

        return Math.ceil(this.totalUnit * (this.pemajuan.peratus_kuotabumi / 100), 0);
      },

      totalInputKuotaBumi() {
        return this.form.reduce((total, item) => total + parseInt(item.maklumat7f.bil_kuotabumi || 0, 10), 0)
      },

      totalUnit() {
        return this.form.reduce((total, item) => {
          return total + parseInt(item.maklumat7f.bil_dilulus || item.bil_unit, 10);
        }, 0);
      }
    }
  }
</script>
