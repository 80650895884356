<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">JENIS IKLAN</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" for="keterangan_iklan_lain">Jenis Iklan</label>
              <div class="col-md-8">
                <input
                  id="keterangan_iklan_lain"
                  type="text" name="keterangan_iklan_lain"
                  class="form-control" :class="{'is-invalid': errors.first('keterangan_iklan_lain')}"
                  :value="form.keterangan_iklan_lain" @input="form.keterangan_iklan_lain = $event.target.value.toUpperCase()"
                  v-validate="'required'"
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
      <
    </div>
  </div>
</template>

<script>
  export default {
    name: "TambahJenisIklanModal",

    mounted() {
      vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
    },

    props: {
      readonly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        modalId: 'tambahjenisiklan',
        loading: false,
        saving: false,
        form: {},
        response: () => {
        },
      }
    },

    methods: {
      onOpen({ keterangan_iklan_lain }, callback) {
        this.form.keterangan_iklan_lain = keterangan_iklan_lain || null;
        this.response = callback;
      },

      onClose() {
      },

      close() {
        vmodal.close(this.modalId);
      },

      async save() {
        const passed = await this.$validator.validateAll();

        if (!passed) {
          return;
        }

        this.response({...this.form});
        this.close();
      },
    },

    computed: {
      isOpen() {
        return this.$root.openedModals.indexOf(this.modalId) !== -1
      },
    }
  }
</script>

<style scoped>

</style>
