<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Tempoh Lesen (Tahun)</label>
        <div class="col-12 col-sm-8 col-md-8">
          <input type="text" v-if="!maklumat.flag_pembaharuan_lesen" value="TIDAK BERKAITAN" name="tempoh-lesen" disabled="disabled" class="form-control bg-light ">
          <select v-else name="tempoh_lesen" id="tempoh_lesen" class="form-control"
            :class="{'is-invalid': errors.first(`tempoh_lesen`)}"
            v-model="form.tempoh_lesen"
            v-validate="'required'"
            :disabled="readonly"
            @change="tempohLesen()"
          >
            <option :value="null">Sila Pilih Tempoh Lesen</option>
            <option :value="1">1 Tahun</option>
            <option :value="3">3 Tahun</option>
            <option :value="5">5 Tahun</option>
          </select>
        </div>
      </div>

      <div class="form-group row" v-show="penalti" v-if="maklumat.pembaharuan_tempoh_lesen">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&nbsp;</label>
        <div class="col-12 col-sm-8 col-md-8">
          <div class="card border border-warning">
            <div class="card-header text-warning pb-1 border-warning">
              <h5 class="card-title"><i class="fas fa-exclamation-triangle"></i> Pemakluman</h5>
            </div>
            <div class="card-body">
              <p style="font-size:11px;"> DL perlu diperbaharui bermula tamat tempoh <strong>{{form.tarikh_luput_semasa}}</strong>. Tempoh pembaharuan terdahulu, <strong>{{maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti}}</strong> tahun. </p>

              <p style="font-size:11px;" v-if="form.tempoh_lesen">Tempoh pembaharuan tahun semasa, <strong>{{form.tempoh_lesen}}</strong> tahun. </p>
              <p style="font-size:11px;" v-if="form.total_tempoh_lesen">Jumlah tempoh keseluruhan pembaharuan DL <strong>{{form.total_tempoh_lesen}}</strong> tahun</p>
            </div>
          </div>
        </div>
      </div>
      
      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Dokumen Surat Iringan <br><small>&#9;(Perincian Tujuan Permohonan)</small></label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_iringan_pemaju"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_iringan_pemaju`)}"
            :doc="maklumat.doc_iringan_pemaju"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_iringan_pemaju', file, callback)"
            @remove="removeDoc('doc_iringan_pemaju')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Dokumen DL Terkini</label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_dl_terkini"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_dl_terkini`)}"
            :doc="maklumat.doc_dl_terkini"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_dl_terkini', file, callback)"
            @remove="removeDoc('doc_dl_terkini')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>
      <hr>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBar from "../../common/ErrorBar";
export default {
  name: "BahagianE",
  components: {ErrorBar},
  props: {
    maklumat: Object,
    lesen: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },
  
  created: function(){
    this.onOpen()
  },

  data() {
    return {
      loading: false,
      penalti: false,
      form: {
        tempoh_lesen: this.maklumat.tempoh_lesen,
        tarikh_luput_semasa:'',
        total_tempoh_lesen:'',
      },
    }
  },

  methods: {
    onOpen(){
      if(this.maklumat.flag_pembaharuan_lesen){
        if(this.maklumat.pembaharuan_tempoh_lesen){
          this.form.tarikh_luput_semasa = moment(String(this.maklumat.pembaharuan_tempoh_lesen.tarikh_luput_semasa)).format('DD/MM/YYYY');

          if(this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti > 0){
            this.form.total_tempoh_lesen= this.form.tempoh_lesen+this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti
            this.penalti = true;
          }
        }
      }
    },

    tempohLesen() {
      if(this.maklumat.pembaharuan_tempoh_lesen){
        if(this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti > 0){
          this.form.total_tempoh_lesen= this.form.tempoh_lesen+this.maklumat.pembaharuan_tempoh_lesen.tempoh_lesen_penalti
          this.penalti = true;
        }
      }
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      this.passes = false;
      // save it
      axios.post(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      this.passes = false;

      axios.delete(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/bahagian-e`, this.form)
        .then((response) => {
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    }
  }
}
</script>

<style scoped>

</style>
