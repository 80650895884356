<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps nav">
        <li>
          <a :class="{ 'show active': isCurrentTab('#bahagian-a') }"
            href="#bahagian-a">MAKLUMAT PEMAJU PERUMAHAN</a>
        </li>
        <li class>
          <a :class="{ 'show active': isCurrentTab('#bahagian-b') }"
            href="#bahagian-b">WANG PENDAHULUAN PEMAJUAN PERUMAHAN</a>
        </li>
        <li class>
          <a :class="{ 'show active': isCurrentTab('#bahagian-c') }"
            href="#bahagian-c"><span v-if="hasDocKuiri"
              class="text-danger"><i class="fas fa-exclamation-circle"></i></span> SALINAN DOKUMEN SOKONGAN</a>
        </li>
      </ul>

      <div class="tab-content mt-4 mx-0">
        <div class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-a') }"
          id="bahagian-a"
          role="tabpanel">
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN MAKLUMAT PEMAJU</h4>

              <maklumat-pemaju-with-penghantar-and-pemajuan :pemaju="pemaju"
                :pemajuan="pemajuan"
                :pengarah="pengarah"
                :pemaju_nama_pegawai.sync="maklumat.pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="maklumat.pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="maklumat.pemaju_no_bimbit"
                :pemaju_no_faks.sync="maklumat.pemaju_no_faks"
                :pemaju_email.sync="maklumat.pemaju_email"></maklumat-pemaju-with-penghantar-and-pemajuan>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button class="btn btn-primary btn-sm"
                  @click.prevent="save"
                  :disabled="saving">SIMPAN</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-b') }"
          id="bahagian-b"
          role="tabpanel">
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">MAKLUMAT AKAUN PEMAJUAN PERUMAHAN (HDA)</h4>

              <bahagian-b :akaun-hda="akaunHda"
                :jaminan-bank="jaminanBank"
                :amaun_nilai.sync="maklumat.amaun_nilai"
                :jenis_permohonan_6a.sync="maklumat.jenis_permohonan_6a"
                :kod_jenis_wp_id.sync="maklumat.kod_jenis_wp_id"
                :disabled-jenis-permohonan6a="true"></bahagian-b>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button class="btn btn-primary btn-sm"
                  @click.prevent="save"
                  :disabled="saving">SIMPAN</button>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-c') }"
          id="bahagian-c"
          role="tabpanel">
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">SALINAN DOKUMEN SOKONGAN</h4>
              <maklumat-dokumen-sokongan :default_docs.sync="maklumat.dokumen_rs"
                :show-reply="reply"
                :readonly="readonly"></maklumat-dokumen-sokongan>
            </div>
            <div class="card-footer">
              <div class="text-right">
                <button class="btn btn-primary btn-sm"
                  @click="submit">HANTAR</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BahagianB from "./BahagianB.vue";
import MaklumatPemajuWithPenghantarAndPemajuan from "../../common/MaklumatPemajuWithPenghantarAndPemajuan";
import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";

export default {
  components: {
    MaklumatDokumenSokongan,
    MaklumatPemajuWithPenghantarAndPemajuan,
    BahagianB,
  },

  props: {
    pemaju: {
      type: Object
    },

    pemajuan: {
      type: Object
    },

    akaunHda: {
      type: Object
    },

    jaminanBank: {
      type: Object
    },

    pengarah: {
      type: Object
    },

    default_maklumat: Object,
    default_permohonan: Object,

    errorBag: {
      type: Object
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    reply: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      saving: false,

      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    validate(skipDocValidation) {
      if (this.$validator.errors.first('pemaju_nama_pegawai') ||
        this.$validator.errors.first('pemaju_no_bimbit') ||
        this.$validator.errors.first('pemaju_no_faks') ||
        this.$validator.errors.first('pemaju_email')
      ) {
        window.location.hash = '#bahagian-a';
        return true;
      } else if (this.$validator.errors.first('amaun_nilai') ||
        this.$validator.errors.first('kod_jenis_wp_id') ||
        this.$validator.errors.first('jenis_permohonan_6a')
        // this.$validator.errors.first('tarikh_penutupan_akaun')
      ) {
        window.location.hash = '#bahagian-b';
        return true;
      } else if (!skipDocValidation) {
        window.location.hash = '#bahagian-c';
        return true;
      }

      return false;
    },

    async save() {
      const passes = await this.$validator.validateAll();

      if (!passes && this.validate(true)) {
        return;
      }

      this.saving = true;
      axios.put(`/pemaju/permohonan/${this.permohonan.id}`, this.maklumat)
        .then((response) => {
          this.maklumat = response.data;
        })
        .catch(() => {

        })
        .then(() => {
          this.saving = false;
        });
    },

    async submit(ev) {
      ev.preventDefault();

      const passes = await this.$validator.validateAll();

      if (!passes && this.validate()) {
        return;
      }

      swalconfirm()
        .then(result => {
          if (result.value) {
            ev.target.form.submit();
          }
        });
    }
  },

  computed: {
    hasDocKuiri() {
      return this.maklumat.dokumen_rs.some((dokumen) => {
        return !dokumen.flag_lulus;
      });
    }
  }
};
</script>
