<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps nav">
        <li>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-a') }"
            href="#bahagian-a"
            >A. MAKLUMAT PEMAJU PERUMAHAN</a
          >
        </li>
        <li class>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-b') }"
            href="#bahagian-b"
            >B. MAKLUMAT AKAUN PEMAJUAN PERUMAHAN (HDA)</a
          >
        </li>
        <li class>
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-c') }"
            href="#bahagian-c"
            >C. SALINAN DOKUMEN SOKONGAN</a
          >
        </li>
        <li v-if="!readonly">
          <a
            :class="{ 'show active': isCurrentTab('#bahagian-d') }"
            href="#bahagian-d"
            >D. PERAKUAN PEMAJU PERUMAHAN</a
          >
        </li>
      </ul>

      <div class="tab-content mt-4 mx-0">
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-a') }"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN MAKLUMAT PEMAJU</h4>

              <maklumat-pemaju-with-penghantar-and-pemajuan
                :pemaju="pemaju"
                :pemajuan="pemajuan"
                :pengarah="pengarah"
                :pemaju_nama_pegawai.sync="pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="pemaju_no_bimbit"
                :pemaju_no_faks.sync="pemaju_no_faks"
                :pemaju_email.sync="pemaju_email"
              ></maklumat-pemaju-with-penghantar-and-pemajuan>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-b') }"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">
                MAKLUMAT AKAUN PEMAJUAN PERUMAHAN (HDA)
              </h4>

              <bahagian-b
                :akaun-hda="akaunHda"
                :tarikh_dibuka.sync="tarikh_dibuka"
                :kod_jenis_wp_id.sync="kod_jenis_wp_id"
                :wang_pendahuluan.sync="wang_pendahuluan"
                :baki_terkini.sync="baki_terkini"
              ></bahagian-b>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-c') }"
          id="bahagian-c"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">SALINAN DOKUMEN SOKONGAN</h4>
              <p class="text-info">Sila rujuk garis panduan bagi contoh-contoh dokumen yang perlu dimuat naik.</p>

              <maklumat-dokumen-sokongan
                :doc-configs="docConfigs"
                :default_docs="default_docs"
                :readonly="readonly"
              ></maklumat-dokumen-sokongan>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{ 'show active': isCurrentTab('#bahagian-d') }"
          id="bahagian-d"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">PERAKUAN PEMAJU PERUMAHAN</h4>
              <bahagian-d :tarikh_hantar="default_tarikh_hantar" @submit="submit" :pengarah="pengarah"></bahagian-d>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaklumatPemajuWithPenghantarAndPemajuan from "../../common/MaklumatPemajuWithPenghantarAndPemajuan";
import BahagianB from "./BahagianB.vue";
import BahagianD from "./BahagianD.vue";
import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";

export default {
  components: {
    MaklumatDokumenSokongan,
    MaklumatPemajuWithPenghantarAndPemajuan,
    BahagianB,
    BahagianD
  },

  props: {
    pemaju: {
      type: Object
    },

    pemajuan: {
      type: Object
    },

    akaunHda: {
      type: Object
    },

    pengarah: {
      type: Object
    },

    default_pemaju_nama_pegawai: String,
    default_pemaju_no_pejabat: String,
    default_pemaju_no_bimbit: String,
    default_pemaju_no_faks: String,
    default_pemaju_email: String,
    default_tarikh_dibuka: String,
    default_kod_jenis_wp_id: String,
    default_wang_pendahuluan: String | Number,
    default_baki_terkini: String | Number,

    default_docs: Array,

    default_tarikh_hantar: String,

    docConfigs: Array,

    errorBag: {
      type: Object
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      pemaju_nama_pegawai: this.default_pemaju_nama_pegawai,
      pemaju_no_pejabat: this.default_pemaju_no_pejabat,
      pemaju_no_bimbit: this.default_pemaju_no_bimbit,
      pemaju_no_faks: this.default_pemaju_no_faks,
      pemaju_email: this.default_pemaju_email,
      tarikh_dibuka: this.default_tarikh_dibuka,
      kod_jenis_wp_id: this.default_kod_jenis_wp_id,
      wang_pendahuluan: this.default_wang_pendahuluan,
      baki_terkini: this.default_baki_terkini
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    validate(skipDocValidation) {
      if (
        this.$validator.errors.first("pemaju_nama_pegawai") ||
        this.$validator.errors.first("pemaju_no_pejabat") ||
        this.$validator.errors.first("pemaju_no_bimbit") ||
        this.$validator.errors.first("pemaju_no_faks") ||
        this.$validator.errors.first("pemaju_email")
      ) {
        window.location.hash = "#bahagian-a";
        return true;
      } else if (
        this.$validator.errors.first("tarikh_dibuka") ||
        this.$validator.errors.first("kod_jenis_wp_id") ||
        this.$validator.errors.first("wang_pendahuluan") ||
        this.$validator.errors.first("baki_terkini")
      ) {
        window.location.hash = "#bahagian-b";
        return true;
      } else if (!skipDocValidation) {
        window.location.hash = "#bahagian-c";
        return true;
      }

      return false;
    },

    async submit(ev) {
      ev.preventDefault();

      const passes = await this.$validator.validateAll();

      if (!passes && this.validate()) {
        return;
      }

      swalconfirm().then(result => {
        if (result.value) {
          ev.target.form.submit();
        }
      });
    }
  }
};
</script>
