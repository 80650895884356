<template>
  <div>
    <slot :setCurrent="setCurrent" :currentActive="currentActive"></slot>

    <tuan-tanah-modal
      :kod-negeri-rs="kodNegeriRs"
      :kod-daerah-rs="kodDaerahRs"
      :kod-bandar-rs="kodBandarRs"
      :kod-poskod-rs="kodPoskodRs"
      :kod-poskod-bandar-rs="kodPoskodBandarRs"

      :kod-jenis-tuan-tanah-rs="kodJenisTuanTanahRs"
      :kod-jenis-urusan-rs="kodJenisUrusanRs"
      :kod-pemilikan-pembeli-rs="kodPemilikanPembeliRs"
      :mahkamah-tinggi="mahkamahTinggi"
      :pengarah-rs="pengarahRs"
      :readonly="readonly"
    ></tuan-tanah-modal>


  </div>
</template>

<script>
import TuanTanahModal from "./TuanTanahModal";


export default {
  name: "PindaanTanahNav",

  components: {
    TuanTanahModal,
  },

  props: {
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    mahkamahTinggi: Array,
    pengarahRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      currentActive: 0,
    }
  },

  methods: {
    setCurrent(current) {
      this.currentActive = current;
    }
  }
}
</script>

<style scoped>

</style>
