<template>
  <main>
    <div class="card">
      <div class="card-body">
        <div class="alert alert-warning" v-if="kuiri">
          <strong>Catatan Kuiri:</strong>
          <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
        </div>

        <ul class="ribbon-steps nav">
          <li><a class="active" data-toggle="tab" role="tab" href="#harga-bebas">C1. KOMPONEN HARGA BEBAS</a></li>
          <li v-if="maklumat.kod_skim_projek_id"><a data-toggle="tab" role="tab" href="#mampu-milik">C2. KOMPONEN HARGA MAMPU MILIK</a></li>
        </ul>
        <div class="tab-content mt-4 px-4">
          <div class="tab-pane fade show active" id="harga-bebas" role="tabpanel">
            <section class="form-compact row">
              <div class="col-lg-12">
                <h5 class="card-title">C1. KOMPONEN HARGA BEBAS</h5>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Kos Pembinaan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_pembinaan_harga_bebas" :class="{'is-invalid': errors.first('kos_pembinaan_harga_bebas')}" v-model="form.kos_pembinaan_harga_bebas" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Kos Konsultan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_konsultan_harga_bebas" :class="{'is-invalid': errors.first('kos_konsultan_harga_bebas')}" v-model="form.kos_konsultan_harga_bebas" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Kos Kewangan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_kewangan_harga_bebas" :class="{'is-invalid': errors.first('kos_kewangan_harga_bebas')}" v-model="form.kos_kewangan_harga_bebas" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Kos Tetap <small>(Bayaran Gaji, Sewaan dan lain-lain)</small></label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_tetap_harga_bebas" :class="{'is-invalid': errors.first('kos_tetap_harga_bebas')}" v-model="form.kos_tetap_harga_bebas" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Kos Pemasaran</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_pemasaran_harga_bebas" :class="{'is-invalid': errors.first('kos_pemasaran_harga_bebas')}" v-model="form.kos_pemasaran_harga_bebas" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Kos Tanah</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_tanah_harga_bebas" :class="{'is-invalid': errors.first('kos_tanah_harga_bebas')}" v-model="form.kos_tanah_harga_bebas" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Kos Keseluruhan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" disabled :value="kos_keseluruhan_harga_bebas.toFixed(2)">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Kos Pembangunan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" disabled :value="kos_pembangunan_harga_bebas.toFixed(2)">
                  </div>
                </div>
                <div class="form-group">
                  <label style="white-space: pre-wrap;">9.&#9;Pengiraan Deposit</label>
                  <section class="form-compact">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                        <tr>
                          <th rowspan="2">GDV</th>
                          <th class="text-center" colspan="2">GDC</th>
                          <th class="text-center" rowspan="2">Jumlah GDC</th>
                          <th class="text-right" rowspan="2">Deposit 3%</th>
                        </tr>
                        <tr>
                          <th class="text-center">Kos Pembinaan</th>
                          <th class="text-center">Kos Konsultan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{ getNilaiJualanBebas | currency }}</td>
                          <td></td>
                          <td class="text-center"></td>
                          <td class="text-center">{{ getGDCOriginalHargaBebas | currency }}</td>
                          <td class="text-right">{{ getDepositOriginalHargaBebas | currency }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <br>
                    <div class="form-group">
                      <label class="font-weight-bold">Persetujuan Bayaran:</label>
                      <div>
                        <div class="form-check form-check-inline">
                          <input id="persetujuan_harga_bebas_1" type="radio" name="persetujuan_harga_bebas" v-model="form.persetujuan_harga_bebas" class="form-check-input" :value="1">
                          <label for="persetujuan_harga_bebas_1" class="form-check-label">Setuju</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input id="persetujuan_harga_bebas_0" type="radio" name="persetujuan_harga_bebas" v-model="form.persetujuan_harga_bebas" class="form-check-input" :value="0">
                          <label for="persetujuan_harga_bebas_0" class="form-check-label">Tidak Setuju</label>
                        </div>
                      </div>
                    </div>

                    <section v-if="!form.persetujuan_harga_bebas">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                          <tr>
                            <th rowspan="2">GDV</th>
                            <th class="text-center" colspan="2">GDC</th>
                            <th class="text-center" rowspan="2">Jumlah GDC</th>
                            <th class="text-right" rowspan="2">Deposit 3%</th>
                          </tr>
                          <tr>
                            <th class="text-center">Kos Pembinaan</th>
                            <th class="text-center">Kos Konsultan</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>{{ getNilaiJualanBebas | currency }}</td>
                            <td>{{ form.kos_pembinaan_harga_bebas | currency }}</td>
                            <td class="text-center">{{ form.kos_konsultan_harga_bebas | currency }}</td>
                            <td class="text-center">{{ getGDCDisagreeHargaBebas | currency }}</td>
                            <td class="text-right">{{ getDepositDisagreeHargaBebas | currency }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>

                  </section>
                </div>
              </div>
            </section>
          </div>
          <div class="tab-pane fade" id="mampu-milik" role="tabpanel" v-if="maklumat.kod_skim_projek_id">
            <section class="form-compact row">
              <div class="col-lg-12">
                <h5 class="card-title">C2. KOMPONEN HARGA MAMPU MILIK</h5>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Kos Pembinaan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_pembinaan_harga_mampumilik" :class="{'is-invalid': errors.first('kos_pembinaan_harga_mampumilik')}" v-model="form.kos_pembinaan_harga_mampumilik" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Kos Konsultan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_konsultan_harga_mampumilik" :class="{'is-invalid': errors.first('kos_konsultan_harga_mampumilik')}" v-model="form.kos_konsultan_harga_mampumilik" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Kos Kewangan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_kewangan_harga_mampumilik" :class="{'is-invalid': errors.first('kos_kewangan_harga_mampumilik')}" v-model="form.kos_kewangan_harga_mampumilik" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Kos Tetap <small>(Bayaran Gaji, Sewaan dan lain-lain)</small></label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_tetap_harga_mampumilik" :class="{'is-invalid': errors.first('kos_tetap_harga_mampumilik')}" v-model="form.kos_tetap_harga_mampumilik" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Kos Pemasaran</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_pemasaran_harga_mampumilik" :class="{'is-invalid': errors.first('kos_pemasaran_harga_mampumilik')}" v-model="form.kos_pemasaran_harga_mampumilik" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Kos Tanah</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" name="kos_tanah_harga_mampumilik" :class="{'is-invalid': errors.first('kos_tanah_harga_mampumilik')}" v-model="form.kos_tanah_harga_mampumilik" v-validate="'required|decimal:2'">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Kos Keseluruhan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" disabled :value="kos_keseluruhan_harga_mampumilik.toFixed(2)">
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Kos Pembangunan</label>
                  <div class="col-md-8">
                    <input type="text" class="form-control" disabled :value="kos_pembangunan_harga_mampumilik.toFixed(2)">
                  </div>
                </div>
                <div class="form-group">
                  <label style="white-space: pre-wrap;">9.&#9;Pengiraan Deposit</label>
                  <section class="form-compact">
                    <div class="table-responsive">
                      <table class="table">
                        <thead>
                        <tr>
                          <th rowspan="2">GDV</th>
                          <th class="text-center" colspan="2">GDC</th>
                          <th class="text-center" rowspan="2">Jumlah GDC</th>
                          <th class="text-right" rowspan="2">Deposit 3%</th>
                        </tr>
                        <tr>
                          <th class="text-center">Kos Pembinaan</th>
                          <th class="text-center">Kos Konsultan</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                          <td>{{ getNilaiJualanMampumilik | currency }}</td>
                          <td></td>
                          <td class="text-center"></td>
                          <td class="text-center">{{ getGDCOriginalHargaMampumilik | currency }}</td>
                          <td class="text-right">{{ getDepositOriginalHargaMampumilik | currency }}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <br>
                    <div class="form-group">
                      <label class="font-weight-bold">Persetujuan Bayaran:</label>
                      <div>
                        <div class="form-check form-check-inline">
                          <input id="persetujuan_harga_mampumilik_1" type="radio" name="persetujuan_harga_mampumilik" v-model="form.persetujuan_harga_mampumilik" class="form-check-input" :value="1">
                          <label for="persetujuan_harga_mampumilik_1" class="form-check-label">Setuju</label>
                        </div>
                        <div class="form-check form-check-inline">
                          <input id="persetujuan_harga_mampumilik_0" type="radio" name="persetujuan_harga_mampumilik" v-model="form.persetujuan_harga_mampumilik" class="form-check-input" :value="0">
                          <label for="persetujuan_harga_mampumilik_0" class="form-check-label">Tidak Setuju</label>
                        </div>
                      </div>
                    </div>

                    <section v-if="!form.persetujuan_harga_mampumilik">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                          <tr>
                            <th rowspan="2">GDV</th>
                            <th class="text-center" colspan="2">GDC</th>
                            <th class="text-center" rowspan="2">Jumlah GDC</th>
                            <th class="text-right" rowspan="2">Deposit 3%</th>
                          </tr>
                          <tr>
                            <th class="text-center">Kos Pembinaan</th>
                            <th class="text-center">Kos Konsultan</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>{{ getNilaiJualanMampumilik | currency }}</td>
                            <td>{{ form.kos_pembinaan_harga_mampumilik | currency }}</td>
                            <td class="text-center">{{ form.kos_konsultan_harga_mampumilik | currency }}</td>
                            <td class="text-center">{{ getGDCDisagreeHargaMampumilik | currency }}</td>
                            <td class="text-right">{{ getDepositDisagreeHargaMampumilik | currency }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </section>

                  </section>
                </div>
              </div>

            </section>
          </div>
        </div>
        <br>
        <section class="form-compact row">
          <div class="col-lg-12">
            <h5 class="card-title">C3. JUMLAH KESELURUHAN</h5>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Kos Pembinaan</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_pembinaan_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Kos Konsultan</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_konsultan_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Kos Kewangan</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_kewangan_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Kos Tetap <small>(Bayaran Gaji, Sewaan dan lain-lain)</small></label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_tetap_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Kos Pemasaran</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_pemasaran_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Kos Tanah</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_tanah_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Kos Keseluruhan</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_keseluruhan_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Kos Pembangunan</label>
              <div class="col-md-8">
                <input type="text" class="form-control" disabled :value="kos_pembangunan_keseluruhan.toFixed(2)">
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-4 pt-2">
                <h4>JUMLAH DEPOSIT (HDA) PERLU DIBAYAR</h4>
              </div>
              <div class="col-md-8 pt-2" style="border-top: 2px solid black; border-bottom: 2px solid black;">
                <h4>
                  {{ jumlah_deposit_keseluruhan | currency }}
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="card-footer">
        <div class="text-right">
          <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition><button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "BahagianD",
  components: {},
  props: {
    maklumat: Object,
    kewangan: Object,
    pembangunan_rs: Array,

    kuiri: null,
  },
  data() {
    return {
      loading: false,
      form: {
        kos_pembinaan_harga_bebas: this.kewangan ? this.kewangan.kos_pembinaan_harga_bebas : null,
        kos_konsultan_harga_bebas: this.kewangan ? this.kewangan.kos_konsultan_harga_bebas : null,
        kos_kewangan_harga_bebas: this.kewangan ? this.kewangan.kos_kewangan_harga_bebas : null,
        kos_tetap_harga_bebas: this.kewangan ? this.kewangan.kos_tetap_harga_bebas : null,
        kos_pemasaran_harga_bebas: this.kewangan ? this.kewangan.kos_pemasaran_harga_bebas : null,
        kos_tanah_harga_bebas: this.kewangan ? this.kewangan.kos_tanah_harga_bebas : null,
        persetujuan_harga_bebas: this.kewangan ? this.kewangan.persetujuan_harga_bebas : 1,

        kos_pembinaan_harga_mampumilik: this.kewangan ? this.kewangan.kos_pembinaan_harga_mampumilik : null,
        kos_konsultan_harga_mampumilik: this.kewangan ? this.kewangan.kos_konsultan_harga_mampumilik : null,
        kos_kewangan_harga_mampumilik: this.kewangan ? this.kewangan.kos_kewangan_harga_mampumilik : null,
        kos_tetap_harga_mampumilik: this.kewangan ? this.kewangan.kos_tetap_harga_mampumilik : null,
        kos_pemasaran_harga_mampumilik: this.kewangan ? this.kewangan.kos_pemasaran_harga_mampumilik : null,
        kos_tanah_harga_mampumilik: this.kewangan ? this.kewangan.kos_tanah_harga_mampumilik : null,
        persetujuan_harga_mampumilik: this.kewangan ? this.kewangan.persetujuan_harga_mampumilik : 1,
      },
    }
  },
  methods: {
    async save() {
      if (this.loading) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      // todo kalau x setuju, gdc mesti dekat-dekat dengan 70% value gdv

      this.loading = true;

      axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/bahagian-d`, this.form)
        .then((response) => {
          this.form = response.data.kewangan;
          this.$emit('update', JSON.parse(JSON.stringify(response.data)));
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
  },
  computed: {
    // HARGA BEBAS
    getNilaiJualanBebas() {
      if (!this.maklumat.kod_skim_projek_id) {
        return this.pembangunan_rs.reduce((total, pembangunan) => {
          return total + (_.sum(pembangunan.nolot_unit_rs.map(i => parseFloat(i.harga_jualan) * 100)));
        }, 0) / 100;
      }

      return this.pembangunan_rs.reduce((total, pembangunan) => {
        return total + (_.sum(pembangunan.nolot_unit_rs.filter(i => !i.kod_skim_projek_id).map(i => parseFloat(i.harga_jualan) * 100)));
      }, 0) / 100;
    },

    kos_keseluruhan_harga_bebas() {
      return ((parseFloat(this.form.kos_pembinaan_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_konsultan_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_kewangan_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_tetap_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_pemasaran_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_tanah_harga_bebas || 0) * 100)) / 100;
    },

    kos_pembangunan_harga_bebas() {
      return ((parseFloat(this.form.kos_pembinaan_harga_bebas || 0) * 100) +
        (parseFloat(this.form.kos_konsultan_harga_bebas || 0) * 100)) / 100;
    },

    getGDCOriginalHargaBebas() {
      return ((this.getNilaiJualanBebas * 100) * 0.7) / 100;
    },

    getDepositOriginalHargaBebas() {
      return ((this.getGDCOriginalHargaBebas * 100) * 0.03) / 100;
    },


    getGDCDisagreeHargaBebas() {
      return ((this.kos_pembangunan_harga_bebas * 100)) / 100;
    },

    getDepositDisagreeHargaBebas() {
      return ((this.getGDCDisagreeHargaBebas * 100) * 0.03) / 100;
    },

    jumlah_deposit_harga_bebas() {
      if (this.form.persetujuan_harga_bebas) {
        return this.getDepositOriginalHargaBebas;
      }

      return this.getDepositDisagreeHargaBebas;
    },

    // MAMPU MILIK
    getNilaiJualanMampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0.00;
      }

      return this.pembangunan_rs.reduce((total, pembangunan) => {
        return total + (_.sum(pembangunan.nolot_unit_rs.filter(i => !!i.kod_skim_projek_id).map(i => parseFloat(i.harga_jualan) * 100)));
      }, 0) / 100;
    },

    kos_keseluruhan_harga_mampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return ((parseFloat(this.form.kos_pembinaan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_konsultan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_kewangan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_tetap_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_pemasaran_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_tanah_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_pembangunan_harga_mampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return ((parseFloat(this.form.kos_pembinaan_harga_mampumilik || 0) * 100) +
        (parseFloat(this.form.kos_konsultan_harga_mampumilik || 0) * 100)) / 100;
    },

    getGDCOriginalHargaMampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return ((this.getNilaiJualanMampumilik * 100) * 0.7) / 100;
    },

    getDepositOriginalHargaMampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return Math.min(((this.getGDCOriginalHargaMampumilik * 100) * 0.03) / 100, 200000);
    },


    getGDCDisagreeHargaMampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return ((this.kos_pembangunan_harga_mampumilik * 100)) / 100;
    },

    getDepositDisagreeHargaMampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      return Math.min(((this.getGDCDisagreeHargaMampumilik * 100) * 0.03) / 100, 200000);
    },

    jumlah_deposit_harga_mampumilik() {
      if (!this.maklumat.kod_skim_projek_id) {
        return 0;
      }

      if (this.form.persetujuan_harga_mampumilik) {
        return this.getDepositOriginalHargaMampumilik;
      }

      return this.getDepositDisagreeHargaMampumilik;
    },

    // KESELURUHAN
    kos_pembinaan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_pembinaan_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_pembinaan_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_pembinaan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_konsultan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_konsultan_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_konsultan_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_konsultan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_kewangan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_kewangan_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_kewangan_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_kewangan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_tetap_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_tetap_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_tetap_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_tetap_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_pemasaran_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_pemasaran_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_pemasaran_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_pemasaran_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_tanah_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.form.kos_tanah_harga_bebas || 0));
      }

      return ((parseFloat(this.form.kos_tanah_harga_bebas || 0) * 100) +
      (parseFloat(this.form.kos_tanah_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_keseluruhan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.kos_keseluruhan_harga_bebas || 0));
      }

      return ((parseFloat(this.kos_keseluruhan_harga_bebas || 0) * 100) +
        (parseFloat(this.kos_keseluruhan_harga_mampumilik || 0) * 100)) / 100;
    },

    kos_pembangunan_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.kos_pembangunan_harga_bebas || 0));
      }

      return ((parseFloat(this.kos_pembangunan_harga_bebas || 0) * 100) +
        (parseFloat(this.kos_pembangunan_harga_mampumilik || 0) * 100)) / 100;
    },

    jumlah_deposit_keseluruhan() {
      if (!this.maklumat.kod_skim_projek_id) {
        return (parseFloat(this.jumlah_deposit_harga_bebas || 0));
      }

      return ((parseFloat(this.jumlah_deposit_harga_bebas || 0) * 100) +
        (parseFloat(this.jumlah_deposit_harga_mampumilik || 0) * 100)) / 100;
    },

  }
}
</script>

<style scoped>

</style>
