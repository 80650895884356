<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <h5 class="card-title">Dicadangkan:</h5>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th width="100px">Bil.</th>
            <th>Jenis Iklan</th>
            <th width="1%" class="text-center"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(jenis_iklan, index) in kodJenisIklanRs" :key="`jenis_iklan_${jenis_iklan.id}`">
            <td>{{ index + 1 }}.</td>
            <td>{{ jenis_iklan.keterangan }}</td>
            <td>
              <div class="form-check" style="padding: 0;padding:0; text-align:center;"><input type="checkbox" :disabled="!canSave" :checked="checkedJenisIklan(jenis_iklan.id)" @input="toggleJenisIklan(jenis_iklan.id)"></div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <br>
      <br>

      <h5 class="card-title">Lain-lain, jika ada:</h5>
      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th width="100px">Bil.</th>
            <th>Jenis Iklan</th>
            <th width="1%" class="text-center" v-if="canSave">
              <button class="btn btn-success" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="add"><i class="fas fa-plus"></i></button>
            </th>
          </tr>
          </thead>
          <tbody>
          <template v-if="jenis_iklan_lain_rs.length">
          <tr v-for="(item, index) in jenis_iklan_lain_rs">
            <td>{{ index + 1 }}</td>
            <td>{{ item.keterangan_iklan_lain }}</td>
            <td nowrap v-if="canSave">
              <button class="btn btn-icon text-primary" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="update(index)" title="Kemaskini Jenis Iklan"><i class="fas fa-building"></i></button>
              <button class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroy(index)" title="Hapus Jenis Iklan"><i class="fas fa-trash"></i></button>
            </td>
          </tr>
          </template>
          <template v-else>
            <td class="text-center text-gray" colspan="5">Tiada maklumat iklan lain-lain.</td>
          </template>
          </tbody>
        </table>
      </div>

      <tambah-jenis-iklan-modal></tambah-jenis-iklan-modal>
    </div>
    <div class="card-footer" v-if="canSave">
      <div class="text-right">
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>

</template>

<script>
import TambahJenisIklanModal from "../../common/TambahJenisIklanModal";
export default {
  name: "BahagianC3",
  components: {TambahJenisIklanModal},
  props: {
    maklumat: Object,
    kodJenisIklanRs: Array,

    default_iklan_rs: Array,
    default_iklan_lain_rs: Array,

    kuiri: null,
  },
  data() {
    return {
      loading: false,

      jenis_iklan_rs: JSON.parse(JSON.stringify(this.default_iklan_rs)),
      jenis_iklan_lain_rs: JSON.parse(JSON.stringify(this.default_iklan_lain_rs)),
    }
  },
  methods: {
    checkedJenisIklan(kod_jenis_iklan_id) {
        const jenis_iklan = this.jenis_iklan_rs.find(item => item.kod_jenis_iklan_id == kod_jenis_iklan_id);

        return (jenis_iklan && !jenis_iklan.dipadam_pada);
    },

    toggleJenisIklan(kod_jenis_iklan_id) {
      const jenis_iklan = this.jenis_iklan_rs.find(item => item.kod_jenis_iklan_id == kod_jenis_iklan_id);

      if ((jenis_iklan && !jenis_iklan.dipadam_pada)) {
        const index = this.jenis_iklan_rs.indexOf(jenis_iklan);
        this.jenis_iklan_rs.splice(index, 1);
      } else {
        this.jenis_iklan_rs.push({
          kod_jenis_iklan_id: kod_jenis_iklan_id,
        })
      }
    },

    update(index) {
      const jenis_iklan_lain = this.jenis_iklan_lain_rs[index];

      vmodal.open('tambahjenisiklan', {
        ...jenis_iklan_lain,
      }, ({ keterangan_iklan_lain }) => {

        this.jenis_iklan_lain_rs.splice(index, 1, { ...jenis_iklan_lain, keterangan_iklan_lain });
      })
    },

    destroy(index) {
      this.jenis_iklan_lain_rs.splice(index, 1);
    },

    add() {
      vmodal.open('tambahjenisiklan', {
      }, ({ keterangan_iklan_lain }) => {
        this.jenis_iklan_lain_rs.push({ keterangan_iklan_lain })
      })
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/apdl-pindaan-lesen/${this.maklumat.id}/bahagian-c3`, {
        jenis_iklan_rs: this.jenis_iklan_rs,
        jenis_iklan_lain_rs: this.jenis_iklan_lain_rs,
      })
        .then((response) => {
          this.jenis_iklan_rs = response.data.iklan_rs;
          this.jenis_iklan_lain_rs = response.data.iklan_lain_rs;
          this.$emit('update', JSON.parse(JSON.stringify(response.data)));
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
  },

  computed: {
    canSave() {
      return !!this.maklumat.pindaan_pelbagai_iklan;
    },
  }
}
</script>

<style scoped>

</style>
