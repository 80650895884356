<template>
  <div class="card position-relative overflow-hidden">
    <div
      v-if="default_permohonan.status_id === 'D'"
      style="
        position: absolute;
        padding: 5px 50px;
        background: #5e87b0;
        color: white;
        top: 15px;
        right: -45px;
        transform: rotate(45deg);
        z-index: 1;
      "
    >
      DERAF
    </div>
    <div
      v-else-if="
        ['K1', 'K2', 'KB'].indexOf(default_permohonan.status_id) !== -1
      "
      style="
        position: absolute;
        padding: 5px 50px;
        background: red;
        color: white;
        top: 15px;
        right: -45px;
        transform: rotate(45deg);
        z-index: 1;
      "
    >
      KUIRI
    </div>

    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li :class="{ disabled: !hasEditingSection('a') }">
            <a href="#bahagian-a">A - Maklumat Pemaju</a>
          </li>
          <li :class="{ disabled: !hasEditingSection('b') }">
            <a href="#bahagian-b">B - Maklumat Kewangan</a>
          </li>
          <li :class="{ disabled: !hasEditingSection('c') }">
            <a href="#bahagian-c">C - Maklumat Tanah</a>
          </li>
          <li :class="{ disabled: !hasEditingSection('d') }">
            <a href="#bahagian-d">D - Akuan Berkanun</a>
          </li>
          <li>
            <a href="#bahagian-e">E - Lain - Lain Dokumen</a>
          </li>
          <li>
            <a href="#bahagian-f">F - Perakuan Maklumat</a>
          </li>
        </ul>
      </nav>
      <section>
        <error-bar
          :messages="errorMessages"
          @close="errorMessages = {}"
        ></error-bar>

        <div class="tab-content">
          <div
            v-if="hasEditingSection('a') && isCurrentTab('#bahagian-a')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-a') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN A: MAKLUMAT PEMAJU</h4>
                <bahagian-a
                  :pemaju="pemaju"
                  :maklumat="maklumat"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-taraf-pemaju-rs="kodTarafPemajuRs"
                  :kod-jenis-pemaju-rs="kodJenisPemajuRs"
                  @update="(v) => (this.maklumat = v)"
                  @next="tab = '#bahagian-b'"
                ></bahagian-a>
              </div>
            </div>
          </div>

          <div
            v-if="hasEditingSection('b') && isCurrentTab('#bahagian-b')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-b') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN B: MAKLUMAT KEWANGAN</h4>
                <bahagian-b
                  :pemaju="pemaju"
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  @next="tab = '#bahagian-b'"
                ></bahagian-b>
              </div>
            </div>
          </div>

          <div
            v-if="hasEditingSection('c') && isCurrentTab('#bahagian-c')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-c') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN C: MAKLUMAT TANAH</h4>
                <bahagian-c
                  :maklumat="maklumat"
                  :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
                  :kod-dun-rs="kodDunRs"
                  :kod-parlimen-rs="kodParlimenRs"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-bank-hq-rs="kodBankHqRs"

                  :kod-syarat-nyata-rs="kodSyaratNyataRs"
                  :kod-status-tanah-rs="kodStatusTanahRs"
                  :kod-rezab-tanah-rs="kodRezabTanahRs"
                  :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
                  :kod-bebanan-tanah-rs="kodBebananTanahRs"
                  :kod-jenis-kaveat-rs="kodJenisKaveatRs"
                  :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"
                  :kod-jenis-tuan-tanah-rs="kodJenisTuanTanahRs"
                  :kod-jenis-urusan-rs="kodJenisUrusanRs"
                  :kod-pemilikan-pembeli-rs="kodPemilikanPembeliRs"

                  :mahkamah-tinggi="mahkamahTinggi"
                  :pengarah-rs="pengarahRs"
                  :kod-poskod-rs="kodPoskodRs"
                  :kod-poskod-bandar-rs="kodPoskodBandarRs"

                  @update="v => this.maklumat = v"
                  @next="tab = '#bahagian-c'"
                ></bahagian-c>
              </div>
            </div>
          </div>

          <div
            v-if="hasEditingSection('d') && isCurrentTab('#bahagian-d')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-d') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN D: AKUAN BERKANUN</h4>
                <bahagian-d
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  @next="tab = '#bahagian-b'"
                ></bahagian-d>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-e')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-e') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN E: LAIN - LAIN DOKUMEN</h4>
                <bahagian-e
                  :maklumat="maklumat"
                  :lesen="lesen"
                  @update="(v) => (this.maklumat = v)"
                ></bahagian-e>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-f')"
            class="tab-pane fade"
            :class="{ 'show active': isCurrentTab('#bahagian-f') }"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN F: PERAKUAN MAKLUMAT BENAR</h4>
                <bahagian-f
                  :maklumat="maklumat"
                  @update="(v) => (this.maklumat = v)"
                  @hantar="submit"
                ></bahagian-f>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import BahagianB from "./BahagianB";
import BahagianC from "./BahagianC";
import BahagianD from "./BahagianD";
import BahagianE from "./BahagianE";
import BahagianF from "./BahagianF";
import ErrorBar from "../../common/ErrorBar";

export default {
  name: "Borang",
  components: {
    BahagianA,
    BahagianB,
    BahagianC,
    BahagianD,
    BahagianE,
    BahagianF,
    ErrorBar,
  },

  props: {
    pengarah: Object,
    pemaju: Object,

    lesen: Object,

    default_maklumat: Object,
    default_permohonan: Object,

    // tanah
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,

    // tuan tanah
    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    // pemaju
    kodKategoriPemajuRs: Array,
    kodTarafPemajuRs: Array,
    kodJenisPemajuRs: Array,

    // pemajuan
    kodJenisHakmilikRs: Array,

    // misc
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodBankHqRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,

    mahkamahTinggi: Array,
    pengarahRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,
      errorMessages: {},
      defaultTab: this.getDefaultTab(),
      tab: this.defaultTab,
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    getDefaultTab() {
      if (this.hasEditingSection("a")) return "#bahagian-a";
      if (this.hasEditingSection("b")) return "#bahagian-b";
      if (this.hasEditingSection("c")) return "#bahagian-c";
      if (this.hasEditingSection("d")) return "#bahagian-d";
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    hasEditingSection(section) {
      return (
        this.default_maklumat &&
        this.default_maklumat.seksyen_rs.length &&
        this.default_maklumat.seksyen_rs.find(
          (item) => item.seksyen === section
        )
      );
    },

    async submit() {
      swalconfirm().then((result) => {
        if (result.value) {
          axios
            .post(`/pemaju/dl-pindaanpembaharuan/${this.maklumat.id}/hantar`)
            .then((response) => {
              window.location.replace("/pemaju/home/tugasan");
            });
        }
      });
    },
  },
};
</script>

<style scoped></style>
