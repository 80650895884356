<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>
      <div>

        <h6>
            <i>Medan bertanda <img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"> akan dipaparkan dalam cetakan eSPA.</i>
        </h6>

        <div class="form-group row">
          <!-- <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Nama PBT <span class="label-required">*</span></label> -->
          <label class="col-form-label col-md-4" style="white-space: pre-wrap; align-items: center; display: flex;">1.&#9;Nama PBT <span class="label-required">*</span>
            <img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;" data-toggle="tooltip"
                        data-placement="top" title="Medan ini akan dipaparkan dalam cetakan eSPA.">
          </label>
          <div class="col-12 col-sm-6 col-md-6">
            <select2 class="form-control" name="kod_pbt_id" v-model="form.kod_pbt_id">
              <option value="">Sila Pilih</option>
              <option v-for="pbt in namaPbt" :value="pbt.id" :key="`pbt_${pbt.id}`">{{ pbt.keterangan }}</option>
            </select2>
            <!-- <select2 class="form-control" v-model="form.kod_pbt_id" :disabled="readonly">
              <option value="">Sila Pilih</option>
              <option v-for="pbt in filteredPbtRs(maklumat.tanah.kod_negeri_id)" :value="pbt.id" :key="pbt.id">{{ pbt.keterangan }}</option>
            </select2> -->
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap; align-items: center; display: flex;">2.&#9;No. Rujukan Surat Kelulusan PB <span class="label-required">*</span>
            <span @mouseover="showImage = true" @mouseout="showImage = false" style="cursor: pointer;">
              <img v-if="showImage" style="position: absolute;z-index: 10;border: 2px solid grey;" width="550px" src="\img\pelanbangunan.png" alt="Hover Image" />
              <span><img src="\img\info.png" alt="" width="15px"></span>
            </span>
              <img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;" data-toggle="tooltip"
                          data-placement="top" title="Medan ini akan dipaparkan dalam cetakan eSPA.">
          </label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.pb_tajuk" @input="form.pb_tajuk = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;No. Lot <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.pb_no_lot" @input="form.pb_no_lot = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Tarikh Kelulusan</label>
          <div class="col-12 col-sm-4 col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('pb_tarikh_kelulusan')}"
              name="pb_tarikh_kelulusan"
              v-model="form.pb_tarikh_kelulusan"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Tarikh Luput <span class="label-required">*</span></label>
          <div class="col-12 col-sm-4 col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('pb_tarikh_luput_pajakan')}"
              name="pb_tarikh_luput_pajakan"
              v-model="form.pb_tarikh_luput_pajakan"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Salinan Surat Kelulusan PBT</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="pb_doc_rujukan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`pb_doc_rujukan`)}"
              :doc="maklumat.pb_doc_rujukan"
              @uploadwithcallback="({file, callback}) => uploadDoc('pb_doc_rujukan', file, callback)"
              @remove="removeDoc('pb_doc_rujukan')"
              v-validate="'required'"
              :readonly="true"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Pelan Bangunan Yang Diluluskan PBT <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="pb_doc_pelan_bangunan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`pb_doc_pelan_bangunan`)}"
              :doc="maklumat.pb_doc_pelan_bangunan"
              @uploadwithcallback="({file, callback}) => uploadDoc('pb_doc_pelan_bangunan', file, callback)"
              @remove="removeDoc('pb_doc_pelan_bangunan')"
              v-validate="'required'"
              :readonly="true"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Pindaan Pelan Bangunan Yang Diluluskan PBT <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="pindaan_pb_doc_pelan_bangunan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`pindaan_pb_doc_pelan_bangunan`)}"
              :doc="maklumat.pindaan_pb_doc_pelan_bangunan"
              @uploadwithcallback="({file, callback}) => uploadDoc('pindaan_pb_doc_pelan_bangunan', file, callback)"
              @remove="removeDoc('pindaan_pb_doc_pelan_bangunan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">9.&#9;No. Rujukan Surat Kelulusan Pindaan PB <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="pindaan_sk_doc_pelan_bangunan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`pindaan_sk_doc_pelan_bangunan`)}"
              :doc="maklumat.pindaan_sk_doc_pelan_bangunan"
              @uploadwithcallback="({file, callback}) => uploadDoc('pindaan_sk_doc_pelan_bangunan', file, callback)"
              @remove="removeDoc('pindaan_sk_doc_pelan_bangunan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">10.&#9;Dokumen Arahan Pindaan PB <span class="label-required">*</span></label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="pindaan_da_doc_pelan_bangunan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`pindaan_da_doc_pelan_bangunan`)}"
              :doc="maklumat.pindaan_da_doc_pelan_bangunan"
              @uploadwithcallback="({file, callback}) => uploadDoc('pindaan_da_doc_pelan_bangunan', file, callback)"
              @remove="removeDoc('pindaan_da_doc_pelan_bangunan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianJ",
  props: {
    maklumat: Object,
    kodPbtRs: Array,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,

      showImage: false,

      namaPbt: this.kodPbtRs.filter((item) => item.kod_negeri_id == this.maklumat.tanah.kod_negeri_id),

      form: {
        pb_tajuk: this.maklumat.pb_tajuk,
        pb_no_lot: this.maklumat.pb_no_lot,
        pb_tarikh_kelulusan: this.maklumat.pb_tarikh_kelulusan,
        pb_tarikh_luput_pajakan: this.maklumat.pb_tarikh_luput_pajakan,
        kod_pbt_id: this.maklumat.kod_pbt_id,
      },
    }
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/bahagian-j`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  }
}
</script>

<style scoped>

</style>
