Vue.component('latlng', require('./components/core/LatLng.vue').default);
Vue.component('multi-list', require('./components/core/MultiList.vue').default);
Vue.component('datepicker', require('./components/core/DatePicker.vue').default);
Vue.component('daterangepicker', require('./components/core/DateRangePicker.vue').default);
Vue.component('input-file', require('./components/core/InputFile.vue').default);
Vue.component('input-file-new', require('./components/core/InputFileNew.vue').default);
Vue.component('file-upload', require('./components/core/FileUpload.vue').default);
Vue.component('file-upload-multi', require('./components/core/FileUploadMulti.vue').default);
Vue.component('input-multi-file', require('./components/core/InputMultiFile.vue').default);
Vue.component('select2', require('./components/core/Select2').default);
Vue.component('input-diff', require('./components/core/InputDiff.vue').default);
Vue.component('input-money', require('./components/core/InputMoney.vue').default);

Vue.component('pembeli-modal', require('./components/common/PembeliModal.vue').default);
Vue.component('kemaskini-modal', require('./components/common/KemaskiniModal.vue').default);
Vue.component('pemaju-select', require('./components/common/PemajuSelect.vue').default);
Vue.component('pemaju-tanpalesen-select', require('./components/common/PemajuTanpaLesenSelect.vue').default);
Vue.component('senarai-dokumen-sokongan', require('./components/common/MaklumatDokumenSokongan.vue').default);

Vue.component('form-laporan-7e', require('./components/form/laporan-7e/Borang.vue').default);
Vue.component('reply-laporan-7e', require('./components/form/laporan-7e/Reply.vue').default);
Vue.component('form-kuota-bumi', require('./components/form/kuota-bumi/Borang.vue').default);
Vue.component('form-laporan-7f', require('./components/form/laporan-7f/Borang.vue').default);
Vue.component('form-laporan-kewangan-maklumat-kewangan', require('./components/form/laporan-kewangan/Borang.vue').default);
Vue.component('reply-laporan-kewangan-maklumat-kewangan', require('./components/form/laporan-kewangan/Reply.vue').default);
Vue.component('form-pengeluaran-wang-lebihan', require('./components/form/pengeluaran-wang-lebihan/Borang.vue').default);
Vue.component('reply-pengeluaran-wang-lebihan', require('./components/form/pengeluaran-wang-lebihan/Reply.vue').default);
Vue.component('form-pengeluaran-wang-semua', require('./components/form/pengeluaran-wang-semua/Borang.vue').default);
Vue.component('reply-pengeluaran-wang-semua', require('./components/form/pengeluaran-wang-semua/Reply.vue').default);
Vue.component('form-pengeluaran-wang-pendahuluan', require('./components/form/pengeluaran-wang-pendahuluan/Borang.vue').default);
Vue.component('reply-pengeluaran-wang-pendahuluan', require('./components/form/pengeluaran-wang-pendahuluan/Reply.vue').default);
Vue.component('form-perlantikan-juruaudit', require('./components/form/perlantikan-juruaudit/Borang.vue').default);
Vue.component('reply-perlantikan-juruaudit', require('./components/form/perlantikan-juruaudit/Reply.vue').default);
Vue.component('form-permohonan-lesen-baru', require('./components/form/permohonan-lesen-baru/Borang.vue').default);
Vue.component('reply-permohonan-lesen-baru', require('./components/form/permohonan-lesen-baru/Reply.vue').default);
Vue.component('form-permohonan-batal-lesen', require('./components/form/permohonan-batal-lesen/Borang.vue').default);
Vue.component('reply-permohonan-batal-lesen', require('./components/form/permohonan-batal-lesen/Reply.vue').default);
Vue.component('form-permohonan-pindaan-lesen', require('./components/form/permohonan-pindaan-lesen/Borang.vue').default);

Vue.component('form-permohonan-pembaharuan-lesen', require('./components/form/permohonan-pembaharuan-lesen/Borang.vue').default);
Vue.component('reply-permohonan-pembaharuan-lesen', require('./components/form/permohonan-pembaharuan-lesen/Reply.vue').default);
Vue.component('form-pengeluaran-wang-dibeku', require('./components/form/permohonan-pengeluaran-wang-dibeku/Borang.vue').default);

Vue.component('form-dl-baru', require('./components/form/dl-baru/Borang').default);
Vue.component('reply-dl-baru', require('./components/form/dl-baru/Reply').default);

Vue.component('form-dl-pindaanpembaharuan', require('./components/form/dl-pindaanpembaharuan/Borang').default);
Vue.component('reply-dl-pindaanpembaharuan', require('./components/form/dl-pindaanpembaharuan/Reply').default);

Vue.component('form-dl-penamatan', require('./components/form/dl-penamatan/Borang').default);
Vue.component('reply-dl-penamatan', require('./components/form/dl-penamatan/Reply').default);

Vue.component('form-ap-baru', require('./components/form/ap-baru/Borang').default);
Vue.component('reply-ap-baru', require('./components/form/ap-baru/Reply').default);

Vue.component('form-ap-pindaanpembaharuan', require('./components/form/ap-pindaanpembaharuan/Borang').default);
Vue.component('reply-ap-pindaanpembaharuan', require('./components/form/ap-pindaanpembaharuan/Reply').default);

Vue.component('form-ap-pembatalan', require('./components/form/ap-pembatalan/Borang').default);
Vue.component('reply-ap-pembatalan', require('./components/form/ap-pembatalan/Reply').default);

Vue.component('tanah-nav', require('./components/common/Tanah/TanahNav').default);
Vue.component('simple-nav', require('./components/common/Tanah/SimpleNav').default);
Vue.component('tanah', require('./components/common/Tanah/Tanah').default);
Vue.component('butiran-tanah', require('./components/common/Tanah/ButiranTanah').default);

Vue.component('pindaan-tanah-nav', require('./components/common/Tanah/PindaanTanahNav').default);
Vue.component('pindaan-tanah', require('./components/common/Tanah/PindaanTanah').default);
Vue.component('pindaan-butiran-tanah', require('./components/common/Tanah/PindaanButiranTanah').default);

Vue.component('bangunan', require('./components/common/Bangunan/Bangunan').default);
Vue.component('butiran-unit', require('./components/common/Bangunan/ButiranUnit').default);

Vue.component('pengarah-modal', require('./components/common/Pengarah/PengarahModal').default);
Vue.component('peguam-modal', require('./components/common/PeguamModal.vue').default);
Vue.component('pembeli-saksi-modal', require('./components/common/PembeliSaksiModal.vue').default);
Vue.component('jadual-modal', require('./components/common/JadualModal.vue').default);


