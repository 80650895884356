<template>
  <select :class="{'select2-hidden-accessible': ready}">
    <slot></slot>
  </select>
</template>

<script>
export default {
  name: "Select2",
  props: ["value"],
  data() {
    return {
      ready: false,
    }
  },

  mounted: function () {
    this.ready = false;
    var vm = this;
    $(this.$el)
      // init select2
      .select2({
        theme: 'bootstrap4',
      })
      .val(this.value)
      .trigger("change")
      // emit event on change.
      .on("change", function () {
        vm.$emit("input", this.value);
        vm.$emit("change", this.value);
      });
    this.$nextTick(() => {
      this.ready = true;
    })

  },
  watch: {
    value(value) {
      // update value
      $(this.$el)
        .val(value)
        .trigger("change");
    },
  },
  destroyed() {
    $(this.$el)
      .off()
      .select2("destroy");
  }
}
</script>

<style scoped>
</style>
