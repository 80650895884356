<template>
  <div class="card">
    <section class="card-body form-compact">
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">1. Kod Pemaju</label>
        <div class="col-md-3">
          <input type="text" class="form-control" disabled :value="pemaju.kod_pemaju">
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">2. Nama Pemaju</label>
        <div class="col-md-9">
          <input type="text" class="form-control" disabled :value="pemaju.nama">
        </div>
      </div>
      <div class="form-group form-row">

        <label class="col-form-label col-md-3">3. Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
        <div class="col-md-9">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai')}"
                 name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai"
                 @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required|max:255'" data-vv-as="Nama Pegawai Dihubungi">
          <span class="invalid-feedback" v-if="errors.first('pemaju_nama_pegawai')">{{ errors.first('pemaju_nama_pegawai') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">4. No Telefon Pegawai <span class="label-required">*</span></label>
        <div class="col-md-3">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit')}"
                 name="pemaju_no_bimbit" :value="pemaju_no_bimbit"
                 @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:10,11'"  data-vv-as="No Telefon Bimbit">
          <span class="invalid-feedback" v-if="errors.first('pemaju_no_bimbit')">{{ errors.first('pemaju_no_bimbit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">5. Nama Penuh Firma Juruaudit <span class="label-required">*</span></label>
        <div class="col-md-9">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('nama_juruaudit')}"
                 name="nama_juruaudit" :value="nama_juruaudit"
                 @input="$emit('update:nama_juruaudit', $event.target.value.toUpperCase())" v-validate="'required:max:255'" data-vv-as="Nama Penuh Firma Juruaudit">
          <span class="invalid-feedback" v-if="errors.first('nama_juruaudit')">{{ errors.first('nama_juruaudit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">6. Alamat Pejabat Juruaudit <span class="label-required">*</span></label>
        <div class="col-md-9">
          <textarea type="text" class="form-control" :class="{'is-invalid': errors.first('alamat_juruaudit')}"
                    name="alamat_juruaudit" :value="alamat_juruaudit"
                    @input="$emit('update:alamat_juruaudit', $event.target.value.toUpperCase())"
                    v-validate="'required'" data-vv-as="Alamat Pejabat Juruaudit"></textarea>
          <span class="invalid-feedback" v-if="errors.first('alamat_juruaudit')">{{ errors.first('alamat_juruaudit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">7. No Telefon Juruaudit <span class="label-required">*</span></label>
        <div class="col-md-3">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('no_tel_juruaudit')}"
                 name="no_tel_juruaudit" :value="no_tel_juruaudit"
                 @input="$emit('update:no_tel_juruaudit', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:9,11'" data-vv-as="No Telefon">
          <span class="invalid-feedback" v-if="errors.first('no_tel_juruaudit')">{{ errors.first('no_tel_juruaudit') }}</span>
        </div>
        <label class="col-form-label col-md-3">8. No Faks Juruaudit</label>
        <div class="col-md-3">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('no_faks_juruaudit')}"
                 name="no_faks_juruaudit" :value="no_faks_juruaudit"
                 @input="$emit('update:no_faks_juruaudit', $event.target.value.toUpperCase())" v-validate="'numeric|length:9,11'" data-vv-as="No Faks">
          <span class="invalid-feedback" v-if="errors.first('no_faks_juruaudit')">{{ errors.first('no_faks_juruaudit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">9. Emel Juruaudit <span class="label-required">*</span></label>
        <div class="col-md-3">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('emel_juruaudit')}"
                 name="emel_juruaudit" :value="emel_juruaudit"
                 @input="$emit('update:emel_juruaudit', $event.target.value)" v-validate="'required|email'" data-vv-as="Emel">
          <span class="invalid-feedback" v-if="errors.first('emel_juruaudit')">{{ errors.first('emel_juruaudit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">10. No Kelulusan <span class="label-required">*</span></label>
        <div class="col-md-5">
          <div class="form-inline" :class="{'has-error': errors.first('no_kelulusan')}">
            <input type="text" class="form-control flex-grow-1 mr-2"
                   :class="{'is-invalid': errors.first('no_kelulusan')}" name="no_kelulusan" :value="no_kelulusan"
                   @input="$emit('update:no_kelulusan', $event.target.value.toUpperCase())" v-validate="'required'" data-vv-as="No Kelulusan">
            <a v-if="!readonly" class="d-flex align-items-center" href="javascript:void(0);" data-toggle="tooltip" data-placement="left" title="Contoh Dokumen"><span
              class="badge badge-primary mr-1">!</span></a>
          </div>
          <span class="invalid-feedback" v-if="errors.first('no_kelulusan')">{{ errors.first('no_kelulusan') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">11. Tarikh Mula Kelulusan <span class="label-required">*</span></label>
        <div class="col-md-3">
          <datepicker :class="{'is-invalid': errors.first('tarikh_mula')}" name="tarikh_mula" :value="tarikh_mula"
                      @input="value => $emit('update:tarikh_mula', value)" v-validate="'required'" data-vv-as="Tarikh Mula Kelulusan"></datepicker>
          <span class="invalid-feedback" v-if="errors.first('tarikh_mula')">{{ errors.first('tarikh_mula') }}</span>
        </div>
        <label class="col-form-label col-md-3">12. Tarikh Akhir Kelulusan <span class="label-required">*</span></label>
        <div class="col-md-3">
          <datepicker :class="{'is-invalid': errors.first('tarikh_akhir')}" name="tarikh_akhir" :value="tarikh_akhir"
                      @input="value => $emit('update:tarikh_akhir', value)" v-validate="'required'" data-vv-as="Tarikh Akhir Kelulusan"></datepicker>
          <span class="invalid-feedback" v-if="errors.first('tarikh_akhir')">{{ errors.first('tarikh_akhir') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-3">13. Tahun Kewangan Berakhir Syarikat</label>
        <div class="col-md-3">
          <input type="text" class="form-control" disabled :value="tarikh_akhir_kewangan">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import PemajuPermohonanDokumen from "../../common/PemajuPermohonanDokumen";
  import PemajuPermohonanDokumenDefault from "../../common/PemajuPermohonanDokumenDefault";
  export default {
    name: "BahagianA",
    components: {PemajuPermohonanDokumenDefault, PemajuPermohonanDokumen},
    inject: ['$validator'],

    props: {
      pemaju: Object,

      pemaju_nama_pegawai: String,
      pemaju_no_bimbit: String,
      nama_juruaudit: String,
      alamat_juruaudit: String,
      no_tel_juruaudit: String,
      no_faks_juruaudit: String,
      emel_juruaudit: String,
      no_kelulusan: String,
      tarikh_mula: String,
      tarikh_akhir: String,
      tarikh_akhir_kewangan: String,

      docConfigs: Array,

      default_docs: Array,

      readonly: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped>

</style>
