<template>
  <div class="card">
    <div class="card-body form-compact">
      <p>1. Saya akui segala maklumat dan dokumen yang dikemukakan adalah benar.</p>
      <p>2. Saya memahami sekiranya maklumat dan dokumen yang dikemukakan tidak benar atau tidak lengkap, pihak Kementerian berhak menolak permohonan ini.</p>
      <br />
      <br />

      <div class="text-center" v-if="!readonly">
        <button class="btn btn-primary" @click="$emit('submit', $event)">HANTAR</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
  }
}
</script>
