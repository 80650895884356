<template>
  <tr >
    <template v-if="editing">
      <td>
        <select
          :class="{'is-invalid': errors.first('kod_jenis_perunding_id') }"
          v-model="form.kod_jenis_perunding_id" class="form-control"
          name="kod_jenis_perunding_id"
          v-validate="'required'"
          data-vv-as="Jenis Firma"
        >
          <option :value="null">Sila Pilih</option>
          <option v-for="perunding in jenisRs" :value="perunding.id">{{ perunding.keterangan }}</option>
        </select>
        <span v-if="errors.first('kod_jenis_perunding_id')" class="invalid-feedback">{{ errors.first('kod_jenis_perunding_id') }}</span>
      </td>
      <td>
        <input
          :class="{'is-invalid': errors.first('nama_perunding') }"
          name="nama_perunding"
          class="form-control" type="text" :value="form.nama_perunding"
          @input="form.nama_perunding = $event.target.value.toUpperCase()"
          @keypress.enter.prevent="add"
          v-validate="'required|max:200'"
          data-vv-as="Nama"
        >
        <span v-if="errors.first('nama_perunding')" class="invalid-feedback">{{ errors.first('nama_perunding') }}</span>
      </td>
      <td>
        <input
          :class="{'is-invalid': errors.first('alamat_perunding') }"
          name="alamat_perunding"
          class="form-control" type="text" :value="form.alamat_perunding"
          @input="form.alamat_perunding = $event.target.value.toUpperCase()"
          @keypress.enter.prevent="add"
          v-validate="'required'"
          data-vv-as="Alamat"
        >
        <span v-if="errors.first('alamat_perunding')" class="invalid-feedback">{{ errors.first('alamat_perunding') }}</span>
      </td>
      <td>
        <input
          :class="{'is-invalid': errors.first('telefon_perunding') }"
          name="telefon_perunding"
          class="form-control" type="text" :value="form.telefon_perunding"
          @input="form.telefon_perunding = $event.target.value.toUpperCase()"
          @keypress.enter.prevent="add"
          v-validate="'required|numeric|length:9,11'"
          data-vv-as="No Telefon"
        >
        <span v-if="errors.first('telefon_perunding')" class="invalid-feedback">{{ errors.first('telefon_perunding') }}</span>
      </td>
      <td>
        <input
          :class="{'is-invalid': errors.first('fax_perunding') }"
          name="fax_perunding"
          class="form-control" type="text" :value="form.fax_perunding"
          @input="form.fax_perunding = $event.target.value.toUpperCase()"
          @keypress.enter.prevent="add"
          v-validate="'numeric|length:9,11'"
          data-vv-as="No Faks"
        >
        <span v-if="errors.first('fax_perunding')" class="invalid-feedback">{{ errors.first('fax_perunding') }}</span>
      </td>
      <td>
        <input
          :class="{'is-invalid': errors.first('emel_perunding') }"
          name="emel_perunding"
          class="form-control" type="text" :value="form.emel_perunding"
          @input="form.emel_perunding = $event.target.value"
          @keypress.enter.prevent="add"
          v-validate="'required|email'"
          data-vv-as="Emel"
        >
        <span v-if="errors.first('emel_perunding')" class="invalid-feedback">{{ errors.first('emel_perunding') }}</span>
      </td>
      <td width="1%" nowrap>
        <button class="btn btn-sm btn-secondary" type="button" @click.prevent="stopEdit" :disabled="loading"><i class="fas fa-times"></i></button>
        <button class="btn btn-sm btn-success" type="button" @click.prevent="save" :disabled="loading"><i class="fas fa-check"></i></button>
      </td>
    </template>
    <template v-else>
      <td>{{ optional(jenisRs.find(item => row.kod_jenis_perunding_id == item.id)).keterangan }}</td>
      <td>{{ row.nama_perunding }}</td>
      <td>{{ row.alamat_perunding }}</td>
      <td>{{ row.telefon_perunding }}</td>
      <td>{{ row.fax_perunding }}</td>
      <td>{{ row.emel_perunding }}</td>
      <td v-if="!readonly" nowrap>
        <button class="btn btn-sm btn-primary" type="button" @click.prevent="startEdit"><i class="fas fa-edit"></i></button>
        <button class="btn btn-sm btn-danger" type="button" @click.prevent="$emit('remove')"><i class="fas fa-trash"></i></button>
      </td>
    </template>
  </tr>
</template>

<script>
export default {
  name: "RowPaparButiranFirma",

  props: {
    row: Object,

    jenisRs: Array,

    loading: {
      type: Boolean,
      default: false,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editing: false,

      form: {
        kod_jenis_perunding_id: null,
        nama_perunding: null,
        alamat_perunding: null,
        telefon_perunding: null,
        fax_perunding: null,
        emel_perunding: null,
      }
    }
  },
  methods: {
    async save() {
      const passes = await this.$validator.validateAll();

      if (passes) {
        this.$emit('update', { form: this.form, callback: this.stopEdit });
      }
    },

    startEdit() {
      this.form = {
        kod_jenis_perunding_id: this.row.kod_jenis_perunding_id,
        nama_perunding: this.row.nama_perunding,
        alamat_perunding: this.row.alamat_perunding,
        telefon_perunding: this.row.telefon_perunding,
        fax_perunding: this.row.fax_perunding,
        emel_perunding: this.row.emel_perunding,
      };

      this.editing = true;
    },

    stopEdit() {
      this.editing = false;
    },
  }
}
</script>
