<template>
  <div style="display: contents">
    <template v-if="['G', 'I'].includes(kodJenisPerjanjianId)">
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="no_lot_pt" :class="{'is-invalid': errors.first('no_lot_pt')}" v-model="form.no_lot_pt" @input="form.no_lot_pt = $event.target.value.toUpperCase()" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" ref="nolot" type="text" class="form-control" name="no_unit" :class="{'is-invalid': errors.first('no_unit')}" v-model="form.no_unit" @input="form.no_unit = $event.target.value.toUpperCase()" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="luas_tanah" :class="{'is-invalid': errors.first('luas_tanah')}" v-model="form.luas_tanah" @input="form.luas_tanah = $event.target.value.toUpperCase()" v-validate="'required|decimal:2'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="luas_binaan" :class="{'is-invalid': errors.first('luas_binaan')}" v-model="form.luas_binaan" @input="form.luas_binaan = $event.target.value.toUpperCase()" v-validate="'required|decimal:2'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="jenis_susunatur" :class="{'is-invalid': errors.first('jenis_susunatur')}" v-model="form.jenis_susunatur" @input="form.jenis_susunatur = $event.target.value.toUpperCase()" v-validate="'required'">
      </td>
      <td class="px-1">
        <select2
          name="kedudukan_lot_id"
          class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('kedudukan_lot_id')}"
          v-model="form.kedudukan_lot_id"
          v-validate="'required'"
        >
          <option :value="null">Sila Pilih</option>
          <option v-for="kedudukanlot in kodKedudukanLotRs" :value="kedudukanlot.id" :key="`kedudukanlot_${kedudukanlot.id}`">{{ kedudukanlot.keterangan }}</option>
        </select2>
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="bil_bilik_tidur" :class="{'is-invalid': errors.first('bil_bilik_tidur')}" v-model="form.bil_bilik_tidur" v-validate="'required|numeric'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="bil_bilik_air" :class="{'is-invalid': errors.first('bil_bilik_air')}" v-model="form.bil_bilik_air" v-validate="'required|numeric'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="harga" :class="{'is-invalid': errors.first('harga')}" v-model="form.harga" v-validate="'required|decimal:2'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="harga_jualan" :class="{'is-invalid': errors.first('harga_jualan')}" v-model="form.harga_jualan" @input="form.harga_jualan = $event.target.value.toUpperCase()" v-validate="'required|decimal:2'">
      </td>
    </template>
    <template v-else>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="strata_menara" :class="{'is-invalid': errors.first('strata_menara')}" v-model="form.strata_menara" @input="form.strata_menara = $event.target.value.toUpperCase()" v-validate="{'required': ['HH', 'JH'].includes(maklumat.kod_type_perjanjian_id) }">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="strata_tingkat" :class="{'is-invalid': errors.first('strata_tingkat')}" v-model="form.strata_tingkat" v-validate="'required|numeric'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="no_petak" :class="{'is-invalid': errors.first('no_petak')}" v-model="form.no_petak" v-validate="'required|numeric'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="no_unit" :class="{'is-invalid': errors.first('no_unit')}" v-model="form.no_unit" @input="form.no_unit = $event.target.value.toUpperCase()" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="jenis_layout" :class="{'is-invalid': errors.first('jenis_layout')}" v-model="form.jenis_layout" @input="form.jenis_layout = $event.target.value.toUpperCase()" v-validate="'required'">
      </td>
      <td class="px-1">
        <select2
          name="kedudukan_lot_id"
          class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('kedudukan_lot_id')}"
          v-model="form.kedudukan_lot_id"
          v-validate="'required'"
        >
          <option :value="null">Sila Pilih</option>
          <option v-for="kedudukanlot in kodKedudukanLotRs" :value="kedudukanlot.id" :key="`kedudukanlot_${kedudukanlot.id}`">{{ kedudukanlot.keterangan }}</option>
        </select2>
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="jadual_petak_utama" :class="{'is-invalid': errors.first('jadual_petak_utama')}" v-model="form.jadual_petak_utama" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="luas_tanah" :class="{'is-invalid': errors.first('luas_tanah')}" v-model="formattedLuasTanah" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="petak_aksesori" :class="{'is-invalid': errors.first('petak_aksesori')}" v-model="form.petak_aksesori" @input="form.petak_aksesori = $event.target.value.toUpperCase()" v-validate="{'required': ['HH', 'JH'].includes(maklumat.kod_type_perjanjian_id) }">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="keluasan" :class="{'is-invalid': errors.first('keluasan')}" v-model="formattedKeluasan" v-validate="{'required': ['HH', 'JH'].includes(maklumat.kod_type_perjanjian_id)}">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="jadual_petak_aksesori" :class="{'is-invalid': errors.first('jadual_petak_aksesori')}" v-model="form.jadual_petak_aksesori" v-validate="{'required': ['HH', 'JH'].includes(maklumat.kod_type_perjanjian_id) }">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="bilangan_tlk" :class="{'is-invalid': errors.first('bilangan_tlk')}" v-model="form.bilangan_tlk" v-validate="{'required': ['HH', 'JH'].includes(maklumat.kod_type_perjanjian_id), 'numeric': true }">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="unit_syer" :class="{'is-invalid': errors.first('unit_syer')}" v-model="form.unit_syer" v-validate="'required|numeric'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="harga_jualan" :class="{'is-invalid': errors.first('harga_jualan')}" v-model="formattedHargaJjualan" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="harga" :class="{'is-invalid': errors.first('harga')}" v-model="formattedHarga" v-validate="'required'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="bil_bilik_tidur" :class="{'is-invalid': errors.first('bil_bilik_tidur')}" v-model="form.bil_bilik_tidur" v-validate="'required|numeric'">
      </td>
      <td class="px-1">
        <input @keypress.enter="save" type="text" class="form-control" name="bil_bilik_air" :class="{'is-invalid': errors.first('bil_bilik_air')}" v-model="form.bil_bilik_air" v-validate="'required|numeric'">
      </td>
    </template>
    <td class="text-center"><input @keypress.enter="save" type="checkbox" v-model="form.tuan_tanah"  @change="tuanTanahChange"></td>
    <td class="text-center" v-if="maklumat.pengesahan_kuotabumi == `Y`"><input @keypress.enter="save" type="checkbox" v-model="form.kuota_bumi" :disabled="!!form.tuan_tanah"></td>
    <td class="px-1" v-if="programKemajuan == 1">
      <select2
        name="kod_skim_projek_id"
        class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('kod_skim_projek_id')}"
        v-model="form.kod_skim_projek_id"
        v-validate="'required'"
      >
        <option :value="null">Sila Pilih</option>
        <option v-for="skim in kodSkimProjekRs" :value="skim.id" :key="`skim_${skim.id}`">{{ skim.keterangan }}</option>
      </select2>
    </td>
    <td v-if="!isEdit">
      <button type="button" class="btn btn-success" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="save" :disabled="loading" title="Tambah Unit"><i class="fas fa-plus"></i></button>
    </td>
    <template v-else>
      <td v-if="nolotUnit.profile_nolot && nolotUnit.profile_nolot.status_jualan">
        <span :title="nolotUnit.profile_nolot.no_kp"><i class="fa fa-user"></i></span>
      </td>
      <td v-else-if="!readonly" nowrap>
        <button type="button" @click.prevent="$emit('cancelSave')" :disabled="loading" class="btn btn-icon text-dark btn-sm mr-2" title="Batal"><i class="fas fa-times-circle"></i></button>
        <button type="button" @click.prevent="save" :disabled="loading" class="btn btn-icon text-success" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" title="Simpan"><i class="fas fa-check"></i></button>
      </td>
    </template>
  </div>
</template>


<script>
export default {
  name: "FormUnit",

  props: {
    maklumat: Object,
    kodJenisPerjanjianId: null,
    programKemajuan: {
      type: Number,
      default: 0,
    },
    kodSkimProjekRs: Array,
    form: Object,
    nolotUnit: Object,

    readonly: {
      type: Boolean,
      default: false,
    },

    isEdit: {
      type: Boolean,
      default: false
    },

    kodKedudukanLotRs: Array,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed : {
    formattedLuasTanah: {
      get() {
        const value = parseFloat(this.form.luas_tanah);
        return isNaN(value) ? '' : value.toFixed(2);
      },
      set(value) {
        this.form.luas_tanah = value;
      }
    },
    formattedKeluasan: {
      get() {
        const value = parseFloat(this.form.keluasan);
        return isNaN(value) ? '' : value.toFixed(2);
      },
      set(value) {
        this.form.keluasan = value;
      }
    },
    formattedHargaJjualan: {
      get() {
        const value = parseFloat(this.form.harga_jualan);
        return isNaN(value) ? '' : value.toFixed(2);
      },
      set(value) {
        this.form.harga_jualan = value;
      }
    },
    formattedHarga: {
      get() {
        const value = parseFloat(this.form.harga);
        return isNaN(value) ? '' : value.toFixed(2);
      },
      set(value) {
        this.form.harga = value;
      }
    },
  },
  methods: {
    async save() {
      if (this.loading) {
        return;
      }

      const self = this;
      const passes = await this.$validator.validateAll();
      if (!passes) {
        return;
      }

      this.$emit('save', this.form, (loading) => {
        self.loading = loading;
        self.isEdit = false;
      })
    },

    tuanTanahChange() {
      this.form.kuota_bumi = 0;
    },
  }
}
</script>
