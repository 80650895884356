<template>
  <div class="card bg-light">
    <div class="card-body">
      <h4 class="card-title">SENARAI NAMA PEMBELI</h4>
      <!-- <div class="alert alert-warning">
        <strong>Nota: </strong>
        <p class="m-0">1. Sila Kemaskini Maklumat Pembeli Perumahan di Modul <a :href="`/pemaju/kuota-bumi/${maklumat.pemajuan_id}/borang?redirect=${currentUrl()}`">Pengurusan No. Lot / Unit dan Pembeli</a></p>
        <p class="m-0">2. Setiap Butiran Pembangunan yang telah dikemaskini dan belum ada pembeli, sila tanda kotak <i>Perakuan
          Tiada Pembeli</i></p>
      </div> -->
      <div class="table-responsive">
        <table class="bg-white table table-hover table-card">
          <thead class="thead-light">
          <tr>
            <th>Jenis Rumah</th>
            <th>Harga (Min)</th>
            <th>Harga (Max)</th>
            <th>Bil Pembeli / Bil Unit</th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="(item, index) in pembangunanRs"
            :key="`pembangunan-${index}`"
          >
            <td>{{ item.jenis_rumah.keterangan }}</td>
            <td>{{ optional(getPembangunan(item)).harga_min | currency('RM', ',', 2, '.', 'front', false) }}</td>
            <td>{{ optional(getPembangunan(item)).harga_max | currency('RM', ',', 2, '.', 'front', false) }}</td>
            <td>{{ optional(getJualan(item)).bil_dijual || 0 }} / {{ optional(getPembangunan(item)).bil_dilulus || 0 }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BahagianE",
    props: {
      maklumat: Object,

      pembangunanRs: {
        type: Array,
        default: () => ([]),
      },

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    methods: {
      getPembangunan(pembangunan) {
        return this.maklumat.pembangunan_rs.find(i => pembangunan.maklumat7f.id === i.maklumat7f_id);
      },

      getJualan(pembangunan) {
        return this.maklumat.jualan_rs.find(i => pembangunan.maklumat7f.id === i.maklumat7f_id);
      },

      getKemajuan(pembangunan) {
        return this.maklumat.kemajuan_rs.find(i => pembangunan.maklumat7f.id === i.maklumat7f_id);
      },
      currentUrl() {
        return encodeURIComponent(window.location.pathname + '#bahagian-e');
      }
    }
  }
</script>

<style scoped>

</style>
