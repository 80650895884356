<template>
  <div>
    <div class="card position-relative" @click="$emit('click', $event)" style="cursor: pointer">
      <div class="card-body px-3 py-3">
        <h5>{{ no }}.&nbsp; {{ tanah.label }}</h5>
        <div class="row mt-4">
          <div class="col-6">
            <div>Status Hakmilik</div>
            <div v-if="edit">
              <select v-model="form.kod_jenis_hakmilik_id" @click.stop>
                <option :value="null">Sila Pilih Status Hakmilik</option>
                <option v-for="jenis_hakmilik in kodJenisHakmilikRs" :key="`hakmilik-${jenis_hakmilik.id}`" :value="jenis_hakmilik.id">{{ jenis_hakmilik.keterangan }}</option>
              </select>
            </div>
            <div v-else class="text-grey">{{ tanah.jenis_hakmilik ? tanah.jenis_hakmilik.keterangan : 'Tiada Maklumat'}}</div>
            <div><b>No. Hakmilik : </b><i>{{ tanah.butiran_rs.map(item => item.no_geran).join(' , ') }}</i></div>
            <div><em style="color: black; font-weight: bold;">Jumlah Butiran Tanah : {{ tanah.butiran_rs.length }}</em></div>
          </div>
          <div class="col-6 text-right">
            <div>
              {{ tanah.negeri ? tanah.negeri.keterangan : '' }}
            </div>
            <div class="text-grey">
              <div class="d-inline-block">Parlimen : {{ tanah.parlimen ? tanah.parlimen.keterangan : 'Tiada Maklumat'}}</div>
              <div v-if="tanah.negeri && tanah.negeri.ada_dun === 'Y'" class="d-inline-block ml-3">DUN : {{ tanah.dun ? tanah.dun.keterangan : 'Tiada Maklumat'}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute text-right" style="top: 5px; right: 5px" v-if="!readonly">
        <button class="btn pb-0 pr-2" @click.stop="$emit('destroyTanah')"><i class="fa fa-trash text-danger"></i></button>

        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" v-if="!readonly && !edit" @click.stop="editing"><i class="fa fa-edit"></i></button>

        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" v-if="!readonly && edit" @click.stop="$emit('editTanah', form, stopEdit)" :disabled="saving"><i class="fa fa-save"></i></button>
        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" v-if="!readonly && edit" @click.stop="stopEdit" :disabled="saving"><i class="fa fa-times"></i></button>
      </div>
      <div class="position-absolute" style="bottom: 5px; right: 5px">
        <button class="btn pb-0 pr-2" type="button"><i class="fa" :class="{ 'fa-chevron-down': show, 'fa-chevron-up': !show}"></i></button>
      </div>
    </div>
    <div v-if="show" class="ml-4 p-4" style="border-left: 4px solid #dee2e6">
      <div class="d-flex align-items-center mb-2">
        <h5 class="mb-0">BUTIRAN TANAH KESELURUHAN</h5>
        <div class="ml-3">
          <button v-if="!readonly" class="btn btn-sm btn-success" type="button" @click.prevent="$emit('addButiran')"><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <slot></slot>
    </div>

    <tambah-butiran-tanah-modal
        :tanah="tanah"
        :maklumat="maklumat"
        :kod-bebanan-tanah-rs="kodBebananTanahRs"
        :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
        :kod-status-tanah-rs="kodStatusTanahRs"
        :kod-rezab-tanah-rs="kodRezabTanahRs"
        :kod-syarat-nyata-rs="kodSyaratNyataRs"
        :kod-jenis-kaveat-rs="kodJenisKaveatRs"
        :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"
        :readonly="readonly"
        :kod-daerah-rs="kodDaerahRs"
        :kod-bandar-rs="kodBandarRs"
      ></tambah-butiran-tanah-modal>
  
      <gadaian-tanah-modal
        :kod-bank-hq-rs="kodBankHqRs"
        :kod-negeri-rs="kodNegeriRs"
        :kod-daerah-rs="kodDaerahRs"
        :kod-bandar-rs="kodPoskodBandarRs"
        :kod-poskod-rs="kodPoskodRs"
        :readonly="readonly"
      ></gadaian-tanah-modal>
  
  
      <kaveat-tanah-modal
        :kod-jenis-kaveat-rs="kodJenisKaveatRs"
        :readonly="readonly"
      ></kaveat-tanah-modal>
  </div>
</template>

<script>
import TambahButiranTanahModal from './TambahButiranTanahModal';
import KaveatTanahModal from "./KaveatTanahModal";
import GadaianTanahModal from "./GadaianTanahModal";

export default {
  components: {
    TambahButiranTanahModal,

    KaveatTanahModal,
    GadaianTanahModal,
  },

  name: "Tanah",

  props: {
    tanah: Object,
    totaltanah: Number,
    maklumat: Object,

    saving: {
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false,
    },

    no: {
      type: Number,
      default: 1,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodBankHqRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,

    kodJenisHakmilikRs: Array,
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,
  },

  data() {
    return {
      edit: false,
      form: {},
    }
  },

  methods: {
    editing() {
      this.form = JSON.parse(JSON.stringify(this.tanah));
      this.edit = true;
    },

    stopEdit() {
      this.edit = false;
    }
  },
}
</script>

<style scoped>

</style>
