<template>
    <section class="card">
      <div class="card-body pb-0">
        <div class="alert alert-warning" v-if="kuiri">
          <strong>Catatan Kuiri:</strong>
          <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
        </div>

        <h5 class="card-title">Sila kemaskini maklumat dan tekan butang "Simpan"</h5>
      </div>
      <div class="table-responsive">
          <table class="table table-bordered mb-0 table-card">
            <thead class="thead-light">
            <tr>
              <th>Bil.</th>
              <th>Jenis Dokumen</th>
              <th>Tarikh</th>
              <th>Tarikh Luput</th>
              <th>No Rujukan</th>
              <th>Dokumen Rujukan</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="(kelulusan_agensi, index) in kodKelulusanAgensiRs" :key="`kelulusan-agensi-${kelulusan_agensi.id}`">
              <td>{{ index + 1 }}.</td>
              <td><a href="#" @click.prevent="update(kelulusan_agensi.id)">{{ kelulusan_agensi.keterangan}}<span class="label-required" v-if="kelulusan_agensi.mandatori">*</span></a></td>
              <td>{{ docs[kelulusan_agensi.id].tarikh_kelulusan }}</td>
              <td>{{ kelulusan_agensi.id === 6 ? docs[kelulusan_agensi.id].tarikh_luput_kelulusan : '' }}</td>
              <td>{{ docs[kelulusan_agensi.id].rujukan_kelulusan }}</td>
              <td>
                <a  style="display: flex; max-width: 120px;"  v-if="docs[kelulusan_agensi.id].dokumen_rujukan" :href="docs[kelulusan_agensi.id].dokumen_rujukan.url" target="_blank" :title="docs[kelulusan_agensi.id].dokumen_rujukan.nama_asal"><i class="fas fa-file-pdf mr-2" /> <span style="flex: 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ docs[kelulusan_agensi.id].dokumen_rujukan.nama_asal }}</span></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      <div class="card-footer">
      </div>

      <kemaskini-tarikh-dokumen-lesen-modal></kemaskini-tarikh-dokumen-lesen-modal>
    </section>
</template>

<script>
import TambahButiranBangunanModal from "../../common/TambahButiranBangunanModal";
import KemaskiniButiranUnitModal from "../../common/KemaskiniButiranUnitModal";
import KemaskiniTarikhDokumenLesenModal from "../../common/KemaskiniTarikhDokumenLesenModal";
export default {
  name: "BahagianC2",
  components: {KemaskiniTarikhDokumenLesenModal, KemaskiniButiranUnitModal, TambahButiranBangunanModal},
  props: {
    kodKelulusanAgensiRs: Array,

    maklumat: Object,
    kelulusan_agensi_rs: Array,

    kuiri: null,
  },
  data() {
    const docs = this.createDocs();

    return {
      docs,
      loading: false,
    }
  },

  methods: {
    createDocs() {
      const docs = {};

      for (let i = 0; i < this.kodKelulusanAgensiRs.length; i++) {
        const key = this.kodKelulusanAgensiRs[i].id;

        const found = this.kelulusan_agensi_rs.find(item => item.kod_kelulusan_agensi_id == key);

        docs[key] = {
          tarikh_kelulusan: found ? found.tarikh_kelulusan : null,
          rujukan_kelulusan: found ? found.rujukan_kelulusan : null,
          dokumen_rujukan: found ? found.dokumen_rujukan : null,
        };

        if (key === 6) {
          docs[key].tarikh_luput_kelulusan = found ? found.tarikh_luput_kelulusan : null;
        }
      }

      return docs;
    },

    update(key) {
      vmodal.open('kemaskinitarikhdokumenlesen', {
        maklumatId: this.maklumat.id,
        key, ...this.docs[key],
      }, ({ tarikh_kelulusan, tarikh_luput_kelulusan, rujukan_kelulusan, dokumen_rujukan }, maklumat) => {

        this.docs[key].tarikh_kelulusan = tarikh_kelulusan;
        this.docs[key].rujukan_kelulusan = rujukan_kelulusan;
        this.docs[key].dokumen_rujukan = dokumen_rujukan;


        const kelulusan_agensi = {};
        kelulusan_agensi.tarikh_kelulusan = tarikh_kelulusan;
        kelulusan_agensi.rujukan_kelulusan = rujukan_kelulusan;
        kelulusan_agensi.dokumen_rujukan = dokumen_rujukan;

        if (key === 6) {
          this.docs[key].tarikh_luput_kelulusan = tarikh_luput_kelulusan;
          kelulusan_agensi.tarikh_luput_kelulusan = tarikh_luput_kelulusan;
        }

        const found = this.kelulusan_agensi_rs.find(item => item.kod_kelulusan_agensi_id == key)
        if (!found) {
          this.kelulusan_agensi_rs.push(kelulusan_agensi);
        } else {
          const index = this.kelulusan_agensi_rs.indexOf(found);
          this.kelulusan_agensi_rs[index] = {
            ...this.kelulusan_agensi_rs[index],
            ...kelulusan_agensi,
          }
        }

        this.$emit('update', this.kelulusan_agensi_rs);
      })
    },
  }
}
</script>

<style scoped>

</style>
