<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact">
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">1. Nama Pemaju</label>
          <div class="col-md-9">
            <input type="text" class="form-control" disabled :value="pemaju.nama">
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">2. Alamat Pemaju</label>
          <div class="col-md-9">
            <textarea type="text" class="form-control" disabled>{{ pemaju.alamat_perniagaan1 }}</textarea>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">3. Poskod</label>
          <div class="col-md-3">
            <input type="text" class="form-control" disabled :value="pemaju.poskod_perniagaan">
          </div>
          <label class="col-form-label col-md-3">4. Bandar</label>
          <div class="col-md-3">
            <input type="text" class="form-control" disabled :value="optional(pemaju.bandar_perniagaan).keterangan">
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">5. Negeri</label>
          <div class="col-md-3">
            <input type="text" class="form-control" disabled :value="optional(pemaju.negeri_perniagaan).keterangan">
          </div>
        </div>
        <div class="form-group form-row" v-if="pegawai_perhubungan_rs.length">
          <label class="col-form-label col-md-3">Pegawai Perhubungan</label>
          <div class="col-md-9">
            <select class="form-control">
              <option selected :value="null">Sila Pilih</option>
              <option v-for="(pegawai, index) in pegawai_perhubungan_rs" :value="index">{{ pegawai.nama }}</option>
            </select>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">6. Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
          <div class="col-md-9">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai') }" name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai" @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required|max:100'"  data-vv-as="Nama Pegawai" />
            <span class="invalid-feedback" v-if="errors.first('pemaju_nama_pegawai')">{{ errors.first('pemaju_nama_pegawai') }}</span>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">7. No Telefon Pejabat <span class="label-required">*</span></label>
          <div class="col-md-3">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_pejabat') }" name="pemaju_no_pejabat" :value="pemaju_no_pejabat" @input="$emit('update:pemaju_no_pejabat', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:9,11'" data-vv-as="No Telefon Pejabat" />
            <span class="invalid-feedback" v-if="errors.first('pemaju_no_pejabat')">{{ errors.first('pemaju_no_pejabat') }}</span>
          </div>
          <label class="col-form-label col-md-3">8. No Telefon Bimbit <span class="label-required">*</span></label>
          <div class="col-md-3">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit') }" name="pemaju_no_bimbit" :value="pemaju_no_bimbit" @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:10,11'" data-vv-as="No Telefon Bimbit" />
            <span class="invalid-feedback" v-if="errors.first('pemaju_no_bimbit')">{{ errors.first('pemaju_no_bimbit') }}</span>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">10. Emel <span class="label-required">*</span></label>
          <div class="col-md-3">
            <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_email') }" name="pemaju_email" :value="pemaju_email" @input="$emit('update:pemaju_email', $event.target.value)" v-validate="'required|email'" data-vv-as="Emel" />
            <span class="invalid-feedback" v-if="errors.first('pemaju_email')">{{ errors.first('pemaju_email') }}</span>
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">11. No. Lesen</label>
          <div class="col-md-3">
            <input type="text" class="form-control" disabled :value="pemaju.lesen.no_lesenpermit">
          </div>
        </div>
        <div class="form-group form-row">
          <label class="col-form-label col-md-3">12. Nama Pemajuan</label>
          <div class="col-md-9">
            <input type="text" class="form-control" disabled :value="pemajuan.nama">
          </div>
        </div>
        <div class="form-group">
          <label>13. Jenis Pemajuan</label>
          <div class="table-responsive mt-2">
            <table class="bg-white table table-hover table-card">
              <thead class="thead-light">
              <tr>
                <th>Bil</th>
                <th>Jenis Rumah</th>
                <th>Bil Unit Diluluskan</th>
                <th>Bil Tingkat</th>
                <th>Tarikh CFO / CCC yang diperolehi.</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(pembangunan, index) in pemajuan.pembangunan_rs" :key="`pembangunan-${pembangunan.id}`">
                <td>{{ index + 1 }}</td>
                <td>{{ optional(pembangunan.jenis_rumah).keterangan }}</td>
                <td>{{ pembangunan.bil_unit }}</td>
                <td>{{ pembangunan.bil_tingkat }}</td>
                <td>{{ pembangunan.maklumat7f ? (pembangunan.maklumat7f.tarikh_pbt_ccc || pembangunan.maklumat7f.tarikh_pbt_cf) : '' }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
  export default {
    inject: ['$validator'],

    props: {
      pemaju: {
        type: Object,
      },

      pemajuan: {
        type: Object,
      },

      pengarah: {
        type: Object,
      },
      pemaju_nama_pegawai: String,
      pemaju_no_bimbit: String,
      pemaju_no_pejabat: String,
      pemaju_no_faks: String,
      pemaju_email: String,

      pegawai_perhubungan_rs: { type: Array, default: () => ([]) }
    },
  }
</script>
