<template>
  <div style="width: 100%; max-width: calc(75vw - 1rem);">
    <div class="card position-relative" @click="$emit('click', $event)" style="cursor: pointer">
      <div v-if="!editing" class="card-body px-3 py-3">
        <h5>{{ bangunan.jenis_rumah.keterangan }}</h5>
        <div class="row mb-2">
          <div class="col-6">
            <div class="mb-2">
              <div class="font-weight-bold">Jenis Perjanjian</div>
              {{  bangunan.jenis_perjanjian.keterangan }}
            </div>
            <div class="text-dark col-form-label-sm">
              <div class="d-inline-block mr-3">Bil Unit : {{ bangunan.nolot_unit_rs.length }} / {{ bangunan.bil_unit || 0 }}</div>
              <div class="d-inline-block">Bil Tingkat : {{ bangunan.bil_tingkat || 'Tiada Maklumat'}}</div>
            </div>
          </div>
          <div class="col-6 text-right">
            <div v-if="bangunan.orig_harga_minima">
              <div>
                <b>Julat Harga Diluluskan</b>
              </div>
              <div>
                {{ bangunan.orig_harga_minima | currency }} - {{ bangunan.orig_harga_maksima | currency }}
              </div>
            </div>
            <div>
              <b>Julat Harga</b>
            </div>
            <div>
              {{ bangunan.harga_minima | currency }} - {{ bangunan.harga_maksima | currency }}
            </div>
          </div>
        </div>
      </div>
      <div v-else class="card-body px-3 py-3" @click.stop>
        <div>
          <select name="jenis_rumah" v-model="form.kod_jenis_rumah_id">
            <option v-for="item in filteredJenisRumahRs(form.kod_jenis_hakmilik_id)" :value="item.id">{{ item.keterangan }}</option>
          </select>
        </div>
        <div class="row mb-2">
          <div class="col-6">
            <div class="mb-2">
              <div class="font-weight-bold">Jenis Perjanjian</div>
              {{  bangunan.jenis_perjanjian.keterangan }}
            </div>
            <div class="text-dark col-form-label-sm">
              <div class="d-inline-block mr-3">Bil Unit : {{ bangunan.nolot_unit_rs.length }} / <input style="width: 50px" v-model="form.bil_unit"></div>
              <div class="d-inline-block">Bil Tingkat : <input style="width: 50px" v-model="form.bil_tingkat"></div>
            </div>
          </div>
          <div class="col-6 text-right">
            <div v-if="bangunan.orig_harga_minima">
              <div>
                <b>Julat Harga Diluluskan</b>
              </div>
              <div>
                {{ bangunan.orig_harga_minima | currency }} - {{ bangunan.orig_harga_maksima | currency }}
              </div>
            </div>
            <div>
              <b>Julat Harga</b>
            </div>
            <div>
              {{ bangunan.harga_minima | currency }} - {{ bangunan.harga_maksima | currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute" style="top: 5px; right: 5px" v-if="!readonly">
        <div class="d-flex" v-if="!editing">
          <button class="btn btn-sm btn-link btn-icon pb-0 pr-2 pt-2" @click.stop.prevent="edit" title="Kemaskini Komponen"><i class="fa fa-edit text-primary"></i></button>
          <button v-if="countNolotHasDelete == 0" class="btn btn-sm btn-link btn-icon pb-0 pr-2 pt-2" @click.stop.prevent="$emit('destroyBangunan')" title="Hapus Komponen"><i class="fa fa-trash text-danger"></i></button>
        </div>
        <div class="d-flex" v-else>
          <button class="btn btn-sm btn-link btn-icon pb-0 pr-2 pt-2" @click.stop.prevent="editing = false" title="Batal Kemaskini Komponen"><i class="fa fa-times-circle text-dark"></i></button>
          <button class="btn btn-sm btn-link btn-icon pb-0 pr-2 pt-2" v-if="saving" @click.stop.prevent><i class="fa fa-spin fa-spinner text-success"></i></button>
          <button class="btn btn-sm btn-link btn-icon pb-0 pr-2 pt-2" v-else @click.stop.prevent="saveEdit" title="Simpan Kemaskini Komponen"><i class="fa fa-check text-success"></i></button>
        </div>
      </div>
      <div class="position-absolute" style="bottom: 5px; right: 5px">
        <button class="btn pb-0 pr-2" type="button"><i class="fa" :class="{ 'fa-chevron-down': show, 'fa-chevron-up': !show}"></i></button>
      </div>
    </div>
    <div v-if="show" class="ml-4 p-4" style="border-left: 4px solid #dee2e6">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bangunan",
  props: {
    bangunan: Object,

    show: {
      type: Boolean,
      default: false,
    },

    no: {
      type: Number,
      default: 1,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    kodJenisKediamanRs: {
      type: Array,
      default: () => ([])
    }

  },

  data() {
    return {
      editing: false,
      saving: false,
      form: {},
    }
  },

  methods: {
    edit() {
      this.form = {
        ...this.bangunan
      }

      this.editing = true;
    },
    filteredJenisRumahRs() {
      return this.kodJenisKediamanRs;
    },

    saveEdit() {
      if (this.saving) {
        return;
      }

      this.saving = true;
      this.$emit('editBangunan',  this.form, this.reset)
    },

    reset() {
      this.saving = false;
      this.editing = false;
    }
  },

  computed: {
    countNolotHasDelete() {
      const countData = this.bangunan.nolot_unit_rs.filter((nolot_unit) => {
        return nolot_unit.profile_nolot === null || (nolot_unit.profile_nolot && nolot_unit.profile_nolot.status_jualan === 0)
      });
      return countData.length
    }
  }
}
</script>

<style scoped>

</style>
