<template>
  <div class="card position-relative">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <section class="form-compact row">
        <div class="col-lg-12">
          <h5 class="card-title">MAKLUMAT HDA</h5>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;No Akaun HDA</label>
            <div class="col-md-8">
              <input
                type="text" name="hda_no_akaun"
                class="form-control" :class="{'is-invalid': errors.first('hda_no_akaun')}"
                :value="form.hda_no_akaun" @input="form.hda_no_akaun = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Nama Bank</label>
            <div class="col-md-8">
              <input
                type="text" name="hda_nama_bank"
                class="form-control" :class="{'is-invalid': errors.first('hda_nama_bank')}"
                :value="form.hda_nama_bank" @input="form.hda_nama_bank = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <section>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Alamat Bank</label>
              <div class="col-md-8">
                <input
                  type="text" name="hda_alamat_bank1"
                  class="form-control" :class="{'is-invalid': errors.first('hda_alamat_bank1')}"
                  :value="form.hda_alamat_bank1" @input="form.hda_alamat_bank1 = $event.target.value.toUpperCase()"
                  :disabled="readonly" v-validate="'required'"
                >
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input
                  type="text" name="hda_alamat_bank2"
                  class="form-control"
                  :value="form.hda_alamat_bank2" @input="form.hda_alamat_bank2 = $event.target.value.toUpperCase()"
                  :disabled="readonly"
                >
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input
                  type="text" name="hda_alamat_bank3"
                  class="form-control"
                  :value="form.hda_alamat_bank3" @input="form.hda_alamat_bank3 = $event.target.value.toUpperCase()"
                  :disabled="readonly"
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Poskod</label>

              <div class="col-md-4">
                <input
                  type="text" name="hda_poskod"
                  class="form-control" :class="{'is-invalid': errors.first('hda_poskod')}"
                  :value="form.hda_poskod" @input="form.hda_poskod = $event.target.value.toUpperCase()"
                  :disabled="readonly"
                  v-validate="'required|numeric|length:5,6'"
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri & Daerah <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2
                  name="hda_kod_negeri_id"
                  class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('hda_kod_negeri_id')}"
                  v-model="form.hda_kod_negeri_id"
                  @change="form.hda_kod_daerah_id = null; form.hda_kod_bandar_id = null;"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih Negeri</option>
                  <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri_${negeri.id}`">{{ negeri.keterangan }}</option>
                </select2>
              </div>
              <div class="col-md-4">
                <select2
                  name="hda_kod_daerah_id"
                  class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('hda_kod_daerah_id')}"
                  v-model="form.hda_kod_daerah_id"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih Daerah</option>
                  <option v-for="daerah in filteredDaerahRs(form.hda_kod_negeri_id)" :value="daerah.id" :key="`daerah_${daerah.id}`">{{ daerah.keterangan }}</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2
                  name="hda_kod_bandar_id"
                  class="form-control" :class="{'is-invalid': errors.first('hda_kod_bandar_id')}"
                  v-model="form.hda_kod_bandar_id"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih Mukim / Bandar / Pekan</option>
                  <option v-for="bandar in filteredBandarRs(form.hda_kod_negeri_id)" :value="bandar.id" :key="`bandar_${bandar.id}`">{{ bandar.keterangan }}</option>
                </select2>
              </div>
            </div>
          </section>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;No. Telefon</label>
            <div class="col-md-8">
              <input
                type="text" name="hda_no_telefon"
                class="form-control" :class="{'is-invalid': errors.first('hda_no_telefon')}"
                :value="form.hda_no_telefon" @input="form.hda_no_telefon = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Tarikh Akaun Dibuka</label>
            <div class="col-md-4">
              <datepicker
                :class="{'is-invalid': errors.first('hda_tarikh_dibuka')}"
                name="hda_tarikh_dibuka"
                v-model="form.hda_tarikh_dibuka"
                :disabled="readonly" v-validate="'required'"
              >
              </datepicker>
            </div>
          </div>
        </div>
      </section>
      <br>
      <section class="form-compact row">
        <div class="col-lg-12">
          <h5 class="card-title">WANG DEPOSIT LESEN</h5>
          <div class="form-compact form-row">
            <div class="col-lg-4">
              <div class="form-group form-row">
                <label class="col-form-label col-12" style="white-space: pre-wrap;">1.&#9;Jenis Wang Deposit</label>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="form-group" tabindex="-1">
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    id="kod_jenis_wp_hda"
                    name="kod_jenis_wp_id"
                    class="form-check-input"
                    :class="{'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="form.kod_jenis_wp_id = 'T'"
                    :checked="form.kod_jenis_wp_id === 'T'"
                    v-validate="'required'"
                    value="T"
                  />
                  <label class="form-check-label" for="kod_jenis_wp_hda">HDA</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    id="kod_jenis_wp_bg"
                    name="kod_jenis_wp_id"
                    class="form-check-input"
                    :class="{'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="form.kod_jenis_wp_id = 'S'"
                    :checked="form.kod_jenis_wp_id === 'S'"
                    value="S"
                  />
                  <label class="form-check-label" for="kod_jenis_wp_bg">Jaminan Bank / Bank Guarantee</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    type="radio"
                    id="kod_jenis_wp_aa"
                    name="kod_jenis_wp_id"
                    class="form-check-input"
                    :class="{'is-invalid': errors.first('kod_jenis_wp_id') }"
                    @input.prevent="form.kod_jenis_wp_id = 'A'"
                    :checked="form.kod_jenis_wp_id === 'A'"
                    value="A"
                  />
                  <label class="form-check-label" for="kod_jenis_wp_aa">Akaun Amanah</label>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;No. BG / No. Sijil</label>
            <div class="col-md-8">
              <input
                type="text" name="wp_no_sijil"
                class="form-control" :class="{'is-invalid': errors.first('wp_no_sijil')}"
                :value="form.wp_no_sijil" @input="form.wp_no_sijil = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Amaun</label>
            <div class="col-md-8">
              <input
                type="text" name="wp_amaun"
                class="form-control" :class="{'is-invalid': errors.first('wp_amaun')}"
                :value="form.wp_amaun" @input="form.wp_amaun = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required|decimal:2'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Nama Bank</label>
            <div class="col-md-8">
              <input
                type="text" name="wp_nama_bank"
                class="form-control" :class="{'is-invalid': errors.first('wp_nama_bank')}"
                :value="form.wp_nama_bank" @input="form.wp_nama_bank = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Cawangan</label>
            <div class="col-md-8">
              <input
                type="text" name="wp_nama_cawangan"
                class="form-control" :class="{'is-invalid': errors.first('wp_nama_cawangan')}"
                :value="form.wp_nama_cawangan" @input="form.wp_nama_cawangan = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Tarikh Sahlaku</label>
            <div class="col-md-4">
              <datepicker
                :class="{'is-invalid': errors.first('wp_tarikh_sahlaku')}"
                name="wp_tarikh_sahlaku"
                v-model="form.wp_tarikh_sahlaku"
                :disabled="readonly" v-validate="'required'"
              ></datepicker>
            </div>
          </div>
        </div>
      </section>
      <br>
      <section class="form-compact row">
        <div class="col-lg-12">
          <h5 class="card-title">JAMINAN BANK TANAH</h5>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;No. BG / No. Sijil</label>
            <div class="col-md-8">
              <input
                type="text" name="tanah_no_sijil"
                class="form-control" :class="{'is-invalid': errors.first('tanah_no_sijil')}"
                :value="form.tanah_no_sijil" @input="form.tanah_no_sijil = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Amaun</label>
            <div class="col-md-8">
              <input
                type="text" name="tanah_amaun"
                class="form-control" :class="{'is-invalid': errors.first('tanah_amaun')}"
                :value="form.tanah_amaun" @input="form.tanah_amaun = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required|decimal:2'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Nama Bank</label>
            <div class="col-md-8">
              <input
                type="text" name="tanah_nama_bank"
                class="form-control" :class="{'is-invalid': errors.first('tanah_nama_bank')}"
                :value="form.tanah_nama_bank" @input="form.tanah_nama_bank = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Cawangan</label>
            <div class="col-md-8">
              <input
                type="text" name="tanah_nama_cawangan"
                class="form-control" :class="{'is-invalid': errors.first('tanah_nama_cawangan')}"
                :value="form.tanah_nama_cawangan" @input="form.tanah_nama_cawangan = $event.target.value.toUpperCase()"
                :disabled="readonly" v-validate="'required'"
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Tarikh Sahlaku</label>
            <div class="col-md-4">
              <datepicker
                :class="{'is-invalid': errors.first('tanah_tarikh_sahlaku')}"
                name="tanah_tarikh_sahlaku"
                v-model="form.tanah_tarikh_sahlaku"
                :disabled="readonly" v-validate="'required'"
              ></datepicker>
            </div>
          </div>
        </div>
      </section>
    </div>

    <div v-if="!readonly" class="card-footer">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition><button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>

    <div v-if="readonly" style="position: absolute; width: 100%; height: 100%; background: rgba(255, 255, 255, 0.6)"></div>
  </div>
</template>

<script>
export default {
  name: "BahagianE",
  props: {
    maklumat: Object,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    readonly: {
      type: Boolean,
      default: false,
    },

    kuiri: null,
  },

  data() {
    return {
      loading: false,
      form: {
        hda_no_akaun: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_no_akaun : null,
        hda_nama_bank: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_nama_bank : null,
        hda_alamat_bank1: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_alamat_bank1 : null,
        hda_alamat_bank2: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_alamat_bank2 : null,
        hda_alamat_bank3: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_alamat_bank3 : null,
        hda_poskod: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_poskod : null,
        hda_kod_negeri_id: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_kod_negeri_id : null,
        hda_kod_daerah_id: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_kod_daerah_id : null,
        hda_kod_bandar_id: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_kod_bandar_id : null,
        hda_no_telefon: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_no_telefon : null,
        hda_tarikh_dibuka: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.hda_tarikh_dibuka : null,

        kod_jenis_wp_id: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.kod_jenis_wp_id : null,
        wp_no_sijil: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.wp_no_sijil : null,
        wp_amaun: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.wp_amaun : null,
        wp_nama_bank: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.wp_nama_bank : null,
        wp_nama_cawangan: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.wp_nama_cawangan : null,
        wp_tarikh_sahlaku: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.wp_tarikh_sahlaku : null,

        tanah_no_sijil: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.tanah_no_sijil : null,
        tanah_amaun: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.tanah_amaun : null,
        tanah_nama_bank: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.tanah_nama_bank : null,
        tanah_nama_cawangan: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.tanah_nama_cawangan : null,
        tanah_tarikh_sahlaku: this.maklumat && this.maklumat.akaun_hda ? this.maklumat.akaun_hda.tanah_tarikh_sahlaku : null,
      },
    }
  },

  methods: {
    async save() {
      if (this.loading) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/bahagian-e`, this.form)
        .then((response) => {
          this.form = response.data.akaun_hda;
          this.$emit('update', JSON.parse(JSON.stringify(response.data)));
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },
    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandarRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodBandarRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    }
  }
}
</script>

<style scoped>

</style>
