<template>
  <tr>
    <td class="px-1"></td>
    <td>&nbsp;</td>
    <form-unit
      :maklumat="maklumat"
      :kod-jenis-perjanjian-id="pembangunan.kod_jenis_perjanjian_id"
      :program-kemajuan="maklumat.program_kemajuan"
      :kod-skim-projek-rs="kodSkimProjekRs"
      :form="form"
      :kod-kedudukan-lot-rs="KodKedudukanLotRs"
      @save="(form, callback) => add({form, callback})"
    ></form-unit>
  </tr>
</template>

<script>
import FormUnit from './FormUnit.vue';

export default {
  name: "AddUnitForm",
  components: {
    FormUnit,
  },

  props: {
    maklumat: Object,
    pembangunan: Object,
    programKemajuan: {
      type: Number,
      default: 0,
    },
    kodSkimProjekRs: Array,
    KodKedudukanLotRs: Array,
  },

  data() {
    return {
      loading: false,

      form: {
        no_lot_pt: null,
        no_unit: null,
        jenis_layout: null,
        luas_tanah: null,
        luas_binaan: null,
        harga_jualan: null,
        bilangan_tlk: null,
        kuota_bumi: 0,
        tuan_tanah: 0,

        kod_skim_projek_id: null,
        
        strata_menara: null,
        strata_tingkat: null,

      }
    }
  },

  methods: {
    async add({ form, callback }) {
      if (this.loading) {
        return;
      }

      const self = this;

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.$emit('add', form, callback)
    },

    tuanTanahChange() {
      this.form.kuota_bumi = 0;
    },
  }
}
</script>

<style scoped>

</style>
