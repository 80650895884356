import { render, staticRenderFns } from "./BahagianF.vue?vue&type=template&id=54bb5c27&scoped=true&"
import script from "./BahagianF.vue?vue&type=script&lang=js&"
export * from "./BahagianF.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54bb5c27",
  null
  
)

export default component.exports