<template>
  <div class="card">
    <section class="card-body form-compact">
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">1. Kod Pemaju</label>
        <div class="col-md-8">
          <input type="text" class="form-control" disabled :value="pemaju.kod_pemaju">
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">2. Nama Pemaju</label>
        <div class="col-md-8">
          <input type="text" class="form-control" disabled :value="pemaju.nama">
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label col-md-4">3. Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
        <div class="col-md-8">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai') }" name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai" @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required|max:100'" data-vv-as="Nama Pegawai" />
          <span class="invalid-feedback" v-if="errors.first('pemaju_nama_pegawai')">{{ errors.first('pemaju_nama_pegawai') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">4. No Telefon Pejabat <span class="label-required">*</span></label>
        <div class="col-md-3">
          <input type="number" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_pejabat') }" name="pemaju_no_pejabat" :value="pemaju_no_pejabat" @input="$emit('update:pemaju_no_pejabat', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:9,11'" data-vv-as="No Telefon Pejabat" />
          <span class="invalid-feedback" v-if="errors.first('pemaju_no_pejabat')">{{ errors.first('pemaju_no_pejabat') }}</span>
        </div>
        <label class="col-form-label col-md-2">5. No Telefon Bimbit <span class="label-required">*</span></label>
        <div class="col-md-3">
          <input type="number" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit') }" name="pemaju_no_bimbit" :value="pemaju_no_bimbit" @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:10,11'" data-vv-as="No Telefon Bimbit" />
          <span class="invalid-feedback" v-if="errors.first('pemaju_no_bimbit')">{{ errors.first('pemaju_no_bimbit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">6. Emel <span class="label-required">*</span></label>
        <div class="col-md-8">
          <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_email') }" name="pemaju_email" :value="pemaju_email" @input="$emit('update:pemaju_email', $event.target.value)" v-validate="'required|email'" data-vv-as="Emel"/>
          <span class="invalid-feedback" v-if="errors.first('pemaju_email')">{{ errors.first('pemaju_email') }}</span>
        </div>
      </div>

      <div class="form-group form-row">
        <label class="col-form-label col-md-4">7. Nama Juruaudit <span class="label-required">*</span></label>
        <div class="col-md-8">
          <input name="nama_juruaudit" type="text" class="form-control" :class="{'is-invalid': errors.first('nama_juruaudit')}" :value="nama_juruaudit" @input="$emit('update:nama_juruaudit', $event.target.value.toUpperCase())" v-validate="'required'" data-vv-as="Nama Juruaudit" readonly="" aria-describedby="nama_juruauditHelp">
          <small id="nama_juruauditHelp" class="form-text">Jika tidak pernah mendaftarkan maklumat juruaudit, boleh daftar terlebih dahulu <a :href="'/pemaju/perlantikan-juruaudit'">di sini</a>.</small>
          <span class="invalid-feedback" v-if="errors.first('nama_juruaudit')">{{ errors.first('nama_juruaudit') }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">8. Tarikh Kewangan Berakhir</label>
        <div class="col-md-8">
          <input type="text" class="form-control" disabled :value="tarikh_akhir_kewangan">
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-4">9. No Rujukan Surat Warta</label>
        <div class="col-md-8">
          <input name="no_rujukan_suratwarta" type="text" class="form-control"
                 :class="{'is-invalid': errors.first('no_rujukan_suratwarta')}" :value="no_rujukan_suratwarta"
                 @input="$emit('update:no_rujukan_suratwarta', $event.target.value.toUpperCase())" v-validate="'max:255'">
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import PemajuPermohonanDokumen from "../../common/PemajuPermohonanDokumen";
  import PemajuPermohonanDokumenDefault from "../../common/PemajuPermohonanDokumenDefault";

  export default {
    name: "BahagianA",
    components: {PemajuPermohonanDokumenDefault, PemajuPermohonanDokumen},
    inject: ['$validator'],

    props: {
      pemaju: Object,

      tarikh_akhir_kewangan: String,
      nama_juruaudit: String,
      no_rujukan_suratwarta: String,

      pemaju_nama_pegawai: String,
      pemaju_no_pejabat: String,
      pemaju_no_bimbit: String,
      pemaju_email: String,

      readonly: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style scoped>

</style>
