<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>
      <section class="form-compact row">
        <div class="col-lg-12">
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Kategori Pemaju <span class="label-required">*</span></label>
            <div class="col-md-4">
              <select name="kod_kategori_pemaju" id="kod_kategori_pemaju" v-model="form.kod_kategori_pemaju_id" class="form-control">
                <option value="null">Sila Pilih</option>
                <option v-for="item in kodKategoriPemajuRs" :value="item.id" :key="`kod-${item.id}`">{{ item.keterangan }}</option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Nama Pemaju</label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.nama" disabled>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;No. Pendaftaran SSM</label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.no_ssm" disabled>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;No. Pendaftaran SSM Baru</label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.no_ssm_baru" disabled>
            </div>
          </div>
          <section>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Alamat Berdaftar</label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="form.alamat_daftar1" disabled>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input type="text" class="form-control" :value="form.alamat_daftar2" disabled>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input type="text" class="form-control" :value="form.alamat_daftar3"  disabled>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-4">
                <input type="text" class="form-control" :value="form.poskod_daftar"  disabled>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="getNegeri(form.kod_negeri_daftar_id)" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Daerah <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 class="form-control" v-model="form.kod_daerah_daftar_id">
                  <option value="">Sila Pilih Daerah</option>
                  <option v-for="daerah in filteredDaerahRs(form.kod_negeri_daftar_id)" :value="daerah.id" :key="`daerah_daftar_${daerah.id}`">{{ daerah.keterangan }}</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 class="form-control" v-model="form.kod_bandar_daftar_id">
                  <option value="">Sila Pilih Mukim / Bandar / Pekan</option>
                  <option v-for="bandar in filteredBandarRs(form.kod_negeri_daftar_id)" :value="bandar.id" :key="`bandar_daftar_${bandar.id}`">{{ bandar.keterangan }}</option>
                </select2>
              </div>
            </div>
          </section>

          <section>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Alamat Perniagaan</label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="form.alamat_perniagaan1" disabled>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input type="text" class="form-control" :value="form.alamat_perniagaan2" disabled>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input type="text" class="form-control" :value="form.alamat_perniagaan3" disabled>
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-4">
                <input type="text" class="form-control" :value="form.poskod_perniagaan" disabled>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="getNegeri(form.kod_negeri_perniagaan_id)" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Daerah <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 class="form-control" v-model="form.kod_daerah_perniagaan_id">
                  <option value="">Sila Pilih Daerah</option>
                  <option v-for="daerah in filteredDaerahRs(form.kod_negeri_perniagaan_id)" :value="daerah.id" :key="`daerah_perniagaan_${daerah.id}`">{{ daerah.keterangan }}</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 class="form-control" v-model="form.kod_bandar_perniagaan_id">
                  <option value="">Sila Pilih Mukim / Bandar / Pekan</option>
                  <option v-for="bandar in filteredBandarRs(form.kod_negeri_perniagaan_id)" :value="bandar.id" :key="`bandar_perniagaan_${bandar.id}`">{{ bandar.keterangan }}</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (1)</label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="form.telefon_perniagaan1" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (2) / No Telefon Bimbit</label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="form.telefon_perniagaan2" disabled>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Faksimili</label>
              <div class="col-md-4">
                <input type="text" class="form-control" :value="form.fax_perniagaan" disabled>
              </div>
            </div>
          </section>

          <section>
            <div class="form-group row">
              <div class="offset-md-4 col-form-label col">
                <div class="form-check form-check-inline">
                  <input id="alamat_suratmenyurat_sama" v-model="form.alamat_suratmenyurat_sama" type="checkbox" class="form-check-input">
                  <label for="alamat_suratmenyurat_sama" class="form-check-label">Sama seperti alamat perniagaan.</label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Alamat Surat Menyurat <span class="label-required">*</span></label>
              <div class="col-md-8">
                <input type="text" class="form-control" :value="form.alamat_suratmenyurat1" @input="form.alamat_suratmenyurat1 = $event.target.value.toUpperCase()" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input type="text" class="form-control" :value="form.alamat_suratmenyurat2" @input="form.alamat_suratmenyurat2 = $event.target.value.toUpperCase()" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
            <div class="form-group row">
              <div class="offset-md-4 col-md-8">
                <input type="text" class="form-control" :value="form.alamat_suratmenyurat3" @input="form.alamat_suratmenyurat3 = $event.target.value.toUpperCase()" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Poskod <span class="label-required">*</span></label>

              <div class="col-md-4">
                <input type="text" class="form-control" v-model="form.poskod_suratmenyurat" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri & Daerah <span class="label-required">*</span></label>
              <div class="col-md-4">
                <select2 v-model="form.kod_negeri_suratmenyurat_id" @change="form.kod_daerah_suratmenyurat_id = null; form.kod_bandar_suratmenyurat_id = null;" class="form-control" :disabled="form.alamat_suratmenyurat_sama">
                  <option :value="null">Sila Pilih Negeri</option>
                  <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri_suratmenyurat_${negeri.id}`">{{ negeri.keterangan }}</option>
                </select2>
              </div>
              <div class="col-md-4">
                <select2 v-model="form.kod_daerah_suratmenyurat_id" class="form-control" :disabled="form.alamat_suratmenyurat_sama">
                  <option :value="null">Sila Pilih Daerah</option>
                  <option v-for="daerah in filteredDaerahRs(form.kod_negeri_suratmenyurat_id)" :value="daerah.id" :key="`daerah_suratmenyurat_${daerah.id}`">{{ daerah.keterangan }}</option>
                </select2>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (1) <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" v-model="form.telefon_suratmenyurat1" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat (2) / No Telefon Bimbit</label>
              <div class="col-md-4">
                <input type="text" class="form-control" v-model="form.telefon_suratmenyurat2" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Faksimili</label>
              <div class="col-md-4">
                <input type="text" class="form-control" v-model="form.fax_suratmenyurat" :disabled="form.alamat_suratmenyurat_sama">
              </div>
            </div>
          </section>


          <section>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
              <div class="col-md-8">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('nama_pegawai') }" name="nama_pegawai" :value="form.nama_pegawai" @input="form.nama_pegawai = $event.target.value.toUpperCase()" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No Telefon Pejabat & Bimbit <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('pegawai_no_pejabat') }" name="pegawai_no_pejabat" :value="form.pegawai_no_pejabat" @input="form.pegawai_no_pejabat = $event.target.value.toUpperCase()" placeholder="No Telefon Pejabat" />
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('pegawai_no_bimbit') }" name="pegawai_no_bimbit" :value="form.pegawai_no_bimbit" @input="form.pegawai_no_bimbit = $event.target.value.toUpperCase()" placeholder="No Telefon Bimbit" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Jawatan <span class="label-required">*</span></label>
              <div class="col-md-4">
                <input type="text" class="form-control" :class="{'is-invalid': errors.first('pegawai_jawatan') }" name="pegawai_jawatan" :value="form.pegawai_jawatan" @input="form.pegawai_jawatan = $event.target.value.toUpperCase()" />
              </div>
            </div>
          </section>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">9.&#9;Emel <span class="label-required">*</span></label>
            <div class="col-md-4">
              <input
                type="text" name="emel"
                class="form-control" :class="{'is-invalid' :errors.first('emel')}"
                v-model="form.emel"
                v-validate="'required'"
              >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">10.&#9;Alamat Web</label>
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                v-model="form.alamat_web"
              >
            </div>
          </div>
          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">11.&#9;Taraf Pemaju <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="taraf in kodTarafPemajuRs" :key="`taraf_pemaju_${taraf.id}`">
                <input
                  :id="`taraf-pemaju-${taraf.id}`"
                  type="radio" name="kod_taraf_pemaju_id"
                  class="form-check-input" :class="{'is-invalid' :errors.first('kod_taraf_pemaju_id')}"
                  v-model="form.kod_taraf_pemaju_id" :value="taraf.id"
                  v-validate="'required'"
                >
                <label class="form-check-label" :for="`taraf-pemaju-${taraf.id}`">{{  taraf.keterangan }}</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <fieldset class="form-fieldset">
              <legend style="white-space: pre-wrap;">12.&#9;Amaun Modal</legend>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">a.&#9;Modal Dibenarkan</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="form.amaun_modal_dibenar" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">b.&#9;Modal Terbitan</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="form.amaun_modal_diterbit" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">c.&#9;Modal Berbayar Tunai</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="form.amaun_modal_tunai" disabled>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">d.&#9;Modal Berbayar Bukan Tunai</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" :value="form.amaun_modal_bukantunai" disabled>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">13.&#9;Jenis Pemaju <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="jenis in kodJenisPemajuRs" :key="`jenis_pemaju_${jenis.id}`">
                <input
                  :id="`jenis-pemaju-${jenis.id}`"
                  type="radio" name="kod_jenis_pemaju_id"
                  class="form-check-input" :class="{'is-invalid' :errors.first('kod_jenis_pemaju_id')}"
                  v-model="form.kod_jenis_pemaju_id" :value="jenis.id"
                  v-validate="'required'"
                >
                <label class="form-check-label" :for="`jenis-pemaju-${jenis.id}`">{{  jenis.keterangan }}</label>
              </div>
            </div>
          </div>

          <div class="ml-4">
            <div class="form-group">
              Jika Subsidiari atau Bersekutu, sila isikan butiran syarikat Induk:
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">i.&#9;Nama Syarikat Induk</label>
              <div class="col-md-8">
                <input type="text" class="form-control" v-model="form.nama_pemaju_induk" :disabled="[2,3].indexOf(form.kod_jenis_pemaju_id) === -1">
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">ii.&#9;No Pendaftaran SSM</label>
              <div class="col-md-4">
                <input type="text" class="form-control" v-model="form.nossm_pemaju_induk" :disabled="[2,3].indexOf(form.kod_jenis_pemaju_id) === -1">
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="card-footer">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition><button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianA",
  props: {
    maklumat: Object,
    pemaju: Object,

    kodTarafPemajuRs: Array,
    kodJenisPemajuRs: Array,
    kodKategoriPemajuRs: Array,
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    kuiri: null,
  },

  data() {
    return {
      loading: false,

      form: {
        nama: this.pemaju.nama,
        no_ssm: this.pemaju.no_ssm,
        no_ssm_baru: this.pemaju.no_ssm_baru,

        alamat_daftar1: this.pemaju.alamat_daftar1,
        alamat_daftar2: this.pemaju.alamat_daftar2,
        alamat_daftar3: this.pemaju.alamat_daftar3,
        poskod_daftar: this.pemaju.poskod_daftar,
        kod_negeri_daftar_id: this.pemaju.kod_negeri_daftar_id,
        kod_daerah_daftar_id: this.pemaju.kod_daerah_daftar_id,
        kod_bandar_daftar_id: this.pemaju.kod_bandar_daftar_id,

        alamat_perniagaan1: this.pemaju.alamat_perniagaan1,
        alamat_perniagaan2: this.pemaju.alamat_perniagaan2,
        alamat_perniagaan3: this.pemaju.alamat_perniagaan3,
        poskod_perniagaan: this.pemaju.poskod_perniagaan,
        kod_negeri_perniagaan_id: this.pemaju.kod_negeri_perniagaan_id,
        kod_daerah_perniagaan_id: this.pemaju.kod_daerah_perniagaan_id,
        kod_bandar_perniagaan_id: this.pemaju.kod_bandar_perniagaan_id,
        telefon_perniagaan1: this.pemaju.telefon_perniagaan1,
        telefon_perniagaan2: this.pemaju.telefon_perniagaan2,
        fax_perniagaan: this.pemaju.fax_perniagaan,

        alamat_suratmenyurat_sama: !!this.pemaju.alamat_suratmenyurat_sama,
        alamat_suratmenyurat1: this.pemaju.alamat_suratmenyurat1,
        alamat_suratmenyurat2: this.pemaju.alamat_suratmenyurat2,
        alamat_suratmenyurat3: this.pemaju.alamat_suratmenyurat3,
        poskod_suratmenyurat: this.pemaju.poskod_suratmenyurat,
        kod_negeri_suratmenyurat_id: this.pemaju.kod_negeri_suratmenyurat_id,
        kod_daerah_suratmenyurat_id: this.pemaju.kod_daerah_suratmenyurat_id,
        kod_bandar_suratmenyurat_id: this.pemaju.kod_bandar_suratmenyurat_id,
        telefon_suratmenyurat1: this.pemaju.telefon_suratmenyurat1,
        telefon_suratmenyurat2: this.pemaju.telefon_suratmenyurat2,
        fax_suratmenyurat: this.pemaju.fax_suratmenyurat,
        emel: this.pemaju.emel,
        alamat_web: this.pemaju.alamat_web,

        kod_taraf_pemaju_id: this.pemaju.kod_taraf_pemaju_id,
        amaun_modal_dibenar: this.pemaju.amaun_modal_dibenar,
        amaun_modal_diterbit: this.pemaju.amaun_modal_diterbit,
        amaun_modal_tunai: this.pemaju.amaun_modal_tunai,
        amaun_modal_bukantunai: this.pemaju.amaun_modal_bukantunai,
        kod_jenis_pemaju_id: this.pemaju.kod_jenis_pemaju_id,
        nama_pemaju_induk: this.pemaju.nama_pemaju_induk,
        nossm_pemaju_induk: this.pemaju.nossm_pemaju_induk,
        kod_kategori_pemaju_id: this.pemaju.kod_kategori_pemaju_id,
      },
    };
  },

  methods: {
    getNegeri(kod_negeri_id) {
      const negeri = this.kodNegeriRs.find(item => item.id == kod_negeri_id);

      return negeri ? negeri.keterangan : null
    },
    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return this.kodDaerahRs;
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandarRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return this.kodBandarRs;
      }

      return this.kodBandarRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },

    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/bahagian-a`, this.form)
      .then((response) => {
        this.form = JSON.parse(JSON.stringify(response.data.pemaju));
        this.$emit('update', response.data);
        this.$success();
      })
      .catch((error) => {
      })
      .then(() => {
        this.loading = false;
      })
    }
  },

  watch: {
    'form.alamat_suratmenyurat_sama': function(value) {
      if (value) {
        this.form.alamat_suratmenyurat1 = this.form.alamat_perniagaan1;
        this.form.alamat_suratmenyurat2 = this.form.alamat_perniagaan2;
        this.form.alamat_suratmenyurat3 = this.form.alamat_perniagaan3;
        this.form.poskod_suratmenyurat = this.form.poskod_perniagaan;
        this.form.kod_negeri_suratmenyurat_id = this.form.kod_negeri_perniagaan_id;
        this.form.kod_daerah_suratmenyurat_id = this.form.kod_daerah_perniagaan_id;
        this.form.kod_bandar_suratmenyurat_id = this.form.kod_bandar_perniagaan_id;
        this.form.telefon_suratmenyurat1 = this.form.telefon_perniagaan1;
        this.form.telefon_suratmenyurat2 = this.form.telefon_perniagaan2;
        this.form.fax_suratmenyurat = this.form.fax_perniagaan;
      } else {
        this.form.alamat_suratmenyurat1 = null;
        this.form.alamat_suratmenyurat2 = null;
        this.form.alamat_suratmenyurat3 = null;
        this.form.poskod_suratmenyurat = null;
        this.form.kod_negeri_suratmenyurat_id = null;
        this.form.kod_daerah_suratmenyurat_id = null;
        this.form.kod_bandar_suratmenyurat_id = null;
        this.form.telefon_suratmenyurat1 = null;
        this.form.telefon_suratmenyurat2 = null;
        this.form.fax_suratmenyurat = null;
      }
    }
  }
}
</script>

<style scoped>

</style>
