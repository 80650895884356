<template>
  <div class="card">
    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li class="active">
            <a href="#bahagian-a">A - MAKLUMAT PEMBAHARUAN</a>
          </li>
          <li class="undone">
            <a href="#bahagian-b">B - PENGAKUAN PEMAJU PERUMAHAN</a>
          </li>
        </ul>
      </nav>
      <section>
        <div class="alert border-danger" v-if="latest_kuiri">
          <strong class="text-danger">KUIRI PERMOHONAN:</strong>
          <div>{{ latest_kuiri.catatan }}</div>
        </div>
        <div class="tab-content">
          <div
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN A: MAKLUMAT PEMBAHARUAN</h4>
                <bahagian-a
                  :kod-jenis-iklan-rs="kodJenisIklanRs"
                  :lesen="lesen"
                  :permit="permit"
                  :pembangunan_rs="pembangunan_rs"

                  :pemaju_nama_pegawai.sync="maklumat.pemaju_nama_pegawai"
                  :pemaju_no_pejabat.sync="maklumat.pemaju_no_pejabat"
                  :pemaju_no_bimbit.sync="maklumat.pemaju_no_bimbit"
                  :pembaharuan_lesen.sync="maklumat.pembaharuan_lesen"
                  :pembaharuan_permit.sync="maklumat.pembaharuan_permit"
                  :iklan_rs.sync="maklumat.iklan_rs"
                  :iklan_lain_rs.sync="maklumat.iklan_lain_rs"
                ></bahagian-a>
              </div>
            </div>
          </div>

          <div
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN B: PENGAKUAN PEMAJU PERUMAHAN</h4>
                <bahagian-c
                  :pengarah="pengarah"
                  @submit="submit"
                ></bahagian-c>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import BahagianC from "./BahagianC";

export default {
  name: "Borang",
  components: {
    BahagianA,
    BahagianC,
  },

  props: {
    pengarah: Object,
    pemaju: Object,
    pembangunan_rs: Array,
    lesen: Object,
    permit: Object,

    kodJenisIklanRs: Array,

    default_maklumat: Object,
    default_permohonan: Object,
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,
      errorMessages: {},
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),

      // subsyarikat_rs: JSON.parse(JSON.stringify(this.default_subsyarikat_rs)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    validate() {
      if (this.$validator.errors.first('pemaju_nama_pegawai') ||
        this.$validator.errors.first('pemaju_no_pejabat') ||
        this.$validator.errors.first('pemaju_no_bimbit')
      ) {
        window.location.hash = '#bahagian-a';
      }
    },

    async submit(ev) {
      ev.preventDefault();

      const passes = await this.$validator.validateAll();

      if (!passes) {
        this.validate();

        return;
      }

      swalconfirm()
        .then(result => {
          if (result.value) {
            ev.target.form.submit();
          }
        });
    },
  },

  computed: {
    latest_kuiri() {
      if (!this.maklumat.kuiri_rs || !this.maklumat.kuiri_rs.length) {
        return null;
      }

      return this.maklumat.kuiri_rs.find(kuiri => this.maklumat.kuiri_no == kuiri.kuiri_no);
    }
  }
}
</script>

<style scoped>

</style>
