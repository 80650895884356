var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.kuiri)?_c('div',{staticClass:"alert alert-warning"},[_c('strong',[_vm._v("Catatan Kuiri:")]),_vm._v(" "),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.kuiri.catatan))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("doc_pengesahan_peguam")},attrs:{"type":"file","accept":"application/pdf","name":"doc_pengesahan_peguam","doc":_vm.maklumat.doc_pengesahan_peguam,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('doc_pengesahan_peguam', file, callback);
},"remove":function($event){return _vm.removeDoc('doc_pengesahan_peguam')}}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("doc_iringan_pemaju")},attrs:{"type":"file","accept":"application/pdf","name":"doc_iringan_pemaju","doc":_vm.maklumat.doc_iringan_pemaju,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('doc_iringan_pemaju', file, callback);
},"remove":function($event){return _vm.removeDoc('doc_iringan_pemaju')}}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_vm._m(2),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("doc_pelan_susunatur")},attrs:{"type":"file","accept":"application/pdf","name":"doc_pelan_susunatur","doc":_vm.maklumat.doc_pelan_susunatur,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('doc_pelan_susunatur', file, callback);
},"remove":function($event){return _vm.removeDoc('doc_pelan_susunatur')}}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("4.\tLain - Lain Dokumen "),(_vm.maklumat.kod_skim_projek_id)?_c('span',{staticClass:"label-required"},[_vm._v("*")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("doc_other")},attrs:{"type":"file","accept":"application/pdf","name":"doc_other","doc":_vm.maklumat.doc_other,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('doc_other', file, callback);
},"remove":function($event){return _vm.removeDoc('doc_other')}}}),_vm._v(" "),_c('div',{staticClass:"form-text text-grey"},[_vm._v("Contoh: Dokumen RMM")])],1)])]),_vm._v(" "),(!_vm.readonly)?_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.hErrorMessage)?_c('span',{staticClass:"text-danger mr-2"},[_vm._v(_vm._s(_vm.hErrorMessage))]):_vm._e()]),_vm._v(" "),_c('transition',{attrs:{"name":"fade"}},[(_vm.succesfulMessage)?_c('span',{staticClass:"text-success mr-2"},[_vm._v(_vm._s(_vm.succesfulMessage))]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v("Simpan")])],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("1.\tSurat Pengesahan Peguam (Unit terlibat pembatalan) "),_c('span',{staticClass:"label-required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("2.\tDokumen Surat Iringan "),_c('br'),_c('small',[_vm._v("\t(Perincian Tujuan Permohonan)")]),_vm._v(" "),_c('span',{staticClass:"label-required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("3.\tDokumen Pelan Susun Atur "),_c('span',{staticClass:"label-required"},[_vm._v("*")])])}]

export { render, staticRenderFns }