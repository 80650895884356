<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>
    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLiveLabel"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">
              No Unit / Lot {{ unit.no_lot_pt }}
            </h5>
            <button
              type="button"
              class="close"
              @click="close"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <section>
              <h4 class="card-title">MAKLUMAT UNIT</h4>
              <p class="mb-3">
                Kemaskini maklumat kedudukan lot, bilangan bilik tidur dan
                bilangan bilik air.
              </p>
              <div class="row">
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">No Lot PT</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">{{ unit.no_lot_pt }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">No Unit</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">{{ unit.no_unit }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      >Pembeli Pertama</label
                    >
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        {{ isFirstBuyer ? "Ya" : "Tidak" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Tuan Tanah</label>
                    <div class="col-sm-6">
                      <p
                        class="form-control-plaintext"
                        :class="{
                          'text-success': unit.tuan_tanah,
                          'text-danger': !unit.tuan_tanah,
                        }"
                      >
                        {{ unit.tuan_tanah ? "&#x2714;" : "&#x2718;" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Kuota Bumi</label>
                    <div class="col-sm-6">
                      <select
                        class="form-control"
                        v-model="unit.kuota_bumi"
                        :disabled="disabled == 1"
                        name="kuota_bumi"
                        v-validate="'required'"
                      >
                        <option :value="null">Sila Pilih</option>
                        <option :value="1">Ya</option>
                        <option :value="0">Tidak</option>
                      </select>
                      <span
                        class="text-danger"
                        v-if="errors.first('kuota_bumi')"
                      >
                        Sila pilih status kuota bumi.
                      </span>
                      <!-- <p class="form-control-plaintext" :class="{'text-success': unit.kuota_bumi, 'text-danger': !unit.kuota_bumi}">{{ unit.kuota_bumi ? '&#x2714;' : '&#x2718;' }}</p> -->
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Status Jualan</label>
                    <div class="col-sm-6">
                      <p
                        class="form-control-plaintext"
                        :class="{
                          'text-success': unit.status_jualan,
                          'text-danger': !unit.status_jualan,
                        }"
                      >
                        {{ unit.status_jualan ? "&#x2714;" : "&#x2718;" }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      >Bilangan Bilik Tidur</label
                    >
                    <div class="col-sm-6">
                      <input
                        class="form-control"
                        type="number"
                        name="bil_bilik"
                        placeholder="0"
                        v-model="unit.bil_bilik"
                        autofocus=""
                        :disabled="disabled == 1"
                        v-validate="'required'"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Harga Jualan</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        {{
                          unit.harga_jualan
                            | currency("RM", ",", 2, ".", "front", false)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      >Bilangan Bilik Air</label
                    >
                    <div class="col-sm-6">
                      <input
                        class="form-control"
                        type="number"
                        placeholder="0"
                        name="bil_tandas"
                        v-model="unit.bil_tandas"
                        :disabled="disabled == 1"
                        v-validate="'required'"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Rebat Pemaju</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        {{
                          unit.rebat_pemaju
                            | currency("RM", ",", 2, ".", "front", false)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <!-- <div class="form-group row" v-if="kodJenisRumahId !== 4"> -->
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Kedudukan Lot</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        <!-- {{ unit.kedudukan_lot ? unit.kedudukan_lot.keterangan : '-' }} -->
                        <!-- <select class="form-control" :class="{'is-invalid': errors.first('kod_kedudukanlot')}" v-validate="'required'" name="kod_kedudukanlot" v-model="unit.kod_kedudukan_lot_id" :disabled="disabled == 1"> -->
                        <select
                          class="form-control"
                          :class="{
                            'is-invalid': errors.has('kod_kedudukanlot'),
                          }"
                          v-validate="validationRule"
                          name="kod_kedudukanlot"
                          v-model="unit.kod_kedudukan_lot_id"
                          :disabled="disabled == 1"
                        >
                          <option :value="null">Sila Pilih</option>
                          <option
                            v-for="kedudukanLot in kodKedudukanLot"
                            :value="kedudukanLot.id"
                          >
                            {{ kedudukanLot.keterangan }}
                          </option>
                        </select>
                      </p>

                      <!-- <span class="text-danger">{{
                        errors.first("kod_kedudukanlot") ||
                        "Sila pilih kedudukan lot."
                      }}</span> -->
                      <span
                        class="text-danger"
                        v-if="errors.first('kod_kedudukanlot')"
                      >
                        Sila pilih kedudukan lot.
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      >Diskaun Lot Bumiputera</label
                    >
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        {{
                          unit.harga_diskaun_kuotabumi
                            | currency("RM", ",", 2, ".", "front", false)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      >Jenis Susunatur <br />
                      <small style="font-size: 10px"
                        >(Layout Type)</small
                      ></label
                    >
                    <div class="col-sm-6">
                      <input
                        class="form-control"
                        type="text"
                        name="jenis_layout"
                        v-model="unit.jenis_layout"
                        :disabled="disabled == 1"
                        maxlength="255"
                        v-validate="'required'"
                      />
                      <span
                        class="text-danger"
                        v-if="errors.first('jenis_layout')"
                      >
                        Sila pilih jenis Susunatur </span
                      ><br />
                      <small style="font-size: 10px">
                        Jenis Mengikut Pelan Bangunan <br />
                        Cth: A (22' x 70') <br />
                        Jika Tiada Jenis Susunatur, Isikan <br />
                        Cth: NIL (20' x 60')
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      >Inisiatif Kerajaan</label
                    >
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        {{
                          unit.inisiatif_kerajaan
                            | currency("RM", ",", 2, ".", "front", false)
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <!-- <label class="col-sm-6 col-form-label">Inisiatif Kerajaan</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">{{ unit.inisiatif_kerajaan | currency('RM', ',', 2, '.', 'front', false) }}</p>
                    </div> -->
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label"
                      ><strong>Harga SPJB</strong></label
                    >
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">
                        <strong>{{
                          unit.harga_spjb
                            | currency("RM", ",", 2, ".", "front", false)
                        }}</strong>
                      </p>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-6">
                  <div class="form-group row">
                    <label class="col-sm-6 col-form-label">Harga Diskaun</label>
                    <div class="col-sm-6">
                      <p class="form-control-plaintext">{{ ((unit.harga_jualan * 100 - unit.harga_spjb * 100) / 100) | currency('RM', ',', 2, '.', 'front', false) }}</p>
                    </div>
                  </div>
                </div> -->
                <div class="col-12 mt-3">
                  <div class="form-group row">
                    <div class="col-sm-9 col-md-9">&nbsp;</div>
                    <div class="col-sm-3 col-md-3 text-right">
                      <button
                        @click="save"
                        :disabled="saving"
                        class="btn btn-primary px-3"
                        v-if="disabled == 0"
                      >
                        Simpan
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  name: "KemaskiniModal",

  mounted() {
    vmodal.register(this.modalId, { open: this.onOpen, close: this.onClose });
  },

  props: {
    kodKedudukanLot: Array,
    kodJenisRumahId: Number,
  },

  data() {
    return {
      modalId: "kemaskiniNoLotUnit",
      saving: false,
      unit: {},
      unit_pertama: null,
      response: () => {},
      disabled: 0,
    };
  },

  methods: {
    onOpen(data, callback) {
      this.unit = JSON.parse(JSON.stringify(data.unit));
      // console.log(data.unit_pertama);
      this.unit_pertama = data.unit_pertama;
      this.response = callback;
      // if(this.unit.status_jualan == 1){
      //   this.disabled = 1;
      // }else{
      //   this.disabled = 0;
      // }
      // alert(this.unit.tarikh_spjb+' '+this.unit.harga_spjb);
    },

    onClose() {},

    // save() {
    //   console.log(this.unit);
    //   swal.fire({
    //     title: 'Adakah anda pasti?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     confirmButtonColor: '#0069d9',
    //     cancelButtonColor: '#d90014',
    //     confirmButtonText: 'Teruskan',
    //     cancelButtonText: 'Batal'
    //   }).then((result) => {
    //     if (result.value) {
    //       axios.post(`/pemaju/kuota-bumi/unit/${this.unit.id}`, this.unit)
    //       .then(({data}) => {
    //         this.unit = data.unit;
    //         this.response(JSON.parse(JSON.stringify(data.unit)));
    //         swal.fire(
    //           'Berjaya!',
    //           'Maklumat unit berjaya dikemaskini.',
    //           'success'
    //         )
    //       })
    //       .catch(() => {
    //         swal.fire(
    //           'Tidak Berjaya!',
    //           'Maklumat unit tidak berjaya dikemaskini.',
    //           'warning'
    //           )
    //       })
    //       .then(() => {
    //         this.saving = false;
    //       });

    //     }
    // if (result.value) {
    //   this.saving = true;
    //   axios.post(`/pemaju/kuota-bumi/unit/${this.unit.id}`, this.unit)
    //   .then(({data}) => {
    //     this.unit = data.unit;
    //     this.response(JSON.parse(JSON.stringify(this.unit)));
    //   })
    //   .catch(() => {})
    //   .then(() => {
    //     this.saving = false;
    //     this.updating = false;

    //     swal.fire(
    //       'Berjaya!',
    //       'Maklumat unit berjaya dikemaskini.',
    //       'success'
    //     )
    //   });
    // }
    //   });
    // },

    async save() {
      if (this.kodJenisRumahId == 4) {
        this.$validator.fields.find({
          name: "kod_kedudukanlot",
        }).required = false;
      }

      const valid = await this.$validator.validateAll();

      if (this.kodJenisRumahId == 4) {
        this.$validator.fields.find({
          name: "kod_kedudukanlot",
        }).required = false;
      }

      if (!valid) {
        swal.fire(
          "Tidak Berjaya!",
          "Sila isi semua maklumat yang diperlukan.",
          "warning"
        );
        return;
      }

      swal
        .fire({
          title: "Adakah anda pasti?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#0069d9",
          cancelButtonColor: "#d90014",
          confirmButtonText: "Teruskan",
          cancelButtonText: "Batal",
        })
        .then((result) => {
          if (result.value) {
            axios
              .post(`/pemaju/kuota-bumi/unit/${this.unit.id}`, this.unit)
              .then(({ data }) => {
                this.unit = data.unit;
                this.response(JSON.parse(JSON.stringify(data.unit)));
                swal.fire(
                  "Berjaya!",
                  "Maklumat unit berjaya dikemaskini.",
                  "success"
                );
              })
              .catch(() => {
                swal.fire(
                  "Tidak Berjaya!",
                  "Maklumat unit tidak berjaya dikemaskini.",
                  "warning"
                );
              })
              .then(() => {
                this.saving = false;
              });
          }
        });
    },

    close() {
      vmodal.close(this.modalId);
    },
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1;
    },
    isFirstBuyer() {
      return this.unit_pertama && this.unit.id == this.unit_pertama.id;
    },
    validationRule() {
      return this.kodJenisRumahId !== 4 ? "required" : "";
    },
  },
};
</script>
