<template>
  <div class="fileupload" :class="{'has-file': currentFile}">
    <div v-if="currentFile" class="d-flex file-container align-items-center">
      <div class="text-truncate">
        {{ currentFile.name }}
      </div>
      <button class="text-dark ml-2" @click.prevent="remove" type="button" tabindex="-1" title="Hapus"><i class="fas fa-window-close"></i></button>
    </div>
    <div v-else>
      <div class="file-container text-truncate cursor-pointer" @click.prevent="$refs.input.click()">
        <div class="d-flex justify-content-between">
          <div>
            <i class="upload-icon fas fa-upload mr-2" /> Muat Naik <span v-if="acceptType === 'application/pdf'">PDF</span>
          </div>

          <div>
            <span class="text-info fas fa-exclamation-circle" title="Saiz Maksimum: 5MB" />
          </div>
        </div>
      </div>
    </div>
    <input
      v-bind="$attrs"
      type="file"
      :name="name"
      :accept="acceptType"
      class="input-file"
      ref="input"
      @change="filesChange($event.target.name, $event.target.files)"
    >
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  $_veeValidate: {
    value () {
      return this.currentFile;
    },
  },

  props: {
    value: Object|File,
    name: String,

    acceptType: {
      type: String,
      default: 'application/pdf',
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentFile: null,
    };
  },

  methods: {
    filesChange(name, files) {
      this.currentFile = files[0];
      this.$emit('input', this.currentFile);
    },
    remove() {
      this.$emit('input', null);
      this.currentFile = '';
      this.$refs.input.value = '';
    }
  },

  watch: {
    value: function() {
      if (!this.value) {
        this.currentFile = '';
        this.$refs.input.value = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.fileupload {
  position: relative;
  width: 100%;
  padding: 0 !important;

  &.has-file {
    background: #0077bf;
    color: white !important;
  }

  &.is-invalid {
    color: red;

    .upload-icon {
      display: none;
    }
  }

  &.form-control {
    .file-container {
      padding: 0.56rem 0.75rem;
    }
  }
}
.fa-window-close {
  color: #f44747;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 0;
  height: 0;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
}
button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: sans-serif;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
}
</style>
