<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">MAKLUMAT PENGARAH</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row m-2 border p-3">
              <div class="col-md-6">
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Nama Pengarah</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('nama') }" name="nama" :value="form.nama" :readonly="readonly" v-validate="'required'" @input="changeText('nama', $event.target.value)">
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Alamat</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control mb-1" :class="{ 'is-invalid': errors.first('alamat_bod1') }" name="alamat_bod1" :value="form.alamat_bod1" :disabled="readonly" v-validate="'required'" @input="changeText('alamat_bod1', $event.target.value)">
                    <input type="text" class="form-control mb-1" :value="form.alamat_bod2" :disabled="readonly" @input="changeText('alamat_bod2', $event.target.value)">
                    <input type="text" class="form-control" :value="form.alamat_bod3" :disabled="readonly" @input="changeText('alamat_bod3', $event.target.value)">
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Poskod</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('poskod_bod') }" name="poskod_bod" :value="form.poskod_bod" :disabled="readonly" v-validate="'required'" @input="changeText('poskod_bod', $event.target.value)">
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Negeri</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <input v-if="readonly" type="text" class="form-control" :value="form.negeri ? form.negeri.keterangan : ''" disabled>
                    <select v-else name="negeri_bod" class="form-control" :class="{ 'is-invalid': errors.first('negeri_bod') }" v-model="form.negeri_bod" v-validate="'required'">
                      <option :value="null">Sila Pilih Negeri</option>
                      <option v-for="negeri in kodNegeriRs" :value="negeri.id">{{ negeri.keterangan }}</option>
                    </select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Daerah</b></label>
                  <div class="col-sm-7">
                    <input type="text" v-if="readonly" class="form-control" :value="form.daerah_bod" disabled>
                    <input type="text" v-else class="form-control" :class="{ 'is-invalid': errors.first('daerah_bod') }" :value="form.daerah_bod" @input="changeText('daerah_bod', $event.target.value)">
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Bandar</b></label>
                  <div class="col-sm-7">
                    <input type="text" v-if="readonly" class="form-control" :value="form.bandar_bod" disabled>
                    <input type="text" v-else class="form-control" :class="{ 'is-invalid': errors.first('bandar_bod') }" :value="form.bandar_bod" @input="changeText('bandar_bod', $event.target.value)">
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Jenis Kad Pengenalan</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <select class="form-control" :class="{ 'is-invalid' : errors.first('jenis_kp') }" name="jenis_kp" id="jenis_kp" v-model="form.jenis_kp" :disabled="readonly" >
                      <option :value="null">No Kad Pengenalan</option>
                      <option value="PASSPORT">Passpot</option>
                      <option value="POLICE">Polis</option>
                      <option value="ARMY">Tentera</option>
                    </select>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label" v-if="!form.jenis_kp"><b>No Kad Pengenalan</b> <span class="label-required">*</span></label>
                  <label class="col-sm-4 col-form-label" v-else-if="form.jenis_kp === 'PASSPORT'"><b>No Passpot</b> <span class="label-required">*</span></label>
                  <label class="col-sm-4 col-form-label" v-else-if="form.jenis_kp === 'POLICE'"><b>No Polis</b> <span class="label-required">*</span></label>
                  <label class="col-sm-4 col-form-label" v-else-if="form.jenis_kp === 'ARMY'"><b>No Tentera</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <input v-if="!form.jenis_kp" type="text" class="form-control" name="no_kp" :class="{ 'is-invalid': errors.first('no_kp') }" :value="form.no_kp" :disabled="readonly" v-validate="'required|digits:12'" @input="changeText('no_kp', $event.target.value)">
                    <input v-else type="text" class="form-control" name="no_kp" :class="{ 'is-invalid': errors.first('no_kp') }" :value="form.no_kp" :disabled="readonly" v-validate="'required'" @input="changeText('no_kp', $event.target.value)">
                    <small class="form-text text-info" v-if="!form.jenis_kp">Sila masukkan dengan format <b>12 digit</b> tanpa <b>-</b>.</small>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Kerakyatan</b></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" name="kerakyatan" :class="{ 'is-invalid': errors.first('kerakyatan') }" :value="form.kerakyatan" :disabled="readonly" @input="changeText('kerakyatan', $event.target.value)">
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Jawatan</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" name="jawatan" :class="{ 'is-invalid': errors.first('jawatan') }" :value="form.jawatan" :disabled="readonly" v-validate="'required'" @input="changeText('jawatan', $event.target.value)">
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Tarikh Lantikan Jawatan</b> <span class="label-required">*</span></label>
                  <div class="col-sm-7">
                    <datepicker v-model="form.tarikh_lantik" name="tarikh_lantik" :class="{ 'is-invalid': errors.first('tarikh_lantik') }" :disabled="readonly" v-validate="'required'"></datepicker>
                  </div>
                </div>
                <div role="group" class="form-group form-row">
                  <label class="col-sm-4 col-form-label"><b>Tarikh Letak Jawatan</b></label>
                  <div class="col-sm-7">
                    <datepicker v-model="form.tarikh_berhenti" name="tarikh_berhenti" :class="{ 'is-invalid': errors.first('tarikh_berhenti') }" :disabled="readonly || !form.tarikh_lantik"></datepicker>
                  </div>
                </div>
                <div role="group" class="form-group form-row" v-if="readonly">
                  <label class="col-sm-4 col-form-label"><b>Tarikh Senarai Hitam</b></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" :value="form.tarikh_senaraihitam" readonly>
                  </div>
                </div>
                <div role="group" class="form-group form-row" v-if="readonly">
                  <label class="col-sm-4 col-form-label"><b>Tarikh Nyah Senarai Hitam</b></label>
                  <div class="col-sm-7">
                    <input type="text" class="form-control" :value="form.tarikh_nyahsenaraihitam" readonly>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!readonly" type="button" class="btn btn-primary" @click="save" :disabled="readonly">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "PengarahModal",
  mounted() {
    vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
  },

  props: {
    readonly: {
      type: Boolean,
      default: true,
    },

    kodNegeriRs: {
      type: Array,
      default: () => ([])
    },

  },

  data() {
    return {
      modalId: 'pengarah',
      loading: false,
      saving: false,
      form: {},

      callback: null,
    }
  },

  methods: {
    onOpen({
           pengarah,
      }, callback) {
      this.form = {
        ...pengarah,
      };

      this.callback = callback || function() {};
    },

    changeText(key, value) {
      const form = JSON.parse(JSON.stringify(this.form));
      form[key] = value.toUpperCase();
      this.form = form
    },

    async save() {
      if (this.readonly) return;

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.callback(this.form, this.setLoading, this.close)
    },

    onClose() {
    },

    setLoading(value) {
      this.loading = value;
    },

    close() {
      vmodal.close(this.modalId);
    },
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1
    },
  }
}
</script>

<style scoped>

</style>
