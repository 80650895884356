<template>
  <div class="card">
    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li class="active">
            <a href="#bahagian-a">A - BUTIRAN PEMAJU</a>
          </li>
          <li class="active">
            <a href="#bahagian-b">B - BUTIRAN PEMAJUAN</a>
          </li>
          <li class="active">
            <a href="#bahagian-c">C - PENJUALAN UNIT RUMAH DAN JUSTIFIKASI</a>
          </li>
          <li :class="{'disabled': !maklumat.memiliki_jualan }">
            <a href="#bahagian-d">D - DOKUMEN SOKONGAN SEKIRANYA ADA PENJUALAN</a>
          </li>
          <li class="active">
            <a href="#bahagian-e">E - PERAKUAN PEMAJU PERUMAHAN</a>
          </li>
        </ul>
      </nav>
      <section>
        <div class="alert border-danger" v-if="latest_kuiri">
          <strong class="text-danger">KUIRI PERMOHONAN:</strong>
          <div>{{ latest_kuiri.catatan }}</div>
        </div>
        <div class="tab-content">
          <div
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN A: BUTIRAN PEMAJU</h4>
                <bahagian-a
                  :pemaju="pemaju"

                  :pemaju_nama_pegawai.sync="maklumat.pemaju_nama_pegawai"
                  :pemaju_no_pejabat.sync="maklumat.pemaju_no_pejabat"
                  :pemaju_no_bimbit.sync="maklumat.pemaju_no_bimbit"
                ></bahagian-a>
              </div>
              <div class="card-footer" v-if="!readonly">
                <div class="text-right">
                  <button class="btn btn-primary" @click.prevent="save" :disabled="saving">SIMPAN</button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="isCurrentTab('#bahagian-b')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN B: BUTIRAN PEMAJUAN</h4>
                <bahagian-b
                  :default_pembangunan_rs="maklumat.pemajuan.pembangunan_rs"
                ></bahagian-b>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN C: PENJUALAN UNIT RUMAH DAN JUSTIFIKASI</h4>
                <bahagian-c
                  :memiliki_jualan="maklumat.memiliki_jualan"

                  :justifikasi_pembatalan.sync="maklumat.justifikasi_pembatalan"
                ></bahagian-c>
              </div>
            </div>
          </div>
          <div
            v-if="maklumat.memiliki_jualan"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-d')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN D: DOKUMEN SOKONGAN SEKIRANYA ADA PENJUALAN</h4>
                <maklumat-dokumen-sokongan
                  :default_docs="maklumat.dokumen_rs"
                ></maklumat-dokumen-sokongan>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-e')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-e')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN E: PERAKUAN PEMAJU PERUMAHAN</h4>
                <bahagian-e
                  :pengarah="pengarah"

                  @submit="submit"
                ></bahagian-e>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import BahagianB from "./BahagianB";
import BahagianC from "./BahagianC";
import BahagianE from "./BahagianE";
import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";
import ErrorBar from "../../common/ErrorBar";

export default {
  name: "Borang",
  components: {
    ErrorBar,
    MaklumatDokumenSokongan,
    BahagianA,
    BahagianB,
    BahagianC,
    BahagianE,
  },

  props: {
    pengarah: Object,
    pemaju: Object,

    default_maklumat: Object,
    default_permohonan: Object,

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,

      saving: false,

      errorMessages: {},
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    validate() {
      if (this.$validator.errors.first('pemaju_nama_pegawai') ||
        this.$validator.errors.first('pemaju_no_pejabat') ||
        this.$validator.errors.first('pemaju_no_bimbit')
      ) {
        window.location.hash = '#bahagian-a';
      } else if (this.$validator.errors.first('justifikasi_pembatalan')) {
        window.location.hash = '#bahagian-c';
      }
    },

    save() {

    },

    async submit(ev) {
      ev.preventDefault();

      const passes = await this.$validator.validateAll();

      if (!passes) {
        this.validate();

        return;
      }

      swalconfirm()
        .then(result => {
          if (result.value) {
            ev.target.form.submit();
          }
        });
    },
  },

  computed: {
    latest_kuiri() {
      if (!this.maklumat.kuiri_rs || !this.maklumat.kuiri_rs.length) {
        return null;
      }

      return this.maklumat.kuiri_rs.find(kuiri => this.maklumat.kuiri_no == kuiri.kuiri_no);
    }
  }
}
</script>

<style scoped>

</style>
