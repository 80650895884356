<template>
  <section>
    <section class="card">
      <table class="table table-card mb-0">
        <thead>
        <tr>
          <th v-if="hasCheckbox"></th>
          <th>#</th>
          <th>No. Hakmilik</th>
          <th>No. Lot / PTD / PT</th>
          <th>Keluasan Lot</th>
          <th>Kategori Kegunaan Tanah</th>
          <th>Syarat Nyata</th>
          <th>Status Tanah</th>
          <th class="text-center">Tindakan</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td v-if="hasCheckbox"><input type="checkbox" @click.prevent.stop="$emit('select')" :checked.prop="hasCheckbox && selected" :disabled="checkboxReadonly"></td>
          <td width="5%">{{ no }}</td>
          <td style="word-break: break-word;">{{ butiran.no_geran }}</td>
          <td style="word-break: break-word;">{{ butiran.no_lot_pemajuan }}</td>
          <td>{{ butiran.luas_tanah }}</td>
          <td>{{ butiran.kategori_tanah ? butiran.kategori_tanah.keterangan : 'TIADA MAKLUMAT' }}</td>
          <td>{{ butiran.syarat_nyata ? butiran.syarat_nyata.keterangan : 'TIADA MAKLUMAT' }}</td>
          <td>{{ butiran.status_tanah ? butiran.status_tanah.keterangan : 'TIADA MAKLUMAT' }}</td>
          <td class="text-center" nowrap>
            <button class="btn btn-sm btn-link btn-icon" @click.prevent="editButiran"><i class="fa fa-edit"></i></button>
            <button class="btn btn-sm btn-link btn-icon" @click.prevent="$emit('destroyButiran')" v-if="!readonly"><i class="fa fa-trash text-danger"></i></button>
          </td>
        </tr>
        </tbody>
      </table>
    </section>
    <div class="ml-4 p-4" style="border-left: 4px solid #dee2e6">
      <div class="d-flex align-items-center mb-2">
        <h5 class="mb-0">TUAN TANAH</h5>
        <div class="ml-3">
          <button v-if="!readonly" class="btn btn-sm btn-success" type="button" @click.prevent="$emit('addTuanTanah')"><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <section class="card">
        <table class="table table-card mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Jenis Ketuanpunyaan</th>
              <th>Nama Pemilik</th>
              <th>No. Kad Pengenalan / SSM</th>
              <th>Tindakan</th>
            </tr>
          </thead>
          <tbody>
            <!-- Check if there are tuan_tanah_rs data -->
            <template v-if="butiran.tuan_tanah_rs.length > 0">
              <!-- Loop through the data and display each row with index -->
              <tr v-for="(tuan_tanah, index) in butiran.tuan_tanah_rs" :key="`tuan-tanah-${tuan_tanah.id}`">
                <td>{{ index + 1 }}</td> <!-- Index column to count the rows (adding 1 to the index to start from 1) -->
                <td>{{ tuan_tanah.jenis_tuan_tanah.keterangan }}</td>
                <td>{{ tuan_tanah.nama }}</td>
                <td>{{ tuan_tanah.no_kp }}</td>
                <td class="text-center" nowrap>
                  <button class="btn btn-sm btn-link btn-icon" @click.prevent="editTuanTanah(tuan_tanah)"><i class="fa fa-edit"></i></button>
                  <button class="btn btn-sm btn-link btn-icon" @click.prevent="$emit('destroyTuanTanah', tuan_tanah)" v-if="!readonly"><i class="fa fa-trash text-danger"></i></button>
                </td>
              </tr>
            </template>
            <!-- Check if there are no tuan_tanah_rs data and readonly is false -->
            <tr v-else-if="!readonly">
              <td colspan="5">
                <div class="alert alert-warning mb-0">Sila tambah tuan tanah.</div>
              </td>
            </tr>
            <!-- If both previous conditions are not met, display the "Tiada maklumat tuan tanah" message -->
            <tr v-else>
              <td colspan="5">
                <div class="alert alert-warning mb-0">Tiada maklumat tuan tanah.</div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </section>
</template>

<script>


export default {
  name: "ButiranTanah",

  props: {
    tanah: Object,
    butiran: Object,
    no: Number,
    maklumat: Object,

    readonly: {
      type: Boolean,
      default: false,
    },

    hasCheckbox: {
      type: Boolean,
      default: false,
    },

    selected: {
      type: Boolean,
      default: false,
    },

    checkboxReadonly: {
      type: Boolean,
      default: false,
    },

    // no: {
    //   type: Number,
    //   default: 0,
    // },
  },

  methods: {
    editButiran() {
      if (!this.readonly) {
        return this.$emit('editButiran')
      }

      vmodal.open(`tambahbutirantanah-${this.tanah.id}`, {
        ...this.butiran,
      }, (butiran, {setLoading, close}) => {
        close();
      })
    },

    editTuanTanah(default_tuan_tanah) {
      if (!this.readonly) {
        return this.$emit('editTuanTanah', default_tuan_tanah)
      }
      vmodal.open('tuantanah', {
        ...default_tuan_tanah,
        tuan_tanah_rs: this.butiran.tuan_tanah_rs,
        maklumat: this.maklumat,
      }, (tuan_tanah, {setLoading, close}) => {
        close();
      })
    },
  },

}
</script>

<style scoped>

</style>
