<template>
  <div>
    <v-select label="name" :filterable="false" :options="options" @search="onSearch" :value="selected" @input="v => selected = v">
      <template slot="no-options">
        carian nama pemaju..
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          <!-- <strong>{{ option.kod_pemaju }}</strong>&nbsp;- -->
          {{ option.nama }} ({{ option.kod_pemaju }})
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          <!-- <strong>{{ option.kod_pemaju }}</strong>&nbsp;- -->
          {{ option.nama }} ({{ option.kod_pemaju }})
        </div>
      </template>
    </v-select>
    <input type="hidden" :name="name" :value="selected ? selected.kod_pemaju : null">
  </div>

</template>

<script>
import vSelect from 'vue-select';

export default {
  name: "PemajuSelect",
  props: {
    name: String,
    id: String,
    value: null,
  },
  components: {
    vSelect
  },

  created() {
    if (this.value) {
      fetch(
        `/carian-pemaju/${this.value}`
      ).then(res => {
        res.json().then(json => (this.selected = json));
      });
    }
  },

  data() {
    return {
      options: [],
      selected: this.value,
    }
  },
  methods: {
    onSearch(search, loading) {
      if (search && search.length >= 1) {
        loading(true);
        this.search(loading, search, this);
      }
    },

    search: _.debounce((loading, search, vm) => {
      fetch(
        `/carian-pemaju-nama?q=${escape(search)}`
      ).then(res => {
        res.json().then(json => (vm.options = json));
        loading(false);
      });
    }, 350)
  }
}
</script>

<style scoped>
img {
  height: auto;
  max-width: 2.5rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}


</style>
