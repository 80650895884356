import {formatFileSize, isVeevalidateDefined} from "../lib/utils";

const messages = {
    _default: field => `${field} tidak sah.`,
    after: (field, [target, inclusion]) =>
        `${field} perlu selepas ${
            inclusion ? "atau sama dengan " : ""
        }${target}.`,
    alpha_dash: field =>
        `${field} boleh mempunyai karakter angka-abjad, sengkang dan garis bawah.`,
    alpha_num: field => `${field} hanya boleh mempunyai karakter angka-abjad.`,
    alpha_spaces: field =>
        `${field} hanya boleh mempunyai karakter abjad termasuklah aksara ruang.`,
    alpha: field => `${field} hanya boleh mempunyai karakter abjad sahaja.`,
    before: (field, [target, inclusion]) =>
        `${field} perlu sebelum ${
            inclusion ? "atau sama dengan " : ""
        }${target}.`,
    between: (field, [min, max]) =>
        `${field} perlu di antara ${min} dan ${max}.`,
    confirmed: field => `${field} pengesahan tidak sepadan.`,
    credit_card: field => `${field} tidak sah.`,
    date_between: (field, [min, max]) =>
        `${field} perlu di antara ${min} dan ${max}.`,
    date_format: (field, [format]) => `${field} perlu dalam format ${format}.`,
    decimal: (field, [decimals = "*"] = []) =>
        `${field} perlu dalam bentuk angka dan boleh mempunyai ${
            !decimals || decimals === "*" ? "" : decimals
        } titik perpuluhan.`,
    digits: (field, [length]) =>
        `${field} perlu dalam bentuk angka dan mempunyai ${length} digit.`,
    dimensions: (field, [width, height]) =>
        `${field} perlu berdimensi ${width} pixel darab ${height} pixels.`,
    email: field => `${field} perlu dalam format emel yang sah.`,
    ext: field => `${field} perlu dalam format fail yang sah.`,
    image: field => `${field} perlu dalam bentuk imej.`,
    in: field => `${field} perlu dalam nilai yang sah.`,
    integer: field => `${field} perlu dalam bentuk integer.`,
    ip: field => `${field} perlu dalam format alamat ip yang sah.`,
    length: (field, [length, max]) => {
        if (max) {
            return `Panjang ${field} perlu bernilai di antara ${length} dan ${max}.`;
        }

        return `Panjang ${field} perlu bernilai ${length}.`;
    },
    max: (field, [length]) =>
        `${field} perlu tidak melebihi ${length} karakter.`,
    max_value: (field, [max]) => `${field} perlu bernilai ${max} atau kurang.`,
    mimes: field => `${field} perlu mempunyai jenis fail yang sah.`,
    min: (field, [length]) =>
        `${field} perlu sekurang-kurangnya mempunyai ${length} karakter.`,
    min_value: (field, [min]) => `${field} perlu bernilai ${min} atau lebih.`,
    not_in: field => `${field} perlu sah.`,
    numeric: field => `${field} perlu mempunyai hanya karakter angka sahaja.`,
    regex: field => `Format ${field} tidak sah.`,
    required: field => `Sila isi ruangan ${field}.`,
    size: (field, [size]) =>
        `Saiz ${field} perlu kurang daripada ${formatFileSize(size)}.`,
    url: field => `${field} bukan URL yang sah.`,
    youtube_url: field => `${field} bukan Youtube URL yang sah.`,
    latitud: field =>
        `${field} perlu lebih besar daripada -90 dan lebih kecil daripada 90`,
    longitud: field =>
        `${field} perlu lebih besar daripada -180 dan lebih kecil daripada 180`,
    greaterThanZero: field =>
        `Nilai ${field} perlu lebih daripada 0.`,
};


const locale = {
    name: "my",
    messages,
    attributes: {},
    custom: {},
};

export default locale;
