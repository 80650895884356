<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xxxl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">KEMASKINI BUTIRAN UNIT</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Jenis Kediaman</label>
                <div class="col-md-4">
                  <span class="form-control-plaintext">{{ jenisKediaman(pembangunan.kod_jenis_rumah_id) }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Jenis Hakmilik</label>
                <div class="col-md-4">
                  <span class="form-control-plaintext">{{ jenisHakmilik(pembangunan.kod_jenis_hakmilik_id) }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Bilangan Unit Yang Dipohon</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" disabled :value="pembangunan.nolot_unit_rs.length">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Bilangan Tingkat</label>
                <div class="col-md-2">
                  <span class="form-control-plaintext">{{ pembangunan.bil_tingkat }}</span>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Harga Minima (RM)</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" disabled :value="getHargaMinima">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Harga Maksima (RM)</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" disabled :value="getHargaMaksima">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Harga Jualan (RM)</label>
                <div class="col-md-4">
                  <input type="text" class="form-control" disabled :value="getNilaiJualan">
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Jenis Perjanjian</label>
                <div class="col-md-4">
                  <span class="form-control-plaintext">{{ jenisPerjanjian(pembangunan.kod_jenis_perjanjian_id) }}</span>
                </div>
              </div>
            </section>

            <h4 class="card-header">BUTIRAN UNIT</h4>
            <section class="table-responsive">
              <table class="table table-card">
                <thead>
                <tr>
                  <th v-if="pembangunan.kod_jenis_hakmilik_id == 2">No Hakmilik / Lot</th>
                  <th>No Unit</th>
                  <th class="text-center">Tuan Tanah</th>
                  <th class="text-center">Kuota Bumi</th>
                  <th v-if="pembangunan.kod_jenis_hakmilik_id == 1 && !isStrataLanded">Jenis Layout</th>
                  <th v-if="pembangunan.kod_jenis_hakmilik_id == 2">Keluasan Tanah</th>
                  <th>Keluasan Binaan</th>
                  <th>Harga Jualan</th>
                  <th v-if="pembangunan.kod_jenis_hakmilik_id == 1 && !isStrataLanded">Bil. Parkir (TLK)</th>
                  <th class="text-center" v-if="maklumat.kod_skim_projek_id">Program RMM</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                <template v-if="pembangunan.nolot_unit_rs.length">
                  <tr v-for="(nolot_unit, index) in pembangunan.nolot_unit_rs">
                    <td v-if="pembangunan.kod_jenis_hakmilik_id == 2">{{ nolot_unit.no_lot }}</td>
                    <td>{{ nolot_unit.no_unit }}</td>
                    <td class="text-center">{{ nolot_unit.tuan_tanah ? '&#x2714;' : '&#x2718;' }}</td>
                    <td class="text-center">{{ nolot_unit.kuota_bumi ? '&#x2714;' : '&#x2718;' }}</td>
                    <td v-if="pembangunan.kod_jenis_hakmilik_id == 1 && !isStrataLanded">{{ nolot_unit.jenis_layout }}</td>
                    <td v-if="pembangunan.kod_jenis_hakmilik_id == 2">{{ nolot_unit.luas_tanah }}</td>
                    <td>{{ nolot_unit.luas_binaan }}</td>
                    <td>{{ nolot_unit.harga_jualan }}</td>
                    <td v-if="pembangunan.kod_jenis_hakmilik_id == 1 && !isStrataLanded">{{ nolot_unit.bilangan_tlk }}</td>
                    <td class="text-center" v-if="maklumat.kod_skim_projek_id">{{ nolot_unit.kod_skim_projek_id ? '&#x2714;' : '&#x2718;' }}</td>
                    <td>
                      <button type="button" class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="del(index)" title="Hapus"><i class="fas fa-trash"></i></button>
                    </td>
                  </tr>
                </template>
                <tr>
                  <td v-if="pembangunan.kod_jenis_hakmilik_id == 2"><input @keypress.enter="add" ref="nolot" type="text" class="form-control" name="no_lot" :class="{'is-invalid': errors.first('no_lot')}" :value="form.no_lot" @input="form.no_lot = $event.target.value.toUpperCase()" v-validate="'required'"></td>
                  <td><input @keypress.enter="add" type="text" ref="nounit" class="form-control" name="no_unit" :class="{'is-invalid': errors.first('no_unit')}" :value="form.no_unit" @input="form.no_unit = $event.target.value.toUpperCase()" v-validate="'required'"></td>
                  <td class="text-center"><input @keypress.enter="add" type="checkbox" v-model="form.tuan_tanah"  @change="tuanTanahChange"></td>
                  <td class="text-center"><input @keypress.enter="add" type="checkbox" v-model="form.kuota_bumi" :disabled="!!form.tuan_tanah"></td>
                  <td v-if="pembangunan.kod_jenis_hakmilik_id == 1 && !isStrataLanded"><input @keypress.enter="add" type="text" class="form-control" name="jenis_layout" :class="{'is-invalid': errors.first('jenis_layout')}" :value="form.jenis_layout" @input="form.jenis_layout = $event.target.value.toUpperCase()" v-validate="'required'"></td>
                  <td v-if="pembangunan.kod_jenis_hakmilik_id == 2"><input @keypress.enter="add" type="text" class="form-control" name="luas_tanah" :class="{'is-invalid': errors.first('luas_tanah')}" :value="form.luas_tanah" @input="form.luas_tanah = $event.target.value.toUpperCase()" v-validate="'required|decimal:2'"></td>
                  <td><input @keypress.enter="add" type="text" class="form-control" name="luas_binaan" :class="{'is-invalid': errors.first('luas_binaan')}" :value="form.luas_binaan" @input="form.luas_binaan = $event.target.value.toUpperCase()" v-validate="'required|decimal:2'"></td>
                  <td><input @keypress.enter="add" type="text" class="form-control" name="harga_jualan" :class="{'is-invalid': errors.first('harga_jualan')}" :value="form.harga_jualan" @input="form.harga_jualan = $event.target.value.toUpperCase()" v-validate="'required|decimal:2'"></td>
                  <td v-if="pembangunan.kod_jenis_hakmilik_id == 1 && !isStrataLanded"><input @keypress.enter="add" type="text" class="form-control" name="bilangan_tlk" :class="{'is-invalid': errors.first('bilangan_tlk')}" :value="form.bilangan_tlk" @input="form.bilangan_tlk = $event.target.value.toUpperCase()" v-validate="'required|numeric'"></td>
                  <td class="text-center" v-if="maklumat.kod_skim_projek_id"><input type="checkbox" v-model="form.kod_skim_projek_id" :value="maklumat.kod_skim_projek_id"></td>
                  <td>
                    <button type="button" class="btn btn-success" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="add" :disabled="loading"><i class="fas fa-plus"></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
            </section>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "KemaskiniButiranUnitModal",
    mounted() {
      vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
    },

    props: {
      maklumat: Object,
      kodJenisKediamanRs: Array,
      kodJenisHakmilikRs: Array,
      kodJenisPerjanjianRs: Array,

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        modalId: 'kemaskinibutiranunit',
        loading: false,
        saving: false,

        form: {},


        pembangunan: {},
        response: () => {
        },
      }
    },

    methods: {
      onOpen({ pembangunan }, callback) {
        this.form = {
          no_lot: null,
          no_unit: null,
          tuan_tanah: 0,
          kuota_bumi: 0,
          jenis_layout: null,
          luas_tanah: null,
          luas_binaan: null,
          harga_jualan: null,
        };

        this.pembangunan = JSON.parse(JSON.stringify(pembangunan));
        this.response = callback;
      },

      onClose() {
      },

      close() {
        vmodal.close(this.modalId);
      },

      async add() {
        const passes = await this.$validator.validateAll();

        if (!passes) {
          return;
        }

        this.loading = true;

        const pembangunan = this.pembangunan;
        const nolot_unit = JSON.parse(JSON.stringify(this.form));

        axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/pembangunan/${pembangunan.id}`, this.form)
        .then(response => {
          this.pembangunan = response.data;

          this.response(JSON.parse(JSON.stringify(response.data)));
        })
        .catch(error => {})
        .then(() => {
          this.loading = false;
        })


        if (this.pembangunan.kod_jenis_hakmilik_id == 2) {
          this.$refs.nolot.focus();
        } else {
          this.$refs.nounit.focus();
        }

      },

      del(index) {
        this.loading = true;

        const pembangunan = this.pembangunan;
        const nolot_unit = pembangunan.nolot_unit_rs[index];

        axios.delete(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/pembangunan/${pembangunan.id}/${nolot_unit.id}`)
          .then((response) => {
            this.pembangunan = response.data;

            this.response(JSON.parse(JSON.stringify(response.data)));
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })

        this.response(JSON.parse(JSON.stringify(pembangunan)));
      },

      tuanTanahChange() {
        this.kuota_bumi = 0;
      },

      jenisKediaman(id) {
        const kediaman = this.kodJenisKediamanRs.find(item => item.id == id);

        return kediaman ? kediaman.keterangan : '-';
      },

      jenisHakmilik(id) {
        const hakmilik = this.kodJenisHakmilikRs.find(item => item.id == id);

        return hakmilik ? hakmilik.keterangan : '-';
      },

      jenisPerjanjian(id) {
        const perjanjian = this.kodJenisPerjanjianRs.find(item => item.id == id);

        return perjanjian ? perjanjian.keterangan : '-';
      },
    },

    computed: {
      isOpen() {
        return this.$root.openedModals.indexOf(this.modalId) !== -1
      },

      isStrataLanded() {
        const landed = this.kodJenisHakmilikRs.find(item => item.id == 2);

        if (landed) {
          const found = landed.jenis_rumah_rs.find(item => item.id == this.pembangunan.kod_jenis_rumah_id);

          return !!found;
        }

        return false;
      },

      getKeluasanBangunan() {
        if (!this.pembangunan.nolot_unit_rs.length) {
          return 0;
        }
        return (_.sum(this.pembangunan.nolot_unit_rs.map(i => parseFloat(i.luas_binaan) * 100)) / 100).toFixed(2);
      },

      getKeluasanTanah() {
        if (!this.pembangunan.nolot_unit_rs.length) {
          return 0;
        }
        return (_.sum(this.pembangunan.nolot_unit_rs.map(i => parseFloat(i.luas_tanah) * 100)) / 100).toFixed(2);
      },

      getHargaMinima() {
        if (!this.pembangunan.nolot_unit_rs.length) {
          return 0;
        }
        return parseFloat(_.min(this.pembangunan.nolot_unit_rs.map(i => i.harga_jualan))).toFixed(2);
      },

      getHargaMaksima() {
        if (!this.pembangunan.nolot_unit_rs.length) {
          return 0;
        }
        return parseFloat(_.max(this.pembangunan.nolot_unit_rs.map(i => i.harga_jualan))).toFixed(2);
      },

      getNilaiJualan() {
        if (!this.pembangunan.nolot_unit_rs.length) {
          return 0;
        }
        return (_.sum(this.pembangunan.nolot_unit_rs.map(i => parseFloat(i.harga_jualan) * 100)) / 100).toFixed(2);
      },
    }
  }
</script>

<style scoped>

</style>
