<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <table class="bg-white table table-hover table-card">
          <thead class="thead-light">
          <tr>
            <th>Bil</th>
            <th>Jenis Rumah</th>
            <th>Bil. Tingkat</th>
            <th>Bil Unit Diluluskan</th>
            <th>Harga Minimum</th>
            <th>Harga Maksimum</th>
            <th>Tarikh SPJB Pertama</th>
            <th>Tindakan</th>
            <th class="text-center">Status</th>
          </tr>
          </thead>
          <tbody>
          <row-pembangunan
            v-for="(item, index) in pembangunanRs"
            :key="`pembangunan-${index}`"
            :maklumat="maklumat"
            :jenis_rumah="item.jenis_rumah"
            :pembangunan="getPembangunan(item)"
            :jualan="getJualan(item)"
            :kemajuan="getKemajuan(item)"
            :index="index"
            @update="(data) => update(item, data)"
          ></row-pembangunan>
          </tbody>
        </table>
      </div>
      <br>
      <div><span class="text-success">&#x2714;</span> = Telah Dikemaskini</div>
      <div><span class="text-danger">&#x2718;</span> = Belum Dikemaskini</div>
    </div>
    <laporan7f-kemaskini-modal
      :readonly="readonly"
      :pemajuan="pemajuan"
    ></laporan7f-kemaskini-modal>
  </div>
</template>

<script>
  import Laporan7fKemaskiniModal from "../../common/Laporan7fKemaskiniModal";
  import RowPembangunan from "./components/RowPembangunan";
  export default {
    name: "BahagianB",
    components: {RowPembangunan, Laporan7fKemaskiniModal},
    props: {
      maklumat: Object,
      pemajuan: Object,

      pembangunanRs: {
        type: Array,
        default: () => ([]),
      },

      readonly: {
        type: Boolean,
        default: false,
      }
    },

    methods: {
      getPembangunan(pembangunan) {
        return this.maklumat.pembangunan_rs.find(i => pembangunan.maklumat7f.id === i.maklumat7f_id);
      },

      getJualan(pembangunan) {
        return this.maklumat.jualan_rs.find(i => pembangunan.maklumat7f.id === i.maklumat7f_id);
      },

      getKemajuan(pembangunan) {
        return this.maklumat.kemajuan_rs.find(i => pembangunan.maklumat7f.id === i.maklumat7f_id);
      },

      update(item, { pembangunan, jualan, kemajuan }) {
        const maklumat = JSON.parse(JSON.stringify(this.maklumat))

        if (pembangunan) {
          const i = this.getPembangunan(item)
          const index = this.maklumat.pembangunan_rs.indexOf(i);
          if (index !== -1) {
            maklumat.pembangunan_rs.splice(index, 1, pembangunan);
          }
        }

        if (jualan) {
          const i = this.getJualan(item)
          const index = this.maklumat.jualan_rs.indexOf(i);
          if (index !== -1) {
            maklumat.jualan_rs.splice(index, 1, jualan);
          }
        }

        if (kemajuan) {
          const i = this.getKemajuan(item)
          const index = this.maklumat.kemajuan_rs.indexOf(i);
          if (index !== -1) {
            maklumat.kemajuan_rs.splice(index, 1, kemajuan);
          }
        }


        this.$emit('update:maklumat', maklumat);
      }

    }
  }
</script>

<style scoped>

</style>
