<template>
  <div>
    <div class="position-relative" v-if="changed">
      <input type="text" class="form-control is-changed" :value="value" disabled>
      <div style="position: absolute; right: 0; top: calc(50% - 7px); width: 15px; height: 15px; color: #cd8313; cursor: pointer;" data-toggle="tooltip" :title="original"><i class="fa fa-exclamation"></i></div>
    </div>
    <input v-else type="text" class="form-control" :value="value" disabled>
  </div>

</template>

<script>
export default {
  name: "InputDiff",
  props: {
    changed: {
      type: Boolean,
      default: false,
    },
    original: String,
    value: String,
  }
}
</script>

<style scoped>

</style>
