<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLiveLabel"
      style="display: block; padding-right: 17px"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">
              BUTIRAN GADAIAN
            </h5>
            <button
              type="button"
              class="close"
              @click="close"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" for="nilai_gadaian"
                >Nilai (RM) <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <money
                  id="nilai_gadaian"
                  class="form-control"
                  :class="{ 'is-invalid': errors.first('nilai_gadaian') }"
                  type="text"
                  name="nilai_gadaian"
                  v-model="form.nilai_gadaian"
                  :disabled="readonly || form.action === 'H'"
                ></money>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4"
                >Tempoh Mula <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <datepicker
                  :class="{ 'is-invalid': errors.first('tarikh_mula') }"
                  type="text"
                  name="tarikh_mula"
                  v-model="form.tarikh_mula"
                  v-validate="'required'"
                  :disabled="readonly || form.action === 'H'"
                ></datepicker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4"
                >Tempoh Tamat <span class="label-required">*</span></label
              >
              <div class="col-md-4">
                <datepicker
                  :class="{ 'is-invalid': errors.first('tarikh_tamat') }"
                  type="text"
                  name="tarikh_tamat"
                  v-model="form.tarikh_tamat"
                  :min="form.tarikh_mula"
                  v-validate="'required'"
                  :disabled="
                    readonly || form.action === 'H' || !form.tarikh_mula
                  "
                ></datepicker>
              </div>
            </div>

            <div class="form-group">
              <fieldset class="form-fieldset">
                <legend style="white-space: pre-wrap">
                  Institusi Kewangan
                </legend>
                <div class="form-group row">
                  <label
                    class="col-form-label col-md-4"
                    style="white-space: pre-wrap"
                    >a.&#9;Nama Bank<span class="label-required">*</span></label
                  >
                  <div class="col-md-8">
                    <!--<input
                      type="text" name="nama_institusi"
                      class="form-control" :class="{'is-invalid': errors.first('nama_institusi')}"
                      :value="form.nama_institusi" @input="form.nama_institusi = $event.target.value.toUpperCase()"
                      v-validate="'required'"
                      :disabled="readonly || form.action === 'H'"
                    > -->
                    <select2
                      name="kod_bank_hq_id"
                      class="form-control"
                      :class="{
                        'is-invalid select2-hidden-accessible':
                          errors.first('kod_bank_hq_id'),
                      }"
                      v-model="form.kod_bank_hq_id"
                      :disabled="readonly || form.action === 'H'"
                      @change="updateBankHqData"
                    >
                      <option :value="null">
                        Sila Pilih Nama Bank Yang Berdaftar
                      </option>
                      <option
                        v-for="bank in kodBankHqRs"
                        :value="bank.id"
                        :key="`bank_${bank.id}`"
                      >
                        {{ bank.keterangan }}
                      </option>
                    </select2>
                  </div>
                </div>

                <section>
                  <div class="form-group row">
                    <label
                      class="col-form-label col-md-4"
                      style="white-space: pre-wrap"
                      >b.&#9;Alamat <span class="label-required">*</span></label
                    >
                    <div class="col-md-8">
                      <input
                        type="text"
                        name="alamat_institusi1"
                        class="form-control"
                        :class="{
                          'is-invalid': errors.first('alamat_institusi1'),
                        }"
                        :value="form.alamat_institusi1"
                        @input="
                          form.alamat_institusi1 =
                            $event.target.value.toUpperCase()
                        "
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="offset-md-4 col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        :value="form.alamat_institusi2"
                        @input="
                          form.alamat_institusi2 =
                            $event.target.value.toUpperCase()
                        "
                        :disabled="readonly || form.action === 'H'"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="offset-md-4 col-md-8">
                      <input
                        type="text"
                        class="form-control"
                        :value="form.alamat_institusi3"
                        @input="
                          form.alamat_institusi3 =
                            $event.target.value.toUpperCase()
                        "
                        :disabled="readonly || form.action === 'H'"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-form-label col-md-4"
                      style="white-space: pre-wrap"
                      >c.&#9;Poskod <span class="label-required">*</span></label
                    >

                    <div class="col-md-4">
                      <select2
                        name="poskod_institusi"
                        class="form-control"
                        :class="{
                          'is-invalid': errors.first('poskod_institusi'),
                        }"
                        v-model="form.poskod_institusi"
                        @change="updatePoskodData"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                        <option :value="null">Sila Pilih Poskod</option>
                        <option
                          v-for="poskod in kodPoskodRs"
                          :value="poskod.poskod"
                          :key="`poskod_${poskod.id}`"
                        >
                          {{ poskod.poskod }}
                        </option>
                      </select2>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-form-label col-md-4"
                      style="white-space: pre-wrap"
                      >f.&#9;Bandar <span class="label-required">*</span></label
                    >
                    <div class="col-md-4">
                      <select2
                        name="kod_bandar_institusi_id"
                        class="form-control"
                        :class="{
                          'is-invalid': errors.first('kod_bandar_institusi_id'),
                        }"
                        v-model="form.kod_bandar_institusi_id"
                        v-validate="'required'"
                        :disabled="disableBandar"
                      >
                        <option :value="null">Sila Pilih Bandar</option>
                        <option
                          v-for="bandar in kodBandarRs"
                          :value="bandar.id"
                          :key="`bandar_${bandar.id}`"
                        >
                          {{ bandar.keterangan }}
                        </option>
                      </select2>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label
                      class="col-form-label col-md-4"
                      style="white-space: pre-wrap"
                      >d.&#9;Negeri<span class="label-required">*</span></label
                    >
                    <div class="col-md-4">
                      <select2
                        name="kod_negeri_institusi_id"
                        class="form-control"
                        :class="{
                          'is-invalid': errors.first('kod_negeri_institusi_id'),
                        }"
                        v-model="form.kod_negeri_institusi_id"
                        v-validate="'required'"
                        :disabled="true"
                      >
                        <option :value="null">Sila Pilih Negeri</option>
                        <option
                          v-for="negeri in kodNegeriRs"
                          :value="negeri.id"
                          :key="`negeri_${negeri.id}`"
                        >
                          {{ negeri.keterangan }}
                        </option>
                      </select2>
                    </div>
                  </div>
                </section>
              </fieldset>
            </div>
          </div>
          <div class="modal-footer">
            <button
              v-if="!readonly && form.action !== 'H'"
              type="button"
              class="btn btn-primary"
              @click.prevent="save"
            >
              Simpan
            </button>
            <button type="button" class="btn btn-secondary" @click="close">
              Tutup
            </button>
          </div>
        </div>
      </div>
      <
    </div>
  </div>
</template>

<script>
export default {
  name: "GadaianTanahModal",

  mounted() {
    vmodal.register(this.modalId, { open: this.onOpen, close: this.onClose });
  },

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    kodBankHqRs: Array,
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    kodPoskodRs: Array,
  },

  data() {
    return {
      modalId: "gadaiantanah",
      loading: false,
      saving: false,
      form: {},
      response: () => {},
    };
  },

  watch: {
    "form.kod_negeri_institusi_id"(newVal) {
      if (newVal == 14 || newVal == 16) {
        //console.info(newVal);
      } else if (newVal == 10) {
        //console.info(newVal);
      } else {
        //console.info("not found");
      }
    },
  },

  methods: {
    onOpen(
      {
        id = null,
        nilai_gadaian = 0.0,
        tarikh_mula = null,
        tarikh_tamat = null,
        nama_institusi = null,
        alamat_institusi1 = null,
        alamat_institusi2 = null,
        alamat_institusi3 = null,
        poskod_institusi = null,
        kod_negeri_institusi_id = null,
        kod_daerah_institusi_id = null,
        kod_bandar_institusi_id = null,
        action = null,
        kod_bank_hq_id = null,
      },
      callback
    ) {
      this.form = {
        id,
        nilai_gadaian,
        tarikh_mula,
        tarikh_tamat,
        nama_institusi,
        alamat_institusi1,
        alamat_institusi2,
        alamat_institusi3,
        poskod_institusi,
        kod_negeri_institusi_id,
        kod_daerah_institusi_id,
        kod_bandar_institusi_id,
        action,
        kod_bank_hq_id,
      };

      if (kod_bank_hq_id) {
        this.updateBankHqData(); // panggil metode ini jika kod_bank_hq_id ada
      }

      this.response = callback;
    },

    onClose() {},

    close() {
      vmodal.close(this.modalId);
    },

    async save() {
      console.log(this.form);
      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      this.response(JSON.parse(JSON.stringify(this.form)));
      this.close();
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(
        (item) => item.kod_negeri_id == kod_negeri_id
      );
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(
        (item) => item.kod_daerah_id == kod_daerah_id
      );
    },
    // method auto fill
    updateBankHqData() {
      const selectedBankId = parseInt(this.form.kod_bank_hq_id);
      const selectedBank = this.kodBankHqRs.find(
        (item) => item.id == selectedBankId
      );
      if (selectedBank) {
        const selectedNegeri = this.kodNegeriRs.find(
          (item) => item.id == selectedBank.kod_negeri_id
        );
        const selectedDaerah = this.kodDaerahRs.filter(
          (item) => item.kod_negeri_id == selectedNegeri.id
        );
        const selectedBandar = this.kodBandarRs.filter(
          (item) => item.kod_daerah_id == selectedDaerah[1].id
        );

        this.form.nama_institusi = selectedBank.keterangan;
        if (!this.form.alamat_institusi1) {
          this.form.alamat_institusi1 = selectedBank.alamat1;
        }
        if (!this.form.alamat_institusi2) {
          this.form.alamat_institusi2 = selectedBank.alamat2;
        }
        if (!this.form.alamat_institusi3) {
          this.form.alamat_institusi3 = selectedBank.alamat3;
        }
        if (!this.form.alamat_institusi3) {
          this.form.alamat_institusi3 = selectedBank.alamat3;
        }
        if (!this.form.poskod_institusi) {
          this.form.poskod_institusi = selectedBank.poskod;
        }

        // this.form.kod_negeri_institusi_id = selectedNegeri.id;

        this.$nextTick(() => {
          // if (selectedNegeri.id == 10 && !this.form.kod_daerah_institusi_id) {
          //   const daerah = selectedDaerah.find(item => item.id == "1005");
          //   if (daerah) {
          //     this.form.kod_daerah_institusi_id = daerah.id;
          //   } else {
          //     this.form.kod_daerah_institusi_id = "";
          //   }
          // }
        });
      } else {
        this.form.nama_institusi = "";
        this.form.alamat_institusi1 = "";
        this.form.alamat_institusi2 = "";
        this.form.alamat_institusi3 = "";
        this.form.poskod_institusi = "";
        this.form.kod_negeri_institusi_id = "";
        this.form.kod_daerah_institusi_id = "";
        this.form.kod_bandar_institusi_id = "";
      }
    },

    updatePoskodData() {
      const selectedPoskod = this.kodPoskodRs.find(
        (item) => item.poskod == this.form.poskod_institusi
      );
      if (!selectedPoskod) {
        this.form.kod_bandar_institusi_id = null;
        this.form.kod_negeri_institusi_id = null;
      } else {
        this.form.kod_bandar_institusi_id = selectedPoskod.kod_bandar_id;
        this.form.kod_negeri_institusi_id = selectedPoskod.kod_negeri_id;
      }
    },
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1;
    },

    disableDaerah() {
      const result = ["14", "16"].includes(this.form.kod_negeri_institusi_id);
      //console.info("disableDaerah(): " + result);
      return result;
    },
    disableBandar() {
      return true;
    },
  },
};
</script>

<style scoped></style>
