<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">CADANGAN PEMBANGUNAN</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Jenis Hakmilik</label>
              <div class="col-md-4">
                <div class="form-control-plaintext">{{ tanah && tanah.jenis_hakmilik ? tanah.jenis_hakmilik.keterangan : '-' }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Jenis Perjanjian</label>
              <div class="col-md-4">
                <div class="form-control-plaintext">{{ perjanjian ? perjanjian.keterangan : '-' }}</div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Jenis Kediaman</label>
              <div class="col-md-4">
                <select
                  class="form-control" :class="{'is-invalid': errors.first('kod_jenis_rumah_id')}"
                  name="kod_jenis_rumah_id"
                  v-model="form.kod_jenis_rumah_id"
                  v-validate="'required'"
                >
                  <option :value="null">Sila Pilih</option>
                  <option v-for="item in filteredJenisRumahRs(form.kod_jenis_hakmilik_id)" :value="item.id">{{ item.keterangan }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Bilangan Unit Dipohon</label>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control" :class="{'is-invalid': errors.first('bil_unit')}"
                  name="bil_unit"
                  v-model="form.bil_unit"
                  v-validate="'required|decimal|min_value:1'"
                >
              </div>
            </div>
            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Bilangan Tingkat</label>
              <div class="col-md-2">
                <input
                  type="text"
                  class="form-control" :class="{'is-invalid': errors.first('bil_tingkat')}"
                  name="bil_tingkat"
                  v-model="form.bil_tingkat"
                  v-validate="'required|decimal:1|min_value:1'"
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BangunanModal",
  mounted() {
    vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
  },

  props: {
    tanah: Object,
    perjanjian: Object,
    kodJenisKediamanRs: Array,
    kodJenisHakmilikRs: Array,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      modalId: 'butiranbangunan',
      loading: false,
      saving: false,
      form: {},

      response: () => {
      },
    }
  },

  methods: {
    onOpen({ bil_tingkat = 0, bil_unit = 0, kod_jenis_rumah_id = null}, callback) {
      this.form = {
        bil_tingkat,
        bil_unit,
        kod_jenis_rumah_id,
      };

      this.response = callback;
    },

    onClose() {
    },

    close() {
      vmodal.close(this.modalId);
    },

    async save() {
      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      const form = JSON.parse(JSON.stringify(this.form));
      const self = this;

      this.response(form, {
        setLoading(loading) {
          self.loading = loading;
        },
        close: self.close
      });
    },

    filteredJenisRumahRs() {
      return this.kodJenisKediamanRs;
    }
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1
    },
  }
}
</script>

<style scoped>

</style>
