<template>
  <section>
    <div class="d-flex align-items-center mb-2">
      <h5 class="mb-0">BUTIRAN UNIT</h5>

      <div class="flex-grow-1 ml-3 d-flex align-items-center justify-content-between" v-if="!readonly">
        <div>
          <a class="text-sm" target="_blank" :href="urlTemplateUnit">MUAT TURUN TEMPLATE</a>
        </div>
        <div>
          <excel-upload
            @change="(name, files, setLoading) => $emit('uploadExcel', name, files, setLoading, scrollIntoView)"
          ></excel-upload>
        </div>
      </div>

    </div>
    <div class="alert alert-danger" v-if="errorUpload" :ref="`errorholder`">
      {{ errorUpload }}
    </div>
    <div class="alert alert-warning" v-if="!readonly">
      <div><strong>PANDUAN:</strong></div>
      <ol class="mb-0">
        <li>Untuk memuat naik secara pukal, sila muat turun template butiran unit.</li>
        <li>Kemaskini fail excel (.xlsx) dan muat naik fail tersebut.</li>
      </ol>
      <div class="mt-2"><strong>NOTA:</strong></div>
      <ol class="mb-0">
        <li>Lot tuan tanah adalah unit rumah yang tidak boleh dijual.</li>
        <li>Unit-unit yang diperuntukkan kepada tuan tanah berdasarkan dokumen JV sahaja.</li>
        <li>Medan bertanda <img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1; width: 15px; margin-left: auto;" > akan dipaparkan dalam paparan eSPA</li>
      </ol>
    </div>


    <section class="bg-white table-responsive table-hover">
      <table class="table table-card">
        <thead>
        <tr>
          <th>
            <slot name="nolot_unit_header"></slot>
          </th>
          <th>Bil.</th>
          <template v-if="['G', 'I'].includes(pembangunan.kod_jenis_perjanjian_id)">
            <!-- G or I -->
            <th>
              No. Geran
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th>
              No. Lot
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th>
              Keluasan <br>Tanah (MPS)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th>
              Keluasan <br>Binaan (MPS)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th> 
            <th class="text-center">
              Jenis <br>Susunatur
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th> 
            <th class="text-center">
              Kedudukan <br>Lot
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th> 
            <th class="text-center">
              Bil. Bilik Tidur
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Bil. Bilik Air
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Harga (MPS)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th>
              Harga <br>Jualan
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>

          </template>
          <template v-else>
            <!-- H or J -->
            <th class="text-center">
              Menara
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Tingkat
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              No. Petak
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              No. Unit
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Jenis Layout
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Kedudukan <br>Lot
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Jadual Petak Utama
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Keluasan <br>Binaan (MPS)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Petak Aksesori
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Keluasan <br>(MPS)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Jadual <br>Petak Aksesori
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Bil. Parkir (TLK)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Unit Syer
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Harga Jualan
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Harga (MPS)
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Bil. Bilik Tidur
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>
            <th class="text-center">
              Bil. Bilik Air
              <div style="display: block; margin-top: 5px;">
                <image-espa/>
              </div>
            </th>

          </template>

          <th class="text-center">Lot Tuan Tanah</th>
          <th class="text-center" v-if="maklumat.pengesahan_kuotabumi == `Y`">Lot Kuota Bumiputera</th>
          <th class="text-center" v-if="maklumat.program_kemajuan == 1">Lot Rumah Mampu Milik</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <template v-if="pembangunan.nolot_unit_rs.length">
          <row-nolot-unit v-for="(nolot_unit, index) in pembangunan.nolot_unit_rs"
            :key="`pembangunan-${pembangunan.id}-lot-${nolot_unit.id}`"

            ref="unitchild"

            :no="index + 1"
            :nolot_unit="nolot_unit"
            :maklumat="maklumat"
            :skim-projek="nolot_unit.skim_projek"
            :program-kemajuan="maklumat.program_kemajuan"
            :kod-skim-projek-rs="kodSkimProjekRs"
            :kod-jenis-perjanjian-id="pembangunan.kod_jenis_perjanjian_id"
            :kod-kedudukan-lot-rs="KodKedudukanLotRs"
            :readonly="readonly"
            @destroyUnit="$emit('destroyUnit', nolot_unit)"
            @updateUnit="(form, callback) => $emit('updateUnit', nolot_unit, form, callback, scrollIntoView)"
          >
            <slot name="nolot_unit" :nolotUnit="nolot_unit"></slot>
          </row-nolot-unit>
        </template>
        <add-unit-form
          v-if="!readonly"
          :maklumat="maklumat"
          :pembangunan="pembangunan"
          :program-kemajuan="maklumat.program_kemajuan"
          :kod-skim-projek-rs="kodSkimProjekRs"
          :kod-kedudukan-lot-rs="KodKedudukanLotRs"
          @add="(form, callback) => $emit('addUnit', form, callback, scrollIntoView)"
        ></add-unit-form>
        </tbody>
      </table>
    </section>
  </section>
</template>

<script>
import AddUnitForm from "./AddUnitForm";
import ExcelUpload from "./ExcelUpload";
import RowNolotUnit from "./RowNolotUnit";
import ImageEspa from "../../core/ImageEspa";

export default {
  name: "ButiranUnit",
  components: {RowNolotUnit, ExcelUpload, AddUnitForm, ImageEspa},
  props: {
    maklumat: Object,
    pembangunan: Object,

    errorUpload: {
      type: String,
      default: null
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    kodSkimProjekRs: Array,
    typePermohonan: {
      type: String,
      default: 'ap-baru',
    },
    KodKedudukanLotRs: Array,
  },

  methods: {
    scrollIntoView() {
      this.$nextTick(() => {
        var element = this.$refs.errorholder;

        const offset = 75;
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
        })
      })
    }
  },

  computed: {
    urlTemplateUnit() {
      const pembangunan = this.pembangunan;

      // let bulan = 24;
      // if (['G','I'].indexOf(pembangunan.kod_jenis_perjanjian_id) === -1) {
      //   bulan = 36;
      //   let url = `/template/template_nolotunit_${bulan}`;

      //   if (this.maklumat.kod_skim_projek_id) {
      //     url += '_skim';
      //   }

      //   return `${url}.xlsx`;
      // }
      if(this.typePermohonan === 'ap-baru') {
        return `/pemaju/ap-permit-baru/${pembangunan.id}/excel`;
      }

      return `/pemaju/ap-pindaanpembaharuan/${pembangunan.id}/excel`;
    },
  }
}
</script>

<style scoped>

</style>

