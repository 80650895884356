<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">BUTIRAN TANAH KESELURUHAN</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container">
               <div class="form-group row">
              Medan bertanda <img src="/img/espa_icon.png"
              alt="ESPA Icon"
              style="display: inline-flex; align-items: center; line-height: 1;  height: 15px; margin-left: 5px; margin-right: 5px;"
            ></img> akan dipaparkan dalam cetakan eSPA.
            </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_geran" style="white-space: pre-wrap;">1.&#9;Butiran Tanah</label>
                <div class="col-md-8" v-if="tanah.butiran_rs.length > 0 && !modelId">
                  <div class="form-group form-check" style="display: flex; align-items: center;">
                    <input type="checkbox" v-model="useDataPrevious" @change="useDataPreviousChange" class="form-check-input" id="checkbox-copy">
                    <label class="form-check-label ml-1" for="checkbox-copy">Gunakan Maklumat Butiran Tanah Sebelumnya</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_geran" style="white-space: pre-wrap;">&#9;Perihal Hakmilik <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select
                    class="form-control" :class="{ 'is-invalid': errors.first('kod_perihal_hakmilik_id') }"
                    name="kod_perihal_hakmilik_id" v-model="form.kod_perihal_hakmilik_id"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Perihal Hakmilik</option>
                    <option v-for="kod_perihal_hakmilik in kodPerihalHakmilikRs" :value="kod_perihal_hakmilik.id" :key="kod_perihal_hakmilik.id">{{ kod_perihal_hakmilik.keterangan }}</option>
                  </select>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img
                    >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_geran" style="white-space: pre-wrap;">&#9;No. Hakmilik <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input
                    id="no_geran"
                    type="text" name="no_geran"
                    class="form-control" :class="{ 'is-invalid': errors.first('no_geran') }"
                    :value="form.no_geran" @input="form.no_geran = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                  <div class="form-text text-grey">Contoh: HSM 1234 / HSD 1234 / GRN 1234 / PAJAKAN 1234</div>
                </div>
                <div class="col-md-1 col-form-label">
                  <a href="/img/no hakmilik.png" title="Contoh No. Hakmilik" target="_blank" style="cursor:pointer; text-decoration:none;">
                    <i class="fa fa-lg fa-info-circle"></i>
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fa fa-lg fa-info-circle"
                    style="width: 15px; margin-left: 5px; margin-top: 10px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img
                    >
                  </a>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_lot_pemajuan" style="white-space: pre-wrap;">&#9;No. Lot / PTD / PT <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input
                    id="no_lot_pemajuan"
                    type="text" name="no_lot_pemajuan"
                    class="form-control" :class="{ 'is-invalid': errors.first('no_lot_pemajuan') }"
                    :value="form.no_lot_pemajuan" @input="form.no_lot_pemajuan = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                  <div class="form-text text-grey">Contoh: PT1234</div>
                </div>
                <div class="col-md-1 col-form-label">
                  <a href="/img/no lot.png" title="Contoh No. Lot" target="_blank" style="cursor:pointer; text-decoration:none;">
                    <i class="fa fa-lg fa-info-circle"></i>

                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fa fa-lg fa-info-circle"
                    style="width: 15px; margin-left: 5px; margin-top: 10px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img
                    >
                  </a>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" for="luas_tanah" style="white-space: pre-wrap;">&#9;Keluasan Lot <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <div class="input-group">
                    <input
                      id="luas_tanah"
                      type="text" name="luas_tanah"
                      class="form-control" :class="{ 'is-invalid': errors.first('luas_tanah') }"
                      :value="form.luas_tanah" @input="form.luas_tanah = $event.target.value.toUpperCase()"
                      v-validate="'required|decimal:4'"
                      :disabled="readonly"
                    >
                    <div class="input-group-append">
                      <select
                        name="luas_tanah_param"
                        class="form-control" :class="{ 'is-invalid': errors.first('luas_tanah_param') }"
                        v-model="form.luas_tanah_param"
                        v-validate="'required'"
                        :disabled="readonly"
                      >
                        <option :value="null">Sila Pilih</option>
                        <!-- <option value="EKAR">EKAR</option> -->
                        <option value="HEKTAR">HEKTAR</option>
                        <!-- <option value="KPS">KPS</option> -->
                        <option value="MPS">MPS</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" for="seksyen" style="white-space: pre-wrap;">&#9;Seksyen <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <input
                    id="seksyen"
                    type="text" name="seksyen"
                    class="form-control" :class="{ 'is-invalid': errors.first('seksyen') }"
                    :value="form.seksyen" @input="form.seksyen = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                  <div class="form-text text-grey">Masukkan "NIL" jika tidak berkenaan</div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="align-items: center; display: flex"
                  ><span class="tab-space" style="display: inline-block; width: 2.0em"></span
                  >Daerah<span class="label-required">*</span>
                  </label
                >
                <div class="col-md-4">
                  <select2
                    v-model="form.kod_daerah_id"
                    @change="form.kod_bandar_id = null"
                    class="form-control"
                    name="kod_daerah_id"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.first('kod_daerah_id') }"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Daerah</option>
                    <option
                      v-for="daerah in filteredDaerahRs(tanah.negeri.id)"
                      :value="daerah.id"
                      :key="`daerah-${daerah.id}`"
                    >
                      {{ daerah.keterangan }}
                    </option>
                  </select2>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img
                    >
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-form-label col-md-4"
                  style="align-items: center; display: flex"
                  ><span class="tab-space" style="display: inline-block; width: 2.0em"></span
                  >Mukim / Bandar / Pekan <span class="label-required"
                    >*</span
                  >
                  </label
                >
                <div class="col-md-4">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <select
                        name="jenis_bandar"
                        class="form-control"
                        :class="{
                          'is-invalid': errors.first('luas_tanah_param'),
                        }"
                        v-validate="'required'"
                        :disabled="readonly"
                        v-model="selectedType"
                      >
                        <option :value="null">Sila Pilih</option>
                        <option value="BANDAR">BANDAR</option>
                        <option value="PEKAN">PEKAN</option>
                        <option value="MUKIM">MUKIM</option>
                      </select>
                    </div>
                    <select2
                      v-model="form.kod_bandar_id"
                      class="form-control"
                      v-validate="'required'"
                      name="kod_bandar_id"
                      :class="{ 'is-invalid': errors.first('kod_bandar_id') }"
                      style="line-height: 38px; height: auto !important"
                      :disabled="readonly"
                    >
                      <option :value="null">Sila Pilih</option>
                      <option
                        v-for="bandar in filteredBandar"
                        :value="bandar.id"
                        :key="`bandar-${bandar.id}`"
                      >
                        {{ bandar.keterangan }}
                      </option>
                    </select2>
                  </div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Kategori Kegunaan Tanah <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select
                    class="form-control" :class="{ 'is-invalid': errors.first('kod_kategori_tanah_id') }"
                    name="kod_kategori_tanah_id" v-model="form.kod_kategori_tanah_id"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih</option>
                    <option v-for="kod_kegunaan_tanah in kodKegunaanTanahRs" :value="kod_kegunaan_tanah.id" :key="kod_kegunaan_tanah.id">{{ kod_kegunaan_tanah.keterangan }}</option>
                  </select>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Syarat Nyata <span class="label-required">*</span></label>
                <div class="col-md-4 col-form-label">
                  <div class="form-check form-check-inline" v-for="syarat_nyata in kodSyaratNyataRs" :key="`syarat_nyata_${syarat_nyata.id}`">
                    <input
                      :id="`syarat_nyata-pemaju-${syarat_nyata.id}`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('kod_syarat_nyata_id') }"
                      type="radio" name="kod_syarat_nyata_id"
                      v-model="form.kod_syarat_nyata_id" :value="syarat_nyata.id"
                      @change="form.syarat_nyata_lain = null"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                    <label class="form-check-label" :for="`syarat_nyata-pemaju-${syarat_nyata.id}`">{{ syarat_nyata.keterangan }}</label>
                  </div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row" v-if="form.kod_syarat_nyata_id === 4">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Jika lain-lain, nyatakan</label>
                <div class="col-md-4">
                  <input
                    class="form-control"  :class="{ 'is-invalid': errors.first('syarat_nyata_lain') }"
                    type="text" name="syarat_nyata_lain"
                    :value="form.syarat_nyata_lain"
                    @input="form.syarat_nyata_lain = $event.target.value.toUpperCase()"
                    :disabled="readonly"
                    v-validate="'required'"
                  >
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Ada Sekatan Kepentingan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <div class="form-check form-check-inline">
                    <input
                      :id="`ada-sekatan-0`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('ada_sekatan') }"
                      type="radio" name="ada_sekatan"
                      v-model="form.ada_sekatan" :value="0"
                      @change="form.catatan_sekatan_kepentingan = null"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                    <label class="form-check-label" :for="`ada-sekatan-0`">TIDAK</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      :id="`ada-sekatan-1`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('ada_sekatan') }"
                      type="radio" name="ada_sekatan"
                      v-model="form.ada_sekatan" :value="1"
                      @change="form.catatan_sekatan_kepentingan = null"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                    <label class="form-check-label" :for="`ada-sekatan-1`">YA</label>
                  </div>
                </div>
              </div>
              <div class="form-group row" v-if="!!form.ada_sekatan">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Catatan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <textarea
                    class="form-control" :class="{ 'is-invalid': errors.first('catatan_sekatan_kepentingan') }"
                    type="text" name="catatan_sekatan_kepentingan"
                    :value="form.catatan_sekatan_kepentingan"
                    @input="form.catatan_sekatan_kepentingan = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly"
                  ></textarea>
                  <div class="form-text text-grey">Sila rujuk di dalam dokumen Geran</div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Status Tanah <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select
                    class="form-control" :class="{ 'is-invalid': errors.first('kod_status_tanah_id') }"
                    name="kod_status_tanah_id" v-model="form.kod_status_tanah_id"
                    @change="form.status_tanah_lain = null; form.tempoh_pajakan = null; form.tarikh_luput_pajakan = null"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Status Tanah</option>
                    <option v-for="kod_status_tanah in kodStatusTanahRs" :value="kod_status_tanah.id" :key="kod_status_tanah.id">{{ kod_status_tanah.keterangan }}</option>
                  </select>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Tempoh Pajakan (TAHUN) <span class="label-required" v-if="form.kod_status_tanah_id === 2">*</span></label>
                <div class="col-md-4">
                  <input v-if="form.kod_status_tanah_id === 2" type="text" name="tempoh_pajakan"
                        class="form-control" :class="{ 'is-invalid': errors.first('tempoh_pajakan') }"
                        v-model="form.tempoh_pajakan"
                        v-validate="'required|numeric'" :disabled="readonly">
                  <div v-else class="form-control-plaintext">Tidak berkenaan</div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Tarikh Luput Pajakan <span class="label-required" v-if="form.kod_status_tanah_id === 2">*</span></label>
                <div class="col-md-4">
                  <datepicker v-if="form.kod_status_tanah_id === 2"  name="tarikh_luput_pajakan"
                              :class="{ 'is-invalid': errors.first('tarikh_luput_pajakan') }"
                              v-model="form.tarikh_luput_pajakan"
                              v-validate="'required'" :disabled="form.kod_status_tanah_id !== 2 || readonly"></datepicker>
                  <div v-else class="form-control-plaintext">Tidak berkenaan</div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">7.&#9;Rezab Tanah <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select
                    class="form-control" :class="{ 'is-invalid': errors.first('kod_rezab_tanah_id') }"
                    name="kod_rezab_tanah_id" v-model="form.kod_rezab_tanah_id"
                    v-validate="'required'"
                    :disabled="readonly"
                  >
                    <option :value="null">Sila Pilih Rezab Tanah</option>
                    <option v-for="kod_rezab_tanah in kodRezabTanahRs" :value="kod_rezab_tanah.id" :key="kod_rezab_tanah.id">{{ kod_rezab_tanah.keterangan }}</option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">8.&#9;Tanah Wakaf <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <div class="form-check form-check-inline">
                    <input
                      :id="`ada-wakaf-0`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('tanah_wakaf') }"
                      type="radio" name="tanah_wakaf"
                      v-model="form.tanah_wakaf" :value="0"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                    <label class="form-check-label" :for="`ada-wakaf-0`">TIDAK</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      :id="`ada-wakaf-1`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('tanah_wakaf') }"
                      type="radio" name="tanah_wakaf"
                      v-model="form.tanah_wakaf" :value="1"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                    <label class="form-check-label" :for="`ada-wakaf-1`">YA</label>
                  </div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <div v-if="!!form.tanah_wakaf">
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Surat Ikatan Wakaf <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <file-upload
                      class="w-100"
                      v-if="readonly || doc_surat_pengesahan_main"
                      :doc="doc_surat_pengesahan_main"
                      @remove="doc_surat_pengesahan_main = null; form.doc_surat_pengesahan_main_id = null"
                      :readonly="readonly"
                    ></file-upload>
                    <input-file-new v-else class="form-control" v-model="form.doc_surat_pengesahan_main_id"
                                    name="doc_surat_pengesahan_main_id"
                                    :class="{ 'is-invalid': errors.first('doc_surat_pengesahan_main_id') }"
                                    v-validate="'required'"
                    ></input-file-new>
                    <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                  </div>
                  <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Tarikh Surat Ikatan Wakaf <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <datepicker name="tarikh_surat_ikatan_wakaf"
                                :class="{ 'is-invalid': errors.first('tarikh_surat_ikatan_wakaf') }"
                                v-model="form.tarikh_surat_ikatan_wakaf"
                                v-validate="'required'" :disabled="readonly"></datepicker>
                  </div>
                  <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Tarikh Daftar Ikatan Wakaf</label>
                  <div class="col-md-4">
                    <datepicker name="tarikh_daftar_tanah_wakaf"
                                :class="{ 'is-invalid': errors.first('tarikh_daftar_tanah_wakaf') }"
                                v-model="form.tarikh_daftar_tanah_wakaf"
                                :disabled="readonly"></datepicker>
                  </div>
                  <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;No. Perserahan <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <input
                      id="no_perserahan_tanah_wakaf"
                      type="text" name="no_perserahan_tanah_wakaf"
                      class="form-control" :class="{ 'is-invalid': errors.first('no_perserahan_tanah_wakaf') }"
                      :value="form.no_perserahan_tanah_wakaf" @input="form.no_perserahan_tanah_wakaf = $event.target.value.toUpperCase()"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                  </div>
                  <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Surat Perjanjian Usahasama (JV) <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <file-upload
                      class="w-100"
                      v-if="readonly || doc_surat_perjanjian_jv"
                      :doc="doc_surat_perjanjian_jv"
                      @remove="doc_surat_perjanjian_jv = null; form.doc_surat_perjanjian_jv_id = null"
                      :readonly="readonly"
                    ></file-upload>
                    <input-file-new v-else class="form-control" v-model="form.doc_surat_perjanjian_jv_id"
                                    name="doc_surat_perjanjian_jv_id"
                                    :class="{ 'is-invalid': errors.first('doc_surat_perjanjian_jv_id') }"
                                    v-validate="'required'"
                    ></input-file-new>
                    <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Tarikh Perjanjian Usahasama (JV) <span class="label-required">*</span></label>
                  <div class="col-md-4">
                    <datepicker name="tarikh_surat_perjanjian_jv"
                                :class="{ 'is-invalid': errors.first('tarikh_surat_perjanjian_jv') }"
                                v-model="form.tarikh_surat_perjanjian_jv"
                                v-validate="'required'" :disabled="readonly"></datepicker>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Surat Tambahan Perjanjian Usahasama (JV)</label>
                  <div class="col-md-4">
                    <file-upload
                      class="w-100"
                      v-if="readonly || doc_surat_tambahan_perjanjian_jv"
                      :doc="doc_surat_tambahan_perjanjian_jv"
                      @remove="doc_surat_tambahan_perjanjian_jv = null; form.doc_surat_tambahan_perjanjian_jv_id = null"
                      :readonly="readonly"
                    ></file-upload>
                    <input-file-new v-else class="form-control" v-model="form.doc_surat_tambahan_perjanjian_jv_id"
                                    name="doc_surat_tambahan_perjanjian_jv_id"
                                    :class="{ 'is-invalid': errors.first('doc_surat_tambahan_perjanjian_jv_id') }"
                    ></input-file-new>
                    <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                  </div>
                </div>

              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">9.&#9;Bebanan Tanah <span class="label-required">*</span></label>
                <div class="col-md-4 col-form-label">
                  <div class="form-check form-check-inline" v-for="bebanan_tanah in kodBebananTanahRs" :key="`bebanan_tanah_${bebanan_tanah.id}`">
                    <input
                      :id="`bebanan_tanah-pemaju-${bebanan_tanah.id}`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('kod_bebanan_tanah_id') }"
                      type="radio" name="kod_bebanan_tanah_id"
                      v-model="form.kod_bebanan_tanah_id" :value="bebanan_tanah.id"
                      v-validate="'required'"
                      :disabled="readonly"
                    >
                    <label class="form-check-label" :for="`bebanan_tanah-pemaju-${bebanan_tanah.id}`">{{ bebanan_tanah.keterangan }}</label>
                  </div>
                </div>
                <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                </div>
              </div>

              <section class="form-group" v-if="showGadaian">
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">10.&#9;Jenis Gadaian <span class="label-required">*</span></label>
                  <div class="col-md-4 col-form-label">
                  </div>
                  <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                  </div>
                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Bil.</th>
                      <th>Nama Institusi Kewangan</th>
                      <th>Tempoh Mula</th>
                      <th>Tempoh Tamat</th>
                      <th>Status Gadaian</th>
                      <th>Nilai (RM)</th>
                      <th width="1%">
                        <button
                          v-if="!isReadOnly"
                          class="btn btn-success"
                          style="
                            padding: 0.1rem 0.3rem;
                            font-size: 1.3rem;
                          "
                          @click.prevent="addGadaian"
                        >
                          +
                        </button>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="form.gadaian_rs.length">
                      <tr v-for="(gadaian, index) in form.gadaian_rs" :key="`gadaian_${gadaian.id + index}`" :class="{ 'bg-danger': gadaian.dipadam_pada }">
                        <td>
                          {{ index + 1 }}
                          <span
                            class="text-danger"
                            v-if="gadaian.action === 'H'"
                            >(HAPUS)</span
                          >
                          <span
                            class="text-warning"
                            v-if="gadaian.action === 'B'"
                            >(BARU)</span
                          >
                          <span
                            class="text-info"
                            v-if="gadaian.action === 'K'"
                            >(KEKAL)</span
                          >
                        </td>
                        <td>{{ gadaian.nama_institusi }}</td>
                        <td>{{ gadaian.tarikh_mula }}</td>
                        <td>{{ gadaian.tarikh_tamat }}</td>
                        <td>
                          <span v-if="new Date() < new Date(formatDate(gadaian.tarikh_tamat))">
                            AKTIF
                          </span>
                          <span v-else>
                            TIDAK AKTIF
                          </span>
                        </td>
                        <td nowrap>
                          <template>
                            <button
                              class="btn btn-icon text-primary"
                              style="
                                padding: 0.1rem 0.3rem;
                                font-size: 0.9rem;
                              "
                              @click.prevent="editGadaian(index)"
                              title="Kemaskini"
                            >
                              <i class="fas fa-edit"></i>
                            </button>
                            <button
                              v-if="!isReadOnly && gadaian.action !== 'H'"
                              class="btn btn-icon text-danger"
                              style="
                                padding: 0.1rem 0.3rem;
                                font-size: 0.9rem;
                              "
                              @click.prevent="destroyGadaian(index)"
                              title="Hapus"
                            >
                              <i class="fas fa-trash"></i>
                            </button>
                            <button
                              v-if="!isReadOnly && gadaian.action === 'H'"
                              class="btn btn-icon text-warning"
                              style="
                                padding: 0.1rem 0.3rem;
                                font-size: 0.9rem;
                              "
                              @click.prevent="destroyGadaian(index)"
                              title="Aktif Semula"
                            >
                              <i class="fas fa-check"></i>
                            </button>
                          </template>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section class="form-group" v-if="showKaveat">
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">{{ showGadaian ? '11' : '10' }}.&#9;Jenis Kaveat<span class="label-required">*</span>
                  </label>
                  <div class="col-md-4 col-form-label">
                  </div>
                  <div class="col-md-1 col-form-label">
                    <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                    ></img>
                  </div>

                </div>
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                    <tr>
                      <th>Bil.</th>
                      <th>Jenis Kaveat</th>
                      <th>Nama Pengkaveat</th>
                      <th>No. Rujukan Kaveat</th>
                      <th>Tarikh Kaveat</th>
                      <th width="1%">
                        <button v-if="!readonly" class="btn btn-success" style="padding: 0.1rem 0.3rem; font-size: 1.3rem;" @click.prevent="addKaveat">+</button>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="form.kaveat_rs.length">
                      <tr v-for="(kaveat, index) in form.kaveat_rs" :key="`kaveat_${kaveat.nama + index}`">
                        <td>{{ index + 1 }}</td>
                        <td>{{ jenisKaveat(kaveat.kod_jenis_kaveat_id) }}</td>
                        <td>{{ kaveat.nama_pengkaveat }}</td>
                        <td>{{ kaveat.no_rujukan_kaveat }}</td>
                        <td>{{ kaveat.tarikh_kaveat }}</td>
                        <td nowrap>
                          <button class="btn btn-icon text-primary" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="editKaveat(index)" title="Kemaskini"><i class="fas fa-edit"></i></button>
                          <button v-if="!readonly" class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroyKaveat(index)" title="Hapus"><i class="fas fa-trash"></i></button>
                        </td>
                      </tr>
                    </template>
                    <tr v-else>
                      <td class="text-center" colspan="99">Tiada Rekod Dijumpai</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </section>

              <section class="form-group row" v-if="form.kod_bebanan_tanah_id === 6">
                <div class="col-md-8">
                  <textarea name="bebanan_lain" class="form-control" v-model="form.bebanan_lain" id="" cols="30" rows="10" placeholder="Sila masukkan rekod urusan sepertimana pada Borang 11AK (Geran Hakmilik) "
                      :class="{ 'is-invalid': errors.first('bebanan_lain') }"
                      v-validate="'required'"
                      :disabled="readonly"
                  ></textarea>
                </div>
              </section>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">{{ (showGadaian && showKaveat) ? '12' : ((showGadaian || showKaveat) ? '11' : '10') }}.&#9;Muat Naik Dokumen Hakmilik Tanah <span class="label-required">*</span><br><i>(dokumen ini juga merujuk kepada dokumen geran)</i><span class="label-required">*</span></label>
                <div class="col-md-8">
                  <file-upload
                    class="w-100"
                    v-if="readonly || doc_geran"
                    :doc="doc_geran"
                    @remove="doc_geran = null; form.doc_geran_id = null"
                    :readonly="readonly"
                  ></file-upload>
                  <input-file-new v-else class="form-control" v-model="form.doc_geran_id"
                                  name="doc_geran_id"
                                  :class="{ 'is-invalid': errors.first('doc_geran_id') }"
                                  v-validate="'required'"
                  ></input-file-new>
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
            <button v-if="!readonly" type="button" class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment';

export default {
  name: "TambahButiranTanahModal",
  mounted() {
    vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
  },

  props: {
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    tanah: Object,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      modelId: null,
      modalId: `tambahbutirantanah-${this.tanah.id}`,
      loading: false,
      saving: false,
      form: {},

      doc_geran: null,
      doc_surat_pengesahan_main: null,
      doc_surat_perjanjian_jv: null,
      doc_surat_tambahan_perjanjian_jv: null,

      selectedType: null,
      useDataPrevious: false,
      response: () => {
      },
    }
  },

  methods: {
    onOpen({
      id = null,
      no_geran = null,
      no_lot_pemajuan = null,
      luas_tanah = null,
      luas_tanah_param = null,

      kod_perihal_hakmilik_id = null,
      kod_kategori_tanah_id = null,
      kod_syarat_nyata_id = null,
      ada_sekatan = null,
      catatan_sekatan_kepentingan = null,
      syarat_nyata_lain = null,
      kod_status_tanah_id = null,
      kod_rezab_tanah_id = null,
      status_tanah_lain = null,
      tempoh_pajakan = null,
      tarikh_luput_pajakan = null,
      kod_bebanan_tanah_id = null,
      bebanan_lain = null,
      seksyen = null,
      tanah_wakaf = null,

      tarikh_surat_ikatan_wakaf = null,
      tarikh_daftar_tanah_wakaf = null,
      no_perserahan_tanah_wakaf = null,

      tarikh_surat_perjanjian_jv = null,

      doc_geran_id = null,
      doc_geran = null,

      doc_surat_pengesahan_main_id = null,
      doc_surat_pengesahan_main = null,

      doc_surat_perjanjian_jv_id = null,
      doc_surat_perjanjian_jv = null,

      doc_surat_tambahan_perjanjian_jv_id = null,
      doc_surat_tambahan_perjanjian_jv = null,

      kaveat_rs = [],
      gadaian_rs = [],

      kod_daerah_id = null,
      kod_bandar_id = null,
    }, callback) {
      this.form = {
        no_geran,
        no_lot_pemajuan,
        luas_tanah,
        luas_tanah_param,

        kod_perihal_hakmilik_id,
        kod_kategori_tanah_id,
        kod_syarat_nyata_id,
        ada_sekatan,
        catatan_sekatan_kepentingan,
        syarat_nyata_lain,
        kod_status_tanah_id,
        status_tanah_lain,
        kod_rezab_tanah_id,
        tempoh_pajakan,
        tarikh_luput_pajakan,
        kod_bebanan_tanah_id,
        bebanan_lain,
        seksyen,
        tanah_wakaf,

        doc_geran_id,
        doc_surat_pengesahan_main_id,
        doc_surat_perjanjian_jv_id,
        doc_surat_tambahan_perjanjian_jv_id,

        tarikh_surat_ikatan_wakaf,
        tarikh_daftar_tanah_wakaf,
        no_perserahan_tanah_wakaf,
        tarikh_surat_perjanjian_jv,

        kaveat_rs: JSON.parse(JSON.stringify(kaveat_rs)),
        gadaian_rs: JSON.parse(JSON.stringify(gadaian_rs)),

        kod_daerah_id,
        kod_bandar_id,
      };

      this.selectedType = null;

      if(id !== null) { // action edit
        this.form.kod_daerah_id = kod_daerah_id || this.tanah.daerah.id;
        this.form.kod_bandar_id = kod_bandar_id || this.tanah.bandar.id;
        this.setSelectedTypeBandar();
      }

      this.doc_geran = doc_geran;
      this.doc_surat_pengesahan_main = doc_surat_pengesahan_main;
      this.doc_surat_perjanjian_jv = doc_surat_perjanjian_jv;
      this.doc_surat_tambahan_perjanjian_jv = doc_surat_tambahan_perjanjian_jv;
      this.modelId = id;
      this.response = callback;
    },

    onClose() {
      this.useDataPrevious = false;
    },

    close() {
      vmodal.close(this.modalId);
    },

    async save() {
      if (this.loading) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }

      if (this.showGadaian && this.form.gadaian_rs.length === 0) {
        swal.fire(
          'Maaf!',
          'Sila isi maklumat gadaian.',
          'error',
        )
        return;
      }

      if (this.showKaveat && this.form.kaveat_rs.length === 0) {
        swal.fire(
          'Maaf!',
          'Sila isi maklumat kaveat.',
          'error',
        )
        return;
      }

      const form = JSON.parse(JSON.stringify(this.form));
      if (this.form.doc_geran_id instanceof File) {
        form.doc_geran_id = this.form.doc_geran_id;
      }

      if (this.form.doc_surat_pengesahan_main_id instanceof File) {
        form.doc_surat_pengesahan_main_id = this.form.doc_surat_pengesahan_main_id;
      }

      if (this.form.doc_surat_perjanjian_jv_id instanceof File) {
        form.doc_surat_perjanjian_jv_id = this.form.doc_surat_perjanjian_jv_id;
      }

      if (this.form.doc_surat_tambahan_perjanjian_jv_id instanceof File) {
        form.doc_surat_tambahan_perjanjian_jv_id = this.form.doc_surat_tambahan_perjanjian_jv_id;
      }

      const self = this;

      this.response(form, {
        setLoading(loading) {
          self.loading = loading;
        },
        errorCallback(err) {
          self.$hError(err)
        },
        close: self.close
      });
    },

    addGadaian() {
      vmodal.open('gadaiantanah', {}, (gadaian) => {
        this.form.gadaian_rs.push(gadaian);
      })
    },

    addKaveat() {
      vmodal.open('kaveattanah', {}, (kaveat) => {
        this.form.kaveat_rs.push(kaveat);
      })
    },

    editGadaian(index) {
      const editing_gadaian = JSON.parse(JSON.stringify(this.form.gadaian_rs[index]));
      vmodal.open('gadaiantanah', editing_gadaian, (gadaian) => {
        this.form.gadaian_rs.splice(index, 1, gadaian);
      })
    },

    editKaveat(index) {
      const editing_kaveat = JSON.parse(JSON.stringify(this.form.kaveat_rs[index]));
      vmodal.open('kaveattanah', editing_kaveat, (kaveat) => {
        this.form.kaveat_rs.splice(index, 1, kaveat);
      })
    },

    enableGadaian(index) {
      const gadaian = this.form.gadaian_rs[index];

      delete gadaian.dipadam_pada;

      this.form.gadaian_rs.splice(index, 1, gadaian);
    },

    enableKaveat(index) {
      const kaveat = this.form.kaveat_rs[index];

      delete kaveat.dipadam_pada;

      this.form.kaveat_rs.splice(index, 1, kaveat);
    },

    destroyGadaian(index) {
      this.form.gadaian_rs.splice(index, 1);
    },

    destroyKaveat(index) {
      this.form.kaveat_rs.splice(index, 1);
    },

    alamatGadaian(gadaian) {
      return gadaian.alamat_institusi1;
    },

    jenisKaveat(kaveat_id) {
      const found = this.kodJenisKaveatRs.find(item => item.id === kaveat_id);

      return found ? found.keterangan : 'Tiada Maklumat';
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(
        (item) => item.kod_negeri_id == kod_negeri_id
      );
    },

    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(
        (item) => item.kod_daerah_id == kod_daerah_id
      );
    },

    formatDate(date) {
      return moment(date, "D/M/YYYY").format("YYYY-MM-DD")
    },

    setSelectedTypeBandar() {
      const bandarSelected = this.kodBandarRs.find(bandar => bandar.id === this.form.kod_bandar_id);
      const splitBandar = bandarSelected ? bandarSelected.keterangan.split(' ') : [];
      if (splitBandar.length > 0) {
        this.selectedType = splitBandar[0];
      }
    },

    useDataPreviousChange() {
      if(!this.useDataPrevious) {
        this.resetForm();
        return;
      }

      const lastIndexButiran = this.tanah.butiran_rs.length - 1;
      const dataPrevious = JSON.parse(JSON.stringify(this.tanah.butiran_rs[lastIndexButiran]));
      const negeriId = dataPrevious.kod_negari_id;
      const bandarId = dataPrevious.kod_bandar_id;
      this.form = dataPrevious;
      this.form.kod_negari_id = negeriId;
      this.$nextTick(() => {
        this.form.doc_geran_id = null;
        this.form.kod_bandar_id = bandarId;
        this.setSelectedTypeBandar();
      });
    },

    resetForm() {
      this.form = {
        no_geran : null,
        no_lot_pemajuan : null,
        luas_tanah : null,
        luas_tanah_param : null,

        kod_perihal_hakmilik_id : null,
        kod_kategori_tanah_id : null,
        kod_syarat_nyata_id : null,
        ada_sekatan : null,
        catatan_sekatan_kepentingan : null,
        syarat_nyata_lain : null,
        kod_status_tanah_id : null,
        kod_rezab_tanah_id : null,
        status_tanah_lain : null,
        tempoh_pajakan : null,
        tarikh_luput_pajakan : null,
        kod_bebanan_tanah_id : null,
        bebanan_lain : null,
        seksyen : null,
        tanah_wakaf : null,

        tarikh_surat_ikatan_wakaf : null,
        tarikh_daftar_tanah_wakaf : null,
        no_perserahan_tanah_wakaf : null,

        tarikh_surat_perjanjian_jv : null,

        doc_geran_id : null,
        doc_geran : null,

        doc_surat_pengesahan_main_id : null,
        doc_surat_pengesahan_main : null,

        doc_surat_perjanjian_jv_id : null,
        doc_surat_perjanjian_jv : null,

        doc_surat_tambahan_perjanjian_jv_id : null,
        doc_surat_tambahan_perjanjian_jv : null,

        kaveat_rs : [],
        gadaian_rs : [],

        kod_daerah_id : null,
        kod_bandar_id : null,
      }
    },
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1
    },

    showKaveat() {
      return [2,3,4,5].indexOf(this.form.kod_bebanan_tanah_id) !== -1;
    },

    showGadaian() {
      return [1,4,5].indexOf(this.form.kod_bebanan_tanah_id) !== -1;
    },

    filteredBandar() {
      const bandarList = this.filteredBandarRs(this.form.kod_daerah_id);
      if (!this.selectedType) {
        return bandarList;
      }
      return bandarList.filter((bandar) =>
        bandar.keterangan.startsWith(this.selectedType)
      );
    },

    isReadOnly() {
      return this.readonly || this.form.action === "H";
    },
  }
}
</script>

<style scoped>

</style>
