<template>
  <div class="card bg-light">
    <div class="card-body">
      <h4 class="card-title">BUTIRAN KEMAJUAN</h4>
      <div class="card">
        <section class="card-body form-compact">
          <h4 class="card-title mb-1">GAMBAR KEMAJUAN</h4>
          <div class="text-info mb-2">Sila muat naik semua gambar dengan file berformat png/jpg</div>

          <ul class="list-group">
            <li class="list-group-item d-flex justify-content-between">
              <div>
                1. Gambar Kemajuan 1 <span class="label-required">*</span>
              </div>
              <photo-upload
                :doc="maklumat.gambar_sisi_kiri"
                @upload="(options) => uploadPhoto('gambar_sisi_kiri', options)"
                @remove="() => removePhoto('gambar_sisi_kiri')"
                name="gambar_sisi_kiri"
                v-validate="'required'"
              ></photo-upload>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <div>
                2. Gambar Kemajuan 2 <span class="label-required">*</span>
              </div>
              <photo-upload
                :doc="maklumat.gambar_sisi_depan"
                @upload="(options) => uploadPhoto('gambar_sisi_depan', options)"
                @remove="() => removePhoto('gambar_sisi_depan')"
                name="gambar_sisi_depan"
                v-validate="'required'"
              ></photo-upload>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <div>
                3. Gambar Kemajuan 3 <span class="label-required">*</span>
              </div>
              <photo-upload
                :doc="maklumat.gambar_sisi_kanan"
                @upload="(options) => uploadPhoto('gambar_sisi_kanan', options)"
                @remove="() => removePhoto('gambar_sisi_kanan')"
                name="gambar_sisi_kanan"
                v-validate="'required'"
              ></photo-upload>
            </li>
            <li class="list-group-item d-flex justify-content-between">
              <div>
                4. Gambar Kemajuan 4 <span class="label-required">*</span>
              </div>
              <photo-upload
                :doc="maklumat.gambar_sisi_atas"
                @upload="(options) => uploadPhoto('gambar_sisi_atas', options)"
                @remove="() => removePhoto('gambar_sisi_atas')"
                name="gambar_sisi_atas"
                v-validate="'required'"
              ></photo-upload>
            </li>
          </ul>
        </section>

        <section class="card-body form-compact">
          <latlng
            :lat="lat"
            :lng="lng"
            @update="updateCoord"
            :readonly="readonly"
          ></latlng>
          <div class="form-group text-right">
            <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition><button type="button" class="btn btn-sm btn-primary" :disabled="processing" @click.prevent="saveLatLng">Simpan Latitud & Longitud</button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import PemajuPermohonanDokumen from "../../common/PemajuPermohonanDokumen";
import PemajuPermohonanDokumenDefault from "../../common/PemajuPermohonanDokumenDefault";
import PhotoUpload from "../../core/PhotoUpload";

export default {
  name: "BahagianC",

  inject: ['$validator'],

  components: {PhotoUpload, PemajuPermohonanDokumenDefault, PemajuPermohonanDokumen},
  props: {
    pemajuan: {
      type: Object,
      default: () => ({}),
    },

    docConfigs: Array,

    default_docs: Array,

    readonly: {
      default: false,
    },

    maklumat: Object,
  },

  data() {
    return {
      lat: this.pemajuan.latitud,
      lng: this.pemajuan.longitud,
      processing: false,
    };
  },

  methods: {
    updateCoord({lat, lng}) {
      this.lat = lat;
      this.lng = lng;
    },

    saveLatLng() {
      if (this.processing) return;

      Promise.all([
        this.$validator.validate('lat'),
        this.$validator.validate('lng'),
      ]).then((results) => {
        return results.every(t => t);
      })
      .then(passes => {
        if (!passes) {
          return;
        }

        this.processing = true;
        axios.post(`/pemaju/pemajuan/${this.pemajuan.id}/latlng`, {
          lat: this.lat,
          lng: this.lng,
        })
        .then((response) => {
          const pemajuan = JSON.parse(JSON.stringify(this.pemajuan));
          pemajuan.lat = this.lat;
          pemajuan.lng = this.lng;

          this.$emit('update:pemajuan', pemajuan);
          this.processing = false;
          this.$success();
        })
        .catch(() => {
          this.processing = false;
        })
      })
      
    },

    getDoc(config) {
      if (config.multiple) {
        return this.default_docs.filter((doc) => {
          return doc && doc.kod_dokumen == config.kod_dokumen_id;
        })
      }
      return this.default_docs.find((doc) => {
        return doc && doc.kod_dokumen == config.kod_dokumen_id;
      })
    },

    uploadPhoto(attr, {photo, done}) {
      if (!this.maklumat) {
        return;
      }
      const formData = new FormData();

      // append the files to FormData
      formData.append('photo', photo, photo.name);

      // save it
      axios.post(`/pemaju/laporan-7f/${this.maklumat.id}/gambar/${attr}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));

          maklumat[attr] = response.data
          this.$emit('update:maklumat', maklumat);

          done();
        })
        .catch(() => {
          done();
        });
    },

    removePhoto(attr) {
      if (!this.maklumat) {
        return;
      }
      // delete it
      axios.delete(`/pemaju/laporan-7f/${this.maklumat.id}/gambar/${attr}`)
        .then(() => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[attr] = null;
          this.$emit('update:maklumat', maklumat);
        })
        .catch(() => {
        });
    },
  }
}
</script>

<style scoped>

</style>
