<template>
  <div class="card">
    <div class="card-body">

      <div class="alert alert-primary">
        <strong>NOTA:</strong>
        <div>Brosur di dalam Bahasa Melayu adalah Diwajibkan. Jenis iklan lain adalah pilihan.</div>
      </div>

      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="table-responsive">
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th width="1%" class="text-center"></th>
            <th>Jenis Iklan</th>
            <th>Fail</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(jenis_iklan, index) in kodJenisIklanRs" :key="`jenis_iklan_${jenis_iklan.id}`">
            <td>
              <div class="form-check" style="padding: 0;padding:0; text-align:center;"><input type="checkbox" :checked="checkedJenisIklan(jenis_iklan.id)" @click.prevent.stop="toggleJenisIklan(jenis_iklan.id)" :disabled="loading || readonly"></div>
            </td>
            <td>{{ jenis_iklan.keterangan }} <span v-if="jenis_iklan.mandatori" class="label-required">*</span></td>
            <td>
              <file-upload
                type="file" :accept-type="jenis_iklan.mimes"
                :name="`doc_jenis_iklan_${jenis_iklan.id}`"
                class="form-control w-100"
                :class="{'is-invalid': errors.first(`doc_jenis_iklan_${jenis_iklan.id}`)}"
                :doc="getDoc(jenis_iklan)"
                @uploadwithcallback="({file, callback}) => uploadDoc(jenis_iklan, file, callback)"
                @remove="removeDoc(jenis_iklan)"
                v-validate="'required'"
                :readonly="readonly || !checkedJenisIklan(jenis_iklan.id)"
              />
              <div class="text-danger">Hanya dokumen berformat <strong>{{ jenis_iklan.allowed_format ? jenis_iklan.allowed_format.toUpperCase() : '' }}</strong> sahaja dibenarkan</div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianL",
  props: {
    maklumat: Object,
    kodJenisIklanRs: Array,

    kuiri: null,
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    save() {
      this.$success();
    },

    getItem(kod_jenis_iklan_id) {
      return this.maklumat.iklan_rs.find(item => item.kod_jenis_iklan_id == kod_jenis_iklan_id);
    },

    checkedJenisIklan(kod_jenis_iklan_id) {
      return !!this.getItem(kod_jenis_iklan_id);
    },

    toggleJenisIklan(kod_jenis_iklan_id) {
      this.loading = true;

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-l/${kod_jenis_iklan_id}`)
        .then((response) => {
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    getDoc(jenis_iklan) {
      const item = this.getItem(jenis_iklan.id);
      if (item) {
          return item.dokumen;
      }
      return null;
    },

    uploadDoc(iklan, file, clear) {
      if (!this.maklumat || !this.checkedJenisIklan(iklan.id)) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-l/${iklan.id}/upload`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(iklan) {
      if (!this.maklumat || !this.checkedJenisIklan(iklan.id)) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-l/${iklan.id}/upload`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  }
}
</script>

<style scoped>

</style>
