<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact">
        <div class="form-group form-row">

          <label class="col-form-label col-lg-3">1. Jenis Permohonan Seksyen 6A <span
              class="label-required">*</span></label>

          <div class="col-lg-9">
            <div class="form-check form-check-inline">
              <input type="radio"
                id="jenis_permohonan_6a_batal_lesen"
                name="jenis_permohonan_6a"
                class="form-check-input"
                :class="{ 'is-invalid': errors.first('jenis_permohonan_6a') }"
                @input="$emit('update:jenis_permohonan_6a', 'BATAL LESEN')"
                :checked="jenis_permohonan_6a === 'BATAL LESEN'"
                v-validate="'required'"
                :disabled="disabledJenisPermohonan6a"
                value="BATAL LESEN" />
              <label class="form-check-label"
                for="jenis_permohonan_6a_batal_lesen">Batal AP/DL</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio"
                id="jenis_permohonan_6a_bg_80"
                name="jenis_permohonan_6a"
                class="form-check-input"
                :class="{ 'is-invalid': errors.first('jenis_permohonan_6a') }"
                @input="$emit('update:jenis_permohonan_6a', 'PENGELUARAN BG80')"
                :checked="jenis_permohonan_6a === 'PENGELUARAN BG80'"
                :disabled="disabledJenisPermohonan6a"
                value="PENGELUARAN BG80" />
              <label class="form-check-label"
                for="jenis_permohonan_6a_bg_80">Pengeluaran Jaminan Bank (BG) 80%</label>
            </div>
            <div class="form-check form-check-inline">
              <input type="radio"
                id="jenis_permohonan_6a_lulus_p11"
                name="jenis_permohonan_6a"
                class="form-check-input"
                :class="{ 'is-invalid': errors.first('jenis_permohonan_6a') }"
                @input="$emit('update:jenis_permohonan_6a', 'KELULUSAN P11')"
                :checked="jenis_permohonan_6a === 'KELULUSAN P11'"
                :disabled="disabledJenisPermohonan6a"
                value="KELULUSAN P11" />
              <label class="form-check-label"
                for="jenis_permohonan_6a_lulus_p11">Kelulusan P11</label>
            </div>
          </div>
        </div>
        <div class="form-row">
          <div class="col-lg-3">
            <div class="form-group form-row">
              <label class="col-form-label col">2. Jenis Wang Pendahuluan <span class="label-required">*</span></label>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="form-group"
              tabindex="-1">
              <div class="form-check form-check-inline">
                <input type="radio"
                  id="kod_jenis_wp_hda"
                  name="kod_jenis_wp_id"
                  class="form-check-input"
                  :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                  @input="$emit('update:kod_jenis_wp_id', 'T')"
                  :checked="kod_jenis_wp_id === 'T'"
                  v-validate="'required'"
                  value="T" />
                <label class="form-check-label"
                  for="kod_jenis_wp_hda">Tunai</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio"
                  id="kod_jenis_wp_bg"
                  name="kod_jenis_wp_id"
                  class="form-check-input"
                  :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                  @input="$emit('update:kod_jenis_wp_id', 'S')"
                  :checked="kod_jenis_wp_id === 'S'"
                  value="S" />
                <label class="form-check-label"
                  for="kod_jenis_wp_bg">Jaminan Bank (BG)</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio"
                  id="kod_jenis_wp_aa"
                  name="kod_jenis_wp_id"
                  class="form-check-input"
                  :class="{ 'is-invalid': errors.first('kod_jenis_wp_id') }"
                  @input="$emit('update:kod_jenis_wp_id', 'A')"
                  :checked="kod_jenis_wp_id === 'A'"
                  value="A" />
                <label class="form-check-label"
                  for="kod_jenis_wp_aa">Akaun Amanah</label>
              </div>
            </div>
            <br />
            <div class="form-group form-row">
              <label class="col-form-label col-md-3">1. Amaun</label>
              <div class="col-sm-9">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">RM</span>
                  </div>
                  <input type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.first('amaun_nilai') }"
                    name="amaun_nilai"
                    :value="amaun_nilai"
                    @input="$emit('update:amaun_nilai', $event.target.value.toUpperCase())"
                    v-validate="'required|decimal:2'" />
                </div>
              </div>
            </div>
            <section>
              <div class="sub-margin"
                v-if="kod_jenis_wp_id === 'T'">
                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">2. No Akaun HDA</label>
                  <div class="col-md-9">
                    <input type="text"
                      class="form-control"
                      :value="akaunHda.no_akaun"
                      disabled>
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">3. Nama Bank</label>
                  <div class="col-md-9">
                    <input type="text"
                      class="form-control"
                      :value="akaunHda.nama_bank"
                      disabled>
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">4. Cawangan Bank</label>
                  <div class="col-md-9">
                    <input type="text"
                      class="form-control"
                      :value="akaunHda.alamat1_bank"
                      disabled>
                  </div>
                </div>

                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">5. Tarikh HDA Dibuka</label>
                  <div class="col-md-3">
                    <input class="form-control"
                      disabled
                      name="tarikh_dibuka"
                      :value="akaunHda.tarikh_buka">
                  </div>
                </div>
              </div>

              <div class="sub-margin"
                v-if="kod_jenis_wp_id === 'S'">

                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">2. No. Rujukan BG</label>
                  <div class="col-sm-9">
                    <input type="text"
                      class="form-control"
                      disabled
                      :value="jaminanBank.no_rujukan" />
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">3. Nama Bank</label>
                  <div class="col-sm-9">
                    <input type="text"
                      class="form-control"
                      disabled
                      :value="jaminanBank.nama_bank" />
                  </div>
                </div>
                <div class="form-group form-row">
                  <label class="col-form-label col-md-3">4.Tempoh Sah Laku</label>
                  <div class="col-sm-9 form-inline">
                    <label class="mr-2">Dari</label>
                    <input type="text"
                      class="form-control"
                      disabled
                      :value="jaminanBank.tarikh_mula" />
                    <label class="mx-2">Sehingga</label>
                    <input type="text"
                      class="form-control"
                      disabled
                      :value="jaminanBank.tarikh_luput" />
                  </div>
                </div>
              </div>
            </section>

          </div>
        </div>
        <!-- <div class="form-group form-row">
          <label class="col-form-label col-md-3">3. Tarikh Penutupan Akaun <span class="label-required">*</span></label>
          <div class="col-md-3">
            <div class="input-group">
              <datepicker type="text"
                name="tarikh_penutupan_akaun"
                :class="{ 'is-invalid': errors.first('tarikh_penutupan_akaun') }"
                v-model="tarikh_penutupan_akaun"
                v-validate="'required'"
                :value="tarikh_penutupan_akaun"></datepicker>
            </div>
          </div>
        </div> -->
      </section>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],

  props: {
    akaunHda: {
      type: Object
    },

    jaminanBank: {
      type: Object
    },

    disabledJenisPermohonan6a: {
      type: Boolean,
      default: false,
    },

    kod_jenis_wp_id: String,
    jenis_permohonan_6a: String,
    amaun_nilai: String | Number
  },
};
</script>
