<template>
  <div class="card">
    <div class="card-body">
      <section class="form-compact row">
        <div class="col-lg-12">
          <div class="alert alert-warning" v-if="kuiri">
            <strong>Catatan Kuiri:</strong>
            <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
          </div>

          <h6>
              <i>Medan bertanda <img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"> akan dipaparkan dalam cetakan eSPA.</i>
          </h6>

          <div class="form-group row">
            <!-- <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Nama Pemajuan <span class="label-required">*</span></label> -->
            <label class="col-form-label col-md-4" style="white-space: pre-wrap; align-items: center; display: flex;">1.&#9;Nama Pemajuan <span class="label-required">*</span><img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;" data-toggle="tooltip"
                        data-placement="top" title="Medan ini akan dipaparkan dalam cetakan eSPA."></label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.nama" @input="form.nama = $event.target.value.toUpperCase()"
                     :disabled="readonly"
              >
            </div>
          </div>

          <div class="form-group row">
            <!-- <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Nama Fasa <span class="label-required">*</span></label> -->
            <label class="col-form-label col-md-4" style="white-space: pre-wrap; align-items: center; display: flex;">2.&#9;Nama Fasa <span class="label-required">*</span><img src="/img/espa_icon.png" alt="ESPA Icon" class="fas fa-info-circle ml-auto align-middle" style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;" data-toggle="tooltip"
                        data-placement="top" title="Medan ini akan dipaparkan dalam cetakan eSPA."></label>
            <div class="col-md-8">
              <input type="text" class="form-control" :value="form.nama_fasa" @input="form.nama_fasa = $event.target.value.toUpperCase()"
                     :disabled="readonly"
              >
              <div class="text-grey col-form-label-sm">Sila isi maklumat nombor atau nama sahaja tanpa perkataan 'Fasa'. Jika tiada, kosongkan medan ini.</div>
              <!-- <div class="text-danger col-form-label-sm">Jika tiada, kosongkan medan ini. </div> -->
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Kaedah Pemajuan <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="kaedah_pemajuan in filteredKaedahPemajuanRs" :key="`kaedah_pemajuan_${kaedah_pemajuan.id}`">
                <input type="radio" class="form-check-input" v-model="form.kod_kaedah_pemajuan_id" name="kod_kaedah_pemajuan_id"
                       :id="`kaedah_pemajuan_${kaedah_pemajuan.id}`" :value="kaedah_pemajuan.id" @change="form.kod_kaedah_pemajuan_id = kaedah_pemajuan.id; form.kod_jenis_perjanjian_id = null"
                       :disabled="readonly"
                >
                <label class="form-check-label" :for="`kaedah_pemajuan_${kaedah_pemajuan.id}`">{{ kaedah_pemajuan.keterangan }}</label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Jenis Perjanjian <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label" v-if="form.kod_kaedah_pemajuan_id">
              <div class="form-check form-check-inline" v-for="jenis_perjanjian in filteredJenisPerjanjian" :key="`jenis_perjanjian_${jenis_perjanjian.id}`">
                <input type="radio" class="form-check-input" v-model="form.kod_jenis_perjanjian_id" name="kod_jenis_perjanjian_id"
                       :id="`jenis_perjanjian_${jenis_perjanjian.id}`" :value="jenis_perjanjian.id"
                       :disabled="readonly"
                       @click="jenisJadual(jenis_perjanjian.id)"
                >
                <label class="form-check-label" :for="`jenis_perjanjian_${jenis_perjanjian.id}`">{{ jenis_perjanjian.keterangan }}</label>
              </div>
            </div>
            <div class="col-md-8 col-form-label" v-else>
              <div class="form-control-plaintext text-danger">Sila pilih kaedah pemajuan</div>
            </div>
          </div>

          <div class="form-group row" v-if="['H', 'J'].includes(form.kod_jenis_perjanjian_id)">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Jenis Jadual {{ form.kod_jenis_perjanjian_id }} <span class="label-required">*</span></label>
            <div class="col-md-8 col-form-label" v-if="form.kod_jenis_perjanjian_id">
              <div class="form-check form-check-inline" v-for="type_perjanjian in filteredTypePerjanjian" :key="`type_perjanjian_${type_perjanjian.id}`">
                <input type="radio" class="form-check-input" v-model="form.kod_type_perjanjian_id" name="kod_type_perjanjian_id"
                  :id="`type_perjanjian_${type_perjanjian.id}`" :value="type_perjanjian.id"
                  :disabled="readonly"
                  @click="invalidate = false"
                >
                <label class="form-check-label" :for="`type_perjanjian_${type_perjanjian.id}`">{{ type_perjanjian.keterangan }}</label>
              </div>
              <div class="text-danger col-form-label-sm" v-if="invalidate">Ruangan Jenis Jadual {{ form.kod_jenis_perjanjian_id }} diperlukan.</div>
            </div>
            <div class="col-md-8 col-form-label" v-else>
              <label class="text-danger">Sila pilih jenis perjanjian</label>
            </div>
          </div>

          <div class="form-group row" v-if="form.kod_jenis_perjanjian_id == 'H' || form.kod_jenis_perjanjian_id == 'G'">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Jenis Jualan</label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="jenis_jualan in kodJenisJualanRs" :key="`jenis_jualan_${jenis_jualan.id}`" v-show="form.kod_jenis_jualan_id==jenis_jualan.id">
                <input type="radio" class="form-check-input" v-model="form.kod_jenis_jualan_id" name="kod_jenis_jualan_id"
                      :id="`jenis_jualan_${jenis_jualan.id}`" :value="jenis_jualan.id"
                      :disabled="readonly"
                      @click="jenisJualan(jenis_jualan.id)"
                >
                <label class="form-check-label" :for="`jenis_jualan_${jenis_jualan.id}`">{{ jenis_jualan.keterangan }}</label>
              </div>
              <!-- <div class="text-info col-form-label-sm">Jualan Pajakan dan Jualan Pajakan Tanah Wakaf hanya untuk tanah yang mempunyai <strong>"Pemegang Pajak"</strong> sahaja.</div> -->
            </div>
          </div>

          <div class="form-group row" v-if="form.kod_jenis_jualan_id == 2">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Surat Ikatan Wakaf</label>
            <div class="col-md-8">
              <file-upload
                type="file" accept="application/pdf"
                name="doc_surat_wakaf"
                class="form-control w-100"
                :doc="maklumat.doc_surat_wakaf"
                :class="{'is-invalid': errors.first(`doc_surat_wakaf`)}"
                @uploadwithcallback="({file, callback}) => uploadDoc('doc_surat_wakaf', file, callback)"
                @remove="removeDoc('doc_surat_wakaf')"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
            </div>
          </div>

          <div class="form-group row" v-if="form.kod_jenis_perjanjian_id == 'H' || form.kod_jenis_perjanjian_id == 'G'">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">6.&#9;Jenis Jualan Milikan</label>
            <div class="col-md-8 col-form-label">
              <div class="form-check form-check-inline" v-for="jenis_jualan_milikan in kodJenisJualanMilikanRs" :key="`jenis_jualan_milikan_${jenis_jualan_milikan.id}`" v-show="form.kod_jenis_jualan_milikan_id==jenis_jualan_milikan.id">
                <input type="radio" class="form-check-input" v-model="form.kod_jenis_jualan_milikan_id" name="kod_jenis_jualan_milikan_id"
                      :id="`jenis_jualan_milikan_${jenis_jualan_milikan.id}`" :value="jenis_jualan_milikan.id"
                      :disabled="readonly"
                >
                <label class="form-check-label" :for="`jenis_jualan_milikan_${jenis_jualan_milikan.id}`">{{ jenis_jualan_milikan.keterangan }}</label>
              </div>
              <!-- <div class="text-info col-form-label-sm">Milikan Pajakan hanya untuk tanah yang mempunyai <strong>"Pemegang Pajak"</strong> sahaja.</div> -->
            </div>
          </div>

          <div class="form-group row" v-if="form.kod_jenis_jualan_milikan_id == 2">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Tempoh Pajakan Milikan <span class="label-required">*</span></label>
            <div class="col-md-3">
              <input type="number" class="form-control" :class="{'is-invalid': errors.first('tempoh_pajakan_milikan')}" :value="form.tempoh_pajakan_milikan" @input="form.tempoh_pajakan_milikan = $event.target.value.toUpperCase()"
                    :disabled="readonly"
                    v-validate="'numeric'"
              >
            </div>
            <div class="text-danger col-form-label-sm">Sila masukkan nombor sahaja. Contoh: 99</div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">{{ form.kod_jenis_perjanjian_id == 'H' || form.kod_jenis_perjanjian_id == 'G' ? '7' : '5' }}.&#9;Program Pemajuan <span class="label-required">*</span></label>
            <div class="col-md-8">
              <div class="form-check form-check-inline" v-for="program_kemajuan in optionProgramKemajuan" :key="`program_kemajuan_${program_kemajuan.value}`">
                <input
                  :id="`program_kemajuan_${program_kemajuan.value}`"
                  type="radio" name="program_kemajuan"
                  class="form-check-input"
                  :disabled="readonly"
                  v-model="form.program_kemajuan" :value="program_kemajuan.value"
                >
                <label class="form-check-label" :for="`program_kemajuan_${program_kemajuan.value}`">{{ program_kemajuan.text }}</label>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">{{ form.kod_jenis_perjanjian_id == 'H' || form.kod_jenis_perjanjian_id == 'G' ? '8' : '6' }}.&#9;Pengesahan Kuota Bumi <span class="label-required">*</span></label>
            <div class="col-md-8">
              <fieldset role="group" class="form-group">
                <div role="radiogroup" tabindex="-1">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio"
                           :checked="form.pengesahan_kuotabumi == 'Y'"
                           @change="form.pengesahan_kuotabumi = 'Y'" id="pilihan_pengesahan1" name="pilihan_pengesahan"
                           class="custom-control-input">
                    <label class="custom-control-label" for="pilihan_pengesahan1">Saya / Kami <strong><u>BERSUMPAH DAN
                      MENGAKU</u></strong> bahawa sepanjang pengetahuan dan kepercayaan saya / kami, projek ini
                      telah disahkan <strong><u>MEMPUNYAI KUOTA BUMIPUTERA</u></strong> yang diperuntukkan dan diluluskan
                      oleh pihak berkuasa</label>
                  </div>
                </div>
                <br>
                <div class="sub-margin">
                  <div class="form-group form-row">
                    <label class="offset-md-1 col-md-2 col-form-label">Peruntukan Kuota Bumi</label>
                    <div class="col-md-9">
                      <div class="form-check form-check-inline">
                        <input id="pilihan_kuotabumi_peratus" type="radio"
                               name="pilihan_kuotabumi"
                               :disabled="form.pengesahan_kuotabumi != 'Y'" :checked="form.pilihan_kuotabumi == 0"
                               @change="form.pilihan_kuotabumi = 0; form.peratus_kuotabumi = null; form.bilangan_kuotabumi = null;" class="form-check-input">
                        <label for="pilihan_kuotabumi_peratus" class="form-check-label">Peratus</label>
                      </div>
                      <div class="form-check form-check-inline">
                        <input id="pilihan_kuotabumi_bilangan" type="radio" :disabled="form.pengesahan_kuotabumi != 'Y'" :checked="form.pilihan_kuotabumi == 1"
                               name="pilihan_kuotabumi"
                               @change="form.pilihan_kuotabumi = 1; form.peratus_kuotabumi = null; form.bilangan_kuotabumi = null;" class="form-check-input">
                        <label for="pilihan_kuotabumi_bilangan" class="form-check-label">Bilangan</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="offset-md-1 col-md-2 col-form-label">Nilai</label>
                    <div class="col-md-9 form-inline">
                      <input type="text" :disabled="form.pengesahan_kuotabumi != 'Y' || form.pilihan_kuotabumi != 0" v-model="form.peratus_kuotabumi"
                             class="form-control" style="width: 150px">&nbsp;&nbsp;%&nbsp;&nbsp;
                      <input type="text" :disabled="form.pengesahan_kuotabumi != 'Y' || form.pilihan_kuotabumi != 1" v-model="form.bilangan_kuotabumi"
                             class="form-control" style="width: 150px">&nbsp;&nbsp;Unit
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset role="group" class="form-group">
                <div role="radiogroup" tabindex="-1" class="">
                  <div class="custom-control custom-radio custom-control-inline">
                    <input type="radio" :checked="form.pengesahan_kuotabumi == 'N3'"
                           @change="form.pengesahan_kuotabumi = 'N3'" id="pilihan_pengesahan4" name="pilihan_pengesahan"
                           class="custom-control-input">
                    <label class="custom-control-label" for="pilihan_pengesahan4">Saya / Kami <strong><u>BERSUMPAH DAN
                      MENGAKU</u></strong> bahawa sepanjang pengetahuan dan kepercayaan saya / kami, projek ini
                      telah disahkan <strong><u>TIADA KUOTA BUMIPUTERA</u></strong> yang diperuntukkan dan diluluskan oleh
                      pihak berkuasa</label>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">{{ form.kod_jenis_perjanjian_id == 'H' || form.kod_jenis_perjanjian_id == 'G' ? '9' : '7' }}.&#9;Surat Kelulusan Kuota Bumi</label>
            <div class="col-md-8">
              <file-upload
                type="file" accept="application/pdf"
                name="doc_kelulusan_kuotabumi"
                class="form-control w-100"
                :doc="maklumat.doc_kelulusan_kuotabumi"
                :class="{'is-invalid': errors.first(`doc_kelulusan_kuotabumi`)}"
                @uploadwithcallback="({file, callback}) => uploadDoc('doc_kelulusan_kuotabumi', file, callback)"
                @remove="removeDoc('doc_kelulusan_kuotabumi')"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-form-label col-md-4" style="white-space: pre-wrap;">{{ form.kod_jenis_perjanjian_id == 'H' || form.kod_jenis_perjanjian_id == 'G' ? '10' : '8' }}.&#9;Surat Pelepasan Kuota Bumi</label>
            <div class="col-md-8">
              <file-upload
                type="file" accept="application/pdf"
                name="doc_pelepasan_kuotabumi"
                class="form-control w-100"
                :doc="maklumat.doc_pelepasan_kuotabumi"
                :class="{'is-invalid': errors.first(`doc_pelepasan_kuotabumi`)}"
                @uploadwithcallback="({file, callback}) => uploadDoc('doc_pelepasan_kuotabumi', file, callback)"
                @remove="removeDoc('doc_pelepasan_kuotabumi')"
                :readonly="readonly"
              />
              <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianC",
  props: {
    maklumat: Object,
    kodJenisPerjanjianRs: Array,
    kodKaedahPemajuanRs: Array,
    kodSkimProjekRs: Array,
    kodJenisJualanRs: Array,
    kodJenisJualanMilikanRs: Array,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    },
    kodTypePerjanjianRs: Array,
  },

  data() {
    return {
      loading: false,

      form: this.parse(this.maklumat),
      optionProgramKemajuan: [
        {
          value: '1',
          text: 'Ada'
        },
        {
          value: '0',
          text: 'Tiada'
        },
      ],
      invalidate: false,
    }
  },

  created() {
    return this.jenisJadual(this.maklumat.kod_jenis_perjanjian_id);
  },

  methods: {
    parse(maklumat) {
      return {
        nama: maklumat.nama,
        nama_fasa: maklumat.nama_fasa || 'NIL',
        kod_jenis_perjanjian_id: maklumat.kod_jenis_perjanjian_id,
        kod_kaedah_pemajuan_id: maklumat.kod_kaedah_pemajuan_id,
        kod_skim_projek_id: maklumat.kod_skim_projek_id,
        pengesahan_kuotabumi: maklumat.pengesahan_kuotabumi,
        pilihan_kuotabumi: maklumat.pilihan_kuotabumi,
        peratus_kuotabumi: maklumat.peratus_kuotabumi,
        bilangan_kuotabumi: maklumat.bilangan_kuotabumi,
        kod_jenis_jualan_id: maklumat.kod_jenis_jualan_id,
        kod_jenis_jualan_milikan_id: maklumat.kod_jenis_jualan_milikan_id,
        tempoh_pajakan_milikan: maklumat.tempoh_pajakan_milikan,
        program_kemajuan: maklumat.skim_projek ? 1 : maklumat.program_kemajuan,
        kod_type_perjanjian_id: maklumat.kod_type_perjanjian_id,
      };
    },

    save() {
      if (this.loading) {
        return;
      }

      // validate form
      if(['H', 'J'].includes(this.form.kod_jenis_perjanjian_id)) {
        if(!this.form.kod_type_perjanjian_id) {
          this.invalidate = true;
          return;
        }
      }
      this.invalidate = false;

      const request = () => {
        this.loading = true;

        axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/bahagian-c`, this.form)
          .then((response) => {
            this.form = this.parse(JSON.parse(JSON.stringify(response.data)));
            this.$emit('update', response.data);
            this.$success();
          })
          .catch((error) => {
          })
          .then(() => {
            this.loading = false;
          })
      }

      if (this.maklumat.kod_jenis_perjanjian_id !== this.form.kod_jenis_perjanjian_id && this.maklumat.pembangunan_rs.length) {
        swal.fire({
          title: 'ANDA TELAH MELAKUKAN PERUBAHAN PADA JENIS PERJANJIAN ',
          text: 'Sila isi semula maklumat pada Cadangan Pembangunan (Seksyen L)',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#0069d9',
          cancelButtonColor: '#d90014',
          confirmButtonText: 'OK',
          cancelButtonText: 'Batal'
        }).then(confirm => {
          if (confirm.value) {
            request();
          }
        })
        return;
      }

      request();
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },

    jenisJadual(id){
      this.form .kod_jenis_jualan_id=3;
      this.form .kod_jenis_jualan_milikan_id=1;
      var jadual = ['G','H'];
      if(jadual.includes(id)){
          var butiran_tanah_rs  = this.maklumat.butiran_rs;
          var butiran_tanah = [];
          var tuan_tanah = [];
          Object.keys(butiran_tanah_rs).map(function(key) {
            if (butiran_tanah_rs[key].tanah_wakaf === 1) {
              butiran_tanah = butiran_tanah_rs[key];
            }else{
              var tuan_tanah_rs = butiran_tanah_rs[key].tuan_tanah_rs;
              Object.keys(tuan_tanah_rs).map(function(j) {
                if (tuan_tanah_rs[j].kod_jenis_tuan_tanah_id === "BP") {
                  tuan_tanah = tuan_tanah_rs[j];
                }
              });
              // alert("mungkin pemegang pajakan");
            }
          });
          // alert(butiran_tanah.length );
              // console.log(tuan_tanah);
          // console.log(obj);
          if (butiran_tanah.length !== 0) {
            // pajakan tanah wakaf
            this.form .kod_jenis_jualan_id=2;
            this.form .kod_jenis_jualan_milikan_id=2;
          }

          if (tuan_tanah.length !== 0) {
            // pajakan
            this.form .kod_jenis_jualan_id=1;
            this.form .kod_jenis_jualan_milikan_id=2;
          }
      }

      const jadualStrata = ['H', 'J'];
      if(!jadualStrata.includes(id)) {
        this.form.kod_type_perjanjian_id = null;
      }
    },

    jenisJualan(id){
      var jualan = [1,2];
      if(jualan.includes(id)){
        this.form .kod_jenis_jualan_milikan_id=2;
      }else{
        this.form .kod_jenis_jualan_milikan_id=1;
      }
    }
  },

  computed: {
    filteredKaedahPemajuanRs() {
      if (this.maklumat.is_jointventure) {
        return this.kodKaedahPemajuanRs.filter(kaedah => ['JKBJV', 'BKJ 10/90JV'].indexOf(kaedah.singkatan) !== -1);
      }

      return this.kodKaedahPemajuanRs.filter(kaedah => ['JKBJV', 'BKJ 10/90JV'].indexOf(kaedah.singkatan) === -1);
    },

    filteredJenisPerjanjian() {
      if (!this.form.kod_kaedah_pemajuan_id) return [];

      const kaedah = this.filteredKaedahPemajuanRs.find(kaedah => this.form.kod_kaedah_pemajuan_id === kaedah.id);

      if (!kaedah) return [];

      if (['JKBJV', 'JKBS'].indexOf(kaedah.singkatan) !== -1) {
        return this.kodJenisPerjanjianRs.filter(perjanjian => ['G', 'H'].indexOf(perjanjian.id) !== -1);
      }

      return this.kodJenisPerjanjianRs.filter(perjanjian => ['G', 'H'].indexOf(perjanjian.id) === -1);
    },

    filteredTypePerjanjian() {
      if (!this.form.kod_jenis_perjanjian_id) return [];

      return this.kodTypePerjanjianRs.filter(jadual => this.form.kod_jenis_perjanjian_id === jadual.kod_jenis_perjanjian_id);
    },
  }
}
</script>

<style scoped>

</style>
