<template>
  <div class="fileupload" :class="{'has-file': currentFiles.length}">
    <template v-if="readonly && doc.length">
      <div v-for="d in doc" class="d-flex file-container align-items-center">
        <div class="text-truncate">
          <a :href="d.url" target="_blank" :title="d.nama_asal"><i class="fas fa-file-pdf mr-2" /> {{ d.nama_asal }}</a>
        </div>
      </div>
    </template>
    <div v-else-if="readonly && !doc.length" class="d-flex file-container align-items-center">
      <div class="text-truncate">
        Tidak disertakan
      </div>
    </div>
    <div v-else>
      <div v-for="(c, index) in currentFiles" class="d-flex file-container align-items-center">
        <div class="text-truncate">
          {{ c.name }}
        </div>
        <button class="text-dark ml-2" @click.prevent="remove(index)"><i class="fas fa-window-close"></i></button>
      </div>
      <div class="upload-container text-truncate cursor-pointer" @click.prevent="$refs.input.click()">
        <i class="upload-icon fas fa-upload mr-2" /> Muat Naik
      </div>
      <input
        v-bind="$attrs"
        type="file"
        :name="name"
        :accept="acceptType"
        class="input-file"
        ref="input"
        @change="filesChange($event.target.name, $event.target.files)"
      >
      <div ref="placeholder"></div>
    </div>

  </div>
</template>

<script>
  export default {
    inheritAttrs: false,

    props: {
      xClass: 'string',
      doc: Array,
      name: String,

      acceptType: {
        type: String,
        default: 'application/pdf',
      },

      readonly: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        currentFiles: [],
      };
    },

    methods: {
      filesChange(name, files) {
        let index = this.currentFiles.length;
        this.currentFiles.push(files[0]);

        let i = this.$refs.input.cloneNode();
        i.id = `doc-input-${index}`;

        this.$refs.placeholder.append(i);
        this.$refs.input.value = '';
      },

      remove(index) {
        this.$refs.placeholder.childNodes[index].remove();
        this.currentFiles.splice(index, 1);
      }
    },
  }
</script>

<style lang="scss" scoped>
  .fileupload {
    position: relative;
    width: 100px;
    padding: 0 !important;
    height: auto !important;



    &.is-invalid {
      color: red;

      .upload-icon {
        display: none;
      }
    }

    &.form-control {
      .file-container, .upload-container {
        padding: 0.56rem 0.75rem;
      }
    }

    &.has-file {
      .file-container {
        background: #BA68C8;
        color: white !important;
      }
    }
  }
  .input-file {
    opacity: 0; /* invisible but it's there! */
    width: 0;
    height: 0;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    cursor: pointer;
  }
  button {
    display: inline-block;
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    font-family: sans-serif;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
</style>
