<template>
  <tr>
    <td>
      <select
        :class="{'is-invalid': errors.first('kod_jenis_perunding_id') }"
        v-model="form.kod_jenis_perunding_id" class="form-control"
        name="kod_jenis_perunding_id"
        v-validate="'required'"
        data-vv-as="Jenis Firma"
      >
        <option :value="null">Sila Pilih</option>
        <option v-for="perunding in jenisRs" :value="perunding.id">{{ perunding.keterangan }}</option>
      </select>
      <span v-if="errors.first('kod_jenis_perunding_id')" class="invalid-feedback">{{ errors.first('kod_jenis_perunding_id') }}</span>
    </td>
    <td>
      <input
        :class="{'is-invalid': errors.first('nama_perunding') }"
        name="nama_perunding"
        class="form-control" type="text" :value="form.nama_perunding"
        @input="form.nama_perunding = $event.target.value.toUpperCase()"
        @keypress.enter.prevent="add"
        v-validate="'required|max:200'"
        data-vv-as="Nama"
      >
      <span v-if="errors.first('nama_perunding')" class="invalid-feedback">{{ errors.first('nama_perunding') }}</span>
    </td>
    <td>
      <input
        :class="{'is-invalid': errors.first('alamat_perunding') }"
        name="alamat_perunding"
        class="form-control" type="text" :value="form.alamat_perunding"
        @input="form.alamat_perunding = $event.target.value.toUpperCase()"
        @keypress.enter.prevent="add"
        v-validate="'required'"
        data-vv-as="Alamat"
      >
      <span v-if="errors.first('alamat_perunding')" class="invalid-feedback">{{ errors.first('alamat_perunding') }}</span>
    </td>
    <td>
      <input
        :class="{'is-invalid': errors.first('telefon_perunding') }"
        name="telefon_perunding"
        class="form-control" type="text" :value="form.telefon_perunding"
        @input="form.telefon_perunding = $event.target.value.toUpperCase()"
        @keypress.enter.prevent="add"
        v-validate="'required|numeric|length:9,11'"
        data-vv-as="No Telefon"
      >
      <span v-if="errors.first('telefon_perunding')" class="invalid-feedback">{{ errors.first('telefon_perunding') }}</span>
    </td>
    <td>
      <input
        :class="{'is-invalid': errors.first('fax_perunding') }"
        name="fax_perunding"
        class="form-control" type="text" :value="form.fax_perunding"
        @input="form.fax_perunding = $event.target.value.toUpperCase()"
        @keypress.enter.prevent="add"
        v-validate="'numeric|length:9,11'"
        data-vv-as="No Faks"
      >
      <span v-if="errors.first('fax_perunding')" class="invalid-feedback">{{ errors.first('fax_perunding') }}</span>
    </td>
    <td>
      <input
        :class="{'is-invalid': errors.first('emel_perunding') }"
        name="emel_perunding"
        class="form-control" type="text" :value="form.emel_perunding"
        @input="form.emel_perunding = $event.target.value"
        @keypress.enter.prevent="add"
        v-validate="'required|email'"
        data-vv-as="Emel"
      >
      <span v-if="errors.first('emel_perunding')" class="invalid-feedback">{{ errors.first('emel_perunding') }}</span>
    </td>
    <td width="1%">
      <button class="btn btn-sm btn-success" type="button" @click.prevent="add" :disabled="loading"><i class="fas fa-plus"></i></button>
    </td>
  </tr>
</template>

<script>
export default {
  name: "RowTambahButiranFirma",

  props: {
    jenisRs: Array,

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        kod_jenis_perunding_id: null,
        nama_perunding: null,
        alamat_perunding: null,
        telefon_perunding: null,
        fax_perunding: null,
        emel_perunding: null,
      }
    }
  },
  methods: {
    async add() {
      const passes = await this.$validator.validateAll();

      if (passes) {
        this.$emit('add', { form: this.form, callback: this.clear });
      }
    },

    clear() {
      this.form = {
        kod_jenis_perunding_id: null,
        nama_perunding: null,
        alamat_perunding: null,
        telefon_perunding: null,
        fax_perunding: null,
        emel_perunding: null,
      };
      this.$validator.reset();
    },
  }
}
</script>

<style scoped>

</style>
