<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Tempoh Permit (Tahun)</label>
        <div class="col-md-4">
          <input type="text" :value="isLanded ? 2 : 3" name="tempoh-lesen" disabled="disabled" class="form-control bg-light ">
        </div>
      </div>

      <div class="form-group row">
        <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Dokumen Surat Iringan <br><small>&#9;(Perincian Tujuan Permohonan)</small></label>
        <div class="col-md-8">
          <file-upload
            type="file" accept="application/pdf"
            name="doc_iringan_pemaju"
            class="form-control w-100"
            :class="{'is-invalid': errors.first(`doc_iringan_pemaju`)}"
            :doc="maklumat.doc_iringan_pemaju"
            @uploadwithcallback="({file, callback}) => uploadDoc('doc_iringan_pemaju', file, callback)"
            @remove="removeDoc('doc_iringan_pemaju')"
            v-validate="'required'"
            :readonly="readonly"
          />
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorBar from "../../common/ErrorBar";
export default {
  name: "BahagianR",
  components: {ErrorBar},
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,

      form: {
        perakaun: false,
      }
    }
  },

  methods: {
    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },

    save() {
      this.$success();
    },
  },
  computed: {
    isLanded() {
      return ['G', 'I'].indexOf(this.maklumat.kod_jenis_perjanjian_id) !== -1;
    }
  }
}
</script>

<style scoped>

</style>
