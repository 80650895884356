<template>
  <div>
    <div class="alert alert-warning" v-if="kuiri">
      <strong>Catatan Kuiri:</strong>
      <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
    </div>

    <div class="text-right mb-2" v-if="!readonly">
      <button class="btn btn-success" @click.prevent="addBangunan">TAMBAH KOMPONEN</button>
    </div>
    <template v-if="maklumat.pembangunan_rs.length">
      <simple-nav>
        <template #default="{ currentActive, setCurrent }">
          <bangunan
            v-for="(pembangunan, index) in maklumat.pembangunan_rs"
            :no="index + 1"
            :show="currentActive === index"
            :bangunan="pembangunan"
            :key="`bangunan-${pembangunan.id}`"

            @click="setCurrent(currentActive === index ? null : index)"

            @editBangunan="(form, reset) => editBangunan(pembangunan, form, reset)"
            @destroyBangunan="destroyBangunan(pembangunan)"

            :kod-jenis-kediaman-rs="kodJenisKediamanRs"

            :readonly="readonly"
          >
            <template #default>
              <butiran-unit
                :maklumat="maklumat"
                :pembangunan="pembangunan"
                :kod-skim-projek-rs="kodSkimProjekRs"
                :type-permohonan="typePermohonan"
                :kod-kedudukan-lot-rs="KodKedudukanLotRs"
                @uploadExcel="(name, files, setLoading, errcb) => uploadExcel(pembangunan, name, files, setLoading, errcb)"
                @addUnit="(form, callback, errcb) => addUnit(pembangunan, form, callback, errcb)"
                @updateUnit="(nolot_unit, form, callback, errcb) => updateUnit(pembangunan, nolot_unit, form, callback, errcb)"
                @destroyUnit="(nolot_unit) => destroyUnit(pembangunan, nolot_unit)"
                :error-upload="errorsUpload[pembangunan.id]"

                :readonly="readonly"
              >
                <template v-slot:nolot_unit_header>
                  <button v-if="!readonly && hasRowSelected(pembangunan)" type="button" class="btn btn-icon text-danger" style="padding: 0.1rem 0.3rem; font-size: 0.9rem;" @click.prevent="destroyUnitBulk(pembangunan)" title="Hapus Pilihan"><i class="fas fa-trash"></i></button>
                </template>
                <template v-slot:nolot_unit="{ nolotUnit }">
                  <div :key="`row-${nolotUnit.id}`">
                    <input v-if="!readonly" @input.prevent.stop="toggleSelectRow(pembangunan, nolotUnit)" type="checkbox" :checked="isRowSelected(pembangunan, nolotUnit)">
                  </div>
                </template>
              </butiran-unit>
            </template>

          </bangunan>
        </template>
      </simple-nav>

      <div class="text-right" v-if="!readonly">
        <transition name="fade"><span class="text-danger" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
      </div>
    </template>
    <div v-else-if="readonly" class="alert alert-warning">Tiada maklumat bangunan.</div>
    <div v-else class="alert alert-warning">Sila tambah bangunan.</div>

    <bangunan-modal
      :perjanjian="maklumat.jenis_perjanjian"
      :tanah="maklumat.tanah"
      :kod-jenis-kediaman-rs="kodJenisKediamanRs"
    ></bangunan-modal>
  </div>
</template>

<script>
import {jsonToFormData} from "../../../lib/utils";
import BangunanModal from "../../common/Bangunan/BangunanModal";

export default {
  name: "BahagianM",
  components: {BangunanModal},

  props: {
    maklumat: Object,

    kodJenisKediamanRs: Array,


    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    },
    kodSkimProjekRs: Array,
    KodKedudukanLotRs: Array,
  },

  data() {
    return {
      loading: false,

      errorsUpload: {},

      selected: {},
      typePermohonan: 'ap-baru',
    }
  },

  methods: {
    hasRowSelected(pembangunan) {
      return this.selected[pembangunan.id] !== undefined && this.selected[pembangunan.id].length > 0;
    },

    isRowSelected(pembangunan, nolot_unit) {
      return this.selected[pembangunan.id] !== undefined && this.selected[pembangunan.id].indexOf(nolot_unit) !== -1;
    },

    toggleSelectRow(pembangunan, nolot_unit) {
      this.selected[pembangunan.id] = this.selected[pembangunan.id] || [];

      if (this.isRowSelected(pembangunan, nolot_unit)) {
        this.selected[pembangunan.id].splice(this.selected[pembangunan.id].indexOf(nolot_unit), 1);
      } else {
        this.selected[pembangunan.id].push(nolot_unit);
      }

      this.selected = {
        ...this.selected
      };
    },

    addBangunan() {
      vmodal.open('butiranbangunan', {}, (bangunan, {setLoading, close}) => {
        setLoading(true);

        axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan`, bangunan)
          .then((response) => {
            this.$emit('update', response.data);
            close();
          })
          .catch((error) => {
          })
          .then(() => {
            setLoading(false);
          })
      })
    },

    editBangunan(bangunan, form, callback) {
      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}`, form)
        .then(response => {
          this.$emit('update', response.data);
        })
        .catch((error) => {

        })
        .then(() => {
          callback();
        })
    },

    destroyBangunan(bangunan) {
      swalconfirm()
        .then(result => {
          if (result.value) {
            axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}`)
              .then(response => {
                this.$emit('update', response.data);
              })
          }
        });
    },

    uploadExcel(bangunan, name, files, setLoading, errcb) {
      this.clearError(bangunan)

      if (files.length) {
        const formData = new FormData();
        const file = files[0];

        // append the files to FormData
        formData.append('excel', file, file.name);

        setLoading(true)

        axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}/unit/bulk`, formData)
          .then((response) => {
            this.$emit('update', response.data);
          })
          .catch((error) => {
            if (error.response.status === 422) {
              const firstKey = Object.keys(error.response.data.errors)[0];
              this.makeError(bangunan,`Gagal muat naik. Sila pastikan fail dimuat naik mengikut format template. [${error.response.data.errors[firstKey][0]}]`);

              errcb();
            }
            else if (error.response.status === 421) {
              this.makeError(bangunan, error.response.data.message);

              errcb();
            }
            else if (error.response.status >= 500) {
              this.makeError(bangunan, `Gagal muat naik. Sila hubungi pentadbir sistem bagi ralat ini [Kod Ralat : ${error.response.status}].`);

              errcb();
            }
          })
          .then(() => {
            setLoading(false);
          })
      }
    },

    addUnit(bangunan, form, setLoading, errcb) {
      this.clearError(bangunan)

      setLoading(true);

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}/unit`, form)
        .then((response) => {
          this.$emit('update', response.data);
        })
        .catch((error) => {
          if (error.response.status === 421) {
            this.makeError(bangunan, error.response.data.message);

            errcb();
          }
          else if (error.response.status === 422) {
            const firstKey = Object.keys(error.response.data.errors)[0];
            this.makeError(bangunan, `${error.response.data.errors[firstKey][0]}`);

            errcb();
          }
        })
        .then(() => {
          setLoading(false);
        })
    },

    updateUnit(bangunan, nolot_unit, form, {setLoading, close}, errcb) {
      this.clearError(bangunan)

      setLoading(true);

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}/unit/${nolot_unit.id}`, form)
        .then((response) => {
          this.$emit('update', response.data);
          close();
        })
        .catch((error) => {
          const err = {
            ...this.errorsUpload,
          };

          if (error.response.status === 421) {
            this.makeError(bangunan, error.response.data.message);
          }
          else if (error.response.status === 422) {
            const firstKey = Object.keys(error.response.data.errors)[0];
            this.makeError(bangunan, `${error.response.data.errors[firstKey][0]}`);
          }

          errcb();
        })
        .then(() => {
          setLoading(false);
        })
    },

    destroyUnit(bangunan, nolot_unit) {
      // swalconfirm()
      //   .then(result => {
      //     if (result.value) {
      axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}/unit/${nolot_unit.id}`)
        .then(response => {
          this.$emit('update', response.data);
        })
        .catch((error) => {
        })
      // }
      // });
    },

    destroyUnitBulk(bangunan) {
      if (!this.hasRowSelected(bangunan)) {
        return;
      }

      swalconfirm()
        .then(result => {
          if (result.value) {
            axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-m/bangunan/${bangunan.id}/unit/bulk-delete`, {
                selected: this.selected[bangunan.id].map(item => item.id)
              })
              .then(response => {
                this.$emit('update', response.data);

                this.selected[bangunan.id] = [];
              })
              .catch((error) => {
              })
          }
        });
    },

    clearError(bangunan) {
      const err =  {
        ...this.errorsUpload
      };

      delete err[bangunan.id];
      this.errorsUpload = err;
    },

    makeError(bangunan, errMsg) {
      const err = {
        ...this.errorsUpload,
      };

      err[bangunan.id] = errMsg;

      this.errorsUpload = err;
    }
  }
}
</script>

<style scoped>

</style>

