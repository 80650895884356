<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>
    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
       style="display: block; padding-right: 17px;">
    <div class="modal-dialog modal-dialog-scrollable modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLiveLabel">KEMASKINI BUTIRAN PEMBANGUNAN</h5>
          <button type="button" class="close" @click="close" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="alert alert-warning">
            <strong>Nota: </strong><br/>
            Maklumat Kuota Bumiputera & Jualan dikemaskini daripada modul <a :href="`/pemaju/kuota-bumi/${maklumat.pemajuan_id}/borang?redirect=${currentUrl()}`">Pengurusan No. Lot / Unit dan Pembeli</a>. Sekiranya terdapat maklumat yang tidak tepat, pemaju disarankan membuat pengemaskinian maklumat di modul Permohonan Pindaan atau berhubung dengan pegawai di Bahagian Pemantauan Perumahan Swasta (BPPS)
          </div>

          <ul class="ribbon-steps nav">
            <li><a class="active" ref="tab1" data-toggle="tab" role="tab" href="#tab1">1. BUTIRAN PEMAJUAN</a></li>
            <li><a data-toggle="tab" role="tab" href="#tab2">2. BUTIRAN JUALAN</a></li>
            <li><a data-toggle="tab" ref="tab3"  role="tab" href="#tab3">3. BUTIRAN KEMAJUAN KERJA</a></li>
            <li><a data-toggle="tab" role="tab" href="#tab4">4. BUTIRAN KELULUSAN</a></li>
          </ul>

          <section>
            <div class="tab-content mt-4 mx-0">
              <div class="tab-pane fade show active" id="tab1" role="tabpanel">
                <h5>BUTIRAN PEMAJUAN</h5>
                <section class="form-compact px-4">
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Jenis Rumah</label>
                    <div class="col-lg-6">
                      <input type="text" class="form-control" disabled :value="jenis_rumah.keterangan">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Bil Tingkat</label>
                    <div class="col-lg-2">
                      <p type="text" class="form-control-plaintext">{{ pembangunan.bil_tingkat | currency('', ',', 0, '.', 'front', false) }}</p>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Harga Minimum</label>
                    <div class="col-lg-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <input type="text" class="form-control" :value="pembangunan.harga_min | currency('', ',', 2, '.', 'front', false)" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Harga Maksimum</label>
                    <div class="col-lg-6">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text">RM</span>
                        </div>
                        <input type="text" class="form-control" :value="pembangunan.harga_max | currency('', ',', 2, '.', 'front', false)" disabled>
                      </div>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Jumlah Luas Lantai (m.p.s)</label>
                    <div class="col-lg-2">
                      <p type="text" class="form-control-plaintext">{{ pembangunan.luas_tanah | currency('', ',', 2, '.', 'front', false) }}</p>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Jumlah Luas Lot (m.p.s)</label>
                    <div class="col-lg-2">
                      <p type="text" class="form-control-plaintext">{{ pembangunan.luas_binaan | currency('', ',', 2, '.', 'front', false) }}</p>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Bil. Unit Diluluskan</label>
                    <div class="col-lg-2">
                      <p type="text" class="form-control-plaintext">
                        {{ (pembangunan.bil_dilulus || 0) | currency('', ',', 0, '.', 'front', false) }}
                      </p>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Bil. Unit Dimaju</label>
                    <div class="col-lg-2">
                      <input type="text" @keypress.enter.prevent class="form-control" :class="{'is-invalid': errors.first('pembangunan.bil_dimaju')}" name="pembangunan.bil_dimaju" :disabled="isStatusCCC" v-model="pembangunan.bil_dimaju" v-validate="`required|numeric|max_value:${pembangunan.bil_dilulus}`" data-vv-as="Bil Dimaju">
                      <span class="invalid-feedback" v-if="errors.firstRule('pembangunan.bil_dimaju') === 'max_value'">Nilai melebihi bil diluluskan</span>
                      <span class="invalid-feedback" v-else-if="errors.first('pembangunan.bil_dimaju')">{{ errors.first('pembangunan.bil_dimaju') }}</span>
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Bil. Unit Kuota Bumiputera</label>
                    <div class="col-lg-2">
                      <p type="text" class="form-control-plaintext">
                        {{ (pembangunan.bil_kuotabumi || 0) | currency('', ',', 0, '.', 'front', false) }}
                        <!-- {{ pembangunan }} -->
                      </p>
                    </div>
                  </div>
                </section>
              </div>

              <div class="tab-pane fade" id="tab2" role="tabpanel">
                <h5>BUTIRAN JUALAN</h5>
                <section class="form-compact px-4">
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Jumlah Jualan Unit Rumah</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6" style="padding-left: 3rem;">Bumiputera</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual_bumi">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6" style="padding-left: 3rem;">India</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual_india">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6" style="padding-left: 3rem;">Cina</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual_cina">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6" style="padding-left: 3rem;">Lain-lain (Termasuk Syarikat Tempatan)</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual_lain">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6" style="padding-left: 3rem;">Warga Asing (Termasuk Syarikat Asing)</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual_asing">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6" style="padding-left: 3rem;">Bil Unit Tuan Tanah</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.tuan_tanah">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Jumlah Bilangan Unit Kuota Bumiputera Dijual</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.bil_dijual_kuotabumi">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Tarikh Perjanjian Jual Beli Yang Pertama Ditandatangani</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="pembangunan.tarikh_pjb">
                    </div>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">Bilangan Pembeli Tunai</label>
                    <div class="col-lg-2">
                      <input type="text" class="form-control" disabled :value="jualan.beli_tunai">
                    </div>
                  </div>
                </section>
              </div>

              <div class="tab-pane fade" id="tab3" role="tabpanel">
                <h5>BUTIRAN KEMAJUAN KERJA</h5>

                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                    <th colspan="3"></th>
                    <th colspan="3">Bil Unit</th>
                    </thead>
                    <thead>
                    <th colspan="3"></th>
                    <th>Dilulus</th>
                    <th>Dimaju</th>
                    <th>Siap</th>
                    </thead>
                    <tbody>
                    <tr>
                      <td colspan="6">1. KERJA BINAAN BANGUNAN</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>a)</td>
                      <td>Kerja Meratakan Tapak</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_1a') }" name="kemajuan.bilsiap_1a" v-model="kemajuan.bilsiap_1a" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_1a') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_1a')">{{ errors.first('kemajuan.bilsiap_1a') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>b)</td>
                      <td>Kerja-Kerja Asas dan Rasuk</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_1b') }" name="kemajuan.bilsiap_1b" v-model="kemajuan.bilsiap_1b" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_1b') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_1b')">{{ errors.first('kemajuan.bilsiap_1b') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>c)</td>
                      <td>Rangka Konkrit Tetulang</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_1c') }" name="kemajuan.bilsiap_1c" v-model="kemajuan.bilsiap_1c" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_1c') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_1c')">{{ errors.first('kemajuan.bilsiap_1c') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>d)</td>
                      <td>Lantai dan Dinding</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_1d') }" name="kemajuan.bilsiap_1d" v-model="kemajuan.bilsiap_1d" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_1d') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_1d')">{{ errors.first('kemajuan.bilsiap_1d') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>e)</td>
                      <td>Memasang Bumbung, Pendawaian Elektrik dan Saluran Paip Dalaman, Penyaluran serta Pengkabelan Telefon Dalaman</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_1e') }" name="kemajuan.bilsiap_1e" v-model="kemajuan.bilsiap_1e" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_1e') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_1e')">{{ errors.first('kemajuan.bilsiap_1e') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>f)</td>
                      <td>Pelepaan, Kemasan dan Pemasangan Alat-Alat Kelengkapan</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_1f') }" name="kemajuan.bilsiap_1f" v-model="kemajuan.bilsiap_1f" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_1f') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_1f')">{{ errors.first('kemajuan.bilsiap_1f') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="6">2. KERJA BINAAN INFRASTRUKTUR</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>a)</td>
                      <td>Sistem Kerja Pembentungan</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_2a') }" name="kemajuan.bilsiap_2a" v-model="kemajuan.bilsiap_2a" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_2a') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_2a')">{{ errors.first('kemajuan.bilsiap_2a') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>b)</td>
                      <td>Kerja Perparitan</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_2b') }" name="kemajuan.bilsiap_2b" v-model="kemajuan.bilsiap_2b" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_2b') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_2b')">{{ errors.first('kemajuan.bilsiap_2b') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>c)</td>
                      <td>Kerja Pembinaan Jalan</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_2c') }" name="kemajuan.bilsiap_2c" v-model="kemajuan.bilsiap_2c" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_2c') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_2c')">{{ errors.first('kemajuan.bilsiap_2c') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>d)</td>
                      <td>Sistem Bekalan Air</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_2d') }" name="kemajuan.bilsiap_2d" v-model="kemajuan.bilsiap_2d" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_2d') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_2d')">{{ errors.first('kemajuan.bilsiap_2d') }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>e)</td>
                      <td>Sistem Bekalan Elektrik Luaran</td>
                      <td><input type="text" class="form-control" disabled :value="(pembangunan.bil_dilulus || 0)"></td>
                      <td><input type="text" class="form-control" disabled :value="pembangunan.bil_dimaju"></td>
                      <td>
                        <input type="text" class="form-control" :class="{ 'is-invalid': errors.first('kemajuan.bilsiap_2e') }" name="kemajuan.bilsiap_2e" v-model="kemajuan.bilsiap_2e" @keypress.enter.prevent v-validate="`required|numeric|max_value:${pembangunan.bil_dimaju}`" data-vv-as="Bil Disiap">
                        <span class="invalid-feedback" v-if="errors.firstRule('kemajuan.bilsiap_2e') === 'max_value'">Nilai melebihi bil dimaju</span>
                        <span class="invalid-feedback" v-else-if="errors.first('kemajuan.bilsiap_2e')">{{ errors.first('kemajuan.bilsiap_2e') }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                  <br>
                </div>
              </div>

              <div class="tab-pane fade" id="tab4" role="tabpanel">

                <h5>BUTIRAN KELULUSAN</h5>
                <section class="form-compact px-4 mb-4">
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">1. Pemajuan Berstatus CCC</label>
                  </div>
                  <div class="form-group form-row" v-if="shouldFullCCC || shouldPartialCCC">
                    <label class="col-form-label col" style="white-space: pre-wrap;">&#9;a. Tarikh CCC Dikeluarkan</label>
                  </div>

                  <section class="ml-lg-5">
                    <div class="row" v-if="shouldFullCCC || pembangunan.tarikh_pbt_ccc || pembangunan.dokumen_ccc">
                      <div class="col-lg-6">
                        <div class="form-group form-row">
                          <label class="col-form-label col">i. Keseluruhan</label>
                          <div class="col">
                            <datepicker
                              name="pembangunan.tarikh_pbt_ccc"
                              :class="{'is-invalid': errors.first(`pembangunan.tarikh_pbt_ccc`)}"
                              v-model="pembangunan.tarikh_pbt_ccc"
                              v-validate="!!pembangunan.dokumen_ccc ? 'required' : ''"
                              data-vv-as="Tarikh CCC"
                              :readonly="readonly"
                            ></datepicker>
                            <div v-if="errors.first(`pembangunan.tarikh_pbt_ccc`)" class="text-danger text-sm mt-1">{{ errors.first(`pembangunan.tarikh_pbt_ccc`) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group form-row">
                          <label class="col-form-label col">
                            Muat Naik Dokumen<br>
                            <span class="text-sm text-info">Sila muat naik dokumen berformat .pdf</span>
                          </label>
                          <div class="col">
                            <div class="d-flex">
                              <file-upload
                                type="file" accept="application/pdf"
                                :name="`dokumen_ccc`"
                                class="form-control flex-grow-1 mr-2"
                                :class="{'is-invalid': errors.first(`dokumen_ccc`)}"
                                :doc="pembangunan.dokumen_ccc"
                                @uploadwithcallback="({file, callback}) => uploadDoc('dokumen_ccc', file, callback)"
                                @remove="removeDoc('dokumen_ccc')"
                                v-validate="!!pembangunan.tarikh_pbt_ccc ? 'required' : ''"
                                data-vv-as="Dokumen CCC"
                                :readonly="readonly"
                              />
                            </div>
                            <div v-if="errors.first(`dokumen_ccc`)" class="text-danger text-sm mt-1">{{ errors.first(`dokumen_ccc`) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <section v-if="shouldPartialCCC">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group form-row">
                            <label class="col-form-label col">{{ shouldFullCCC ? 'ii': 'i' }}. Berperingkat</label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row mb-0">
                        <label class="col-form-label col-3 font-weight-bold">Tarikh</label>
                        <label class="col-form-label col-2 font-weight-bold">Bil Unit</label>
                        <label class="col-form-label col-7">
                          <strong>Muat Naik Dokumen</strong><br>
                          <span class="text-sm text-info">Sila muat naik dokumen berformat .pdf</span>
                        </label>
                      </div>
                      <div class="form-group row" v-for="peringkat in pembangunan.cccperingkat_rs">
                        <p class="font-control-plaintext col-3">{{ peringkat.tarikh }}</p>
                        <p class="font-control-plaintext col-2">{{ peringkat.bil_unit }}</p>
                        <p class="font-control-plaintext col-6"><a v-if="peringkat.dokumen" :href="peringkat.dokumen.url" target="_blank">{{ peringkat.dokumen.nama_asal }}</a></p>
                        <div class="col-1">
                          <button v-if="!readonly" class="btn btn-sm btn-danger" type="button" @click.prevent="removePeringkat('cccperingkat', peringkat)"><i class="fas fa-trash"></i></button>
                        </div>
                      </div>

                      <section v-if="!readonly">
                        <div class="alert alert-danger" v-if="cccperingkat_err">{{ cccperingkat_err }}</div>
                        <tambah-butiran-siap-berperingkat
                          jenis-siap="CCC"
                          :max-tarikh="pembangunan.tarikh_pbt_ccc"
                          :current-siap="currentSiap"
                          :bil-dilulus="pembangunan.bil_dilulus"
                          :bil-dimaju="parseInt(pembangunan.bil_dimaju)"
                          @add="({ form, callback }) => tambahPeringkat('cccperingkat', form, callback)"
                        ></tambah-butiran-siap-berperingkat>
                      </section>

                    </section>
                  </section>
                  <br>
                  <div class="form-group form-row">
                    <label class="col-form-label col-lg-6">2. Pemajuan Berstatus CFO</label>
                  </div>
                  <div class="form-group form-row">
                    <label class="col-form-label col" style="white-space: pre-wrap;">&#9;a. Tarikh Penerimaan Borang E oleh Pihak Berkuasa Tempatan</label>
                    <div class="col" style="max-width: 15rem;">
                      <datepicker
                        :class="{'is-invalid': errors.first(`pembangunan.tarikh_pbt_e`)}"
                        name="pembangunan.tarikh_pbt_e"
                        v-model="pembangunan.tarikh_pbt_e"
                      ></datepicker>
                    </div>
                  </div>

                  <div class="form-group row" v-if="shouldFullCCC || shouldPartialCCC">
                    <label class="col-form-label col" style="white-space: pre-wrap;">&#9;b. Tarikh CFO Dikeluarkan</label>
                  </div>

                  <section class="ml-lg-5">
                    <div class="row" v-if="shouldFullCCC">
                      <div class="col-lg-6">
                        <div class="form-group form-row">
                          <label class="col-form-label col">i. Keseluruhan</label>
                          <div class="col">
                            <datepicker
                              :class="{'is-invalid': errors.first(`pembangunan.tarikh_pbt_cf`)}"
                              name="pembangunan.tarikh_pbt_cf"
                              v-model="pembangunan.tarikh_pbt_cf"
                              v-validate="!!pembangunan.dokumen_cf ? 'required' : ''"
                              data-vv-as="Tarikh CF"
                            ></datepicker>
                            <div v-if="errors.first(`pembangunan.tarikh_pbt_cf`)" class="text-danger text-sm mt-1">{{ errors.first(`pembangunan.tarikh_pbt_cf`) }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group form-row">
                          <label class="col-form-label col">
                            Muat Naik Dokumen<br>
                            <span class="text-sm text-info">Sila muat naik dokumen berformat .pdf</span>
                          </label>
                          <div class="col">
                            <div class="d-flex">
                              <file-upload
                                type="file" accept="application/pdf"
                                :name="`dokumen_cf`"
                                class="form-control flex-grow-1 mr-2"
                                :class="{'is-invalid': errors.first(`dokumen_cf`)}"
                                :doc="pembangunan.dokumen_cf"
                                @uploadwithcallback="({file, callback}) => uploadDoc('dokumen_cf', file, callback)"
                                @remove="removeDoc('dokumen_cf')"
                                data-vv-as="Dokumen CF"
                                v-validate="!!pembangunan.tarikh_pbt_cf ? 'required' : ''"
                                :readonly="readonly"
                              />
                            </div>
                            <div v-if="errors.first(`dokumen_cf`)" class="text-danger text-sm mt-1">{{ errors.first(`dokumen_cf`) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <section v-if="shouldPartialCCC">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-group form-row">
                            <label class="col-form-label col">{{ shouldFullCCC ? 'ii' : 'i'}}. Berperingkat</label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group row mb-0">
                        <label class="col-form-label col-3 font-weight-bold">Tarikh</label>
                        <label class="col-form-label col-2 font-weight-bold">Bil Unit</label>
                        <label class="col-form-label col-7">
                          <strong>Muat Naik Dokumen</strong><br>
                          <span class="text-sm text-info">Sila muat naik dokumen berformat .pdf</span>
                        </label>
                      </div>
                      <div class="form-group row" v-for="peringkat in pembangunan.cfperingkat_rs">
                        <p class="font-control-plaintext col-3">{{ peringkat.tarikh }}</p>
                        <p class="font-control-plaintext col-2">{{ peringkat.bil_unit }}</p>
                        <p class="font-control-plaintext col-6"><a v-if="peringkat.dokumen" :href="peringkat.dokumen.url" target="_blank">{{ peringkat.dokumen.nama_asal }}</a></p>
                        <div class="col-1">
                          <button class="btn btn-sm btn-danger" type="button" @click.prevent="removePeringkat('cfperingkat', peringkat)"><i class="fas fa-trash"></i></button>
                        </div>
                      </div>

                      <section v-if="!readonly">
                        <div class="alert alert-danger" v-if="cfperingkat_err">{{ cfperingkat_err }}</div>
                        <tambah-butiran-siap-berperingkat
                          jenis-siap="CFO"
                          :max-tarikh="pembangunan.tarikh_pbt_cf"
                          :current-siap="currentSiap"
                          :bil-dilulus="pembangunan.bil_dilulus"
                          :bil-dimaju="parseInt(pembangunan.bil_dimaju)"
                          @add="({ form, callback }) => tambahPeringkat('cfperingkat', form, callback)"
                        ></tambah-butiran-siap-berperingkat>
                      </section>
                    </section>
                  </section>

                  <div class="form-group form-row" v-if="shouldPartialCCC">
                    <label class="col-form-label col" style="white-space: pre-wrap;">&#9;c. Tarikh Sijil Layak Menduduki Sementara Dikeluarkan (TCFO)</label>
                    <div class="col">
                      <daterangepicker
                        :from.sync="pembangunan.tarikh_pbt_tcf"
                        :to.sync="pembangunan.tarikh_pbt_tcf_sah"
                      ></daterangepicker>
                    </div>
                  </div>
                  <br>
                  <div class="form-row">
                    <div class="col-lg-6">
                      <div class="form-group form-row">
                        <label class="col-form-label col">3. Tarikh Lulus Hakmilik Individu / Strata</label>
                        <div class="col" style="max-width: 15rem;">
                          <datepicker
                            :class="{'is-invalid': errors.first(`pembangunan.tarikh_pbt_hmsi`)}"
                            name="pembangunan.tarikh_pbt_hmsi"
                            v-model="pembangunan.tarikh_pbt_hmsi"
                          ></datepicker>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-row" v-if="shouldFullCCC">
                    <div class="col-lg-6">
                      <div class="form-group form-row">
                        <label class="col-form-label col">4. Tarikh VP Dikeluarkan</label>
                        <div class="col" style="max-width: 15rem;">
                          <datepicker
                            :class="{'is-invalid': errors.first(`pembangunan.tarikh_pbt_vp`)}"
                            name="pembangunan.tarikh_pbt_vp"
                            v-model="pembangunan.tarikh_pbt_vp"
                          ></datepicker>
                          <div v-if="errors.first(`pembangunan.tarikh_pbt_vp`)" class="text-danger text-sm mt-1">{{ errors.first(`pembangunan.tarikh_pbt_vp`) }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-group form-row">
                        <label class="col-form-label col">
                          Muat Naik Dokumen<br>
                          <span class="text-sm text-info">Sila muat naik dokumen berformat .pdf</span>
                        </label>
                        <div class="col">
                          <div class="d-flex">
                            <file-upload
                              type="file" accept="application/pdf"
                              :name="`dokumen_vp`"
                              class="form-control flex-grow-1 mr-2"
                              :class="{'is-invalid': errors.first(`dokumen_vp`)}"
                              :doc="pembangunan.dokumen_vp"
                              @uploadwithcallback="({file, callback}) => uploadDoc('dokumen_vp', file, callback)"
                              @remove="removeDoc('dokumen_vp')"
                              data-vv-as="Dokumen VP"
                              v-validate="!!pembangunan.tarikh_pbt_vp ? 'required' : ''"
                              :readonly="readonly"
                            />
                          </div>

                          <div v-if="errors.first(`dokumen_vp`)" class="text-danger text-sm mt-1">{{ errors.first(`dokumen_vp`) }}</div>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </section>

        </div>
        <div class="modal-footer">
          <div class="text-right">
            <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>

            <button v-if="!readonly" type="button" class="btn btn-primary mr-2" :disabled="saving" @click.prevent="save">Simpan</button>

            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import TambahButiranSiapBerperingkat from "../form/laporan-7f/components/TambahButiranSiapBerperingkat";
import { isBefore, parse } from "date-fns";

export default {
    name: "Laporan7fKemaskiniModal",
    components: {TambahButiranSiapBerperingkat},
    mounted() {
      vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
    },

    props: {
      readonly: {
        type: Boolean,
        default: false,
      },

      pemajuan: {
        type: Object,
      }
    },

    data() {
      return {
        modalId: 'laporan7fkemaskini',
        saving: false,
        maklumat: {},
        jenis_rumah: {},
        pembangunan: {},
        jualan: {},
        kemajuan: {},
        cccperingkat_err: null,
        cfperingkat_err: null,
        response: () => {
        },
      }
    },

    methods: {
      onOpen({ maklumat, jenis_rumah, pemajuan, pembangunan, jualan, kemajuan }, callback) {
        this.maklumat = maklumat;
        this.jenis_rumah = jenis_rumah;
        this.cccperingkat_err = null;
        this.cfperingkat_err = null;

        this.pembangunan = JSON.parse(JSON.stringify(pembangunan));
        this.jualan = JSON.parse(JSON.stringify(jualan));
        this.kemajuan = JSON.parse(JSON.stringify(kemajuan));
        this.response = callback;
      },

      onClose() {
      },

      close() {
        vmodal.close(this.modalId);
      },

      validate() {
        if (this.$validator.errors.first('pembangunan.bil_dimaju')) {
          $(this.$refs.tab1).tab('show');
          return;
        }

        if (
          this.$validator.errors.first('kemajuan.bilsiap_1a') ||
          this.$validator.errors.first('kemajuan.bilsiap_1b') ||
          this.$validator.errors.first('kemajuan.bilsiap_1c') ||
          this.$validator.errors.first('kemajuan.bilsiap_1d') ||
          this.$validator.errors.first('kemajuan.bilsiap_1e') ||
          this.$validator.errors.first('kemajuan.bilsiap_1f') ||
          this.$validator.errors.first('kemajuan.bilsiap_2a') ||
          this.$validator.errors.first('kemajuan.bilsiap_2b') ||
          this.$validator.errors.first('kemajuan.bilsiap_2c') ||
          this.$validator.errors.first('kemajuan.bilsiap_2d') ||
          this.$validator.errors.first('kemajuan.bilsiap_2e')
        ) {
          $(this.$refs.tab3).tab('show');
          return;
        }

        // if (this.) {
        //   $(this.$refs.tab3).tab('show');
        //   return;
        // }
      },

      async save() {
        this.$validator.errors.clear();
        const passes = await this.$validator.validateAll();
        if (!passes) {
          this.validate();
          return;
        }

        if (this.maxCCCPeringkatDate && this.pembangunan.tarikh_pbt_ccc && isBefore(parse(this.pembangunan.tarikh_pbt_ccc, 'dd/MM/yyyy', Date.now()), this.maxCCCPeringkatDate)) {
          this.$validator.errors.add({
            field: 'pembangunan.tarikh_pbt_ccc',
            msg: 'Tarikh CCC mesti melebihi tarikh CCC Peringkat'
          });

          return;
        }

        if (this.maxCFPeringkatDate && this.pembangunan.tarikh_pbt_cf && isBefore(parse(this.pembangunan.tarikh_pbt_cf, 'dd/MM/yyyy', Date.now()), this.maxCFPeringkatDate)) {
          this.$validator.errors.add({
            field: 'pembangunan.tarikh_pbt_cf',
            msg: 'Tarikh CF mesti melebihi tarikh CF Peringkat'
          });

          return;
        }

        if (this.pembangunan.tarikh_pbt_vp && this.pembangunan.tarikh_pbt_ccc && isBefore(parse(this.pembangunan.tarikh_pbt_vp, 'dd/MM/yyyy', Date.now()), parse(this.pembangunan.tarikh_pbt_ccc, 'dd/MM/yyyy', Date.now()))) {
          this.$validator.errors.add({
            field: 'pembangunan.tarikh_pbt_vp',
            msg: 'Tarikh VP mesti melebihi tarikh CCC'
          });

          return;
        }

        if (this.pembangunan.tarikh_pbt_vp && this.pembangunan.tarikh_pbt_cf && isBefore(parse(this.pembangunan.tarikh_pbt_vp, 'dd/MM/yyyy', Date.now()), parse(this.pembangunan.tarikh_pbt_cf, 'dd/MM/yyyy', Date.now()))) {
          this.$validator.errors.add({
            field: 'pembangunan.tarikh_pbt_vp',
            msg: 'Tarikh VP mesti melebihi tarikh CCC'
          });

          return;
        }

        this.saving = true;
        axios.put(`/pemaju/laporan-7f/${this.pembangunan.kemajuan_7f_id}/pembangunan/${this.pembangunan.maklumat7f_id}`, {
          pembangunan: this.pembangunan,
          kemajuan: this.kemajuan,
          jualan: this.jualan,
        })
          .then((response) => {
            const { pembangunan, jualan, kemajuan } = response.data;

            this.pembangunan = JSON.parse(JSON.stringify(pembangunan));
            this.jualan = JSON.parse(JSON.stringify(jualan));
            this.kemajuan = JSON.parse(JSON.stringify(kemajuan));

            this.response({ pembangunan, jualan, kemajuan })

            this.$success();

            this.saving = false;
          })
          .catch(() => {
            this.saving = false;
          });
      },

      getDocs(pembangunan, attr) {
        if (!pembangunan[attr]) {
          return [];
        }

        return pembangunan[attr].map(doc => doc.dokumen);
      },

      uploadDoc(attr, file, callback) {
        if (!this.pembangunan) {
          return;
        }
        this.$validator.errors.remove(attr);

        const pembangunan = JSON.parse(JSON.stringify(this.pembangunan));

        const formData = new FormData();

        // append the files to FormData
        formData.append('doc', file, file.name);

        // save it
        axios.post(`/pemaju/laporan-7f/pembangunan/${pembangunan.id}/dokumen/${attr}`, formData)
          .then((response) => {
            pembangunan[attr] = response.data;
            pembangunan.status = 0;

            this.pembangunan = JSON.parse(JSON.stringify(pembangunan));

            this.response({ pembangunan })
          })
          .catch((error) => {

            if (error.response.status === 422) {
              const errors = error.response.data.errors;
              const k = Object.keys(errors);
              this.$validator.errors.add({ field: attr, msg: errors[k[0]][0]});
            }
            callback();
          });
      },

      removeDoc(attr) {
        if (!this.pembangunan) {
          return;
        }

        const pembangunan = JSON.parse(JSON.stringify(this.pembangunan));

        // save it
        axios.delete(`/pemaju/laporan-7f/pembangunan/${pembangunan.id}/dokumen/${attr}`)
          .then(() => {
            pembangunan[attr] = null;
            pembangunan.status = 0;

            this.pembangunan = JSON.parse(JSON.stringify(pembangunan));

            this.response({ pembangunan })
          })
          .catch(() => {

          });
      },

      tambahPeringkat(key, form, callback) {
        if (!this.pembangunan) {
          return;
        }

        this.cccperingkat_err = null;
        this.cfperingkat_err = null;


        const attr = `${key}_rs`;

        // check bil unit yang dimasukkan mesti lebih kecil drpt total ccc peringkat + cf peringkat + input
        const pembangunan = JSON.parse(JSON.stringify(this.pembangunan));

        const formData = new FormData();

        // append the files to FormData
        formData.append('tarikh', form.tarikh);
        formData.append('bil_unit', form.bil_unit);
        if (form.dokumen) {
          formData.append('dokumen', form.dokumen, form.dokumen.name);
        }

        // save it
        axios.post(`/pemaju/laporan-7f/pembangunan/${pembangunan.id}/peringkat/${key}`, formData)
          .then((response) => {
            pembangunan[attr] = response.data;
            pembangunan.status = 0;

            this.pembangunan = JSON.parse(JSON.stringify(pembangunan));

            callback();

            this.response({ pembangunan })
          })
          .catch((error) => {
            if (error.response.status === 422) {
              const response = error.response.data;
              const keys = Object.keys(response.errors);
              this[`${key}_err`] = response.errors[keys[0]][0];
            }
          });
      },

      removePeringkat(attr, peringkat) {
        if (!this.pembangunan) {
          return;
        }

        const pembangunan = JSON.parse(JSON.stringify(this.pembangunan));

        // save it
        axios.delete(`/pemaju/laporan-7f/pembangunan/${pembangunan.id}/peringkat/${attr}/${peringkat.id}`)
          .then((response) => {
            pembangunan[`${attr}_rs`] = response.data;
            pembangunan.status = 0;

            this.pembangunan = JSON.parse(JSON.stringify(pembangunan));

            this.response({ pembangunan })
          })
          .catch(() => {

          });
      },

      currentUrl() {
        return encodeURIComponent(window.location.pathname + '#bahagian-b');
      }
    },

    computed: {
      isStatusCCC() {
        return this.pemajuan.status_projek == 5
        || this.pemajuan.status_projek == 7
      },

      isOpen() {
        return this.$root.openedModals.indexOf(this.modalId) !== -1
      },

      shouldPartialCCC() {
        const attrs = [
          'bilsiap_1a',
          'bilsiap_1b',
          'bilsiap_1c',
          'bilsiap_1d',
          'bilsiap_1e',
          'bilsiap_1f',
          'bilsiap_2a',
          'bilsiap_2b',
          'bilsiap_2c',
          'bilsiap_2d',
          'bilsiap_2e',
        ];

        return this.pembangunan.bil_dimaju > 0 && attrs.every(attr => this.kemajuan[attr] == this.pembangunan.bil_dimaju);
      },

      shouldFullCCC() {
        const attrs = [
          'bilsiap_1a',
          'bilsiap_1b',
          'bilsiap_1c',
          'bilsiap_1d',
          'bilsiap_1e',
          'bilsiap_1f',
          'bilsiap_2a',
          'bilsiap_2b',
          'bilsiap_2c',
          'bilsiap_2d',
          'bilsiap_2e',
        ];

        return this.isStatusCCC || (this.pembangunan.bil_dilulus > 0 && attrs.every(attr => this.kemajuan[attr] == this.pembangunan.bil_dilulus));
      },

      currentSiap() {
        const current_ccc_siap = this.pembangunan.cccperingkat_rs.reduce((total, item) => {
          return total + parseInt(item.bil_unit);
        }, 0);

        const current_cf_siap = this.pembangunan.cfperingkat_rs.reduce((total, item) => {
          return total + parseInt(item.bil_unit);
        }, 0);

        return current_ccc_siap + current_cf_siap;
      },

      maxCCCPeringkatDate() {
        return this.pembangunan.cccperingkat_rs.reduce((total, item) => {
          const d = parse(item.tarikh, 'dd/MM/yyyy', Date.now())

          if (!total) {
            return d;
          }

          if (isBefore(total, d)) {
            return d;
          }

          return total;
        }, null);
      },

      maxCFPeringkatDate() {
        return this.pembangunan.cfperingkat_rs.reduce((total, item) => {
          const d = parse(item.tarikh, 'dd/MM/yyyy', Date.now())

          if (!total) {
            return d;
          }

          if (isBefore(total, d)) {
            return d;
          }

          return total;
        }, null);
      },
    },


  }
</script>

<style scoped>

</style>
