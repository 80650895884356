<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps">
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            href="#bahagian-a"
          >A. MAKLUMAT PEMAJU PERUMAHAN</a>
        </li>
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            href="#bahagian-b"
          >B. SALINAN DOKUMEN SOKONGAN</a>
        </li>
        <li v-if="!readonly">
        <a
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            href="#bahagian-c"
          >C. PERAKUAN PEMAJU PERUMAHAN</a>
        </li>
      </ul>
      <div class="tab-content mt-4">
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-a')}"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN MAKLUMAT PEMAJU</h4>
              <bahagian-a
                :pemaju="pemaju"
                :nama_juruaudit.sync="nama_juruaudit"
                :no_rujukan_suratwarta.sync="no_rujukan_suratwarta"

                :pemaju_nama_pegawai.sync="pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="pemaju_no_bimbit"
                :pemaju_email.sync="pemaju_email"

                :tarikh_akhir_kewangan="default_tarikh_akhir_kewangan"

                :readonly="readonly"
              ></bahagian-a>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-b')}"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">SALINAN DOKUMEN SOKONGAN</h4>
              <maklumat-dokumen-sokongan
                :doc-configs="docConfigs"
                :default_docs="default_docs"
                :readonly="readonly"
              ></maklumat-dokumen-sokongan>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-c')}"
          id="bahagian-c"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">PERAKUAN PEMAJU PERUMAHAN</h4>
              <bahagian-c
                :pengesahan.sync="pengesahan"
                :pengarah="pengarah"
                @submit="submit"
                :readonly="readonly"
                :tarikh_hantar="default_tarikh_hantar"
              ></bahagian-c>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BahagianA from "./BahagianA";
  import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";
  import BahagianC from "./BahagianC";

  export default {
    components: {BahagianC, MaklumatDokumenSokongan, BahagianA},

    props: {
      pemaju: {
        type: Object
      },

      pengarah: {
        type: Object
      },

      default_tarikh_akhir_kewangan: String,
      default_nama_juruaudit: String,
      default_no_rujukan_suratwarta: String,

      default_pemaju_nama_pegawai: String,
      default_pemaju_no_pejabat: String,
      default_pemaju_no_bimbit: String,
      default_pemaju_email: String,

      default_tarikh_hantar: String,

      docConfigs: Array,

      default_docs: Array,

      errorBag: {
        type: Object
      },

      readonly: {
        type: Boolean,
        default: false,
      },
    },

    created() {
      window.addEventListener("hashchange", this.changeTab, false);
      this.$makeErrorBag(this.errorBag);
      this.changeTab();
    },

    destroyed() {
      window.removeEventListener("hashchange", this.changeTab);
    },

    data() {
      return {
        defaultTab: "#bahagian-a",
        tab: this.defaultTab,

        nama_juruaudit: this.default_nama_juruaudit,
        no_rujukan_suratwarta: this.default_no_rujukan_suratwarta,

        pemaju_nama_pegawai: this.default_pemaju_nama_pegawai,
        pemaju_no_pejabat: this.default_pemaju_no_pejabat,
        pemaju_no_bimbit: this.default_pemaju_no_bimbit,
        pemaju_email: this.default_pemaju_email,

        pengesahan: null,
      };
    },

    methods: {
      isCurrentTab(tab) {
        return this.tab === tab;
      },

      changeTab() {
        this.tab = window.location.hash || this.defaultTab;
      },

      validate() {
        if (this.$validator.errors.first('nama_juruaudit') ||
          this.$validator.errors.first('no_rujukan_suratwarta') ||
          this.$validator.errors.first('pemaju_nama_pegawai') ||
          this.$validator.errors.first('pemaju_no_pejabat') ||
          this.$validator.errors.first('pemaju_no_bimbit') ||
          this.$validator.errors.first('pemaju_email')
        ) {
          window.location.hash = '#bahagian-a';
        } else if (this.$validator.errors.first('pengesahan')) {
          window.location.hash = '#bahagian-c';
        } else if (this.$validator.errors.any()) {
          window.location.hash = '#bahagian-b';
        }
      },

      async submit(ev) {
        ev.preventDefault();

        const passes = await this.$validator.validateAll();

        if (!passes) {
          this.validate();

          return;
        }

        swalconfirm()
          .then(result => {
            if (result.value) {
              ev.target.form.submit();
            }
          });
      },
    },
  }
</script>
