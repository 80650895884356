<template>
  <div class="card position-relative overflow-hidden">
    <div v-if="default_permohonan.status_id === 'D'" style="position: absolute; padding: 5px 50px; background: #5e87b0; color: white; top: 15px; right: -45px; transform: rotate(45deg); z-index: 1;">DERAF</div>
    <div v-else-if="['K1', 'K2', 'KB'].indexOf(default_permohonan.status_id) !== -1" style="position: absolute; padding: 5px 50px; background: red; color: white; top: 15px; right: -45px; transform: rotate(45deg); z-index: 1;">KUIRI</div>
    <div v-else-if="['LB'].indexOf(default_permohonan.status_id) !== -1" style="position: absolute; padding: 5px 50px; background: orange; color: white; top: 45px; right: -45px; transform: rotate(45deg); z-index: 1;">LULUS BERSYARAT</div>

    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li class="undone">
            <a href="#bahagian-a">A - MAKLUMAT SYARIKAT</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('b') }">
            <a href="#bahagian-b">B - MAKLUMAT TANAH</a>
          </li>
          <li :class="{'disabled': !hasEditingSection('c') || !maklumat.butiran_rs.length }">
            <a href="#bahagian-c">C - MAKLUMAT PEMAJUAN</a>
          </li>
          <li :class="{'disabled': !hasEditingSection('d') || !maklumat.tanah || maklumat.tanah.kod_jenis_hakmilik_id !== 3 }">
            <a href="#bahagian-d">D - GERAN</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('e') }">
            <a href="#bahagian-e">E - KEBENARAN MERANCANG</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('f') }">
            <a href="#bahagian-f">F - PELAN PRA-HITUNG</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('g') }">
            <a href="#bahagian-g">G - PELAN SUSUNATUR</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('h') }">
            <a href="#bahagian-h">H - SIJIL AKUAN JURUUKUR</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('i') }">
            <a href="#bahagian-i">I - PELAN BANGUNAN</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('j') }">
            <a href="#bahagian-j">J - NOTIS MULA KERJA</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('k') }">
            <a href="#bahagian-k">K - IKLAN</a>
          </li>
          <li :class="{'disabled': !hasEditingSection('l') || !maklumat.jenis_perjanjian }">
            <a href="#bahagian-l">L - CADANGAN PEMAJUAN</a>
          </li>
          <li class="undone" :class="{'disabled': !hasEditingSection('m') }">
            <a href="#bahagian-m">M - MAKLUMAT KOS</a>
          </li>
          <li :class="{'disabled': !hasEditingSection('n') || (!maklumat.kod_jenis_perjanjian_id || ['G', 'I'].indexOf(maklumat.kod_jenis_perjanjian_id) !== -1) }">
            <a href="#bahagian-n">N - PENENTUAN FORMULA UNIT SYER (SIFUS)</a>
          </li>
          <li :class="{'disabled': !hasEditingSection('o') || (!maklumat.kod_jenis_perjanjian_id || ['G', 'I'].indexOf(maklumat.kod_jenis_perjanjian_id) !== -1) }">
            <a href="#bahagian-o">O - JADUAL PETAK</a>
          </li>
          <li class="undone">
            <a href="#bahagian-p">P - LAIN - LAIN DOKUMEN</a>
          </li>
          <li class="undone">
            <a href="#bahagian-q">Q - PERAKUAN MAKLUMAT</a>
          </li>
        </ul>
      </nav>
      <section>
        <error-bar :messages="errorMessages" @close="errorMessages = {}"></error-bar>

        <div class="tab-content">
          <div
            v-if="isCurrentTab('#bahagian-a')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN A: MAKLUMAT PEMAJU</h4>
                <bahagian-a
                  :pemaju="pemaju"
                  :maklumat="pemaju"

                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"

                  :kod-taraf-pemaju-rs="kodTarafPemajuRs"
                  :kod-jenis-pemaju-rs="kodJenisPemajuRs"

                  :readonly="true"
                  @next="tab = '#bahagian-b'"
                ></bahagian-a>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-b')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN B: MAKLUMAT TANAH</h4>
                <bahagian-b
                  :tanah_rs="pemaju.tanah_rs"
                  :maklumat="maklumat"

                  :kod-jenis-urusan-rs="kodJenisUrusanRs"
                  :kod-jenis-tuan-tanah-rs="kodJenisTuanTanahRs"
                  :kod-jenis-kaveat-rs="kodJenisKaveatRs"
                  :kod-bebanan-tanah-rs="kodBebananTanahRs"
                  :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
                  :kod-status-tanah-rs="kodStatusTanahRs"
                  :kod-rezab-tanah-rs="kodRezabTanahRs"
                  :kod-syarat-nyata-rs="kodSyaratNyataRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-pbt-rs="kodPbtRs"
                  :kod-bank-hq-rs="kodBankHqRs"
                  
                  :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"

                  :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
                  :kod-pemilikan-pembeli-rs="kodPemilikanPembeliRs"

                  :kod-dun-rs="kodDunRs"
                  :kod-parlimen-rs="kodParlimenRs"

                  :mahkamah-tinggi="mahkamahTinggi"
                  :pengarah-rs="pengarahRs"
                  :kod-poskod-rs="kodPoskodRs"
                  :kod-poskod-bandar-rs="kodPoskodBandarRs"

                  @update="v => this.maklumat = v"
                  @next="tab = '#bahagian-b'"
                ></bahagian-b>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-c') && maklumat.tanah"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN C: MAKLUMAT PEMAJUAN</h4>
                <bahagian-c
                  :maklumat="maklumat"
                  :kod-jenis-perjanjian-rs="kodJenisPerjanjianRs"
                  :kod-kaedah-pemajuan-rs="kodKaedahPemajuanRs"
                  :kod-skim-projek-rs="kodSkimProjekRs"
                  :kod-jenis-jualan-rs="kodJenisJualanRs"
                  :kod-jenis-jualan-milikan-rs="kodJenisJualanMilikanRs"
                  :kod-type-perjanjian-rs="kodTypePerjanjianRs"

                  @update="v => this.maklumat = v"
                ></bahagian-c>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-d')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-d')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN D: GERAN</h4>
                <bahagian-d
                  :maklumat="maklumat"

                  @update="v => this.maklumat = v"
                ></bahagian-d>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-e')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-e')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN E: SURAT KEBENARAN MERANCANG</h4>
                <bahagian-e
                  :maklumat="maklumat"

                  @update="v => this.maklumat = v"
                ></bahagian-e>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-f')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-f')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN F: PELAN PRA-HITUNG</h4>
                <bahagian-f
                  :maklumat="maklumat"

                  @update="v => this.maklumat = v"
                ></bahagian-f>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-g')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-g')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN G: PELAN SUSUNATUR</h4>
                <bahagian-g
                  :maklumat="maklumat"

                  @update="v => this.maklumat = v"
                ></bahagian-g>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-h')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-h')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN H: SIJIL AKUAN JURUUKUR</h4>
                <bahagian-h
                  :maklumat="maklumat"

                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"

                  @update="v => this.maklumat = v"
                ></bahagian-h>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-i')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-i')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN I: PELAN BANGUNAN</h4>
                <bahagian-j
                  :maklumat="maklumat"

                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-pbt-rs="kodPbtRs"

                  @update="v => this.maklumat = v"
                ></bahagian-j>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-j')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-j')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN J: NOTIS MULA KERJA</h4>
                <bahagian-k
                  :maklumat="maklumat"

                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"

                  @update="v => this.maklumat = v"
                ></bahagian-k>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-k')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-k')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN K: IKLAN</h4>
                <bahagian-l
                  :maklumat="maklumat"

                  :kod-jenis-iklan-rs="kodJenisIklanRs"

                  @update="v => this.maklumat = v"
                ></bahagian-l>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-l')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-l')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN L: CADANGAN PEMAJUAN</h4>
                <bahagian-m
                  :maklumat="maklumat"
                  :kod-jenis-kediaman-rs="kodJenisKediamanRs"
                  :kod-skim-projek-rs="kodSkimProjekRs"
                  :kod-kedudukan-lot-rs="kodKedudukanLotRs"
                  @update="v => this.maklumat = v"
                ></bahagian-m>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-m')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-m')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN M: MAKLUMAT KOS</h4>
                <bahagian-n
                  :maklumat="maklumat"
                  :pemajuan="maklumat.pemajuan"
                  :permit="maklumat.pemajuan.permit"
                  :kewangan="maklumat.kewangan"
                  :pembangunan_rs="maklumat.pembangunan_rs"
                  :permohonan="permohonan"
                  @update="v => this.maklumat = v"
                ></bahagian-n>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-n')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-n')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN N: PENENTUAN FORMULA UNIT SYER (SIFUS)</h4>
                <bahagian-o
                  :maklumat="maklumat"
                  :kewangan="maklumat.kewangan"
                  :pembangunan_rs="maklumat.pembangunan_rs"
                  @update="v => this.maklumat = v"
                ></bahagian-o>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-o')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-o')}"
            role="tabpanel"
          >
            <div class="card bg-light">

              <div class="card-body">
                <h4 class="card-title">SEKSYEN O: JADUAL PETAK</h4>
                <bahagian-p
                  :maklumat="maklumat"
                  :kewangan="maklumat.kewangan"
                  :pembangunan_rs="maklumat.pembangunan_rs"
                  @update="v => this.maklumat = v"
                ></bahagian-p>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-p')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-p')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN P: LAIN - LAIN DOKUMEN</h4>
                <bahagian-r
                  :maklumat="maklumat"

                  @update="v => this.maklumat = v"
                ></bahagian-r>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-q')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-q')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">SEKSYEN Q: PERAKUAN MAKLUMAT BENAR</h4>
                <bahagian-s
                  :maklumat="maklumat"

                  @update="v => this.maklumat = v"

                  @hantar="submit"
                ></bahagian-s>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import ErrorBar from "../../common/ErrorBar";
import BahagianA from "../dl-baru/BahagianA";
import BahagianB from "./BahagianB";
import BahagianC from "./BahagianC";
import BahagianD from "./BahagianD";
import BahagianE from "./BahagianE";
import BahagianF from "./BahagianF";
import BahagianG from "./BahagianG";
import BahagianH from "./BahagianH";
import BahagianI from "./BahagianI";
import BahagianJ from "./BahagianJ";
import BahagianK from "./BahagianK";
import BahagianL from "./BahagianL";
import BahagianM from "./BahagianM";
import BahagianN from "./BahagianN";
import BahagianO from "./BahagianO";
import BahagianP from "./BahagianP";
import BahagianR from './BahagianR';
import BahagianS from './BahagianS';

export default {
  name: "Borang",
  components: {
    ErrorBar,
    BahagianA,
    BahagianB,
    BahagianC,
    BahagianD,
    BahagianE,
    BahagianF,
    BahagianG,
    BahagianH,
    BahagianI,
    BahagianJ,
    BahagianK,
    BahagianL,
    BahagianM,
    BahagianN,
    BahagianO,
    BahagianP,
    BahagianR,
    BahagianS,
  },

  props: {
    pemaju: Object,
    pengarah: Object,

    default_maklumat: Object,
    default_permohonan: Object,

    // tanah
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,

    // tuan tanah
    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    // pembangunan
    kodJenisKediamanRs: Array,
    kodJenisPerjanjianRs: Array,
    kodTypePerjanjianRs: Array,

    // pemaju
    kodKategoriPemajuRs: Array,
    kodTarafPemajuRs: Array,
    kodJenisPemajuRs: Array,

    // pemajuan
    kodJenisHakmilikRs: Array,
    kodPbtRs: Array,
    kodJenisPemajuanRs: Array,
    kodKaedahPemajuanRs: Array,
    kodSkimProjekRs: Array,
    kodKedudukanLotRs: Array,
    
    
    kodJenisJualanRs: Array,
    kodJenisJualanMilikanRs: Array,

    // iklan
    kodJenisIklanRs: Array,

    // kelulusan agensi
    kodKelulusanAgensiRs: Array,

    // misc
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,
    kodBankHqRs: Array,
    mahkamahTinggi: Array,
    pengarahRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,

    docConfigs: Array,
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,
      errorMessages: {},
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    hasEditingSection(section) {
      return this.maklumat && this.maklumat.seksyen_rs.length && this.maklumat.seksyen_rs.find(item => item.seksyen === section)
    },

    async submit() {
      swalconfirm()
        .then(result => {
          if (result.value) {
            axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/hantar`)
              .then(response => {
                window.location.replace("/pemaju/home/tugasan");
              })
          }
        });
    },
  },
}
</script>

<style scoped>

</style>
