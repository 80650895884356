<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>
      <div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Tajuk</label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.nmk_tajuk" @input="form.nmk_tajuk = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;No. Lot</label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.nmk_no_lot" @input="form.nmk_no_lot = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Tarikh Kelulusan</label>
          <div class="col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('nmk_tarikh_kelulusan')}"
              name="nmk_tarikh_kelulusan"
              v-model="form.nmk_tarikh_kelulusan"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Tarikh Mula Kerja</label>
          <div class="col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('nmk_tarikh_mula_kerja')}"
              name="nmk_tarikh_mula_kerja"
              v-model="form.nmk_tarikh_mula_kerja"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">5.&#9;Borang Notis-B mula kerja (Jika Berkaitan)</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="nmk_doc_rujukan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`nmk_doc_rujukan`)}"
              :doc="maklumat.nmk_doc_rujukan"
              @uploadwithcallback="({file, callback}) => uploadDoc('nmk_doc_rujukan', file, callback)"
              @remove="removeDoc('nmk_doc_rujukan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianK",
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,

      form: {
        nmk_tajuk: this.maklumat.nmk_tajuk,
        nmk_no_lot: this.maklumat.nmk_no_lot,
        nmk_tarikh_kelulusan: this.maklumat.nmk_tarikh_kelulusan,
        nmk_tarikh_mula_kerja: this.maklumat.nmk_tarikh_mula_kerja,
      },
    }
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/bahagian-k`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-permit-baru/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    }
  }
}
</script>

<style scoped>

</style>
