<template>
  <div class="card">
    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li class="active">
            <a href="#bahagian-a">A - MAKLUMAT PEMBAHARUAN</a>
          </li>
          <li class="undone">
            <a href="#bahagian-b">B - PENGAKUAN PEMAJU PERUMAHAN</a>
          </li>
        </ul>
      </nav>
      <div class="tab-content">
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-a')}"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BAHAGIAN A: MAKLUMAT PEMBAHARUAN</h4>
              <bahagian-a
                :kod-jenis-iklan-rs="kodJenisIklanRs"
                :lesen="lesen"
                :permit="permit"
                :pembangunan_rs="pembangunan_rs"

                :pemaju_nama_pegawai.sync="pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="pemaju_no_bimbit"
                :pembaharuan_lesen.sync="pembaharuan_lesen"
                :pembaharuan_permit.sync="pembaharuan_permit"
                :iklan_rs.sync="iklan_rs"
                :iklan_lain_rs.sync="iklan_lain_rs"
              ></bahagian-a>
            </div>
          </div>
        </div>

        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-b')}"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BAHAGIAN B: PENGAKUAN PEMAJU PERUMAHAN</h4>
              <bahagian-c
                :pengarah="pengarah"
                @submit="submit"
              ></bahagian-c>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import BahagianB from "./BahagianB";
import BahagianC from "./BahagianC";

export default {
  name: "Borang",
  components: {
    BahagianA,
    BahagianB,
    BahagianC,
  },

  props: {
    pengarah: Object,
    pemaju: Object,
    kodJenisIklanRs: Array,
    kodJenisPemajuRs: Array,
    pembangunan_rs: Array,
    lesen: Object,
    permit: Object,

    default_pemaju_nama_pegawai: String,
    default_pemaju_no_pejabat: String,
    default_pemaju_no_bimbit: String,

    default_pembaharuan_lesen: Boolean,
    default_pembaharuan_permit: Boolean,
    default_iklan_rs: Array,
    default_iklan_lain_rs: Array,

    default_subsyarikat_rs: Array,

    docConfigs: Array,
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,
      errorMessages: {},
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      pembaharuan_lesen: this.default_pembaharuan_lesen ? this.default_pembaharuan_lesen : false,
      pembaharuan_permit: this.default_pembaharuan_permit ? this.default_pembaharuan_permit : false,

      iklan_rs: JSON.parse(JSON.stringify(this.default_iklan_rs)),
      iklan_lain_rs: JSON.parse(JSON.stringify(this.default_iklan_lain_rs)),

      pemaju_nama_pegawai: this.default_pemaju_nama_pegawai,
      pemaju_no_pejabat: this.default_pemaju_no_pejabat,
      pemaju_no_bimbit: this.default_pemaju_no_bimbit,

      // subsyarikat_rs: JSON.parse(JSON.stringify(this.default_subsyarikat_rs)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    validate() {
      if (this.$validator.errors.first('pemaju_nama_pegawai') ||
        this.$validator.errors.first('pemaju_no_pejabat') ||
        this.$validator.errors.first('pemaju_no_bimbit')
      ) {
        window.location.hash = '#bahagian-a';
      }
    },

    async submit(ev) {
      ev.preventDefault();


      if (!this.pembaharuan_lesen && !this.pembaharuan_permit) {
        window.location.hash = '#bahagian-a';

        swal.fire(
          'Maaf!',
          'Sila isi pilih jenis pembaharuan sekurang-kurangnya satu.',
          'error',
        )
        return;
      }


      const passes = await this.$validator.validateAll();

      if (!passes) {
        this.validate();

        return;
      }

      swalconfirm()
        .then(result => {
          if (result.value) {
            ev.target.form.submit();
          }
        });
    },
  },
}
</script>

<style scoped>

</style>
