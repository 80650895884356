<template>
  <section>
    <div class="form-group row" v-if="pegawai_perhubungan_rs.length">
      <label class="col-form-label col-md-3">Pegawai Perhubungan</label>
      <div class="col-md-6">
        <select class="form-control">
          <option selected :value="null">Sila Pilih</option>
          <option v-for="(pegawai, index) in pegawai_perhubungan_rs" :value="index">{{ pegawai.nama }}</option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-md-3">Pegawai Pemaju Untuk Dihubungi <span class="label-required">*</span></label>
      <div class="col-md-6">
        <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_nama_pegawai') }" name="pemaju_nama_pegawai" :value="pemaju_nama_pegawai" @input="$emit('update:pemaju_nama_pegawai', $event.target.value.toUpperCase())" v-validate="'required|max:100'" data-vv-as="Nama Pegawai" />
        <span class="invalid-feedback" v-if="errors.first('pemaju_nama_pegawai')">{{ errors.first('pemaju_nama_pegawai') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-md-3">No Telefon Pejabat <span class="label-required">*</span></label>
      <div class="col-md-2">
        <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_pejabat') }" name="pemaju_no_pejabat" :value="pemaju_no_pejabat" @input="$emit('update:pemaju_no_pejabat', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:9,11'" data-vv-as="No Telefon Pejabat" />
        <span class="invalid-feedback" v-if="errors.first('pemaju_no_pejabat')">{{ errors.first('pemaju_no_pejabat') }}</span>
      </div>
      <label class="col-form-label col-md-3">No Telefon Bimbit <span class="label-required">*</span></label>
      <div class="col-md-2">
        <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_no_bimbit') }" name="pemaju_no_bimbit" :value="pemaju_no_bimbit" @input="$emit('update:pemaju_no_bimbit', $event.target.value.toUpperCase())" v-validate="'required|numeric|length:10,11'" data-vv-as="No Telefon Bimbit" />
        <span class="invalid-feedback" v-if="errors.first('pemaju_no_bimbit')">{{ errors.first('pemaju_no_bimbit') }}</span>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-form-label col-md-3">Emel <span class="label-required">*</span></label>
      <div class="col-md-2">
        <input type="text" class="form-control" :class="{'is-invalid': errors.first('pemaju_email') }" name="pemaju_email" :value="pemaju_email" @input="$emit('update:pemaju_email', $event.target.value)" v-validate="'required|email'" data-vv-as="Emel"/>
        <span class="invalid-feedback" v-if="errors.first('pemaju_email')">{{ errors.first('pemaju_email') }}</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PemajuPegawaiUntukDihubungi",
  inject: ['$validator'],

  props: {
    pemaju_nama_pegawai: String,
    pemaju_no_bimbit: String,
    pemaju_no_pejabat: String,
    pemaju_email: String,

    pegawai_perhubungan_rs: { type: Array, default: () => ([]) }
  },
}
</script>

<style scoped>

</style>
