<template>
  <div>
    <div class="card position-relative" @click="$emit('click', $event)" style="cursor: pointer">
      <div class="card-body px-3 py-3">
        <div v-if="edit" class="row">
          <div class="col-md-6">
            <label for="label">Label Tanah</label>
            <input type="text" class="form-control" :value="form.label" @input="form.label = $event.target.value.toUpperCase()" name="label" @click.stop>
          </div>
        </div>
        <h5 v-else-if="tanah.action === 'H'"><s>{{ no }}.&nbsp; {{ tanah.label }}</s> <span class="text-danger">(DIHAPUS)</span></h5>
        <h5 v-else-if="tanah.action === 'B'">{{ no }}.&nbsp; {{ tanah.label }} <span class="text-warning">(BARU)</span></h5>
        <h5 v-else-if="tanah.action === 'K'">{{ no }}.&nbsp; {{ tanah.label }} <span class="text-info">(KEKAL)</span></h5>
        <h5 v-else>{{ tanah.label }}</h5>
        <div class="row mt-4">
          <div class="col-6">
            <div>Status Hakmilik</div>
            <div v-if="edit && tanah.action === 'K'" class="mb-2">
              <select class="form-control" style="width: 300px;" v-model="form.kod_jenis_hakmilik_id" @click.stop>
                <option :value="null">Sila Pilih Status Hakmilik</option>
                <option v-for="jenis_hakmilik in kodJenisHakmilikRs" :key="`hakmilik-${jenis_hakmilik.id}`" :value="jenis_hakmilik.id">{{ jenis_hakmilik.keterangan }}</option>
              </select>
            </div>
            <div v-else-if="edit && tanah.action === 'H'" class="mb-2">
              <select class="form-control" style="width: 300px;" v-model="form.kod_jenis_hakmilik_id" @click.stop>
                <option :value="null">Sila Pilih Status Hakmilik</option>
                <option v-for="jenis_hakmilik in kodJenisHakmilikRs" :key="`hakmilik-${jenis_hakmilik.id}`" :value="jenis_hakmilik.id">{{ jenis_hakmilik.keterangan }}</option>
              </select>
            </div>
            <div v-else-if="edit && tanah.action === 'B'" class="mb-2">
              <select class="form-control" style="width: 300px;" v-model="form.kod_jenis_hakmilik_id" @click.stop>
                <option :value="null">Sila Pilih Status Hakmilik</option>
                <option v-for="jenis_hakmilik in kodJenisHakmilikRs" :key="`hakmilik-${jenis_hakmilik.id}`" :value="jenis_hakmilik.id">{{ jenis_hakmilik.keterangan }}</option>
                <!-- <option v-for="jenis_hakmilik in filteredJenisHakmilikRs()" :key="`hakmilik-${jenis_hakmilik.id}`" :value="jenis_hakmilik.id">{{ jenis_hakmilik.keterangan }}</option> -->
              </select>
            </div>
            <div v-else class="text-grey">{{ tanah.jenis_hakmilik ? tanah.jenis_hakmilik.keterangan : 'Tiada Maklumat'}}</div>
            <div><b>No. Hakmilik : </b><i>{{ tanah.butiran_rs.map(item => item.no_geran).join(' , ') }}</i></div>
            <div><em style="color: black; font-weight: bold;">Jumlah Butiran Tanah : {{ tanah.butiran_rs.length }}</em></div>
          </div>
          <div class="col-6">
            <div v-if="edit">
              <div class="row">
                <div class="col-6">
                  <div>Negeri</div>
                  <div class="mb-2">
                    <select class="form-control" v-model="form.kod_negeri_id" @click.stop @change="form.kod_daerah_id = null; form.kod_bandar_id = null; form.kod_parlimen_id = null; form.kod_dun_id = null;">
                      <option :value="null">Sila Pilih Negeri</option>
                      <option v-for="negeri in kodNegeriRs" :key="`negeri-${negeri.id}`" :value="negeri.id">{{ negeri.keterangan }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-6">
                  <div>Parlimen</div>
                  <div class="mb-2">
                    <select class="form-control" v-model="form.kod_parlimen_id" @click.stop @change="form.kod_dun_id = null;">
                      <option :value="null">Sila Pilih Parlimen</option>
                      <option v-for="parlimen in filteredParlimenRs(form.kod_negeri_id)" :key="`parlimen-${parlimen.id}`" :value="parlimen.id">{{ parlimen.keterangan }}</option>
                    </select>
                  </div>
                  <div>Dun</div>
                  <div class="mb-2">
                    <select class="form-control" v-model="form.kod_dun_id" @click.stop>
                      <option :value="null">Sila Pilih DUN</option>
                      <option v-for="dun in filteredDunRs(form.kod_parlimen_id)" :key="`dun-${dun.id}`" :value="dun.id">{{ dun.keterangan }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="text-right">
              <div>
                {{ tanah.bandar ? tanah.bandar.keterangan+',' : '' }} {{ tanah.poskod }} {{ tanah.daerah ? tanah.daerah.keterangan+',' : '' }} {{ tanah.negeri ? tanah.negeri.keterangan : '' }}
              </div>
              <div class="text-grey">
                <div class="d-inline-block">Parlimen : {{ tanah.parlimen ? tanah.parlimen.keterangan : 'Tiada Maklumat'}}</div>
                <div v-if="tanah.negeri && tanah.negeri.ada_dun === 'Y'" class="d-inline-block ml-3">DUN : {{ tanah.dun ? tanah.dun.keterangan : 'Tiada Maklumat'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute text-right" style="top: 5px; right: 5px">
        <div v-if="tanah.pemajuan_rs.length" class="text-sm text-info">PEMAJUAN: {{ tanah.pemajuan_rs.map(p => p.nama).join(' , ')}}</div>
        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" @click.stop="$emit('destroyTanah')" v-else-if="!readonly && tanah.action === 'H'"><i class="fa fa-check-circle text-success"></i></button>
        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" @click.stop="$emit('destroyTanah')" v-else-if="!readonly"><i class="fa fa-trash text-danger"></i></button>

        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" v-if="!readonly && !edit" @click.stop="editing"><i class="fa fa-edit"></i></button>

        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" v-if="!readonly && edit" @click.stop="$emit('editTanah', form, stopEdit)" :disabled="saving"><i class="fa fa-save text-success"></i></button>
        <button class="btn btn-sm btn-link btn-icon pb-0 pr-2" v-if="!readonly && edit" @click.stop="stopEdit" :disabled="saving"><i class="fa fa-times text-dark"></i></button>
      </div>
      <div class="position-absolute" style="bottom: 5px; right: 5px">
        <button class="btn pb-0 pr-2" type="button"><i class="fa" :class="{ 'fa-chevron-down': show, 'fa-chevron-up': !show}"></i></button>
      </div>
    </div>
    <div v-if="show" class="ml-4 p-4" style="border-left: 4px solid #dee2e6">
      <div class="d-flex align-items-center mb-2">
        <h5 class="mb-0">BUTIRAN TANAH KESELURUHAN</h5>
        <div class="ml-3">
          <button v-if="!readonly && tanah.action !== 'H'" class="btn btn-sm btn-success" type="button" @click.prevent="$emit('addButiran')"><i class="fas fa-plus"></i></button>
        </div>
      </div>
      <slot></slot>
    </div>
    <pindaan-butiran-tanah-modal
      :tanah="tanah"
      :maklumat="maklumat"
      :kod-bebanan-tanah-rs="kodBebananTanahRs"
      :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
      :kod-status-tanah-rs="kodStatusTanahRs"
      :kod-rezab-tanah-rs="kodRezabTanahRs"
      :kod-syarat-nyata-rs="kodSyaratNyataRs"
      :kod-jenis-kaveat-rs="kodJenisKaveatRs"
      :kod-daerah-rs="kodDaerahRs"
      :kod-bandar-rs="kodBandarRs"

      :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"
      :readonly="readonly"
    ></pindaan-butiran-tanah-modal>

    <gadaian-tanah-modal
      :kod-bank-hq-rs="kodBankHqRs"
      :kod-negeri-rs="kodNegeriRs"
      :kod-daerah-rs="kodDaerahRs"
      :kod-bandar-rs="kodPoskodBandarRs"
      :kod-poskod-rs="kodPoskodRs"
      :readonly="readonly"
    ></gadaian-tanah-modal>


    <kaveat-tanah-modal
      :kod-jenis-kaveat-rs="kodJenisKaveatRs"
      :readonly="readonly"
    ></kaveat-tanah-modal>
  </div>
</template>

<script>
import PindaanButiranTanahModal from './PindaanButiranTanahModal';
import KaveatTanahModal from "./KaveatTanahModal";
import GadaianTanahModal from "./GadaianTanahModal";

export default {
  components: {
    PindaanButiranTanahModal,

    KaveatTanahModal,
    GadaianTanahModal,
  },

  name: "PindaanTanah",

  props: {
    tanah: Object,
    totalTanah: Number,
    maklumat: Object,

    saving: {
      type: Boolean,
      default: false
    },

    show: {
      type: Boolean,
      default: false,
    },

    no: {
      type: Number,
      default: 1,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodBankHqRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,

    kodJenisHakmilikRs: Array,
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,
  },

  data() {
    return {
      edit: false,
      form: {},
    }
  },

  methods: {
    editing() {
      this.form = JSON.parse(JSON.stringify(this.tanah));
      this.edit = true;
    },

    stopEdit() {
      this.edit = false;
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(item => item.kod_daerah_id == kod_daerah_id);
    },
    filteredParlimenRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodParlimenRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },

    filteredDunRs(kod_parlimen_id) {
      if (!kod_parlimen_id) {
        return [];
      }

      return this.kodDunRs.filter(item => item.kod_parlimen_id == kod_parlimen_id);
    },

    filteredJenisHakmilikRs() {
      return this.kodJenisHakmilikRs.filter(item => item.id != 3);
    },

    showDun(kod_negeri_id) {
      if (!kod_negeri_id) {
        return false;
      }

      const negeri = this.kodNegeriRs.find(item => item.id == kod_negeri_id);

      return negeri && negeri.ada_dun == 'Y';
    }
  }
}
</script>

<style scoped>

</style>
