var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"list-group"},_vm._l((_vm.default_docs),function(config,index){return _c('div',{key:("doc-" + (config.id)),staticClass:"list-group-item form-row d-flex"},[_c('label',{staticClass:"col-form-label col-md-8"},[(
          !_vm.readonly && !config.flag_lulus && config.mandatori_ind && _vm.showKuiri
        )?_c('span',{staticClass:"text-danger"},[_c('i',{staticClass:"fas fa-exclamation-circle"})]):_vm._e(),_vm._v("\n      "+_vm._s(index + 1)+". "),_c('span',{domProps:{"innerHTML":_vm._s(config.nama_dokumen)}}),_vm._v(" "),(config.mandatori_ind)?_c('span',{staticClass:"label-required"},[_vm._v("*")]):_vm._e(),_vm._v(" "),(
          config.nama_dokumen ===
          'Salinan Laporan Juruaudit kepada Ahli-Ahli Lembaga Syarikat'
        )?_c('span',{staticClass:"italic"},[_c('br'),_vm._v("\n        Independent Auditor's Report To The Member Of The Company\n      ")]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-inline"},[(!config.multiple)?_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:(config.mandatori_ind ? 'required' : ''),expression:"config.mandatori_ind ? 'required' : ''"}],staticClass:"form-control flex-grow-1 mr-2",class:{ 'is-invalid': _vm.errors.first(("doc[" + (config.id) + "]")) },attrs:{"type":"file","accept":_vm.acceptType,"name":("doc[" + (config.id) + "]"),"doc":_vm.getDoc(config),"readonly":(_vm.showKuiri && !!config.flag_lulus) || _vm.readonly},on:{"upload":function (file) { return _vm.uploadDoc(file, config); },"remove":function (doc) { return _vm.removeDoc(doc, config); }}}):_c('file-upload-multi',{directives:[{name:"validate",rawName:"v-validate",value:(config.mandatori_ind ? 'required' : ''),expression:"config.mandatori_ind ? 'required' : ''"}],staticClass:"form-control flex-grow-1 mr-2",class:{ 'is-invalid': _vm.errors.first(("doc[" + (config.id) + "]")) },attrs:{"type":"file","accept":_vm.acceptType,"name":("doc[" + (config.id) + "]"),"docs":_vm.getDoc(config),"readonly":(_vm.showKuiri && !!config.flag_lulus) || _vm.readonly},on:{"upload":function (ref) {
                var files = ref.files;
                var clear = ref.clear;

                return _vm.uploadDocMultiple(files, config, clear);
},"remove":function (doc) { return _vm.removeDoc(doc, config); }}}),_vm._v(" "),(_vm.checkDocConfig(index))?_c('a',{staticClass:"d-flex align-items-center mt-1 text-decoration-none",attrs:{"target":"_blank","href":_vm.docConfigs[index].contoh_dokumen_url
              ? _vm.docConfigs[index].contoh_dokumen_url
              : 'javascript:void(0);',"data-toggle":"tooltip","data-placement":"left","title":"Contoh Dokumen"}},[_c('span',{staticClass:"badge badge-primary mr-1"},[_vm._v("Contoh")])]):_vm._e()],1)]),_vm._v(" "),(_vm.showKuiri && !_vm.readonly && !config.flag_lulus && config.kuiri)?_c('div',{staticClass:"col-md-12 mt-2"},[_c('label',{staticStyle:{"font-weight":"500"}},[_vm._v("Catatan Kuiri")]),_vm._v(" "),_c('textarea',{staticClass:"form-control",attrs:{"disabled":""}},[_vm._v(_vm._s(config.kuiri.catatan_kuiri))]),_vm._v(" "),_c('br'),_vm._v(" "),(config.kuiri.catatan_kuiri)?_c('div',[_c('strong',[_vm._v("Catatan Maklumbalas")]),_vm._v(" "),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(config.kuiri.catatan_maklumbalas_kuiri),expression:"config.kuiri.catatan_maklumbalas_kuiri"}],staticClass:"form-control",attrs:{"name":("kuiri_permohonan_rs[" + (config.kuiri.id) + "][catatan_maklumbalas_kuiri]"),"disabled":config.kuiri.tarikh_maklumbalas_kuiri},domProps:{"value":(config.kuiri.catatan_maklumbalas_kuiri)},on:{"click":function($event){$event.stopPropagation();},"input":function($event){if($event.target.composing){ return; }_vm.$set(config.kuiri, "catatan_maklumbalas_kuiri", $event.target.value)}}})]):_vm._e()]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }