<template>
  <tr :class="{'bg-warning-light text-dark': isFirstBuyer}">
    <template v-if="!updating">
      <td>{{ no }}</td>
      <td>{{ item.no_lot_pt }}</td>
      <td wrap>
        {{ item.no_unit }}
        {{isFirstBuyer ? '(PJB Pertama)': ''}}
        <div v-if="item.updated_by === 'API' " class="d-flex">
          <span class="badge badge-info px-3" :title="'Dikemaskini oleh API pada '+ item.updated_at">API</span>
        </div>
      </td>
      <td class="text-center" :class="{'text-success': item.tuan_tanah, 'text-danger': !item.tuan_tanah}" >{{ item.tuan_tanah ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center" :class="{'text-success': item.kuota_bumi, 'text-danger': !item.kuota_bumi}">{{ item.kuota_bumi ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center" :class="{'text-success': item.status_jualan, 'text-danger': !item.status_jualan}">{{ item.status_jualan ? '&#x2714;' : '&#x2718;' }}</td>
      <td>{{ item.harga_jualan | currency('', ',', 2, '.', 'front', false) }}</td>
      <td>{{ item.rebat_pemaju | currency('', ',', 2, '.', 'front', false) }}</td>
      <td>{{ item.harga_diskaun_kuotabumi | currency('', ',', 2, '.', 'front', false) }}</td>
      <td>{{ item.inisiatif_kerajaan | currency('', ',', 2, '.', 'front', false) }}</td>
      <td>
        <span v-if="item.status_jualan">{{ item.harga_spjb | currency('', ',', 2, '.', 'front', false) }} </span><span v-else>-</span>
      </td>
      <!-- <td><span v-if="item.status_jualan">{{ ((item.harga_jualan * 100 - item.harga_spjb * 100) / 100) | currency('RM', ',', 2, '.', 'front', false) }}</span><span v-else>-</span></td> -->
      <td><span v-if="item.tarikh_spjb">{{ item.tarikh_spjb }}</span><span v-else>-</span></td>
      <!-- <td>{{ item.kedudukan_lot ? item.kedudukan_lot.keterangan : '-' }}</td> -->
      <!-- <td>{{ item.bil_bilik }}</td> -->
      <!-- <td>{{ item.bil_tandas }}</td> -->
      <td class="text-center" nowrap>
        <button @click="startUpdating" class="btn btn-inverse-primary btn-sm" title="Kemaskini kedudukan lot, bilangan bilik tidur dan bilangan bilik air">UNIT</button>
        <template v-if="item.tuan_tanah !== 1">
          <button @click="updatePembeli" class="btn btn-inverse-info btn-sm" title="Kemaskini pembeli dan jana SPJB">PEMBELI</button>
          <button @click="updatePeguam" class="btn btn-inverse-success btn-sm" title="Kemaskini Peguam">PEGUAM</button>
          <button @click="updateSaksi" class="btn btn-inverse-warning btn-sm" title="Kemaskini Saksi">SAKSI</button>
          <button v-if="['G', 'H'].includes(pemajuan.kod_jenis_perjanjian_id)" @click="updateJadual(1)" class="btn btn-inverse-success btn-sm" title="Kemaskini Jadual 1">JADUAL 1</button>
          <button v-if="['G', 'H'].includes(pemajuan.kod_jenis_perjanjian_id)" @click="updateJadual(2)" class="btn btn-inverse-success btn-sm" title="Kemaskini Jadual 2">JADUAL 2</button>
          <button v-if="['G', 'H'].includes(pemajuan.kod_jenis_perjanjian_id)" @click="updateJadual(4)" class="btn btn-inverse-success btn-sm" title="Kemaskini Jadual 4">JADUAL 4</button>
          <button v-if="['H'].includes(pemajuan.kod_jenis_perjanjian_id)" @click="updateJadual(5)" class="btn btn-inverse-success btn-sm" title="Kemaskini Jadual 5">JADUAL 5</button>
        </template>
        <template v-else>
          <button class="btn btn-secondary btn-sm disabled" title="Tidak boleh kemaskini maklumat pembeli bagi unit Tuan Tanah" disabled="" style="cursor: not-allowed;">PEMBELI</button>
        </template>
      </td>
    </template>
    <template v-else>
      <td>{{ no }}</td>
      <td nowrap>{{ form.no_unit }}</td>
      <td class="text-center" :class="{'text-success': item.tuan_tanah, 'text-danger': !item.tuan_tanah}" >{{ item.tuan_tanah ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center" :class="{'text-success': item.kuota_bumi, 'text-danger': !item.kuota_bumi}">{{ item.kuota_bumi ? '&#x2714;' : '&#x2718;' }}</td>
      <td class="text-center" :class="{'text-success': item.status_jualan, 'text-danger': !item.status_jualan}">{{ item.status_jualan ? '&#x2714;' : '&#x2718;' }}</td>
      <td>{{ item.harga_jualan | currency('RM', ',', 2, '.', 'front', false) }}</td>
      <td><span v-if="item.status_jualan">{{ item.harga_spjb | currency('RM', ',', 2, '.', 'front', false) }}</span><span v-else>-</span></td>
      <td>{{ ((item.harga_jualan * 100 - item.harga_spjb * 100) / 100) | currency('RM', ',', 2, '.', 'front', false) }}</td>
      <td><span v-if="item.tarikh_spjb">{{ item.tarikh_spjb }}</span><span v-else>-</span></td>
      <!-- <td>{{ form.kedudukan_lot ? item.kedudukan_lot.keterangan : '-' }}</td> -->
      <!-- <td><input class="form-control" type="text" v-model="form.bil_bilik"></td> -->
      <!-- <td><input class="form-control" type="text" v-model="form.bil_tandas"></td> -->
      <td class="text-center" nowrap>
        <button v-if="updating" @click="updating = false" :disabled="saving" class="btn btn-inverse-secondary btn-sm mr-2">BATAL</button><button v-if="updating" @click="save" :disabled="saving" class="btn btn-inverse-success btn-sm">SIMPAN</button>
      </td>
    </template>
  </tr>
</template>

<script>
  export default {
    name: "RowNolotUnit",
    props: {
      item: Object,
      unitPertama: Object,
      no: Number,
      pemajuan: Object,
    },
    data() {
      return {
        updating: false,
        saving: false,
        form: {},
      };
    },
    methods: {
      save() {
        this.saving = true;
        axios.post(`/pemaju/kuota-bumi/unit/${this.item.id}`, this.form)
        .then((response) => {
          this.$emit('update:item', response.data);
        })
        .catch(() => {})
        .then(() => {
          this.saving = false;
          this.updating = false;
        })
      },

      startUpdating() {
        // this.form = JSON.parse(JSON.stringify(this.item));
        // this.updating = true
        vmodal.open('kemaskiniNoLotUnit', {unit: this.item, unit_pertama: this.unitPertama}, (unit) => {
          this.$emit('update:item', unit);
        })
      },

      updatePembeli() {
        // add condition if status jualan 'x' and (jenis_layout is null or kod_kedudukan_lot_id is null)
        if(this.item.status_jualan === 0 && (this.item.jenis_layout == null || this.item.kod_kedudukan_lot_id == null)) {
          swal.fire(
            'Harap Maaf!',
            'Sila lengkapkan maklumat unit terlebih dahulu.',
          )
          return
        }
        vmodal.open('pembeli', {unit: this.item, unit_pertama: this.unitPertama}, (unit) => {
          this.$emit('update:item', unit);
        })
      },
      
      updatePeguam() {
        if(this.item.status_jualan === 0 && (this.item.jenis_layout == null || this.item.kod_kedudukan_lot_id == null)) {
          swal.fire(
            'Harap Maaf!',
            'Sila lengkapkan maklumat unit terlebih dahulu.',
          )
          return
        }
        
        vmodal.open('peguam', {unit: this.item, unit_pertama: this.unitPertama}, (unit) => {
          this.$emit('update:item', unit);
        })
      },

      updateSaksi() {
        if(this.item.status_jualan === 0 && (this.item.jenis_layout == null || this.item.kod_kedudukan_lot_id == null)) {
          swal.fire(
            'Harap Maaf!',
            'Sila lengkapkan maklumat unit terlebih dahulu.',
          )
          return
        }
        
        vmodal.open('pembeli_saksi', {unit: this.item, unit_pertama: this.unitPertama}, (unit) => {
          this.$emit('update:item', unit);
        })
      },
      updateJadual(type) {
        if(this.item.status_jualan === 0 && (this.item.jenis_layout == null || this.item.kod_kedudukan_lot_id == null)) {
          swal.fire(
            'Harap Maaf!',
            'Sila lengkapkan maklumat unit terlebih dahulu.',
          )
          return
        }
        vmodal.open(`jadual`, {unit: this.item, unit_pertama: this.unitPertama, type}, (unit) => {
          this.$emit('update:item', unit);
        })
      }
    },

    computed: {
      isFirstBuyer(){
        return this.unitPertama && this.item.id == this.unitPertama.id
      }
    }
  }
</script>
