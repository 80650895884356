<template>
  <div class="card">
    <div class="card-body with-side-steps">
      <nav class="side-steps">
        <ul class="">
          <li class="step-heading">SEKSYEN</li>
          <li :class="{'disabled': !canAccessSection('a')}">
            <a href="#bahagian-a">A - BUTIRAN PEMAJU</a>
          </li>
          <li :class="{'disabled': !canAccessSection('b')}">
            <a href="#bahagian-b">B - BUTIRAN LOKASI PEMAJUAN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('b1')}">
            <a href="#bahagian-b1">B1 - BUTIRAN TANAH KESELURUHAN PEMAJUAN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('c')}">
            <a href="#bahagian-c">C - BUTIRAN PEMAJUAN PERUMAHAN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('c1')}">
            <a href="#bahagian-c1">C1 - BUTIRAN PEMAJUAN PERUMAHAN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('c2')}">
            <a href="#bahagian-c2">C2 - BUTIRAN PEMAJUAN PERUMAHAN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('c3')}">
            <a href="#bahagian-c3">C3 - BUTIRAN PEMAJUAN PERUMAHAN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('d')}">
            <a href="#bahagian-d">D - BUTIRAN KEWANGAN PROJEK SEMASA MEMOHON LESEN</a>
          </li>
          <li :class="{'disabled': !canAccessSection('e')}">
            <a href="#bahagian-e">E - BUTIRAN MAKLUMAT AKAUN HDA</a>
          </li>
          <li :class="{'disabled': !canAccessSection('f')}">
            <a href="#bahagian-f">F - SENARAI NAMA SYARIKAT INDUK/SUBSIDIARI/BERSEKUTU YANG TERLIBAT DALAM PEMAJUAN PERUMAHAN SAHAJA</a>
          </li>
          <li :class="{'disabled': !canAccessSection('g')}">
            <a href="#bahagian-g">G - SALINAN DOKUMEN SOKONGAN</a>
          </li>
          <li>
            <a href="#bahagian-h">H - PENGAKUAN PEMAJU PERUMAHAN</a>
          </li>
        </ul>
      </nav>
      <section>
        <error-bar :messages="errorMessages" @close="errorMessages = {}"></error-bar>

        <div class="tab-content">
          <div
            v-if="isCurrentTab('#bahagian-a')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN A: BUTIRAN PEMAJU</h4>
                <bahagian-a
                  :maklumat="maklumat"
                  :kod-taraf-pemaju-rs="kodTarafPemajuRs"
                  :kod-kategori-pemaju-rs="kodKategoriPemajuRs"
                  :kod-jenis-pemaju-rs="kodJenisPemajuRs"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"

                  :pemaju.sync="maklumat.pemaju"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('a')"
                  :kuiri="hasKuiriSection('a')"
                ></bahagian-a>
              </div>
            </div>
          </div>
          <div
            v-if="isCurrentTab('#bahagian-b')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN B: BUTIRAN LOKASI PEMAJUAN</h4>
                <bahagian-b
                  :maklumat="maklumat"

                  :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"

                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :kod-parlimen-rs="kodParlimenRs"
                  :kod-dun-rs="kodDunRs"
                  :kod-pbt-rs="kodPbtRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('b')"
                  :kuiri="hasKuiriSection('b')"
                ></bahagian-b>
              </div>
            </div>
          </div>
          <div
            v-if="isCurrentTab('#bahagian-b1')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-b1')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN B1: BUTIRAN TANAH KESELURUHAN PEMAJUAN</h4>
                <bahagian-b1
                  :maklumat="maklumat"
                  :tanah_rs="maklumat.tanah_rs"
                  :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
                  :kod-status-tanah-rs="kodStatusTanahRs"
                  :kod-syarat-nyata-rs="kodSyaratNyataRs"
                  :kod-bebanan-tanah-rs="kodBebananTanahRs"

                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('b1')"
                  :kuiri="hasKuiriSection('b1')"
                ></bahagian-b1>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-c')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN C: BUTIRAN PEMAJUAN PERUMAHAN</h4>
                <bahagian-c
                  :maklumat="maklumat"
                  :kod-jenis-pemajuan-rs="kodJenisPemajuanRs"
                  :kod-kaedah-pemajuan-rs="kodKaedahPemajuanRs"
                  :kod-skim-projek-rs="kodSkimProjekRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('c')"
                  :kuiri="hasKuiriSection('c')"
                ></bahagian-c>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-c1')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-c1')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN C1: BUTIRAN PEMAJUAN PERUMAHAN</h4>
                <bahagian-c1
                  :maklumat="maklumat"
                  :default_pembangunan_rs="maklumat.pembangunan_rs"
                  :kod-jenis-kediaman-rs="kodJenisKediamanRs"
                  :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
                  :kod-jenis-perjanjian-rs="kodJenisPerjanjianRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('c1')"
                  :kuiri="hasKuiriSection('c1')"
                ></bahagian-c1>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-c2')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-c2')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN C2: BUTIRAN PEMAJUAN PERUMAHAN</h4>
                <bahagian-c2
                  :kod-kelulusan-agensi-rs="kodKelulusanAgensiRs"
                  :maklumat="maklumat"
                  :kelulusan_agensi_rs="maklumat.kelulusan_agensi_rs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('c2')"
                  :kuiri="hasKuiriSection('c2')"
                ></bahagian-c2>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-c3')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-c3')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN C3: BUTIRAN PEMAJUAN PERUMAHAN</h4>
                <bahagian-c3
                  :maklumat="maklumat"
                  :default_iklan_rs="maklumat.iklan_rs"
                  :default_iklan_lain_rs="maklumat.iklan_lain_rs"

                  :kod-jenis-iklan-rs="kodJenisIklanRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('c3')"
                  :kuiri="hasKuiriSection('c3')"
                ></bahagian-c3>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-d')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-d')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN D: BUTIRAN KEWANGAN PROJEK SEMASA MEMOHON LESEN</h4>
                <bahagian-d
                  :maklumat="maklumat"
                  :kewangan="maklumat.kewangan"
                  :pembangunan_rs="maklumat.pembangunan_rs"

                  :readonly="!canAccessSection('d')"
                  :kuiri="hasKuiriSection('d')"
                ></bahagian-d>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-e')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-e')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN E: BUTIRAN MAKLUMAT HDA</h4>
                <bahagian-e
                  :maklumat="maklumat"
                  :kod-negeri-rs="kodNegeriRs"
                  :kod-daerah-rs="kodDaerahRs"
                  :kod-bandar-rs="kodBandarRs"
                  :readonly="!canAccessSection('e')"

                  :kuiri="hasKuiriSection('e')"
                ></bahagian-e>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-f')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-f')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN F: SENARAI NAMA SYARIKAT INDUK/SUBSIDIARI/BERSEKUTU YANG TERLIBAT DALAM PEMAJUAN PERUMAHAN SAHAJA</h4>
                <bahagian-f
                  :maklumat="maklumat"
                  :default_syarikat_rs="maklumat.subsyarikat_rs"
                  :kod-jenis-pemaju-rs="kodJenisPemajuRs"

                  @update="v => this.maklumat = v"

                  :readonly="!canAccessSection('f')"
                  :kuiri="hasKuiriSection('f')"
                ></bahagian-f>
              </div>
            </div>
          </div>

          <div
            v-if="isCurrentTab('#bahagian-g')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-g')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN G: SALINAN DOKUMEN SOKONGAN</h4>
                <maklumat-dokumen-sokongan
                  :default_docs.sync="maklumat.dokumen_rs"

                  :show-kuiri="false"

                  :readonly="!canAccessSection('g')"
                ></maklumat-dokumen-sokongan>
              </div>
            </div>
          </div>
          <div
            v-if="isCurrentTab('#bahagian-h')"
            class="tab-pane fade"
            :class="{'show active': isCurrentTab('#bahagian-h')}"
            role="tabpanel"
          >
            <div class="card bg-light">
              <div class="card-body">
                <h4 class="card-title">BAHAGIAN H: PENGAKUAN PEMAJU PERUMAHAN</h4>
                <bahagian-h
                  :pengarah="pengarah"
                  @submit="submit"
                ></bahagian-h>
              </div>
              <div class="card-footer">
                <div class="text-right">
                  <button class="btn btn-primary" @click="submit" :disabled="submitting">SEMAK & HANTAR</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA";
import BahagianB from "./BahagianB";
import BahagianB1 from "./BahagianB1";
import BahagianC from "./BahagianC";
import BahagianC1 from "./BahagianC1";
import BahagianC2 from "./BahagianC2";
import BahagianC3 from "./BahagianC3";
import BahagianD from "./BahagianD";
import BahagianE from "./BahagianE";
import BahagianF from "./BahagianF";
import BahagianH from "./BahagianH";
import MaklumatDokumenSokongan from "../../common/MaklumatDokumenSokongan";
import ErrorBar from "../../common/ErrorBar";

export default {
  name: "Borang",
  components: {
    ErrorBar,
    MaklumatDokumenSokongan,
    BahagianA,
    BahagianB,
    BahagianB1,
    BahagianC,
    BahagianC1,
    BahagianC2,
    BahagianC3,
    BahagianD,
    BahagianE,
    BahagianF,
    BahagianH,
  },

  props: {
    pengarah: Object,

    default_maklumat: Object,
    default_permohonan: Object,

    // tanah
    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,

    // pembangunan
    kodJenisKediamanRs: Array,
    kodJenisPerjanjianRs: Array,

    // pemaju
    kodKategoriPemajuRs: Array,
    kodTarafPemajuRs: Array,
    kodJenisPemajuRs: Array,

    // pemajuan
    kodJenisHakmilikRs: Array,
    kodPbtRs: Array,
    kodJenisPemajuanRs: Array,
    kodKaedahPemajuanRs: Array,
    kodSkimProjekRs: Array,

    // iklan
    kodJenisIklanRs: Array,

    // kelulusan agensi
    kodKelulusanAgensiRs: Array,

    // misc
    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,

    docConfigs: Array,
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    // this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      submitting: false,
      errorMessages: {},
      tab: this.getDefaultTab(),
      maklumat: JSON.parse(JSON.stringify(this.default_maklumat)),
      permohonan: JSON.parse(JSON.stringify(this.default_permohonan)),
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.getDefaultTab();
    },

    getDefaultTab() {
      const hash_prefix = "#bahagian-";

      const sections = ['a', 'b', 'b1', 'c', 'c1', 'c2', 'c3', 'd', 'e', 'f', 'g', 'h'];
      for (let i = 0; i < sections.length; i++) {
        const section = sections[i];

        if (this.canAccessSection(section)) {
          return hash_prefix + section;
        }
      }
    },

    canAccessSection(section) {
      if (this.isStatusKuiri) {
        return !!this.hasKuiriSection(section);
      }

      return this.isLulusBersyarat && section == 'e';
    },

    hasKuiriSection(section) {
      return this.maklumat.kuiri_rs.filter(kuiri => this.maklumat.kuiri_no == kuiri.kuiri_no).find(kuiri => kuiri.seksyen == section);
    },

    async submit(ev) {
      this.submitting = true;

      ev.preventDefault();

      this.errorMessages = {};

      try {
        const response = await axios.post(`/pemaju/apdl-lesen-baru/${this.maklumat.id}/semak`)

        swalconfirm()
          .then(result => {
            if (result.value) {
              ev.target.form.submit();
            }
          });
      } catch(error) {
        this.errorMessages = error.response.data;
      }

      this.submitting = false;
    },
  },

  computed: {
    isStatusKuiri() {
      return ['K1', 'K2', 'K3', 'KB'].indexOf(this.permohonan.status_id) !== -1;
    },

    isLulusBersyarat() {
      return this.permohonan.status_id == 'LB';
    }
  }
}
</script>

<style scoped>

</style>
