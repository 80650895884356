<template>
  <div>
    <div class="alert alert-warning" v-if="kuiri">
      <strong>Catatan Kuiri:</strong>
      <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
    </div>

    <template v-if="maklumat.tanah_rs.length">
      <tanah-nav
        :kod-negeri-rs="kodNegeriRs"
        :kod-daerah-rs="kodDaerahRs"
        :kod-bandar-rs="kodBandarRs"
        :kod-bank-hq-rs="kodBankHqRs"

        :kod-syarat-nyata-rs="kodSyaratNyataRs"
        :kod-status-tanah-rs="kodStatusTanahRs"
        :kod-rezab-tanah-rs="kodRezabTanahRs"
        :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
        :kod-bebanan-tanah-rs="kodBebananTanahRs"
        :kod-jenis-kaveat-rs="kodJenisKaveatRs"

        :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"

        :kod-jenis-tuan-tanah-rs="kodJenisTuanTanahRs"
        :kod-jenis-urusan-rs="kodJenisUrusanRs"
        :kod-pemilikan-pembeli-rs="kodPemilikanPembeliRs"

        :mahkamah-tinggi="mahkamahTinggi"
        :pengarah-rs="pengarahRs"
        :kod-poskod-rs="kodPoskodRs"
        :kod-poskod-bandar-rs="kodPoskodBandarRs"

        :readonly="readonly"
      >
        <template #default="{ currentActive, setCurrent }">
          <div class="text-right mb-2" v-if="!readonly">
            <button class="btn btn-success" @click.prevent="addTanah(setCurrent)">TAMBAH BUTIRAN TANAH</button>
          </div>

          <tanah
            v-for="(tanah, index) in maklumat.tanah_rs"
            :no="index + 1"
            :show="currentActive === index"
            :tanah="tanah"
            :maklumat="maklumat"
            :key="`tanah-${tanah.id}`"

            @click="setCurrent(currentActive === index ? null : index)"

            @editTanah="(form, cb) => editTanah(tanah, form, cb)"
            @destroyTanah="destroyTanah(tanah)"

            @addButiran="addButiran(tanah)"

            :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
            :readonly="readonly"

            :kod-negeri-rs="kodNegeriRsFiltered"
            :kod-daerah-rs="kodDaerahRs"
            :kod-bandar-rs="kodBandarRs"
            :kod-bank-hq-rs="kodBankHqRs"
            :kod-parlimen-rs="kodParlimenRs"
            :kod-dun-rs="kodDunRs"
            :kod-poskod-rs="kodPoskodRs"
            :kod-poskod-bandar-rs="kodPoskodBandarRs"

            :kod-syarat-nyata-rs="kodSyaratNyataRs"
            :kod-status-tanah-rs="kodStatusTanahRs"
            :kod-rezab-tanah-rs="kodRezabTanahRs"
            :kod-kegunaan-tanah-rs="kodKegunaanTanahRs"
            :kod-bebanan-tanah-rs="kodBebananTanahRs"
            :kod-jenis-kaveat-rs="kodJenisKaveatRs"

            :kod-perihal-hakmilik-rs="kodPerihalHakmilikRs"
          >
            <template #default>
              <template v-if="tanah.butiran_rs.length">
                <butiran-tanah
                  v-for="(butiran, index) in tanah.butiran_rs"
                  :no="index + 1"
                  :butiran="butiran"
                  :key="`butiran-${butiran.id}`"

                  @editButiran="editButiran(tanah, butiran)"
                  @destroyButiran="destroyButiran(tanah, butiran)"

                  @addTuanTanah="addTuanTanah(tanah, butiran)"
                  @editTuanTanah="(tuan) => editTuanTanah(tanah, butiran, tuan)"
                  @destroyTuanTanah="(tuan) => destroyTuanTanah(tanah, butiran, tuan)"

                  :readonly="readonly"
                ></butiran-tanah>
              </template>
              <div v-else-if="!readonly" class="alert alert-warning mb-0">Sila tambah butiran tanah.</div>
              <div v-else class="alert alert-warning mb-0">Tiada maklumat butiran tanah.</div>

            </template>

          </tanah>
        </template>
      </tanah-nav>

    </template>
    <div v-else-if="readonly" class="alert alert-warning">Tiada maklumat tanah.</div>
    <div v-else>
      <div class="text-right mb-2" v-if="!readonly">
        <button class="btn btn-success" @click.prevent="addTanah()">TAMBAH BUTIRAN TANAH</button>
      </div>

      <div class="alert alert-warning">
        Sila tambah tanah.
      </div>
    </div>


    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>

    <tanah-modal
      :kod-negeri-rs="kodNegeriRsFiltered"
      :kod-bandar-rs="kodBandarRs"
      :kod-daerah-rs="kodDaerahRs"
      :kod-parlimen-rs="kodParlimenRs"
      :kod-dun-rs="kodDunRs"
      :kod-jenis-hakmilik-rs="kodJenisHakmilikRs"
      :readonly="readonly"
    ></tanah-modal>
  </div>
</template>

<script>
import TanahModal from "../../common/Tanah/TanahModal";

import {jsonToFormData} from "../../../lib/utils";


export default {
  name: "BahagianC",
  components: {TanahModal},

  props: {
    maklumat: Object,

    kodJenisHakmilikRs: Array,

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
    kodBankHqRs: Array,
    kodParlimenRs: Array,
    kodDunRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,

    kodKegunaanTanahRs: Array,
    kodStatusTanahRs: Array,
    kodRezabTanahRs: Array,
    kodSyaratNyataRs: Array,
    kodBebananTanahRs: Array,
    kodJenisKaveatRs: Array,

    kodPerihalHakmilikRs: Array,

    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    mahkamahTinggi: Array,
    pengarahRs: Array,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    save() {
      this.$success();
    },

    addTanah(callback) {
      vmodal.open('tanahmodal', {}, (tanah, {setLoading, errorCallback, close}) => {
        setLoading(true);

        axios.post(`/pemaju/setup/bahagian-c/tanah`, tanah)
          .then((response) => {
            this.$emit('update', response.data);
            this.$success();

            if (callback) {
              this.$nextTick(() => {
                callback(this.maklumat.tanah_rs.length - 1);
              })
            }

            close();
          })
          .catch((error) => {
            errorCallback(error)
          })
          .then(() => {
            setLoading(false);
          })
      })
    },

    editTanah(tanah, form, cb) {
      axios.post(`/pemaju/setup/bahagian-c/tanah/${tanah.id}`, form)
        .then(response => {
          this.$emit('update', response.data);
          cb()
        })
    },

    destroyTanah(tanah) {
      swalconfirm()
        .then(result => {
          if (result.value) {
            axios.delete(`/pemaju/setup/bahagian-c/tanah/${tanah.id}`)
              .then(response => {
                this.$emit('update', response.data);
              })
          }
        });
    },

    addButiran(tanah) {
      vmodal.open(`tambahbutirantanah-${tanah.id}`, {}, (butiran, {setLoading, errorCallback, close}) => {
        setLoading(true);

        const data = jsonToFormData(butiran);

        axios.post(`/pemaju/setup/bahagian-c/tanah/${tanah.id}/butiran`, data)
          .then((response) => {
            this.$emit('update', response.data);
            this.$success();
            close();
          })
          .catch((error) => {
            errorCallback(error)
          })
          .then(() => {
            setLoading(false);
          })
      })
    },

    editButiran(tanah, default_butiran) {
      vmodal.open(`tambahbutirantanah-${tanah.id}`, {
        ...default_butiran,
      }, (butiran, {setLoading, errorCallback, close}) => {
        setLoading(true);

        const data = jsonToFormData(butiran);

        axios.post(`/pemaju/setup/bahagian-c/tanah/${tanah.id}/butiran/${default_butiran.id}`, data)
          .then((response) => {
            this.$emit('update', response.data);
            this.$success();
            close();
          })
          .catch((error) => {
            errorCallback(error)
          })
          .then(() => {
            setLoading(false);
          })
      })
    },

    destroyButiran(tanah, butiran) {
      swalconfirm()
        .then(result => {
          if (result.value) {
            axios.delete(`/pemaju/setup/bahagian-c/tanah/${tanah.id}/butiran/${butiran.id}`)
              .then(response => {
                this.$emit('update', response.data);
              })
          }
        });
    },

    addTuanTanah(tanah, butiran) {
      vmodal.open('tuantanah', {
        tuan_tanah_rs: butiran.tuan_tanah_rs,
        maklumat: this.maklumat,
      }, (tuan_tanah, {setLoading, errorCallback, close}) => {
        setLoading(true);

        const data = jsonToFormData(tuan_tanah);

        axios.post(`/pemaju/setup/bahagian-c/butiran/${butiran.id}/tuan_tanah`, data)
          .then((response) => {
            this.$emit('update', response.data);
            this.$success();
            close();
          })
          .catch((error) => {
            errorCallback(error)
          })
          .then(() => {
            setLoading(false);
          })
      })
    },

    editTuanTanah(tanah, butiran, default_tuan_tanah) {
      vmodal.open('tuantanah', {
        ...default_tuan_tanah,
        tuan_tanah_rs: butiran.tuan_tanah_rs,
        maklumat: this.maklumat,
      }, (tuan_tanah, {setLoading, errorCallback, close}) => {
        setLoading(true);

        const data = jsonToFormData(tuan_tanah);

        axios.post(`/pemaju/setup/bahagian-c/butiran/${butiran.id}/tuan_tanah/${default_tuan_tanah.id}`, data)
          .then((response) => {
            this.$emit('update', response.data);
            this.$success();
            close();
          })
          .catch((error) => {
            errorCallback(error)
          })
          .then(() => {
            setLoading(false);
          })
      })
    },

    destroyTuanTanah(tanah, butiran, tuan_tanah) {
      swalconfirm()
        .then(result => {
          if (result.value) {
            axios.delete(`/pemaju/setup/bahagian-c/butiran/${butiran.id}/tuan_tanah/${tuan_tanah.id}`)
              .then(response => {
                this.$emit('update', response.data);
              })
          }
        });
    }
  },

  computed: {
    kodNegeriRsFiltered() {
      /*
        12 - Sabah
        13 - Sarawak
        98 - Luar Negara
      */
      const excludeId = ['12', '13', '98'];
      return this.kodNegeriRs.filter(item => !excludeId.includes(item.id));
    }
  }
}
</script>

<style scoped>

</style>
