<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps nav">
        <li>
          <a
            :class="{'show active': isCurrentTab('#bahagian-a')}"
            href="#bahagian-a"
          >A. BUTIRAN PEMAJU</a>
        </li>
        <li class>
          <a
            :class="{'show active': isCurrentTab('#bahagian-b')}"
            href="#bahagian-b"
          >B. MAKLUMAT KEWANGAN</a>
        </li>
        <li class v-if="!readonly">
          <a
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            href="#bahagian-c"
          >C. PERAKUAN PEMAJU PERUMAHAN</a>
        </li>
      </ul>

      <div class="tab-content mt-4 mx-0">
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-a')}"
          id="bahagian-a"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">BUTIRAN PEMAJU</h4>

              <bahagian-a
                :pemaju="pemaju"
                :pemajuan="pemajuan"
                :pengarah="pengarah"

                :pemaju_nama_pegawai.sync="pemaju_nama_pegawai"
                :pemaju_no_pejabat.sync="pemaju_no_pejabat"
                :pemaju_no_bimbit.sync="pemaju_no_bimbit"
                :pemaju_email.sync="pemaju_email"

              ></bahagian-a>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-b')}"
          id="bahagian-b"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">MAKLUMAT KEWANGAN</h4>

              <bahagian-b
                :sesi="sesi"
                :akaun-hda="akaunHda"
                :jaminan-bank-rs="jaminanBankRs"
                :sumber-biaya-rs="sumberBiayaRs"
                :bentuk-biaya-rs="bentukBiayaRs"
                :perihal-peraturan7-rs="perihalPeraturan7Rs"

                :default_bulan_rs.sync="bulan_rs"
                :default_sumber_kewangan_rs.sync="sumber_kewangan_rs"

                :nilai_hakmilik_ditebus.sync="nilai_hakmilik_ditebus"
                :nilai_jumlah_jualan.sync="nilai_jumlah_jualan"
                :nilai_jumlah_progbill.sync="nilai_jumlah_progbill"

                :readonly="readonly"
              ></bahagian-b>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          :class="{'show active': isCurrentTab('#bahagian-c')}"
          id="bahagian-c"
          role="tabpanel"
        >
          <bahagian-c
            :pengesahan.sync="pengesahan"
            :pengarah="pengarah"
            @submit="submit"
            :readonly="readonly"
            :tarikh_hantar="default_tarikh_hantar"
          ></bahagian-c>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BahagianA from "./BahagianA.vue";
import BahagianB from "./BahagianB.vue";
import BahagianC from "./BahagianC.vue";

export default {
  name: "Borang",

  components: {
    BahagianA,
    BahagianB,
    BahagianC
  },

  props: {
    akaunHda: {
      type: Object
    },

    pemaju: {
      type: Object
    },

    pemajuan: {
      type: Object
    },

    jaminanBankRs: {
      type: Array
    },

    pengarah: {
      type: Object
    },

    perihalPeraturan7Rs: {
      type: Array,
      default: []
    },

    sumberBiayaRs: {
      type: Array,
      default: []
    },

    bentukBiayaRs: {
      type: Array,
      default: []
    },

    sesi: {
      type: Object,
      default: () => ({
        id: "MAC",
        baki_bawa_bawah: 10000,
        bulan: ["JANUARI", "FEBRUARI", "MAC"]
      })
    },

    default_bulan_rs: {
      type: Array,
      default: []
    },

    default_sumber_kewangan_rs: {
      type: Array,
      default: []
    },

    default_nilai_hakmilik_ditebus: {
      default: '',
    },

    default_nilai_jumlah_jualan: {
      default: '',
    },

    default_nilai_jumlah_progbill: {
      default: '',
    },

    default_pemaju_nama_pegawai: String,
    default_pemaju_no_pejabat: String,
    default_pemaju_no_bimbit: String,
    default_pemaju_email: String,

    default_tarikh_hantar: String,

    errorBag: {
      type: Object
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    window.addEventListener("hashchange", this.changeTab, false);
    this.$makeErrorBag(this.errorBag);
    this.changeTab();
  },

  destroyed() {
    window.removeEventListener("hashchange", this.changeTab);
  },

  data() {
    return {
      defaultTab: "#bahagian-a",
      tab: this.defaultTab,

      sumber_kewangan_rs: this.default_sumber_kewangan_rs,
      bulan_rs: [
        this.createDefaultPerihal(0),
        this.createDefaultPerihal(1),
        this.createDefaultPerihal(2)
      ],

      pemaju_nama_pegawai: this.default_pemaju_nama_pegawai,
      pemaju_no_pejabat: this.default_pemaju_no_pejabat,
      pemaju_no_bimbit: this.default_pemaju_no_bimbit,
      pemaju_email: this.default_pemaju_email,

      nilai_hakmilik_ditebus: this.default_nilai_hakmilik_ditebus || 0,
      nilai_jumlah_jualan: this.default_nilai_jumlah_jualan || 0,
      nilai_jumlah_progbill: this.default_nilai_jumlah_progbill || 0,

      pengesahan: null,
    };
  },

  methods: {
    isCurrentTab(tab) {
      return this.tab === tab;
    },

    changeTab() {
      this.tab = window.location.hash || this.defaultTab;
    },

    createDefaultPerihal(bulan) {
      console.log({"bulan":bulan});
      console.log({"default_bulan_rs":this.default_bulan_rs});
      const data = {
        
        jumlah_masuk: this.default_bulan_rs.hasOwnProperty(bulan)
          ? parseFloat(this.default_bulan_rs[bulan].jumlah_masuk)
          : 0.00,
        jumlah_keluar: this.default_bulan_rs.hasOwnProperty(bulan)
          ? parseFloat(this.default_bulan_rs[bulan].jumlah_keluar)
          : 0.00,
        penyata_kewangan_bulanan: this.default_bulan_rs.hasOwnProperty(bulan)
          ? this.default_bulan_rs[bulan].penyata_kewangan_bulanan
          : null,
      };

      if (bulan === 0) {
        data.baki_bawa_bawah = this.default_bulan_rs.hasOwnProperty(bulan)
          ? (this.default_bulan_rs[bulan].baki_bawa_bawah || this.sesi.baki_bawa_bawah || 0.00)
          : (this.sesi.baki_bawa_bawah || 0.00);
      }

      this.perihalPeraturan7Rs.forEach((perihal) => {
        data[perihal.id] = this.default_bulan_rs.hasOwnProperty(bulan)
          ? parseFloat(this.default_bulan_rs[bulan][perihal.id])
          : 0.00;
      });
      return JSON.parse(JSON.stringify(data));
    },

    validate() {
      if (this.$validator.errors.first('pemaju_nama_pegawai') ||
        this.$validator.errors.first('pemaju_no_pejabat') ||
        this.$validator.errors.first('pemaju_no_bimbit') ||
        this.$validator.errors.first('pemaju_email')
      ) {
        window.location.hash = '#bahagian-a';

        return false;
      }

      if (!this.sumber_kewangan_rs.length) {
        this.$validator.errors.add({
          field: 'sumber_kewangan',
          msg: 'Sila isi maklumat sumber kewangan.'
        });

        if (this.tab === '#bahagian-b') {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0
          return false;
        }

        window.location.hash = '#bahagian-b';
        return false;
      }

      if (this.$validator.errors.first('pengesahan')) {
        window.location.hash = '#bahagian-c';

        return false;
      }

      if (this.$validator.errors.any()) {
        window.location.hash = '#bahagian-b';

        return false;
      }

      return true;
    },

    save(ev) {
      ev.preventDefault();
      ev.target.form.submit();
    },

    submit(ev) {
      ev.preventDefault();

      this.$validator.errors.clear();

      this.$validator.validateAll()
        .then(passes => {
          if (this.validate() && passes) {
            swalconfirm()
              .then(result => {
                if (result.value) {
                  ev.target.form.submit();
                }
              });
          }
        })
    }
  }
};
</script>
