<template>
  <div class="card">
    <div class="card-body form-compact">
      <div class="align-items-start" :class="{ 'form-check': !readonly }">
        <div class="row m-2">
          <label for="" class="form-check-label ml-2 text-justify">
            Menurut peruntukan-peruntukan Akta Pemajuan Perumahan (Kawalan dan Pelesenan) 1966 (Akta 118) &
            Peraturan-Peraturan dan Akta Akuan Berkanun 1960 (Akta 113), tuan adalah dengan ini dikehendaki
            mengemukakan kepada Pengawal dan juga menyiarkan dalam Warta suatu salinan laporan juruaudit yang
            disediakan di bawah Seksyen 9 berserta kunci kira-kira dan penyata akaun untung ruginya.
            Jika tuan gagal mengemukakan laporan ini dalam masa yang ditetapkan atau membuat kenyataan palsu tentang
            mana-mana butiran, atau gagal memberitahu pertukaran alamat dan pindaan tahun kewangan berakhir, tuan
            boleh didakwa atau dikenakan penalti menurut Seksyen 19 Akta Pemajuan Perumahan (Kawalan dan Pelesenan)
            1966 (Akta 118) dan Peraturan-Peraturan serta Seksyen 199 dan 200 Kanun Keseksaan (Akta 574) dan Akta
            Akuan Berkanun 1960 (Akta 113).
          </label>
        </div>
        <!-- <div class="row m-2">
          <label for="pengesahan" class="form-check-label ml-2 justify-content">
            Jika tuan gagal mengemukakan laporan ini dalam masa yang ditetapkan atau membuat kenyataan palsu tentang
            mana-mana butiran, atau gagal memberitahu pertukaran alamat dan pindaan tahun kewangan berakhir, tuan
            boleh didakwa atau dikenakan penalti menurut Seksyen 19 Akta Pemajuan Perumahan (Kawalan dan Pelesenan)
            1966 (Akta 118) dan Peraturan-Peraturan serta Seksyen 199 dan 200 Kanun Keseksaan (Akta 574) dan Akta
            Akuan Berkanun 1960 (Akta 113).
          </label>
        </div> -->
        <div class="row m-2">
          <input id="pengesahan" type="checkbox" name="pengesahan" class="form-check-input"
            @input="$emit('update:pengesahan', $event.target.checked)" :checked="pengesahan"
            :class="{ 'is-invalid': errors.first('pengesahan') }" v-validate="'required'">
          <label for="pengesahan" class="form-check-label ml-2 text-justify">
            Saya, <b>{{ pengarah.nama }}</b>, No. Kad Pengenalan, <b>{{ pengarah.no_kp }}</b> bersumpah
            dan mengaku sepanjang pengetahuan dan kepercayaan saya/ kami, butir-butir
            yang diberikan di dalam perakuan ini adalah benar dan lengkap dan faham bahawa apa-apa salah penyataan
            butir-butir yang dikemukakan adalah menjadi satu kesalahan di bawah Seksyen 7(e) Akta Pemajuan
            Perumahan (Kawalan dan Pelesenan) 1966 dan Akta Akuan Berkanun 1960.
          </label>
        </div>
      </div>
      <br />
      <!-- <div class="form-group form-row">
        <label for="pengesahan" class="form-check-label ml-2 text-justify">
          Menurut peruntukan-peruntukan Akta Pemajuan Perumahan (Kawalan dan Pelesenan) 1966 (Akta 118) &
          Peraturan-Peraturan dan Akta Akuan Berkanun 1960 (Akta 113), tuan adalah dengan ini dikehendaki
          mengemukakan kepada Pengawal dan juga menyiarkan dalam Warta suatu salinan laporan juruaudit yang
          disediakan di bawah Seksyen 9 berserta kunci kira-kira dan penyata akaun untung ruginya.
        </label>
        <label for="pengesahan" class="form-check-label mt-2 ml-2 text-justify">
          Jika tuan gagal mengemukakan laporan ini dalam masa yang ditetapkan atau membuat kenyataan palsu tentang
          mana-mana butiran, atau gagal memberitahu pertukaran alamat dan pindaan tahun kewangan berakhir, tuan
          boleh didakwa atau dikenakan penalti menurut Seksyen 19 Akta Pemajuan Perumahan (Kawalan dan Pelesenan)
          1966 (Akta 118) dan Peraturan-Peraturan serta Seksyen 199 dan 200 Kanun Keseksaan (Akta 574) dan Akta
          Akuan Berkanun 1960 (Akta 113).
        </label>
        <input id="pengesahan" type="checkbox" name="pengesahan" class="form-check-input"
          @input="$emit('update:pengesahan', $event.target.checked)" :checked="pengesahan"
          :class="{ 'is-invalid': errors.first('pengesahan') }" v-validate="'required'">
        <label for="pengesahan" class="form-check-label mt-2 ml-2 text-justify">
          Saya, <b>{{ pengarah.nama }}</b>, No. Kad Pengenalan, <b>{{ pengarah.jawatan }}</b> bersumpah
          dan mengaku sepanjang pengetahuan dan kepercayaan saya/ kami, butir-butir
          yang diberikan di dalam perakuan ini adalah benar dan lengkap dan faham bahawa apa-apa salah penyataan
          butir-butir yang dikemukakan adalah menjadi satu kesalahan di bawah Seksyen 7(e) Akta Pemajuan
          Perumahan (Kawalan dan Pelesenan) 1966 dan Akta Akuan Berkanun 1960.
        </label>
      </div>
      <!-- <br />
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Nama :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ pengarah.nama }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Jawatan :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ pengarah.jawatan }}</span>
        </div>
      </div>
      <div class="form-group form-row">
        <label class="col-form-label col-md-2">Tarikh :</label>
        <div class="col-md-4">
          <span class="form-control-plaintext">{{ tarikh_hantar }}</span>
        </div>
      </div> -->
      <div class="text-center" v-if="!readonly">
        <button class="btn btn-primary" @click="$emit('submit', $event)">HANTAR</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ['$validator'],

  props: {
    tarikh_hantar: String,

    readonly: {
      type: Boolean,
      default: false,
    },

    pengesahan: Boolean,

    pengarah: Object,
  }
}
</script>
