import locale from "./locale/my";

window.VeeValidate = require("vee-validate");

VeeValidate.Validator.extend("latitud", {
    getMessage: field => `Nilai ${field} mesti diantara -90 dan 90.`,
    validate: val => {

        return val > -90 && val < 90;
    }
});

VeeValidate.Validator.extend("longitud", {
    getMessage: field => `Nilai ${field} mesti diantara -180 dan 180.`,
    validate: val => {

        return val > -180 && val < 180;
    }
});

VeeValidate.Validator.extend("greaterThanZero", {
    getMessage: field => `Nilai ${field} mesti lebih daripada 0.`,
    validate: val => {
        if (val > 0 ) return true;
        return false;
    }
});

var isEmptyArray = function (arr) {
    return Array.isArray(arr) && arr.length === 0;
};

VeeValidate.Validator.extend('required_if_has', {
    getMessage: field => `Nilai ${field} mesti diantara -180 dan 180.`,
    validate: (val, [otherValue]) => {
        let required = !(isEmptyArray(value) || [false, null, undefined].includes(otherValue));

        if (!required) {
            return {
                valid: true,
                data: {
                    required: required,
                }
            }
        }

        let invalid = (isEmptyArray(value) || [false, null, undefined].includes(value));

        invalid = invalid || !String(value).trim().length;

        return {
            valid: !invalid,
            data: {
                required: required
            }
        };
    },
},
{
    computesRequired: true,
    hasTarget: true
})

VeeValidate.Validator.localize('my', locale);
