var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[(_vm.kuiri)?_c('div',{staticClass:"alert alert-warning"},[_c('strong',[_vm._v("Catatan Kuiri:")]),_vm._v(" "),_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(_vm.kuiri.catatan))])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("doc_surat_permohonan")},attrs:{"type":"file","accept":"application/pdf","name":"doc_surat_permohonan","doc":_vm.maklumat.doc_surat_permohonan,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('doc_surat_permohonan', file, callback);
},"remove":function($event){return _vm.removeDoc('doc_surat_permohonan')}}})],1)]),_vm._v(" "),_c('div',{staticClass:"form-group row"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"col-md-8"},[_c('file-upload',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],staticClass:"form-control w-100",class:{'is-invalid': _vm.errors.first("doc_dl_terkini")},attrs:{"type":"file","accept":"application/pdf","name":"doc_dl_terkini","doc":_vm.maklumat.doc_dl_terkini,"readonly":_vm.readonly},on:{"uploadwithcallback":function (ref) {
	var file = ref.file;
	var callback = ref.callback;

	return _vm.uploadDoc('doc_dl_terkini', file, callback);
},"remove":function($event){return _vm.removeDoc('doc_dl_terkini')}}})],1)])]),_vm._v(" "),(!_vm.readonly)?_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.succesfulMessage)?_c('span',{staticClass:"text-success mr-2"},[_vm._v(_vm._s(_vm.succesfulMessage))]):_vm._e()]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.save.apply(null, arguments)}}},[_vm._v("Simpan")])],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("1.\tSurat Permohonan Pembatalan "),_c('span',{staticClass:"label-required"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"col-form-label col-md-4",staticStyle:{"white-space":"pre-wrap"}},[_vm._v("2.\tDokumen DL Terkini "),_c('span',{staticClass:"label-required"},[_vm._v("*")])])}]

export { render, staticRenderFns }