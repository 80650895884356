var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card bg-light"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v("SENARAI FIRMA DAN KONTRAKTOR")]),_vm._v(" "),_c('div',{staticClass:"card"},[_c('h4',{staticClass:"card-header"},[_vm._v("FIRMA BUKAN TEKNIKAL")]),_vm._v(" "),_c('div',{staticClass:"table-responsive"},[_c('multi-list',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{attrs:{"width":"15%"}},[_vm._v("Jenis Firma")]),_vm._v(" "),_c('th',{attrs:{"width":"15%"}},[_vm._v("Nama Syarikat")]),_vm._v(" "),_c('th',{attrs:{"width":"21%"}},[_vm._v("Alamat")]),_vm._v(" "),_c('th',{attrs:{"width":"12%"}},[_vm._v("Telefon")]),_vm._v(" "),_c('th',{attrs:{"width":"12%"}},[_vm._v("Faks")]),_vm._v(" "),_c('th',{attrs:{"width":"10%"}},[_vm._v("Emel")]),_vm._v(" "),(!_vm.readonly)?_c('th'):_vm._e()])]},proxy:true},{key:"default",fn:function(){return [(!_vm.default_perunding_bukan_teknikal_rs.length)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"alert alert-warning alert-sm"},[_vm._v("Sila isi semua butiran dan tekan butang 'TAMBAH'")])])]):_vm._l((_vm.default_perunding_bukan_teknikal_rs),function(row,index){return _c('row-papar-butiran-firma',{key:("row-" + index),attrs:{"row":row,"readonly":_vm.readonly,"jenis-rs":_vm.jenisPerundingBukanTeknikalRs,"loading":_vm.processing},on:{"update":function (ref) {
var form = ref.form;
var callback = ref.callback;
 _vm.kemaskiniPerundingBukanTeknikal(row, form,callback) },"remove":function($event){return _vm.removePerundingBukanTeknikal(index)}}})}),_vm._v(" "),(!_vm.readonly)?_c('row-tambah-butiran-firma',{attrs:{"jenis-rs":_vm.jenisPerundingBukanTeknikalRs,"loading":_vm.processing},on:{"add":function (ref) {
var form = ref.form;
var callback = ref.callback;
 _vm.addPerundingBukanTeknikal(form,callback) }}}):_vm._e()]},proxy:true}])})],1),_vm._v(" "),_c('h4',{staticClass:"card-header"},[_vm._v("FIRMA PERUNDING")]),_vm._v(" "),_c('div',{staticClass:"table-responsive"},[_c('multi-list',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{attrs:{"width":"15%"}},[_vm._v("Jenis Firma")]),_vm._v(" "),_c('th',{attrs:{"width":"15%"}},[_vm._v("Nama Perunding")]),_vm._v(" "),_c('th',{attrs:{"width":"21%"}},[_vm._v("Alamat")]),_vm._v(" "),_c('th',{attrs:{"width":"12%"}},[_vm._v("Telefon")]),_vm._v(" "),_c('th',{attrs:{"width":"12%"}},[_vm._v("Faks")]),_vm._v(" "),_c('th',{attrs:{"width":"10%"}},[_vm._v("Emel")]),_vm._v(" "),(!_vm.readonly)?_c('th'):_vm._e()])]},proxy:true}])},[_vm._v(" "),[(!_vm.default_perunding_teknikal_rs.length)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"alert alert-warning alert-sm"},[_vm._v("Sila isi semua butiran dan tekan butang 'TAMBAH'")])])]):_vm._l((_vm.default_perunding_teknikal_rs),function(row,index){return _c('row-papar-butiran-firma',{key:("row-teknikal-" + index),attrs:{"row":row,"readonly":_vm.readonly,"jenis-rs":_vm.jenisPerundingTeknikalRs,"loading":_vm.processing},on:{"update":function (ref) {
var form = ref.form;
var callback = ref.callback;
 _vm.kemaskiniPerundingTeknikal(row, form,callback) },"remove":function($event){return _vm.removePerundingTeknikal(index)}}})}),_vm._v(" "),(!_vm.readonly)?_c('row-tambah-butiran-firma',{attrs:{"jenis-rs":_vm.jenisPerundingTeknikalRs,"loading":_vm.processing},on:{"add":function (ref) {
var form = ref.form;
var callback = ref.callback;
 _vm.addPerundingTeknikal(form,callback) }}}):_vm._e()]],2)],1),_vm._v(" "),_c('h4',{staticClass:"card-header"},[_vm._v("KONTRAKTOR")]),_vm._v(" "),_c('div',{staticClass:"table-responsive"},[_c('multi-list',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{attrs:{"width":"15%"}},[_vm._v("Jenis Firma")]),_vm._v(" "),_c('th',{attrs:{"width":"15%"}},[_vm._v("Nama Kontraktor")]),_vm._v(" "),_c('th',{attrs:{"width":"21%"}},[_vm._v("Alamat")]),_vm._v(" "),_c('th',{attrs:{"width":"12%"}},[_vm._v("Telefon")]),_vm._v(" "),_c('th',{attrs:{"width":"12%"}},[_vm._v("Faks")]),_vm._v(" "),_c('th',{attrs:{"width":"10%"}},[_vm._v("Emel")]),_vm._v(" "),(!_vm.readonly)?_c('th'):_vm._e()])]},proxy:true}])},[_vm._v(" "),[(!_vm.default_perunding_kontraktor_rs.length)?_c('tr',[_c('td',{attrs:{"colspan":"7"}},[_c('div',{staticClass:"alert alert-warning alert-sm"},[_vm._v("Sila isi semua butiran dan tekan butang 'TAMBAH'")])])]):_vm._l((_vm.default_perunding_kontraktor_rs),function(row,index){return _c('row-papar-butiran-firma',{key:("row-kontraktor-" + index),attrs:{"row":row,"readonly":_vm.readonly,"jenis-rs":_vm.jenisPerundingKontraktorRs,"loading":_vm.processing},on:{"update":function (ref) {
var form = ref.form;
var callback = ref.callback;
 _vm.kemaskiniPerundingKontraktor(row, form,callback) },"remove":function($event){return _vm.removePerundingKontraktor(index)}}})}),_vm._v(" "),(!_vm.readonly)?_c('row-tambah-butiran-firma',{attrs:{"jenis-rs":_vm.jenisPerundingKontraktorRs,"loading":_vm.processing},on:{"add":function (ref) {
var form = ref.form;
var callback = ref.callback;
 _vm.addPerundingKontraktor(form,callback) }}}):_vm._e()]],2)],1)])]),_vm._v(" "),_c('div',{staticClass:"card-footer"},[_c('div',{staticClass:"text-right"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.succesfulMessage)?_c('span',{staticClass:"text-success mr-2"},[_vm._v(_vm._s(_vm.succesfulMessage))]):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }