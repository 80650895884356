<template>
  <div class="card">
    <div class="card-body">
      <div class="alert alert-warning" v-if="kuiri">
        <strong>Catatan Kuiri:</strong>
        <div style="white-space: pre-wrap">{{ kuiri.catatan }}</div>
      </div>
      <div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;No. Hakmilik <span class="label-required">*</span></label>
          <div class="col-md-8">
            <input type="text" class="form-control" :value="form.sj_no_geran" @input="form.sj_no_geran = $event.target.value.toUpperCase()" :disabled="readonly">
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Negeri & Daerah <span class="label-required">*</span></label>
          <div class="col-md-4">
            <select2 v-model="form.sj_kod_negeri_id"
                     @change="form.sj_kod_daerah_id = null; form.kod_bandar_id = null; form.kod_parlimen_id = null; form.kod_dun_id = null;"
                     class="form-control"
                     v-validate="'required'"
                     name="sj_kod_negeri_id"
                     :class="{'is-invalid': errors.first('sj_kod_negeri_id')}"
                     :disabled="readonly"
            >
              <option :value="null">Sila Pilih Negeri</option>
              <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri-${negeri.id}`">{{ negeri.keterangan }}</option>
            </select2>
          </div>
          <div class="col-md-4">
            <select2 v-model="form.sj_kod_daerah_id"
                     @change="form.kod_bandar_id = null;"
                     class="form-control"
                     name="sj_kod_daerah_id"
                     v-validate="'required'"
                     :class="{'is-invalid': errors.first('sj_kod_daerah_id')}"
                     :disabled="readonly"
            >
              <option :value="null">Sila Pilih Daerah</option>
              <option v-for="daerah in filteredDaerahRs(form.sj_kod_negeri_id)" :value="daerah.id" :key="`daerah-${daerah.id}`">{{
                  daerah.keterangan
                }}
              </option>
            </select2>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">&#9;Mukim / Bandar / Pekan <span class="label-required">*</span></label>
          <div class="col-md-4">
            <select2 v-model="form.sj_kod_bandar_id" class="form-control"
                     v-validate="'required'"
                     name="sj_kod_bandar_id"
                     :class="{'is-invalid': errors.first('sj_kod_bandar_id')}"
                     :disabled="readonly"
            >
              <option :value="null">Sila Pilih Mukim / Bandar / Pekan</option>
              <option v-for="bandar in filteredBandarRs(form.sj_kod_daerah_id)" :value="bandar.id" :key="`bandar-${bandar.id}`">{{
                  bandar.keterangan
                }}
              </option>
            </select2>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Sijil Akuan Juruukur Malaysia</label>
          <div class="col-md-8">
            <file-upload
              type="file" accept="application/pdf"
              name="sj_doc_rujukan"
              class="form-control w-100"
              :class="{'is-invalid': errors.first(`sj_doc_rujukan`)}"
              :doc="maklumat.sj_doc_rujukan"
              @uploadwithcallback="({file, callback}) => uploadDoc('sj_doc_rujukan', file, callback)"
              @remove="removeDoc('sj_doc_rujukan')"
              v-validate="'required'"
              :readonly="readonly"
            />
            <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-form-label col-md-4" style="white-space: pre-wrap;">3.&#9;Tarikh Sijil Akuan Juruukur</label>
          <div class="col-12 col-sm-4 col-md-4">
            <datepicker
              :class="{'is-invalid': errors.first('sj_tarikh_akuan')}"
              name="sj_tarikh_akuan"
              v-model="form.sj_tarikh_akuan"
              v-validate="'required'"
              :disabled="readonly"
            >
            </datepicker>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="!readonly">
      <div class="text-right">
        <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
        <transition name="fade"><span class="text-success mr-2" v-if="succesfulMessage">{{ succesfulMessage }}</span></transition>
        <button class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BahagianH",
  props: {
    maklumat: Object,

    kuiri: null,

    readonly: {
      type: Boolean,
      default: false,
    },

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,
  },

  data() {
    return {
      loading: false,

      form: {
        sj_no_geran: this.maklumat.sj_no_geran,
        sj_kod_negeri_id: this.maklumat.sj_kod_negeri_id,
        sj_kod_daerah_id: this.maklumat.sj_kod_daerah_id,
        sj_kod_bandar_id: this.maklumat.sj_kod_bandar_id,
        sj_tarikh_akuan: this.maklumat.sj_tarikh_akuan,
      },
    }
  },

  methods: {
    save() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/bahagian-h`, this.form)
        .then((response) => {
          this.form = JSON.parse(JSON.stringify(response.data));
          this.$emit('update', response.data);
          this.$success();
        })
        .catch((error) => {
        })
        .then(() => {
          this.loading = false;
        })
    },

    uploadDoc(key, file, clear) {
      if (!this.maklumat) {
        return;
      }

      const formData = new FormData();

      // append the files to FormData
      formData.append('doc', file, file.name);

      // save it
      axios.post(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`, formData)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(response.data));

          this.$emit('update', maklumat)
          this.$success(`Berjaya muat naik dokumen. (${this.$options.filters.filesize(file.size)})`);
        })
        .catch((error) => {
          this.$hError(error)
        })
        .then(() => {
          clear();
        });
    },

    removeDoc(key) {
      if (!this.maklumat[key]) {
        return;
      }

      // save it
      axios.delete(`/pemaju/ap-pindaanpembaharuan/${this.maklumat.id}/dokumen/${key}`)
        .then((response) => {
          const maklumat = JSON.parse(JSON.stringify(this.maklumat));
          maklumat[key] = null;

          this.$emit('update', maklumat)
        })
        .catch(() => {

        });
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(item => item.kod_daerah_id == kod_daerah_id);
    },
  }
}
</script>

<style scoped>

</style>
