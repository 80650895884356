<template>
  <div v-if="isOpen" class="v-modal">
    <div class="modal-backdrop fade show"></div>

    <div class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel"
         style="display: block; padding-right: 17px;">
      <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLiveLabel">MAKLUMAT TUAN TANAH</h5>
            <button type="button" class="close" @click="close" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="container-fluid">
            <div class="form-group row">
              <div class="col-md-12">
                <div class="alert alert-info alert-dismissible fade show" role="alert">
                    <strong>Nota:</strong>Pilihan "PEMEGANG PAJAKAN" adalah kepada pemaju yang didaftarkan sebagai pemegang pajakan didalam rekod transaksi hakmilik tanah (Geran).
                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-8">
                Medan bertanda <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    style="display: inline-flex; align-items: center; line-height: 1;  height: 16px; margin-left: 5px; margin-right: 5px;"
                  ></img> akan dipaparkan dalam cetakan eSPA.
              </div>
            </div>
            <div class="form-group row mb-0">
              <div class="col-md-4"></div>
              <div class="col-md-8" v-if="tuan_tanah_rs.length > 0 && !modelId">
                <div class="form-group form-check" style="display: flex; align-items: center;">
                  <input type="checkbox" v-model="useDataPrevious" @change="useDataPreviousChange" class="form-check-input" id="checkbox-copy">
                  <label class="form-check-label ml-1" for="checkbox-copy">Gunakan Maklumat Tuan Tanah Sebelumnya</label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Jenis Ketuanpunyaan <span class="label-required">*</span></label>
              <div class="col-md-8 col-form-label">
                <div class="form-check form-check-inline" v-for="jenis_tuan_tanah in kodJenisTuanTanahRs" :key="`jenis_tuan_tanah-${jenis_tuan_tanah.id}`">
                  <input
                    :id="`jenis_tuan_tanah-${jenis_tuan_tanah.id}`"
                    class="form-check-input"
                    :class="{ 'is-invalid': errors.first('kod_jenis_tuan_tanah_id') }"
                    type="radio" name="kod_jenis_tuan_tanah_id"
                    v-model="form.kod_jenis_tuan_tanah_id"
                    :value="jenis_tuan_tanah.id"
                    v-validate="'required'"
                    :disabled="(jenis_tuan_tanah.id === 'WR' && filterTuanTanahExclude.length === 0) || (jenis_tuan_tanah.id === 'PP' && filterTuanTanahPajakanExclude.length === 0) ||  readonly || form.action === 'H'"
                  >
                  <label class="form-check-label" :for="`jenis_tuan_tanah-${jenis_tuan_tanah.id}`">{{ jenis_tuan_tanah.keterangan }}</label>
                </div>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Kategori Pemilik <span class="label-required">*</span></label>
              <div class="col-md-8">
                <select2 name="kod_kategori_pemilik_id"
                        class="form-control"
                        :class="{ 'is-invalid': errors.first('kod_kategori_pemilik_id') }"
                        v-model="form.kod_kategori_pemilik_id"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H' || disabledKategoriPemilik"
                >
                  <option :value="null">Sila Pilih Kategori Pemilik</option>
                  <option v-for="pemilikan in kodPemilikanPembeliRs" :value="pemilikan.id" :key="`pemilikan-${pemilikan.id}`">{{ pemilikan.keterangan }}</option>
                </select2>

              </div>
            </div>

            <section>
              <div class="form-group row">
                <label class="col-form-label col-md-4" for="nama" style="align-items: center; display: flex">3.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Nama <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                <div class="col-md-8">
                  <input
                    id="nama"
                    type="text" name="nama"
                    class="form-control" :class="{'is-invalid': errors.first('nama')}"
                    :value="form.nama" @input="form.nama = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>

              <section v-if="form.kod_jenis_tuan_tanah_id === 'BP'">
                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">4.&#9;Nama Enakmen</label>
                  <div class="col-md-8">
                    <input
                      id="nama_enakmen"
                      type="text" name="nama_enakmen"
                      class="form-control" :class="{'is-invalid': errors.first('nama_enakmen')}"
                      :value="form.nama_enakmen" @input="form.nama_enakmen = $event.target.value.toUpperCase()"
                      :disabled="readonly || form.action === 'H'"
                    >
                  </div>
                </div>
              </section>

              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_kp" style="align-items: center; display: flex" v-if="form.kod_kategori_pemilik_id === 'I'">{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '5' : '4' }}.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >No Kad Pengenalan<span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                <label class="col-form-label col-md-4" for="no_kp" style="align-items: center; display: flex" v-else-if="form.kod_kategori_pemilik_id === 'S'">{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '5' : '4' }}.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >No SSM <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                <label class="col-form-label col-md-4" for="no_kp" style="align-items: center; display: flex" v-else>{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '5' : '4' }}.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >No Kad Pengenalan / SSM <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                <div class="col-md-8">
                  <input
                    id="no_kp"
                    type="text" name="no_kp"
                    class="form-control" :class="{'is-invalid': errors.first('no_kp')}"
                    :value="form.no_kp" @input="form.no_kp = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" for="no_kp" style="align-items: center; display: flex" >{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '6' : '5' }}.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>
                  No SSM Baru <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>

                <div class="col-md-8">
                  <input
                    id="no_ssm_baru"
                    type="text" name="no_ssm_baru"
                    class="form-control"
                    :value="form.no_ssm_baru" @input="form.no_ssm_baru = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :class="{'is-invalid': errors.first('no_ssm_baru')}"
                    :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>

              <div class="form-group row">
                <label for="alamat1_tuan_tanah" class="col-form-label col-md-4" style="align-items: center; display: flex">{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '7' : '6' }}.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>
                  Alamat <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                <div class="col-md-8">
                  <input
                    id="alamat1_tuan_tanah"
                    type="text" name="alamat1"
                    class="form-control" :class="{'is-invalid': errors.first('alamat1')}"
                    :value="form.alamat1" @input="form.alamat1 = $event.target.value.toUpperCase()"
                    v-validate="'required'"
                    :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input id="alamat2_tuan_tanah"
                         type="text" name="alamat2"
                         class="form-control"
                         :value="form.alamat2" @input="form.alamat2 = $event.target.value.toUpperCase()"
                         :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>
              <div class="form-group row">
                <div class="offset-md-4 col-md-8">
                  <input
                    id="alamat3_tuan_tanah"
                    type="text"
                    class="form-control"
                    :value="form.alamat3" @input="form.alamat3 = $event.target.value.toUpperCase()"
                    :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label for="poskod_tuan_tanah" class="col-form-label col-md-4" style="align-items: center; display: flex"><!--{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '8' : '7' }}.--><span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>&nbsp;&nbsp;&nbsp;Poskod <span class="label-required">*</span></label>

                <div class="col-md-4">
                  <select2
                    name="poskod"
                    @input="poskodChange"
                    class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('poskod')}"
                    v-model="form.poskod"
                    v-validate="'required'"
                    data-vv-as="Poskod"
                    :disabled="readonly || form.action === 'H'"
                  >
                    <option :value="null">Sila Pilih Poskod</option>
                    <option v-for="poskod in kodPoskodRs" :value="poskod.poskod" :key="`poskod_${poskod.id}`">{{ poskod.poskod }}</option>
                  </select2>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="align-items: center; display: flex"><!--{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '10' : '9' }}.--><span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>&nbsp;&nbsp;&nbsp;Bandar <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select2
                    name="kod_bandar_id"
                    class="form-control" :class="{'is-invalid': errors.first('kod_bandar_id')}"
                    v-model="form.kod_bandar_id"
                    v-validate="'required'"
                    :disabled="true"
                  >
                    <option :value="null">Sila Pilih Bandar</option>
                    <option v-for="bandar in kodPoskodBandarRs" :value="bandar.id" :key="`bandar_${bandar.id}`">{{ bandar.keterangan }}</option>
                  </select2>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="align-items: center; display: flex"><!--{{ form.kod_jenis_tuan_tanah_id === 'BP' ? '9' : '8' }}.--><span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>&nbsp;&nbsp;&nbsp;Negeri <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select2
                    name="kod_negeri_id"
                    class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('kod_negeri_id')}"
                    v-model="form.kod_negeri_id"
                    v-validate="'required'"
                    :disabled="true"
                  >
                    <option :value="null">Sila Pilih Negeri</option>
                    <option v-for="negeri in kodNegeriRs" :value="negeri.id" :key="`negeri_${negeri.id}`">{{ negeri.keterangan }}</option>
                  </select2>
                </div>
              </div>
            </section>

            <section v-if="form.kod_jenis_tuan_tanah_id === 'WR'">
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;"><!--10.-->&#9;Pemilik Asal <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select2
                    name="tuan_tanah_asal_id"
                    class="form-control" :class="{'is-invalid': errors.first('tuan_tanah_asal_id')}"
                    v-model="form.tuan_tanah_asal_id"
                    v-validate="'required'"
                    :disabled="readonly || form.action === 'H'"
                  >
                    <option :value="null">Sila Pilih Tuan Tanah Asal</option>
                    <option v-for="tuan_tanah in filterTuanTanahExclude" :value="tuan_tanah.id" :key="`tuan_tanah_${tuan_tanah.id}`">{{ tuan_tanah.nama }}</option>
                  </select2>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;"><!--11.-->&#9;Jenis Urusan <span class="label-required">*</span></label>
                <div class="col-md-8 col-form-label">
                  <div class="form-check form-check-inline" v-for="jenis_urusan in kodJenisUrusanRs" :key="`jenis_urusan-${jenis_urusan.id}`">
                    <input
                      :id="`jenis_urusan-${jenis_urusan.id}`"
                      class="form-check-input" :class="{ 'is-invalid': errors.first('kod_jenis_urusan_id') }"
                      type="radio" name="kod_jenis_urusan_id"
                      v-model="form.kod_jenis_urusan_id" :value="jenis_urusan.id"
                      v-validate="'required'"
                      :disabled="readonly || form.action === 'H'"
                    >
                    <label class="form-check-label" :for="`jenis_urusan-${jenis_urusan.id}`">{{ jenis_urusan.keterangan }}</label>
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;"><!--12.-->&#9;Muat Naik Dokumen Faraid / L.A / GP <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <file-upload class="form-control" v-if="doc_urusan || readonly" :doc="doc_urusan" @remove="doc_urusan = null; form.doc_urusan_id = null" :readonly="readonly || form.action === 'H'"></file-upload>
                  <input-file-new v-else class="form-control" v-model="form.doc_urusan_id"
                                  name="doc_urusan_id"
                                  :class="{ 'is-invalid': errors.first('doc_urusan_id') }"
                                  v-validate="'required'"
                  ></input-file-new>
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>

            </section>

            <section v-if="form.kod_jenis_tuan_tanah_id === 'PP'">
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">10.&#9;Pemberi Pajakan <span class="label-required">*</span></label>
                <div class="col-md-4">
                  <select2
                    name="tuan_tanah_asal_id"
                    class="form-control" :class="{'is-invalid': errors.first('tuan_tanah_asal_id')}"
                    v-model="form.tuan_tanah_asal_id"
                    v-validate="'required'"
                    :disabled="readonly || form.action === 'H'"
                  >
                    <option :value="null">Sila Pilih Pemberi Pajakan</option>
                    <option v-for="tuan_tanah in filterTuanTanahPajakanExclude" :value="tuan_tanah.id" :key="`tuan_tanah_${tuan_tanah.id}`">{{ tuan_tanah.nama }}</option>
                  </select2>
                  <!-- <div class="col-form-label-sm">Sila daftar tuan tanah jenis ketuanpunyaan pemberi pajakan terlebih dahulu.</div> -->
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">11.&#9;Mempunyai Wakil Kuasa? <span class="label-required">*</span></label>
                <div class="col-md-8 col-form-label">
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input"
                        v-model="form.wakilkuasa_ada" name="wakilkuasa_ada"
                        :id="`wakilkuasa_ada_1`" :value="1" :disabled="readonly">
                    <label class="form-check-label" :for="`wakilkuasa_ada_1`">Ada</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" v-model="form.wakilkuasa_ada" name="wakilkuasa_ada"
                          :id="`wakilkuasa_ada_0`" :value="0" :disabled="readonly">
                    <label class="form-check-label" :for="`wakilkuasa_ada_0`">Tiada</label>
                  </div>
                  <div v-if="form.wakilkuasa_ada" class="col-form-label-sm">Sila daftar Maklumat Wakil Kuasa di tab PROFIL > Wakil Kuasa</div>
                </div>
              </div>
              <hr>
              <h5>Maklumat Pajakan Persendirian <i>(Private Lease)</i></h5>
              <P class="d-block mb-3">Sekiranya pemegang pajak mempunyai surat ikatan amanah, sila isi semua maklumat dibawah.</P>
              <div class="form-group row">
                <label class="col-form-label col-10 col-md-4" style="white-space: pre-wrap;">12.&#9;Tempoh Pajakan</label>
                <div class="col-10 col-md-4 ">
                  <div class="input-group mb-3">
                    <input type="number" id="tempoh_pajakan" name="tempoh_pajakan"
                    class="form-control" :class="{'is-invalid': errors.first('tempoh_pajakan')}"
                    :value="form.tempoh_pajakan" @input="form.tempoh_pajakan = $event.target.value.toUpperCase()"
                    :disabled="readonly || form.action === 'H'"
                    v-validate="'numeric'"
                    @change="getTarikhLuputPajakan()">
                    <div class="input-group-append">
                      <span class="input-group-text">Tahun</span>
                    </div>
                  </div>
                </div>
                <!-- <div class="col-4 col-md-2">
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" id="tempoh_pajakan_bulan"
                    :value="form.diff_in_month" disabled>
                    <div class="input-group-append">
                      <span class="input-group-text">Bulan</span>
                    </div>
                  </div>
                </div>
                <div class="col-4 col-md-2 ">
                  <div class="input-group mb-3">
                    <input type="number" class="form-control" id="tempoh_pajakan_hari"
                    :value="form.diff_in_day" disabled>
                    <div class="input-group-append">
                      <span class="input-group-text">Hari</span>
                    </div>
                  </div>
                </div> -->
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">13.&#9;Tarikh Mula Pajakan</label>
                <div class="col-md-4">
                  <datepicker name="tarikh_mula_pajakan"
                    :class="{ 'is-invalid': errors.first('tarikh_mula_pajakan') }"
                    :change="getTarikhLuputPajakan()"
                    v-model="form.tarikh_mula_pajakan"
                    :disabled="readonly">
                  </datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">14.&#9;Tarikh Luput Pajakan</label>
                <div class="col-md-4">
                  <datepicker name="tarikh_luput_pajakan"
                    :class="{ 'is-invalid': errors.first('tarikh_luput_pajakan') }"
                    v-model="form.tarikh_luput_pajakan"
                    :disabled="readonly">
                  </datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">15.&#9;Tarikh Daftar Perserahan</label>
                <div class="col-md-4">
                  <datepicker name="tarikh_daftar_perserahan"
                    :class="{ 'is-invalid': errors.first('tarikh_daftar_perserahan') }"
                    v-model="form.tarikh_daftar_perserahan"
                    :disabled="readonly">
                  </datepicker>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">16.&#9;No. Perserahan</label>
                <div class="col-md-4">
                  <input
                    id="no_perserahan"
                    type="text" name="no_perserahan"
                    class="form-control" :class="{'is-invalid': errors.first('no_perserahan')}"
                    :value="form.no_perserahan" @input="form.no_perserahan = $event.target.value.toUpperCase()"
                    :disabled="readonly || form.action === 'H'"
                  >
                </div>
              </div>
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;">17.&#9;Dokumen Sokongan(Borang 15A KTN)</label>
                <div class="col-md-8">
                  <file-upload class="form-control" v-if="doc_surat_ikatan_amanah || readonly" :doc="doc_surat_ikatan_amanah" @remove="doc_surat_ikatan_amanah = null; form.doc_surat_ikatan_amanah_id = null" :readonly="readonly || form.action === 'H'"></file-upload>
                  <input-file-new v-else class="form-control"
                    v-model="form.doc_surat_ikatan_amanah_id"
                    name="doc_surat_ikatan_amanah_id"
                    :class="{ 'is-invalid': errors.first('doc_surat_ikatan_amanah_id') }"
                  ></input-file-new>
                  <div class="text-dark col-form-label-sm">(Nota: Sila cantumkan dokumen sokongan borang 14A KTN sekiranya ada bersama dokumen sokongan borang 15A KTN )</div>
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>
            </section>

            <section v-if="form.kod_jenis_tuan_tanah_id === 'JV'">
              <!-- {{ doc_jointventure }} -->
              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;"><!--10.-->&#9;Perjanjian Usahasama <span class="label-required">*</span></label>
                <div class="col-md-8">
                  <file-upload class="form-control" v-if="doc_jointventure || readonly" :doc="doc_jointventure" @remove="doc_jointventure = null; form.doc_jointventure_id = null" :readonly="readonly || form.action === 'H'"></file-upload>
                  <input-file-new v-else class="form-control" v-model="form.doc_jointventure_id"
                                  name="doc_jointventure_id"
                                  :class="{ 'is-invalid': errors.first('doc_jointventure_id') }"
                                  v-validate="'required'"
                  ></input-file-new>
                  <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4" style="white-space: pre-wrap;"><!--11.-->&#9;Mempunyai Wakil Kuasa? <span class="label-required">*</span></label>
                <div class="col-md-8 col-form-label">
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input"
                      v-model="form.wakilkuasa_ada" name="wakilkuasa_ada"
                           :id="`wakilkuasa_ada_1`" :value="1" :disabled="readonly">
                    <label class="form-check-label" :for="`wakilkuasa_ada_1`">Ada</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input type="radio" class="form-check-input" v-model="form.wakilkuasa_ada" name="wakilkuasa_ada"
                           :id="`wakilkuasa_ada_0`" :value="0" :disabled="readonly">
                    <label class="form-check-label" :for="`wakilkuasa_ada_0`">Tiada</label>
                  </div>
                </div>
              </div>

              <section v-if="form.wakilkuasa_ada">
                <h5>Maklumat Penerima Wakil Kuasa</h5>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">1.&#9;Surat Wakil Kuasa <span class="label-required">*</span></label>
                  <div class="col-md-8">
                    <file-upload class="form-control" v-if="doc_wakilkuasa || readonly" :doc="doc_wakilkuasa" @remove="doc_wakilkuasa = null; form.doc_wakilkuasa_id = null" :readonly="readonly || form.action === 'H'"></file-upload>
                    <input-file-new v-else class="form-control" v-model="form.doc_wakilkuasa_id"
                                    name="doc_wakilkuasa_id"
                                    :class="{ 'is-invalid': errors.first('doc_wakilkuasa_id') }"
                                    v-validate="'required'"
                    ></input-file-new>
                    <div class="text-danger col-form-label-sm">Hanya dokumen berformat PDF sahaja dibenarkan</div>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-form-label col-md-4" style="white-space: pre-wrap;">2.&#9;Jenis Wakil Kuasa <span class="label-required">*</span></label>
                  <div class="col-md-8">
                    <select
                      id="wakilkuasa_jenis"
                      v-validate="'required'"
                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_jenis')}"
                      name="wakilkuasa_jenis"
                      v-model="form.wakilkuasa_jenis"
                      :disabled="readonly || form.action === 'H'"
                      @change="handleWakilkuasaJenisChange"
                    >
                      <option :value="null">Sila Pilih Jenis Wakil Kuasa</option>
                      <option v-for="jenis in kodJenisWakilKuasa" :value="jenis.id" :key="`jenis_wakilkuasa_${jenis.id}`">{{ jenis.name }}</option>
                    </select>
                  </div>
                </div>

                <!-- section if wakilkuasa_jenis === 'SYARIKAT (PEMAJU)' -->
                <section v-if="form.wakilkuasa_jenis === 'S'">
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_nama" style="align-items: center; display: flex">3.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Nama Penerima Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_nama"
                        type="text" name="wakilkuasa_nama"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_nama')}"
                        v-model="form.wakilkuasa_nama"
                        v-validate="'required'"
                        readonly
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_kp" style="align-items: center; display: flex">4.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. SSM <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_kp"
                        type="text" name="wakilkuasa_no_kp"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                        v-model="form.wakilkuasa_no_kp"
                        v-validate="'required'"
                        readonly
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_pendaftaran" style="align-items: center; display: flex">5.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>
                   No. Pendaftaran Wakil Kuasa <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_pendaftaran"
                        type="text" name="wakilkuasa_no_pendaftaran"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran')}"
                        v-model="form.wakilkuasa_no_pendaftaran"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_tel" style="align-items: center; display: flex">6.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>
                   Nama Penandatangan <span class="label-required"> </span></label>
                    <div class="col-md-8">
                      <select
                        name="wakilkuasa_penandatangan_id"
                        class="form-control"
                        :class="{'is-invalid': errors.first('wakilkuasa_penandatangan_id')}"
                        v-model="form.wakilkuasa_penandatangan_id"
                        :disabled="readonly || form.action === 'H'"
                      disabled>
                      <option :value="null">Sila Pilih Nama Penandatangan</option>
                        <option v-for="pengarah in pengarahRs" :value="pengarah.id" :key="`pengarah_${pengarah.id}`">{{ pengarah.nama }} {{ '('+pengarah.no_kp+')' }}</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_tel" style="align-items: center; display: flex">6.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>
                   No. Telefon Bimbit <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_tel"
                        type="text" name="wakilkuasa_no_tel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_tel')}"
                        v-model="form.wakilkuasa_no_tel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">7.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>Tarikh Pendaftaran Wakil Kuasa <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <datepicker name="wakilkuasa_tarikh_pendaftaran"
                        :class="{ 'is-invalid': errors.first('wakilkuasa_tarikh_pendaftaran') }"
                        v-model="form.wakilkuasa_tarikh_pendaftaran"
                        :disabled="readonly">
                      </datepicker>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">8.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span>Nama Mahkamah <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <select2
                        name="wakilkuasa_mahkamah_id"
                        class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wakilkuasa_mahkamah_id')}"
                        v-model="form.wakilkuasa_mahkamah_id"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                        <option :value="null">Sila Pilih Mahkamah Tinggi</option>
                        <option v-for="mahkamah in mahkamahTinggi" :value="mahkamah.id" :key="`mahkamah_${mahkamah.id}`">{{ mahkamah.nama_mahkamah }}</option>
                      </select2>
                    </div>
                  </div>
                </section>

                <!-- section if wakilkuasa_jenis === 'SYARIKAT (BUKAN PEMAJU)' -->
                <section v-else-if="form.wakilkuasa_jenis === 'BP'">
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_nama" style="align-items: center; display: flex">3.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Nama Penerima Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_nama"
                        type="text" name="wakilkuasa_nama"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_nama')}"
                        v-model="form.wakilkuasa_nama"
                        v-validate="'required'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_kp" style="align-items: center; display: flex">4.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. SSM <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_kp"
                        type="text" name="wakilkuasa_no_kp"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                        v-model="form.wakilkuasa_no_kp"
                        v-validate="'required'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">5.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Nama Penandatangan <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_penandatangan_nama"
                        type="text" name="wakilkuasa_penandatangan_nama"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_penandatangan_nama')}"
                        :value="form.wakilkuasa_penandatangan_nama"
                        @input="form.wakilkuasa_penandatangan_nama = $event.target.value.toUpperCase()"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_jawatan" style="align-items: center; display: flex">6.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Jawatan <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_jawatan"
                        type="text" name="wakilkuasa_jawatan"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_jawatan')}"
                        v-model="form.wakilkuasa_jawatan"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_kp_penandatangan" style="align-items: center; display: flex">7.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Kad Pengenalan Penandatangan <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_kp_penandatangan"
                        type="text" name="wakilkuasa_no_kp_penandatangan"
                        class="form-control"
                        :class="{'is-invalid': errors.first('wakilkuasa_no_kp_penandatangan')}"
                        :value="noKpPenandatangan"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_tel" style="align-items: center; display: flex">8.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Telefon Bimbit <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_tel"
                        type="text" name="wakilkuasa_no_tel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_tel')}"
                        v-model="form.wakilkuasa_no_tel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_emel" style="align-items: center; display: flex">9.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Emel <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_emel"
                        type="email" name="wakilkuasa_emel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_emel')}"
                        v-model="form.wakilkuasa_emel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_pendaftaran" style="align-items: center; display: flex">10.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Pendaftaran Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_pendaftaran"
                        type="text" name="wakilkuasa_no_pendaftaran"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran')}"
                        v-model="form.wakilkuasa_no_pendaftaran"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">11.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Tarikh Pendaftaran Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <datepicker name="wakilkuasa_tarikh_pendaftaran"
                        :class="{ 'is-invalid': errors.first('wakilkuasa_tarikh_pendaftaran') }"
                        v-model="form.wakilkuasa_tarikh_pendaftaran"
                        :disabled="readonly">
                      </datepicker>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">12.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Nama Mahkamah <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <select2
                        name="wakilkuasa_mahkamah_id"
                        class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wakilkuasa_mahkamah_id')}"
                        v-model="form.wakilkuasa_mahkamah_id"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                        <option :value="null">Sila Pilih Mahkamah Tinggi</option>
                        <option v-for="mahkamah in mahkamahTinggi" :value="mahkamah.id" :key="`mahkamah_${mahkamah.id}`">{{ mahkamah.nama_mahkamah }}</option>
                      </select2>
                    </div>
                  </div>
                </section>

                <!-- section if wakilkuasa_jenis === 'FIRMA' -->
                <section v-else-if="form.wakilkuasa_jenis === 'F'">
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_nama" style="align-items: center; display: flex">3.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Nama Penerima Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_nama"
                        type="text" name="wakilkuasa_nama"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_nama')}"
                        :value="form.wakilkuasa_nama" @input="form.wakilkuasa_nama = $event.target.value.toUpperCase()"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_pendaftaran_firma" style="align-items: center; display: flex">4.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Pendaftaran Firma <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_pendaftaran_firma"
                        type="text" name="wakilkuasa_no_pendaftaran_firma"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran_firma')}"
                        v-model="form.wakilkuasa_no_pendaftaran_firma"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">5.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Nama Penandatangan <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_nama_penandatangan_firma"
                        type="text" name="wakilkuasa_nama_penandatangan_firma"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_nama_penandatangan_firma')}"
                        :value="form.wakilkuasa_nama_penandatangan_firma"
                        @input="form.wakilkuasa_nama_penandatangan_firma = $event.target.value.toUpperCase()"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_jawatan" style="align-items: center; display: flex">6.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Jawatan <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_jawatan"
                        type="text" name="wakilkuasa_jawatan"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_jawatan')}"
                        v-model="form.wakilkuasa_jawatan"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_kp_firma" style="align-items: center; display: flex">7.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Kad Pengenalan <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_kp_firma"
                        type="text" name="wakilkuasa_no_kp_firma"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp_firma')}"
                        v-model="form.wakilkuasa_no_kp_firma"
                        v-mask="'######-##-####'"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_tel" style="align-items: center; display: flex">8.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Telefon Bimbit <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_tel"
                        type="text" name="wakilkuasa_no_tel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_tel')}"
                        v-model="form.wakilkuasa_no_tel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_emel" style="align-items: center; display: flex">9.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Emel <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_emel"
                        type="email" name="wakilkuasa_emel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_emel')}"
                        v-model="form.wakilkuasa_emel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_pendaftaran" style="align-items: center; display: flex">10.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Pendaftaran Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_pendaftaran"
                        type="text" name="wakilkuasa_no_pendaftaran"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran')}"
                        v-model="form.wakilkuasa_no_pendaftaran"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">11.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Tarikh Pendaftaran Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <datepicker name="wakilkuasa_tarikh_pendaftaran"
                        :class="{ 'is-invalid': errors.first('wakilkuasa_tarikh_pendaftaran') }"
                        v-model="form.wakilkuasa_tarikh_pendaftaran"
                        :disabled="readonly">
                      </datepicker>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">12.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Nama Mahkamah <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <select2
                        name="wakilkuasa_mahkamah_id"
                        class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wakilkuasa_mahkamah_id')}"
                        v-model="form.wakilkuasa_mahkamah_id"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                        <option :value="null">Sila Pilih Mahkamah Tinggi</option>
                        <option v-for="mahkamah in mahkamahTinggi" :value="mahkamah.id" :key="`mahkamah_${mahkamah.id}`">{{ mahkamah.nama_mahkamah }}</option>
                      </select2>
                    </div>
                  </div>
                </section>

                <!-- section if wakilkuasa_jenis === 'INDIVIDU' -->
                <section v-else-if="form.wakilkuasa_jenis === 'I'">
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_nama" style="align-items: center; display: flex">3.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Nama Penerima Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_nama"
                        type="text" name="wakilkuasa_nama"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_nama')}"
                        :value="form.wakilkuasa_nama" @input="form.wakilkuasa_nama = $event.target.value.toUpperCase()"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_kp" style="align-items: center; display: flex">4.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Kad Pengenalan <span class="label-required">*</span> <img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_kp"
                        type="text" name="wakilkuasa_no_kp"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_kp')}"
                        v-model="form.wakilkuasa_no_kp"
                        v-mask="'######-##-####'"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_tel" style="align-items: center; display: flex">5.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Telefon Bimbit <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_tel"
                        type="text" name="wakilkuasa_no_tel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_tel')}"
                        v-model="form.wakilkuasa_no_tel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_emel" style="align-items: center; display: flex">6.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > Emel <span class="label-required">*</span></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_emel"
                        type="email" name="wakilkuasa_emel"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_emel')}"
                        v-model="form.wakilkuasa_emel"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" for="wakilkuasa_no_pendaftaran" style="align-items: center; display: flex">7.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  > No. Pendaftaran Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-8">
                      <input
                        id="wakilkuasa_no_pendaftaran"
                        type="text" name="wakilkuasa_no_pendaftaran"
                        class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_no_pendaftaran')}"
                        v-model="form.wakilkuasa_no_pendaftaran"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">8.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Tarikh Pendaftaran Wakil Kuasa <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <datepicker name="wakilkuasa_tarikh_pendaftaran"
                        :class="{ 'is-invalid': errors.first('wakilkuasa_tarikh_pendaftaran') }"
                        v-model="form.wakilkuasa_tarikh_pendaftaran"
                        :disabled="readonly">
                      </datepicker>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-form-label col-md-4" style="align-items: center; display: flex">9.<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Nama Mahkamah <span class="label-required">*</span><img src="/img/espa_icon.png"
                    alt="ESPA Icon"
                    class="fas fa-info-circle ml-auto align-middle"
                    style="display: inline-flex; align-items: center; line-height: 1;  width: 15px;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Medan ini akan dipaparkan dalam cetakan eSPA."
                  ></img
                ></label>
                    <div class="col-md-4">
                      <select2
                        name="wakilkuasa_mahkamah_id"
                        class="form-control" :class="{'is-invalid select2-hidden-accessible': errors.first('wakilkuasa_mahkamah_id')}"
                        v-model="form.wakilkuasa_mahkamah_id"
                        v-validate="'required'"
                        :disabled="readonly || form.action === 'H'"
                      >
                        <option :value="null">Sila Pilih Mahkamah Tinggi</option>
                        <option v-for="mahkamah in mahkamahTinggi" :value="mahkamah.id" :key="`mahkamah_${mahkamah.id}`">{{ mahkamah.nama_mahkamah }}</option>
                      </select2>
                    </div>
                  </div>
                </section>
               <!--  <div class="form-group row" v-show="form.wakilkuasa_jenis">
                  <label for="wakilkuasa_alamat1" class="col-form-label col-md-4" style="align-items: center; display: flex">{{ alamatNumber }}<span
                    class="tab-space"
                    style="display: inline-block; width: 1.4em"
                  ></span
                  >Alamat <span class="label-required">*</span></label>
                  <div class="col-md-8">
                    <input
                      id="wakilkuasa_alamat1"
                      type="text" name="wakilkuasa_alamat1"
                      class="form-control" :class="{'is-invalid': errors.first('wakilkuasa_alamat1')}"
                      :value="form.wakilkuasa_alamat1"
                      @input="form.wakilkuasa_alamat1 = $event.target.value.toUpperCase()"
                      :disabled="readonly || form.action === 'H'"
                      v-validate="'required'"
                    >
                  </div>
                  <div class="offset-md-4 col-md-8 mb-2">
                      <input id="wakilkuasa_alamat2"
                        type="text" name="wakilkuasa_alamat2"
                        class="form-control"
                        :value="form.wakilkuasa_alamat2"
                        @input="form.wakilkuasa_alamat2 = $event.target.value.toUpperCase()"
                        :disabled="readonly || form.action === 'H'"
                      >
                  </div>
                  <div class="offset-md-4 col-md-8">
                      <input id="wakilkuasa_alamat3"
                        type="text" name="wakilkuasa_alamat3"
                        class="form-control"
                        :value="form.wakilkuasa_alamat3"
                        @input="form.wakilkuasa_alamat3 = $event.target.value.toUpperCase()"
                        :disabled="readonly || form.action === 'H'"
                      >
                  </div>
                </div>
 -->
              </section>
            </section>
            </div>
          </div>
          <div class="modal-footer">
            <transition name="fade"><span class="text-danger mr-2" v-if="hErrorMessage">{{ hErrorMessage }}</span></transition>
            <button v-if="!readonly && form.action !== 'H'" type="button" class="btn btn-warning" @click.prevent="resetForm" :disabled="loading">Semula</button>
            <button v-if="!readonly && form.action !== 'H'" type="button" class="btn btn-primary" @click.prevent="save" :disabled="loading">Simpan</button>
            <button type="button" class="btn btn-secondary" @click="close">Tutup</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TuanTanahModal",

  mounted() {
    vmodal.register(this.modalId, {open: this.onOpen, close: this.onClose})
  },

  props: {
    readonly: {
      type: Boolean,
      default: false,
    },

    kodNegeriRs: Array,
    kodDaerahRs: Array,
    kodBandarRs: Array,

    kodJenisTuanTanahRs: Array,
    kodJenisUrusanRs: Array,
    kodPemilikanPembeliRs: Array,

    mahkamahTinggi: Array,
    pengarahRs: Array,
    kodPoskodRs: Array,
    kodPoskodBandarRs: Array,
  },

  data() {
    return {
      modelId: null,
      modalId: 'tuantanah',
      loading: false,
      saving: false,

      doc_urusan: null,
      doc_jointventure: null,
      doc_wakilkuasa: null,
      doc_surat_ikatan_amanah: null,

      maklumat: Object,

      tuan_tanah_rs: [],

      form: {},
      response: () => {
      },
      kodJenisWakilKuasa: [
        {id: 'S', name: 'SYARIKAT (PEMAJU)'},
        {id: 'BP', name: 'SYARIKAT (BUKAN PEMAJU)'},
        {id: 'F', name: 'FIRMA'},
        {id: 'I', name: 'INDIVIDU'},
      ],
      useDataPrevious: false,
    }
  },

  methods: {
    resetFields(){
      this.form.nama = '';
      this.form.nama_enakmen = '';
      this.form.no_kp = '';
      this.form.no_ssm_baru = '';
      this.form.alamat1 = '';
      this.form.alamat2 = '';
      this.form.alamat3 = '';
      this.form.poskod = '';
      this.form.wakilkuasa_ada = '';
      this.form.tempoh_pajakan = null;
      this.form.tarikh_mula_pajakan = null;
      this.form.tarikh_luput_pajakan = null;
      this.form.tarikh_daftar_perserahan = null;
      this.form.no_perserahan = '';
      this.form.wakilkuasa_no_kp = '';
      this.form.wakilkuasa_no_pendaftaran = '';
      this.form.wakilkuasa_no_tel = '';
      this.form.wakilkuasa_tarikh_pendaftaran = null;
      this.form.wakilkuasa_nama = '';
      this.form.wakilkuasa_jawatan = '';
      this.form.wakilkuasa_emel = '';
      this.form.wakilkuasa_no_pendaftaran_firma = '';
      this.form.wakilkuasa_nama_penandatangan_firma = '';
      this.form.wakilkuasa_no_kp_firma = '';
      this.form.wakilkuasa_penandatangan_nama = null;



      // dropdown
      this.form.kod_negeri_id = null;
      this.form.kod_daerah_id = null;
      this.form.kod_bandar_id = null;
      this.form.tuan_tanah_asal_id = null;
      this.form.tuan_tanah_asal_id = null;
      this.form.wakilkuasa_mahkamah_id = null;
      this.form.wakilkuasa_penandatangan_id = null;
      this.form.kod_kategori_pemilik_id = null;

      // radio
      this.form.kod_jenis_urusan_id = null;
    },
    resetForm() {
      // Reset formData to initial state
      this.form = {
        name: '',
        email: '',
        message: '',
        kod_jenis_tuan_tanah_id: '',
        kod_kategori_pemilik_id: '',
        nama: '',
        no_kp: '',
        no_ssm_baru: '',
        alamat1: '',
        alamat2: '',
        alamat3: '',
        poskod: '',
        kod_negeri_id: '',
        kod_daerah_id: '',
        kod_bandar_id: '',
        tuan_tanah_asal_id: '',
        kod_jenis_urusan_id: '',
        doc_urusan_id: '',
        doc_jointventure_id: '',
        wakilkuasa_ada: '',
        wakilkuasa_jenis: '',
        wakilkuasa_nama: '',
        wakilkuasa_no_kp: '',
        wakilkuasa_alamat1: '',
        wakilkuasa_alamat2: '',
        wakilkuasa_alamat3: '',
        wakilkuasa_poskod: '',
        wakilkuasa_kod_negeri_id: '',
        wakilkuasa_kod_daerah_id: '',
        wakilkuasa_kod_bandar_id: '',
        wakilkuasa_no_tel: '',
        wakilkuasa_emel: '',
        wakilkuasa_no_pendaftaran: '',
        wakilkuasa_tarikh_pendaftaran: '',
        wakilkuasa_penandatangan_id: '',
        wakilkuasa_penandatangan_nama: '',
        wakilkuasa_jawatan: '',
        wakilkuasa_no_pendaftaran_firma: '',
        wakilkuasa_no_kp_firma: '',
        wakilkuasa_nama_penandatangan_firma: '',
        wakilkuasa_mahkamah_id: '',
        doc_wakilkuasa_id: '',

        nama_enakmen: '',
        tempoh_pajakan: '',
        tarikh_mula_pajakan: '',
        tarikh_luput_pajakan: '',
        tarikh_daftar_perserahan: '',
        no_perserahan: '',
        doc_surat_ikatan_amanah_id: '',

        action: '',
      };
      this.useDataPrevious = false;
    },
    onOpen({
      id = null,
      kod_jenis_tuan_tanah_id = null,
      kod_kategori_pemilik_id = null,
      nama = null,
      no_kp = null,
      alamat1 = null,
      alamat2 = null,
      alamat3 = null,
      poskod = null,
      kod_negeri_id = null,
      kod_daerah_id = null,
      kod_bandar_id = null,
      tuan_tanah_asal_id = null,
      kod_jenis_urusan_id = null,

      nama_enakmen = null,
      tempoh_pajakan = null,
      tarikh_mula_pajakan = null,
      tarikh_luput_pajakan = null,
      tarikh_daftar_perserahan = null,
      no_perserahan = null,
      doc_surat_ikatan_amanah_id = null,
      doc_surat_ikatan_amanah = null,

      doc_urusan_id = null,
      doc_jointventure_id = null,

      doc_wakilkuasa_id = null,
      wakilkuasa_ada = null,
      wakilkuasa_jenis = null,
      wakilkuasa_nama = null,
      wakilkuasa_no_kp = null,
      wakilkuasa_alamat1 = null,
      wakilkuasa_alamat2 = null,
      wakilkuasa_alamat3 = null,
      wakilkuasa_poskod = null,
      wakilkuasa_kod_negeri_id = null,
      wakilkuasa_kod_daerah_id = null,
      wakilkuasa_kod_bandar_id = null,
      wakilkuasa_no_tel = null,
      wakilkuasa_emel = null,
      wakilkuasa_no_pendaftaran = null,
      wakilkuasa_tarikh_pendaftaran = null,
      wakilkuasa_penandatangan_id = null,
      wakilkuasa_penandatangan_nama = null,
      wakilkuasa_jawatan = null,
      wakilkuasa_no_pendaftaran_firma = null,
      wakilkuasa_no_kp_firma = null,
      wakilkuasa_nama_penandatangan_firma = null,
      wakilkuasa_mahkamah_id = null,

      doc_urusan = null,
      doc_jointventure = null,
      doc_wakilkuasa = null,

      tuan_tanah_rs = [],
      maklumat = null,
      action = null,
   }, callback) {
      this.form = {
        kod_jenis_tuan_tanah_id,
        kod_kategori_pemilik_id,
        nama,
        no_kp,
        no_ssm_baru: maklumat?.no_ssm_baru,
        alamat1,
        alamat2,
        alamat3,
        poskod,
        kod_negeri_id,
        kod_daerah_id,
        kod_bandar_id,
        tuan_tanah_asal_id,
        kod_jenis_urusan_id,
        doc_urusan_id,
        doc_jointventure_id,
        wakilkuasa_ada,
        wakilkuasa_jenis,
        wakilkuasa_nama,
        wakilkuasa_no_kp,
        wakilkuasa_alamat1,
        wakilkuasa_alamat2,
        wakilkuasa_alamat3,
        wakilkuasa_poskod,
        wakilkuasa_kod_negeri_id,
        wakilkuasa_kod_daerah_id,
        wakilkuasa_kod_bandar_id,
        wakilkuasa_no_tel,
        wakilkuasa_emel,
        wakilkuasa_no_pendaftaran,
        wakilkuasa_tarikh_pendaftaran,
        wakilkuasa_penandatangan_id,
        wakilkuasa_penandatangan_nama,
        wakilkuasa_jawatan,
        wakilkuasa_no_pendaftaran_firma,
        wakilkuasa_no_kp_firma,
        wakilkuasa_nama_penandatangan_firma,
        wakilkuasa_mahkamah_id,
        doc_wakilkuasa_id,

        nama_enakmen,
        tempoh_pajakan,
        tarikh_mula_pajakan,
        tarikh_luput_pajakan,
        tarikh_daftar_perserahan,
        no_perserahan,
        doc_surat_ikatan_amanah_id,

        action,
      }

      this.doc_urusan = doc_urusan;
      this.doc_jointventure = doc_jointventure;
      this.doc_wakilkuasa = doc_wakilkuasa;
      this.doc_surat_ikatan_amanah = doc_surat_ikatan_amanah;

      this.maklumat = maklumat;

      this.modelId = id;
      this.tuan_tanah_rs = tuan_tanah_rs;
      this.response = callback;
    },

    onClose() {
      this.useDataPrevious = false;
    },

    close() {
      vmodal.close(this.modalId);
    },

    async save() {
      if (this.loading) {
        return;
      }

      const passes = await this.$validator.validateAll();

      if (!passes) {
        return;
      }
      const form = JSON.parse(JSON.stringify(this.form));

      if (this.form.doc_urusan_id instanceof File) {
        form.doc_urusan_id = this.form.doc_urusan_id;
      }

      if (this.form.doc_jointventure_id instanceof File) {
        form.doc_jointventure_id = this.form.doc_jointventure_id;
      }

      if (this.form.doc_wakilkuasa_id instanceof File) {
        form.doc_wakilkuasa_id = this.form.doc_wakilkuasa_id;
      }

      if (this.form.doc_surat_ikatan_amanah_id instanceof File) {
        form.doc_surat_ikatan_amanah_id = this.form.doc_surat_ikatan_amanah_id;
      }

      const self = this;

      this.response(form, {
        setLoading(loading) {
          self.loading = loading;
        },
        errorCallback(err) {
          self.$hError(err)
        },
        close: self.close
      });
    },

    filteredDaerahRs(kod_negeri_id) {
      if (!kod_negeri_id) {
        return [];
      }

      return this.kodDaerahRs.filter(item => item.kod_negeri_id == kod_negeri_id);
    },
    getTarikhLuputPajakan() {
      if(this.form.tempoh_pajakan || this.form.tempoh_pajakan > 0){

        if(this.form.tarikh_mula_pajakan){
          const [day, month, year] = this.form.tarikh_mula_pajakan.split('/');
          const date = new Date(+year, +month - 1, +day-1);
          const newDate = new Date(date);

          newDate.setFullYear(newDate.getFullYear() + parseInt(this.form.tempoh_pajakan));

          this.form.tarikh_luput_pajakan = moment(newDate).format('DD/MM/YYYY');
        }
      }
    },
    filteredBandarRs(kod_daerah_id) {
      if (!kod_daerah_id) {
        return [];
      }

      return this.kodBandarRs.filter(item => item.kod_daerah_id == kod_daerah_id);
    },

    poskodChange() {
      const poskod = this.form.poskod;
      const poskodSelected = this.kodPoskodRs.find((poskodData) => poskodData.poskod == poskod);
      if(!poskod) {
        this.form.kod_bandar_id = null;
        this.form.kod_negeri_id = null;
        return;
      }

      if(!poskodSelected) {
        this.form.kod_bandar_id = null;
        this.form.kod_negeri_id = null;
        return;
      }

      this.form.kod_bandar_id = poskodSelected.kod_bandar_id;
      this.form.kod_negeri_id = poskodSelected.kod_negeri_id;
    },

    useDataPreviousChange() {
      if(!this.useDataPrevious) {
        this.resetForm();
        return;
      }

      const lastIndexTuanTanah = this.tuan_tanah_rs.length - 1;
      const dataPrevious = JSON.parse(JSON.stringify(this.tuan_tanah_rs[lastIndexTuanTanah]));
      const negeriId = dataPrevious.kod_negeri_id;
      const bandarId = dataPrevious.kod_bandar_id;
      this.form = dataPrevious;
      this.form.kod_negeri_id = negeriId;
      this.$nextTick(() => {
        this.form.doc_urusan_id = null;
        this.form.doc_jointventure_id = null;
        this.form.doc_wakilkuasa_id = null;
        this.form.doc_surat_ikatan_amanah_id = null;
        this.form.kod_bandar_id = bandarId;
        if(!this.form.no_ssm_baru) this.form.no_ssm_baru = this.maklumat?.no_ssm_baru
      });

    },
    handleWakilkuasaJenisChange() {
      this.form.wakilkuasa_penandatangan_id = null;
    },
  },

  computed: {
    isOpen() {
      return this.$root.openedModals.indexOf(this.modalId) !== -1
    },

    filterTuanTanahExclude() {
      if (this.modelId) {
        return this.tuan_tanah_rs.filter(tuan_tanah => tuan_tanah.id !== this.modelId);
      }

      return this.tuan_tanah_rs;
    },

    filterTuanTanahPajakanExclude() {
      if (this.modelId) {
        return this.tuan_tanah_rs.filter(tuan_tanah => {
          return (tuan_tanah.id !== this.modelId && tuan_tanah.kod_jenis_tuan_tanah_id == 'BP')
        });
      } else {
        return this.tuan_tanah_rs.filter(tuan_tanah => {
          return (tuan_tanah.kod_jenis_tuan_tanah_id == 'BP')
        });
      }

      return this.tuan_tanah_rs;
    },

    alamatNumber() {
      let number = 1
      if(!this.form.wakilkuasa_jenis) return number
      switch (this.form.wakilkuasa_jenis) {
        case 'S':
          number = 9
          break;
        case 'BP':
          number = 13
          break;
        case 'F':
          number = 13
          break;
        case 'I':
          number = 10
          break;
        default:
          number = 1
          break;
      }
      return number
    },

    noKpPenandatangan() {
      const pengarahId = this.form.wakilkuasa_penandatangan_id
      if(!pengarahId) {
        return null
      }
      return this.pengarahRs.find(item => item.id === pengarahId).no_kp || null
    },

    disabledKategoriPemilik() {
      return ['SB', 'PM'].includes(this.form.kod_jenis_tuan_tanah_id)
    }
  },

  watch: {
    'form.kod_jenis_tuan_tanah_id': function() {
      // jika radio btn == syarikat subsidiari
      if (this.form.kod_jenis_tuan_tanah_id === 'SB') {
        if (this.maklumat && this.maklumat.kod_jenis_pemaju_id == 2) {
          if (!this.form.nama) {
            this.form.nama = this.maklumat.nama_pemaju_induk;
          }
          if (!this.form.no_kp) {
            this.form.no_kp = this.maklumat.nossm_pemaju_induk;
          }
        }

        // reset dropdown kategori pemilik, comment code berikut untuk mengembalikan behaviour semula
        // this.form.kod_kategori_pemilik_id = null;
        if(!this.useDataPrevious) this.resetFields();
        // behaviour asli
        if (!this.form.kod_kategori_pemilik_id) {
          this.form.kod_kategori_pemilik_id = 'S';
        }
      }
      // jika radio btn == pemaju
      else if (this.form.kod_jenis_tuan_tanah_id === 'PM') {
        if (!this.form.kod_kategori_pemilik_id) {
          this.form.kod_kategori_pemilik_id = 'S';
        }

        if (this.maklumat) {
          if (!this.form.nama) {
            this.form.nama = this.maklumat.nama;
          }
          if (!this.form.no_ssm_baru){
            this.form.no_ssm_baru = this.maklumat.no_ssm_baru
          }
          if (!this.form.no_kp) {
            this.form.no_kp = this.maklumat.no_ssm;
          }

          if (!this.form.alamat1) {
            this.form.alamat1 = this.maklumat.alamat_perniagaan1;
          }

          if (!this.form.alamat2) {
            this.form.alamat2 = this.maklumat.alamat_perniagaan2;
          }

          if (!this.form.alamat3) {
            this.form.alamat3 = this.maklumat.alamat_perniagaan3;
          }

          if (!this.form.kod_negeri_id) {
            this.form.kod_negeri_id = this.maklumat.kod_negeri_perniagaan_id;
          }

          if (!this.form.poskod) {
            this.form.poskod = this.maklumat.poskod_perniagaan;
          }

          this.$nextTick(() => {
            // if (!this.form.kod_daerah_id) {
            //   this.form.kod_daerah_id = this.maklumat.kod_daerah_perniagaan_id;
            // }

            this.$nextTick(() => {
              if (!this.form.kod_bandar_id) {
                this.form.kod_bandar_id = this.maklumat.kod_bandar_perniagaan_id;
              }
            })
          })
        }
      }
      // jika radio btn bernilai selain itu
      else
      {
        if(!this.useDataPrevious) this.resetFields();
        // reset dropdown kategori pemilik
        // this.form.kod_kategori_pemilik_id = null;
      }
      // if(!this.form.no_ssm_baru) this.form.no_ssm_baru = this.maklumat?.no_ssm_baru
    },
    'form.wakilkuasa_jenis': function() {
      if (!this.form.wakilkuasa_jenis) {
        return
      }
      if(["S"].includes(this.form.wakilkuasa_jenis)) {
        this.form.wakilkuasa_nama = this.maklumat.nama;
        this.form.wakilkuasa_no_kp = `${this.maklumat.no_ssm_baru} (${this.maklumat.no_ssm})`;
      } else {
        this.form.wakilkuasa_nama = null;
        this.form.wakilkuasa_no_kp = null
      }
    }
  }
}
</script>

<style scoped>

</style>
