<template>
  <div class="fileupload">
    <div v-if="readonly" class="d-flex file-container align-items-center">
      <div class="d-flex justify-content-between text-truncate" v-if="doc">
        <div class="text-truncate flex-grow-1">
          <a :href="doc.url" target="_blank" :title="doc.nama"
            ><i class="fas fa-file-pdf mr-2" /> {{ doc.nama }}
          </a>
        </div>
        <div class="flex-shrink-0 ml-3">
          ({{ doc.size_in_byte | filesize }})
        </div>
      </div>
      <div v-else class="text-truncate">Tidak disertakan</div>
    </div>
    <div v-else>
      <div
        v-if="currentFile || doc"
        class="d-flex file-container align-items-center"
      >
        <div
          class="d-flex flex-grow-1 justify-content-between text-truncate"
          v-if="doc"
        >
          <div class="text-truncate">
            <a :href="doc.url" target="_blank" :title="doc.nama"
              ><i class="fas fa-file-pdf mr-2" /> {{ doc.nama }}
            </a>
          </div>
          <div class="flex-shrink-0 ml-3">
            ({{ doc.size_in_byte | filesize }})
          </div>
        </div>
        <div class="text-truncate" v-else-if="currentFile">uploading..</div>
        <button
          class="text-dark ml-2"
          @click.prevent="remove"
          type="button"
          tabindex="-1"
        >
          <i class="fas fa-window-close"></i>
        </button>
      </div>
      <div
        v-if="!currentFile && !doc"
        class="file-container text-truncate cursor-pointer"
        @click.prevent="$refs.input.click()"
      >
        <div class="d-flex justify-content-between">
          <div>
            <i class="upload-icon fas fa-upload mr-2" /> Muat Naik
            <span v-if="acceptType === 'application/pdf'">PDF</span>
          </div>
          <div>
            <span
              class="text-info fas fa-exclamation-circle"
              data-toggle="tooltip"
              data-placement="top"
              :title="`Saiz Maksimum: ` + size"
            />
          </div>
        </div>
      </div>
    </div>
    <input
      v-if="!doc"
      v-bind="$attrs"
      type="file"
      :name="name"
      :accept="acceptType"
      class="input-file"
      ref="input"
      @change="filesChange($event.target.name, $event.target.files)"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  $_veeValidate: {
    value() {
      if (this.doc) {
        return this.doc;
      }

      return this.currentFile;
    },
  },

  props: {
    doc: Object,
    name: String,
    size: {
      type: String,
      default: "5MB",
    },
    acceptType: {
      type: String,
      default: "application/pdf",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      currentFile: null,
    };
  },
  methods: {
    filesChange(name, files) {
      this.currentFile = files[0];
      this.$emit("upload", this.currentFile);
      this.$emit("uploadwithcallback", {
        file: this.currentFile,
        callback: this.clear,
      });
    },

    clear() {
      this.currentFile = "";
      if (this.$refs.input) {
        this.$refs.input.value = "";
      }
    },

    remove() {
      this.$emit("remove", this.doc);
      this.currentFile = "";
      if (this.$refs.input) {
        this.$refs.input.value = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fileupload {
  position: relative;
  width: 100%;
  padding: 0 !important;

  &.has-file {
    background: #ba68c8;
    color: white !important;
  }

  &.is-invalid {
    color: red;

    .upload-icon {
      display: none;
    }
  }

  &.form-control {
    .file-container {
      padding: 0.56rem 0.75rem;
    }
  }
}
.fa-window-close {
  color: #f44747;
}
.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 0;
  height: 0;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
}
button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  font-family: sans-serif;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}
</style>
