<template>
  <div>
    <button v-if="!loading" class="btn btn-primary btn-sm" @click.prevent="$refs.file.click()">MUAT NAIK</button>
    <button v-if="loading" class="btn btn-warning btn-sm" disabled>SEDANG MEMUAT NAIK</button>

    <input type="file"
           ref="file"
           class="hiddenfile"
           accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
           @change="change($event.target.name, $event.target.files)"
    >
  </div>
</template>

<script>
export default {
  name: "ExcelUpload",

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    change(name, files) {
      const self = this;

      this.$emit('change', name, files, (loading) => {
        self.loading = loading;
      })

      this.$refs.file.value = '';
    }
  }
}
</script>

<style scoped>
.hiddenfile {
  opacity: 0; /* invisible but it's there! */
  width: 0;
  height: 0;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
}
</style>
