<template>
  <div class="card">
    <div class="card-body">
      <ul class="ribbon-steps nav">
        <li class>
          <a
            :class="{'show active': isCurrentTab('#bahagian-c')}"
            href="#"
          >MAKLUMAT PEMILIKAN</a>
        </li>
      </ul>

      <div class="text-right" v-if="redirectUrl">
        <a :href="redirectUrl" class="btn btn-warning">Kembali</a>
      </div>

      <div class="  mt-4">
        <div
          v-if="isCurrentTab('#bahagian-c')"
          class="tab-pane fade show active"
          id="bahagian-c"
          role="tabpanel"
        >
          <div class="card bg-light">
            <div class="card-body">
              <h4 class="card-title">MAKLUMAT PEMILIKAN</h4>
              <bahagian-c
                :pemajuan.sync="formPemajuan"
                :kewangan="formKewangan"
                @done="() => isKuotaBumi && setTab('#bahagian-d')"
              ></bahagian-c>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import BahagianA from './BahagianA';
  import BahagianB from './BahagianB';
  import BahagianC from './BahagianC';

  export default {
    name: "Borang",

    components: {
      BahagianA,
      BahagianB,
      BahagianC,
    },

    props: {
      redirectUrl: {
        type: String,
        default: null,
      },

      pemaju: {
        type: Object
      },

      pemajuan: {
        type: Object
      },

      errorBag: {
        type: Object
      },

      readonly: {
        type: Boolean,
        default: false,
      },
    },

    created() {
      window.addEventListener("hashchange", this.changeTab, false);
      this.$makeErrorBag(this.errorBag);
      this.changeTab();
    },

    destroyed() {
      window.removeEventListener("hashchange", this.changeTab);
    },

    data() {
      return {
        defaultTab: "#bahagian-c",
        tab: this.defaultTab,
        formPemajuan: JSON.parse(JSON.stringify(this.pemajuan)),
        formKewangan: JSON.parse(JSON.stringify(this.pemajuan.kewangan)),
      };
    },

    methods: {
      isCurrentTab(tab) {
        return this.tab === tab;
      },

      setTab(tab) {
        window.location.hash = tab;
      },

      changeTab() {
        this.tab = window.location.hash || this.defaultTab;
      },

      getTotalKuotaBumi(pembangunan) {
        return pembangunan.nolot_unit_rs.reduce((total, item) => {
          if (item.kuota_bumi) {
            return total + 1;
          }

          return total;
        }, 0);
      }
    },

    computed: {
      isKuotaBumi() {
        return this.formPemajuan.pengesahan_kuotabumi === 'Y';
      },

      isTally() {
        if (!this.isKuotaBumi) {
          return true;
        }
        return this.formPemajuan.pembangunan_rs.reduce((total, item) => {
          if (!total) {
            return total;
          }
          return item.maklumat7f.bil_kuotabumi <= this.getTotalKuotaBumi(item);
        }, true);
      },
    }
  }
</script>

